import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function searchEntityReducer(state = initialState.entityInfo, action) {
    switch (action.type) {
        case types.SEARCH_ENTITY_SUCCESS:
            return {...state, entityInfo: action.entityInfo};

        case types.REMOVE_SEARCH_RESULTS:
            return {...state, entityInfo: initialState.entityInfo};

        default:
            return state;
    }
}