import axios from 'axios';
import * as types from './actionTypes';
import {API_ROOT} from "../api-config";
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from "./appLoading";

const ANNOUNCEMENTS_URL = API_ROOT + '/api/announcements';

export function loadAnnouncement() {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(ANNOUNCEMENTS_URL).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.LOAD_ANNOUNCEMENT_SUCCESS, announcements: responseData.data});
            } else {
                console.log("announcement.loadAnnouncements.errorMessage ===>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Couldn't retrieve annoncement."});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("announcement.loadAnnouncements.error ===>", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function confirmAccount(account, additionalProducts, applicationId, saveAndContinue, onProceed) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');

            await axios.put(APPLICATIONS_URL + applicationId + "/additional", additionalProducts).then(response => response.data);

            let url = saveAndContinue ? APPLICATIONS_URL + applicationId + "/account?saveAndContinue=true" : APPLICATIONS_URL + applicationId + "/account";

            const responseData = await axios.put(url, account).then(response => response.data);

            loadingPageDataFinish(dispatch);
            if (responseData.success) {
                dispatch({type: types.ACCOUNT_CONFIRM_SUCCESS, application: responseData.data});
                onProceed && onProceed();
            } else {
                console.log("product.confirmAccount.errorMessage ==>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Couldn't confirm product details."});
            }
        } catch (error) {
            console.log("product.confirmAccount.error ===>", error)
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, false, status);
        }
    };
}

export function viewMenuOption(history, path) {
    return (dispatch) => dispatch({type: types.VIEW_MENU_OPTION, history, path});
}
