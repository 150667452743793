import React, {Component} from "react";
import _ from "lodash";
import validate from "validate.js";
import {Col} from "react-bootstrap";
import FormField from '../formField/FormField'


const ADDITIONAL_PRODUCTS = {
    bolId: "Business Online",
    cashManId: "Cash Management"
};
const TOGGLE = {
    otherBolProfileId: "otherBolProfileId"
};

class BolComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddToggled: false,
            bolProduct: props.bolProduct ? props.bolProduct : {id: ADDITIONAL_PRODUCTS.bolId},
            config: {limits: {payments: false, collections: false}},
            operator: {
                accountAccess: {balanceStatements: false, Payments: false, ownTransfers: false, notifications: false}
            },
            toggle: {
                [TOGGLE.otherBolProfileId]: false,
            },
            otherBolProfileId: "",
            selectedUser: null
        };
        this.handleOnChange = this._handleOnChange.bind(this);
        this.handleOnChangeLimits = this._handleOnChangeLimits.bind(this);
        this.handleOnChangeOperator = this._handleOnChangeOperator.bind(this);
        this.handleAddOperator = this._handleAddOperator.bind(this);
        this.handleRemoveOperator = this._handleRemoveOperator.bind(this);
        this.resetError = this._resetError.bind(this);
        this.showAddOperator = this._showAddOperator.bind(this);
        this.showAddOperator = this._showAddOperator.bind(this);
        this.handleOptions = this._handleOptions.bind(this);
    }

    componentDidMount() {
        this._setDefaults();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.errors) {
            this.setState({propsErrors: nextProps.errors})
        }
    }

    _setDefaults = () => {
        const {bolProfiles, bolProduct} = this.props;
        const bolProfileId = bolProduct && bolProduct.bolProfileId;
        const isFound = _.find(bolProfiles, ({userId}) => (userId === bolProfileId));
        if (!isFound && bolProfileId) {
            this.setState({otherBolProfileId: bolProfileId});
            this.handleToggle(TOGGLE.otherBolProfileId);
        }
    };

    _handleOnChange({target}, attribute, innerAttribute) {
        const {onChange} = this.props;
        const {bolProduct} = this.state;
        let value = _.trimStart(target.value);
        if (innerAttribute) {
            value = _.extend({}, bolProduct[attribute], {[innerAttribute]: value});
        }
        this.setState({bolProduct: _.extend({}, bolProduct, {[attribute]: value})}, () => {
            onChange(this.state.bolProduct);
        });
    }

    handleSelectChange = (event, attribute, innerAttribute) => {
        const {onChange} = this.props;
        const {bolProduct} = this.state;
        let value = event.value;
        if (innerAttribute) {
            value = _.extend({}, bolProduct[attribute], {[innerAttribute]: value});
        }
        this.setState({bolProduct: _.extend({}, bolProduct, {[attribute]: value})}, () => {
            onChange(this.state.bolProduct);
        });
    };

    handleSelectUserChange = (event) => {

        const {onChange} = this.props;
        const {bolProduct} = this.state;
        const config = bolProduct && bolProduct.config;
        let value = event.value;
        this.setState({selectedUser: value});

        this.setState({bolProduct: _.extend({}, bolProduct, {config: _.extend({}, config, {operators: []})}, {'bolProfileId': value})}, () => {
            onChange(this.state.bolProduct);
        });
    };

    handleSelectChangeOperator = (event, attribute) => {
        const {operator} = this.state;
        let value = event.value;
        this.setState({operator: _.extend({}, operator, {[attribute]: value})});
    };

    _handleOnChangeLimits({target}, attribute) {
        const {onChange} = this.props;
        const {bolProduct} = this.state;
        const config = bolProduct && bolProduct.config;
        let value = _.trimStart(target.value);
        if (target.type === 'number')
            value = parseInt(value, 10);
        const limits = _.extend({}, config && config.limits, {[attribute]: value});
        this.setState({bolProduct: _.extend({}, bolProduct, {config: _.extend({}, config, {limits})})}, () => {
            onChange(this.state.bolProduct)
        });
    }

    _handleOptions(event, attribute) {

        const {onChange} = this.props;
        const {bolProduct} = this.state;
        const config = bolProduct && bolProduct.config;
        // let value = event.value;
        const limits = _.extend({}, config && config.limits, {[attribute]: config && config.limits ? !config.limits[attribute] : true});
        this.setState({bolProduct: _.extend({}, bolProduct, {config: _.extend({}, config, {limits})})}, () => {
            onChange(this.state.bolProduct)
        });
    }

    _handleOnChangeOperator({target}, attribute, innerAttribute) {
        const {operator} = this.state;
        let value = _.trimStart(target.value);
        if (innerAttribute) {
            value = _.extend({}, operator && operator[attribute], {[innerAttribute]: value});
        }
        this.setState({operator: _.extend({}, operator, {[attribute]: value})});
    }

    handleCheckSelect = (isSelected, attribute, innerAttribute) => {
        const {operator} = this.state;

        let value = isSelected;
        if (innerAttribute) {
            value = _.extend({}, operator && operator[attribute], {[innerAttribute]: value});
        }
        this.setState({operator: _.extend({}, operator, {[attribute]: value})});
    };


    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    _validateForm() {
        const {operator} = this.state;
        const options = {fullMessages: false};
        const constraints = {operatorId: {presence: true, length: {minimum: 1, message: 'required'}}};

        const errors = validate(operator, constraints, options);
        this.setState({errors});
        return _.isEmpty(errors);
    }

    _handleAddOperator() {
        if (this._validateForm()) {
            const {onChange} = this.props;
            const {bolProduct, operator} = this.state;
            const config = bolProduct && bolProduct.config;
            const isFound = _.find(config && config.operators, o => ((o.operatorId && o.operatorId.toLocaleLowerCase()) === (operator.operatorId && operator.operatorId.toLocaleLowerCase())));

            let operators;
            if (!isFound) {
                operators = _.union(config && config.operators, [operator]);
            } else {
                operators = _.map(config && config.operators, o => {
                    const isMatch = ((o.operatorId && o.operatorId.toLocaleLowerCase()) === (operator.operatorId && operator.operatorId.toLocaleLowerCase()));
                    return isMatch ? operator : o;
                });
            }
            this.setState({
                bolProduct: _.extend({}, bolProduct, {config: _.extend({}, config, {operators})}),
                isAddToggled: false, operator: {
                    accountAccess: {
                        balanceStatements: false,
                        Payments: false,
                        ownTransfers: false,
                        notifications: false
                    }
                }
            }, () => {
                onChange(this.state.bolProduct);
            });
        }
    }

    _handleRemoveOperator(operatorId) {
        const {onChange} = this.props;
        const {bolProduct} = this.state;
        const config = bolProduct && bolProduct.config;
        const operators = _.filter(config && config.operators, o => (o.operatorId !== operatorId));
        this.setState({bolProduct: _.extend({}, bolProduct, {config: _.extend({}, config, {operators})})}, () => {
            onChange(this.state.bolProduct);
        });
    }

    _showAddOperator() {
        this.setState(prevState => ({isAddToggled: !prevState.isAddToggled}))
    }

    _renderOperatorForm() {
        const {bolProfiles} = this.props;
        const {operator, errors} = this.state;
        const accountAccess = operator && operator.accountAccess;
        let operatorsOptions = [];

        _.each(bolProfiles, (entity) => {
            _.each(entity.divisions, (div) => {

                if (div.code === this.state.selectedUser) {
                    _.each(div.users, (f) => {
                        operatorsOptions.push({'value': f.id, 'label': `${f.id}: ${f.name}`})
                    })
                }
            });
        });

        const hasProfileOperators = _.size(operatorsOptions) > 0;

        return (
            <div>
                <div className="product-heading">
                    Operator Details
                </div>
                <div className="title-gradient"/>
                <div style={{paddingTop: '5px'}}>
                    Add User(s) you want to allow access to this account.
                </div>
                <div className="card-container-form">
                    <FormField className="form-group" id="bol" error={errors && errors.operatorId}>
                        <label>User ID</label>
                        {
                            hasProfileOperators ?
                                <Select
                                    name="operatorId"
                                    onChange={(event) => {
                                        errors && errors.operatorId && this.resetError('operatorId');
                                        this.handleSelectChangeOperator(event, 'operatorId');
                                    }}
                                    options={operatorsOptions}
                                    value={operator && operator.operatorId ? operator.operatorId : ""}
                                />
                                :
                                <input
                                    className="form-control"
                                    id="operatorId"
                                    onChange={(event) => {
                                        errors && errors.operatorId && this.resetError('operatorId');
                                        this.handleOnChangeOperator(event, 'operatorId', null, false)
                                    }}
                                    type="text"
                                    value={operator && operator.operatorId || ''}
                                />
                        }
                    </FormField>
                    <br/>
                    <div className="flexRow" style={{padding: 0, margin: 0}}>
                        <div className="flexColumn col-md-6"
                             style={{alignItems: "flex-start", paddingLeft: 0, marginLeft: 0}}>

                            <div
                                className="inline-container" style={{marginLeft: 0}}
                                onClick={() => this.handleCheckSelect(!(accountAccess && accountAccess.balanceStatements), 'accountAccess', 'balanceStatements')}>
                                <div className="icon-container">
                                    <i className={((accountAccess && accountAccess.balanceStatements) ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                       style={{fontSize: 20}}/>
                                </div>
                                Balance & Statements
                            </div>

                            <div
                                className="inline-container" style={{marginLeft: 0}}
                                onClick={() => this.handleCheckSelect(!(accountAccess && accountAccess.payments), 'accountAccess', 'payments')}>
                                <div className="icon-container">
                                    <i className={((accountAccess && accountAccess.payments) ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                       style={{fontSize: 20}}/>
                                </div>
                                Payments
                            </div>
                        </div>
                        <div className="flexColumn  col-md-6" style={{alignItems: "flex-start"}}>
                            <div
                                className="inline-container" style={{marginLeft: 0}}
                                onClick={() => this.handleCheckSelect(!(accountAccess && accountAccess.ownTransfers), 'accountAccess', 'ownTransfers')}
                            >
                                <div className="icon-container">
                                    <i className={((accountAccess && accountAccess.ownTransfers) ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                       style={{fontSize: 20}}/>
                                </div>
                                Own Transfers
                            </div>

                            <div
                                className="inline-container" style={{marginLeft: 0}}
                                onClick={() => this.handleCheckSelect(!(accountAccess && accountAccess.notifications), 'accountAccess', 'notifications')}>
                                <div className="icon-container">
                                    <i className={((accountAccess && accountAccess.notifications) ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                       style={{fontSize: 20}}/>
                                </div>
                                Notifications
                            </div>
                        </div>
                    </div>
                </div>
                <div className="action-button-container">
                    <div/>
                    <button
                        className="action-btn-secondary"
                        onClick={this.handleAddOperator}
                        type="button"
                    >
                        Add
                    </button>
                </div>
            </div>
        )
    }

    _renderOperators() {
        const {bolProfileOperators} = this.props;
        const propErrors = this.props.errors;
        const {bolProduct} = this.state;
        const config = bolProduct && bolProduct.config;
        const hasOperators = _.size(config && config.operators) > 0;
        return (
            <div>
                <FormField
                    error={propErrors && propErrors.operators ? ["At Least One Operator Should Be Added"] : ""}
                >
                    {
                        hasOperators &&
                        <div>
                            <div className="section-title" style={{marginBottom: 0}}>
                                <span>Operators</span>
                            </div>
                            {
                                _.map(config && config.operators, (o, i) => {
                                    const accountAccess = o && o.accountAccess;
                                    const op = _.find(bolProfileOperators, ope => (ope.operatorId === o.operatorId));
                                    return (
                                        <div key={`operator/${i}`}>
                                            <div className="flexRow" style={{alignItems: "center",}}>
                                                <div className="col-md-11">
                                                    <span
                                                        className={"active-entity"}> {`${i + 1}. ${o.operatorId}: ${op && op.operatorName ? op.operatorName : ''}`}</span>
                                                    {accountAccess.balanceStatements &&
                                                    <span className="greyText"> | Balance & Statements</span>}
                                                    {accountAccess.payments &&
                                                    <span className="greyText"> | Payments</span>}
                                                    {accountAccess.ownTransfers &&
                                                    <span className="greyText"> | Own Transfers</span>}
                                                    {accountAccess.notifications &&
                                                    <span className="greyText"> | Notifications</span>}
                                                </div>
                                                <div className="col-md-1" style={{padding: 0}} id="item-content">
                                                    <p><a className="btn"
                                                          role="button"
                                                          id="remove-btn"
                                                          onClick={() => this.handleRemoveOperator(o.operatorId)}> <i
                                                        className="fa fa-times" aria-hidden="true"/></a>
                                                    </p>
                                                </div>
                                            </div>
                                            <hr style={{padding: 1, margin: 0}}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    <a
                        className="btn btn-secondary addButton"
                        onClick={() => {
                            propErrors && propErrors.operators && this.props.resetErrors('operators');
                            this.showAddOperator()
                        }}
                    >Add User</a>
                    {propErrors && propErrors.operators && (<div><br/><br/></div>)}
                </FormField>
                {this.state.isAddToggled &&
                <div style={{zIndex: -1}}>
                    <Popup onClose={this.showAddOperator.bind(this)}>
                        {this._renderOperatorForm()}
                    </Popup>
                </div>
                }
            </div>
        )
    }

    handleToggle = (attribute, hardToggle = null) => {
        this.setState(prevState => {
            const value = (hardToggle !== null) ? hardToggle : !(prevState.toggle && prevState.toggle[attribute]);
            const toggle = _.extend({}, prevState.toggle, {[attribute]: value});
            return {toggle}
        });
    };

    render() {
        const {errors, resetErrors, bolProfiles, onFetchOperators} = this.props;
        const {bolProduct, toggle} = this.state;
        const config = bolProduct && bolProduct.config;
        const hasProfiles = _.size(bolProfiles) > 0;

        let profilesOptions = [];
        _.each(bolProfiles, (entity) => {
            _.each(entity.divisions, (div) => {
                profilesOptions.push({'value': div.code, 'label': `${div.code}: ${div.name}`})
            })
        });


        const onToggle = (value, event) => {
            if (value === OTHER_PROFILE_ID.value && !toggle[TOGGLE.otherBolProfileId]) {
                this.handleToggle(TOGGLE.otherBolProfileId, true);
                this.setState({otherBolProfileId: ''})
            } else if (value !== OTHER_PROFILE_ID.value && toggle[TOGGLE.otherBolProfileId]) {
                this.handleToggle(TOGGLE.otherBolProfileId, false);
                this.setState({otherBolProfileId: ''})
            }
        };
        const isOther = toggle[TOGGLE.otherBolProfileId];
        const bolProfileId = bolProduct && bolProduct.bolProfileId;
        console.log("is other?");
        console.log(this.state);


        return (
            <div>


                <FormField className="form-group" id="bol" error={errors && errors.transactionalPayments}>
                    <Col xs={9} style={{paddingLeft: 0}}>
                        <label>Do you want to pay money out of this account/s? </label>
                    </Col>
                    <div className="input-group">
                        <div
                            className="inline-container" style={{marginLeft: 0}}
                            onClick={() => this.handleOptions(!(config && config.limits && config.limits.payments), 'payments')}>
                            <div className="icon-container">
                                <i className={((config && config.limits && config.limits.payments) ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                   style={{fontSize: 20}}/>
                            </div>
                        </div>
                    </div>
                </FormField>

                <FormField className="form-group" id="bol" error={errors && errors.transactionalPayments}>
                    <Col xs={9} style={{paddingLeft: 0}}>
                        <label>Do you want to collect money into this account/s? </label>
                    </Col>
                    <div className="input-group">
                        <div
                            className="inline-container" style={{marginLeft: 0}}
                            onClick={() => this.handleOptions(!(config && config.limits && config.limits.collections), 'collections')}>
                            <div className="icon-container">
                                <i className={((config && config.limits && config.limits.collections) ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                   style={{fontSize: 20}}/>
                            </div>
                        </div>
                    </div>
                </FormField>

                <FormField className="form-group" id="bol" error={errors && errors.transactionalPayments}>
                    <Col xs={12} style={{paddingLeft: 0}}>
                        <label>Transactional Limit</label>
                    </Col>
                    <div className="input-group">
                        <span className="input-group-addon">R</span>
                        <input
                            className="form-control"
                            id="bolProfileId"
                            onChange={(event) => {
                                errors && errors.transactionalPayments && resetErrors('transactionalPayments');
                                this.handleOnChangeLimits(event, 'transactionalPayments')
                            }}
                            type="number"
                            value={config && config.limits && config.limits.transactionalPayments || ''}
                        />
                        <span className="input-group-addon">.00</span>
                    </div>
                </FormField>

                <FormField className="form-group" id="bol" error={errors && errors.transactionalTransfers}>
                    <Col xs={12} style={{paddingLeft: 0}}>
                        <label>Interaccount Transactional Limit</label>
                    </Col>
                    <div className="input-group">
                        <span className="input-group-addon">R</span>
                        <input
                            className="form-control"
                            id="bolProfileId"
                            onChange={(event) => {
                                errors && errors.transactionalTransfers && resetErrors('transactionalTransfers');
                                this.handleOnChangeLimits(event, 'transactionalTransfers')
                            }}
                            type="number"
                            value={config && config.limits && config.limits.transactionalTransfers || ''}
                        />
                        <span className="input-group-addon">.00</span>
                    </div>
                </FormField>
                <FormField
                    error={errors && errors.bolProfileId ? (hasProfiles ? (isOther ? errors.bolProfileId : ["One Should Be Selected"]) : errors.bolProfileId) : ""}>
                    <label>Business Online Profile ID</label>
                    {
                        hasProfiles ?
                            <Select
                                name="bolProfileId"
                                onChange={(event) => {
                                    errors && errors.bolProfileId && resetErrors('bolProfileId');
                                    this.handleSelectUserChange(event);
                                    onToggle(event.value);
                                }}
                                options={_.union(profilesOptions, [OTHER_PROFILE_ID])}
                                value={isOther ? OTHER_PROFILE_ID.value : bolProfileId}
                            /> :
                            <input
                                className="form-control"
                                id="bolProfileId"
                                onChange={(event) => {
                                    errors && errors.bolProfileId && resetErrors('bolProfileId');
                                    this.handleOnChange(event, 'bolProfileId')
                                }}
                                type="text"
                                value={bolProfileId || ''}
                            />
                    }
                    {
                        isOther &&
                        <div>
                            <br/>
                            <input
                                className="form-control"
                                id="bolProfileId"
                                onChange={(event) => {
                                    errors && errors.bolProfileId && resetErrors('bolProfileId');
                                    this.handleOnChange(event, 'bolProfileId');
                                    this.setState({otherBolProfileId: event.value});
                                }}
                                type="text"
                                value={bolProfileId || ''}
                            />
                        </div>
                    }
                </FormField>
                <br/>

                {this._renderOperators()}
            </div>
        )
    }
}

export default BolComponent;