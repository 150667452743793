import React, {Component} from 'react';
import _ from 'lodash';
import {browserHistory} from 'react-router';

import {connect} from 'react-redux';
import {fetchProfiles, onChangeValue, saveRemediation} from '../../actions/legal-remidiation';
import {bindActionCreators} from 'redux';
import * as actions from "../../actions/entity";
import {ControlLabel, Label, Col, Popover, ToggleButton, ToggleButtonGroup, Panel, Tooltip} from 'react-bootstrap'
import {fetchAccounts} from "../../actions/kyc";
import AccountsList from "../accounts/AccountsList";
import AccountsComponent from "./components/AccountsComponent";
import Button from "react-bootstrap/es/Button";
import OverlayTrigger from "react-bootstrap/es/OverlayTrigger";
import ProfileComponent from "./components/ProfileComponent";
import EntitiesComponent from "./components/EntitiesComponent";
import {remediationRoutes} from "../../routers/routes";

class Legals extends Component {
    constructor(props) {
        super(props);
        this.renderTransactionalAccounts = this.renderTransactionalAccounts.bind(this);
        this.handleOnFilterTransactionalAccounts = this.handleOnFilterTransactionalAccounts.bind(this);
        this.handleAccountSelect = this.handleAccountSelect.bind(this);
        this.onProfileSelect = this.onProfileSelect.bind(this);
        this.onEntitySelect = this.onEntitySelect.bind(this);
        this.onAgreementChange = this.onAgreementChange.bind(this);
        this.onSaveRemediation = this.onSaveRemediation.bind(this);
        this._fetchProfiles = this._fetchProfiles.bind(this);
        this.state = {
            errors: {},
            filteredAccounts: null
        };
    }

    componentWillMount() {
        this._fetchProfiles();
    }

    _fetchProfiles() {
        const {actions, remediation: {entity: {cif}}} = this.props;

        actions.fetchProfiles(cif);
    }

    filterAccounts(searchTerm) {
        const {entityAccounts} = this.props;
        let filteredAccounts;
        if (searchTerm) {
            filteredAccounts = entityAccounts.filter((acc) => {
                return acc && (acc.accountNumber.includes(searchTerm) || acc.name && acc.name.toLowerCase().includes(searchTerm.toString().toLowerCase()));
            });
            console.log("filtered", filteredAccounts);
        }
        return filteredAccounts;
    }

    onEntitySelect(entityId) {
        this.setState({entityId: entityId, profileId: null});
    }

    onAgreementChange(agreement) {
        let {actions, remediation: {accounts, agreements}, entityAccounts} = this.props;
        const {entityId} = this.state;
        const remed = {};
        const {profile} = agreement;
        console.log(agreement);
        const profileAccounts = accounts[profile];
        const accountList = profileAccounts.map(account => entityAccounts[account]);
        remed[profile] = {...agreement, accounts: accountList};
        agreements = {...agreements, ...remed};

        actions.onChangeValue(agreements);
    }

    onProfileSelect(profileId) {
        this.setState({profileId});
    }

    onSaveRemediation() {
        const {actions, remediation: {id, agreements, entity}, history} = this.props;
        const onProceed = (status = true) => {
            if (status)
                history.push(remediationRoutes.complete);
        };
        actions.saveRemediation(id, {entity, agreements}, onProceed);
    }

    handleAccountSelect(selectedAccount) {
        const {actions} = this.props;
        console.log(selectedAccount.accountNumber);
        actions.fetchProfilesByAccount(selectedAccount.accountNumber);
        this.setState({selectedAccount: selectedAccount});
    }

    handleOnFilterTransactionalAccounts(searchTerm) {
        let filteredAccounts = this.filterAccounts(searchTerm);
        this.setState({filteredAccounts})
    }

    renderTransactionalAccounts() {
        const {entityAccounts, remediation} = this.props;
        const {selectedAccount, filteredAccounts} = this.state;
        return (
            <div className="form-horizontal">
                <ControlLabel>Accounts</ControlLabel>
                <AccountsList
                    accounts={entityAccounts}
                    filteredAccounts={filteredAccounts}
                    onFilterAccounts={this.handleOnFilterTransactionalAccounts}
                    onSelect={this.handleAccountSelect}
                    onSelectRadio={this.handleAccountSelect}
                    selectedAcc={selectedAccount}
                    isMultiSelect={false}
                    listStyle={{maxHeight: '63vh'}}
                    hideDate={true}
                />
            </div>
        );
    }

    render() {
        const {remediation, remediation: {profiles, agreements, entity: {registeredName}}, entityAccounts} = this.props;
        const divisions = profiles && profiles[this.state.entityId];
        const agreement = agreements ? agreements[this.state.profileId] ? agreements[this.state.profileId].agreement : null : null;
        if (remediation.entities && remediation.entities.length <= 0) {
            return (
                <div className="page-container">
                    <div className="page-main-section">
                        <div className="no-entities-message-card">
                            <h3 className="login_title">We could not find profiles for your entity's accounts!</h3>
                            <p>Please create a profile for the selected entity's account/s before attempting to update
                                your EBTC's
                                or choose a different entity.</p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="container-fluid">
                    <Col md={12} style={{marginTop: '3em'}}>
                        <div className='col-md-6'>
                            <h5>You are currently viewing the channel configuration for:</h5>
                            <h3>{registeredName}</h3>
                        </div>
                    </Col>
                    <div className="col-md-4" style={{maxHeight: '63vh', overflowY: 'scroll'}}>
                        {/*{this.renderTransactionalAccounts()}*/}
                        <h2>Entities - <small>as per Business Online</small></h2>
                        {
                            remediation.entities &&
                            <EntitiesComponent
                                entities={remediation.entities}
                                agreements={agreements}
                                profiles={profiles}
                                onEntitySelect={this.onEntitySelect}
                                selected={this.state.entityId}
                            />
                        }
                    </div>
                    <div className="col-md-4" style={{maxHeight: '63vh', overflowY: 'scroll'}}>

                        <h2>Profiles - <small>Participating on my accounts</small></h2>
                        {
                            this.state.entityId
                            &&
                            divisions.map((division) => {
                                const agreement = agreements ? agreements[division.code] ? agreements[division.code] : null : null;
                                    return (
                                        <ProfileComponent key={division.code}
                                                          selected={this.state.profileId}
                                                          division={division}
                                                          agreement={agreement}
                                                          onProfileSelect={this.onProfileSelect}
                                                          onAgreementChange={this.onAgreementChange}
                                        />
                                    )
                                }
                            )
                        }

                    </div>
                    <div className="col-md-4" style={{maxHeight: '63vh', overflowY: 'scroll'}}>
                        <h2>Account/s</h2>
                        {
                            this.state.profileId &&
                            <div>
                                <AccountsComponent accounts={remediation.accounts[this.state.profileId]}
                                                   entityAccounts={entityAccounts}
                                                   profile={this.state.profileId}
                                                   agreement={agreement}
                                                   onAgreementChange={this.onAgreementChange}/>
                            </div>
                        }
                    </div>
                    <Col md={12} style={{marginTop: '3em'}}>
                        <button className='btn btn-primary btn-lg' disabled={!agreements}
                                onClick={this.onSaveRemediation}>Save
                        </button>
                    </Col>

                </div>
            );
        }
    }


}


function mapStateToProps({remediation, accounts, impersonation, loading}) {
    const accountsObj = {};
    const hasAccounts = accounts && accounts.selectedEntityAccounts;
    if (hasAccounts) {
        accounts.selectedEntityAccounts.forEach(account => {
            let {accountNumber} = account;
            accountNumber = parseInt(accountNumber);
            accountsObj[accountNumber] = {name: account.name, number: account.accountNumber};
        });
    }
    return {
        remediation: remediation,
        entityAccounts: accountsObj,
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...actions,
            fetchAccounts,
            onChangeValue,
            fetchProfiles,
            saveRemediation
        }, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Legals);