import React from 'react';
import _ from "lodash";
import FormField from "../formField/FormField";

const LEVELS = ["", "A", "B"];

const AuthorisersList = (props) => {
    const {authorisers = [], onSelect} = props;
    return (
        <FormField style={{width: "100%"}} error={props.error}>
            <div className="section-title">
                Set Directors Level
            </div>

            <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-6"><h5>Director Names</h5></div>
                <div className="col-md-2 col-sm-2 col-xs-2 text-center"><h5>None</h5></div>
                <div className="col-md-2 col-sm-2 col-xs-2 text-center"><h5>Level A</h5></div>
                <div className="col-md-2 col-sm-2 col-xs-2 text-center"><h5>Level B</h5></div>
            </div>

            <div className="rules-container">
                {_.map(authorisers, (d, i) => {
                        return (
                            <div className="row auth-list-item" key={d.idNumber}>
                                <div
                                    className="col-md-6 col-sm-6 col-xs-6"
                                    style={{padding: 0}}>{`${i + 1}. ${d.firstName} ${d.surname}`}
                                </div>
                                <fieldset className="row" style={{maxHeight: '43vh', justifyContent: "center"}}
                                          name={`levels-${d.idNumber}`}>
                                    {
                                        _.map(LEVELS, level => (
                                            <div
                                                className="col-md-4 col-sm-4 col-xs-4"
                                                style={{textAlign: "center"}}
                                                onClick={() => onSelect(d.idNumber, level)}
                                                key={`level/${d.idNumber}/${level}`}
                                            >
                                                <i
                                                    className={(d.authLevel === level) ? "fa fa-check-circle" : "fa fa-circle-o"}
                                                    style={{color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10}}
                                                />
                                            </div>
                                        ))}
                                </fieldset>
                            </div>
                        )
                    }
                )}
            </div>
        </FormField>
    )
}
export default AuthorisersList