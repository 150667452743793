import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function businessOnline(state = initialState.businessOnline, action) {

    switch (action.type) {
        case types.ON_BOL_VALUE_CHANGE:
            let {bolRequest} = state;
            const request = {[action.objName]: _.extend({}, state.bolRequest[action.objName], action.attribute ? {[action.attribute]: action.value} : action.value)};
            bolRequest = {...bolRequest, ...request};
            return {
                ...state,
                bolRequest
            };
        case types.START_BOL_ONBOARD_SUCCESS:
            return {...action.businessOnline};
        case types.ONBOARING_DOC:
            return {...state, document: action.documentStr};
        default:
            return {...state};
    }

}