import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Products.scss';
import {managedFundRoutes} from '../../routers/routes';


const ManagedFundTiles = () => {
    return (
        <div className='row'>
            <div className='col-md-8'>
                <div style={{ display:'flex', alignSelf:'start'}}>
                    <div className="product-btn">
                        <div className="product-heading">
                            Cash Account
                        </div>
                        <div className="title-gradient" />
                        <p style={{
                            paddingTop: '3px',
                            paddingBottom: '3px',
                            fontSize: '12px',
                            color: '#858D9D',
                            textTransform:'none'
                        }}>Submit a manage fund application for a Business Current Account</p>

                        <div className="flexRow" style={{ alignItems: 'flex-end', flex:'auto', margin: 0, padding: 0}}>
                            <div style={{ flex: 1, alignItems: 'flex-end'}} />
                            <Link to={managedFundRoutes.cashAccount} className="flat-button-primary" style={{ padding: 0 }} role="button">
                                <span>Apply Now <i className="fa fa-angle-right fa-lg" /> </span>
                                {/* <span style={{color:'grey' }}> Coming soon <i className="fa fa-angle-right fa-lg" /> </span> */}
                            </Link>
                        </div>
                    </div>

                    <div className="product-btn">
                        <div className="product-heading">
                            Cash & Custody Account
                        </div>
                        <div className="title-gradient" />
                        <p style={{
                            paddingTop: '3px',
                            paddingBottom: '3px',
                            fontSize: '12px',
                            color: '#858D9D',
                            textTransform:'none'
                        }}>Submit a manage fund consolidated application for a Business Current Account and Custody Account</p>

                    
                        
                        <div className="flexRow" style={{ alignItems: 'flex-end', flex:'auto', margin: 0, padding: 0}}>
                            <div style={{ flex:1}} />
                            <Link to={managedFundRoutes.cashAndCustodyAccount} className="flat-button-primary" style={{ padding: 0 }} role="button">
                                <span>Apply Now <i className="fa fa-angle-right fa-lg" /> </span>
                            </Link>
                        </div>
                    </div>

                    <div className="product-btn">
                        <div className="product-heading">
                            Custody Account
                        </div>
                        <div className="title-gradient" />
                        <p style={{
                            paddingTop: '3px',
                            paddingBottom: '3px',
                            fontSize: '12px',
                            color: '#858D9D',
                            textTransform:'none'
                        }}>Submit a manage fund application for a Custody Account</p>

                    
                        <div className="flexRow" style={{ alignItems: 'flex-end', flex:'auto', margin: 0, padding: 0 }}>
                            <div style={{ flex: 1}} />
                            <Link to={managedFundRoutes.custodyAccount} className="flat-button-primary"  role="button">
                                <span>Apply Now <i className="fa fa-angle-right fa-lg" /> </span>
                            </Link>
                        </div>
                    </div>
                   
                </div>
            </div>
            <div className='col-md-4'></div>
        </div>
            
    );
};
export default ManagedFundTiles;