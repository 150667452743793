import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function individualReducer(state = initialState.individual, action) {
    const attribute = action.individualType ? action.individualType : 'individual';

    switch (action.type) {
        case types.FECTH_INDIVIDUAL_SUCCESS:
            return {...state, [attribute]: action.individual};

        case types.CLEAR_INDIVIDUAL_INFO:
            return {...state, [attribute]: {}};
            
        default:
            return state;
    }
}