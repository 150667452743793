import * as types from '../actions/actionTypes';

export default function delegatesReducer(state = {}, action) {
    switch (action.type) {
        case types.START_CREDIT_APPLICATION:
            return {...state, creditApplication: action.creditApplication};

        case types.CLEAR_CREDIT_APPLICATION:
            return {...state, creditApplication: null};
        case types.CREDIT_APPLICATION_COMPLETE_SUCCESS:
            return {...state, creditApplication: action.creditApplication};
        default:
            return state;
    }
}
