import React, { useState } from 'react';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import Table from 'react-bootstrap/lib/Table';
import Button from 'react-bootstrap/lib/Button';
import { useDispatch, useSelector } from 'react-redux';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Row from 'react-bootstrap/lib/Row';
import Glyphicon from 'react-bootstrap/lib/Glyphicon';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import SearchComponent from '../SearchComponent';
import { fetchIndiviualInfo } from '../../actions/kyc';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import Alert from 'react-bootstrap/lib/Alert';
import { FormGroup } from 'react-bootstrap';

const AdditionalQuestions = (props) => {
    const dispatch = useDispatch();
    const [newPipSwitch, setNewPipSwitch] = useState(false);
    const [searching, setSearching] = useState(false);
    const [pipError, setPipError] = useState(false);
    const [searchError, setSearchErrors] = useState(false);
    const [controllerError, setControllerError] = useState(false);
    const [newPip, setNewPip] = useState({
        name: '',
        relationship: '',
        official: ''
    });
    const [newController, setNewController] = useState({
        name: '',
        identityNumber: ''
    });
    const [newControllerSwitch, setNewControllerSwitch] = useState(false);
    const controllers = useSelector(
        state => state.kyc && state.kyc.kycRequest.controllers ? state.kyc.kycRequest.controllers : []);
    const pips = useSelector(state => state.kyc && state.kyc.kycRequest.pips ? state.kyc.kycRequest.pips : []);
    const pip = useSelector(state => state.kyc && state.kyc.kycRequest.pip);
    const individual = useSelector(state => state.individual && state.individual.individual);

    const newPipChange = (item, change) => {
        const newPipState = newPip;
        newPipState[item] = change;
        setNewPip(newPipState);
    };
    const newControllerChange = (item, change) => {
        const newControllerState = newController;
        newControllerState[item] = change;
        setNewController(newControllerState);
    };
    const newSearchChange = ({ target }) => {
        setNewController({ ...newController, identityNumber: target.value });
    };

    const newPipCommit = () => {
        if (_.isEmpty(newPip.name) || _.isEmpty(newPip.relationship)) {
            setPipError(true);
        } else {
            const { onPipsUpdate } = props;
            pips.push(newPip);
            onPipsUpdate(pips);
            resetNewPip();
        }
    };

    const resetNewPip = () => {
        setNewPip({
            name: '',
            relationship: '',
            publicOfficial: null
        });
        setPipError(false);
        setNewPipSwitch(false);
    };
    const newControllerCommit = () => {
        if (_.isEmpty(newController.name) || _.isEmpty(newController.identityNumber)) {
            setControllerError(true);
        } else {
            const { onControllerUpdate } = props;
            controllers.push(newController);
            onControllerUpdate(controllers);
            resetNewController();
        }
    };

    const removeController = (item) => {
        const { onControllerUpdate } = props;
        const updatedControllers = [...controllers];
        updatedControllers.splice(item, 1);
        onControllerUpdate(updatedControllers);
    };
   
    const removePip = ( item) => {
        const { onPipsUpdate } = props;
        const updatedPips = [...pips];
        updatedPips.splice(item, 1);
        onPipsUpdate(updatedPips);
    };

    const resetNewController = () => {
        setNewController({
            name: '',
            identityNumber: ''
        });
        setControllerError(false);
        setNewControllerSwitch(false);
    };

    const onPipUpdate = (change) => {
        const { onPipChange } = props;
        onPipChange(change);
    };

    const controllerPop = (
        <Popover id="controller-pop" title="Exercising Control over the Entity">
            This can be persons exercising control through voting rights attaching to different
            classes of shares or through shareholder agreements or who have the ability to
            effectively manage, direct and control the entity.
        </Popover>
    );

    const seniorManagement = (
        <Popover id="senior-pop" title="The Senior Management Officials ">
            <span>• Refers to the natural person(s) who exercises control over the daily business management
                activities of the legal entity, i.e. the ‘C’ level management. E.g. CEO, CFO, CIO, CCO etc.
                This does not include the Board.</span>
        </Popover>
    );
    const checkID = () => {
        return !(newController.identityNumber && newController.identityNumber.length === 13);
    };
    const onIndividualSearch = () => {
        // this.loadingStart();
        setSearching(true);
        setSearchErrors(false);
        const onFinish = (status, finish) => {
            if (status) {
                newController.name = `${finish.firstName} ${finish.lastName}`;
                setNewController({ ...newController });
                setSearching(false);
            }
        };
        const onError = () => {
            // this.setState({isLoading: false});
            setSearching(false);
            setSearchErrors({ registrationNumber: 'Individual not found on search.' });
        };
        dispatch(fetchIndiviualInfo(newController.identityNumber, onFinish, null, onError));
    };

    return (
        <div className={'col-md-12'}>
            <div className="card-container-form" style={{padding:0}}>
                <div className="section-title">
                    <span>We need to verify who is the Controller or Senior Management Officials of this entity &nbsp;
                        <OverlayTrigger trigger={['hover', 'focus']} placement={'top'} overlay={controllerPop}>
                            <span>
                                <Glyphicon id={'controller-help'} glyph={'question-sign'} />
                            </span>
                        </OverlayTrigger> :</span>
                </div>
               
                <Table stripped hover>
                    <thead>
                        <tr className='bg-primary' style={{fontSize:'1em'}}>
                            <th>Identity Number </th>
                            <th>Company Related Party Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {
                            controllers && controllers.map((controller, i) =>
                                <tr key={i} style={{fontSize:'1em'}} className='bg-info'>
                                    <td>{controller.identityNumber}</td>
                                    <td>{controller.name}</td>
                                    <td><Button className="btn btn-sm" style={{width:'auto', margin:'0', padding:'0 5px'}} bsStyle={'danger'}
                                        onClick={() => removeController(i)}>Delete</Button></td>
                                </tr>
                            )
                        }
                    </tbody>
                </Table>
                <div className="row" style={{ margin: 0 }}>
                    {controllerError &&
                    <div style={{ padding: "5px",}} >
                         <span className="form-error"> <strong>Complete</strong> all the details before saving.</span>
                    </div>

                    }
                    {
                        newControllerSwitch &&
                        <div className="card-container-form" style={{padding:0}}>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-xs-12">
                                    <div className="form-group">
                                    <label htmlFor="">Identity Number</label>
                                    <InputGroup className={'form-control'} style={{border:0, padding:0}}>
                                        <input type="text" name="idNumber" onChange={newSearchChange} className="form-control" />
                                        <InputGroup.Button >
                                            <Button disabled={checkID() || searching} style={{ width: 'auto' }} bsStyle={'primary'}
                                                onClick={onIndividualSearch}>{searching ? 'Searching' : 'Search'}</Button>
                                        </InputGroup.Button>
                                        {searchError &&
                                            <div>
                                                <span className="form-error">{searchError.registrationNumber}</span>
                                            </div>
                                        }
                                    </InputGroup>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-xs-12">
                                    <div className="form-group">
                                        <label htmlFor="">Company Related Party Name</label>
                                        <input className={'form-control'} type={'text'} id={'controllerName'}
                                            defaultValue={newController.name} disabled={true}
                                            onChange={({ target }) => newControllerChange('name', target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{margin:0}}>
                                <ButtonGroup style={{float:'right'}}>
                                    <Button className="btn btn-sm" style={{width:'auto', margin:'0 5px'}} onClick={resetNewController} bsStyle={'danger'}>Cancel</Button>
                                    <Button className="btn btn-sm" style={{width:'auto', margin:'0 5px'}} onClick={newControllerCommit}>Save</Button>
                                </ButtonGroup>
                            </div>
                        </div>
                    }
                </div>
                <div className="row" style={{ margin: 0 }}>
                    <Button disabled={newControllerSwitch} style={{width:'auto'}}  bsStyle={'primary'} onClick={() => setNewControllerSwitch(true)}>Add
                        Person</Button>
                </div>
            </div>
            {/*PIP*/}
            <hr />
            <div className="card-container-form" style={{padding:0}}>
                <Row>
                    <div className={'col-md-12'}>
                        <div className="section-title">
                            <span>Are any of the authorised/controlling persons either a public official
                                in a position of authority or related/associated to a public official
                                in a position of authority?</span>
                        </div>
                    </div>
                    <div className={'col-md-12'}>
                        <ToggleButtonGroup type="radio" name="pip" defaultValue={pip}
                            onChange={onPipUpdate}>
                            <ToggleButton value={true}>Yes</ToggleButton>
                            <ToggleButton value={false}>No</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </Row>
                <br />
                {pip &&
                    <div className="row" style={{margin:0}}>
                       
                        <div className="row" style={{margin:0}}>
                            <Table stripped hover>
                                <thead>
                                    <tr className='bg-primary' style={{fontSize:'1em'}}>
                                        <th>Company Related Party Name</th>
                                        <th>Relationship or Association</th>
                                        <th>Public Official</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        pips && pips.map((pipL, i) =>
                                            <tr key={i} style={{fontSize:'1em'}} className='bg-info' >
                                                <td>{pipL.name}</td>
                                                <td>{pipL.relationship}</td>
                                                <td>{pipL.official}</td>
                                                <td><Button className="btn btn-sm" style={{width:'auto', margin:'0', padding:'0 5px'}} bsStyle={'danger'}
                                                    onClick={() => removePip(i)}>Delete</Button></td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>

                            <hr />
                            {pipError &&
                                <div style={{ padding: "5px",}} >
                                    <span className="form-error"> <strong>Complete</strong> all the details before saving.</span>
                                </div>
                            }
                            {
                                newPipSwitch &&
                                <div className="card-container-form" style={{padding:0}} >

                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Company Related Party Name</label>
                                                <input className={'form-control'} type={'text'} id={'pipName'}
                                                    defaultValue={newPip.name}
                                                    onChange={({ target }) => newPipChange('name', target.value)} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-xs-12">
                                            <FormGroup className="form-group">
                                                <label htmlFor="">Relationship or Association</label>
                                                <select className={'form-control'} type={'text'} id={'pipRelationShip'}
                                                    defaultValue={newPip.relationship}
                                                    onChange={({ target }) =>
                                                        newPipChange('relationship', target.value)}>
                                                    <option value={''}>--SELECT--</option>
                                                    <option>Spouse/partner</option>
                                                    <option>Son/daughter</option>
                                                    <option>Parent</option>
                                                    <option>Sibling</option>
                                                    <option>Business partner</option>
                                                    <option>Close associate</option>
                                                </select>
                                            </FormGroup>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-xs-12">
                                            <div className="form-group">
                                                <label htmlFor="">Public Official</label>
                                                <input className={'form-control'} type={'text'} id={'pipName'}
                                                defaultValue={newPip.official}
                                                onChange={({ target }) => newPipChange('official', target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <ButtonGroup style={{float:'right'}}>
                                        <Button className="btn btn-sm" style={{width:'auto', margin:'0 5px'}} onClick={resetNewPip} bsStyle={'danger'}>Cancel</Button>
                                        <Button className="btn btn-sm" style={{width:'auto', margin:'0 5px'}} onClick={newPipCommit}>Save</Button>
                                    </ButtonGroup>

                                </div>

                            }
                        </div>
                        <div className="row" style={{margin:0}}>
                            <Button disabled={newPipSwitch} style={{width:'auto'}} bsStyle={'primary'} onClick={() => setNewPipSwitch(true)}>Add Person</Button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default AdditionalQuestions;
