import React from 'react';

import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Nav, Navbar, Modal} from "react-bootstrap";

import "../styles/App.scss";
import logo from '../resources/standardbank_logo_42x42.png';
import * as actions from '../actions/delegation';
import ImpersonationCandidate from './delegation/ImpersonationCandidate';
import {loadAnnouncement} from '../actions/announcement';
import AnnouncementPopup from './announcement';
import ClientSupportPage from './support/ClientSupportPage';
import UserMenu from './UserMenu';
import Popup from "./Popup";
import ImpersonationRequiredMessage from "./delegation/ImpersonationRequiredMessage";
import _ from "lodash";
import ReactLoading from "react-loading";
import {loadImportantNotices} from '../actions/importantNotice';

const STAFF_PERMISSION = {
    provLDAP: "PROV_LDAP"
};

class NavBar extends React.Component {
    state = {
        showImpersonationCandidate: false,
        showSupport: false,
        showSBStaffUserMessage: true,
        isSBStaff: false,
        announcementPopup: false,
    };

    
    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.user) {
            const {user} = nextProps;
            const isSBStaff = _.find(user && user.permissions, perm => (perm === STAFF_PERMISSION.provLDAP));
            this.setState({isSBStaff: isSBStaff && true});
        }
    }
    
    componentDidMount() {
        const flagToken = localStorage.getItem('flag');
        if (flagToken == "true") {
            this.setState({announcementPopup:true});
            localStorage.setItem('flag', false);
        }
    }
    logout = (e) => {
        e.preventDefault();
        this.setState({announcementPopup:false});
        this.props.history.replace('/logout');
    };

    actOnBehalfOf = (e, impersonation) => {
        e.preventDefault();
        const {actions} = this.props;
        actions.startImpersonation(impersonation);

        if (window.location.pathname !== '/' || window.location.pathname !== '/#') {
            this.props.history.replace('/');
        }
    };

    cancelImpersonation = (e, impersonation) => {
        e.preventDefault();
        this.props.actions.cancelImpersonation(impersonation);
        if (window.location.pathname !== '/' || window.location.pathname !== '/#') {
            this.props.history.replace('/');
        }
    };

    onHandleToggleImpersonationCandidatePopup = () => {
        this.setState(prevState => ({showImpersonationCandidate: !(prevState && prevState.showImpersonationCandidate)}))
    };

    onHandleToggleSupportPopup = () => {
        this.setState(prevState => ({showSupport: !(prevState && prevState.showSupport)}))
    };

    handleToggleSBStaffUserMessage = () => {
        this.setState(prevState => ({showSBStaffUserMessage: !prevState.showSBStaffUserMessage}))
    };

    announcementPopupToggle =()=>{
        this.setState(prevState => ({announcementPopup: !prevState.announcementPopup}));
    }

    handleAllowUserToProceed = () => {
        const {impersonation} = this.props;
        const {isSBStaff} = this.state;
        const canProceed = isSBStaff ? impersonation && (impersonation.username || impersonation.firstName) : true;
        if (isSBStaff && !canProceed) {
            this.handleToggleSBStaffUserMessage();
        }
        return canProceed;
    };


    handleImpersonation = (idNumber,onFinish) => {
        const {actions, impersonationList} = this.props;
        const  onComplete= () => {
            this.onHandleToggleImpersonationCandidatePopup();
            if (window.location.pathname !== '/' || window.location.pathname !== '/#') {
                this.props.history.replace('/');
            }
        };
        actions && actions.startImpersonationsStaffMemberOnly(idNumber, impersonationList, onComplete, onFinish);
    };

    

    handleOnSelectAnnouncement = (announcementRoute) => {
        if (this.handleAllowUserToProceed()) {
            // localStorage.setItem('routeAnnouncementToHome', announcementRoute);
            const {history} = this.props;
            this.announcementPopupToggle();
            history && history.push(announcementRoute);
        }
    };

    _renderImpersonationCandidate() {
        const {impersonation} = this.props;
        return (
            <Modal show={true} onHide={this.onHandleToggleImpersonationCandidatePopup}>
                <Modal.Body>
                    <ImpersonationCandidate
                        impersonation={impersonation}
                        onImpersonate={this.handleImpersonation}
                    />
                </Modal.Body>
            </Modal>
        )
    }

    _renderAnnouncementPopup(){
        const {announcements} = this.props;
        return(
            <Modal show={this.state.announcementPopup} onHide={() => this.setState({announcementPopup: false})} >
                <Modal.Header>
                    <h4 className={'text-center'}>
                        <span className={'text-danger'} style={{fontSize:"23px", fontWeight:'700'}}> Announcements</span>
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <AnnouncementPopup 
                        products={this.props}
                        announcements={this.props.announcements}
                        selectedAnnouncement={this.handleOnSelectAnnouncement} 
                    />
                </Modal.Body>
            </Modal>
        )
    }

    _renderSupportPage() {
        const {application} = this.props;
        return (
            <Modal show={true} container={this} onHide={this.onHandleToggleSupportPopup} bsSize={'lg'}>
                <Modal.Body>
                    <ClientSupportPage
                        close={this.onHandleToggleSupportPopup}
                    />
                </Modal.Body>
            </Modal>
        );
    }

    _renderSBStaffMessage() {
        return (
            <ImpersonationRequiredMessage
                onClose={this.handleToggleSBStaffUserMessage}
            />
        )
    }

    render() {
        const {impersonation, impersonationList, user,history, importantNotices} = this.props;
        const {isSBStaff, showImpersonationCandidate, showSupport, showSBStaffUserMessage} = this.state;
        let rulesEnabled = true;
        if (impersonation && impersonation.rulesEnabled === false) {
            rulesEnabled = false;
        }
        if(user && user.username) {
            return (
                <nav className="navbar nav-container" style={{margin:'0', borderRadius:'0'}}>
                    {/* <div id="alertBanner" style={{
                                padding: '15px',
                                fontSize: '15px',
                                textAlign: 'center',
                                backgroundColor: 'rgb(208, 2, 27)',
                                color: 'rgb(255, 255, 255)'
                            }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12"><strong>Important notice</strong>: Visit our &nbsp;
                                    <a href="http://www.standardbank.co.za/southafrica/personal/campaigns/covid-19?intcmp=coza-sitewide-alertbanner-personal-covid19site"
                                       target="_self"
                                       style={{
                                           textDecoration: 'underline',
                                           color: '#FFF',
                                           display: 'inline-block',
                                           fontWeight: 'normal',
                                           fontFamily: '-apple-system,BlinkMacSystemFont,\'Segoe UI\',Roboto,\'Helvetica Neue\',Arial,sans-serif,\'Apple Color Emoji\',\'Segoe UI Emoji\',\'Segoe UI Symbol\' '
                                       }}
                                       data-id="link_content_alert banner"
                                       data-text="campaign page | personal | covid-19 site link click"
                                       className="link link--white"> COVID-19 site </a> for latest information regarding how we
                                    can support you. For up to date information about the pandemic visit
                                    <a href="https://sacoronavirus.co.za" target="_blank"
                                       style={{
                                           textDecoration: 'underline',
                                           color: '#FFF',
                                           display: 'inline-block',
                                           fontWeight: 'normal',
                                           fontFamily: '-apple-system,BlinkMacSystemFont,\'Segoe UI\',Roboto,\'Helvetica Neue\',Arial,sans-serif,\'Apple Color Emoji\',\'Segoe UI Emoji\',\'Segoe UI Symbol\' '
                                       }}
                                       data-id="link_content_alert banner"
                                       data-text="campaign page | cover-19 site link click"
                                       className="link link--white">www.sacoronavirus.co.za</a>.
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {importantNotices
                        .filter(notice => notice.alertType === 'WARNING' && notice.active == true)
                        .map((notice, index) => (
                            <div id="alertBanner" key={index} style={{
                                padding: '10px',
                                fontSize: '15px',
                                textAlign: 'center',
                                backgroundColor: 'rgb(208, 2, 27)',
                                color: 'rgb(255, 255, 255)'
                            }}>
                                <div className="row" style={{margin:0}}>
                                    <div className="col-md-12" style={{padding:0}}>
                                        <p style={{ textAlign:'justify', margin:'0px 15px' }}>
                                           <strong>{ notice.title } - </strong>
                                           <span dangerouslySetInnerHTML={{ __html: notice.message }}></span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    <div className="row">
                        <div className="navbar-header  col-md-6 col-sm-6 col-xs-6">
                            <Link className="navbar-brand" to="/">
                                <img src={logo} className="img-fluid" alt="logo"/>
                            </Link>
                        </div>

                        <div className="col-md-6 col-sm-6 col-xs-6">
                            <ul className="nav navbar-nav navbar-right pull-right" >
                                <Navbar.Collapse>
                                    <Nav pullRight>
                                        <UserMenu
                                            impersonation={impersonation}
                                            impersonationList={impersonationList}
                                            user={user}
                                            rulesEnabled={rulesEnabled}
                                            onActOnBehalfOf={this.actOnBehalfOf}
                                            onShowImpersonationPopUp={this.onHandleToggleImpersonationCandidatePopup}
                                            onShowSupportPopUp={this.onHandleToggleSupportPopup}
                                            onCancelImpersonation={this.cancelImpersonation}
                                            isSBStaff={isSBStaff}
                                            onAllowUserToProceed={this.handleAllowUserToProceed}
                                            logout = {this.logout}
                                            history={history}
                                        />
                                    </Nav>
                                </Navbar.Collapse>
                            </ul>

                            <button className="btn-announcement pull-right" style={{ padding:"8px 10px", width:"auto"}} title='Announcement' onClick={this.announcementPopupToggle}>  
                                Announcements
                                <i className='fa fa-bullhorn' style={{padding:'10px'}}></i>
                            </button>
                           
                        </div>
                    </div>
                    {showImpersonationCandidate && this._renderImpersonationCandidate()}
                    {showSupport && this._renderSupportPage()}
                    {!showSBStaffUserMessage && this._renderSBStaffMessage()}
                    {this.props.announcements.length > 0 && this._renderAnnouncementPopup()}
                </nav>
            );
        } else {
            return (
                <div>
                </div>
            )
        }
    }
}

function mapStateToProps({impersonation, announcement, importantNotice, impersonationList, user, tracking}, ownProps) {
    return {
        loginStatus: tracking.loginStatus === 'Logged in' ? true : false,
        user: user && user.user ? user.user : {},
        announcements: announcement && announcement.announcements ? announcement.announcements : [],
        importantNotices: importantNotice && importantNotice.importantNotices ? importantNotice.importantNotices : [],
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        impersonationList: impersonationList && impersonationList.impersonationList ? impersonationList.impersonationList : [],
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, loadAnnouncement, loadImportantNotices}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
