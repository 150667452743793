import React from 'react'
import _ from 'lodash'

const ErrorLabel = ({error})=> (
    <div style={{margin: '0px 15px'}}>
       <span className="form-error">{error}</span>
    </div>
);

const FormField = ({children,className, error, style})=> (
    <div className={className ? className : ''} style={style? style : {}}>
        {children}
        <div style={{height: "auto", width: '100%',textAlign: 'left',margin: '0px 0px', float:'left'}}>
        {
            error && _.map(error, (err,i) => ( i == 0 && <ErrorLabel key={`error-${i}`} error={err}/>))
        }
        </div>
    </div>
);

export default FormField;