import React from 'react';

const Footer = () => (
    <div className="footer-container">
        <div className="footer-main-section">
            <div className="flexColumn" style={{alignItems:"flex-start"}}>
                <div className="section-title" style={{color: "white"}}>
                    <span>Contact Us</span>
                </div>
                {/* <span >Call Help Desk : 0800 776 228 (8am - 5pm)</span> */}
                {/* <span >E-mail address: CorporateBankingFrontline@standardbank.co.za</span> */}
                <span> E-mail address : serviceonlineapplication@standardbank.co.za </span>
            </div>
            <div/>
        </div>
    </div>
);

export default Footer;