import {combineReducers} from 'redux';
import productReducer from './productReducer';
import entityReducer from './entityReducer';
import userReducer from './userReducer';
import applicationReducer from './applicationReducer';
import userApplicationsReducer from './userApplicationsReducer';
import userPendingApplicationsReducer from './userPendingApplicationsReducer';
import newEntityDetailsReducer from './new_entity_details_reducer';
import accountsReducer from './accountsReducer';
import signatoriesReducer from './signatoriesReducer';
import errorReducer from './errorReducer';
import systemErrorReducer from './systemErrorReducer';
import accountsEmptyReducer from './accountsEmptyReducer';
import loadingReducer from './loadingReducer';
import individualReducer from './individualReducer';
import authorisersReducer from './authorisersReducer';
import authRulesReducer from './authRulesReducer';
import delegatesReducer from './delegatesReducer';
import impersonationReducer from './impersonationReducer';
import impersonationListReducer from './impersonationListReducer';
import searchEntityReducer from './searchEntityReducer';
import bolReducer from './bolReducer';
import digitalCreditReducer from './digitalCreditReducer';
import custodyReducer from './custodyReducer';
import kycReducer from './kycReducer';
import globalMarketReducer from './globalMarketReducer';
import supportReducer from './supportReducer';
import businessOnlineReducer from './businessOnlineReducer';
import remediationReducer from './legal_remediation_reducer';
import tpfaReducer from './tpfaReducer';
import trackingReducer from './trackingReducer';
import signatoriesMaintenanceReducer from './signatoriesMaintenanceReducer';
import cashManagedFundReducer from "./cashManagedFundReducer";
import custodyManagedFundReducer from "./custodyManagedFundReducer";
import cashCustodyManagedFundReducer from "./cashCustodyManagedFundReducer";
import electronicIndemnityReducer from "./electronicIndemnityReducer";
import announcementReducer from "./announcementReducer";
import signatoryReducer from "./signatoriesForDashboardReducer"
import importantNoticeReducer from './importantNoticeReducer';

const rootReducer = combineReducers({
    product: productReducer,
    entity: entityReducer,
    user: userReducer,
    application: applicationReducer,
    applications: userApplicationsReducer,
    newEntityDetails: newEntityDetailsReducer,
    accounts: accountsReducer,
    accountsEmpty: accountsEmptyReducer,
    copies: signatoriesReducer,
    error: errorReducer,
    systemError: systemErrorReducer,
    loading: loadingReducer,
    individual: individualReducer,
    authorisers: authorisersReducer,
    authRules: authRulesReducer,
    delegatedList: delegatesReducer,
    impersonation: impersonationReducer,
    impersonationList: impersonationListReducer,
    entityInfo: searchEntityReducer,
    bol: bolReducer,
    digitalCredit: digitalCreditReducer,
    custody: custodyReducer,
    kyc: kycReducer,
    globalMarket: globalMarketReducer,
    support: supportReducer,
    remediation: remediationReducer,
    businessOnline: businessOnlineReducer,
    tpfa: tpfaReducer,
    tracking: trackingReducer,
    signatoriesMaintenance: signatoriesMaintenanceReducer,
    cashManagedFund: cashManagedFundReducer,
    custodyManagedFund: custodyManagedFundReducer,
    cashCustodyManagedFund: cashCustodyManagedFundReducer,
    electronicIndemnity:electronicIndemnityReducer,
    announcement:announcementReducer,
    signatory: signatoryReducer,
    importantNotice: importantNoticeReducer,
    pandingApplications : userPendingApplicationsReducer
});

export default rootReducer;
