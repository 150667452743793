import axios from 'axios';
import _ from 'lodash';
import * as types from './actionTypes';
import {API_ROOT} from "../api-config";
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from "./appLoading";
import {globalMarketAccountRoutes} from '../routers/routes'

const GLOBAL_MARKET_URL = API_ROOT + '/api/global-market';
const CUSTOMERS_URL = API_ROOT + '/api/osd-customers';
const ACCOUNTS_URL = API_ROOT + '/api/osd-accounts/';

export function update (application, onProceed = null) {
    return async (dispatch) => {
        try {
            dispatch({type: types.GM_UPDATE, application});
            onProceed && onProceed();
        } catch (error) {
            console.log("fetchSelectCustomer.error ===> ", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchCustomers (cif, application, onProceed = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);

            /*axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            let responseBody = await axios.get(CUSTOMERS_URL + "?=" + cif).then(response => response.data);

            if (responseBody.success) {
                application.customers = responseBody.data;
            }
            else {
                application.customers = [];
                application.errorMessage = responseBody.message;
            }*/


            application.customers = [
                {
                  "id": 249035,
                  "customerShortName": "LAZARD 20224",
                  "createDate": "2017-08-17 00:00:00",
                  "effectiveDate": "2017-08-17 00:00:00",
                  "closingDate": null,
                  "closingReasonCode": null,
                  "cif": 532172304,
                  "accounts": [
                    {
                      "id": 6016327,
                      "customerNumber": 249035,
                      "systemCode": "BSRE",
                      "sourceSystemSubscriptionId": "249035",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2017-08-17 16:10:31"
                    },
                    {
                      "id": 6016332,
                      "customerNumber": 249035,
                      "systemCode": "MXMRq",
                      "sourceSystemSubscriptionId": "249035",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2017-08-17 16:10:59"
                    },
                    {
                      "id": 6491406,
                      "customerNumber": 249035,
                      "systemCode": "CDPL",
                      "sourceSystemSubscriptionId": "249035",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CDPL_TAKEON",
                      "authorisingDate": "2018-08-14 17:14:03"
                    },
                    {
                      "id": 6016290,
                      "customerNumber": 249035,
                      "systemCode": "CIF",
                      "sourceSystemSubscriptionId": "532172304",
                      "existsOnSourceSystem": true,
                      "authorisingUser": "BONFRISB",
                      "authorisingDate": "2017-08-17 15:42:26"
                    },
                    {
                      "id": 6016323,
                      "customerNumber": 249035,
                      "systemCode": "CPSO",
                      "sourceSystemSubscriptionId": "584180",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2017-08-17 16:11:04"
                    },
                    {
                      "id": 6016324,
                      "customerNumber": 249035,
                      "systemCode": "FCLI",
                      "sourceSystemSubscriptionId": "35170",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2017-08-17 16:11:07"
                    },
                    {
                      "id": 6016331,
                      "customerNumber": 249035,
                      "systemCode": "FCSH",
                      "sourceSystemSubscriptionId": "848667",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2017-08-17 16:11:10"
                    },
                    {
                      "id": 6016325,
                      "customerNumber": 249035,
                      "systemCode": "FNET",
                      "sourceSystemSubscriptionId": "95309",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2017-08-17 16:11:14"
                    },
                    {
                      "id": 6016321,
                      "customerNumber": 249035,
                      "systemCode": "FRM",
                      "sourceSystemSubscriptionId": "249035",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2017-08-17 16:11:17"
                    },
                    {
                      "id": 6016322,
                      "customerNumber": 249035,
                      "systemCode": "GLBS",
                      "sourceSystemSubscriptionId": "168952",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2017-08-17 16:11:21"
                    },
                    {
                      "id": 6016326,
                      "customerNumber": 249035,
                      "systemCode": "LDOC",
                      "sourceSystemSubscriptionId": "27143",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2017-08-17 16:11:24"
                    },
                    {
                      "id": 6016329,
                      "customerNumber": 249035,
                      "systemCode": "MEQD",
                      "sourceSystemSubscriptionId": "22023",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2017-08-17 16:11:27"
                    },
                    {
                      "id": 6125833,
                      "customerNumber": 249035,
                      "systemCode": "MXCO",
                      "sourceSystemSubscriptionId": "36956",
                      "existsOnSourceSystem": true,
                      "authorisingUser": "CI",
                      "authorisingDate": "2017-10-27 00:00:00"
                    },
                    {
                      "id": 6016328,
                      "customerNumber": 249035,
                      "systemCode": "MXLN",
                      "sourceSystemSubscriptionId": "36956",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2017-08-17 16:11:31"
                    },
                    {
                      "id": 6016330,
                      "customerNumber": 249035,
                      "systemCode": "STBL",
                      "sourceSystemSubscriptionId": "249035",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2017-08-17 16:11:35"
                    },
                    {
                      "id": 6016333,
                      "customerNumber": 249035,
                      "systemCode": "TOMS",
                      "sourceSystemSubscriptionId": "249035",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2017-08-17 16:11:39"
                    }
                  ],
                  "settlementInstructions": [
                    {
                      "id": 214952,
                      "customerNumber": 249035,
                      "bankAccountNo": "249035",
                      "instrument": "45",
                      "currency": "ANY",
                      "effectDate": null,
                      "loadSSIFor": "C",
                      "entityCode": "SBL",
                      "roleCode": "MAN FUND",
                      "primaryIndicator": "Y",
                      "method": "E",
                      "debitCreditIndicator": "B",
                      "lastUpdated": null,
                      "status": "A",
                      "securityFlag": "Both",
                      "settlementLocation": "E-CLR",
                      "effectiveToDate": null,
                      "custody": "N",
                      "thirdParty": "N",
                      "collateral": "N",
                      "effectiveDateType": "V",
                      "ibanIndicator": "N",
                      "ibanNumber": null,
                      "globusBankNoIndicator": "N",
                      "f57Message": "N",
                      "f57Name": null,
                      "f57SubAccountNo": null,
                      "f57Identifier": null,
                      "f58Identifier": null,
                      "f59Identifier": "/ECLR/23860",
                      "field72F58Line1": null,
                      "field72F58Line2": null,
                      "field72F58Line3": null,
                      "field72F58Line4": null,
                      "field72F58Line5": null,
                      "field72F58Line6": null,
                      "field72F59Line1": null,
                      "field72F59Line2": null,
                      "field72F59Line3": null,
                      "field72F59Line4": null,
                      "field72F59Line5": null,
                      "field72F59Line6": null,
                      "intermediaries": [
                        
                      ],
                      "correspondent": null,
                      "bankBeneficiery": {
                        "swiftCode": null,
                        "option": null,
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "benAccountNo": null,
                        "benBankName": null,
                        "benAddress1": null,
                        "benAddress2": null,
                        "benAddress3": null
                      },
                      "customerBeneficiery": {
                        "swiftCode": null,
                        "option": "D",
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "custAccountNo": "23860",
                        "customerName": "LAZARD 20224",
                        "custAddress1": null,
                        "custAddress2": null,
                        "custAddress3": null
                      }
                    },
                    {
                      "id": 220701,
                      "customerNumber": 249035,
                      "bankAccountNo": "249035",
                      "instrument": "45",
                      "currency": "EGP",
                      "effectDate": null,
                      "loadSSIFor": "C",
                      "entityCode": "SBL",
                      "roleCode": "MAN FUND",
                      "primaryIndicator": "Y",
                      "method": "S",
                      "debitCreditIndicator": "B",
                      "lastUpdated": null,
                      "status": "A",
                      "securityFlag": "Both",
                      "settlementLocation": "EG",
                      "effectiveToDate": null,
                      "custody": "N",
                      "thirdParty": "N",
                      "collateral": "N",
                      "effectiveDateType": "V",
                      "ibanIndicator": "N",
                      "ibanNumber": null,
                      "globusBankNoIndicator": "N",
                      "f57Message": "N",
                      "f57Name": null,
                      "f57SubAccountNo": null,
                      "f57Identifier": null,
                      "f58Identifier": null,
                      "f59Identifier": null,
                      "field72F58Line1": null,
                      "field72F58Line2": null,
                      "field72F58Line3": null,
                      "field72F58Line4": null,
                      "field72F58Line5": null,
                      "field72F58Line6": null,
                      "field72F59Line1": null,
                      "field72F59Line2": null,
                      "field72F59Line3": null,
                      "field72F59Line4": null,
                      "field72F59Line5": null,
                      "field72F59Line6": null,
                      "intermediaries": [
                        {
                          "id": {
                            "systemGeneratedCustomerNumber": 220701,
                            "ssiSequence": 1
                          },
                          "customerNumber": null,
                          "swiftCode": null,
                          "ssiNumber": null,
                          "ssiSequence": null,
                          "option": "A",
                          "bankAccountNo": "93870",
                          "clearingCode": null,
                          "accountNumber": null,
                          "bankName": null,
                          "addressLine1": null,
                          "addressLine2": null,
                          "addressLine3": null,
                          "message": "N",
                          "name": null,
                          "subAccountNo": null,
                          "identifier": null
                        }
                      ],
                      "correspondent": null,
                      "bankBeneficiery": {
                        "swiftCode": null,
                        "option": null,
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "benAccountNo": null,
                        "benBankName": null,
                        "benAddress1": null,
                        "benAddress2": null,
                        "benAddress3": null
                      },
                      "customerBeneficiery": {
                        "swiftCode": null,
                        "option": "D",
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "custAccountNo": "1350062236",
                        "customerName": "LAZARD 20224",
                        "custAddress1": null,
                        "custAddress2": null,
                        "custAddress3": null
                      }
                    },
                    {
                      "id": 214953,
                      "customerNumber": 249035,
                      "bankAccountNo": "249035",
                      "instrument": "45",
                      "currency": "ANY",
                      "effectDate": null,
                      "loadSSIFor": "C",
                      "entityCode": "SBL",
                      "roleCode": "MAN FUND",
                      "primaryIndicator": "Y",
                      "method": "T",
                      "debitCreditIndicator": "B",
                      "lastUpdated": null,
                      "status": "A",
                      "securityFlag": "Both",
                      "settlementLocation": "D-TC",
                      "effectiveToDate": null,
                      "custody": "N",
                      "thirdParty": "N",
                      "collateral": "N",
                      "effectiveDateType": "V",
                      "ibanIndicator": "N",
                      "ibanNumber": null,
                      "globusBankNoIndicator": "N",
                      "f57Message": "N",
                      "f57Name": null,
                      "f57SubAccountNo": null,
                      "f57Identifier": null,
                      "f58Identifier": null,
                      "f59Identifier": "/DTCYID/2787",
                      "field72F58Line1": null,
                      "field72F58Line2": null,
                      "field72F58Line3": null,
                      "field72F58Line4": null,
                      "field72F58Line5": null,
                      "field72F58Line6": null,
                      "field72F59Line1": null,
                      "field72F59Line2": null,
                      "field72F59Line3": null,
                      "field72F59Line4": null,
                      "field72F59Line5": null,
                      "field72F59Line6": null,
                      "intermediaries": [
                        {
                          "id": {
                            "systemGeneratedCustomerNumber": 214953,
                            "ssiSequence": 1
                          },
                          "customerNumber": null,
                          "swiftCode": null,
                          "ssiNumber": null,
                          "ssiSequence": null,
                          "option": "A",
                          "bankAccountNo": "72749",
                          "clearingCode": null,
                          "accountNumber": null,
                          "bankName": null,
                          "addressLine1": null,
                          "addressLine2": null,
                          "addressLine3": null,
                          "message": "N",
                          "name": null,
                          "subAccountNo": null,
                          "identifier": null
                        }
                      ],
                      "correspondent": {
                        "swiftCode": null,
                        "option": null,
                        "accountNo": "CUST",
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "corrAccountNumber": null,
                        "corrBankName": null,
                        "corrAddressLine1": null,
                        "corrAddressLine2": null,
                        "corrAddressLine3": null
                      },
                      "bankBeneficiery": {
                        "swiftCode": null,
                        "option": null,
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "benAccountNo": null,
                        "benBankName": null,
                        "benAddress1": null,
                        "benAddress2": null,
                        "benAddress3": null
                      },
                      "customerBeneficiery": {
                        "swiftCode": null,
                        "option": "D",
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "custAccountNo": "1140996320T",
                        "customerName": "LAZARD 20224",
                        "custAddress1": null,
                        "custAddress2": null,
                        "custAddress3": null
                      }
                    }
                  ]
                },
                {
                  "id": 254498,
                  "customerShortName": "New South Wales Treasury Corp",
                  "createDate": "2017-11-29 00:00:00",
                  "effectiveDate": "2017-11-29 00:00:00",
                  "closingDate": null,
                  "closingReasonCode": null,
                  "cif": 532172304,
                  "accounts": [
                    {
                      "id": 6149204,
                      "customerNumber": 254498,
                      "systemCode": "BSRE",
                      "sourceSystemSubscriptionId": "254498",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "SINGJASR",
                      "authorisingDate": "2017-11-29 17:46:15"
                    },
                    {
                      "id": 6149205,
                      "customerNumber": 254498,
                      "systemCode": "CAM",
                      "sourceSystemSubscriptionId": "254498",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "SINGJASR",
                      "authorisingDate": "2017-11-29 17:46:16"
                    },
                    {
                      "id": 6149201,
                      "customerNumber": 254498,
                      "systemCode": "CIF",
                      "sourceSystemSubscriptionId": "532172304",
                      "existsOnSourceSystem": true,
                      "authorisingUser": "HALLMELI",
                      "authorisingDate": "2017-11-29 13:43:21"
                    },
                    {
                      "id": 6149206,
                      "customerNumber": 254498,
                      "systemCode": "CPSO",
                      "sourceSystemSubscriptionId": "594516",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "SINGJASR",
                      "authorisingDate": "2017-11-29 17:46:16"
                    },
                    {
                      "id": 6149207,
                      "customerNumber": 254498,
                      "systemCode": "FCSH",
                      "sourceSystemSubscriptionId": "883893",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "SINGJASR",
                      "authorisingDate": "2017-11-29 17:46:17"
                    },
                    {
                      "id": 6149208,
                      "customerNumber": 254498,
                      "systemCode": "FNET",
                      "sourceSystemSubscriptionId": "95871",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "SINGJASR",
                      "authorisingDate": "2017-11-29 17:46:18"
                    },
                    {
                      "id": 6149209,
                      "customerNumber": 254498,
                      "systemCode": "FRM",
                      "sourceSystemSubscriptionId": "254498",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "SINGJASR",
                      "authorisingDate": "2017-11-29 17:46:19"
                    },
                    {
                      "id": 6149210,
                      "customerNumber": 254498,
                      "systemCode": "GLBS",
                      "sourceSystemSubscriptionId": "169730",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "SINGJASR",
                      "authorisingDate": "2017-11-29 17:46:20"
                    },
                    {
                      "id": 6149211,
                      "customerNumber": 254498,
                      "systemCode": "LDOC",
                      "sourceSystemSubscriptionId": "27582",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "SINGJASR",
                      "authorisingDate": "2017-11-29 17:46:21"
                    },
                    {
                      "id": 6149212,
                      "customerNumber": 254498,
                      "systemCode": "MXLN",
                      "sourceSystemSubscriptionId": "37641",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "SINGJASR",
                      "authorisingDate": "2017-11-29 17:46:21"
                    },
                    {
                      "id": 6149213,
                      "customerNumber": 254498,
                      "systemCode": "STBL",
                      "sourceSystemSubscriptionId": "254498",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "SINGJASR",
                      "authorisingDate": "2017-11-29 17:46:22"
                    },
                    {
                      "id": 6149214,
                      "customerNumber": 254498,
                      "systemCode": "TOMS",
                      "sourceSystemSubscriptionId": "254498",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "SINGJASR",
                      "authorisingDate": "2017-11-29 17:46:23"
                    }
                  ],
                  "settlementInstructions": [
                    {
                      "id": 217961,
                      "customerNumber": 254498,
                      "bankAccountNo": "254498",
                      "instrument": "45",
                      "currency": "ANY",
                      "effectDate": null,
                      "loadSSIFor": "C",
                      "entityCode": "SBL",
                      "roleCode": "MAN FUND",
                      "primaryIndicator": "Y",
                      "method": "E",
                      "debitCreditIndicator": "B",
                      "lastUpdated": null,
                      "status": "A",
                      "securityFlag": "Both",
                      "settlementLocation": "E-CLR",
                      "effectiveToDate": null,
                      "custody": "N",
                      "thirdParty": "N",
                      "collateral": "N",
                      "effectiveDateType": "V",
                      "ibanIndicator": "N",
                      "ibanNumber": null,
                      "globusBankNoIndicator": "N",
                      "f57Message": "N",
                      "f57Name": null,
                      "f57SubAccountNo": null,
                      "f57Identifier": null,
                      "f58Identifier": null,
                      "f59Identifier": "/ECLR/23860",
                      "field72F58Line1": null,
                      "field72F58Line2": null,
                      "field72F58Line3": null,
                      "field72F58Line4": null,
                      "field72F58Line5": null,
                      "field72F58Line6": null,
                      "field72F59Line1": null,
                      "field72F59Line2": null,
                      "field72F59Line3": null,
                      "field72F59Line4": null,
                      "field72F59Line5": null,
                      "field72F59Line6": null,
                      "intermediaries": [
                        
                      ],
                      "correspondent": null,
                      "bankBeneficiery": {
                        "swiftCode": null,
                        "option": null,
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "benAccountNo": null,
                        "benBankName": null,
                        "benAddress1": null,
                        "benAddress2": null,
                        "benAddress3": null
                      },
                      "customerBeneficiery": {
                        "swiftCode": null,
                        "option": "D",
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "custAccountNo": "23860",
                        "customerName": "GFGAM TCD",
                        "custAddress1": null,
                        "custAddress2": null,
                        "custAddress3": null
                      }
                    },
                    {
                      "id": 217963,
                      "customerNumber": 254498,
                      "bankAccountNo": "254498",
                      "instrument": "45",
                      "currency": "ANY",
                      "effectDate": null,
                      "loadSSIFor": "C",
                      "entityCode": "SBL",
                      "roleCode": "MAN FUND",
                      "primaryIndicator": "Y",
                      "method": "S",
                      "debitCreditIndicator": "B",
                      "lastUpdated": null,
                      "status": "A",
                      "securityFlag": "Both",
                      "settlementLocation": "ZA",
                      "effectiveToDate": null,
                      "custody": "N",
                      "thirdParty": "N",
                      "collateral": "N",
                      "effectiveDateType": "V",
                      "ibanIndicator": "N",
                      "ibanNumber": null,
                      "globusBankNoIndicator": "N",
                      "f57Message": "N",
                      "f57Name": null,
                      "f57SubAccountNo": null,
                      "f57Identifier": null,
                      "f58Identifier": null,
                      "f59Identifier": null,
                      "field72F58Line1": null,
                      "field72F58Line2": null,
                      "field72F58Line3": null,
                      "field72F58Line4": null,
                      "field72F58Line5": null,
                      "field72F58Line6": null,
                      "field72F59Line1": null,
                      "field72F59Line2": null,
                      "field72F59Line3": null,
                      "field72F59Line4": null,
                      "field72F59Line5": null,
                      "field72F59Line6": null,
                      "intermediaries": [
                        {
                          "id": {
                            "systemGeneratedCustomerNumber": 217963,
                            "ssiSequence": 1
                          },
                          "customerNumber": null,
                          "swiftCode": null,
                          "ssiNumber": null,
                          "ssiSequence": null,
                          "option": "A",
                          "bankAccountNo": "81161",
                          "clearingCode": null,
                          "accountNumber": null,
                          "bankName": null,
                          "addressLine1": null,
                          "addressLine2": null,
                          "addressLine3": null,
                          "message": "N",
                          "name": null,
                          "subAccountNo": null,
                          "identifier": null
                        }
                      ],
                      "correspondent": null,
                      "bankBeneficiery": {
                        "swiftCode": null,
                        "option": null,
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "benAccountNo": null,
                        "benBankName": null,
                        "benAddress1": null,
                        "benAddress2": null,
                        "benAddress3": null
                      },
                      "customerBeneficiery": {
                        "swiftCode": null,
                        "option": "D",
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "custAccountNo": "ZA0000070856",
                        "customerName": "GFGAM TCD",
                        "custAddress1": null,
                        "custAddress2": null,
                        "custAddress3": null
                      }
                    },
                    {
                      "id": 217962,
                      "customerNumber": 254498,
                      "bankAccountNo": "254498",
                      "instrument": "45",
                      "currency": "ANY",
                      "effectDate": null,
                      "loadSSIFor": "C",
                      "entityCode": "SBL",
                      "roleCode": "MAN FUND",
                      "primaryIndicator": "Y",
                      "method": "T",
                      "debitCreditIndicator": "B",
                      "lastUpdated": null,
                      "status": "A",
                      "securityFlag": "Both",
                      "settlementLocation": "D-TC",
                      "effectiveToDate": "2019-10-01 19:15:23",
                      "custody": "N",
                      "thirdParty": "N",
                      "collateral": "N",
                      "effectiveDateType": "V",
                      "ibanIndicator": "N",
                      "ibanNumber": null,
                      "globusBankNoIndicator": "N",
                      "f57Message": "N",
                      "f57Name": null,
                      "f57SubAccountNo": null,
                      "f57Identifier": null,
                      "f58Identifier": null,
                      "f59Identifier": "/DTCYID/0902",
                      "field72F58Line1": null,
                      "field72F58Line2": null,
                      "field72F58Line3": null,
                      "field72F58Line4": null,
                      "field72F58Line5": null,
                      "field72F58Line6": null,
                      "field72F59Line1": null,
                      "field72F59Line2": null,
                      "field72F59Line3": null,
                      "field72F59Line4": null,
                      "field72F59Line5": null,
                      "field72F59Line6": null,
                      "intermediaries": [
                        {
                          "id": {
                            "systemGeneratedCustomerNumber": 217962,
                            "ssiSequence": 1
                          },
                          "customerNumber": null,
                          "swiftCode": null,
                          "ssiNumber": null,
                          "ssiSequence": null,
                          "option": "A",
                          "bankAccountNo": "72749",
                          "clearingCode": null,
                          "accountNumber": null,
                          "bankName": null,
                          "addressLine1": null,
                          "addressLine2": null,
                          "addressLine3": null,
                          "message": "N",
                          "name": null,
                          "subAccountNo": null,
                          "identifier": null
                        }
                      ],
                      "correspondent": {
                        "swiftCode": null,
                        "option": null,
                        "accountNo": "CUST",
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "corrAccountNumber": null,
                        "corrBankName": null,
                        "corrAddressLine1": null,
                        "corrAddressLine2": null,
                        "corrAddressLine3": null
                      },
                      "bankBeneficiery": {
                        "swiftCode": null,
                        "option": null,
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "benAccountNo": null,
                        "benBankName": null,
                        "benAddress1": null,
                        "benAddress2": null,
                        "benAddress3": null
                      },
                      "customerBeneficiery": {
                        "swiftCode": null,
                        "option": "D",
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "custAccountNo": "EIV90",
                        "customerName": "GFGAM TCD",
                        "custAddress1": null,
                        "custAddress2": null,
                        "custAddress3": null
                      }
                    }
                  ]
                },
                {
                  "id": 258719,
                  "customerShortName": "LAZARD 20446",
                  "createDate": "2018-02-26 00:00:00",
                  "effectiveDate": "2018-02-26 00:00:00",
                  "closingDate": null,
                  "closingReasonCode": null,
                  "cif": 532172304,
                  "accounts": [
                    {
                      "id": 6235069,
                      "customerNumber": 258719,
                      "systemCode": "BSRE",
                      "sourceSystemSubscriptionId": "258719",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2018-02-26 21:42:45"
                    },
                    {
                      "id": 6235074,
                      "customerNumber": 258719,
                      "systemCode": "CAM",
                      "sourceSystemSubscriptionId": "258719",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2018-02-26 21:42:48"
                    },
                    {
                      "id": 6235054,
                      "customerNumber": 258719,
                      "systemCode": "CIF",
                      "sourceSystemSubscriptionId": "532172304",
                      "existsOnSourceSystem": true,
                      "authorisingUser": "BONFRISB",
                      "authorisingDate": "2018-02-26 21:01:20"
                    },
                    {
                      "id": 6235138,
                      "customerNumber": 258719,
                      "systemCode": "CPSO",
                      "sourceSystemSubscriptionId": "604362",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "BONFRISB",
                      "authorisingDate": "2018-02-26 22:02:33"
                    },
                    {
                      "id": 6235073,
                      "customerNumber": 258719,
                      "systemCode": "FCSH",
                      "sourceSystemSubscriptionId": "906468",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2018-02-26 21:42:51"
                    },
                    {
                      "id": 6235067,
                      "customerNumber": 258719,
                      "systemCode": "FNET",
                      "sourceSystemSubscriptionId": "96371",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2018-02-26 21:42:54"
                    },
                    {
                      "id": 6235065,
                      "customerNumber": 258719,
                      "systemCode": "FRM",
                      "sourceSystemSubscriptionId": "258719",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2018-02-26 21:42:57"
                    },
                    {
                      "id": 6235141,
                      "customerNumber": 258719,
                      "systemCode": "GLBS",
                      "sourceSystemSubscriptionId": "170480",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2018-02-26 22:08:07"
                    },
                    {
                      "id": 6235068,
                      "customerNumber": 258719,
                      "systemCode": "LDOC",
                      "sourceSystemSubscriptionId": "27968",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2018-02-26 21:43:02"
                    },
                    {
                      "id": 6235071,
                      "customerNumber": 258719,
                      "systemCode": "MEQD",
                      "sourceSystemSubscriptionId": "22613",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2018-02-26 21:43:04"
                    },
                    {
                      "id": 6235070,
                      "customerNumber": 258719,
                      "systemCode": "MXLN",
                      "sourceSystemSubscriptionId": "38069",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2018-02-26 21:43:07"
                    },
                    {
                      "id": 6235072,
                      "customerNumber": 258719,
                      "systemCode": "STBL",
                      "sourceSystemSubscriptionId": "258719",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2018-02-26 21:43:10"
                    },
                    {
                      "id": 6235075,
                      "customerNumber": 258719,
                      "systemCode": "TOMS",
                      "sourceSystemSubscriptionId": "258719",
                      "existsOnSourceSystem": false,
                      "authorisingUser": "CARLSONL",
                      "authorisingDate": "2018-02-26 21:43:12"
                    }
                  ],
                  "settlementInstructions": [
                    {
                      "id": 220253,
                      "customerNumber": 258719,
                      "bankAccountNo": "258719",
                      "instrument": "45",
                      "currency": "ANY",
                      "effectDate": null,
                      "loadSSIFor": "C",
                      "entityCode": "SBL",
                      "roleCode": "MAN FUND",
                      "primaryIndicator": "Y",
                      "method": "E",
                      "debitCreditIndicator": "B",
                      "lastUpdated": null,
                      "status": "A",
                      "securityFlag": "Both",
                      "settlementLocation": "E-CLR",
                      "effectiveToDate": null,
                      "custody": "N",
                      "thirdParty": "N",
                      "collateral": "N",
                      "effectiveDateType": "V",
                      "ibanIndicator": "N",
                      "ibanNumber": null,
                      "globusBankNoIndicator": "N",
                      "f57Message": "N",
                      "f57Name": null,
                      "f57SubAccountNo": null,
                      "f57Identifier": null,
                      "f58Identifier": null,
                      "f59Identifier": "/ECLR/23860",
                      "field72F58Line1": null,
                      "field72F58Line2": null,
                      "field72F58Line3": null,
                      "field72F58Line4": null,
                      "field72F58Line5": null,
                      "field72F58Line6": null,
                      "field72F59Line1": null,
                      "field72F59Line2": null,
                      "field72F59Line3": null,
                      "field72F59Line4": null,
                      "field72F59Line5": null,
                      "field72F59Line6": null,
                      "intermediaries": [
                        
                      ],
                      "correspondent": null,
                      "bankBeneficiery": {
                        "swiftCode": null,
                        "option": null,
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "benAccountNo": null,
                        "benBankName": null,
                        "benAddress1": null,
                        "benAddress2": null,
                        "benAddress3": null
                      },
                      "customerBeneficiery": {
                        "swiftCode": null,
                        "option": "D",
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "custAccountNo": "23860",
                        "customerName": "LAZARD 20446",
                        "custAddress1": null,
                        "custAddress2": null,
                        "custAddress3": null
                      }
                    },
                    {
                      "id": 220254,
                      "customerNumber": 258719,
                      "bankAccountNo": "258719",
                      "instrument": "10",
                      "currency": "USD",
                      "effectDate": null,
                      "loadSSIFor": "B",
                      "entityCode": "SBL",
                      "roleCode": "MAN FUND",
                      "primaryIndicator": "Y",
                      "method": "S",
                      "debitCreditIndicator": "B",
                      "lastUpdated": null,
                      "status": "A",
                      "securityFlag": "CASH",
                      "settlementLocation": null,
                      "effectiveToDate": "2019-10-01 18:54:00",
                      "custody": "N",
                      "thirdParty": "N",
                      "collateral": "N",
                      "effectiveDateType": "V",
                      "ibanIndicator": "N",
                      "ibanNumber": null,
                      "globusBankNoIndicator": "N",
                      "f57Message": "N",
                      "f57Name": null,
                      "f57SubAccountNo": null,
                      "f57Identifier": null,
                      "f58Identifier": null,
                      "f59Identifier": null,
                      "field72F58Line1": "/BNF/FFC LAZARD 20446",
                      "field72F58Line2": "/ACC EME19 ADD 41488400",
                      "field72F58Line3": null,
                      "field72F58Line4": null,
                      "field72F58Line5": null,
                      "field72F58Line6": null,
                      "field72F59Line1": null,
                      "field72F59Line2": null,
                      "field72F59Line3": null,
                      "field72F59Line4": null,
                      "field72F59Line5": null,
                      "field72F59Line6": null,
                      "intermediaries": [
                        
                      ],
                      "correspondent": null,
                      "bankBeneficiery": {
                        "swiftCode": null,
                        "option": "A",
                        "bankAccountNo": "0010023",
                        "clearingCode": null,
                        "accountNo": "0010962009",
                        "thirdPartyIndicator": "Y",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "benAccountNo": null,
                        "benBankName": null,
                        "benAddress1": null,
                        "benAddress2": null,
                        "benAddress3": null
                      },
                      "customerBeneficiery": {
                        "swiftCode": null,
                        "option": null,
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "custAccountNo": null,
                        "customerName": null,
                        "custAddress1": null,
                        "custAddress2": null,
                        "custAddress3": null
                      }
                    },
                    {
                      "id": 220251,
                      "customerNumber": 258719,
                      "bankAccountNo": "258719",
                      "instrument": "45",
                      "currency": "EGP",
                      "effectDate": null,
                      "loadSSIFor": "C",
                      "entityCode": "SBL",
                      "roleCode": "MAN FUND",
                      "primaryIndicator": "Y",
                      "method": "S",
                      "debitCreditIndicator": "B",
                      "lastUpdated": null,
                      "status": "A",
                      "securityFlag": "Both",
                      "settlementLocation": "EG",
                      "effectiveToDate": null,
                      "custody": "N",
                      "thirdParty": "N",
                      "collateral": "N",
                      "effectiveDateType": "V",
                      "ibanIndicator": "N",
                      "ibanNumber": null,
                      "globusBankNoIndicator": "N",
                      "f57Message": "N",
                      "f57Name": null,
                      "f57SubAccountNo": null,
                      "f57Identifier": null,
                      "f58Identifier": null,
                      "f59Identifier": null,
                      "field72F58Line1": null,
                      "field72F58Line2": null,
                      "field72F58Line3": null,
                      "field72F58Line4": null,
                      "field72F58Line5": null,
                      "field72F58Line6": null,
                      "field72F59Line1": null,
                      "field72F59Line2": null,
                      "field72F59Line3": null,
                      "field72F59Line4": null,
                      "field72F59Line5": null,
                      "field72F59Line6": null,
                      "intermediaries": [
                        {
                          "id": {
                            "systemGeneratedCustomerNumber": 220251,
                            "ssiSequence": 1
                          },
                          "customerNumber": null,
                          "swiftCode": null,
                          "ssiNumber": null,
                          "ssiSequence": null,
                          "option": "A",
                          "bankAccountNo": "93870",
                          "clearingCode": null,
                          "accountNumber": null,
                          "bankName": null,
                          "addressLine1": null,
                          "addressLine2": null,
                          "addressLine3": null,
                          "message": "N",
                          "name": null,
                          "subAccountNo": null,
                          "identifier": null
                        }
                      ],
                      "correspondent": null,
                      "bankBeneficiery": {
                        "swiftCode": null,
                        "option": null,
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "benAccountNo": null,
                        "benBankName": null,
                        "benAddress1": null,
                        "benAddress2": null,
                        "benAddress3": null
                      },
                      "customerBeneficiery": {
                        "swiftCode": null,
                        "option": "D",
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "custAccountNo": "1350062272",
                        "customerName": "LAZARD 20446",
                        "custAddress1": null,
                        "custAddress2": null,
                        "custAddress3": null
                      }
                    },
                    {
                      "id": 220252,
                      "customerNumber": 258719,
                      "bankAccountNo": "258719",
                      "instrument": "45",
                      "currency": "ANY",
                      "effectDate": null,
                      "loadSSIFor": "C",
                      "entityCode": "SBL",
                      "roleCode": "MAN FUND",
                      "primaryIndicator": "Y",
                      "method": "T",
                      "debitCreditIndicator": "B",
                      "lastUpdated": null,
                      "status": "A",
                      "securityFlag": "Both",
                      "settlementLocation": "D-TC",
                      "effectiveToDate": null,
                      "custody": "N",
                      "thirdParty": "N",
                      "collateral": "N",
                      "effectiveDateType": "V",
                      "ibanIndicator": "N",
                      "ibanNumber": null,
                      "globusBankNoIndicator": "N",
                      "f57Message": "N",
                      "f57Name": null,
                      "f57SubAccountNo": null,
                      "f57Identifier": null,
                      "f58Identifier": null,
                      "f59Identifier": "/DTCYID/0902",
                      "field72F58Line1": null,
                      "field72F58Line2": null,
                      "field72F58Line3": null,
                      "field72F58Line4": null,
                      "field72F58Line5": null,
                      "field72F58Line6": null,
                      "field72F59Line1": null,
                      "field72F59Line2": null,
                      "field72F59Line3": null,
                      "field72F59Line4": null,
                      "field72F59Line5": null,
                      "field72F59Line6": null,
                      "intermediaries": [
                        {
                          "id": {
                            "systemGeneratedCustomerNumber": 220252,
                            "ssiSequence": 1
                          },
                          "customerNumber": null,
                          "swiftCode": null,
                          "ssiNumber": null,
                          "ssiSequence": null,
                          "option": "A",
                          "bankAccountNo": "72749",
                          "clearingCode": null,
                          "accountNumber": null,
                          "bankName": null,
                          "addressLine1": null,
                          "addressLine2": null,
                          "addressLine3": null,
                          "message": "N",
                          "name": null,
                          "subAccountNo": null,
                          "identifier": null
                        }
                      ],
                      "correspondent": {
                        "swiftCode": null,
                        "option": null,
                        "accountNo": "CUST",
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "corrAccountNumber": null,
                        "corrBankName": null,
                        "corrAddressLine1": null,
                        "corrAddressLine2": null,
                        "corrAddressLine3": null
                      },
                      "bankBeneficiery": {
                        "swiftCode": null,
                        "option": null,
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "benAccountNo": null,
                        "benBankName": null,
                        "benAddress1": null,
                        "benAddress2": null,
                        "benAddress3": null
                      },
                      "customerBeneficiery": {
                        "swiftCode": null,
                        "option": "D",
                        "bankAccountNo": null,
                        "clearingCode": null,
                        "accountNo": null,
                        "thirdPartyIndicator": "N",
                        "ibanIndicator": "N",
                        "ibanNo": null,
                        "custAccountNo": "PS75350",
                        "customerName": "LAZARD 20446",
                        "custAddress1": null,
                        "custAddress2": null,
                        "custAddress3": null
                      }
                    }
                  ]
                }
              ] 


            // filter customer subscriptions - find customer with money market subscription 
            var customer = null;
            var hasMoneyMarketSubscription = false;

            for (var i=0; i<application.customers.length; i++) {
                customer = application.customers[i];

                if (customer!=null && customer.accounts!=null && customer.accounts.length>0) {
                    for (var j=0; j<customer.accounts.length; j++) {
                        var account = customer.accounts[j];
                        //console.log("System Code: ", account.systemCode);
                        if (account.systemCode === "MRMX" || account.systemCode === "CPSA" || account.systemCode === "DTRS") {
                            hasMoneyMarketSubscription = true;
                            console.log("==============> money market subscription found ::: customer: ", customer)
                        }
                        // subscription found
                        if (hasMoneyMarketSubscription) break;
                    }
                }
                if (hasMoneyMarketSubscription) break;
            }
            application.customer = hasMoneyMarketSubscription ? customer : {};
            application.hasMoneyMarketSubscription = hasMoneyMarketSubscription;


            dispatch({type: types.GM_FETCH_CUSTOMERS, application});
            onProceed && onProceed();
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("fetchOsdCustomers.error ===> ", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}



export function fetchSettlementInstructions (osdId, onProceed = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);

            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            let responseBody = await axios.get(ACCOUNTS_URL + osdId + "/settlement_instructions").then(response => response.data);

            const application = {};
            if (responseBody.success) {
                application.instructions = responseBody.data;
            }
            else {
                application.instructions = [];
                application.errorMessage = responseBody.message;
            }

            dispatch({type: types.GM_FETCH_INSTRUCTIONS, application});
            onProceed && onProceed();
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("fetchSettlementInstructions.error ===> ", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}
 
export function processGMApplication(legalEntity, application, impersonation, complete, onProceed = null, user = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);

            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            let responseBody = null;
            let dispatchType = null;
            let errorMessage = null;

            if (application && application.id) {
                if (complete) {
                    console.log("submit global market application to OSD");
                    dispatchType = types.GM_COMPLETE_APPLICATION;
                    errorMessage = "Error while submitting GM application to OSD.";
                    if (application.applicationStatus) {
                        application.applicationStatus = {status: globalMarketAccountRoutes.complete};
                    }

                    responseBody = await axios.post(GLOBAL_MARKET_URL + "/complete", application).then(response => response.data);
                } 
                else {
                    console.log("update global market application process : " + application.id);
                    console.log("application : ", application);
                    dispatchType = types.GM_UPDATE_APPLICATION;
                    errorMessage = "Error while updating existing GM application.";
                    responseBody = await axios.put(GLOBAL_MARKET_URL, application).then(response => response.data);
                }
            } else {
                console.log("start global market application process");
                const goldTierId = legalEntity && legalEntity.goldTierId;
                const cif = legalEntity && legalEntity.cif;
                const registeredName = legalEntity && legalEntity.registeredName;
                const registrationNumber = legalEntity && legalEntity.registrationNumber;
                const impersonatedIdNumber = impersonation && impersonation.identityNumber;

                application = {
                    requestedBy: user && (user.username + " ["+ user.firstname +" " + user.surname + "]"),
                    impersonatedIdNumber,
                    notificationEmailAddress: user && user.email, 
                    applicationStatus: {status: globalMarketAccountRoutes.confirmentity},
                    legalEntity: {goldTierId, cif, registeredName, registrationNumber},
                    instructions: [],
                    customer: {}
                };

                dispatchType = types.GM_START_APPLICATION;
                errorMessage = "Error while creating new GM application.";
                responseBody = await axios.post(GLOBAL_MARKET_URL, application).then(response => response.data);
                console.log ("responseBody : ", responseBody)
            }

            console.log("responseBody", responseBody);

            if (responseBody.success) {
                if (responseBody.data) {
                    const responseData = responseBody.data;
                    application.id = responseData.id;
                    application.modifiedAt = responseData.modifiedAt;
                    application.createdAt = responseData.createdAt;
                    application.requestedBy = responseData.requestedBy;
                    application.impersonatedIdNumber = responseData.impersonatedIdNumber;
                    application.notificationEmailAddress = responseData.notificationEmailAddress;
                    application.applicationStatus = responseData.applicationStatus;

                    application.customer = responseData.customer;
                    application.instructions = responseData.instructions;
                    application.legalEntity = responseData.legalEntity;

                    dispatch({type: dispatchType, application});
                    onProceed && onProceed();
                } else {
                    application.legalEntity = legalEntity;
                    dispatch({type: dispatchType, error: responseBody.message, application: application});
                }
            } else {
                application.legalEntity = legalEntity;
                dispatch({type: dispatchType, error: responseBody.message, application: application});
            }

            //onProceed && onProceed();
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("processGMApplication.error ===> ", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchSelectedGlobalMarketRequest(id, history, onFinish=null){
    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(GLOBAL_MARKET_URL +"/" + id ).then(response => response.data);

            if (responseData.success) {

                const application = responseData.data;
                dispatch({type: types.GM_FETCH_APPLICATION, data: application});

                const currentStatus = application && application.applicationStatus;
                history.push(currentStatus?currentStatus.status: "/");

            } else {
                console.log("entity.fetchSelectedGlobalMarketRequest.errorMessage ====>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage:  "Couldn't retrieve selected global market application."});
            }

            onFinish && onFinish();
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("entity.fetchGlobalMarket.error ===>", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}
