import * as types from './actionTypes';
import axios from 'axios';
import {API_ROOT} from '../api-config';
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from './appLoading';


const KYC_MAINTENANCE_URL = `${API_ROOT}/api/kyc-maintenance`;


export function onChangeValue(attribute, value) {
    return (dispatch) => {
        dispatch({type: types.KY_CHANGE, attribute, value});
    };
}

export function onDirChangeValue(objName, attribute, value) {
    return (dispatch) => {
        dispatch({type: types.KY_DIR_CHANGE, objName, attribute, value});
    };
}

export function onAddressChangeValue(objName, attribute, value) {
    return (dispatch) => {
        dispatch({type: types.KY_ADDRESS_CHANGE, objName, attribute, value});
    };
}

export function onNewDoc(value) {
    return (dispatch) => {
        dispatch({type: types.KY_DOC, document: value});
    };
}

export function onNewRelatedParty(value) {
    return (dispatch) => {
        dispatch({type: types.KY_RELATED, relatedParty: value});
    };
}

export function onNewAuthorisedPerson(value) {
    return (dispatch) => {
        dispatch({type: types.KY_AUTHORISED_PERSON, authorisedPerson: value});
    };
}

export function onRemoveAuthorisedPerson(value) {
    return (dispatch) => {
        dispatch({type: types.KY_RM_AUTHORISED_PERS, authorisedPerson: value});
    };
}

export function onRemoveRelatedParty(value) {
    return (dispatch) => {
        dispatch({type: types.KY_RM_RELATED, relatedParty: value});
    };
}

export const addOwner = (update) => {
    return (dispatch) => {
        dispatch({type: types.KY_ADD_OWNER, update});
    };
};
export const pip = (update) => {
    return (dispatch) => {
        dispatch({type: types.KY_PIP, update});
    };
};
export const updatePips = (update) => {
    return (dispatch) => {
        dispatch({type: types.KY_UPDATE_PIPS, update: update});
    };
};
export const updateController = (update) => {
    return (dispatch) => {
        dispatch({type: types.KY_UPDATE_CONTROLLER, update: update});
    };
};

export function kycSaved() {
    return (dispatch) => {
        dispatch({type: types.KY_SAVED});
    };
}

export function setEntity(legalEntity) {
    return async dispatch => dispatch({type: types.KY_ENTITY, legalEntity: legalEntity});
}

export function startKycMaintenance(id, Application, request, history) {
   // const request = { id:id, entityDetails: {...selectedEntity}, product:{...Application.product}};
    id = id ? id : '';
    return async dispatch => {
        loadingPageDataStart(dispatch);
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.post(KYC_MAINTENANCE_URL + '?idNumber=' + id, {...request,product:{...Application.product}}).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.KY_START, kycMaintenance: responseData.data});
                history.push('/kyc/entity-details');
                loadingPageDataFinish(dispatch);
            } else {
                console.log('kyc.kycMaintenance.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t fetch kycMaintenance details.'});
                loadingPageDataFinish(dispatch, true, status);
            }
        } catch (error) {
            console.log('lr.finishRem.error ===> ', JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchSelectedKYCRequest(id, history) {
    return async dispatch => {
        loadingPageDataStart(dispatch);
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(KYC_MAINTENANCE_URL + '/' + id).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.KY_CONTINUE, kycMaintenance: responseData.data});
                history.push('/kyc/entity-details');
                loadingPageDataFinish(dispatch);
            } else {
                console.log('kyc.kycMaintenance.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Error saving KYC.'});
                loadingPageDataFinish(dispatch, true, status);
            }
        } catch (error) {
            console.log('lr.finishRem.error ===> ', JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function saveKYC(id, request, history, forward = false) {
    return async dispatch => {
        loadingPageDataStart(dispatch);
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.put(KYC_MAINTENANCE_URL + '/' + id, request).then(response => response.data);
            if (responseData.success) {
                forward && history.replace('/');
                loadingPageDataFinish(dispatch);
            } else {
                console.log('kyc.kycMaintenance.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Error saving KYC.'});
                loadingPageDataFinish(dispatch, true, status);
            }
        } catch (error) {
            console.log('lr.finishRem.error ===> ', JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function completeKYC(id, request, history) {
    return async dispatch => {
        loadingPageDataStart(dispatch);
        try {
            console.log('making request');
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            console.log('request done');
            const responseData = await axios.put(`${KYC_MAINTENANCE_URL}/${id}/complete`, request).then(response => response.data);
            console.log('request data out');
            if (responseData.success) {
                console.log('request success');
                loadingPageDataFinish(dispatch);
                console.log('redirect', history);
                history.replace('/kyc/complete');
            } else {
                console.log('kyc.kycMaintenance.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Error saving KYC.'});
                loadingPageDataFinish(dispatch, true, status);
            }
        } catch (error) {
            console.log('lr.finishRem.error ===> ', JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}
