import axios from 'axios';
import * as types from './actionTypes';
import {API_ROOT} from "../api-config";
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from "./appLoading";

const DIGITAL_CREDIT_URL = `${API_ROOT}/api/credit-request`;

export function onCompleteApplication(creditApplication, onSuccess = null) {
    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.post(DIGITAL_CREDIT_URL, creditApplication).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.CREDIT_APPLICATION_COMPLETE_SUCCESS, creditApplication});
                onSuccess && onSuccess();
            } else {
                console.log("digitalCredit.onCompleteApplication.errorMessage ====>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Couldn't complete digital credit application."});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("digitalCredit.onCompleteApplication.error ===>", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function onSelectEntity(creditApplication, onProceed = null) {
    return dispatch => {
        dispatch({type: types.START_CREDIT_APPLICATION, creditApplication});
        onProceed && onProceed();
    }
}


export function onClearSelectedEntity() {
    return dispatch => {
        dispatch({type: types.CLEAR_CREDIT_APPLICATION});
    }
}


