import React from 'react';
import '../../styles/Products.scss';

const ProductTile = (props) => {
    const {product, selectProduct, onLearnMore, isSBStaff, isEnable, checkImpersonation} = props;
    if (!product) return null;
    return (

        <div className={`product-btn product-btn-style ${isSBStaff || isEnable || _.isEmpty(checkImpersonation) ? 'tile-enable' : 'tile-disable'}`} >
            <div className="product-heading">
                {product.name}
            </div>
            <div className="title-gradient" />
            <p className="product-description">{product.description}</p>

            <div className="flexRow" style={{ justifyContent: 'space-between' }}>
                {product.openingDepositCents > 0 ? <div>
                    <p className="product-opening-points">R {product.openingDepositCents ? product.openingDepositCents / 100 : ''}</p>
                    <p className="product-opening-points-title">Opening deposit</p>
                </div> : <div></div>}
                <div style={{ textAlign: 'right' }}>
                    <p className="product-opening-points">Anytime</p>
                    <p className="product-opening-points-title">Access</p>
                </div>
            </div>
            <div style={{ flex: 1 }} />
            <div className="flexRow support-style-flex">
                {(product.name === 'Business Current Account') &&
                    <a className="flat-button-primary-light" style={{ padding: 0 }}
                        onClick={() => onLearnMore(product.name)}
                        role="button"><span>Learn More</span>
                    </a>
                }

                {(product.name === 'KYC Review') &&
                    <a className="flat-button-primary-light text-danger" style={{ fontSize: '1.2rem' }}
                        onClick={() => onLearnMore(product.name)}
                        role="button"><span>Documents Required *</span>
                    </a>
                }
                <div style={{ flex: 1 }} />
                {
                    product && product.staffOnly && isSBStaff ?
                        <a className="flat-button-primary" style={{ padding: 0 }}
                            onClick={() => selectProduct(product.id, product.productRoute)}
                            role="button"><span>Apply Now <i className="fa fa-angle-right fa-lg" /> </span>
                        </a>
                        :
                        product.comingSoon ?
                            <a className="flat-button-primary" style={{ padding: 0 }}><span>Coming Soon</span></a>
                            :
                            <a className="flat-button-primary" style={{ padding: 0 }}
                                onClick={() => selectProduct(product.id, product.productRoute)}
                                role="button"><span>Apply Now <i className="fa fa-angle-right fa-lg" /> </span>
                            </a>
                }

            </div>
        </div>
    );
};
export default ProductTile;
