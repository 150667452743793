import React from "react";
import {bindActionCreators} from 'redux';
import _ from "lodash";
import 'react-select/dist/react-select.css';
import '../../styles/bancs.css';
import * as actions from '../../actions/custody';
import {newCustodyAccountRoutes} from '../../routers/routes'
import {connect} from 'react-redux';
import FormField from "../formField/FormField";
import Toggler from "../Toggler";
import validate from 'validate.js'
import FileUpload from "../FileUpload";
import {uploadDoc} from "../../actions/kyc";

class AccountRelationsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            test: false,
            errors: {}
        };

        this.handleOnChange = this._handleOnChange.bind(this);
        this.handleToggle = this._handleToggle.bind(this);
        this.resetError = this._resetError.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.errors) {
            this.setState({propsErrors: nextProps.errors})
        }
    }


    _resetError(attribute) {

        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    _handleOnChange({target}, attribute, innerAttribute) {

        let value = target.value;
        if (innerAttribute) {
            value = innerAttribute
        }
        this.props.action.onChangeValue('accountRelations', attribute, value);
    }

    _handleToggle(attribute) {
        const {accountRelations} = this.props.custodyRequest;
        this.props.action.onChangeValue('accountRelations', attribute, !accountRelations[attribute]);
    }


    handleSelectChange = (event, attribute, innerAttribute, isCheckbox = true) => {
        const {form} = this.state;
        let value = isCheckbox ? event.target.checked : event.value;
        if (innerAttribute) {
            value = _.extend({}, form && form[attribute], {[innerAttribute]: value});
        }
        const updateForm = _.extend({}, form, {[attribute]: value});
        this.setState({form: updateForm});
    };


    saveDetails = (event) => {

        event.preventDefault();
        const {history} = this.props;
        if (this.validateForm()) {
            const onSuccess = () => history.push(newCustodyAccountRoutes.beneficialowner);
            this.props.action.saveCustody(this.props.appId, this.props.custodyRequest, 'UserTask_AccountRelations', onSuccess);
        }
    };

    onHandleUpload = (base64, inProgress, onComplete, documentType) => {
        const {action} = this.props;

        const onSuccess = (documentId, data) => {
            action.onChangeValue('accountRelations', 'documentId', documentId);

        };
        action.uploadDoc(base64, onSuccess, null, inProgress, onComplete);
    };


    validateForm = () => {

        const {accountRelations} = this.props.custodyRequest;
        const options = {fullMessages: false};

        let constraints = {
            tradingName: {presence: true, length: {minimum: 1, message: 'required'}},
            clientType: {presence: true, length: {minimum: 1, message: 'required'}}
        };

        if (accountRelations.withholdingTax || accountRelations.dividendwithholdingTax) {
            if (accountRelations.mirrorAccount) {
                constraints = _.extend({}, constraints, {
                    mirrorAccount: {
                        presence: true,
                        length: {minimum: 12, message: 'required'}
                    }
                })
            } else {
                constraints = _.extend({}, constraints, {
                    documentId: {
                        presence: true,
                        length: {minimum: 1, message: 'required'}
                    }
                })
            }
        }

        const errors = validate(accountRelations, constraints, options);
        this.setState({errors});
        console.log(errors);
        return _.isEmpty(errors);
    };

    _renderFooter() {

        return (
            <div className="flexRow" style={{justifyContent: "space-between", float: "right"}}>
                <button
                    className="action-btn-primary"
                    onClick={this.saveDetails}
                    type="button"
                >Next
                </button>
            </div>
        )
    }

    render() {
        const {errors} = this.state;
        const {custodyRequest} = this.props;

        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="card-container-form">

                        <div className="row>">
                            <div className="col-md-6 col-md-offset-3">

                                <FormField className="form-group" id="entityName"
                                           error={errors && errors.tradingName}>
                                    <label htmlFor="tradingName">Full legal Entity Name</label>
                                    <input
                                        className="form-control"
                                        id="tradingName"
                                        onChange={(event) => {
                                            errors && errors.tradingName && this.resetError('tradingName');
                                            this.handleOnChange(event, 'tradingName')
                                        }}
                                        type="text"
                                        value={custodyRequest && custodyRequest.accountRelations && custodyRequest.accountRelations.tradingName}
                                    />
                                </FormField>

                                <div className="input-group fontSize">

                                    <p><b>Kindly indicate in which capacity is this account being opened</b></p>
                                </div>

                                <FormField className="form-group" id="entityName"
                                           error={errors && errors.clientType && ['One option must be selected']}>
                                    <div className="inline-container">
                                        <div className="icon-container"
                                             onClick={(event) => {
                                                 errors && errors.clientType && this.resetError('clientType');
                                                 this.handleOnChange(event, 'clientType', 'Beneficiary Account')
                                             }}>
                                            <i
                                                className={(custodyRequest.accountRelations && custodyRequest.accountRelations.clientType == 'Beneficiary Account' ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                                style={{fontSize: 20}}
                                                id="beneficiaryAccount"

                                            />
                                        </div>
                                        Beneficiary Account
                                    </div>

                                    {/*<div className="inline-container">
                                    <div className="icon-container"
                                         onClick={(event) => {
                                             this.handleOnChange(event, 'clientType', 'Broker/FSB nominee')
                                         }}>
                                        <i
                                            className={(custodyRequest.accountRelations && custodyRequest.accountRelations.clientType == 'Broker/FSB nominee' ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                            style={{fontSize: 20}}
                                            id="beneficiaryAccount"
                                        />
                                    </div>
                                    Broker nominee or FSB approved nominee account (If yes additional documentation to be
                                    provided)
                                </div>

                                <div className="inline-container"
                                     onClick={(event) => {
                                         this.handleOnChange(event, 'clientType', 'Foreign Nominee Account')
                                     }}>
                                    <i
                                        className={(custodyRequest.accountRelations && custodyRequest.accountRelations.clientType == 'Foreign Nominee Account' ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                        style={{fontSize: 20}}
                                        id="beneficiaryAccount"

                                        type="checkbox"

                                    />
                                    Foreign nominee account (If yes additional documentation to be provided)
                                </div>

                                <div className="inline-container"
                                     onClick={(event) => {
                                         this.handleOnChange(event, 'clientType', 'JSE Member')
                                     }}>
                                    <i
                                        className={(custodyRequest.accountRelations && custodyRequest.accountRelations.clientType == 'JSE Member' ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                        style={{fontSize: 20}}
                                        id="beneficiaryAccount"
                                    />
                                    JSE member (If yes additional documentation to be provided)
                                </div>

                                <div className="inline-container"
                                     onClick={(event) => {
                                         this.handleOnChange(event, 'clientType', 'Own Name Account')
                                     }}>
                                    <i
                                        className={(custodyRequest.accountRelations && custodyRequest.accountRelations.clientType == 'Own Name Account' ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                        style={{fontSize: 20}}
                                        id="beneficiaryAccount"
                                    />
                                    Own name account (proprietary account)
                                </div>

                                <div className="inline-container"
                                     onClick={(event) => {
                                         this.handleOnChange(event, 'clientType', 'Segregated Depository Account')
                                     }}>
                                    <i
                                        className={(custodyRequest.accountRelations && custodyRequest.accountRelations.clientType == 'Segregated Depository Account' ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                        style={{fontSize: 20}}
                                        id="beneficiaryAccount"
                                    />
                                    Segregated depository account (SDA) (If yes additional documentation to be provided)
                                </div>*/}
                                </FormField>
                                <br/>


                                <div className="input-group fontSize">
                                    <p><b>Indicate the types of accounts/Standing Instructions required</b></p>
                                </div>

                                <div>
                                    <Toggler
                                        label="Bond account (J code)"
                                        isOn={custodyRequest.accountRelations && custodyRequest.accountRelations.bondAccount}
                                        onToggle={() => {
                                            this.handleToggle('bondAccount')
                                        }}
                                    />
                                </div>
                                <div>
                                    <Toggler
                                        label="Money market (SOR) account"

                                        isOn={custodyRequest.accountRelations && custodyRequest.accountRelations.moneyMarket}
                                        onToggle={() => {
                                            this.handleToggle('moneyMarket')
                                        }}
                                    />

                                </div>
                                <div>
                                    <Toggler
                                        label="Exempt from interest Withholding Tax"
                                        isOn={custodyRequest.accountRelations && custodyRequest.accountRelations.withholdingTax}
                                        onToggle={() => {
                                            this.handleToggle('withholdingTax')
                                        }}
                                    />
                                </div>
                                <div>
                                    <Toggler
                                        label="Money market auto mandate required"
                                        isOn={custodyRequest.accountRelations && custodyRequest.accountRelations.moneyMarketAutoMandate}
                                        onToggle={() => {
                                            this.handleToggle('moneyMarketAutoMandate')
                                        }}
                                    />
                                </div>
                                <div>
                                    <Toggler
                                        label="Exempt from Dividend Withholding Tax"
                                        isOn={custodyRequest.accountRelations && custodyRequest.accountRelations.dividendwithholdingTax}
                                        onToggle={() => {
                                            this.handleToggle('dividendwithholdingTax')
                                        }}
                                    />
                                </div>

                                {

                                    custodyRequest.accountRelations.moneyMarket ?
                                        <div className="input-group redText fontSize">
                                            <br/>
                                            <FormField className="form-group" id="participantId"
                                                       error={errors && errors.fullLegalEntityName}>
                                                <label htmlFor="participantId">Participant ID(If existing)</label>
                                                <input
                                                    className="form-control"
                                                    id="participantId"
                                                    onChange={(event) => {
                                                        errors && errors.participantId && this.resetError('participantId');
                                                        this.handleOnChange(event, 'participantId')
                                                    }}
                                                    type="text"
                                                    value={custodyRequest && custodyRequest.accountRelations && custodyRequest.accountRelations.participantId}
                                                />
                                            </FormField></div>
                                        :
                                        ''
                                }

                                {
                                    custodyRequest.accountRelations.withholdingTax || custodyRequest.accountRelations.dividendwithholdingTax ?
                                        <div className="input-group redText fontSize">
                                            <br/>
                                            <FormField className="form-group" id="mirrorAccount"
                                                       error={errors && errors.mirrorAccount && [' Enter valid mirror account or upload file']}>
                                                <label htmlFor="mirrorAccount">Mirror Account</label>
                                                <input
                                                    className="form-control"
                                                    id="mirrorAccount"
                                                    onChange={(event) => {
                                                        errors && errors.mirrorAccount && this.resetError('mirrorAccount');
                                                        this.handleOnChange(event, 'mirrorAccount')
                                                    }}
                                                    type="text"
                                                    value={custodyRequest && custodyRequest.accountRelations && custodyRequest.accountRelations.mirrorAccount}
                                                />
                                            </FormField></div>
                                        :
                                        ''
                                }

                            </div>
                        </div>
                    </div>


                    {custodyRequest.accountRelations.withholdingTax || custodyRequest.accountRelations.dividendwithholdingTax ?
                        <div className="input-group redText fontSize">
                            <p>Please enter Mirror Account or complete the Withholding Tax
                                (WHT) declaration form if this new account is excempt from Dividend Withholding Tax
                                and/or Interest Withholding Tax</p>

                            <FileUpload
                                documentType="withholding"
                                onUpload={(base64, inProgress, onComplete) => {
                                    errors && errors.withholding && onResetError('withholding');
                                    this.onHandleUpload && this.onHandleUpload(base64, inProgress, onComplete, 'withholding')
                                }}
                                onRemoveDocument={() => {
                                    errors && errors.proofOfAddress && onResetError('withholding');
                                    onHandleRemoveDocument && onHandleRemoveDocument('withholding');
                                }}
                            />
                        </div>

                        : ''}
                </div>


                <div className="page-footer-section" style={{width: "80%"}}>
                    {this._renderFooter()}
                </div>
            </div>
        );
    }
}

function mapStateToProps({custody}) {
    return {
        custodyRequest: custody && custody.custodyRequest,
        appId: custody && custody.id
    }
}

function mapDispatchToProps(dispatch) {
    return {
        action: bindActionCreators({
            ...actions,
            uploadDoc
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountRelationsPage);

