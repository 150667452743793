import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {fetchHierachy, fetchEntityInfo} from '../../actions/entity';
import {digitalCreditRoutes} from '../../routers/routes'
import * as actions from '../../actions/digitalCredit';
import EntityList from "../legalentity/EntityList";
import ReactLoading from "react-loading";
import NoLinkedEntitiesMessage from "../legalentity/NoLinkedEntitiesMessage";
import validate from "validate.js";
import FormField from "../formField/FormField";

class DigitalCreditLandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {errors: {}, selectedEntity: null, isLoading: false};
        this.handleSelect = this._handleSelect.bind(this);
        this.onSubmit = this._onSubmit.bind(this);
    }

    componentWillMount() {
        this._fetchHierachy();
    }

    _fetchHierachy() {
        const {actions, impersonation} = this.props;
        actions.fetchHierachy(impersonation);
    }

    _handleSelect(selectedEntity) {
        const {errors} = this.state;
        errors && errors.selectedEntity && this.handleResetError("selectedEntity");
        this.setState({selectedEntity});
    }

    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    validateSelected = () => {
        const {selectedEntity} = this.state;
        const options = {fullMessages: false};

        const constraints = {selectedEntity: {presence: true}};
        const errors = validate({selectedEntity}, constraints, options);

        this.setState({errors});
        return _.isEmpty(errors);
    };

    _onSubmit(e) {
        e.preventDefault();
        if (this.validateSelected()) {
            const {actions, history} = this.props;
            const {selectedEntity} = this.state;

            this.setState({isLoading: true});
            const onFinish = () => {
                this.setState({isLoading: false});
                history.push(digitalCreditRoutes.details);
            };
            const creditApplication = {customerName: selectedEntity.registeredName, customerCIF: selectedEntity.cif};
            actions.onSelectEntity(creditApplication, onFinish)
        }
    }

    _renderEntities() {
        const {entities} = this.props;
        const hasEntities = _.size(entities) > 0;

        if (!hasEntities) return null;
        const {errors, selectedEntity} = this.state;
        return (
            <FormField
                className="form-group"
                error={errors && errors.selectedEntity && ["One Should Be Selected"]}
            >
                <EntityList
                    entities={entities}
                    onSelect={this.handleSelect}
                    selectedEntity={selectedEntity}
                    style={{maxHeight: '65vh'}}
                />
            </FormField>
        );
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={this.onSubmit}
                    type="button"
                >Next
                </button>
            </div>
        )
    }

    render() {
        const {entities, loading} = this.props;
        const {isLoading} = this.state;

        if (loading) return null;

        const hasEntities = _.size(entities) > 0;
        if (!hasEntities) {
            return (
                <div className="page-container">
                    <div className="page-main-section">
                        <NoLinkedEntitiesMessage/>
                    </div>
                </div>
            )
        }
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="column" style={{width: "60%"}}>
                        {this._renderEntities()}
                    </div>
                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
                {
                    isLoading &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps({entity, impersonation, loading}) {
    return {
        entities: entity && entity.entities ? entity.entities : [],
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, fetchHierachy, fetchEntityInfo}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(DigitalCreditLandingPage);