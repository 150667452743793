import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function accountsEmptyReducer(state = initialState.accountsEmpty, action) {
    switch (action.type) {
        case types.FETCH_ACCOUNTS_EMPTY:
            return {...state, accountsEmpty:  action.accountsEmpty};
        default:
            return state;
    }
}