import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function userPendingApplicationsReducer(state = initialState.pandingApplications, action) {
    switch(action.type) {
        case types.LOAD_USER_PENDING_FOR_APPROVAL_APPLICATIONS_SUCCESS:
            return {...state, pandingApplications: action.pandingApplications};

        default:
            return state;
    }
}