import React, {useState} from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {DataList} from 'primereact/components/datalist/DataList';
import validate from 'validate.js';
import "../../../styles/ConfirmApplication.css";
import "../../../styles/Global.css";
import FormField from "../../formField/FormField";
import {managedFundRoutes} from "../../../routers/routes";
import {saveCustodyManagedFund} from "../../../actions/custodyManagedFund";
import {SelectiveRulesPage} from "../../selectiveapproval/SelectiveRulesPage";



export const ConfirmApplicationPage = (props) => {

    const custodyRequest = useSelector(state => state.custodyManagedFund && state.custodyManagedFund.custodyManagedFundRequest);
    const appId = useSelector(state => state.custodyManagedFund && state.custodyManagedFund.id);
    const authorisers = useSelector(state => state.authorisers && state.authorisers.authorisers ? state.authorisers.authorisers : []);
    const goldTierId = useSelector(state => state.custodyManagedFund && state.custodyManagedFund.advisorGoldtierId);
    const [notificationEmailAddress, setNotificationEmailAddress] = useState(custodyRequest.notificationEmailAddress);
    const [errors, setErrors] = useState({});
    const [selectedApprovers,setSelectedApprovers] = useState([]);
    const [errormsg , setErrormsg]  = useState(false);
    const [currentselected,setCurrentselected] = useState('');
    const [selectiveauthorisers , setSelectiveauthorisers] = useState([]);
    const [authorisersA , setAuthorisersA] = useState([]);
    const [authorisersB , setAuthorisersB] = useState([]);
    const [levelDataList, setLevelDataList] = useState([]);
    const [levelAApprovers , setLevelAApprovers] = useState([]);
    const [levelBApprovers , setLevelBApprovers] = useState([]);


    const dispatch = useDispatch();

    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    };

    const saveDetails = (event) => {
        event.preventDefault();
        if (!notificationEmailAddress){
        setErrors({notificationEmailAddress: [" please enter an email address "]})
        }
        if (validateCount() && notificationEmailAddress && notificationEmailAddress.length > 5) {
            const {history} = props;
            custodyRequest.notificationEmailAddress = notificationEmailAddress;
            const onSuccess = () => history && history.push(managedFundRoutes.custodyConfirmApplicationPage);
            if (currentselected === 'No'){
            const prevSelectedApprovers = [];
            custodyRequest.selectedApprovers = prevSelectedApprovers;
            dispatch(saveCustodyManagedFund(appId, custodyRequest, 'UserTask_Notification', onSuccess));
            }
            else {
            custodyRequest.selectedApprovers = selectedApprovers;
            dispatch(saveCustodyManagedFund(appId, custodyRequest, 'UserTask_Notification', onSuccess));
            }
        }
    };


     const validateCount = () => {
         const isCountValid = () => {
             for ( const level of levelDataList){
                 const counts = Object.values(level)[0];
                 if (levelAApprovers.length === counts.countOfA && levelBApprovers.length === counts.countOfB){
                 return true;
                 }}
                 return false;
          };

         if (currentselected==='Yes' && !isCountValid() ) {
             setErrormsg(true);
             return false
          }
          return true;
     };

    const updateLevelDataList = (levelData) => {

           setLevelDataList(levelData);
        };

     const updateSelection = (selected) => {
          setCurrentselected(selected);

     };
     
     const updateauthorisers = (authorizers) => {
         setSelectiveauthorisers(authorizers);
         setAuthorisersA(authorizers.filter(apA => apA.authLevel === 'A' ));
         setAuthorisersB(authorizers.filter(apB => apB.authLevel === 'B' ));
         
         };


   const handleSelections =(e, idNumber, authLevel) => {
          const authorizer = { identityNumber:idNumber, approvalLevel:authLevel };
          errormsg && setErrormsg(false);

          if (selectedApprovers.some(a => a.identityNumber === idNumber)) {
                setSelectedApprovers(prevState => (
                  prevState.filter(a => a.identityNumber !== idNumber)));
                setLevelAApprovers(prevState => (
                  prevState.filter(a => a.identityNumber !== idNumber)));
                setLevelBApprovers(prevState => (
                  prevState.filter(a => a.identityNumber !== idNumber)));

          } else {
                setSelectedApprovers(prevState => (
                  [...prevState, authorizer]));
                if (authLevel === 'A') {
                   setLevelAApprovers(prevState => (
                  [...prevState,authorizer]));
                  }
                else {
                 setLevelBApprovers(prevState => (
                  [...prevState , authorizer]));
                  }
          }
       };

    const renderList = (sc) => {
        if (!sc) return null;
        const message = sc.registered===true? `${sc.firstName} ${sc.surname} is registered and will be sent an approval request`:`${sc.firstName} ${sc.surname} is not registered`
        return (
            <div className="row">
                <div className="col-md-12">
                    <label>{message}</label>
                </div>
                <hr/>
            </div>
        );
    };


    const renderFilteredList =(sc) => {
        return (
            <div className="row">
                <div className="checkbox-inline column">
                    <FormField >
                        <div className ={sc.registered===false? " disable flexRow " : "flexRow"} style={{ alignItems: "flex-start", textAlign: "left" }}>
                            <div className="icon-container"  >
                                <i className={selectedApprovers.some(a => a.identityNumber === sc.idNumber)  && !(sc.registered===false)? "fa fa-check-square-o" : "fa fa-square-o"}
                                    style={{ color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10 }}
                                     checked={selectedApprovers.some(a => a.identityNumber === sc.idNumber) }
                                     onClick={(event) => handleSelections(event, sc.idNumber,sc.authLevel)} />
                            </div>
                            <p style={{ margin: 0 }}> {sc.firstName} {sc.surname} </p>
                        </div>
                    </FormField>
                </div>
            </div>
        );
    }

    const renderFooter = () => {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary btn-sm"
                    onClick={saveDetails}
                    type="button"
                >Complete
                </button>
            </div>
        );
    };


    return (
        <div className="page-container">
            <div className="page-main-section">
                <div className="flexColumn">
                    <div className="card-container-form">
                        <div style={{width: "100%"}}>
                            <div className="product-title">
                                  <SelectiveRulesPage
                                   selectedApprovers = {selectedApprovers}
                                   renderList = {renderList.bind(this)}
                                   renderFilteredList = {renderFilteredList.bind(this)}
                                   authorisers = {selectiveauthorisers}
                                   result1 = {authorisersA}
                                   result2 = {authorisersB}
                                   updateLevelDataList = {updateLevelDataList}
                                   updateSelection ={updateSelection}
                                   updateauthorisers = {updateauthorisers}
                                   goldTierId = {goldTierId}
                                   errormsg = {errormsg}
                                   />
                            </div>
                        </div>
                        <FormField id="kycEmail" error={errors && errors.notificationEmailAddress}>
                            <label>Please Enter Email Address Where The Confirmation Letter Will Be Sent </label>
                            <input
                                className="form-control"
                                id="notificationEmailAddress"
                                onChange={(event) => {
                                    errors && errors.notificationEmailAddress && resetError('notificationEmailAddress');
                                    setNotificationEmailAddress(event.target.value);
                                }}
                                type="text"
                                value={notificationEmailAddress}
                            />
                        </FormField>

                    </div>
                </div>
            </div>
            <div className="page-footer-section">
                {renderFooter()}
            </div>
        </div>
    );

};


export default (ConfirmApplicationPage);
