import * as types from './actionTypes';
import axios from 'axios';
import {API_ROOT} from '../api-config';
import {authCheck} from './appLoading';

const MAINTENANCE_URL = `${API_ROOT}/api/maintanance/signatories`;


export function removeSignatory({selectedEntity, accountNumber, instructions, instructingUser, impersonatedUser}, onComplete, onFinish = null) {

    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.post(MAINTENANCE_URL,
                  { maintenanceRequest : {accountNumber, instructions, instructingUser, impersonatedUser, entityDTO: {cif: selectedEntity.cif, goldtierId: selectedEntity.goldTierId}}}
                ).then(response => response.data);

            if (responseData.success) {
                onComplete && onComplete(true);
                dispatch({type: types.UPDATE_COPIES, copies: responseData.data, errorMessage: null});
            } else {
                onComplete && onComplete();
                console.log('maintenance.removeSignatory.errorMessage ===> ', responseData.message);
                //dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Couldn't remove signatories."});
            }
            onFinish && onFinish();
        } catch (error) {
            console.log('maintenance.removeSignatory.error ===>', error);
            onComplete && onComplete();
            authCheck(dispatch, error);
            onFinish && onFinish();
        }
    };
}

export const addSignatory = ({selectedEntity, accountNumber, instructions, instructingUser, impersonatedUser}, onComplete) => async (dispatch) => {
    try {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
        console.log(selectedEntity);

        const responseData = await axios.post(MAINTENANCE_URL,
            {
                maintenanceRequest : {accountNumber, instructions, instructingUser, impersonatedUser, entityDTO: {cif: selectedEntity.cif, goldtierId: selectedEntity.goldTierId}}}).then(response => response.data);
        if(responseData.success) {
            onComplete && onComplete(true);
        } else {
            onComplete && onComplete();
            console.log('maintenance.addSignatory.errorMessage ===> ', responseData.message);
           // dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Couldn't add signatory."});
        }
    }catch (error) {
        onComplete && onComplete();
        console.log('maintenance.addSignatory.error ===>', error);
        authCheck(dispatch, error);
    }
};
