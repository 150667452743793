import React from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {DataList} from 'primereact/components/datalist/DataList';
import validate from 'validate.js'
import "../../styles/ConfirmApplication.css";
import "../../styles/Global.css";
import * as actions from '../../actions/tpfaActions';
import FormField from "../formField/FormField";
import {tpfaRoutes} from "../../routers/routes";
import {trackData} from "../../actions/analytics";
import {SelectiveRulesPage} from "../selectiveapproval/SelectiveRulesPage";
import {saveApplication, submitApplication, additionalProductChange} from '../../actions/tpfaActions';


class TPFAConfirmAuthorisers extends React.Component {
    state = {
        form: {emailAddress: ''}, errors: {},
        searchTerm: null,
        selectedApprovers:[],
        levelDataList : [],
        approversA : [],
        approversB : [],
        errormsg : false,
        currentSelected : '',
        selectiveAuthorizers: []
    };



    componentDidMount() {

        this.props.actions.trackData('pageName', 'New Account| Confirm Authorizers| step 5');
    }

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }


    validateCount = () => {

         const { approversA,approversB,currentSelected,levelDataList} = this.state;
          function isCountValid (){
            for ( const level of levelDataList){
                const counts = Object.values(level)[0];
                if (approversA.length === counts.countOfA && approversB.length === counts.countOfB){
                return true;
                }}
                return false;
         };

         if (currentSelected==='Yes' && !isCountValid()) {
            this.setState({errormsg : true});
            return false
         }
         return true;
        }

    validateForm = () => {
        const {form} = this.state;
        const options = {fullMessages: false};
        const constraints = {emailAddress: {presence: true, email: true, length: {minimum: 1, message: 'required'}}};
        const errors = validate(form, constraints, options);
        this.setState({errors});
        return this.validateCount() && _.isEmpty(errors) ;
    };

    updateLevelDataList = (levelData) => {
          this.setState({
                  levelDataList : levelData
          })
    };

    updateSelection = (selected) => {
        this.setState({
                currentSelected:selected
        })
        }

    saveDetails = (event) => {
        event.preventDefault();
        const { form, currentSelected, selectedApprovers } = this.state;

        if (this.validateForm()) {
            const { actions, id, application, history } = this.props;
            const applicationDetails = {
                ...application,
                notificationEmailAddress: form.emailAddress,
                selectedApprovers: selectedApprovers
            }
            history.push(tpfaRoutes.complete)
            const onProceed = () => history && history.push(tpfaRoutes.complete);
            if (currentSelected === 'No') {
                const prevSelectedApprovers = [];
                //  actions.complete(id, form.emailAddress, onProceed, prevSelectedApprovers);
                actions.submitApplication(id, applicationDetails, history);
            } else {
                //  actions.complete(id, form.emailAddress, onProceed, this.state.selectedApprovers);
                actions.submitApplication(id, applicationDetails, history);
            }
        }
    };

    handleChange = (e) => {
        const {form} = this.state;
        const emailAddress = _.trimStart(e.target.value);
        this.setState({form: _.extend({}, form, {emailAddress})});
    };

    updateauthorisers = (authorizers) => {
        this.setState({selectiveAuthorizers:authorizers});
    };

    handleSelections = (e, identityNumber, approvalLevel) => {
      const { selectedApprovers, approversA, approversB , errormsg} = this.state;
      const authorizer = { identityNumber, approvalLevel};
      errormsg && this.setState({errormsg: false});

      if (selectedApprovers.some(a => a.identityNumber === identityNumber)) {
        this.setState(prevState => ({
          selectedApprovers: prevState.selectedApprovers.filter(a => a.identityNumber !== identityNumber),
          approversA: prevState.approversA.filter(a => a.identityNumber !== identityNumber),
          approversB: prevState.approversB.filter(a => a.identityNumber !== identityNumber),
        }));
      } else {
        this.setState(prevState => ({
          selectedApprovers: [...prevState.selectedApprovers, authorizer],
          approversA: approvalLevel === "A" ? [...prevState.approversA, authorizer] : prevState.approversA,
          approversB: approvalLevel === "B" ? [...prevState.approversB, authorizer] : prevState.approversB,
        }));
      }
    }

    renderList = (sc) => {
        if (!sc) return null;
        const message = sc.registered===true? `${sc.firstName} ${sc.surname} is registered and will be sent an approval request`:`${sc.firstName} ${sc.surname} is not registered`
        return (
            <div className="row">
                <div className="col-md-12">
                    <label>{message}</label>
                </div>
                <hr/>
            </div>
        );
    };

     renderFilteredList(sc) {

        return (
            <div className="row">
                <div className="checkbox-inline column">
                    <FormField >
                        <div className ={sc.registered===false? " disable flexRow " : "flexRow"} style={{ alignItems: "flex-start", textAlign: "left" }}>
                            <div className="icon-container"  >
                                <i className={this.state.selectedApprovers.some(a => a.identityNumber === sc.idNumber)  && !(sc.registered===false)? "fa fa-check-square-o" : "fa fa-square-o"}
                                    style={{ color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10 }}
                                     checked={this.state.selectedApprovers.some(a => a.identityNumber === sc.idNumber) }
                                     onClick={(event) => this.handleSelections(event, sc.idNumber,sc.authLevel)} />
                            </div>
                            <p style={{ margin: 0 }}> { sc.firstName} {sc.surname} </p>
                        </div>
                    </FormField>
                </div>
            </div>
        );
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary btn-sm"
                    onClick={this.saveDetails}
                    type="button"
                >Complete
                </button>
            </div>
        )
    }

    render() {
        const {authorisers} = this.props;
        const {errors, form, selectiveAuthorizers} = this.state;
        const authorisersA = selectiveAuthorizers.filter(a => a.authLevel==='A');
        const authorisersB = selectiveAuthorizers.filter(b => b.authLevel==='B');

        return (
                <div className="page-container">
                    <div className="page-main-section">
                        <div className="flexColumn" >
                            <div className="card-container-form">
                                <FormField>
                                  <SelectiveRulesPage
                                   selectedApprovers = {this.state.selectedApprovers}
                                   renderList = {this.renderList}
                                   renderFilteredList = {this.renderFilteredList.bind(this)}
                                   authorisers = {selectiveAuthorizers}
                                   result1 = {authorisersA}
                                   result2 = {authorisersB}
                                   goldTierId ={this.props.application.goldTierId}
                                   updateLevelDataList = {this.updateLevelDataList}
                                   updateSelection = {this.updateSelection}
                                   updateauthorisers = {this.updateauthorisers}
                                   errormsg = {this.state.errormsg}
                                   />
                                </FormField>


                                <FormField id="kycEmail" error={errors && errors.emailAddress}>
                                    <label>Please Enter Email Address Where The Confirmation Letter Will Be Sent</label>
                                    <input
                                        className="form-control"
                                        id="emailAddress"
                                        onChange={(event) => {
                                            errors && errors.emailAddress && this._resetError('emailAddress');
                                            this.handleChange(event)
                                        }}
                                        type="text"
                                        value={form && form.emailAddress ? form.emailAddress : ''}
                                    />
                                </FormField>
                            </div>
                        </div>
                    </div>
                    <div className="page-footer-section">
                        {this._renderFooter()}
                    </div>
                </div>
            );
    }
}

// function mapStateToProps({application, authorisers, loading}) {
//     return {
//         applicationId: application && application.application ? application.application.id : '',
//         application: application && application.application ? application.application : {},
//         authorisers: authorisers && authorisers.authorisers ? authorisers.authorisers : [],
//         loading,
//     };
// }

// function mapDispatchToProps(dispatch) {
//     return {actions: bindActionCreators({...actions, trackData}, dispatch)}
// }

function mapStateToProps({tpfa: {id, application}, bol: {profiles}, impersonation, loading}) {
    return {
        id,
        application,
        loading,
        profiles,
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...actions, trackData,
            // fetchIndiviualInfo,  fetchBolProfiles,
            submitApplication, additionalProductChange,saveApplication
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TPFAConfirmAuthorisers);