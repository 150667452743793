import React, { useState } from "react";
import FormField from "../formField/FormField";
import SearchComponent from "../SearchComponent";
import Toggler from "../Toggler";
import Select from 'react-select';
import FileUpload from "../FileUpload";
import { FormGroup } from "react-bootstrap";
import validate from 'validate.js';
import { merge } from 'lodash';

const NewAuthorizedPerson = (props) => {
    const {
        errors, newAP, onChange, onAddressChange, handleSelectChange, onResetError, onHandleUpload, allowOnlyNumbers, allowOnlyCharacters,
        onHandleRemoveDocument, onSubmit, showUpload, onSearch, isSearchComplete, onToggleCitizen, saCitizen
    } = props;

    const shouldDisableInput = !(!saCitizen || isSearchComplete);

    if (!newAP) return null;

    const showUploadButtons = showUpload ? showUpload : null;

    const phoneOptions = _.map(newAP.telephones, (t) => ({ value: t.msisdn, label: t.msisdn }));
    const addressOptions = _.map(newAP.addresses, (t) => ({ value: t.line1, label: t.line1 }));

    const hasPhoneOptions = _.size(phoneOptions) > 0;
    const hasAddressOptions = _.size(phoneOptions) > 0;

    const [authoriserErrors, setAuthoriserErrors] = useState(null);

    const validateForm = (newAP) => {
        const options = { fullMessages: false };
        if (!newAP.address) {
            newAP.address = {
                line1: '',
                line2: '',
                line3: '',
                postalCode:'',
            };
        }
        const constraints = {
            identityNumber: { presence: { allowEmpty: false }, length: { minimum: 1, message: "Can't Be Blank" } },
            firstName: { presence: { allowEmpty: false }, length: { minimum: 1, message: "Can't Be Blank" } },
            lastName: { presence: { allowEmpty: false }, length: { minimum: 1, message: "Can't Be Blank" } },
            phoneNumber: { presence: { allowEmpty: false }, length: { minimum: 1, message: "Can't Be Blank" } },
            email: { presence: { allowEmpty: false }, email: true, length: { minimum: 1, message: "Can't Be Blank" } },
        };

        // Validate nested constraints if address is present
        const addressConstraints = {
            line1: { presence: { allowEmpty: false }, length: { minimum: 1, message: "Can't Be Blank" } },
            line2: { presence: { allowEmpty: false }, length: { minimum: 1, message: "Can't Be Blank" } },
            line3: { presence: { allowEmpty: false }, length: { minimum: 1, message: "Can't Be Blank" } },
            postalCode: { presence: { allowEmpty: false }, length: { minimum: 1, message: "Can't Be Blank" } },
        };

        // Validate the main constraints
        const mainErrors = validate(newAP, constraints, options);
        const addressErrors = newAP && newAP.address ? validate(newAP.address, addressConstraints, options) : {};

        // Merge the errors using Lodash merge
        const mergedErrors = merge({}, mainErrors, { address: addressErrors });

        setAuthoriserErrors(mergedErrors);
        console.log(mergedErrors);
        return _.isEmpty(mergedErrors);
    };

    return (
        <div className="">
            <div className="">
                <div className="product-heading">
                    New Authorised Person
                </div>
                <div className="title-gradient" />
            </div>
            <Toggler
                label="South African Citizen / Resident?"
                isOn={saCitizen}
                onToggle={() => onToggleCitizen(saCitizen)} 
            />
            <div className="">
                <div className="col-xs-12 col-md-6">
                    <div className="card-container-form">
                        <FormField id="regNumber" className="form-group"
                            error={authoriserErrors && authoriserErrors.identityNumber} >
                            {
               
                                
                                (shouldDisableInput || saCitizen)  ?
                                <SearchComponent
                                        label={"ID Number"}
                                        onChange={(event) => {
                                            allowOnlyNumbers(event);
                                            errors && errors.identityNumber && onResetError && onResetError('identityNumber');
                                            onChange(event, 'identityNumber')
                                        }}
                                        onSearch={onSearch}
                                        errors={errors}
                                        searchValue={newAP && newAP.identityNumber || ''}
                                    /> :
                                    <div>
                                        <label>ID/Passport Number <span className="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="idNumber"
                                            value={newAP.identityNumber || ''}
                                            onChange={(event) => {
                                                errors && errors.identityNumber && onResetError && onResetError('identityNumber');
                                                onChange(event, 'identityNumber')
                                            }}
                                        />
                                    </div>
                                  
                            }
                        </FormField>

                        <FormField className={"form-group"} id="firstname" error={authoriserErrors && authoriserErrors.firstName}>
                            <label>First Name <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                disabled={isSearchComplete || shouldDisableInput}
                                className="form-control"
                                id="firstName"
                                value={newAP.firstName || ''}
                                onChange={(event) => {
                                    allowOnlyCharacters(event);
                                    errors && errors.firstName && onResetError && onResetError('firstName');
                                    onChange(event, 'firstName')
                                }}
                            />
                        </FormField>

                        <FormField className={"form-group"} id="lastName" error={authoriserErrors && authoriserErrors.lastName}>
                            <label>Surname <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                disabled={isSearchComplete || shouldDisableInput}
                                id="surname"
                                value={newAP.lastName || ''}
                                onChange={(event) => {
                                    allowOnlyCharacters(event);
                                    errors && errors.lastName && onResetError && onResetError('lastName');
                                    onChange(event, 'lastName')
                                }}
                            />
                        </FormField>


                        <FormField className={"form-group"} id="contactNum" error={authoriserErrors && authoriserErrors.phoneNumber}>
                            <label>Contact Number <span className="text-danger">*</span></label>
                            {
                                hasPhoneOptions ?
                                    <Select
                                        menuContainerStyle={{ zIndex: 999 }}
                                        disabled={shouldDisableInput}
                                        name="contactNumber"
                                        onChange={(event) => handleSelectChange(event, 'phoneNumber')}
                                        options={phoneOptions}
                                        value={newAP.phoneNumber || ''}
                                    />
                                    :
                                    <FormField id="number" error={errors && errors.phoneNumber}>
                                        <input
                                            className="form-control"
                                            disabled={shouldDisableInput}
                                            id="contactNumber"
                                            onChange={(event) => {
                                                allowOnlyNumbers(event);
                                                errors && errors.phoneNumber && onResetError && onResetError('phoneNumber');
                                                onChange(event, 'phoneNumber')
                                            }}
                                            type="text"
                                            value={newAP.phoneNumber || ''}
                                        />
                                    </FormField>
                            }
                        </FormField>
                        <FormField className={"form-group"} id="emailAddress" error={authoriserErrors && authoriserErrors.email}>
                            <label>Email Address</label>
                            <input
                                type="text"
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="email"
                                value={newAP.email || ''}
                                onChange={(event) => {
                                    errors && errors.email && onResetError && onResetError('email');
                                    onChange(event, 'email')
                                }}
                            />
                        </FormField>
                    </div>
                </div>
                <div className="col-xs-12 col-md-6">
                    <div className="card-container-form">
                        {
                            hasAddressOptions &&
                            <FormField className={"form-group"} id="contactNum" error={authoriserErrors && authoriserErrors.addressing} >
                                <label>Choose Address</label>
                                <Select
                                    menuContainerStyle={{ zIndex: 999 }}
                                    disabled={shouldDisableInput}
                                    name="addressing"
                                    onChange={(event) => handleSelectChange(event, 'address')}
                                    options={addressOptions}
                                    value={newAP.address ? newAP.address.line1 : ""}
                                />
                            </FormField>
                        }
                        <FormField className="form-group" id="regAddress" error={authoriserErrors && authoriserErrors.address && authoriserErrors.address.line1} >
                            <label htmlFor="individualAddressLine1">Address <span
                                className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                id="individualAddressLine1"
                                disabled={shouldDisableInput}
                                onChange={(event) => {
                                    onAddressChange(event, 'line1')
                                }}
                                type="text"
                                value={newAP.address ? newAP.address.line1 : ""}

                            />
                        </FormField>

                        <FormField className="form-group" id="line2" error={authoriserErrors && authoriserErrors.address && authoriserErrors.address.line2} >
                            <label htmlFor="individualAddressLine2">Suburb</label>
                            <input
                                className="form-control"
                                id="individualAddressLine2"
                                disabled={shouldDisableInput}
                                onChange={(event) => {
                                    allowOnlyCharacters(event);
                                    onAddressChange(event, 'line2')
                                }}
                                type="text"
                                value={newAP.address ? newAP.address.line2 : ""}
                            />
                        </FormField>

                        <FormField className="form-group" id="line3" error={authoriserErrors && authoriserErrors.address && authoriserErrors.address.line3}  >
                            <label htmlFor="individualCity">City <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                disabled={shouldDisableInput}
                                className="form-control"
                                id="individualCity"
                                value={newAP.address ? newAP.address.line3 : ""}
                                onChange={(event) => {
                                    allowOnlyCharacters(event);
                                    onAddressChange(event, 'line3')
                                }}
                            />
                        </FormField>

                        <FormField className="form-group" id="regPostalCode" error={authoriserErrors && authoriserErrors.address && authoriserErrors.address.postalCode} >
                            <label htmlFor="stateOrProvince">Zip/Postal Code <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                max={9999}
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="individualZipOrPostalCode"
                                value={newAP.address ? newAP.address.postalCode : ""}
                                onChange={(event) => {
                                    allowOnlyNumbers(event);
                                    onAddressChange(event, 'postalCode')
                                }}
                            />
                        </FormField>
                        
                        <FormGroup>
                            <div className={'row'} style={{ marginLeft: '0' }}>
                                <label className={'control-label'}>Identity Document</label>
                            </div>
                            <FileUpload
                                disabled={false}
                                documentType={'Identity Document'}
                                onUpload={props.handleUpload}
                                onRemoveDocument={false}
                            />
                        </FormGroup>
                        <div style={{ zIndex: -1, padding: 0, margin: 0 }}>

                            {
                                showUploadButtons &&
                                <FormField
                                    className="form-group"
                                    style={{ display: "flex", flexDirection: "column" }}
                                    error={errors && errors.proofOfAddress ? ["Document required"] : null}
                                >
                                    <label>Proof of Address</label>
                                    <FileUpload
                                        documentType={DOC_TYPES.proofOfAddress}
                                        onUpload={(base64, inProgress, onComplete) => {
                                            errors && errors.proofOfAddress && onResetError('proofOfAddress');
                                            onHandleUpload && onHandleUpload(base64, inProgress, onComplete, DOC_TYPES.proofOfAddress)
                                        }}
                                        onRemoveDocument={() => {
                                            errors && errors.proofOfAddress && onResetError('proofOfAddress');
                                            onHandleRemoveDocument && onHandleRemoveDocument(DOC_TYPES.proofOfAddress);
                                        }}
                                    />
                                </FormField>
                            }

                            {
                                showUploadButtons &&
                                <FormField
                                    className="form-group"
                                    style={{ display: "flex", flexDirection: "column" }}
                                    error={errors && errors.proofOfId ? ["Document required"] : null}
                                >
                                    <label>Proof of ID</label>
                                    <FileUpload
                                        documentType={DOC_TYPES.proofOfId}
                                        onUpload={(base64, inProgress, onComplete) => {
                                            errors && errors.proofOfId && onResetError('proofOfId');
                                            onHandleUpload && onHandleUpload(base64, inProgress, onComplete, DOC_TYPES.proofOfId)
                                        }}
                                        onRemoveDocument={() => {
                                            errors && errors.proofOfId && onResetError('proofOfId');
                                            onHandleRemoveDocument && onHandleRemoveDocument(DOC_TYPES.proofOfId);
                                        }}
                                    />
                                </FormField>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="action-button-container" style={{ width: "100%" }}>
                <div />
                <button
                    className="action-btn-secondary btn-sm"
                    style={{ margin: "0px 30px" }}

                    onClick={() => {
                        validateForm(newAP);
                        onSubmit();
                    }}

                    // onClick={() => {
                    //     if (validateForm(newAP)) {
                    //         // Log to verify that onSubmit is a function
                    //         console.log("onSubmit is a function", typeof props.onSubmit);

                    //         // Call the onSubmit function
                    //         onSubmit();
                    //     }
                    // }}
                    type="button"
                >
                    Add
                </button>


            </div>
        </div>
    )
}

export default NewAuthorizedPerson;
