import * as types from './actionTypes';
import axios from 'axios';
import {API_ROOT} from "../api-config";
import {authCheck,loadingPageDataFinish, loadingPageDataStart} from "./appLoading";

const TRACKING_URL = `${API_ROOT}/api/global-payments`;

export function fetchAccountTransactions(accountNumbers, onComplete = null){
    return async dispatch => {
        try {
            loadingPageDataStart && loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            let queryString = '';
            _.map(accountNumbers, acc=>queryString = queryString + `&acc=${acc}`);
            const responseData = await axios.get(`${TRACKING_URL}?${queryString}`).then(response => response.data);

            loadingPageDataFinish && loadingPageDataFinish(dispatch);
            if (responseData.success) {
                dispatch({type: types.FETCH_ACCOUNT_TRANSACTIONS_SUCCESS, transactions: responseData.data, errorMessage: null});
                onComplete && onComplete(true);
            } else {
                console.log("tracking.fetchAccountTransactions.errorMessage ===>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Couldn't retrieve transactions."});
            }
        } catch (error){
            console.log("tracking.fetchAccountTransactions.error ===>", error);
            authCheck(dispatch,error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function fetchTransaction(transactionId, onComplete){
    return async dispatch => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(`${TRACKING_URL}/${transactionId}`).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.FETCH_TRANSACTION_SUCCESS, selectedTransactionDetails: responseData.data, errorMessage: null});
                onComplete && onComplete();
            } else {
                onComplete && onComplete(false);
                console.log("tracking.fetchTransaction.errorMessage ===>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Couldn't retrieve selected transaction details."});
            }
        } catch (error){
            onComplete && onComplete();
            console.log("tracking.fetchTransaction.error ===>", error);
            authCheck(dispatch,error);
        }
    }
}