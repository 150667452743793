const CASHMANGROUPS = [
    {
        name: 'Ashburton Management Company RF Proprietary Limited',
        number: '07851'
    },
    {
        name: 'Boutique Collective Investments Investments RF Pty Limited',
        number: '07246'
    },
    {
        name: 'Community Growth Management Company Limited',
        number: '01630'
    },
    {
        name: 'Glacier Management Company RF Pty Limited',
        number: '07040'
    },
    {
        name: 'H4 Collective Investments RF Pty Ltd',
        number: '07852'
    },
    {
        name: 'IP Management Company RF Proprietary Limited',
        number: '07249'
    },
    {
        name: 'Kagiso Collective Investments Limited',
        number: '07885'
    },
    {
        name: 'Momentum Collective Investments RF Pty Ltd',
        number: '06188'
    },
    {
        name: 'Nedgroup Collective Investments RF Pty Limited',
        number: '06408'
    },
    {
        name: 'Oasis Crescent Management Company Limited',
        number: '04906'
    },
    {
        name: 'Oasis Crescent Management Company Limited A',
        number: '04906A'
    },
    {
        name: 'Old Mutual Unit Trust Managers RF Pty Limited',
        number: '01630'
    },
    {
        name: 'Prudential Portfolio Managers Unit Trust Limited',
        number: '07247'
    },
    {
        name: 'PSG Collective Investments RF Limited',
        number: '07251'
    },
    {
        name: 'Sanlam Collective Investments RF Proprietary Limited',
        number: '07040'
    },
    {
        name: 'Strategic Investment Service Management Company RF Pty Limited',
        number: '06187'
    },
    {
        name: 'Sygnia Collective Investments RF Pty Limited',
        number: '07740'
    },
    {
        name: 'Sygnia Itrix RF Proprietary Limited',
        number: '07252'
    }

];

export default CASHMANGROUPS;
