import React from 'react';
import _ from 'lodash';
import * as types from '../../actions/actionTypes';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import validate from 'validate.js';
import ReactLoading from 'react-loading';

import * as actions from '../../actions/reports';
import { fetchHierachy } from '../../actions/entity';
import {fetchAccountsForProduct, onSelectAccount, onSelectAllAccounts, fetchIndividualAccount} from '../../actions/kyc';
import FormField from '../formField/FormField';
import ConfirmAndSignatoriesAccountList from '../accounts/ConfirmAndSignatoriesAccountList';
import EntityList from '../legalentity/EntityList';
import NoLinkedEntitiesMessage from '../legalentity/NoLinkedEntitiesMessage';
import '../../styles/rules.css';
import Popup from '../Popup';
import { Math } from 'core-js/library/web/timers';
import { Right } from 'react-bootstrap/lib/Media';

const REPORT_TYPES = [
    {
        label: 'Account Signatories',
        value: 'ACCOUNT_SIGNATORIES'
    },
    {
        label: 'Confirmation Letter',
        value: 'CONFIRMATION_LETTER'
    }
];

const DELIVERY_METHOD = {
    email: 'Email',
    download: 'Download'
};

const EmailReportMessage = ({ success }) => {
    if (success === true) {
        return (
            <div>
                <div>
                    <div className="product-heading">
                        Successful
                    </div>
                    <div className="title-gradient" />
                </div>
                <br />
                <div className="popup-content">
                    <div className="flexColumn">
                        <div>
                            <p>
                                Email report request sent.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div>
            <div>
                <div className="product-heading">
                    Unsuccessful
                </div>
                <div className="title-gradient" />
            </div>
            <br />
            <div className="popup-content">
                <div className="flexColumn">
                    <div>
                        <p>There was an error emailing the report.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const maxAccCount = 40;

class ConfirmAndSignatoriesReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            filteredAccounts: null,
            form: {
                accounts: null,
                emailAddress: null,
                reportName: REPORT_TYPES[0].value,
                deliveryMethod: DELIVERY_METHOD.download,
                isFetchingAccounts: false,
                isLoading: false,
                isProcessingReport: false,
            },
            selectedAccount: null,
            selectedEntity: null,
            isProductDisabled: true,
            searchTerm: '',
            isMultiAccountSelect: true,
            showEmailReportCompleteMessage: null,
            selectedProduct: "BCA",
            selectedCount: 0,
            searchButton: false,
            searchError:''
        };
        this.handleOnChange = this._handleOnChange.bind(this);
        this.handleOnProductChange = this._handleOnProductChange.bind(this);
        this.handleOnFilterAccounts = this._handleOnFilterAccounts.bind(this);
        this.handleOnSelectAccount = this._handleOnSelectAccount.bind(this);
        this.handleOnSelectAllAccounts = this._handleOnSelectAllAccounts.bind(this);
        this.searchAccount = this._searchAccount.bind(this);
        this.handleOnSelectEntity = this._handleOnSelectEntity.bind(this);
        this.handleSubmitReportRequest = this._handleSubmitReportRequest.bind(this);
    }

    componentWillMount() {
        this._fetchData();
    }

    _fetchData() {
        const { actions, impersonation} = this.props;
        actions.fetchHierachy(impersonation);
        
    }

    _handleOnProductChange(event) {
        const { actions } = this.props;
        const { selectedEntity } = this.state;
        const selectedProduct = event.target.value;

        this.setState({ selectedProduct: selectedProduct, selectedCount: 0 });
        this.setState({ isFetchingAccounts: true, selectedProduct: selectedProduct });
        const onComplete = () => {
            this.setState({ isFetchingAccounts: false });
        };
        actions.fetchAccountsForProduct(`cifNumber=${selectedEntity.cif}`, selectedProduct, 'selectedEntityAccounts', onComplete, false);
    };

    _handleOnChange({ target }, attribute) {
        const { accounts, actions } = this.props;
        const { form } = this.state;
        const product = "BCA"
        let value = target.value;
        
        if(value === 'ACCOUNT_SIGNATORIES'){
            this.setState({ isProductDisabled: true, selectedProduct: product });
        }
        if(value === 'CONFIRMATION_LETTER'){
            this.setState({ isProductDisabled: false, selectedProduct : product});
        }

        this.setState({ selectedCount: 0 });
        this.props.actions.clearAccounts();
        
        if (attribute === 'emailAddress') {
            value = _.size(value) === 0 ? null : _.split(value, ',');
        }

        let update = { form: _.extend({}, form, { [attribute]: value }) };
        if (attribute === 'reportName') {
            update = _.extend(update, {
                isMultiAccountSelect: value !== 'CONFIRMATION_LETTER',
                selectedAccount: null,
                filteredAccounts: null
            });
            actions.onSelectAllAccounts(accounts, false, 'selectedEntityAccounts');
        }
        this.setState(update);
    };

    _handleOnFilterAccounts(searchTerm) {
        const { accounts } = this.props;
        const { searchError } = this.state;
        let filteredAccounts = null;
        if (searchTerm) {
            this.setState({ searchButton: true});
            filteredAccounts = _.filter(accounts, acc => {
                return acc && (acc.accountNumber.includes(searchTerm) || acc.name && acc.name.toLowerCase().includes(searchTerm.toLowerCase()));
            });
        }
        if(!searchTerm) {
            this.setState({ searchButton: false});
        }
        this.setState({ filteredAccounts, searchTerm, searchError: ''});
    }

    _handleOnSelectEntity = (selectedEntity) => {
        const { actions } = this.props;
        const { selectedProduct } = this.state;

        this.setState({ selectedEntity });
        this.props.actions.clearAccounts(); 
        
        const onComplete = () => {
            this.setState({ isFetchingAccounts: false });
        };
        actions.fetchAccountsForProduct(`cifNumber=${selectedEntity.cif}`, selectedProduct, 'selectedEntityAccounts', onComplete, false);
      };


    _handleOnSelectAccount({ target }, account) {
        const { accounts, actions } = this.props;
        const { errors, filteredAccounts, searchTerm, selectedCount } = this.state;

        const isSelected = !account.isSelected;

        errors && errors.accounts && this._resetError('accounts');

        const newSelectedCount = isSelected ? selectedCount + 1 : selectedCount - 1;

        if (isSelected && selectedCount >= maxAccCount) {
            return;
        }
        const onComplete = () => {
            if (_.size(filteredAccounts) > 0) {
                this._handleOnFilterAccounts(searchTerm);
            }
        };
        
        this.setState({ selectedCount: newSelectedCount });
        actions.onSelectAccount(accounts, account, isSelected, 'selectedEntityAccounts', true, null, onComplete);
    }

    _handleOnSelectAllAccounts(isSelected) {
        const { accounts, actions } = this.props;
        const { errors, filteredAccounts, searchTerm } = this.state;
        errors && errors.accounts && this._resetError('accounts');
        const onComplete = () => {
            if (_.size(filteredAccounts) > 0) {
                this._handleOnFilterAccounts(searchTerm);
            }
        };
        actions.onSelectAllAccounts(accounts, isSelected, 'selectedEntityAccounts', filteredAccounts, onComplete);
    }

    _searchAccount() {
        const { accounts, actions } = this.props;
        const { errors, selectedProduct, selectedEntity, searchTerm, searchError } = this.state;
        errors && errors.accounts && this._resetError('accounts');
        const err1 = 'This account already exists in the list';
       
        if (!searchTerm || isNaN(searchTerm)) {
            console.log('Search term is not a valid number');
            return;
        }
        
        if (accounts.some(({ accountNumber }) => accountNumber === searchTerm)) {
            this.setState({ searchError: err1 });
            console.log("This account already exists in the list");
            return;
        }

        this.setState({ isFetchingAccounts: true});
        const onComplete = () => {
            this.setState({ isFetchingAccounts: false });
            this._handleOnFilterAccounts(searchTerm);
        };
        actions.fetchIndividualAccount(`cifNumber=${selectedEntity.cif}`, searchTerm,'selectedEntityAccounts', selectedProduct, onComplete, false);
    } 


    _resetError(attribute) {
        const { errors } = this.state;
        this.setState({ errors: _.extend({}, errors, { [attribute]: null }) });
    }

    _validateForm(hasAccounts) {
        const { form } = this.state;
        const isEmail = form && form.deliveryMethod === DELIVERY_METHOD.email;

        const options = { fullMessages: false };
        const constraints = _.extend(
            { deliveryMethod: { presence: true }, reportName: { presence: true } },
            !hasAccounts ? { accounts: { presence: true } } : null
        );

        let errors = validate(form, constraints, options);

        if (isEmail) {
            const emailConstraint = { emailAddress: { presence: true, email: true } };

            const emails = _.split(form && form.emailAddress, ',');

            _.map(emails, emailAddress => (
                errors = _.extend({}, errors, validate({ emailAddress }, emailConstraint, options))
            )
            );
        }
        this.setState({ errors });
        return _.isEmpty(errors);
    }

    _filterSelectedAccounts(accountsList) {
        let selectedAccounts = [];
        _.filter(accountsList, acc => {
            if (acc.isSelected) {
                const account = _.pick(acc, ['originalAccountNumber', 'accountNumber', 'name', 'branch', 'accountOpenDate', 'accountType']);
                selectedAccounts = _.union(selectedAccounts, [account]);
            }
        });
        return { selectedAccounts };
    }

    _handleSubmitReportRequest(e) {
        e.preventDefault();
        const { accounts, actions } = this.props;
        const { form, isMultiAccountSelect, selectedAccount, selectedEntity } = this.state;
        const legalEntityId = selectedEntity && selectedEntity.goldTierId;

        const { selectedAccounts } = this._filterSelectedAccounts(accounts);

        const hasAccounts = _.size(selectedAccounts) > 0;

        if (this._validateForm(hasAccounts)) {
            this.setState({ isProcessingReport: true });
            const deliveryMethod = form && form.deliveryMethod;
            const reportName = form && form.reportName;
            const requestBody = { accounts: selectedAccounts, legalEntityId };

            if (deliveryMethod === DELIVERY_METHOD.email) {
                const emailAddress = form && form.emailAddress;
                const onEmailComplete = (success = false) => {
                    this.setState({ isProcessingReport: false, showEmailReportCompleteMessage: { success } });
                };
                actions.emailReport(reportName, _.extend({}, requestBody, { emailAddress }), onEmailComplete);
            } else {
                const onDownloadComplete = () => this.setState({ isProcessingReport: false });
                // actions.downloadReport(reportName, requestBody, onDownloadComplete);
                actions.downloadConfirmationReport(reportName, selectedEntity, requestBody, onDownloadComplete);
                
            }
        }
        return false;
    };

    _renderEntitiesSection() {
        const { entities } = this.props;
        const { selectedEntity } = this.state;

        return (
            <div style={{ width: '100%' }} >
                <div>
                    <div className="product-heading">
                        Legal Entities
                    </div>
                    <div className="title-gradient" />
                </div>
                <div style={{paddingTop:'20px'}}>

                <EntityList
                    entities={entities}
                    onSelect={this.handleOnSelectEntity}
                    selectedEntity={selectedEntity}
                />
                </div>
            </div>
        );
    }

    _renderReportInputs() {
        const { errors, form, selectedEntity, isProductDisabled } = this.state;
        return (
            <div>
                <div>
                    <div className="product-heading">
                        Reports
                    </div>
                    <div className="title-gradient" />
                </div>

                <div style={{paddingTop:'20px'}}>
                    <div className="form-group">
                        <label>Report</label>
                        <select className="form-control" 
                                disabled={!selectedEntity} 
                                onChange={(event) => { this.handleOnChange(event, 'reportName');  }}>
                                {   
                                    _.map(REPORT_TYPES, (r, i) => (<option key={`reo-${i}`} value={r.value}>{r.label}</option>))
                                }
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Products</label>
                        <select className="form-control" 
                                disabled={!selectedEntity || isProductDisabled} 
                                onChange={(event) => { this.handleOnProductChange(event);  }} >
                            <option value="BCA"> Business Current Account </option>
                            <option value="TPFA"> TPFA Accounts </option>
                            <option value="SHARIAH"> Shari’ah Business Current Account </option>
                            <option value="SAVINGS"> Savings Account </option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label>How would you like to get the report?</label>
                        <select className="form-control" disabled={!selectedEntity}
                            onChange={(event) => {
                                this.handleOnChange(event, 'deliveryMethod');
                            }}>
                            <option>{DELIVERY_METHOD.download}</option>
                            <option>{DELIVERY_METHOD.email}</option>
                        </select>
                    </div>

                    {
                        form && form.deliveryMethod === DELIVERY_METHOD.email &&
                        <FormField className="form-group" id="turnover" error={errors && errors.emailAddress}>
                            <label>Recipients</label>
                            <input
                                className="form-control"
                                placeholder={'Comma separated'}
                                onChange={(event) => {
                                    errors && errors.emailAddress && this._resetError('emailAddress');
                                    this.handleOnChange(event, 'emailAddress');
                                }}
                                multiple='multiple'
                                name="emailaddress"
                                type="email"
                            />
                        </FormField>
                    }
                </div>
            </div>
        );
    }

    _renderReportSection() {
        const { accounts} = this.props;
        const { errors, filteredAccounts, searchError, isFetchingAccounts, isProcessingReport, selectedAccount, searchTerm, searchButton, isMultiAccountSelect, selectedCount } = this.state;
        if (isFetchingAccounts) {
            return (
                <div className="rule-spinner">
                    <ReactLoading type="spokes" color="#444" />
                </div>
            );
        }
        const hasAccounts = _.size(accounts) > 0;
        return (
            <div>
                <div>

                    <div className="section-title" style={{padding:0}}>
                        Accounts <span style={{ float: 'right' }}> Selected Accounts : {selectedCount} / {maxAccCount}</span>
                    </div>
                    {
                        selectedCount >= maxAccCount && (<span class="form-error" style={{ float: 'right' }}>You cannot select more than {maxAccCount} accounts.</span>)
                    }

                    <FormField error={errors && errors.accounts ? ['An account should be selected.'] : ''}>
                        {
                            (!hasAccounts && !isFetchingAccounts) ?
                                <p style={{ color: 'firebrick' }}>Selected Entity with Product Has No Accounts.</p>
                                :
                                <ConfirmAndSignatoriesAccountList
                                    accounts={accounts}
                                    filteredAccounts={filteredAccounts}
                                    onFilterAccounts={this.handleOnFilterAccounts}
                                    onSelect={this.handleOnSelectAccount}
                                    onSelectAll={this.handleOnSelectAllAccounts}
                                    selectedAcc={selectedAccount}
                                    isMultiSelect={isMultiAccountSelect}
                                    selectedCount={selectedCount}
                                    searchAccount={this.searchAccount}
                                    searchButton ={searchButton}
                                    searchTerm = {searchTerm}
                                    searchError ={searchError}
                                />
                        }
                    </FormField>
                </div>
                {
                    isProcessingReport && <div className="rule-spinner">
                        <ReactLoading type="spokes" color="#444" />
                    </div>
                }
            </div>
        );
    }
    _renderPopUps() {
        const { showEmailReportCompleteMessage } = this.state;
        if (showEmailReportCompleteMessage) {
            return (
                <Popup onClose={() => this.setState({ showEmailReportCompleteMessage: null })}>
                    <EmailReportMessage
                        success={showEmailReportCompleteMessage && showEmailReportCompleteMessage.success}
                    />
                </Popup>
            );
        }
        return null;
    }

    _renderFooter() {
        const { selectedEntity, isFetchingAccounts } = this.state;
        return (
            <div className="action-button-container">
                <div />
                {
                    (selectedEntity && !isFetchingAccounts) &&
                    <button className="action-btn-primary btn-sm" onClick={this.handleSubmitReportRequest}>
                        Get Report
                    </button>
                }
            </div>
        );
    }

    render() {
        const { entities, loading, systemError } = this.props;
        if (loading || systemError && systemError.show) return null;
        const { selectedEntity } = this.state;
        const hasEntities = _.size(entities) > 0;
        if (!hasEntities) return (
            <div className="page-container">
                <div className="page-main-section">
                    <NoLinkedEntitiesMessage />
                </div>
            </div>
        );
        return (
            <div className="page-container" style={{display:'flex'}}>
                <div className="page-main-section" style={{display:'flex', minWidth: '80%'}}>
                    <div className="row" style={{ margin:0, display:'flex', flexWrap:'wrap',  minWidth: '100%'}}>
                        <div className="col-lg-6 col-md-6 col-sm-12" style={{flex:1}}>
                            {this._renderEntitiesSection()}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12" style={{flex:1}}>
                            <div style={{ width: '100%'}}>
                                {this._renderReportInputs()}
                                {selectedEntity && this._renderReportSection()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-footer-section" style={{ width: '80%' }}>
                    {this._renderFooter()}
                </div>
                {this._renderPopUps()}
            </div>
        );
    }
}

function mapStateToProps({ product, accounts, entity, impersonation, loading, systemError }) {
    return {
        accounts: accounts && accounts.selectedEntityAccounts ? accounts.selectedEntityAccounts : [],
        entities: entity.entities ? entity.entities : [],
        products: product && product.products ? product.products : [],
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading,
        systemError
    };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...actions,
        fetchAccountsForProduct,
        fetchHierachy,
        onSelectAccount,
        onSelectAllAccounts,
        fetchIndividualAccount,
        clearAccounts: () =>
          dispatch({
            type: types.FETCH_ACCOUNTS_EMPTY,
            accountsEmpty: true,
            objName: "selectedEntityAccounts",
            accounts: [],
          }),
      },
      dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAndSignatoriesReports);
