import React from "react";
import _ from "lodash";
import FormField from "../../formField/FormField";
import {ButtonToolbar, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';

import "../../../styles/ConfirmApplication.css";
import "../../../styles/Global.css";
import "react-datepicker/dist/react-datepicker.css";

const labelClassList = "col-xs-12 col-sm-4 col-md-4";
const inputClassList = "col-xs-12 col-sm-8 col-md-8";

export const CustomerBeneficiaryComponent = (props) => {

    return (
       <div className="col-md-12" style={{minHeight: 200}}>
            <div className="form-horizontal col-md-12" style={{padding: "0 10"}}>
                <div className="col-md-12">

                    <div className="section-title col-md-12" style={{paddingBottom: 0, marginBottom: "0px"}}>
                        <span>Customer Beneficiary (Field 59)</span>
                        <hr style={{padding: 0}}/>
                    </div>

                    <div className="col-md-12 form-group">
                        <ButtonToolbar>
                            <ToggleButtonGroup
                                type="radio"
                                name="customerBeneficiaryBIC"
                                value={props.instruction && props.instruction.customerBeneficiaryBIC}
                                onChange={(event) => props.handleOnToggle(event, "customerBeneficiaryBIC")}>
                                <ToggleButton value={true}>Have BIC</ToggleButton>
                                <ToggleButton value={false}>No BIC</ToggleButton>
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>

                    {
                        props.instruction.customerBeneficiaryBIC ? (
                            <div className="col-md-12 form-group">

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="custBenSwiftAddress">Swift Address</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="custBenSwiftAddress" type="text" name="custBenSwiftAddress"
                                                    value={props.instruction && props.instruction.custBenSwiftAddress}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="custBenAccountNumber">Account Number</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="custBenAccountNumber" type="text" name="custBenAccountNumber"
                                                    value={props.instruction && props.instruction.custBenAccountNumber}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="custBenClearingCode">Clearing Code</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="custBenClearingCode" type="text" name="custBenClearingCode"
                                                    value={props.instruction && props.instruction.custBenClearingCode}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ) : (
                            <div>
                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="custBenInstitutionName">Institution Name</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="custBenInstitutionName" type="text" name="custBenInstitutionName"
                                                    value={props.instruction && props.instruction.custBenInstitutionName}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="custBenAccountNumber">Account Number</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="custBenAccountNumber" type="text" name="custBenAccountNumber"
                                                    value={props.instruction && props.instruction.custBenAccountNumber}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="custBenAddress1">Address</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="custBenAddress1" type="text" name="custBenAddress1"
                                                    value={props.instruction && props.instruction.custBenAddress1}
                                                    onChange={(event) => props.handleOnChange(event)} placeholder="Address Line 1"/>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="custBenAddress2">&nbsp;</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="custBenAddress2" type="text" name="custBenAddress2"
                                                    value={props.instruction && props.instruction.custBenAddress2}
                                                    onChange={(event) => props.handleOnChange(event)} placeholder="Address Line 2"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="custBenAddress3">&nbsp;</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="custBenAddress3" type="text" name="custBenAddress3"
                                                    value={props.instruction && props.instruction.custBenAddress3}
                                                    onChange={(event) => props.handleOnChange(event)} placeholder="Address Line 3"/>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        )
                    }

                    <div className="col-md-12 form-group">
                        <div className="col-md-4">
                            <label htmlFor="custBenIbanNo">Iban Number</label>
                        </div>
                        <div className="col-md-8">
                            <div>
                                <input className="form-control" id="custBenIbanNo" type="text" name="custBenIbanNo"
                                        value={props.instruction && props.instruction.custBenIbanNo}
                                        onChange={(event) => props.handleOnChange(event)} placeholder="Iban Number"/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

}