import * as types from '../actions/actionTypes';

export const trackData = (key: string, value: string) => {
    return (dispatch) => {
        dispatch({type: types.TRACKING, key, value})
    }
};

export const setTrackData = (key: string, value: string) => {
    return (dispatch) => {
        dispatch({type: types.TRACKING_DATA, key, value})
    }
};

export const submitTracking = () => {
    return (dispatch) => {
        dispatch({type: types.TRACKING_SUBMIT})
    }
};

export const trackLogin = (user: any) => {
    return (dispatch) => {
        dispatch({type: types.LOGIN_TRACKING, user});
    }
}

export const startApplicationTracking = (applicationName: string) => {
    return (dispatch) => {
        dispatch({type: types.START_APPLICATION, applicationName})
    }
}

export const completeApplicationTracking = (applicationName: string) => {
    return (dispatch) => {
        dispatch({type: types.COMPLETE_APPLICATION, applicationName})
    }
}