import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import validate from 'validate.js';
import { Col } from 'react-bootstrap';
import { ADDITIONAL_PRODUCTS } from './ConfirmProductPage';
import FormField from '../formField/FormField';
import Select from 'react-select';
import Popup from '../Popup';
import { useSelector } from 'react-redux';
const OTHER_PROFILE_ID = { value: 'otherId', label: 'Other' };


export const BolSetup = (props) => {

    const appEntity = useSelector(state => state.application && state.application.application.legalEntity && state.application.application.legalEntity.typeOfEntity);
    const isEntityCorp = (appEntity === 'Corporate');
    const [isAddToggled, setIsAddToggled] = useState(false);
    const [bolProduct, setBolProduct] = useState(props.bolProduct ? props.bolProduct : { id: ADDITIONAL_PRODUCTS.bolId });
    const [operator, setOperator] = useState({
        accountAccess: {
            balanceStatements: false,
            Payments: false,
            ownTransfers: false,
            notifications: false
        }
    });
    const [selectedUser, setSelectedUser] = useState(null);
    const [operatorErrors, setOperatorErrors] = useState({});
    const [isOther, setIsOther] = useState(false);


    // const handleOnChange = ({target}, attribute, innerAttribute) => {
    //     const {onChange} = props;
    //     // let value = _.trimStart(target.value);
    //     let value = target.value;
    //     console.log(value);
    //     if (innerAttribute) {
    //         // if (isEntityCorp && innerAttribute === 'shortName') {
    //         //     // If it's the "shortName" attribute, sanitize the value to allow only alphanumeric characters and spaces
    //         //     value = value.replace(/[^a-zA-Z0-9 ]/g, '');
    //         //   }
    //         value = _.extend({}, bolProduct[attribute], {[innerAttribute]: value});
    //     }
    //     setBolProduct(_.extend({}, bolProduct, {[attribute]: value}));
    //     onChange(bolProduct);
    // };


    const handleOnChange = ({ target }, attribute, innerAttribute) => {
        const { onChange } = props;
        let value = target.value;

        if (isEntityCorp && innerAttribute === 'shortName') {
            // If it's the "shortName" attribute, sanitize the value to allow only alphanumeric characters and spaces
            value = value.replace(/[^a-zA-Z0-9 ]/g, '');
        }

        const updatedAttribute = innerAttribute
            ? { [innerAttribute]: value }
            : value;

        const updatedBolProduct = { ...bolProduct, [attribute]: updatedAttribute };

        setBolProduct(updatedBolProduct);
        onChange(updatedBolProduct);
    };


    const handleSelectUserChange = (event) => {

        const { onChange } = props;
        const config = bolProduct && bolProduct.config;
        let value = event.value;

        if (value === OTHER_PROFILE_ID.value) {
            setIsOther(true);
        } else {
            setIsOther(false);
            setSelectedUser(value);
            // setBolProduct(_.extend({}, bolProduct, {operators: [], 'bolProfileId': value}));
            setBolProduct(_.extend({}, bolProduct, { config: _.extend({}, config, { operators: [] }) }, { 'bolProfileId': value }));
            onChange(bolProduct);
        }
    };

    const handleSelectChangeOperator = (event, attribute) => {
        let value = event.value;
        setOperator(_.extend({}, operator, { [attribute]: value }));
    };

    const handleOnChangeLimits = ({ target }, attribute) => {
        const { onChange } = props;
        const config = bolProduct && bolProduct.config;
        let value = _.trimStart(target.value);
        if (target.type === 'number')
            value = parseInt(value, 10);
        const limits = _.extend({}, config && config.limits, { [attribute]: value });

        setBolProduct(_.extend({}, bolProduct, { config: _.extend({}, config, { limits }) }));
        onChange(bolProduct);
    };

    const handleOptions = (event, attribute) => {

        const { onChange } = props;
        const config = bolProduct && bolProduct.config;
        let value = event.value;
        const limits = _.extend({}, config && config.limits, { [attribute]: config && config.limits ? !config.limits[attribute] : true });
        setBolProduct(_.extend({}, bolProduct, { config: _.extend({}, config, { limits }) }));
        onChange(bolProduct);
    };

    const handleOnChangeOperator = ({ target }, attribute, innerAttribute) => {
        let value = _.trimStart(target.value);
        if (innerAttribute) {
            value = _.extend({}, operator && operator[attribute], { [innerAttribute]: value });
        }
        setOperator(_.extend({}, operator, { [attribute]: value }));
    };

    const handleCheckSelect = (isSelected, attribute, innerAttribute) => {
        let value = isSelected;
        if (innerAttribute) {
            value = _.extend({}, operator && operator[attribute], { [innerAttribute]: value });
        }
        // if(innerAttribute=='all'){
        //     operator.operatorId = 'ALL';
        // }
        // if(!isSelected){
        //     operator.operatorId = '';
        // }
        setOperator(_.extend({}, operator, { [attribute]: value }));
    };


    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, { [attribute]: null }));
    };

    const validateForm = () => {
        const options = { fullMessages: false };
        const constraints = { operatorId: { presence: true, length: { minimum: 1, message: 'required' } } };
        const operatorErrors = validate(operator, constraints, options);
        setOperatorErrors(operatorErrors);
        return _.isEmpty(operatorErrors);
    };

    const handleAddOperator = () => {
        if (validateForm()) {
            const { onChange } = props;
            const config = bolProduct && bolProduct.config;
            const isFound = _.find(config && config.operators, o => ((o.operatorId && o.operatorId.toLocaleLowerCase()) === (operator.operatorId && operator.operatorId.toLocaleLowerCase())));

            let operators;
            if (!isFound) {
                operators = _.union(config && config.operators, [operator]);
            } else {
                operators = _.map(config && config.operators, o => {
                    const isMatch = ((o.operatorId && o.operatorId.toLocaleLowerCase()) === (operator.operatorId && operator.operatorId.toLocaleLowerCase()));
                    return isMatch ? operator : o;
                });
            }
            const updatedProduct = _.extend({}, bolProduct, { config: _.extend({}, config, { operators }) });
            setBolProduct(updatedProduct);
            setIsAddToggled(false);
            setOperator({
                accountAccess: {
                    balanceStatements: false,
                    Payments: false,
                    ownTransfers: false,
                    notifications: false
                }
            });
            onChange(updatedProduct);
        }
    };

    const handleRemoveOperator = (operatorId) => {
        const { onChange } = props;
        const config = bolProduct && bolProduct.config;
        const operators = _.filter(config && config.operators, o => (o.operatorId !== operatorId));
        setBolProduct(_.extend({}, bolProduct, { config: _.extend({}, config, { operators }) }));
        onChange(bolProduct);
    };

    const showAddOperator = () => {
        setIsAddToggled(!isAddToggled);
    };

    const renderOperatorForm = () => {
        const { bolProfiles } = props;
        const accountAccess = operator && operator.accountAccess;
        let operatorsOptions = [];

        _.each(bolProfiles, (entity) => {
            _.each(entity.divisions, (div) => {

                if (div.code === selectedUser) {
                    _.each(div.users, (f) => {
                        operatorsOptions.push({ 'value': f.id, 'label': `${f.id}: ${f.name}` });
                    });
                }
            });
        });

        const hasProfileOperators = _.size(operatorsOptions) > 0;

        return (
            <div>
                <div className="product-heading">
                    Operator Details
                </div>
                <div className="title-gradient" />
                <div style={{ paddingTop: '5px' }}>
                    Add User(s) you want to allow access to this account.
                </div>
                <div className="card-container-form">
                    {/* <FormField className="form-group">
                        <div className="inline-container" style={{marginLeft: 0}}
                            onClick={() => handleCheckSelect(!(accountAccess && accountAccess.all), 'accountAccess', 'all')}>
                            <div className="icon-container">
                                <i className={((accountAccess && accountAccess.all) ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                                style={{fontSize: 20}}/>
                            </div>
                            Add all Users
                        </div>
                    </FormField> */}
                    <FormField className="form-group" id="bol" error={operatorErrors && operatorErrors.operatorId}>
                        <label>User ID</label>
                        {
                            hasProfileOperators ?

                                <Select
                                    name="operatorId"
                                    onChange={(event) => {
                                        operatorErrors && operatorErrors.operatorId && setOperatorErrors('operatorId');
                                        handleSelectChangeOperator(event, 'operatorId');
                                    }}
                                    options={operatorsOptions}
                                    value={operator && operator.operatorId ? operator.operatorId : ''}
                                />
                                :
                                <input
                                    className="form-control"
                                    id="operatorId"
                                    onChange={(event) => {
                                        operatorErrors && operatorErrors.operatorId && setOperatorErrors('operatorId');
                                        handleOnChangeOperator(event, 'operatorId');
                                    }}
                                    // disabled = {accountAccess.all}
                                    type="text"
                                    value={operator && operator.operatorId || ''}
                                />
                        }
                    </FormField>
                    <br />
                    <div className="flexRow" style={{ padding: 0, margin: 0 }}>
                        <div className="flexColumn col-md-6"
                            style={{ alignItems: 'flex-start', paddingLeft: 0, marginLeft: 0 }}>

                            <div
                                className="inline-container" style={{ marginLeft: 0 }}
                                onClick={() => handleCheckSelect(!(accountAccess && accountAccess.balanceStatements), 'accountAccess', 'balanceStatements')}>
                                <div className="icon-container">
                                    <i className={((accountAccess && accountAccess.balanceStatements) ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                                        style={{ fontSize: 20 }} />
                                </div>
                                Balance & Statements
                            </div>

                            <div
                                className="inline-container" style={{ marginLeft: 0 }}
                                onClick={() => handleCheckSelect(!(accountAccess && accountAccess.payments), 'accountAccess', 'payments')}>
                                <div className="icon-container">
                                    <i className={((accountAccess && accountAccess.payments) ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                                        style={{ fontSize: 20 }} />
                                </div>
                                Payments
                            </div>
                        </div>
                        <div className="flexColumn  col-md-6" style={{ alignItems: 'flex-start' }}>
                            <div
                                className="inline-container" style={{ marginLeft: 0 }}
                                onClick={() => handleCheckSelect(!(accountAccess && accountAccess.ownTransfers), 'accountAccess', 'ownTransfers')}>
                                <div className="icon-container">
                                    <i className={((accountAccess && accountAccess.ownTransfers) ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                                        style={{ fontSize: 20 }} />
                                </div>
                                Own Transfers
                            </div>

                            <div
                                className="inline-container" style={{ marginLeft: 0 }}
                                onClick={() => handleCheckSelect(!(accountAccess && accountAccess.notifications), 'accountAccess', 'notifications')}>
                                <div className="icon-container">
                                    <i className={((accountAccess && accountAccess.notifications) ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                                        style={{ fontSize: 20 }} />
                                </div>
                                Notifications
                            </div>
                        </div>
                    </div>
                </div>
                <div className="action-button-container">
                    <div />
                    <button
                        className="action-btn-secondary"
                        onClick={handleAddOperator}
                        type="button">
                        Add
                    </button>
                </div>
            </div>
        );
    };

    const renderOperators = () => {
        const { bolProfileOperators } = props;
        const propErrors = props.errors;
        const config = bolProduct && bolProduct.config;
        const hasOperators = _.size(config && config.operators) > 0;

        return (
            <div>
                <FormField
                    error={propErrors && propErrors.operators ? ['At Least One Operator Should Be Added'] : ''}>
                    {
                        hasOperators &&
                        <div>
                            <div className="section-title" style={{ marginBottom: 0 }}>
                                <span>Operators</span>
                            </div>
                            {
                                _.map(config && config.operators, (o, i) => {
                                    const accountAccess = o && o.accountAccess;
                                    const op = _.find(bolProfileOperators, op => (op.operatorId === o.operatorId));
                                    return (
                                        <div key={`operator/${i}`}>
                                            <div className="flexRow" style={{ alignItems: 'center', }}>
                                                <div className="col-md-11">
                                                    <span
                                                        className={'active-entity'}> {`${i + 1}. ${o.operatorId}: ${op && op.operatorName ? op.operatorName : ''}`}</span>
                                                    {accountAccess.balanceStatements &&
                                                        <span className="greyText"> | Balance & Statements</span>}
                                                    {accountAccess.payments &&
                                                        <span className="greyText"> | Payments</span>}
                                                    {accountAccess.ownTransfers &&
                                                        <span className="greyText"> | Own Transfers</span>}
                                                    {accountAccess.notifications &&
                                                        <span className="greyText"> | Notifications</span>}
                                                </div>
                                                <div className="col-md-1" style={{ padding: 0 }} id="item-content">
                                                    <p><a className="btn"
                                                        role="button"
                                                        id="remove-btn"
                                                        onClick={() => handleRemoveOperator(o.operatorId)}> <i
                                                            className="fa fa-times" aria-hidden="true" /></a>
                                                    </p>
                                                </div>
                                            </div>
                                            <hr style={{ padding: 1, margin: 0 }} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                    <a
                        className="btn btn-secondary addButton"
                        onClick={() => {
                            propErrors && propErrors.operators && props.resetErrors('operators');
                            showAddOperator();
                        }}>Add Users</a>
                    {propErrors && propErrors.operators && (<div><br /><br /></div>)}
                </FormField>

                {isAddToggled &&
                    <div style={{ zIndex: -1 }}>
                        <Popup onClose={showAddOperator.bind(this)}>
                            {renderOperatorForm()}
                        </Popup>
                    </div>
                }
            </div>
        );
    };

    const { resetErrors, bolProfiles, onFetchOperators, errors } = props;
    const config = bolProduct && bolProduct.config;
    const hasProfiles = _.size(bolProfiles) > 0;

    let profilesOptions = [];
    _.each(bolProfiles, (entity) => {
        _.each(entity.divisions, (div) => {
            profilesOptions.push({ 'value': div.code, 'label': `${div.code}: ${div.name}` });
        });
    });

    const bolProfileId = bolProduct && bolProduct.bolProfileId;
    if (isEntityCorp) {
        return (
            <div>

                <FormField className="form-group" id="shortName" error={errors && errors.shortName}>
                    <Col xs={9} style={{ paddingLeft: 0 }}>
                        <label>Account Short Name<small className='text-danger'> *</small></label>
                    </Col>
                    <div>
                        {/* <input
                            className="form-control"
                            id="bolShortName"
                            onChange={(event) => {
                                errors && errors.shortName && resetErrors('shortName');
                                handleOnChange(event, 'config','shortName');
                            }}
                            type="text"
                            maxLength={10}
                            value={config && config.shortName || ''}
                        /> */}
                        <input
                            className="form-control"
                            id="bolShortName"
                            onChange={(event) => {
                                // const inputValue = event.target.value;
                                // // Regular expression to allow only alphanumeric characters and spaces
                                // const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9 ]/g, '');

                                errors && errors.shortName && resetErrors('shortName');
                                handleOnChange(event, 'config', 'shortName');
                            }}
                            type="text"
                            maxLength={10}
                            value={config && config.shortName || ''}
                        />
                    </div>
                </FormField>


                <FormField className="form-group" id="bol" >
                    <Col xs={9} style={{ paddingLeft: 0 }}>
                        <label>Do you want to pay money out of this account? </label>
                    </Col>
                    <div className="input-group">
                        <div
                            className="inline-container" style={{ marginLeft: 0 }}
                            onClick={() => handleOptions(!(config && config.limits && config.limits.payments), 'payments')}>
                            <div className="icon-container">
                                <i className={((config && config.limits && config.limits.payments) ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                                    style={{ fontSize: 20 }} />
                            </div>
                        </div>
                    </div>
                </FormField>

                <FormField className="form-group" id="bol" >
                    <Col xs={9} style={{ paddingLeft: 0 }}>
                        <label>Do you want to collect money into this account? </label>
                    </Col>
                    <div className="input-group">
                        <div
                            className="inline-container" style={{ marginLeft: 0 }}
                            onClick={() => handleOptions(!(config && config.limits && config.limits.collections), 'collections')}>
                            <div className="icon-container">
                                <i className={((config && config.limits && config.limits.collections) ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                                    style={{ fontSize: 20 }} />
                            </div>
                        </div>
                    </div>
                </FormField>

                <FormField className="form-group" id="bol" error={errors && errors.transactionalPayments}>
                    <Col xs={12} style={{ paddingLeft: 0 }}>
                        <label>Transactional Limit<small className='text-danger'> *</small></label>
                    </Col>
                    <div className="input-group">
                        <span className="input-group-addon">R</span>
                        <input
                            className="form-control"
                            id="bolProfileId"
                            // required={true}
                            onChange={(event) => {
                                errors && errors.transactionalPayments && resetErrors('transactionalPayments');
                                handleOnChangeLimits(event, 'transactionalPayments');
                            }}
                            type="number"
                            value={config && config.limits && config.limits.transactionalPayments || ''}
                        />
                        <span className="input-group-addon">.00</span>
                    </div>
                </FormField>

                <FormField className="form-group" id="bol" error={errors && errors.transactionalTransfers}>
                    <Col xs={12} style={{ paddingLeft: 0 }}>
                        <label>Interaccount Transactional Limit<small className='text-danger'> *</small></label>
                    </Col>
                    <div className="input-group">
                        <span className="input-group-addon">R</span>
                        <input
                            className="form-control"
                            id="bolProfileId"
                            onChange={(event) => {
                                errors && errors.transactionalTransfers && resetErrors('transactionalTransfers');
                                handleOnChangeLimits(event, 'transactionalTransfers');
                            }}
                            type="number"
                            value={config && config.limits && config.limits.transactionalTransfers || ''}
                        />
                        <span className="input-group-addon">.00</span>
                    </div>
                </FormField>
                <FormField error={errors && errors.bolProfileId ? (hasProfiles ? (isOther ? errors.bolProfileId : ['One Should Be Selected']) : errors.bolProfileId) : ''}>
                    <label>Business Online Profile ID<small className='text-danger'> *</small></label>
                    {
                        hasProfiles ?
                            <Select
                                name="bolProfileId"
                                onChange={(event) => {
                                    errors && errors.bolProfileId && resetErrors('bolProfileId');
                                    handleSelectUserChange(event);
                                }}
                                options={_.union(profilesOptions, [OTHER_PROFILE_ID])}
                                value={isOther ? OTHER_PROFILE_ID.value : bolProfileId}
                            /> :
                            <input
                                className="form-control"
                                id="bolProfileId"
                                onChange={(event) => {
                                    errors && errors.bolProfileId && resetErrors('bolProfileId');
                                    handleOnChange(event, 'bolProfileId');
                                }}
                                type="text"
                                value={bolProfileId || ''}
                            />
                    }
                    {
                        isOther &&
                        <div>
                            <br />
                            <input
                                className="form-control"
                                id="bolProfileId"
                                onChange={(event) => {
                                    errors && errors.bolProfileId && resetErrors('bolProfileId');
                                    handleOnChange(event, 'bolProfileId');
                                }}
                                type="text"
                                value={bolProfileId || ''}
                            />
                        </div>
                    }
                </FormField>
                <br />

                {renderOperators()}
            </div>
        );
    }
    else {
        return (
            <div>
                <FormField className="form-group" id="shortName" error={errors && errors.shortName}>
                    <Col xs={9} style={{ paddingLeft: 0 }}>
                        <label>Account Short Name: </label>
                    </Col>
                    <div>
                        <input
                            className="form-control"
                            id="bolShortName"
                            onChange={(event) => {
                                errors && errors.shortName && resetErrors('shortName');
                                handleOnChange(event, 'config', 'shortName');
                            }}
                            type="text"
                            maxLength={20}
                            value={config && config.shortName || ''}
                        />
                    </div>
                </FormField>


                <FormField className="form-group" id="bol" error={errors && errors.transactionalPayments} >
                    <Col xs={9} style={{ paddingLeft: 0 }}>
                        <label>Do you want to pay money out of this account? </label>
                    </Col>
                    <div className="input-group">
                        <div
                            className="inline-container" style={{ marginLeft: 0 }}
                            onClick={() => handleOptions(!(config && config.limits && config.limits.payments), 'payments')}>
                            <div className="icon-container">
                                <i className={((config && config.limits && config.limits.payments) ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                                    style={{ fontSize: 20 }} />
                            </div>
                        </div>
                    </div>
                </FormField>

                <FormField className="form-group" id="bol" error={errors && errors.transactionalPayments}>
                    <Col xs={9} style={{ paddingLeft: 0 }}>
                        <label>Do you want to collect money into this account? </label>
                    </Col>
                    <div className="input-group">
                        <div
                            className="inline-container" style={{ marginLeft: 0 }}
                            onClick={() => handleOptions(!(config && config.limits && config.limits.collections), 'collections')}>
                            <div className="icon-container">
                                <i className={((config && config.limits && config.limits.collections) ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                                    style={{ fontSize: 20 }} />
                            </div>
                        </div>
                    </div>
                </FormField>

                <FormField className="form-group" id="bol" error={errors && errors.transactionalPayments}>
                    <Col xs={12} style={{ paddingLeft: 0 }}>
                        <label>Transactional Limit</label>
                    </Col>
                    <div className="input-group">
                        <span className="input-group-addon">R</span>
                        <input
                            className="form-control"
                            id="bolProfileId"
                            // required={true}
                            onChange={(event) => {
                                errors && errors.transactionalPayments && resetErrors('transactionalPayments');
                                handleOnChangeLimits(event, 'transactionalPayments');
                            }}
                            type="number"
                            value={config && config.limits && config.limits.transactionalPayments || ''}
                        />
                        <span className="input-group-addon">.00</span>
                    </div>
                </FormField>

                <FormField className="form-group" id="bol" error={errors && errors.transactionalTransfers}>
                    <Col xs={12} style={{ paddingLeft: 0 }}>
                        <label>Interaccount Transactional Limit</label>
                    </Col>
                    <div className="input-group">
                        <span className="input-group-addon">R</span>
                        <input
                            className="form-control"
                            id="bolProfileId"
                            onChange={(event) => {
                                errors && errors.transactionalTransfers && resetErrors('transactionalTransfers');
                                handleOnChangeLimits(event, 'transactionalTransfers');
                            }}
                            type="number"
                            value={config && config.limits && config.limits.transactionalTransfers || ''}
                        />
                        <span className="input-group-addon">.00</span>
                    </div>
                </FormField>

                <FormField
                    error={errors && errors.bolProfileId ? (hasProfiles ? (isOther ? errors.bolProfileId : ['One Should Be Selected']) : errors.bolProfileId) : ''}>
                    <label>Business Online Profile ID</label>
                    {
                        hasProfiles ?
                            <Select
                                name="bolProfileId"
                                // required={true}
                                onChange={(event) => {
                                    errors && errors.bolProfileId && resetErrors('bolProfileId');
                                    handleSelectUserChange(event);
                                }}
                                options={_.union(profilesOptions, [OTHER_PROFILE_ID])}
                                value={isOther ? OTHER_PROFILE_ID.value : bolProfileId}
                            /> :
                            <input
                                className="form-control"
                                id="bolProfileId"
                                onChange={(event) => {
                                    errors && errors.bolProfileId && resetErrors('bolProfileId');
                                    handleOnChange(event, 'bolProfileId');
                                }}
                                type="text"
                                value={bolProfileId || ''}
                            />
                    }
                    {
                        isOther &&
                        <div>
                            <br />
                            <input
                                className="form-control"
                                id="bolProfileId"
                                onChange={(event) => {
                                    errors && errors.bolProfileId && resetErrors('bolProfileId');
                                    handleOnChange(event, 'bolProfileId');
                                }}
                                type="text"
                                value={bolProfileId || ''}
                            />
                        </div>
                    }
                </FormField>
                <br />

                {renderOperators()}
            </div>
        );
    }
};
