import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import validate from 'validate.js';

import '../../../styles/App.scss';
import '../../../styles/ConfirmDetails.css';
import '../../../styles/Global.css';
import * as actions from '../../../actions/entity';
import FormField from '../../formField/FormField';
import Toggler from '../../Toggler';
class NewEntityDetailsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {errors: {}, isSameAddress: true, haveVATNumber: true};
        this.handleChange = this._handleChange.bind(this);
        this.handleChangeAddress = this._handleChangeAddress.bind(this);
        this.handlePrevious = this._handlePrevious.bind(this);
        this.handleNext = this._handleNext.bind(this);
        this.handleResetError = this._resetError.bind(this);
        this.handleSaveAndContinue = this._handleSaveAndContinue.bind(this);
        this.onSetPhysicalAddress = this._onSetPhysicalAddress.bind(this);
        this.handleDropDownSelect = this._handleDropDownSelect.bind(this);
        this.onTogglePhysicalAddress = this._onTogglePhysicalAddress.bind(this);
        this.handleVATNumber = this._handleVATNumber.bind(this);
    }

    componentDidMount() {
        _.defer(() => this._setDefaultValues());
    }

    _setDefaultValues() {
        const {actions, newEntityDetails} = this.props;
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;

        if (legalEntity) {
            const {registeredAddress, addresses, registeredName, tradingNames, typeOfEntity} = legalEntity;
            const canSetAddress = _.size(addresses) > 0 && _.isEmpty(registeredAddress);
            const canSetRegisteredName = _.size(tradingNames) > 0 && !registeredName;
            const canSetTypeOfEntity = !typeOfEntity;

            if (canSetAddress || canSetRegisteredName || canSetTypeOfEntity) {
                const defaultAddress = addresses && addresses[0];
                const defaultName = tradingNames && tradingNames[0];
                const defaultEntityType = 'Private Company (Pty LTD)';

                const updatedLegalEntity = _.extend({}, legalEntity, canSetTypeOfEntity && {typeOfEntity: defaultEntityType}, canSetAddress && {
                    registeredAddress: defaultAddress,
                    physicalAddress: defaultAddress
                }, canSetRegisteredName && {registeredName: defaultName});

                actions.onChangeValue('newEntityDetails', 'legalEntity', updatedLegalEntity);
            }
        }
    }

    _handleChange({target}, attribute, innerAttribute) {
        let value = _.trimStart(target.value);
        if (innerAttribute) {
            const {newEntityDetails} = this.props;
            value = _.extend({}, newEntityDetails && newEntityDetails[attribute], {[innerAttribute]: value});
        }
        this.props.actions.onChangeValue('newEntityDetails', attribute, value);
    }

    _handleDropDownSelect({target}, attribute, innerAttribute, listName) {
        let value = target.value;
        if (innerAttribute) {
            const {newEntityDetails} = this.props;
            if (listName) {
                value = newEntityDetails && newEntityDetails[attribute] && newEntityDetails[attribute][listName] && newEntityDetails[attribute][listName][value];
            }
            value = _.extend({}, newEntityDetails && newEntityDetails[attribute], {[innerAttribute]: value});
        }
        this.props.actions.onChangeValue('newEntityDetails', attribute, value);
    }

    _handleChangeAddress({target}, attribute, addressType, isSame = false) {
        const {actions, newEntityDetails} = this.props;
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;

        if (legalEntity) {
            const onSetValue = (type) => {
                return {[type]: _.extend({}, legalEntity[type], {[attribute]: _.trimStart(target.value)})};
            };

            let value = _.extend({}, legalEntity, onSetValue(addressType));
            if (addressType === 'registeredAddress' && isSame) {
                value = _.extend({}, value, onSetValue('physicalAddress'));
            }
            actions.onChangeValue('newEntityDetails', 'legalEntity', value);
        }
    }

    allowOnlyCharacters = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^A-Za-z\s]/g, '');
        event.target.value = value;
    }

    allowOnlyNumbers = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^0-9]/g, '');
        event.target.value = value;
    }

    allowOnlyAlphanumeric = (event) => {
        let { value } = event.target;
        // Replace characters that are not alphanumeric or spaces with an empty string
        value = value.replace(/[^A-Za-z0-9\s]/g, '');
        event.target.value = value;
    }
    
    _resetError(attribute, innerAttribute = null) {
        const {errors} = this.state;

        let value = null;
        if (innerAttribute) {
            value = _.extend({}, errors && errors[attribute], {[innerAttribute]: value});
        }
        this.setState({errors: _.extend({}, errors, {[attribute]: value})});
    }

    _validateForm() {
        const {newEntityDetails} = this.props;
        const {isSameAddress, haveVATNumber} = this.state;
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        const options = {fullMessages: false};

        const constraints = {
            registeredName: {presence: true, length: {minimum: 1, message: 'required'}},
            telephoneNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            ...(haveVATNumber && { vatNumber: { presence: true, length: { minimum: 10, message: 'VAT Number should be 10 digits' } } }),
        };
        const addressContraints = {
            street: {presence: true, length: {minimum: 1, message: 'required'}},
            suburb: {presence: true, length: {minimum: 1, message: 'required'}},
            city: {presence: true, length: {minimum: 1, message: 'required'}},
            province: {presence: true, length: {minimum: 1, message: 'required'}},
            postalCode: {presence: true, length: {minimum: 1, message: 'required'}},
            country: {presence: true, length: {minimum: 1, message: 'required'}},
        };
        const legalEntityErrors = validate(legalEntity, constraints, options);

        const registeredAddress = validate(legalEntity && legalEntity.registeredAddress, addressContraints, options);
        const physicalAddress = !isSameAddress ? validate(legalEntity && legalEntity.physicalAddress, addressContraints, options) : {};

        const errors = _.extend({}, legalEntityErrors, {registeredAddress}, {physicalAddress});
        this.setState({errors});
        return _.isEmpty(_.extend({}, legalEntityErrors, registeredAddress, physicalAddress));
    }

    _onTogglePhysicalAddress() {
        this.setState(prevState => {
            this._onSetPhysicalAddress(!prevState.isSameAddress);
            return {isSameAddress: !prevState.isSameAddress};
        });
    }

    _onSetPhysicalAddress(isSame) {
        const {actions, newEntityDetails} = this.props;
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;

        let physicalAddress;
        if (isSame) {
            physicalAddress = legalEntity.registeredAddress;
        } else {
            physicalAddress = {
                street: null,
                suburb: null,
                city: null,
                province: null,
                postalCode: null,
                country: null
            };
        }
        const value = _.extend({}, legalEntity, {physicalAddress});
        actions.onChangeValue('newEntityDetails', 'legalEntity', value);
    }

    _handleNext(event) {
        event.preventDefault();
        const {newEntityDetails} = this.props;
        const {isSameAddress} = this.state;

        if(!newEntityDetails.legalEntity.registeredAddress.country){
            newEntityDetails.legalEntity.registeredAddress.country = 'SOUTH AFRICA';
        }
        if(isSameAddress){
            newEntityDetails.legalEntity.physicalAddress = newEntityDetails.legalEntity.registeredAddress;
        }
        if (this._validateForm()) {
            const {actions, newEntityDetails, history} = this.props;
            const onProceed = () => history.push('/onboard/kyc');
            const updateEntityDetails = _.extend({}, newEntityDetails, {status: 'kyc'});
            actions.saveNewEntityInfo(updateEntityDetails, onProceed);
        }
    }

    _handleVATNumber = (haveVATNumber) => {
		this.setState({ haveVATNumber });
		
        if (!haveVATNumber) {
            const { actions, newEntityDetails } = this.props;
            const updatedLegalEntity = { ...newEntityDetails.legalEntity, vatNumber: null };
            actions.onChangeValue('newEntityDetails', 'legalEntity', updatedLegalEntity);
        }
	};

    _handlePrevious() {
        this.props.history.push('/onboard/reginfo');
    }

    _handleSaveAndContinue() {
        const {actions, newEntityDetails, history} = this.props;
        const onProceed = () => {
            history.replace('/');
            //clear newEntityDetails data
        };
        const updateEntityDetails = _.extend({}, newEntityDetails, {status: 'details'});
        actions.saveNewEntityInfo(updateEntityDetails, onProceed);
    }

    render() {
        const {newEntityDetails, systemError} = this.props;
        const {isSameAddress, haveVATNumber} = this.state;
        if (systemError && systemError.show) return null;
        const {errors} = this.state;
        return (
            newEntityDetails &&
            <EntityDetailsCard
                errors={errors}
                isSameAddress={isSameAddress}
                onChange={this.handleChange}
                onChangeAddress={this.handleChangeAddress}
                onNext={this.handleNext}
                onPrevious={this.handlePrevious}
                onResetError={this.handleResetError}
                onSaveAndContinue={this.handleSaveAndContinue}
                onSelect={this.handleDropDownSelect}
                onSetPhysicalAddress={this.onSetPhysicalAddress}
                onTogglePhysicalAddress={this.onTogglePhysicalAddress}
                newEntityDetails={newEntityDetails}
                haveVATNumber={haveVATNumber}
                allowOnlyCharacters={this.allowOnlyCharacters}
                allowOnlyNumbers={this.allowOnlyNumbers}
                allowOnlyAlphanumeric={this.allowOnlyAlphanumeric}
                handleVATNumber={this.handleVATNumber}
            />
        );
    }
}

class EntityDetailsCard extends React.Component {
   
    _renderRegisteredAddress() {
        const {errors, isSameAddress, newEntityDetails, onChangeAddress, onResetError, onSelect, allowOnlyCharacters, allowOnlyNumbers, allowOnlyAlphanumeric} = this.props;

        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        const hasAddresses = _.size(legalEntity && legalEntity.addresses) > 0;
        const registeredAddress = legalEntity && legalEntity.registeredAddress;
        return (
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        <span>Registered Address</span>
                    </div>

                    {
                        hasAddresses &&
                        <div className="form-group">
                            <label htmlFor="registeredAddress">Select Address</label>
                            <select
                                name="addresses"
                                className="form-control"
                                onChange={(event) => onSelect(event, 'legalEntity', 'registeredAddress', 'addresses')}
                                defaultValue={registeredAddress && registeredAddress.street || ''}
                            >
                                {
                                    _.map(legalEntity.addresses, (a, i) => {
                                        return (
                                            <option key={`address-${i}`} value={i}>{a.street}</option>
                                        );
                                        }
                                    )
                                }
                                <option value={-1}>Other</option>
                            </select>
                            <br/>
                        </div>
                    }

                    <AddressComponent
                        address={registeredAddress}
                        addressType={'registeredAddress'}
                        isSameAddress={isSameAddress}
                        errors={errors && errors.registeredAddress}
                        onChangeAddress={onChangeAddress}
                        onResetError={onResetError}
                        allowOnlyCharacters={allowOnlyCharacters}
                        allowOnlyNumbers={allowOnlyNumbers}
                        allowOnlyAlphanumeric={allowOnlyAlphanumeric}
                    />
                </div>
            </div>
        );
    }

    _renderPhyiscalAddress() {
        const {errors, isSameAddress, newEntityDetails, onChangeAddress, onResetError, onSelect, onTogglePhysicalAddress, allowOnlyCharacters, allowOnlyNumbers, allowOnlyAlphanumeric} = this.props;

        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        const physicalAddress = legalEntity && legalEntity.physicalAddress;
        const hasAddresses = _.size(legalEntity && legalEntity.addresses) > 0;
        return (
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        <span>Physical Address</span>
                    </div>

                    <div
                        className="inline-container"
                        onClick={onTogglePhysicalAddress}
                    >
                        <div className="icon-container">
                            <i className={(isSameAddress ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                               style={{fontSize: 20}}/>
                        </div>
                        Same As Registered Address
                    </div>

                    {
                        !isSameAddress &&
                        <div>
                            {
                                hasAddresses &&
                                <div className="form-group">
                                    <label htmlFor="registeredAddress">Select Address</label>
                                    <select
                                        name="addresses"
                                        className="form-control"
                                        onChange={(event) => onSelect(event, 'legalEntity', 'physicalAddress', 'addresses')}
                                    >
                                        <option value={-1}>Other</option>
                                        {
                                            _.map(legalEntity.addresses, (a, i) => {
                                                return (
                                                    <option key={`address-${i}`} value={i}>{a.street}</option>
                                                );
                                                }
                                            )
                                        }
                                    </select>
                                    <br/>
                                </div>
                            }

                            <AddressComponent
                                address={physicalAddress}
                                addressType={'physicalAddress'}
                                errors={errors && errors.physicalAddress}
                                onChangeAddress={onChangeAddress}
                                onResetError={onResetError}
                                allowOnlyCharacters={allowOnlyCharacters}
                                allowOnlyNumbers={allowOnlyNumbers}
                                allowOnlyAlphanumeric={allowOnlyAlphanumeric}
                            />
                        </div>
                    }
                </div>
            </div>
        );
    }

    _renderFooter() {
        const {onPrevious, onSaveAndContinue} = this.props;
        return (
            <div className="action-button-container">
                <button
                    className="action-btn-primary"
                    onClick={onPrevious}
                    type="button"
                >Previous
                </button>
                <button
                    className="action-btn-secondary"
                    onClick={onSaveAndContinue}
                    type="button"
                > Save And Continue Later
                </button>
                <button
                    className="action-btn-primary"
                    type="submit"
                >Next
                </button>
            </div>
        );
    }

    _renderEntitySection() {
        const {errors, onChange, onResetError, onSelect, newEntityDetails, allowOnlyCharacters, allowOnlyNumbers, allowOnlyAlphanumeric, haveVATNumber, handleVATNumber} = this.props;
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        const hasTradingNames = _.size(legalEntity && legalEntity.tradingNames) > 0;
        return (
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        <span>Entity Details</span>
                    </div>

                    <FormField className={'form-group'} id="tradingas" error={errors && errors.tradingAs}>
                        <label htmlFor="tradingAs">Trading As (Optional)</label>
                        <input
                            type="text"
                            className="form-control"
                            id="tradingAs"
                            value={legalEntity && legalEntity.tradingAs || ''}
                            onChange={(event) => {
                                allowOnlyAlphanumeric(event);
                                errors && errors.tradingAs && onResetError('tradingAs');
                                onChange(event, 'legalEntity', 'tradingAs');
                            }}
                        />
                    </FormField>

                    <div className="form-group">
                        <label htmlFor="registeredName">Registered Name</label>
                        {
                            hasTradingNames ?
                                <select
                                    name="tradingNames"
                                    className="form-control"
                                    onChange={(event) => onSelect(event, 'legalEntity', 'registeredName', 'tradingNames')}
                                    defaultValue={legalEntity && legalEntity.tradingAs || ''}
                                >
                                    {
                                        _.map(legalEntity.tradingNames, (t, i) => {
                                            return (
                                                <option key={`address-${i}`} value={i}>{t}</option>
                                            );
                                            }
                                        )
                                    }
                                </select> :
                                <FormField id="registeredname" error={errors && errors.registeredName}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="registeredName"
                                        value={legalEntity && legalEntity.registeredName || ''}
                                        onChange={(event) => {
                                            allowOnlyAlphanumeric(event);
                                            errors && errors.registeredName && onResetError('registeredName');
                                            onChange(event, 'legalEntity', 'registeredName');
                                        }}
                                    />
                                </FormField>
                        }
                    </div>

                    <div className="form-group">
                        <label htmlFor="typeOfEntity">Type of Entity</label>
                        <select
                            className="form-control"
                            id="typeOfEntity"
                            defaultValue={legalEntity && legalEntity.typeOfEntity || ''}
                            onChange={(event) => onChange(event, 'legalEntity', 'typeOfEntity')}
                        >
                            <option>Close Corporation</option>
                            <option>Joint Venture</option>
                            <option>Partnership</option>
                            <option>Private Company (Pty LTD)</option>
                            <option>Professional Partnership</option>
                            <option>Public Company</option>
                            <option>Sole Proprietor</option>
                            <option>Trust</option>
                        </select>
                    </div>
                      
						<Toggler
						label="Do you have a VAT Number?"
					    isOn={haveVATNumber}
					    onToggle={handleVATNumber}
					/>

                    <FormField className="form-group" id="vatnumber" error={errors && errors.vatNumber}>
                        <label htmlFor="vatNumber">VAT Number</label>
                        <input
                            type="text"
                            className="form-control"
                            id="vatNumber"
                            maxLength="10"
                            value={legalEntity && legalEntity.vatNumber || ''}
                            onChange={(event) => {
                                allowOnlyNumbers(event);
                                errors && errors.vatNumber && onResetError('vatNumber');
                                onChange(event, 'legalEntity', 'vatNumber');
                            }}
                            readOnly={!haveVATNumber}
                        />
                    </FormField>

                    <FormField className="form-group" id="telephone" error={errors && errors.telephoneNumber}>
                        <label htmlFor="telephoneNumber">Telephone Number</label>
                        <input
                            type="text"
                            className="form-control"
                            id="telephoneNumber"
                            value={legalEntity && legalEntity.telephoneNumber || ''}
                            onChange={(event) => {
                                 allowOnlyNumbers(event);
                                errors && errors.telephoneNumber && onResetError('telephoneNumber');
                                onChange(event, 'legalEntity', 'telephoneNumber');
                            }}
                        />
                    </FormField>
                </div>
            </div>
        );
    }

    render() {
        const {onNext} = this.props;
        return (
            <form className="page-container" onSubmit={onNext}>
                <div className="page-main-section">
                    {this._renderEntitySection()}
                    {this._renderRegisteredAddress()}
                    {this._renderPhyiscalAddress()}
                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
            </form>
        );
    }
};

const AddressComponent = (props) => {
    const {address, addressType, errors, isSameAddress, onChangeAddress, onResetError, allowOnlyCharacters, allowOnlyNumbers, allowOnlyAlphanumeric} = props;


    return (
        <div>
            <FormField className="form-group" id="registeredaddress" error={errors && errors.street}>
                <label htmlFor="street">Address</label>
                <input
                    type="text"
                    className="form-control"
                    id="street"
                    value={address && address.street || ''}
                    onChange={(event) => {
                        allowOnlyAlphanumeric(event);
                        errors && errors.street && onResetError(addressType, 'street');
                        onChangeAddress(event, 'street', addressType, isSameAddress);
                    }}
                />
            </FormField>

            <FormField className="form-group" id="line2" error={errors && errors.suburb}>
                <label htmlFor="suburb">Suburb</label>
                <input
                    className="form-control"
                    id="suburb"
                    onChange={(event) => {
                        allowOnlyCharacters(event);
                        errors && errors.suburb && onResetError && onResetError(addressType, 'suburb');
                        onChangeAddress(event, 'suburb', addressType, isSameAddress);
                    }}
                    type="text"
                    value={address && address.suburb || ''}
                />
            </FormField>

            <FormField className="form-group" id="registeredcity" error={errors && errors.city}>
                <label htmlFor="registeredCity">City</label>
                <input
                    type="text"
                    className="form-control"
                    id="registeredCity"
                    value={address && address.city || ''}
                    onChange={(event) => {
                        allowOnlyCharacters(event);
                        errors && errors.city && onResetError(addressType, 'city');
                        onChangeAddress(event, 'city', addressType, isSameAddress);
                    }}
                />
            </FormField>

            <FormField className="form-group" id="stateOrProvice" error={errors && errors.province}>
                <label htmlFor="stateOrProvince">State/Province</label>
                <input
                    type="text"
                    className="form-control"
                    id="stateOrProvince"
                    value={address && address.province || ''}
                    onChange={(event) => {
                        allowOnlyCharacters(event);
                        errors && errors.province && onResetError(addressType, 'province');
                        onChangeAddress(event, 'province', addressType, isSameAddress);
                    }}
                />
            </FormField>

            <FormField className="form-group" id="postal" error={errors && errors.postalCode}>
                <label htmlFor="stateOrProvince">Zip/Postal Code</label>
                <input
                    type="text"
                    className="form-control"
                    id="registoredZipOrPostalCode"
                    value={address && address.postalCode || ''}
                    onChange={(event) => {
                        allowOnlyNumbers(event);
                        errors && errors.postalCode && onResetError(addressType, 'postalCode');
                        onChangeAddress(event, 'postalCode', addressType, isSameAddress);
                    }}
                />
            </FormField>

            <FormField className="form-group" id="country1" error={errors && errors.country}>
                <label htmlFor="stateOrProvince">Country</label>
                <input
                    type="text"
                    className="form-control"
                    id="country"
                    value={address && address.country || ''}
                    onChange={(event) => {
                        allowOnlyCharacters(event);
                        errors && errors.country && onResetError(addressType, 'country');
                        onChangeAddress(event, 'country', addressType, isSameAddress);
                    }}
                />
            </FormField>
        </div>
    );
};

function mapStateToProps({entity, systemError}, ownProps) {

    const newEntityDetails = entity && entity.newEntityDetails;
    const registeredAddress = newEntityDetails && newEntityDetails.legalEntity && newEntityDetails.legalEntity.registeredAddress;
    const legalEntity = _.extend({}, newEntityDetails && newEntityDetails.legalEntity, {registeredAddress: registeredAddress ? registeredAddress : {}});

    return {
        newEntityDetails: newEntityDetails ? _.extend({}, newEntityDetails, {legalEntity}) : {},
        systemError
    };
}

function mapDispatchToProps(dispatch) {
    console.log('dispatch :::' + JSON.stringify(dispatch));
    return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(NewEntityDetailsPage);
