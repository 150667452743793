import React from 'react';
import _ from 'lodash'

import FormField from "../../formField/FormField";
import ReactLoading from "react-loading";
import validate from "validate.js";

export default class RemoveSignatory extends React.Component {
    state = {errors: {}, isRemoving: false};

    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors});
    };

    validateSelected = () => {
        const {signatories} = this.props;
        const options = {fullMessages: true};
        const hasSelected = _.find(signatories, signatory => (signatory && signatory.isSelected));
        const constraints = {signatories: {presence: true}};

        const errors = validate({signatories: hasSelected}, constraints, options);

        this.setState({errors});
        return _.isEmpty(errors)
    };

    onHandleSubmit = (e) => {
        e.preventDefault();
        if (this.validateSelected()) {
            this.setState({isRemoving: true});
            const {onDeleteSignatories, signatories} = this.props;
            const onFinish = () => this.setState({isRemoving: false});
            onDeleteSignatories && onDeleteSignatories(signatories, onFinish);
        }
    };

    onHandleSignatorySelect = (e, signatory) => {
        const {onSelect} = this.props;
        const {errors} = this.state;
        errors && errors.signatories && this.handleResetError("signatories");
        onSelect && onSelect(e, signatory)
    };

    onHandleSelectAll = () => {
        const {onSelectAll} = this.props;
        const {errors} = this.state;
        errors && errors.signatories && this.handleResetError("signatories");
        onSelectAll && onSelectAll()
    };

    _renderSignatories() {
        const {signatories, isSelectAll} = this.props;
        const iconStyle = {
            color: "rgb(0, 51, 170)",
            fontSize: 20, marginRight: 5
        };
        const selectedCount = _.size(_.filter(signatories, sign => (sign.isSelected)));
        return (
            <div>
                <label
                    className={`checkbox-inline ${isSelectAll ? "selection-list-item-selected" : "selection-list-item"}`}
                    style={{padding: 3, paddingLeft: 0, marginLeft: 0, width: "100%", textAlign: "center"}}
                    onClick={this.onHandleSelectAll}
                >
                    <i
                        className={isSelectAll ? "fa fa-check-circle" : "fa fa-circle-o"}
                        style={iconStyle}
                    />
                    <span className="active-entity" style={{marginLeft: 10}}>Select All</span>
                    <span className="active-entity" style={{
                        marginLeft: 10,
                        color: "rgb(0, 51, 170)"
                    }}>{(selectedCount > 0) ? selectedCount : ''}</span>
                </label>

                {
                    _.map(signatories, (signatory, i) => {
                        const isSelected = signatory && signatory.isSelected;
                        return (
                            <label
                                className={`checkbox-inline ${signatory.isSelected ? "selection-list-item-selected" : "selection-list-item"}`}
                                key={`signatory-${i}`}
                                style={{padding: 3, paddingLeft: 0, marginLeft: 0, width: "100%"}}
                                onClick={(event) => this.onHandleSignatorySelect(event, signatory)}
                            >
                                <span className="icon-container">
                                    <i
                                        className={(isSelected ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                        style={{fontSize: 20}}
                                    />
                                </span>
                                {` ${signatory.initialsOrFirstNames} ${signatory.surname} | Instruction: ${signatory && signatory.instruction ? signatory.instruction : ''}`}
                            </label>
                        )
                    })
                }
            </div>
        )
    }

    _renderHeader() {
        return (
            <div>
                <div className="product-heading">
                    Remove Account Signatories
                </div>
                <div className="title-gradient"/>
            </div>
        )
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-secondary"
                    onClick={this.onHandleSubmit}
                    type="button"
                >Remove Selected
                </button>
            </div>
        )
    }

    render() {
        const {isFetchingSignatories, signatories} = this.props;
        const {errors, isRemoving} = this.state;
        const hasSignatories = _.size(signatories) > 0;
        return (
            <div>
                {this._renderHeader()}
                <br/>
                <div className="popup-content">
                    <div className="flexColumn">
                        <FormField
                            error={errors && errors.signatories && ["At Least One Should Be Selected"]}
                            className="card-container-form"
                        >
                            {
                                isFetchingSignatories ? null : (
                                    hasSignatories ? this._renderSignatories() :
                                        <div>Selected Account Has No Signatories </div>
                                )
                            }
                        </FormField>
                    </div>
                </div>
                {this._renderFooter()}
                {
                    (isFetchingSignatories || isRemoving) &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
            </div>
        );
    }
}

export const RemovalMessage = ({success}) => {
    if (success == true) {
        return (
            <div>
                <div>
                    <div className="product-heading">
                        Successful
                    </div>
                    <div className="title-gradient"/>
                </div>
                <br/>
                <div className="popup-content">
                    <div className="flexColumn">
                        <div>
                            <p>
                                Your instruction has been captured.
                            </p>
                            <p>
                                It should take 24 hours for your request to be completed.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div>
                <div className="product-heading">
                    Unsuccessful
                </div>
                <div className="title-gradient"/>
            </div>
            <br/>
            <div className="popup-content">
                <div className="flexColumn">
                    <div>
                        <p>There was an error capturing your instruction.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
