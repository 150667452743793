import React from 'react';
import { Link } from 'react-router-dom';
import {reportsRoutes} from '../../routers/routes';
import '../../styles/Products.scss';

const ReportsTile = () => {
  return (
    <div className='row'>
    <div className='col-md-8'>
        <div style={{ display:'flex', alignSelf:'start'}}>
            <div className="product-btn">
                <div className="product-heading">
                   Confirmation and Signatories Letter
                </div>
                <div className="title-gradient" />
                <p style={{
                    paddingTop: '3px',
                    paddingBottom: '3px',
                    fontSize: '12px',
                    color: '#858D9D',
                    textTransform:'none'
                }}>Description of Confirmation and Signatories Letter</p>

                <div className="flexRow" style={{ alignItems: 'flex-end', flex:'auto', margin: 0, padding: 0}}>
                    <div style={{ flex: 1, alignItems: 'flex-end'}} />
                    <Link to={reportsRoutes.confirmAndSignatoriesReports} className="flat-button-primary" style={{ padding: 0 }} role="button">
                        <span>Apply Now <i className="fa fa-angle-right fa-lg" /> </span>
                        {/* <span style={{color:'grey' }}> Coming soon <i className="fa fa-angle-right fa-lg" /> </span> */}
                    </Link>
                </div>
            </div>

            <div className="product-btn">
                <div className="product-heading">
                  Account Verification
                </div>
                <div className="title-gradient" />
                <p style={{
                    paddingTop: '3px',
                    paddingBottom: '3px',
                    fontSize: '12px',
                    color: '#858D9D',
                    textTransform:'none'
                }}>Description of Account Verification</p>

            
                
                <div className="flexRow" style={{ alignItems: 'flex-end', flex:'auto', margin: 0, padding: 0}}>
                    <div style={{ flex:1}} />
                    <Link to="/reporting"  className="flat-button-primary" style={{ padding: 0 }} role="button">
                        <span>Apply Now <i className="fa fa-angle-right fa-lg" /> </span>
                    </Link>
                </div> 
            </div>

            <div className="product-btn">
                <div className="product-heading">
                    Statements
                </div>
                
                <div className="title-gradient" />

                <p style={{
                    paddingTop: '3px',
                    paddingBottom: '3px',
                    fontSize: '12px',
                    color: '#858D9D',
                    textTransform:'none'
                }}>Description of Statements</p>
            
                <div className="flexRow" style={{ alignItems: 'flex-end', flex:'auto', margin: 0, padding: 0 }}>
                    <div style={{ flex: 1}} /> 
                    <Link  to={reportsRoutes.statement} className="flat-button-primary"  role="button">
                        <span>Apply Now <i className="fa fa-angle-right fa-lg" /> </span>
                    </Link>
                </div>
            </div>
           
        </div>
    </div>
    <div className='col-md-4'></div>
</div>
  )
}

export default ReportsTile