import React from 'react';
import _ from "lodash";
import validate from "validate.js";
import Select from 'react-select';
import 'react-select/dist/react-select.css';

import FormField from "../formField/FormField";
import Popup from "../Popup";

export const LEVELS = [
    {label: "A", value: "2"},
    {label: "A and A", value: "4"},
    {label: "A and B", value: "3"}
];

export default class ReleaseLevelsAndLimits extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            levelsSections: props.levelsSections,
            releaseLevels: props.releaseLevels,
            toggle: {}
        };
    }

    componentWillMount() {
        _.defer(() => this.setDefaultValues());
    }

    setDefaultValues() {
        const {releaseLevels, onUpdateLevels, levelsSections} = this.props;
        let toggle = _.extend({}, this.state.toggle);
        let updatedLevels = _.extend({}, releaseLevels);
        _.map(levelsSections, levelSection => {
            const hasSectionLevels = _.size(releaseLevels && releaseLevels[levelSection.sectionId]) > 0;
            updatedLevels = _.extend({}, updatedLevels, !hasSectionLevels && {[levelSection.sectionId]: []});
            toggle = _.extend({}, toggle, {
                [levelSection.sectionId]: hasSectionLevels,
                [levelSection.attribute]: false
            });
        });
        _.defer(() => onUpdateLevels && onUpdateLevels(updatedLevels));
        this.setState({toggle})
    }

    handleAddReleaseLevel = (levelSection, value) => {
        const {onUpdateLevels} = this.props;
        const {releaseLevels} = this.state;
        const levels = _.union(releaseLevels && releaseLevels[levelSection.sectionId], [value]);
        const updatedLevels = _.extend({}, releaseLevels, {[levelSection.sectionId]: levels});
        onUpdateLevels && onUpdateLevels(updatedLevels);
        this.setState({releaseLevels: updatedLevels}, this.handleToggle(levelSection.attribute));
    };

    handleRemoveReleaseLevel = (levelSection, index) => {
        const {onUpdateLevels} = this.props;
        const {releaseLevels} = this.state;
        const levels = _.filter(releaseLevels && releaseLevels[levelSection.sectionId], (r, i) => (i !== index));
        const updatedLevels = _.extend({}, releaseLevels, {[levelSection.sectionId]: levels});
        onUpdateLevels && onUpdateLevels(updatedLevels);
        this.setState({releaseLevels: updatedLevels});
    };

    handleToggle = (attribute) => {
        const {levelSection} = this.state;
        this.setState(prevState => {
            const attributeUpdate = {[attribute]: !(prevState.toggle && prevState.toggle[attribute])};
            const toggle = _.extend({}, prevState.toggle, attributeUpdate);

            return _.extend({}, {toggle}, (!(prevState.toggle && prevState.toggle[attribute]) === false) && {levelSection: null})
        })
    };

    _renderPopUps() {
        const {isGroup} = this.props;
        const {levelSection, toggle} = this.state;
        if (toggle && toggle[levelSection && levelSection.attribute]) {
            return (
                <Popup onClose={() => this.handleToggle(levelSection.attribute)}>
                    <CaptureReleaseLevel
                        isGroup={isGroup}
                        levelSection={levelSection}
                        onAddLimit={this.handleAddReleaseLevel}
                    />
                </Popup>
            )
        }
        return null;
    }

    _renderLevelsAndLimits(levelSection, showTitle = false) {
        const {isGroup} = this.props;
        const {releaseLevels} = this.state;
        return (
            <div key={`rl/${levelSection && levelSection.sectionId}`}>
                <ReleaseLevelsList
                    isGroup={isGroup}
                    levelSection={levelSection}
                    levels={releaseLevels && releaseLevels[levelSection.sectionId]}
                    onRemove={this.handleRemoveReleaseLevel}
                />
                <AddLevelButton
                    title={levelSection.sectionTitle}
                    attribute={levelSection.attribute}
                    onClick={() => {
                        this.setState({levelSection});
                        this.handleToggle(levelSection.attribute)
                    }}
                />
                <br/>
                <br/>
            </div>
        )
    }

    render() {
        const {productCategories} = this.props;
        const {levelsSections, toggle} = this.state;
        if (_.size(levelsSections) < 1) return null;
        return (
            <div>
                {
                    _.map(levelsSections, (levelSection) => {
                        if (productCategories && (productCategories[levelSection.categoryID] !== true)) return null;
                        const hasSections = _.size(levelSection && levelSection.sections) > 0;

                        return (
                            <div key={`levelSection/${levelSection.sectionId}`}>
                                <div>
                                <div
                                    className="product-heading"
                                    style={{width: "100%", paddingLeft: 0, marginLeft: 0, marginBottom: 5, fontSize: 16}}
                                    onClick={() => this.handleToggle(levelSection.sectionId)}
                                >{levelSection.sectionTitle}
                                </div>
                                <div className="title-gradient"/>
                                </div>

                                {
                                        hasSections ?
                                            _.map(levelSection && levelSection.sections, lSection => (this._renderLevelsAndLimits(lSection, true)))
                                            : this._renderLevelsAndLimits(levelSection)
                                }
                                <br/>
                            </div>
                        )
                    })
                }
                {this._renderPopUps()}
            </div>
        )
    }
}
const AddLevelButton = ({attribute, onClick, title}) => (
    <div style={{marginLeft: 10}}>
        <a
            className="btn btn-secondary addButton"
            onClick={() => onClick(attribute)}
        >{`Add ${title} Level`}</a>
    </div>
);


export class CaptureReleaseLevel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            form: {
                level: '',
                limit: '',
                mandatory: false,
                linkToProduct: false,
            }
        }
    }

    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    handleValidateForm = () => {
        const {form} = this.state;
        const options = {fullMessages: false};

        const constraints = {
            level: {presence: true, numericality: true, length: {minimum: 1, message: 'required'}},
            limit: {presence: true, numericality: true, length: {minimum: 1, message: 'required'}},
        };

        const errors = validate(form, constraints, options);

        this.setState({errors});
        return _.isEmpty(errors);
    };

    handleOnChange = ({target}, attribute) => {
        const value = target.value;
        const form = _.extend({}, this.state.form, {[attribute]: value});
        this.setState({form})
    };

    handleSelectChange = (event, attribute, innerAttribute) => {
        const {form} = this.state;
        let value = event.value;
        if (innerAttribute) {
            value = _.extend({}, form && form[attribute], {[innerAttribute]: value})
        }
        const updatedForm = _.extend({}, form, {[attribute]: value});
        this.setState({form: updatedForm})
    };

    handleAddLevelAndLimit = () => {
        if (this.handleValidateForm()) {
            const {levelSection, onAddLimit} = this.props;
            const {form} = this.state;
            onAddLimit && onAddLimit(levelSection, form);
        }
    };

    _renderHeader() {
        return (
            <div>
                <div className="product-heading">
                    Release Level And Limit
                </div>
                <div className="title-gradient"/>
            </div>

        )
    }

    render() {
        const {form, errors} = this.state;
        return (
            <div>
                {this._renderHeader()}
                <br/>
                <div className="card-container-form">
                    <FormField className="form-group" error={errors && errors.level}>
                        <label htmlFor="level">Level</label>
                        <Select
                            name="levels"
                            onChange={(event) => {
                                errors && errors.level && this.handleResetError('level');
                                this.handleSelectChange(event, 'level')
                            }}
                            options={LEVELS}
                            value={form && form.level ? form.level : ''}
                        />
                    </FormField>

                    <FormField className="form-group" error={errors && errors.limit}>
                        <label htmlFor="level">Limit in ZAR</label>
                        <input
                            className="form-control"
                            onChange={(event) => {
                                errors && errors.limit && this.handleResetError('limit');
                                this.handleOnChange(event, 'limit');
                            }}
                            type="text"
                            value={form && form.limit ? form.limit : ''}
                        />
                    </FormField>
                </div>

                <div className="flexRow" style={{justifyContent: "space-between"}}>
                    <div/>
                    <button
                        className="action-btn-secondary"
                        onClick={this.handleAddLevelAndLimit}
                        type="button"
                    >Add
                    </button>
                </div>
            </div>

        )
    }
};

const ReleaseLevelsList = ({isGroup, levelSection, levels, onRemove}) => {
    const hasLevels = _.size(levels) > 0;
    if (!hasLevels) return null;
    const findLevel = (level) => {
        const foundLevel = _.find(LEVELS, l => (level === l.value));
        return foundLevel && foundLevel.label
    };
    return (
        <div className="list-scroller" style={{maxHeight: 165, minHeight: 0}}>
            {
                _.map(levels, (r, i) => (
                    <div key={`level/${levelSection.sectionId}/${i}`}>
                        <div className="flexRow" style={{alignItems: "center",}}>
                            <div className="col-md-11">
                                <span className={"active-entity"}> {`${i + 1}. Level: ${findLevel(r.level)}`}</span>
                                <span className={"greyText"}> | {`Limit: ZAR${r.limit}`}</span>
                            </div>
                            <div className="col-md-1" style={{padding: 0}} id="item-content">
                                <p><a className="btn"
                                      role="button"
                                      id="remove-btn"
                                      onClick={() => onRemove(levelSection, i)}> <i
                                    className="fa fa-times" aria-hidden="true"/></a>
                                </p>
                            </div>
                        </div>
                        <hr style={{padding: 1, margin: 0}}/>



                    </div>
                ))
            }
        </div>
    )
};