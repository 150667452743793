import React from 'react';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import * as actions from "../../actions/digitalCredit";

class CreditConfirmationPage extends React.Component {
    _renderFooter(){
        return(
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={() => this.props.history.replace("/")}
                    type="button"
                >Home
                </button>
            </div>
        )
    }
    render() {
        const {creditApplication} = this.props;
        const facilityProducts = creditApplication && creditApplication.facilityProducts;
        const product = _.size(facilityProducts) > 0 ? facilityProducts[0].product : '';
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="flexColumn">
                        <div className="card-container">
                            <div className="section-title">Application Submitted</div>
                            <p>Congratulations, your request for a <b>{product}</b> has been sent to our credit team.</p>
                            <p>Expect a call from us within 24 hours!!!</p>
                        </div>
                    </div>
                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
            </div>
        );
    }
}

function mapStateToProps({digitalCredit}) {
    return {
        creditApplication: digitalCredit && digitalCredit.creditApplication ? digitalCredit.creditApplication : {}
    }
}
function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CreditConfirmationPage);