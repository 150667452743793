import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import validate from 'validate.js';
import 'react-select/dist/react-select.css';
import branches from '../../data/branches-std-only';
import {confirmAccount} from '../../actions/product';
import {shariahBcaRoutes} from '../../routers/routes';
import {clearBolProfileOperators, fetchBolOperators, fetchBolProfiles} from '../../actions/bol';
import ButtonsComponent from '../ButtonsComponent';
import FormField from '../formField/FormField';
import {Glyphicon, OverlayTrigger, ToggleButtonGroup, Tooltip} from 'react-bootstrap';
import {BolSetup} from './shariahBcaBolSetup';
import {trackData} from '../../actions/analytics';
import Modal from 'react-bootstrap/lib/Modal';
import Jumbotron from 'react-bootstrap/lib/Jumbotron';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import Row from 'react-bootstrap/lib/Row';
    
const STATEMENTS_DELIVERY_METHODS = {
    email: 'E',
   // post: 'M',
    suppressStatement: 'S',
};
const FREQUENCY = {
    monthly: 'Monthly',
    weekly: 'Weekly',
    daily: 'Daily'
};



export const ADDITIONAL_PRODUCTS = {
    bolId: 'Business Online',
    cashManId: 'Cash Management'
};

export const OTHER_PROFILE_ID = {value: 'otherId', label: 'Other'};

// const CashmanDisclaimer = (props) => {
//     return (
//         <Modal show={props.show} bsSize={'lg'} keyboard={false} backdrop={'static'} >
//             <Modal.Header><h3>Disclaimer</h3></Modal.Header>
//             <Modal.Body>
//                 <p>
//                     Please read the below disclaimer regarding Cash Management and
//                     indicate whether you agree or do not agree.
//                 </p>
//                 <Jumbotron style={{overflowY:'scroll', maxHeight:'40em'}}>
//                     <p>
//                         The Standard Bank of South Africa Limited (“<strong>the Bank</strong>”) has created a digital platform for
//                         Account Opening which allows our customers to open bank accounts on the digital channel.
//                         The digital channel has the capability to link new bank accounts to existing Cash Management
//                         (“<strong>Cash Man</strong>”) structures, if any.  The customers must be aware that two scenarios exist:
//                     </p>
//                     <h3>
//                         <u>Scenario 1</u>
//                     </h3>
//                     <p>
//                         In the event that the bank account that is to be loaded to the digital channel relates to a
//                         legal entity that <u>has not</u> already been onboarded onto an existing Cash Man structure then the
//                         relevant internal due diligence procedures would need to be conducted and legal documents
//                         relevant to the Cash Man product that is being offered to the customer would need to be signed
//                         prior to the onboarding of the bank account to the digital channel and the Cash Man structure
//                         being implemented.
//                     </p>
//                     <p>
//                         The above processes take time to conduct and the Bank will conduct these internal process
//                         requirements within a reasonable time, after receipt of such written request from the customer
//                         that they are intending on opening a bank account on the digital channel and add such bank
//                         account on the Cash Man structure.
//                     </p>
//                     <h3>
//                         <u>Scenario 2</u>
//                     </h3>
//                     <p>
//                         In the event that the bank account that is to be loaded to the digital channel relates to a
//                         legal entity that has already been onboarded onto an existing Cash Man structure then the
//                         relevant internal due diligence procedure would not be required as these would have already
//                         have been conducted when the Cash Man product was onboarded and the process will be finalised
//                         within a reasonable period of time.
//                     </p>
//                     <p>
//                         <strong>
//                             The Customer indemnifies the Bank against any loss, damage, claims, costs or any other
//                             liability which may arise as a result of the Bank conducting the various internal process
//                             requirements before the bank account and Cash Man structure are onboarded and implemented
//                             respectively.
//                         </strong>
//                     </p>
//                 </Jumbotron>
//             </Modal.Body>
//             <Modal.Footer>
//                 <ButtonGroup>
//                     <Button onClick={props.agree} bsStyle={'primary'}>
//                         Agree
//                     </Button>
//                     <Button onClick={props.notAgree} bsStyle={'default'}>Do Not Agree</Button>
//                 </ButtonGroup>
//             </Modal.Footer>
//         </Modal>
//     );
// };

export const shariahBcaConfirmProduct = (props) => {

    const appEntity = useSelector(state => state.application && state.application.application.legalEntity);
    const applicationId = useSelector(state => state.application && state.application.application.id);
    const additionalProductsList = useSelector(state => state.application && state.application.application.additionalProductsRequired);
    const accountDetails = useSelector(state => state.application && state.application.application.bankAccount);
    const bolProfiles = useSelector(state => state.bol && state.bol.profiles);
    const bolProfileOperators = useSelector(state => state.bol && state.bol.operators);
    const dispatch = useDispatch();
    const tagged = useState(true);

    // useEffect(() => {
    //         dispatch(fetchBolProfiles(appEntity.cif));
    //     }, []
    // );

    useEffect(() => {
        dispatch(trackData('pageName', 'New Account| Confirm Product| Step 3'));
    }, tagged);

    const confirmDetails = (accountDetails, additionalProducts, saveAndContinue = false) => {
        const {history} = props;
        let nextPage = saveAndContinue ? '/saveapplication' : shariahBcaRoutes.shariahBcaKYC;
        const onProceed = () => history.push(nextPage);
        dispatch(confirmAccount(accountDetails, additionalProducts, applicationId, saveAndContinue, onProceed));
    };


    const onFetchOperators = (profileId) => {
        dispatch(clearBolProfileOperators());
        _.defer(() => profileId && dispatch(fetchBolOperators(profileId)));
    };

    return (
        <ProductDetailsCard
            appEntity={appEntity}
            accountDetails={accountDetails}
            additionalProductsList={additionalProductsList}
            bolProfiles={bolProfiles}
            bolProfileOperators={bolProfileOperators}
            confirmDetails={confirmDetails}
            onFetchOperators={onFetchOperators}
            history={props.history}
        />
    );

};

const ProductDetailsCard = (props) => {

    const _bolProduct = _.find(props.additionalProductsList, product => product.id === ADDITIONAL_PRODUCTS.bolId);
    // const _cashManProduct = _.find(props.additionalProductsList, product => product.id === ADDITIONAL_PRODUCTS.cashManId);
    const _accountDetails = props.accountDetails;
    const {bolProfiles, bolProfileOperators, onFetchOperators} = props;
    const defaultBranch = _.find(branches, branch => branch.centreName == 'JOHANNESBURG');
    const filteredBranches = _.filter(branches, b => b.centreName !== defaultBranch.centreName);

    const [errors, setErrors] = useState({});
    const [bolProduct, setBolProduct] = useState(_bolProduct);
    // const [cashManProduct, setCashManProduct] = useState(_cashManProduct);
    const [accountDetails, setAccountDetails] = useState(_accountDetails);
    const [additionalProducts, setAdditionalProducts] = useState({
        businessOnline: bolProduct ? 'YES' : null
        // cashManagement: cashManProduct ? 'YES' : null,
        // cashManGroupNumber: cashManProduct && cashManProduct.cashManGroupNumber,
        // cashManDisclaimer: false
    });
    const [disclaimCash, setDisclaimCash] = useState(false);

    const statementTooltip = (
        <Tooltip id="tooltip">
            <strong>Suppress Statement: </strong>
            Select the following option
            if you receive statements
            from Business Online or via Host-to-Host

        </Tooltip>
    );

    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    };

    // const validateForm = () => {
    //     const options = {fullMessages: false};
    //     const forbiddenDomains = ['gmail.com', 'yahoo.com', 'hotmail.com'];
    //     const constraints = _.extend(
    //         {accountName: {presence: true, length: {minimum: 1, message: 'required'}}},
    //         accountDetails && accountDetails.preferences && accountDetails.preferences.statementDeliveryMethod === STATEMENTS_DELIVERY_METHODS.email ? {
    //             deliveryDestination: {
    //                 presence: true, email: true, length: {minimum: 3, message: 'required'},
    //                 format: function (value, obj) {
    //                     const valueDomain = value && value.split('@').slice(1)[0];
    //                     const domain = valueDomain && valueDomain.toLowerCase();
    //                     return _.find(forbiddenDomains, f => f === domain);
    //                 }
    //             }
    //         } : null
    //     );

    //     let errors = validate(accountDetails && accountDetails.preferences, constraints, options);


    //     if (additionalProducts && additionalProducts.businessOnline ==='YES' || additionalProducts && additionalProducts.cashManagement === 'YES') {
    //         const {businessOnline} = additionalProducts;
   
    //         const bolConstraints = _.extend(businessOnline === 'YES' ? {
    //             bolProfileId: {
    //                 presence: true,
    //                 length: {minimum: 1, message: 'required'}
    //             },
    //             transactionalPayments: {
    //                 presence: true, numericality: {onlyInteger: true, greaterThan: 0}
    //             },
    //             transactionalTransfers: {
    //                 presence: true, numericality: {onlyInteger: true, greaterThan: 0}
    //             },
    //             shortName: {
    //                 presence: true,
    //                 length: {minimum: 1, message: 'Required'}
    //             },
    //             operators: {presence: true}
    //         } : null);

    //         const config = bolProduct && bolProduct.config;
    //         const limits = bolProduct && bolProduct.config && bolProduct.config.limits;
    //         const operators = bolProduct && bolProduct.config && bolProduct.config.operators;
    //         const bolProfileId = bolProduct && (bolProduct.bolProfileId === OTHER_PROFILE_ID.value) ? null : bolProduct && bolProduct.bolProfileId;
    //         const bolErrors = validate({...bolProduct, ...config, ...limits, operators, bolProfileId}, bolConstraints, options);
    //         const err = validate(additionalProducts, options);
    //         errors = _.extend({}, errors, err, bolErrors);
    //     }
    //     setErrors(errors);
    //     return _.isEmpty(errors);
    // };

    const validateForm = () => {
        const options = { fullMessages: false };
        const forbiddenDomains = ['gmail.com', 'yahoo.com', 'hotmail.com'];
        const constraints = {
          accountName: { presence: true, length: { minimum: 1, message: 'required' } },
        };
      
        if (
          accountDetails &&
          accountDetails.preferences &&
          accountDetails.preferences.statementDeliveryMethod === STATEMENTS_DELIVERY_METHODS.email
        ) {
          constraints.deliveryDestination = {
            presence: true,
            email: true,
            length: { minimum: 3, message: 'required' },
            format: function (value, obj) {
              const valueDomain = value && value.split('@').slice(1)[0];
              const domain = valueDomain && valueDomain.toLowerCase();
              return forbiddenDomains.includes(domain);
            },
          };
        }
      
        let errors = validate(accountDetails && accountDetails.preferences, constraints, options);
      
        if (
          additionalProducts &&
          (additionalProducts.businessOnline === 'YES' || additionalProducts.cashManagement === 'YES')
        ) {
          const { businessOnline } = additionalProducts;
      
          if (businessOnline === 'YES') {
            const bolConstraints = {
              bolProfileId: { presence: true, length: { minimum: 1, message: 'required' } },
              transactionalPayments: { presence: true, numericality: { onlyInteger: true, greaterThan: 0 } },
              transactionalTransfers: { presence: true, numericality: { onlyInteger: true, greaterThan: 0 } },
              shortName: { presence: true, length: { minimum: 1, message: 'Required' } },
              operators: { presence: true },
            };
      
            const config = bolProduct && bolProduct.config;
            const limits = bolProduct && bolProduct.config && bolProduct.config.limits;
            const operators = bolProduct && bolProduct.config && bolProduct.config.operators;
            const bolProfileId =
              bolProduct && bolProduct.bolProfileId === OTHER_PROFILE_ID.value ? null : bolProduct && bolProduct.bolProfileId;
            const bolErrors = validate(
              { ...bolProduct, ...config, ...limits, operators, bolProfileId },
              bolConstraints,
              options
            );
            errors = { ...errors, ...bolErrors };
          }
      
          const err = validate(additionalProducts, options);
          errors = { ...errors, ...err };
        }
      
        setErrors(errors);
        return _.isEmpty(errors);
      };
      

    const saveAndContinue = (event) => {
        saveDetails(event, true);

    };

    const saveDetails = (event, saveAndContinue) => {
        event.preventDefault();

        console.log(bolProduct);

        if (validateForm()) {
            const {additionalProductsList, confirmDetails} = props;

            let updatedAdditionalProductsList = [];
            if (additionalProducts.businessOnline === 'YES') {
                updatedAdditionalProductsList = _.union(additionalProductsList, [bolProduct]);
            }

            if (!accountDetails.centreNumber) {
                const defaultBranch = _.find(branches, b => b.centreName === 'JOHANNESBURG');
                accountDetails.centreNumber = defaultBranch.centreNumber;
                accountDetails.branchName = defaultBranch.centreName;
                accountDetails.branchCode = defaultBranch.centreIbt;
            }
            confirmDetails(accountDetails, updatedAdditionalProductsList, saveAndContinue);
        }
    };

    const handleDeliveryMethodChange = ({target}) => {
        const accPreferences = accountDetails && accountDetails.preferences;
      //  const isPost = target.value === STATEMENTS_DELIVERY_METHODS.post;
        const isSuppress = target.value === STATEMENTS_DELIVERY_METHODS.suppressStatement;

        let preferences = _.extend({}, accPreferences, {statementDeliveryMethod: target.value});

//        if (isPost) {
//            preferences = _.extend(preferences, {
//                statementDeliveryFrequency: FREQUENCY.monthly,
//                deliveryDestination: null
//            });
//        } else
         if (isSuppress) {
            preferences = _.extend(preferences, {
                statementDeliveryFrequency: null,
                deliveryDestination: null
            });
        } else {
            preferences = _.extend(preferences, {
                statementDeliveryFrequency: FREQUENCY.monthly,
            });
        }

        setAccountDetails(_.extend({}, accountDetails, {preferences}));
    };

    const handleBranchChange = ({target}) => {
        const selectedBranch = _.find(branches, b => b.centreName === target.value);
        const updatedAccount = {
            ...accountDetails,
            centreNumber: selectedBranch.centreNumber,
            branchName: selectedBranch.centreName,
            branchCode: selectedBranch.centreIbt
        };
        setAccountDetails(updatedAccount);
    };

    const handlePrefChange = ({target}, attribute) => {
        const {value} = target;

        const preferences = _.extend({}, accountDetails && accountDetails.preferences, {[attribute]: _.trimStart(value)});
        const updatedAccountDetails = _.extend({}, accountDetails, {preferences});
        setAccountDetails(updatedAccountDetails);
    };

    const onBusOnlineClick = (isSelected) => {
        isSelected === 'NO' && setBolProduct(null);
        setAdditionalProducts(_.extend({}, additionalProducts, {businessOnline: isSelected}));
    };

    // const onCashManagementClick = (isSelected) => {
    //     if (isSelected === 'YES'){
    //         setDisclaimCash(true);
    //     } else {
    //         setAdditionalProducts(_.extend({}, additionalProducts, {cashManGroupNumber: ''}));
    //         setAdditionalProducts(_.extend({}, additionalProducts, {cashManDisclaimer: false}));
    //         setAdditionalProducts(_.extend({}, additionalProducts, {cashManagement: isSelected}));
    //     }
    // };
    // const disclaimerAgree = () => {
    //     setDisclaimCash(false);
    //     setAdditionalProducts(_.extend({}, additionalProducts, {cashManDisclaimer: true}));
    //     setAdditionalProducts(_.extend({}, additionalProducts, {cashManagement: 'YES'}));
    // };
    // const disclaimerNotAgree = () => {
    //     setDisclaimCash(false);
    //     setAdditionalProducts(_.extend({}, additionalProducts, {cashManDisclaimer: false}));
    //     setAdditionalProducts(_.extend({}, additionalProducts, {cashManagement: 'NO'}));
    // };

    // const onAdditionalChange = (e, attribute) => {
    //     setAdditionalProducts(_.extend({}, additionalProducts, {[attribute]: (e.target.value)}));
    // };

    const onChangeBolProduct = (bolProduct) => {
        setBolProduct(bolProduct);
    };


//    if (props.appEntity.typeOfEntity==='Commercial'){
//    return (
//            <div>
//                <form className="page-container">
//                    <div className="container" style={{minHeight: '50em'}}>
//                        <div className="col-xs-12 col-md-6">
//                            <div className="card-container-form">
//                                <div className="section-title">
//                                    <span>Product Details</span>
//                                </div>

//                                <FormField className="form-group" id="accName" error={errors && errors.accountName}>
//                                    <label htmlFor="accountttName">Account Name <small className="text-danger">*</small>
//                                    </label>

//                                    <input
//                                        className="form-control"
//                                        id="accountName"
//                                        onChange={(event) => {
//                                            errors && errors.accountName && resetError('accountName');
//                                            handlePrefChange(event, 'accountName');
//                                        }}
//                                        maxLength={25}
//                                        type="text"
//                                        value={accountDetails && accountDetails.preferences && accountDetails.preferences.accountName || ''}
//                                    />
//                                </FormField>

//                                <div className="form-group">
//                                    <label htmlFor="branch">Branch <small className="text-danger">*</small></label>
//                                    <select
//                                        className="form-control"
//                                        id="branch"
//                                        onChange={(event) => handleBranchChange(event)}
//                                        value={accountDetails && accountDetails.branchName || ''}
//                                    >
//                                        <option key={'branchCode-default'}>{defaultBranch.centreName}</option>
//                                        {
//                                            _.map(filteredBranches, (b) => (
//                                                <option key={`branchCode-${b.centreNumber}`}>{b.centreName}</option>
//                                            ))
//                                        }
//                                    </select>
//                                </div>

//                                <div className="form-group">
//                                    <label htmlFor="bankStatementDeliveryMethod">
//                                        Bank Statement Delivery Method <small className="text-danger">*</small>&nbsp;
//                                        <OverlayTrigger placement="right" overlay={statementTooltip}>
//                                            <Glyphicon glyph="question-sign"/>
//                                        </OverlayTrigger>
//                                    </label>
//                                    <select
//                                        className="form-control"
//                                        id="statementDeliveryMethod"
//                                        onChange={(event) => handleDeliveryMethodChange(event)}
//                                        value={accountDetails && accountDetails.preferences && accountDetails.preferences.statementDeliveryMethod || ''}>
//                                        <option value={STATEMENTS_DELIVERY_METHODS.suppressStatement}>Suppress Statement</option>
//                                        <option value={STATEMENTS_DELIVERY_METHODS.email}>Email
//                                        </option>
//                                    </select>
//                                </div>

//                                {
//                                    accountDetails && accountDetails.preferences && accountDetails.preferences.statementDeliveryMethod === STATEMENTS_DELIVERY_METHODS.email &&
//                                    <FormField className="form-group" id="desti"
//                                               error={errors && errors.deliveryDestination}>
//                                        <label htmlFor="deliveryDestination">Bank Statement Email Address <small
//                                            className="text-danger">*</small></label>
//                                        <input
//                                            id="deliveryDestination"
//                                            className="form-control"
//                                            onChange={(event) => {
//                                                errors && errors.deliveryDestination && resetError('deliveryDestination');
//                                                handlePrefChange(event, 'deliveryDestination');
//                                            }}
//                                            type="email"
//                                            value={accountDetails && accountDetails.preferences && accountDetails.preferences.deliveryDestination || ''}
//                                        />
//                                    </FormField>
//                                }

//                                {
//                                    accountDetails && accountDetails.preferences && accountDetails.preferences.statementDeliveryMethod === STATEMENTS_DELIVERY_METHODS.email &&

//                                    <div className="form-group">
//                                        <label htmlFor="bankStatementFrequency">Bank Statement Frequency</label>
//                                        <select
//                                            className="form-control"
//                                            id="bankStatementFrequency"
//                                            onChange={(event) => handlePrefChange(event, 'statementDeliveryFrequency')}
//                                            value={accountDetails && accountDetails.preferences && accountDetails.preferences.statementDeliveryFrequency || ''}
//                                        >
//                                            <option>{FREQUENCY.monthly}</option>
//                                            <option>{FREQUENCY.weekly}</option>
//                                            <option>{FREQUENCY.daily}</option>
//                                        </select>
//                                    </div>
//                                }
//                            </div>
//                        </div>
//                        <div className="col-xs-12 col-md-6">
//                            <div className="card-container-form">
//                                <div className="section-title">
//                                    <span>Add account to the following:</span>
//                                </div>
//                                <div className="input-group greyText fontSize">
//                                    <p>Select from the list below if your need you account to be added to them:</p>
//                                </div>

//                                <div>
//                                    <Row style={{margin:'0'}}>
//                                        <span style={{paddingRight:'1em'}}>Business Online</span>
//                                        <ToggleButtonGroup type="radio" name="bolOptions" id={'bolOptions'} onChange={onBusOnlineClick}
//                                                           value={additionalProducts && additionalProducts.businessOnline}>
//                                            <ToggleButton id={'bolYes'} value={'YES'}>Yes</ToggleButton>
//                                            <ToggleButton id={'bolNo'} value={'NO'}>No</ToggleButton>
//                                        </ToggleButtonGroup>
//                                    </Row>
//                                </div>

//                                {
//                                    (additionalProducts && additionalProducts.businessOnline === 'YES') &&

//                                    <BolSetup
//                                        bolProduct={bolProduct}
//                                        bolProfiles={bolProfiles}
//                                        bolProfileOperators={bolProfileOperators}
//                                        errors={errors}
//                                        onFetchOperators={onFetchOperators}
//                                        onChange={onChangeBolProduct}
//                                        resetErrors={resetError}
//                                    />
//                                }
//                                <hr/>
//                                <hr/>
//                            </div>
//                        </div>
//                    </div>

//                    <div className="page-footer-section">
//                        <ButtonsComponent
//                            history={props.history}
//                            onNext={saveDetails}
//                            onSaveAndContinue={saveAndContinue}
//                            prevPage={shariahBcaRoutes.confirmdetails}
//                        />
//                    </div>
//                </form>
//                <CashmanDisclaimer
//                    show={disclaimCash}
//                    agree={disclaimerAgree}
//                    notAgree={disclaimerNotAgree}
//                />
//            </div>
//        );

//    }
//    else{
       return (
        <div>
            <form className="page-container">
                <div className="container" style={{minHeight: '50em'}}>
                    <div className="col-xs-12 col-md-6">
                        <div className="card-container-form">
                            <div className="section-title">
                                <span>Product Details</span>
                            </div>

                            <FormField className="form-group" id="accName" error={errors && errors.accountName}>
                                <label htmlFor="accountName">Account Name <small className="text-danger">*</small>
                                </label>

                                <input
                                    className="form-control"
                                    id="accountName"
                                    onChange={(event) => {
                                        errors && errors.accountName && resetError('accountName');
                                        handlePrefChange(event, 'accountName');
                                    }}
                                    maxLength={25}
                                    type="text"
                                    value={accountDetails && accountDetails.preferences && accountDetails.preferences.accountName || ''}
                                />
                            </FormField>

                            <div className="form-group">
                                <label htmlFor="branch">Branch <small className="text-danger">*</small></label>
                                <select
                                    className="form-control"
                                    id="branch"
                                    onChange={(event) => handleBranchChange(event)}
                                    value={accountDetails && accountDetails.branchName || ''}
                                >
                                    <option key={'branchCode-default'}>{defaultBranch.centreName}</option>
                                    {
                                        _.map(filteredBranches, (b) => (
                                            <option key={`branchCode-${b.centreNumber}`}>{b.centreName}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="bankStatementDeliveryMethod">
                                    Bank Statement Delivery Method <small className="text-danger">*</small>&nbsp;
                                    <OverlayTrigger placement="right" overlay={statementTooltip}>
                                        <Glyphicon glyph="question-sign"/>
                                    </OverlayTrigger>
                                </label>
                                <select
                                    className="form-control"
                                    id="statementDeliveryMethod"
                                    onChange={(event) => handleDeliveryMethodChange(event)}
                                    value={accountDetails && accountDetails.preferences && accountDetails.preferences.statementDeliveryMethod || ''}>
                                    <option value={STATEMENTS_DELIVERY_METHODS.suppressStatement}>Suppress Statement</option>
                                    <option value={STATEMENTS_DELIVERY_METHODS.email}>Email
                                    </option>
                                </select>
                            </div>

                            {
                                accountDetails && accountDetails.preferences && accountDetails.preferences.statementDeliveryMethod === STATEMENTS_DELIVERY_METHODS.email &&
                                <FormField className="form-group" id="desti"
                                            error={errors && errors.deliveryDestination}>
                                    <label htmlFor="deliveryDestination">Bank Statement Email Address <small
                                        className="text-danger">*</small></label>
                                    <input
                                        id="deliveryDestination"
                                        className="form-control"
                                        onChange={(event) => {
                                            errors && errors.deliveryDestination && resetError('deliveryDestination');
                                            handlePrefChange(event, 'deliveryDestination');
                                        }}
                                        type="email"
                                        value={accountDetails && accountDetails.preferences && accountDetails.preferences.deliveryDestination || ''}
                                    />
                                </FormField>
                            }

                            {
                                accountDetails && accountDetails.preferences && accountDetails.preferences.statementDeliveryMethod === STATEMENTS_DELIVERY_METHODS.email &&

                                <div className="form-group">
                                    <label htmlFor="bankStatementFrequency">Bank Statement Frequency</label>
                                    <select
                                        className="form-control"
                                        id="bankStatementFrequency"
                                        onChange={(event) => handlePrefChange(event, 'statementDeliveryFrequency')}
                                        value={accountDetails && accountDetails.preferences && accountDetails.preferences.statementDeliveryFrequency || ''}
                                    >
                                        <option>{FREQUENCY.monthly}</option>
                                        <option>{FREQUENCY.weekly}</option>
                                        <option>{FREQUENCY.daily}</option>
                                    </select>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="card-container-form">
                            <div className="section-title">
                                <span>Add account to the following:</span>
                            </div>
                            <div className="input-group greyText fontSize">
                                <p>Select from below if you need your account to be added to:</p>
                            </div>

                            <div>
                                <Row style={{margin:'0'}}>
                                    <span style={{paddingRight:'1em'}}>Business Online</span>
                                    <ToggleButtonGroup type="radio" name="bolOptions" id={'bolOptions'} onChange={onBusOnlineClick}
                                                       value={additionalProducts && additionalProducts.businessOnline}>
                                        <ToggleButton id={'bolYes'} value={'YES'}>Yes</ToggleButton>
                                        <ToggleButton id={'bolNo'} value={'NO'}>No</ToggleButton>
                                    </ToggleButtonGroup>
                                </Row>
                            </div>

                            {
                                (additionalProducts && additionalProducts.businessOnline === 'YES') &&

                                <BolSetup
                                    bolProduct={bolProduct}
                                    bolProfiles={bolProfiles}
                                    bolProfileOperators={bolProfileOperators}
                                    errors={errors}
                                    onFetchOperators={onFetchOperators}
                                    onChange={onChangeBolProduct}
                                    resetErrors={resetError}
                                />
                            }
                            {/* <hr/>

                            <div className="form-group">
                                <div>
                                    <Row style={{margin:'0'}}>
                                        <span style={{paddingRight:'1em'}}>Cash Management</span>
                                        <ToggleButtonGroup type="radio" name="cashmanOptions" id="cashmanOptions" onChange={onCashManagementClick}
                                            value={additionalProducts && additionalProducts.cashManagement}>
                                            <ToggleButton id={'cashmanYes'} value={'YES'}>Yes</ToggleButton>
                                            <ToggleButton id={'cashmanNo'} value={'NO'}>No</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Row>
                                </div>
                                {
                                    (additionalProducts && additionalProducts.cashManagement ==='YES') &&
                                    <div>
                                        <br/>
                                        <FormField className="form-group" id="cashman"
                                                   error={errors && errors.cashManGroupNumber}>
                                            <label>Cashman Group Number</label>

                                            <input
                                                className="form-control"
                                                id="cashManGroupNumber"
                                                onChange={(event) => {
                                                    errors && errors.cashManGroupNumber && resetError('cashManGroupNumber');
                                                    onAdditionalChange(event, 'cashManGroupNumber');
                                                }}
                                                type="number"
                                                value={additionalProducts && additionalProducts.cashManGroupNumber || ''}
                                            />
                                        </FormField>
                                    </div>
                                }
                                <hr/>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="page-footer-section" style={{width:'80%'}}>
                    <ButtonsComponent
                        history={props.history}
                        onNext={saveDetails}
                        onSaveAndContinue={saveAndContinue}
                        prevPage={shariahBcaRoutes.shariahBcaConfirmDetails}
                    />
                </div>
            </form>
            {/* <CashmanDisclaimer
                show={disclaimCash}
                agree={disclaimerAgree}
                notAgree={disclaimerNotAgree}
            /> */}
        </div>
    );
    // }
};

export default (shariahBcaConfirmProduct);
