import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NoLinkedEntitiesMessage from "../legalentity/NoLinkedEntitiesMessage";
import { fetchHierachy } from "../../actions/entity";
import EntityList from "../legalentity/EntityList";
import FormField from "../formField/FormField";
import validate from "validate.js";
import { tpfaRoutes } from "../../routers/routes";
import { startTpfaApplication } from "../../actions/tpfaActions";

class TPFAStart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedEntity: null
        }
    }

    componentDidMount() {
        const { actions, impersonation } = this.props;
        actions.fetchHierachy(impersonation);
    }

    validateForm = () => {
        const {selectedEntity, termsAccepted} = this.state;
        const options = {fullMessages: false};
        const constraints = {selectedEntity: {presence: true}};

        let errors = validate({selectedEntity, termsAccepted}, constraints, options);
        if (!termsAccepted) {
            errors = _.extend({}, errors, validate({termsAccepted: null}, {termsAccepted: {presence: true}}, options))
        }
        this.setState({errors});
        return _.isEmpty(errors);
    };

    onSubmit = (e) => {

        e.preventDefault();
        const { actions, history, application, impersonation: { identityNumber } } = this.props;
        const { selectedEntity } = this.state;

        const entityAndProduct = { ...application , ...selectedEntity };

        console.log(this.props);

        const cif = selectedEntity && selectedEntity.cif;

        const onProceed = (success = true, message = '') => {
            if (success) {
                history.push(tpfaRoutes.thirdParty);
            } else {
                this.setState({ showHasNoApprovalMessage: true, message })
            }
        };

        if (this.validateForm() && cif) {
        // if (/*this.validateForm()*/ true && cif) {
            actions.startTpfaApplication(identityNumber, entityAndProduct, onProceed);
        }
    };

    handleSelect = (selectedEntity) => {
        this.setState({ selectedEntity });
    };

    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    handleAcceptTsAndCs = () => {
        const { errors } = this.state;
        errors && errors.termsAccepted && this.handleResetError("termsAccepted");
        this.setState(prevState => ({ termsAccepted: !prevState.termsAccepted }))
    };

    
    renderEntities = () => {
        const { entities } = this.props;
        const hasEntities = _.size(entities) > 0;

        if (!hasEntities) return null;
        const { selectedEntity, errors } = this.state;

        return (
            <FormField
                className="form-group"
                error={errors && errors.selectedEntity && ["One Should Be Selected"]} >
                <EntityList
                    entities={entities}
                    onSelect={this.handleSelect}
                    selectedEntity={selectedEntity}
                   
                />
            </FormField>
        );
    };

    renderTerms = () => {
        const { termsAccepted, errors } = this.state;
        return (
            <FormField
                className="form-group"
                error={errors && errors.termsAccepted && ["Terms And Conditions Should Be Accepted"]} >
                <div className="flexRow" style={{ alignItems: "flex-start", textAlign: "left" }}>
                    <div className="icon-container" onClick={this.handleAcceptTsAndCs}>
                        <i className={termsAccepted ? "fa fa-check-square-o" : "fa fa-square-o"}
                            style={{ color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10 }}
                        />
                    </div>
                    {/* https://www.businessonline.standardbank.co.za/docs/General%20Terms%20and%20Conditions.pdf */}
                    <p style={{ margin: 0 }}> I agree to the
                        <a target="_blank" href="https://www.standardbank.co.za/static_file/South%20Africa/PDF/Terms%20and%20Conditions/Personal/General_Terms_and_Conditions_productservices.pdf">Terms
                            and Conditions</a>;
                            
                        <a target="_blank"
                            href="https://www.standardbank.co.za/static_file/South%20Africa/PDF/Business%20Ts%20and%20Cs/Business_Transaction_Accounts_T&Cs.pdf"> Product
                            Terms and Conditions</a> , including sourcing of entity details from public domain.
                    </p>
                    {/* https://www.businessonline.standardbank.co.za/docs/Terms%20and%20conditions%20for%20business%20transactional%20accounts.pdf */}
                   
                </div>
            </FormField>
        )
    };

    renderFooter = () => {
        const { termsAccepted } = this.state;
        return (
            <div className="flexRow" style={{ justifyContent: "space-between" }}>
                <div />
                <button
                    className="action-btn-primary btn-sm"
                    onClick={this.onSubmit}
                    type="button"
                    // disabled={termsAccepted ? !termsAccepted : true}
                >Next
                </button>
            </div>
        )
    };

    render() {
        const { entities, loading } = this.props;
        if (loading) return null;

        const hasEntities = _.size(entities) > 0;
        if (!hasEntities) {
            return (
                <div className="page-container">
                    <div className="page-main-section">
                        <NoLinkedEntitiesMessage />
                    </div>
                </div>
            )
        } else {
            return (
                <div className="page-container">
                    <div className="page-main-section">
                        <div className="column" style={{ width: "60%" }}>
                            {this.renderEntities()}
                        </div>
                    </div>
                    {this.renderTerms()}
                    <div className="page-footer-section" style={{ width: "80%" }}>
                        {this.renderFooter()}
                    </div>
                </div>
            )
        }
    }
}

function mapStateToProps({ entity, businessOnline, application, impersonation, loading }) {
    const hasEntities = entity && entity.entities;
    return {
        application: application.application,
        entities: hasEntities ? entity.entities : [],
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading
    }
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators({ fetchHierachy, startTpfaApplication }, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(TPFAStart);