import React from 'react';
import _ from "lodash";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import validate from "validate.js";

import Select from 'react-select';
import 'react-select/dist/react-select.css';

import * as actions from "../../actions/digitalCredit";
import {digitalCreditRoutes} from '../../routers/routes'
import {fetchAccounts} from "../../actions/kyc";
import FormField from "../formField/FormField";

const Product_Type = [
    {value: "Working Capital Facilities", label: "Working Capital Facilities"},
    {value: "General Short Term Banking Facilities", label: "General Short Term Banking Facilities"},
    {value: "Debtor Financing", label: "Debtor Financing"},
    {value: "Invoice Financing", label: "Invoice Financing"},
    {value: "Import Financing", label: "Import Financing"},
    {value: "Import Finance", label: "Import Finance"},
    {value: "Credit Cards", label: "Credit Cards"},
    {value: "Company Corporate Card", label: "Company Corporate Card"},
    {value: "Individual Corporate Card", label: "Individual Corporate Card"},
    {value: "Fleet Management Card", label: "Fleet Management Card"},
    {value: "Structured Loans", label: "Structured Loans"},
    {value: "Residential Mortgage", label: "Residential Mortgage"},
    {value: "Vehicle And Asset Finance", label: "Vehicle And Asset Finance"},
    {value: "Trading Facilities", label: "Trading Facilities"},
    {value: "Letter of Credit", label: "Letter of Credit"},
    {value: "Performance Guarantees", label: "Performance Guarantees"},
    {value: "Financial Guarantees", label: "Financial Guarantees"},
    {value: "Discounting Facility", label: "Discounting Facility"},
    {value: "Trading", label: "Trading"},
    {value: "Securities Financing", label: "Securities Financing"},
    {value: "Equity Financing", label: "Equity Financing"},
    {value: "Credit Derivatives", label: "Credit Derivatives"},
];

const Tenor_Day = _.map(_.range(0, 31), d => ({value: d.toString(), label: d}));
const Tenor_Month = _.map(_.range(0, 12), d => ({value: d.toString(), label: d}));
const Tenor_Year = _.map(_.range(0, 6), d => ({value: d.toString(), label: d}));

const Currency_Type = [
    {value: "ZAR", label: "ZAR"},
    {value: "USD", label: "USD"},
];
const excludeTenory = [
    "Working Capital Facilities",
    " General Short Term Banking Facilities",
    "Credit Cards",
    "Company Corporate Card",
    "Individual Corporate Card",
    "Fleet Management Card",
];

class DigitalCreditPage extends React.Component {
    state = {
        errors: {},
        form: {}
    };

    handleSelectChange = (event, attribute, innerAttribute) => {
        const {form} = this.state;
        let value = event.value;
        if (innerAttribute) {
            value = _.extend({}, form && form[attribute], {[innerAttribute]: value});
        }
        const updateForm = _.extend({}, form, {[attribute]: value});
        this.setState({form: updateForm});
    };

    handleOnChange = ({target}, attribute, innerAttribute) => {
        const {form} = this.state;
        let value = target.value;
        if (innerAttribute) {
            value = _.extend({}, form && form[attribute], {[innerAttribute]: value});
        }
        const updateForm = _.extend({}, form, {[attribute]: value});
        this.setState({form: updateForm});
    };
    onResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };
    handlePrevious = () => {
        this.props.history.push(digitalCreditRoutes.root);
    };

    validateForm = () => {
        const {form} = this.state;
        const options = {fullMessages: false};
        const hideTenor = _.find(excludeTenory, key => (key === form.product));

        const constraints = _.extend({
            product: {presence: true},
            proposedLimit: {presence: true, numericality: true, length: {minimum: 1, message: 'required'}},
            limitCurrency: {presence: true}
        }, !hideTenor && {
            floatingProposedTenorDay: {presence: true},
            floatingProposedTenorMonth: {presence: true},
            floatingProposedTenorYear: {presence: true},
        });

        const errors = validate(form, constraints, options);

        this.setState({errors});
        return _.isEmpty(errors);
    };

    handleSubmit = (event) => {
        const {actions, creditApplication, history} = this.props;
        const {form} = this.state;
        event.preventDefault();
        if (this.validateForm()) {
            const hideTenor = _.find(excludeTenory, key => (key === form.product));
            const updatedForm = _.extend({}, form, hideTenor && {
                floatingProposedTenorDay: null,
                floatingProposedTenorMonth: null,
                floatingProposedTenorYear: null,
            });
            const updatedCreditApplication = _.extend({}, creditApplication, {facilityProducts: [updatedForm]});
            const onSuccess = () => history && history.push(digitalCreditRoutes.complete);
            actions.onCompleteApplication(updatedCreditApplication, onSuccess)
        }
    };

    _renderFooter() {
        return (
            <div className="flexRow" style={{justifyContent: "space-between"}}>
                <button
                    className="action-btn-primary"
                    onClick={this.handlePrevious}
                    type="button"
                >Previous
                </button>
                <button
                    className="action-btn-primary"
                    type="submit"
                >Complete
                </button>
            </div>
        )
    }

    render() {
        const {errors, form} = this.state;

        const hideTenor = _.find(excludeTenory, key => (key === form.product));

        return (
            <form className="page-container" onSubmit={this.handleSubmit}>
                <div className="page-main-section">

                    <div className="flexColumn">
                        <div className="card-container-form">
                            <div className="section-title">
                                <span> Credit Facility </span>
                            </div>

                            <FormField className="form-group"
                                       error={errors && errors.product}>
                                <label htmlFor="product">Product</label>
                                <Select
                                    name="product"
                                    onChange={(event) => {
                                        errors && errors.product && this.onResetError('product');
                                        this.handleSelectChange(event, 'product');
                                    }}
                                    options={Product_Type}
                                    value={form && form.product ? form.product : ''}
                                />
                            </FormField>

                            <FormField className="form-group"
                                       error={errors && errors.proposedLimit}>
                                <label htmlFor="proposedLimit">Limit</label>
                                <input
                                    name="proposedLimit"
                                    className="form-control"
                                    onChange={(event) => {
                                        errors && errors.proposedLimit && this.onResetError('proposedLimit');
                                        this.handleOnChange(event, 'proposedLimit');
                                    }}
                                    type="text"
                                    value={form && form.proposedLimit ? form.proposedLimit : ''}
                                />
                            </FormField>

                            <FormField className="form-group"
                                       error={errors && errors.limitCurrency}>
                                <label htmlFor="limitCurrency">Currency</label>

                                <Select
                                    name="limitCurrency"
                                    onChange={(event) => {
                                        errors && errors.limitCurrency && this.onResetError('limitCurrency');
                                        this.handleSelectChange(event, 'limitCurrency');
                                    }}
                                    options={Currency_Type}
                                    value={form && form.limitCurrency ? form.limitCurrency : ''}
                                />
                            </FormField>
                        </div>
                    </div>

                    <div className="flexColumn">
                        <div className="card-container-form">

                            <div className="section-title">
                                <span>Repayment Period</span>
                            </div>

                            {
                                !hideTenor &&
                                <div>
                                    <FormField className="form-group"
                                               error={errors && errors.floatingProposedTenorYear}>
                                        <label htmlFor="floatingProposedTenorYear">Year</label>
                                        <Select
                                            name="floatingProposedTenorYear"
                                            onChange={(event) => {
                                                errors && errors.floatingProposedTenorYear && this.onResetError('floatingProposedTenorYear');
                                                this.handleSelectChange(event, 'floatingProposedTenorYear');
                                            }}
                                            options={Tenor_Year}
                                            value={form && form.floatingProposedTenorYear ? form.floatingProposedTenorYear : ''}
                                        />
                                    </FormField>

                                    <FormField className="form-group"
                                               error={errors && errors.floatingProposedTenorMonth}>
                                        <label htmlFor="floatingProposedTenorMonth">Month</label>
                                        <Select
                                            name="floatingProposedTenorMonth"
                                            onChange={(event) => {
                                                errors && errors.floatingProposedTenorMonth && this.onResetError('floatingProposedTenorMonth');
                                                this.handleSelectChange(event, 'floatingProposedTenorMonth');
                                            }}
                                            options={Tenor_Month}
                                            value={form && form.floatingProposedTenorMonth ? form.floatingProposedTenorMonth : ''}
                                        />
                                    </FormField>
                                    <FormField className="form-group"
                                               error={errors && errors.floatingProposedTenorDay}>
                                        <label htmlFor="floatingProposedTenorDay">Day</label>
                                        <Select
                                            name="floatingProposedTenorDay"
                                            onChange={(event) => {
                                                errors && errors.floatingProposedTenorDay && this.onResetError('floatingProposedTenorDay');
                                                this.handleSelectChange(event, 'floatingProposedTenorDay');
                                            }}
                                            options={Tenor_Day}
                                            value={form && form.floatingProposedTenorDay ? form.floatingProposedTenorDay : ''}
                                        />
                                    </FormField>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
            </form>
        )
    }
}

function mapStateToProps({digitalCredit}) {
    return {
        creditApplication: digitalCredit && digitalCredit.creditApplication ? digitalCredit.creditApplication : {}
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, fetchAccounts}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(DigitalCreditPage);