import React from "react";
import _ from "lodash";

import "../../../styles/ConfirmApplication.css";
import "../../../styles/Global.css";
import "react-datepicker/dist/react-datepicker.css";
import { BasicInfoComponent } from "./BasicInfoComponent";
import { CorrespondentComponent } from "./CorrespondentComponent";
import { BankBeneficiaryComponent } from "./BankBeneficiaryComponent";
import { CustomerBeneficiaryComponent } from "./CustomerBeneficiaryComponent";
import { IntermediaryComponent } from "./IntermediaryComponent";

export const FormComponent = (props) => {
    return (
        <div className="col-md-12" style={{minHeight: 200}}>
            <div className="form-horizontal col-md-12" style={{padding: "0 10"}}>
                <hr style={{padding: 0}}/>
                
                <div>
                    <div className="col-md-3">&nbsp;</div>
                    <div className="col-md-6 form-group">
                        <div>
                            <div className="col-md-4">
                                <label htmlFor="customerName">Customer<span
                                    className="redText">&nbsp;*</span></label>
                            </div>
                            <div className="col-md-8">
                                {
                                    props.hasMoneyMarketSubscription ?
                                    (
                                        <input className="form-control" id="customerNameText" type="text"
                                            value={props.instruction.customerName}
                                            readOnly/>
                                    ) :
                                    (
                                        <select className="form-control col-md-10" id="customerName" type="text"
                                                name="customerName"
                                                onChange={(event) => props.handleOnCustomerChange(event)}
                                                value={props.instruction && props.instruction.customerName || ''}>
                                            <option key={`customerName-default`} value="">Select Customer</option>
                                            {
                                                _.map(props.customers, (c) => (
                                                    <option key={`customerName-${c.customerShortName}`} value={c.customerShortName}> {c.customerShortName} </option>
                                                ))
                                            }
                                        </select>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">&nbsp;</div>
                </div>

                <hr style={{padding: 0}}/>
                <div className="col-md-12">
                     <div className="col-md-4">
                        <BasicInfoComponent 
                            instruction={props.instruction}
                            hasMoneyMarketSubscription={props.hasMoneyMarketSubscription}
                            handleOnCustomerChange={props.handleOnCustomerChange}
                            
                            INSTRUMENTS={props.INSTRUMENTS}
                            customers={props.customers}
                            currencies={props.currencies}

                            handleOnToggle={props.handleOnToggle}
                            handleOnChange={props.handleOnChange}
                        />
                    </div>
                    <div className="col-md-4">
                        <CorrespondentComponent 
                            instruction={props.instruction}
                            handleOnToggle={props.handleOnToggle}
                            handleOnChange={props.handleOnChange}
                        />
                    </div>
                    <div className="col-md-4">
                        {
                            props.instruction.isBankInstruction ? (
                                <BankBeneficiaryComponent 
                                    instruction={props.instruction}
                                    handleOnToggle={props.handleOnToggle}
                                    handleOnChange={props.handleOnChange}
                                />
                            ) : (
                                <CustomerBeneficiaryComponent 
                                    instruction={props.instruction}
                                    handleOnToggle={props.handleOnToggle}
                                    handleOnChange={props.handleOnChange}
                                />
                            )
                        }
                    </div>
                </div>

                <div className="col-md-12">
                    <IntermediaryComponent 
                        instruction={props.instruction}
                        handleRemoveIntermediary={props.handleRemoveIntermediary}
                    />
                </div>

            </div>
        </div>
    )

}