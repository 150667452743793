import {createStore, compose, applyMiddleware} from 'redux';
import reduxThunk from 'redux-thunk';
import reducers from '../reducers/reducers';
import {loadState, saveState} from './localStorage'
import {AUTH_ERROR} from "../actions/actionTypes";

const autMiddleWare = store => next => action => {
    const unAuthenticated = action && (action.type === AUTH_ERROR || action.status == 401) ;
    if (unAuthenticated) {
        try {
            localStorage.removeItem('state');
        } catch (e) {
            console.log("authMiddleware.removeItem error==>", e)
        }
        window.location.replace("/");
    } else {
        return next(action)
    }
};

export default () => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const persistedState = loadState();

    const store = createStore(reducers, persistedState, composeEnhancers(
        applyMiddleware(reduxThunk), applyMiddleware(autMiddleWare)
    ));

    store.subscribe(() => {
        saveState(store.getState())
    });
    return store;
};