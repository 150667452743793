import React, { Component } from "react";
import _ from 'lodash'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import "../../../styles/kyc.css";
import '../../../styles/verifyModal.css';
import * as actions from '../../../actions/kyc';
import FileUpload from "../../FileUpload";
import FormField from "../../formField/FormField";
import specimenTemplate from "../../../resources/New_Signatory_specimens.pdf";
import validate from 'validate.js'

const DOC_TYPES = {
    signatorySpecimen: 'Signatory specimen',
};

export class NewSignatorySpecimenComponent extends Component {

    state = {errors:{}};

    _findDoc(entityDocs, docType) {
        const docFound = _.find(entityDocs, (doc) => doc.documentType === docType);
        return docFound ? docFound : null
    }


    onHandleUpload = (base64, inProgress, onComplete) => {
        const {actions, applicationId, entityDocs} = this.props;
        const {errors} = this.state;
        errors && errors.specimenDoc && this.resetError("specimenDoc");

        const onSuccess = (docId, data) => {
            actions.verifyDoc(entityDocs, DOC_TYPES.signatorySpecimen, applicationId, docId);
        };
        actions.uploadDoc(base64, onSuccess, null, inProgress, onComplete, onSuccess)
    };

  
    resetError = (attribute) => {
        const { errors } = this.state;
        this.setState({ errors: _.extend({}, errors, { [attribute]: null }) })
    };

    validateDocuments = () => {
        const { entityDocs } = this.props;
        const options = { fullMessages: false };
        const constraints = { specimenDoc: { presence: true } };
        const isFound = this._findDoc(entityDocs, DOC_TYPES.signatorySpecimen);
        const errors = validate({ specimenDoc: _.isEmpty(isFound) ? null : isFound }, constraints, options);

        this.setState({ errors });
        return _.isEmpty(errors);
    };


    onHandleRemoveDocument = (documentType) => { };


    render() {
        const { errors } = this.state;
        return (
            <div>
                <hr style={{ marginTop: 10, marginBottom: 10 }} />
                <FormField
                    className="form-group " id="proofOfIdDoc"
                    style={{ display: "flex", flexDirection: "column" }}
                    error={errors && errors.specimenDoc && ["A Signed Signatory Specimens Required"]}
                >
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <label htmlFor="proofOfId" style={{ margin: 0, padding: 0 }}>New Signatory Specimens</label>
                        {/* <a className="btn btn-secondary addButton" href={specimenTemplate} download> <i
                            className="fa fa-download fa-fw"/> Specimen Template</a> */}
                        <div className="flat-button-primary" variant="contained" color="primary">
                            <a className="btn btn-secondary addButton" href={specimenTemplate} download="SpecimenTemplate.pdf" target={'_blank'}>
                                <i className="fa fa-download fa-fw" />Specimen Template</a>
                        </div>
                    </div>
                    <FileUpload
                        documentType={DOC_TYPES.signatorySpecimen}
                        onUpload={this.onHandleUpload.bind(this)}
                        onRemoveDocument={this.onHandleRemoveDocument.bind(this)}
                    />

                </FormField>
            </div>
        );
    }
}

function mapStateToProps({ application }, ownProps) {
    const hasLegalEntity = application.application && application.application.legalEntity;
    return {
        applicationId: application && application.application && application.application.id,
        entityDocs: hasLegalEntity && application.application.legalEntity.supportingDocuments ? application.application.legalEntity.supportingDocuments : [],
    };
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(actions, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(NewSignatorySpecimenComponent);