import React from 'react';
import _ from "lodash";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import validate from "validate.js";
import 'react-select/dist/react-select.css';

import * as actions from "../../actions/bol";
import FormField from "../formField/FormField";

import Select from 'react-select';
import 'react-select/dist/react-select.css';

const BOL_PROFILE_UPDATE = {
    additionalProfile: "additionalProfile",
    new: "new"
};

const PASSWORD_EXPIRY_DAYS = [
    {value: 30, label: "30 Days"},
    {value: 60, label: "60 Days"},
    {value: 180, label: "180 Days"}
];

class CustomerDetailsPage extends React.Component {
    state = {
        errors: {},
        isSameAddress: true,
        bolProfileUpdate: BOL_PROFILE_UPDATE.new
    };

    componentDidMount() {
        _.defer(() => this._setDefaultValues());
    }

    _setDefaultValues() {
        const {actions, newBolProfile} = this.props;
        const customerDetails = newBolProfile && newBolProfile.customerDetails;
        if (customerDetails) {
            const {registeredAddress, addresses, registeredName, tradingNames} = customerDetails;
            const canSetAddress = _.size(addresses) > 0 && _.isEmpty(registeredAddress);
            const canSetRegisteredName = _.size(tradingNames) > 0 && !registeredName;

            if (canSetAddress || canSetRegisteredName) {
                const defaultAddress = addresses && addresses[0];
                const defaultName = tradingNames && tradingNames[0];

                const updatedProfile = _.extend({}, customerDetails, canSetAddress && {
                    registeredAddress: defaultAddress,
                    postalAddress: defaultAddress
                }, canSetRegisteredName && {registeredName: defaultName});

                actions.onChangeValue('newBolProfile', 'customerDetails', updatedProfile);
            }
            const bolProfileUpdate = customerDetails && customerDetails.isAdditionalProfile ? BOL_PROFILE_UPDATE.additionalProfile : BOL_PROFILE_UPDATE.new;
            this.setState({bolProfileUpdate})
        }
    }

    setBolUpdate = () => {
        const {actions, newBolProfile} = this.props;
        const {bolProfileUpdate} = this.state;
        const customerDetails = newBolProfile && newBolProfile.customerDetails;
        const isNewProfile = (bolProfileUpdate === BOL_PROFILE_UPDATE.new);
        const isAdditionalProfile = (bolProfileUpdate === BOL_PROFILE_UPDATE.additionalProfile);
        const updatedCustomerDetails = _.extend({}, customerDetails, {
            isNewProfile,
            isAdditionalProfile
        }, isNewProfile && {ownerId: null});
        actions.onChangeValue('newBolProfile', "customerDetails", updatedCustomerDetails);
    };

    onTogglePostalAddress = () => {
        this.setState(prevState => {
            this._setPostalAddress(!prevState.isSameAddress);
            return {isSameAddress: !prevState.isSameAddress}
        })
    };

    handleOnChange = ({target}, attribute, innerAttribute) => {
        const {actions, newBolProfile} = this.props;
        let value = target.value;
        if (innerAttribute) {
            value = _.extend({}, newBolProfile && newBolProfile[attribute], {[innerAttribute]: value})
        }
        actions.onChangeValue('newBolProfile', attribute, value);
    };

    handleSelectChange = (event, attribute, innerAttribute) => {
        const {actions, newBolProfile} = this.props;
        let value = event.value;
        if (innerAttribute) {
            value = _.extend({}, newBolProfile && newBolProfile[attribute], {[innerAttribute]: value})
        }
        actions.onChangeValue('newBolProfile', attribute, value);
    };


    handleOnChangeAddress = ({target}, attribute, addressType, isSame = false) => {
        const {actions, newBolProfile} = this.props;
        const customerDetails = newBolProfile && newBolProfile.customerDetails;

        if (customerDetails) {
            const onSetValue = (type) => ({[type]: _.extend({}, customerDetails[type], {[attribute]: target.value})});

            let value = _.extend({}, customerDetails, onSetValue(addressType));
            if (addressType === 'registeredAddress' && isSame) {
                value = _.extend({}, value, onSetValue('postalAddress'))
            }
            actions.onChangeValue('newBolProfile', "customerDetails", value);
        }
    };

    _setPostalAddress(isSame) {
        const {actions, newBolProfile} = this.props;
        const customerDetails = newBolProfile && newBolProfile.customerDetails;

        let postalAddress;
        if (isSame) {
            postalAddress = customerDetails.registeredAddress;
        } else {
            postalAddress = {
                street: null,
                suburb: null,
                city: null,
                province: null,
                postalCode: null,
                country: null
            }
        }
        const value = _.extend({}, customerDetails, {postalAddress});
        actions.onChangeValue('newBolProfile', "customerDetails", value);
    };

    handlePrevious = () => {
        this.props.history.push("/bol");
    };

    handleSaveAndContinue = () => {
        const {actions, newBolProfile, history} = this.props;
        this.setBolUpdate();
        const onProceed = () => history.replace('/');
        const updateDetails = _.extend({}, newBolProfile, {status: 'details'});
        actions.saveNewBolProfile(updateDetails, onProceed)
    };

    onResetError = (attribute, innerAttribute = null) => {
        let value = null;
        if (innerAttribute) {
            value = _.extend({}, this.state.errors[attribute], {[innerAttribute]: value});
        }
        const errors = _.extend({}, this.state.errors, {[attribute]: value});
        this.setState({errors})
    };

    _validateForm() {
        const {newBolProfile} = this.props;
        const {bolProfileUpdate, isSameAddress} = this.state;
        const customerDetails = newBolProfile && newBolProfile.customerDetails;
        const profileDetails = newBolProfile && newBolProfile.profileDetails;
        const isAdditionalProfile = (bolProfileUpdate === BOL_PROFILE_UPDATE.additionalProfile);
        const options = {fullMessages: false};

        const constraints = _.extend({
            registrationNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            registeredName: {presence: true, length: {minimum: 1, message: 'required'}},

        }, isAdditionalProfile && {ownerId: {presence: true, length: {minimum: 1, message: 'required'}}});

        const addressContraints = {
            street: {presence: true, length: {minimum: 1, message: 'required'}},
            suburb: {presence: true, length: {minimum: 1, message: 'required'}},
            city: {presence: true, length: {minimum: 1, message: 'required'}},
            province: {presence: true, length: {minimum: 1, message: 'required'}},
            postalCode: {presence: true, length: {minimum: 1, message: 'required'}},
            country: {presence: true, length: {minimum: 1, message: 'required'}},
        };

        const profileDetailsConstraints = {
            userProfileName: {presence: true, length: {minimum: 1, message: 'required'}},
            passwordExpiryDays: {presence: true},
        };

        const customerDetailsErrors = validate(customerDetails, constraints, options);
        const profileDetailsErrors = validate(profileDetails,profileDetailsConstraints, options);
        const registeredAddress = validate(customerDetails && customerDetails.registeredAddress, addressContraints, options);
        const postalAddress = !isSameAddress ? validate(customerDetails && customerDetails.postalAddress, addressContraints, options) : {};

        const errors = _.extend({}, customerDetailsErrors, profileDetailsErrors,{registeredAddress}, {postalAddress},);
        this.setState({errors});
        return _.isEmpty(_.extend({}, customerDetailsErrors,profileDetailsErrors, registeredAddress, postalAddress))
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (this._validateForm()) {
            const {actions, newBolProfile, history} = this.props;
            this.setBolUpdate();
            const onProceed = () => history.push("/bol/channels");
            const updateDetails = _.extend({}, newBolProfile, {status: 'channels'});
            actions.saveNewBolProfile(updateDetails, onProceed);
        }
    };

    _renderPostalAddress() {
        const {newBolProfile} = this.props;
        const customerDetails = newBolProfile && newBolProfile.customerDetails;
        const {errors, isSameAddress} = this.state;
        const postalAddress = customerDetails && customerDetails.postalAddress;

        return (
            <div>
                <div className="section-title">
                    <span>Postal Address</span>
                </div>
                <div className="flexRow"
                     style={{margin: 0, padding: 1, alignItems: "flex-start", textAlign: "left"}}
                     onClick={this.onTogglePostalAddress}
                >
                    <i
                        className={isSameAddress ? "fa fa-check-square-o" : "fa fa-square-o"}
                        style={{color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10}}
                    />Same as registered address
                    <div style={{flex:1}}/>
                </div>


                {
                    !isSameAddress &&
                    <AddressComponent
                        address={postalAddress}
                        addressType={'postalAddress'}
                        errors={errors && errors.postalAddress}
                        isSameAddress={isSameAddress}
                        onChangeAddress={this.handleOnChangeAddress}
                        onResetError={this.onResetError}
                    />
                }
            </div>
        )
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <button
                    className="action-btn-primary"
                    onClick={this.handlePrevious}
                    type="button"
                >Previous
                </button>
                <button
                    className="action-btn-secondary"
                    onClick={this.handleSaveAndContinue}
                    type="button"
                > Save And Continue Later
                </button>
                <button
                    className="action-btn-primary"
                    type="submit"
                >Next
                </button>
            </div>
        )
    }

    render() {
        const {newBolProfile} = this.props;
        const customerDetails = newBolProfile && newBolProfile.customerDetails;
        const profileDetails = newBolProfile && newBolProfile.profileDetails;
        const {errors, isSameAddress} = this.state;
        return (
            <form className="page-container" onSubmit={this.handleSubmit}>
                <div className="page-main-section">
                    <div className="flexColumn">
                        <div className="card-container-form">

                            <div className="section-title">
                                <span>Customer Details</span>
                            </div>

                            <FormField className="form-group" id="regNumber"
                                       error={errors && errors.registrationNumber}>
                                <label htmlFor="registrationNumber">Registration Number</label>
                                <input
                                    className="form-control"
                                    onChange={(event) => {
                                        errors && errors.registrationNumber && this.onResetError('registrationNumber');
                                        this.handleOnChange(event, 'customerDetails', 'registrationNumber');
                                    }}
                                    type="text"
                                    value={customerDetails && customerDetails.registrationNumber ? customerDetails.registrationNumber : ''}
                                />
                            </FormField>

                            <FormField className="form-group" id="registeredName"
                                       error={errors && errors.name}>
                                <label htmlFor="name">Customer Name</label>
                                <input
                                    className="form-control"
                                    onChange={(event) => {
                                        errors && errors.registeredName && this.onResetError('registeredName');
                                        this.handleOnChange(event, 'customerDetails', 'registeredName');
                                    }}
                                    type="text"
                                    value={customerDetails && customerDetails.registeredName ? customerDetails.registeredName : ''}
                                />
                            </FormField>

                        </div>
                    </div>
                    <div className="flexColumn">
                        <div className="card-container-form">
                            <div className="section-title">
                                <span>Registered Address</span>
                            </div>

                            <AddressComponent
                                address={customerDetails && customerDetails.registeredAddress}
                                addressType={'registeredAddress'}
                                errors={errors && errors.registeredAddress}
                                isSameAddress={isSameAddress}
                                onChangeAddress={this.handleOnChangeAddress}
                                onResetError={this.onResetError}
                            />
                            {this._renderPostalAddress()}
                        </div>
                    </div>
                    <div className="flexColumn">
                        <div className="card-container-form">
                        <div className="section-title">
                            <span>Profile Information</span>
                        </div>

                        <FormField className="form-group" id="profileName"
                                   error={errors && errors.userProfileName}>
                            <label htmlFor="userProfileName">Profile Name</label>
                            <input
                                className="form-control"
                                onChange={(event) => {
                                    //Same as eg, company name Dipula BEE Trust Holdings (Pty) Ltd.
                                    errors && errors.userProfileName && this.onResetError('userProfileName');
                                    this.handleOnChange(event, 'profileDetails', 'userProfileName');
                                }}
                                type="text"
                                value={profileDetails && profileDetails.userProfileName ? profileDetails.userProfileName : ''}
                            />
                        </FormField>

                        <FormField className="form-group"
                                   error={errors && errors.passwordExpiryDays ? ["One Should Be Selected"] : ""}>
                            <label htmlFor="passwordExpiryDays">Password Expiry</label>
                            <Select
                                name="passwordExpiryDays"
                                onChange={(event) => {
                                    errors && errors.passwordExpiryDays && this.onResetError('passwordExpiryDays');
                                    this.handleSelectChange(event, 'profileDetails', 'passwordExpiryDays')
                                }}
                                options={PASSWORD_EXPIRY_DAYS}
                                value={profileDetails && profileDetails.passwordExpiryDays ? profileDetails.passwordExpiryDays : ''}
                            />
                        </FormField>
                        </div>

                    </div>
                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
            </form>
        )
    };
}

const AddressComponent = (props) => {
    const {address, addressType, errors, isSameAddress, onChangeAddress, onResetError} = props;
    return (
        <div>
            <FormField className="form-group" id="registeredaddress" error={errors && errors.street}>
                <label htmlFor="street">Address</label>
                <input
                    type="text"
                    className="form-control"
                    id="street"
                    value={address && address.street ? address.street : ''}
                    onChange={(event) => {
                        errors && errors.street && onResetError(addressType, 'street');
                        onChangeAddress(event, 'street', addressType, isSameAddress)
                    }}
                />
            </FormField>

            <FormField className="form-group" id="line2" error={errors && errors.suburb}>
                <label htmlFor="suburb">Suburb</label>
                <input
                    className="form-control"
                    id="suburb"
                    onChange={(event) => {
                        errors && errors.suburb && onResetError && onResetError(addressType, 'suburb');
                        onChangeAddress(event, 'suburb', addressType, isSameAddress)
                    }}
                    type="text"
                    value={address && address.suburb ? address.suburb : ''}
                />
            </FormField>

            <FormField className="form-group" id="registeredcity" error={errors && errors.city}>
                <label htmlFor="registeredCity">City</label>
                <input
                    type="text"
                    className="form-control"
                    id="registeredCity"
                    value={address && address.city ? address.city : ''}
                    onChange={(event) => {
                        errors && errors.city && onResetError(addressType, 'city');
                        onChangeAddress(event, 'city', addressType, isSameAddress)
                    }}
                />
            </FormField>

            <FormField className="form-group" id="stateOrProvice" error={errors && errors.province}>
                <label htmlFor="stateOrProvince">State/Province</label>
                <input
                    type="text"
                    className="form-control"
                    id="stateOrProvince"
                    value={address && address.province ? address.province : ''}
                    onChange={(event) => {
                        errors && errors.province && onResetError(addressType, 'province');
                        onChangeAddress(event, 'province', addressType, isSameAddress)
                    }}
                />
            </FormField>

            <FormField className="form-group" id="postal" error={errors && errors.postalCode}>
                <label htmlFor="stateOrProvince">Zip/Postal Code</label>
                <input
                    type="text"
                    className="form-control"
                    id="registoredZipOrPostalCode"
                    value={address && address.postalCode ? address.postalCode : ''}
                    onChange={(event) => {
                        errors && errors.postalCode && onResetError(addressType, 'postalCode');
                        onChangeAddress(event, 'postalCode', addressType, isSameAddress)
                    }}
                />
            </FormField>

            <FormField className="form-group" id="country1" error={errors && errors.country}>
                <label htmlFor="stateOrProvince">Country</label>
                <input
                    type="text"
                    className="form-control"
                    id="country"
                    value={address && address.country ? address.country : ''}
                    onChange={(event) => {
                        errors && errors.country && onResetError(addressType, 'country');
                        onChangeAddress(event, 'country', addressType, isSameAddress)
                    }}
                />
            </FormField>
        </div>
    )
};

function mapStateToProps({bol}) {
    return {newBolProfile: bol && bol.newBolProfile ? bol.newBolProfile : {},}
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsPage);