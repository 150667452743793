import React, {Component} from 'react';
import _ from 'lodash';
import {browserHistory} from 'react-router';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import '../../styles/App.scss';
import '../../styles/EntityDetails.css';
import '../../styles/NewEntityDetails.css';
import '../../styles/Global.css';

import * as actions from '../../actions/entity';
import {fetchHierachy} from '../../actions/entity';
import {startCustodyApplication} from '../../actions/custody';
import {newCustodyAccountRoutes} from "../../routers/routes";
import EntityList from "../legalentity/EntityList";
import validate from "validate.js";
import FormField from "../formField/FormField";
import Popup from "../Popup";


class NewCustodyAccountPage extends Component {

    constructor(props) {
        super(props);
        this.state = {errors: {}, selectedEntity: null, isLoading: false, showHasNoApprovalMessage: false, message: ''};
        this.handleSelect = this._handleSelect.bind(this);
        this.onSubmit = this._onSubmit.bind(this);
    }

    componentWillMount() {
        this._fetchHierachy();
    }

    handleAcceptTsAndCs = () => {
        const {errors} = this.state;
        errors && errors.termsAccepted && this.handleResetError("termsAccepted");
        this.setState(prevState => ({termsAccepted: !prevState.termsAccepted}))
    };

    _fetchHierachy() {
        const {action, impersonation} = this.props;
        action.fetchHierachy(impersonation);
    }

    _handleSelect(selectedEntity) {
        this.setState({selectedEntity});
    }

    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    validateSelected = () => {
        const {selectedEntity} = this.state;
        const options = {fullMessages: false};

        const constraints = {selectedEntity: {presence: true}};
        const errors = validate({selectedEntity}, constraints, options);

        this.setState({errors});
        return _.isEmpty(errors);
    };
    handlePrevious = () => {
        const {history} = this.props;
        history && history.push('/');
    };

    handleCloseApprovalRulesErrorMessage = () => {
        this.setState({showHasNoApprovalMessage: false})
    };

    _onSubmit(e) {

        e.preventDefault();
        const {action, impersonation, history} = this.props;
        const {selectedEntity} = this.state;
        const goldtierId = selectedEntity && selectedEntity.goldTierId;
        // const cif = "100061514";
        //const cif = "100073503";

        const cif = selectedEntity && selectedEntity.cif;
        const onProceed = (success = true, message = '') => {
            if (success) {
                history.push(newCustodyAccountRoutes.accountrelations);
            } else {
                this.setState({showHasNoApprovalMessage: true, message})
            }
        };
        if (this.validateForm() && cif) {
            action.startCustodyApplication({cif}, goldtierId, impersonation, onProceed);

        }
    }

    validateForm = () => {
        const {selectedEntity, termsAccepted} = this.state;
        const options = {fullMessages: false};
        const constraints = {selectedEntity: {presence: true}};

        let errors = validate({selectedEntity, termsAccepted}, constraints, options);
        if (!termsAccepted) {
            errors = _.extend({}, errors, validate({termsAccepted: null}, {termsAccepted: {presence: true}}, options))
        }
        this.setState({errors});
        return _.isEmpty(errors);
    };

    _renderTerms() {
        const {termsAccepted, errors} = this.state;
        return (
            <FormField
                className="form-group"
                error={errors && errors.termsAccepted && ["Terms And Conditions Should Be Accepted"]}
            >
                <div className="flexRow" style={{alignItems: "flex-start", textAlign: "left"}}>
                    <div className="icon-container" onClick={this.handleAcceptTsAndCs}>
                        <i
                            className={termsAccepted ? "fa fa-check-square-o" : "fa fa-square-o"}
                            style={{color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10}}
                        />
                    </div>
                    <p style={{margin: 0}}>
                        I agree to the <a target="_blank"
                                          href="https://www.businessonline.standardbank.co.za/docs/General%20Terms%20and%20Conditions.pdf">Terms
                        and Conditions</a>;
                        <a target="_blank"
                           href="https://www.businessonline.standardbank.co.za/docs/Terms%20and%20conditions%20for%20business%20transactional%20accounts.pdf"> Product
                            Terms and Conditions</a> , including sourcing of entity details from public domain.
                    </p>
                </div>
            </FormField>
        )
    }

    _renderEntities() {
        const {entities} = this.props;
        const hasEntities = _.size(entities) > 0;

        if (!hasEntities) return null;
        const {errors, selectedEntity} = this.state;

        return (
            <FormField
                className="form-group"
                error={errors && errors.selectedEntity && ["One Should Be Selected"]}
            >
                <EntityList
                    entities={entities}
                    onSelect={this.handleSelect}
                    selectedEntity={selectedEntity}
                    style={{maxHeight: '65vh'}}
                />
            </FormField>
        );
    }

    _renderFooter() {
        return (
            <div className="flexRow" style={{justifyContent: "space-between"}}>
                <button
                    className="action-btn-primary"
                    onClick={this.handlePrevious}
                    type="button"
                >Previous
                </button>
                <button
                    className="action-btn-primary"
                    onClick={this.onSubmit}
                    type="button"
                >Next
                </button>
            </div>
        )
    }

    _renderPopUps() {
        const {showHasNoApprovalMessage, message} = this.state;
        if (showHasNoApprovalMessage) {
            return (
                <Popup onClose={this.handleCloseApprovalRulesErrorMessage}>
                    <div>
                        <div>
                            <div className="product-heading">
                                Error
                            </div>
                            <div className="title-gradient"/>
                        </div>
                        <br/>
                        <p>{message}</p>


                    </div>
                </Popup>
            )
        }
        return null;
    }


    render() {
        const {entities, loading} = this.props;
        const {selectedEntity, isLoading} = this.state;


        if (loading) return null;

        const hasEntities = _.size(entities) > 0;
        if (!hasEntities) {
            return (
                <div className="page-container">
                    <div className="page-main-section">
                        <div className="message-container">
                            <h3 className="login_title">You currently have no linked entities!</h3>
                            <p>Please ensure that you are listed as authorised person on your company resolution and
                                / or have been delegated the required access rights.</p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (

                <div className="page-container">
                    <div className="page-main-section">
                        <div className="flexRow" style={{width: "100%", alignItems: "flex-start"}}>

                            {this._renderEntities()}

                        </div>
                    </div>
                    {this._renderTerms()}
                    <div className="page-footer-section" style={{width: "80%"}}>
                        {this._renderFooter()}
                    </div>

                    {this._renderPopUps()}


                    {
                        isLoading &&
                        <div className="inner-spinner-container">
                            <ReactLoading type="spokes" color="#444"/>
                        </div>
                    }
                </div>


            )
        }
    }

}

function mapStateToProps({accounts, custody, impersonation, loading, entity}) {

    const newCustodyProfile = custody;

    return {
        accounts: accounts && accounts.selectedEntityAccounts ? accounts.selectedEntityAccounts : [],
        entities: entity && entity.entities ? entity.entities : [],
        newCustodyProfile: newCustodyProfile ? _.extend({}, newCustodyProfile) : {},
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading,
    }

}

function mapDispatchToProps(dispatch) {
    return {action: bindActionCreators({actions, fetchHierachy, startCustodyApplication}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCustodyAccountPage);