import React, {useEffect, useImperativeHandle, useState} from 'react';
import _ from 'lodash';
import {DataList} from 'primereact/components/datalist/DataList';
import {useDispatch, useSelector} from 'react-redux';
import ReactLoading from 'react-loading';
import {clearIndiviualInfo, fetchIndiviualInfo} from '../../actions/kyc';
import NewSignatory from '../managedFund/mFundNewSignatories';
import CopySignatory from '../managedFund/mFundCopySignatories';
import '../../styles/kyc.css';
import '../../styles/Global.css';
import NewSignatorySpecimenComponent from '../managedFund/mFundSignatorySpecimenComponent';
import validate from 'validate.js';
import Popup from '../Popup';
import FormField from '../formField/FormField';
import {HelpBlock, Tooltip} from 'react-bootstrap';

const ACTIONS = {
    add: 'add',
    remove: 'remove',
    copy: 'copy',
    none: 'none',
    update: 'update'
};
const signatoryActionType = {
    ADD: 'ADD'
};

export const SignatoriesComponent = React.forwardRef((props, ref) => {

    const individual = useSelector(state => state.individual && state.individual.individual);

    const systemError = useSelector(state => state.systemError);

    const [action, setAction] = useState(ACTIONS.none);
    const [addNewRP, setAddNewRP] = useState(false);
    const [copy, setCopy] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [newRP, setNewRP] = useState({});
    const [signatories, setSignatories] = useState(props.signatories);
    const [signatoryCopyAccount, setSignatoryCopyAccount] = useState(props.signatoryCopyAccount);
    const [toggleSpecimenDownloadUpload, setToggleSpecimenDownloadUpload] = useState(false);
    const dispatch = useDispatch();

    // **************************** Add account for signatory/
    const [showing, setshowing] = useState(false);

    const newSignatorySpecimenRef = React.useRef(null);

    useImperativeHandle(ref, () => {
        return {
            validateSignatories: validateSignatories
        };
    });

    useEffect(() => {
            const signatoriesList = _.find(signatories, s => (s && s.actionType === signatoryActionType.ADD));
            setToggleSpecimenDownloadUpload(!_.isEmpty(signatoriesList) && true);
        }, []
    );

    useEffect(() => {
            setNewRP(_.extend({}, newRP, individual));
        }, [individual]
    );
    useEffect(() => {
        }, [signatoryCopyAccount]
    );

    const resetNewRP = () => {
        setNewRP({});
    }

    const handleNewRPChange = ({target}, attribute, innerAttribute) => {
        let value = _.trimStart(target.value);
        if (target.type && target.type === 'number')
            value = parseInt(value, 10);
        if (innerAttribute) {
            value = _.extend({}, newRP[attribute], {[innerAttribute]: value});
        }
        setNewRP(_.extend({}, newRP, {[attribute]: value}));
    };

    const handleSelectChange = (event, attribute) => {
        setNewRP(_.extend({}, newRP, {[attribute]: event.value}));
    };

    const handleSelectAddress = (event, attribute) => {
        setNewRP(_.extend({}, newRP, {
                address: _.extend({}, newRP.address, {registeredAddress: event.value})
            })
        );
    };

    const handleCopyClick = () => {
        setAddNewRP(false);
        setCopy(true);
        setAction(ACTIONS.add);
    };

    const handleAddNewRPClick = () => {
        setAddNewRP(true);
        setCopy(false);
        setAction(ACTIONS.add);
        setNewRP(_.extend({}, newRP, {actionType: signatoryActionType.ADD}));
    };

    const removeSelectedSignatory = (selected) => {

        const filteredSignatories = _.filter(signatories, s => (s.idNumber !== selected.idNumber));
        props.handleSignatoryUpdate(filteredSignatories);
        setSignatories(filteredSignatories);
        setToggleSpecimenDownloadUpload(filteredSignatories.length > 0);

    };

    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    };

    const validateSignatories = () => {

        if (props.signatoryCopyAccount && props.signatoryCopyAccount.length > 3) {
            return true;
        }

        const options = {fullMessages: false};
        const constraints = {signatories: {presence: true}};
        const errors = validate({signatories: _.size(signatories) > 0 ? signatories : null}, constraints, options);

        let isNewSignatorySpecimenValid = true;
        if (toggleSpecimenDownloadUpload) {
            isNewSignatorySpecimenValid = newSignatorySpecimenRef.current && newSignatorySpecimenRef.current.validateDocuments();
        }

        setErrors(errors);
        return _.isEmpty(errors) && isNewSignatorySpecimenValid;
    };

    const validateForm = () => {
        const options = {fullMessages: false};

        const constraints = {
            firstName: {presence: true, length: {minimum: 1, message: 'required'}},
            lastName: {presence: true, length: {minimum: 1, message: 'required'}},
            idNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            contactNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            // emailAddress: {presence: true, email: true, length: {minimum: 1, message: 'required'}},
            signingLimits: {presence: true, numericality: {onlyInteger: true}},
            signingArrangements: {presence: true, length: {minimum: 1, message: 'required'}}
        };

        const addressConstraints = {
            registeredAddress: {presence: true, length: {minimum: 1, message: 'required'}}
        };

        const addressErrors = validate(newRP && newRP.address, addressConstraints, options);
        let errors = validate(newRP, constraints, options);

        errors = _.extend({}, errors, addressErrors);

        setErrors(errors);
        return _.isEmpty(errors);
    };

    const onNewRPSubmit = () => {
        const {handleSignatoryUpdate} = props;

        if (validateForm()) {

            handleSignatoryUpdate([...signatories, newRP]);
            setSignatories([...signatories, newRP]);

            dispatch(clearIndiviualInfo());

            _.defer(() => {
                setAddNewRP(false);
                setNewRP({});
                setToggleSpecimenDownloadUpload(true);
            });
        }
    };

    const onIndividualSearch = (onComplete) => {
        loadingStart();
        const onFinish = () => {
            loadingFinish();
            onComplete && onComplete();
        };
        const onError = () => {
            loadingFinish();
            // this.setState({isLoading: false});
            setErrors({idNumber: ['Individual not found on search.']});
        };
        dispatch(fetchIndiviualInfo(newRP.idNumber, onFinish, null, onError));
    };

    const loadingStart = () => {
        setIsLoading(true);
    };

    const loadingFinish = () => {
        setIsLoading(false);
    };

    const onCopySubmit = () => {
        setCopy(false);
        setNewRP({});
    };

    const onHandleCancel = () => {
        dispatch(clearIndiviualInfo());
        _.defer(() => {
            setAddNewRP(false);
            setErrors({});
            setNewRP({});
        });
    };

    const onHandleCancelCopy = () => {
        setCopy(false);
    };

    const renderSignatory = (signatory) => {
        if (!signatory) return null;
        return (
            <div>
                <div className="flexRow" style={{padding: 0}}>
                    <div className="col-md-8 col-sm-8 col-xs-8" style={{padding: 0}}>
                        <label>{`${signatory.firstName} ${signatory.lastName}`}</label>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-3" style={{padding: 0}}>
                        <label>{signatory.signingArrangements}</label>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1" style={{padding: 0}}>
                        <a
                            className="btn"
                            role="button"
                            id="remove-btn"
                            onClick={() => removeSelectedSignatory(signatory)}>
                            <i className="fa fa-times" aria-hidden="true"/>
                        </a>
                    </div>
                </div>
                <hr/>
            </div>);
    };

    const renderSignatories = () => {
        const hasSignatories = _.size(signatories) > 0;
        if (!hasSignatories) return null;
        return (
            <div>
                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-8">
                        <b>Name</b>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-3">
                        <b>Instruction</b>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1"></div>
                </div>
                <DataList
                    className="ui-datalist-nobullets"
                    itemTemplate={renderSignatory.bind(this)}
                    paginator={true}
                    rows={6}
                    value={signatories}
                />
            </div>
        );
    };

    const statementTooltip = (
        <Tooltip id="tooltip">
            Click here to copy signatory/ies from the other existing accounts.
        </Tooltip>
    );

    return (
        <div className="flexColumn">
            <div className="card-container-form" style={{minWidth: '22em'}}>
                <div className="section-title">
                    Transactional Signatories
                    <br/>
                    <HelpBlock>
                        <small>
                            Signatories can sign checks and withdraw or deposit funds
                            {/* <br/> */}
                            against the account.
                        </small>
                    </HelpBlock>
                </div>

                {renderSignatories()}


                {props.signatoryCopyAccount &&
                    <div>
                        <div className='row' style={{margin: '0'}}>Copy From Account:</div>
                        <div className='row' style={{margin: '0'}}>
                            <div style={{borderBottom: '1px solid #c1c1c1', padding: '5px'}}>
                                <b>{signatoryCopyAccount}</b>
                                <a class="btn" role="button" id="remove-btn"
                                   style={{float: 'right', position: 'relative', top: ' -0.5em'}}
                                   onClick={() => props.handleSignatoryCopy(null)}>
                                    <i class="fa fa-times" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                }

                {/* Account list for signatory */}

                {
                    !addNewRP &&
                    <FormField
                        className="form-group"
                        error={errors && errors.signatories ? ['At least One Signatory Should Be Added'] : ''}>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            {/* <a className="btn btn-secondary addButton"
                               onClick={() => {
                                   errors && errors.signatories && resetError('signatories');
                                   handleAddNewRPClick();
                               }}
                            >Add Signatory</a> */}
                            
                            {/* <a className="btn btn-secondary addButton"
                               onClick={handleCopyClick}>Copy signatory&nbsp;
                                <OverlayTrigger placement="right" overlay={statementTooltip}>
                                    <Glyphicon glyph="question-sign"/>
                                </OverlayTrigger>
                            </a> */}

                            {!props.signatoryCopyAccount && <a className="btn btn-secondary addButton"
                                                               onClick={() => setshowing(!showing)}>Copy signatory&nbsp;
                            </a>}


                        </div>
                        {
                            toggleSpecimenDownloadUpload &&
                            <NewSignatorySpecimenComponent
                                ref={newSignatorySpecimenRef}
                                entityDocs={props.entityDocs}
                                handleDocUpdate={props.handleDocUpdate}
                            />
                        }
                        {errors && errors.signatories && <br/>}
                    </FormField>
                }


                {showing &&
                    <div className='row' style={{margin: 0}}>
                        <FormField className={"form-group"} error={errors && errors.signatoryCopyAccount}>
                            <label>Account Number <span className="text-danger">*</span></label>
                            <input className="form-control" id="accNumber" value={signatoryCopyAccount}
                                   pattern="^[0-9\b]+$"
                                   onChange={(e) => setSignatoryCopyAccount(e.target.value)} type="number"/>
                        </FormField>

                        <button className="action-btn-primary btn-sm"
                                style={{float: 'right', width: '2em'}}
                                onClick={() => {
                                    props.handleSignatoryCopy(signatoryCopyAccount);
                                    setshowing(false)
                                }}
                                type="button">Add
                        </button>
                    </div>
                }


                {
                    addNewRP &&
                    <Popup onClose={onHandleCancel}>
                        <NewSignatory
                            cancel={onHandleCancel}
                            errors={errors}
                            handleNewRPChange={handleNewRPChange}
                            handleSelectChange={handleSelectChange}
                            handleSelectAddress={handleSelectAddress}
                            newRP={newRP}
                            onNewRPSubmit={onNewRPSubmit}
                            onIndividualSearch={onIndividualSearch}
                            onResetError={resetError}
                            onResetNewRP={resetNewRP}
                        />
                        {
                            isLoading &&
                            <div className="inner-spinner-container">
                                <ReactLoading type="spokes" color="#444"/>
                            </div>
                        }
                    </Popup>
                }

                {
                    copy &&
                    <Popup onClose={onHandleCancelCopy}>
                        <CopySignatory
                            onCopySubmit={onCopySubmit}
                            onLoadingFinish={loadingFinish}
                            onLoadingStart={loadingStart}
                        />
                    </Popup>

                }
                {
                    isLoading &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
            </div>
        </div>
    );
});


export default SignatoriesComponent;
