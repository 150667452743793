import React from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {DataList} from 'primereact/components/datalist/DataList';
import validate from 'validate.js'

import "../../styles/ConfirmApplication.css";
import "../../styles/Global.css";
import * as actions from '../../actions/kyc';
import FormField from "../formField/FormField";
import {newAccountOpeningRoutes} from "../../routers/routes";

class SSICompletionPage extends React.Component {
    state = {
        form: {emailAddress: ''}, errors: {}
    };


    confirmDetails = (accountDetails, additionalProducts) => {
        const {actions, applicationId, history} = this.props;
        const onProceed = () => history.push(newAccountOpeningRoutes.kyc);
        actions.confirmAccount(accountDetails, additionalProducts, applicationId, onProceed);
    };


    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    validateForm = () => {
        const {form} = this.state;
        const options = {fullMessages: false};
        const constraints = {emailAddress: {presence: true, email: true, length: {minimum: 1, message: 'required'}}};
        const errors = validate(form, constraints, options);
        this.setState({errors});
        return _.isEmpty(errors);
    };

    goHome = (event) => {
        event.preventDefault();
        const {history} = this.props;
        history && history.push("/");
    };

    handleChange = (e) => {
        const {form} = this.state;
        const emailAddress = _.trimStart(e.target.value);
        this.setState({form: _.extend({}, form, {emailAddress})});
    };

    renderList(sc) {
        if (!sc) return null;
        return (
            <div className="row">
                <div className="col-md-12">
                    <label>{sc.approvalMessage}</label>
                </div>
                <hr/>
            </div>
        );
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={this.goHome}
                    type="button"
                >Home
                </button>
            </div>
        )
    }

    render() {
        const {authorisers} = this.props;
        const {errors, form} = this.state;
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="flexColumn">
                        <div className="card-container-form">
                            <div className="section-title">Global-Market Account Confirmation</div>
                            <hr/>
                            <div className="input-group greyText fontSize">
                                <p>Thank you!</p> <br/>
                                <p>You have completed capturing your money market account.</p> <br/>
                                <p><a>Click here</a> to download your application.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
            </div>
        )
    }
}

function mapStateToProps({application, loading}, ownProps) {
    return {
        applicationId: application && application.application ? application.application.id : '',
        application: application && application.application ? application.application : {},
        loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(SSICompletionPage);