import React, {useState} from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import validate from 'validate.js';
import "../../../styles/ConfirmApplication.css";
import "../../../styles/Global.css";
import FormField from "../../formField/FormField";
import {managedFundRoutes} from "../../../routers/routes";
import ButtonsComponent from "../../ButtonsComponent";
import Select from "react-select";
import countries from "../../../data/countries";
import {saveCustodyManagedFund} from "../../../actions/custodyManagedFund";
import {saveCashCustodyManagedFund} from "../../../actions/cashAndCustodyManagedFund";

const labelClassList = "col-xs-12 col-sm-4 col-md-4";
const inputClassList = "col-xs-12 col-sm-8 col-md-8";

export const AddressDetailsPage = (props) => {

    const custodyRequest = useSelector(state => state.cashCustodyManagedFund && state.cashCustodyManagedFund.cashCustodyManagedFundRequest);
    const appId = useSelector(state => state.cashCustodyManagedFund && state.cashCustodyManagedFund.id);
    const [beneficialAddress, setBeneficialAddress] = useState(custodyRequest.beneficialAddress);
    const [bndAddress, setBndAddress] = useState(custodyRequest.bndAddress);
    const [sameAsBnd, setSameAsBnd] = useState(false);
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();


    const handleBNDAddressSame = (isSame = false) => {

        setSameAsBnd(isSame);
        if (isSame) {
            setBndAddress({...beneficialAddress});
        }
    };

    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    };

    const saveDetails = (event) => {
        event.preventDefault();
        if(validateForm()){
        const {history} = props;
        const onSuccess = () => history.push(managedFundRoutes.cashAndCustodyContactDetails);
        custodyRequest.beneficialAddress = beneficialAddress;
        custodyRequest.bndAddress = bndAddress;
        dispatch(saveCashCustodyManagedFund(appId, custodyRequest, 'UserTask_AddressDetails', onSuccess));
        }
    };
    const validateForm = () => {
        const options = {fullMessages: false};
        const constraints = {
            streetName: {presence: true, length: {minimum: 1, message: 'required'}},
            // houseName: {presence: true, length: {minimum: 1, message: 'required'}}, 
            // roomNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            suburb: {presence: true, length: {minimum: 1, message: 'required'}},
            postalCode: {presence: true, length: {minimum: 1, message: 'required'}},
            city: {presence: true, length: {minimum: 1, message: 'required'}},
            country: {presence: true, length: {minimum: 1, message: 'required'}},
            state: {presence: true, length: {minimum: 1, message: 'required'}},
        };
        const bndConstraints = {
            streetName: {presence: true, length: {minimum: 1, message: 'required'}},
            // houseName: {presence: true, length: {minimum: 1, message: 'required'}},
            // roomNumber: {presence: true, length: {minimum: 1, message: 'required'}},      
            suburb: {presence: true, length: {minimum: 1, message: 'required'}},
            postalCode: {presence: true, length: {minimum: 1, message: 'required'}},
            city: {presence: true, length: {minimum: 1, message: 'required'}},
            country: {presence: true, length: {minimum: 1, message: 'required'}},
            state: {presence: true, length: {minimum: 1, message: 'required'}}
        }


            const beneficialErrors = validate(beneficialAddress,constraints, options);
            const bndErrors = validate(bndAddress, bndConstraints, options);

            const errors = _.extend({}, {beneficialErrors}, {bndErrors});
            setErrors(errors);
            return _.isEmpty(beneficialErrors) && _.isEmpty(bndErrors);

       
        // setErrors(errors);
        // return _.isEmpty(errors);
    };

    const saveAndContinue = (event) => {

        event.preventDefault();
        const {history} = props;
        const onSuccess = () => history.push('/saveapplication');
        custodyRequest.beneficialAddress = beneficialAddress;
        custodyRequest.bndAddress = bndAddress;
        dispatch(saveCashCustodyManagedFund(appId, custodyRequest, 'saveContinue', onSuccess));
    };

    return (
        <div className="page-container">
            <div className="page-main-section">

                <div className="col-md-12">
                    <div className="card-container-form">
                        <div className="section-title">
                            <span>Address Details Beneficial Owner</span>
                        </div>
                        <div className="form-horizontal">
                            <div className="row" style={{ margin: '0' }}>
                                <div className="col-md-6 form-group">
                                    <FormField className="" id="streetName" error={errors && errors.beneficialErrors && errors.beneficialErrors.streetName}>
                                        <label className={labelClassList} htmlFor="streetName">House Building Number And Street Name / Number</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="streetName"
                                                onChange={(event) => {
                                                    const inputValue =
                                                    event.target.value;
                                                  // Remove non-alphanumeric characters
                                                   event.target.value =
                                                    inputValue.replace(
                                                      /[^A-Za-z0-9 ]/g,
                                                      ""
                                                    );
                                                    setBeneficialAddress({
                                                        ...beneficialAddress,
                                                        streetName: event.target.value
                                                    });
                                                }}
                                                type="text"
                                                value={beneficialAddress.streetName ? beneficialAddress.streetName : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormField className="" id="houseName" error={errors && errors.beneficialErrors && errors.beneficialErrors.houseName}>
                                        <label className={labelClassList} htmlFor="physicalAddressLine1">House/Building Name</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="houseName"
                                                onChange={(event) => {
                                                    const inputValue =
                                                    event.target.value;
                                                  // Remove non-alphanumeric characters
                                                   event.target.value =
                                                    inputValue.replace(
                                                      /[^A-Za-z0-9 ]/g,
                                                      ""
                                                    );
                                                    setBeneficialAddress({
                                                        ...beneficialAddress,
                                                        houseName: event.target.value
                                                    });
                                                }}
                                                type="text"
                                                value={beneficialAddress.houseName ? beneficialAddress.houseName : ""}

                                            />
                                        </div>
                                    </FormField>
                                </div>
                            </div>
                            <div className="row" style={{ margin: '0' }}>
                                <div className="col-md-6 form-group">
                                    <FormField className="" id="room" error={errors && errors.beneficialErrors && errors.beneficialErrors.roomNumber}>
                                        <label className={labelClassList}
                                            htmlFor="roomNumber">Room/Floor/Suite</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="room"
                                                onChange={(event) => {
                                                    const inputValue =
                                                    event.target.value;
                                                  // Remove non-alphanumeric characters
                                                   event.target.value =
                                                    inputValue.replace(
                                                      /[^A-Za-z0-9 ]/g,
                                                      ""
                                                    );
                                                    setBeneficialAddress({
                                                        ...beneficialAddress,
                                                        roomNumber: event.target.value
                                                    });

                                                }}
                                                type="text"
                                                value={beneficialAddress.roomNumber ? beneficialAddress.roomNumber : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormField className="" id="suburb"  error={errors && errors.beneficialErrors && errors.beneficialErrors.suburb}>
                                        <label className={labelClassList} htmlFor="suburb">Suburb</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="suburb"
                                                onChange={(event) => {
                                                    const inputValue = event.target.value;
                                                    event.target.value = inputValue.replace(/[^A-Za-z]+/g, '')
                                                    setBeneficialAddress({
                                                        ...beneficialAddress,
                                                        suburb: event.target.value
                                                    });
                                                }}
                                                type="text"
                                                value={beneficialAddress.suburb ? beneficialAddress.suburb : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                            </div>
                            <div className="row" style={{ margin: '0' }}>
                                <div className="col-md-6 form-group">
                                    <FormField className="" id="postalCode" error={errors && errors.beneficialErrors && errors.beneficialErrors.postalCode}>
                                        <label className={labelClassList} htmlFor="postalCode">Postal/ Zip Code</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="postalCode"
                                                onChange={(event) => {
                                                    setBeneficialAddress({
                                                        ...beneficialAddress,
                                                        postalCode: event.target.value
                                                    });
                                                }}
                                                type="number"
                                                maxLength="4"
                                                max="9999"
                                                value={beneficialAddress.postalCode ? beneficialAddress.postalCode : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormField className="" id="city" error={errors && errors.beneficialErrors && errors.beneficialErrors.city}>
                                        <label className={labelClassList} htmlFor="city">City / Town</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="city"
                                                onChange={(event) => {
                                                    const inputValue = event.target.value;
                                                    event.target.value = inputValue.replace(/[^A-Za-z]+/g, '')
                                                    setBeneficialAddress({...beneficialAddress, city: event.target.value});
                                                }}
                                                type="text"
                                                value={beneficialAddress.city ? beneficialAddress.city : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                            </div>
                            <div className="row" style={{ margin: '0' }}>
                                <div className="col-md-6 form-group">
                                    <FormField className="" id="country" error={errors && errors.beneficialErrors && errors.beneficialErrors.country}>
                                        <label className={labelClassList} htmlFor="country">Country</label>
                                        <div className={inputClassList}>
                                            <Select
                                                name="tittle"
                                                onChange={(event) => {
                                                    setBeneficialAddress({...beneficialAddress, country: event.value});
                                                }}
                                                options={countries}
                                                value={beneficialAddress && beneficialAddress.country}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormField className="" id="province" error={errors && errors.beneficialErrors && errors.beneficialErrors.state}>
                                        <label className={labelClassList} htmlFor="province">State/ Province</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="province"
                                                onChange={(event) => {
                                                    const inputValue = event.target.value;
                                                    event.target.value = inputValue.replace(/[^A-Za-z]+/g, '')
                                                    setBeneficialAddress({...beneficialAddress, state: event.target.value});
                                                }}
                                                type="text"
                                                value={beneficialAddress.state ? beneficialAddress.state : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <div
                        className="inline-container">
                        <div className="icon-container"
                             onClick={(event) => {
                                 handleBNDAddressSame(!sameAsBnd);
                             }}>
                            <i
                                className={(
                                    (sameAsBnd) ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                style={{fontSize: 20}}
                                id="beneficiarySameAddress"

                            />
                        </div>
                        BND Address Same As Beneficial Owner Address
                    </div>
                </div>

                <div className="col-md-12">
                    <div className="card-container-form">
                        <div className="section-title">
                            <span>BND Address Details </span>
                        </div>
                        <div className="form-horizontal">
                            <div className="row" style={{ margin: '0' }}>
                                <div className="col-md-6 form-group">
                                    <FormField id="streetName" error={errors && errors.bndErrors && errors.bndErrors.streetName}>
                                        <label className={labelClassList} htmlFor="streetName">House Building Number And Street Name / Number</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="streetName"
                                                onChange={(event) => {
                                                    const inputValue =
                                                    event.target.value;
                                                  // Remove non-alphanumeric characters
                                                   event.target.value =
                                                    inputValue.replace(
                                                      /[^A-Za-z0-9 ]/g,
                                                      ""
                                                    );
                                                    setBndAddress({...bndAddress, streetName: event.target.value});
                                                }}
                                                type="text"
                                                disabled={sameAsBnd}
                                                value={bndAddress.streetName ? bndAddress.streetName : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormField id="houseName" error={errors && errors.bndErrors && errors.bndErrors.houseName}>
                                        <label className={labelClassList} htmlFor="physicalAddressLine1">House/Building Name</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="houseName"
                                                onChange={(event) => {
                                                    const inputValue =
                                                    event.target.value;
                                                  // Remove non-alphanumeric characters
                                                   event.target.value =
                                                    inputValue.replace(
                                                      /[^A-Za-z0-9 ]/g,
                                                      ""
                                                    );
                                                    setBndAddress({...bndAddress, houseName: event.target.value});
                                                }}
                                                type="text"
                                                disabled={sameAsBnd}
                                                value={bndAddress.houseName ? bndAddress.houseName : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                            </div>
                            <div className="row" style={{ margin: '0' }}>
                                <div className="col-md-6 form-group">
                                    <FormField id="room" error={errors && errors.bndErrors && errors.bndErrors.roomNumber}>
                                        <label className={labelClassList}
                                            htmlFor="physicalAddressLine1">Room/Floor/Suite</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="room"
                                                onChange={(event) => {
                                                    const inputValue =
                                                    event.target.value;
                                                  // Remove non-alphanumeric characters
                                                   event.target.value =
                                                    inputValue.replace(
                                                      /[^A-Za-z0-9 ]/g,
                                                      ""
                                                    );
                                                    setBndAddress({...bndAddress, roomNumber: event.target.value});
                                                }}
                                                type="text"
                                                disabled={sameAsBnd}
                                                value={bndAddress.roomNumber ? bndAddress.roomNumber : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormField id="suburb" error={errors && errors.bndErrors && errors.bndErrors.suburb}>
                                        <label className={labelClassList} htmlFor="physicalAddressLine1">Suburb</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="suburb"
                                                onChange={(event) => {
                                                    const inputValue = event.target.value;
                                                    event.target.value = inputValue.replace(/[^A-Za-z]+/g, '')
                                                    setBndAddress({...bndAddress, suburb: event.target.value});
                                                }}
                                                type="text"
                                                disabled={sameAsBnd}
                                                value={bndAddress.suburb ? bndAddress.suburb : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                            </div>
                            <div className="row" style={{ margin: '0' }}>
                                <div className="col-md-6 form-group">
                                    <FormField id="postalCode" error={errors && errors.bndErrors && errors.bndErrors.postalCode}>
                                        <label className={labelClassList} htmlFor="postalCode">Postal/ Zip Code</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="postalCode"
                                                onChange={(event) => {
                                                    setBndAddress({...bndAddress, postalCode: event.target.value});
                                                }}
                                                type="number"
                                                maxLength="4"
                                                max="9999"
                                                disabled={sameAsBnd}
                                                value={bndAddress.postalCode ? bndAddress.postalCode : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormField id="city" error={errors && errors.bndErrors && errors.bndErrors.city}>
                                        <label className={labelClassList} htmlFor="city">City / Town</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="city"
                                                onChange={(event) => {
                                                    const inputValue = event.target.value;
                                                    event.target.value = inputValue.replace(/[^A-Za-z]+/g, '');
                                                    setBndAddress({...bndAddress, city: event.target.value});
                                                }}
                                                type="text"
                                                disabled={sameAsBnd}
                                                value={bndAddress.city ? bndAddress.city : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                            </div>
                            <div className="row" style={{ margin: '0' }}>
                                <div className="col-md-6 form-group">
                                    <FormField id="country" error={errors && errors.bndErrors && errors.bndErrors.country}>
                                        <label className={labelClassList} htmlFor="country">Country</label>
                                        <div className={inputClassList}>
                                            <Select
                                                name="tittle"
                                                onChange={(event) => {
                                                    setBndAddress({...bndAddress, country: event.value});
                                                }}
                                                options={countries}
                                                disabled={sameAsBnd}
                                                value={bndAddress && bndAddress.country}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                    <FormField id="province" error={errors && errors.bndErrors && errors.bndErrors.state}>
                                        <label className={labelClassList} htmlFor="province">State/ Province</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="province"
                                                onChange={(event) => {
                                                    const inputValue = event.target.value;
                                                    event.target.value = inputValue.replace(/[^A-Za-z]+/g, '');
                                                    setBndAddress({...bndAddress, state: event.target.value});
                                                }}
                                                type="text"
                                                disabled={sameAsBnd}
                                                value={bndAddress.state ? bndAddress.state : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-footer-section" style={{width: '80%'}}>
                <ButtonsComponent
                    history={props.history}
                    onNext={saveDetails}
                    onSaveAndContinue={saveAndContinue}
                    prevPage={managedFundRoutes.cashAndCustodyBeneficialOwnerDetails}
                />
            </div>
        </div>
    );
};
export default (AddressDetailsPage);
