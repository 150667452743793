import _ from 'lodash';
import * as types from '../actions/actionTypes';
import initialState from './initialState';
import {menuRoutes} from '../routers/routes';

export default function applicationReducer(state = initialState.application, action) {
    switch (action.type) {
        case types.PRODUCT_SELECTED:
            return {...state, application: action.application};

        case types.APPLICATION_UPDATED:
            return {...state, application: action.application};

        case types.ENTITY_SELECTED:
            return {...state, application: action.application};

        case types.ENTITY_UPDATE_SUCCESS:
            return {...state, application: action.application};

        case types.ACCOUNT_CONFIRM_SUCCESS:
            return {...state, application: action.application};

        case types.DOC_VERIFY_SUCCESS:
            return {...state, application: action.application};

        case types.DOC_UPLOAD_SUCCESS:
            return {...state, application: action.application};

        case types.DOC_UPDATE_SUCCESS: {
            const application = {...state.application};
            let index = -1;
            application.legalEntity.supportingDocuments.some((docu, i) => {
                if (docu.documentType === action.doc.documentType) {
                    index = i;
                    return true;
                }
            });
            application.legalEntity.supportingDocuments[index].description = action.doc.description;
            return {...state, application: application};
        }

        case types.RELATED_PARTY_SUCCESS:
            return {...state, application: action.application};

        case types.ADD_SIGNATORY_SUCCESS:
            return {...state, application: action.application};

        case types.REMOVE_SIGNATORY_SUCCESS:
            return {...state, application: action.application};

        case types.APP_CONFIRM_SUCCESS: {
            return {...state, application: action.application};
        }
        case types.VIEW_DASH_BOARD: {
            action.history.push('/dashboard');
            return action.state;
        }

        case types.VIEW_MENU_OPTION: {
            action.history.push(action.path);
            switch (action.path) {
                case menuRoutes.maintenance:
                    return state;
                case menuRoutes.newEntity:
                    return state;
                case menuRoutes.reporting:
                    return state;
                default:
                    return state;
            }
        }

        case types.LOAD_SELECTED_APPLICATION_SUCCESS:
            return {...state, application: action.application};

        case types.GO_PREVIOUS:
            action.history.push(action.page);
            return state;

        default:
            return state;
    }
}