import  React, { createRef} from 'react';
import _ from 'lodash'
import validate from "validate.js";
import Select from 'react-select';
import SearchComponent from "../SearchComponent";
import FormField from "../formField/FormField";
import Toggler from "../Toggler";
import ReactLoading from "react-loading";
import FileUpload from "../FileUpload";
import specimenTemplate from "../../resources/New_Signatory_specimens.pdf";
import ListGroup from "react-bootstrap/lib/ListGroup";
import ListGroupItem from "react-bootstrap/lib/ListGroupItem";
import Row from "react-bootstrap/lib/Row";
import SignatureUpload from '../SignatureUpload';

const DOC_TYPES = {
    signatorySignature: 'Signatory signature', 
 };
 
class AddNewSignatoryForm extends React.Component {
    constructor(props) {
        super(props);
        this.signatureUploadRef = createRef();
    }
    
    state = {
        errors: {},
        isRSACitizen: true,
        isAddingOrSearching: false,
        isSearchComplete: false,
        newSignatory: {},
        selectedAccounts: [],
        selectAll: false,
        phoneOptions :[],
        addressOptions :[],
        hasPhoneOptions: true,
        hasAddressOptions: true,
        filteredAccounts: [], // Initialize filteredAccounts array
        searchFilter: '', // Initialize the search filter
        
    };

    componentDidMount() {
        // Initialize filteredAccounts with all accounts
        this.setState({ filteredAccounts: this.filterAccounts('') });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.candidate) {
            const {newSignatory} = this.state;
            let updatedSignatory = _.extend({}, nextProps.candidate, newSignatory)
            this.setState({newSignatory: _.extend({}, updatedSignatory, {initialsOrFirstNames: nextProps.candidate.firstName})});
    
            
        }
    }

    onHandleSearch = () => {
        const {newSignatory} = this.state;
        const {onIndividualSearch} = this.props;
        this.setState({isAddingOrSearching: true});
        const onComplete = (success, individual) => {
            this.setState({isSearchComplete: false, isAddingOrSearching: false, isSearchError: false,
            newSignatory: {
                idOrPassportNumber: individual.idNumber,
                initialsOrFirstNames: individual.firstName,
                lastName: individual.lastName,
                registeredAddress : individual.addresses,
                contactNumber : individual.telephones            
            }});
            this.setState({ phoneOptions : _.map(individual.telephones, (t) => ({
                value: t.msisdn,
                label: t.msisdn,
            }))});
            this.setState({ addressOptions : _.map(individual.addresses, (t) => ({
                value: t.line1,
                label: t.line1,
            }))});
            this.setState({ hasPhoneOptions : _.size(this.state.phoneOptions) > 0});
            this.setState({ hasAddressOptions : _.size(this.state.addressOptions) > 0});
        }       
        const onError = () => this.setState({isSearchComplete: true, isAddingOrSearching:false, isSearchError:true, errors: {idOrPassportNumber:['ID number not found, check ID number']}});
        onIndividualSearch && onIndividualSearch(newSignatory && newSignatory.idOrPassportNumber, onComplete, onError);
                
    };
    

    handleChangeSACitizen = (isRSACitizen) => {
        this.setState({isRSACitizen})
        this.setState({newSignatory:{}})

    };

    handleOnChange = ({target}, attribute, innerAttribute) => {
        const {newSignatory} = this.state;
        let value = _.trimStart(target.value);
     
    if(!value){
        this.setState({
            isSearchComplete: false, 
            isAddingOrSearching: false, 
            isSearchError: false,  
            phoneOptions :[],
            addressOptions :[]
        });
        this.setState({
            newSignatory: {},
            selectedAccounts : []
        });
        this.setState({
            hasPhoneOptions: true,
            hasAddressOptions: true
        });
      
        this.onHandleRemoveDocument();
    }
        if (innerAttribute) {
            value = _.extend({}, newSignatory[attribute], {[innerAttribute]: value});
        }
        this.setState({newSignatory: _.extend({}, newSignatory, {[attribute]: value})});
    };

     handleSelectChange = (event, attribute) => {
        const {newSignatory} = this.state;
        this.setState({newSignatory:_.extend({}, newSignatory, {[attribute]: event.value})});
    };



    onHandleUpload = (base64, inProgress, onComplete) => {
            const {newSignatory, errors} = this.state;
            const {onUploadDocument} = this.props;
            errors && errors.signatureImageB64;
            const trimmedBase64 = base64.split(",")[1];

            const onSuccess = (signatureImageB64, data) => {
                const updatedSignatory = _.extend({}, newSignatory, {signatureImageB64: trimmedBase64});
                this.setState({newSignatory: updatedSignatory});
            };
            onUploadDocument && onUploadDocument(base64, inProgress, onComplete, onSuccess);

        };



    onHandleRemoveDocument = () => {
        const {newSignatory} = this.state;
        const updatedSignatory = _.extend({}, newSignatory, {signatureImageB64: null});
        this.setState({newSignatory: updatedSignatory});
    };

    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors});
    };

    validateForm = () => {
        const {newSignatory} = this.state;
        const {selectedAccounts} = this.state;
        const options = {fullMessages: false};

        validate.validators.isValidAddress = function (value) {
            if ((typeof value === 'object' && value.name) || (typeof value === 'string' && value.trim().length > 0)) {
                return null; // Return null if the validation passes
            }
            return "Can't Be Blank";
            // return 'must be a valid address';
        };
        
        validate.validators.isValidContactNumber = function (value) {
            if ((typeof value === 'object' && value.value) || (typeof value === 'string' && value.trim().length > 0)) {
                return null; // Return null if the validation passes
            }
            return "Can't Be Blank";
            // return 'must be a valid contact number';
        };

        const constraints = {
            idOrPassportNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            initialsOrFirstNames: {presence: true, length: {minimum: 1, message: 'required'}},
            lastName: {presence: true, length: {minimum: 1, message: 'required'}},
            instruction: {presence: true, length: {minimum: 1, message: 'required'}},
            signatureImageB64: {presence: true},
            registeredAddress: { presence: true, isValidAddress: true},
            contactNumber: { presence: true, isValidContactNumber: true}
            // emailAddress : {presence: true, length: {minimum: 1, message: 'required'}}
            //initial : {presence:true , length :{minimum:1 , message: 'required'}},
        };



        let errors = validate(newSignatory, constraints, options, {selectedAccounts} );

        if (selectedAccounts.length < 1) {
            if (!errors) {
                errors = {};
            }
            errors.selectedAccounts = ['At least 1 account required.'];
        }

        this.setState({errors});
        return _.isEmpty(errors);
    };

    resetSignatureUpload = () => {
        if (this.signatureUploadRef.current) {
          this.signatureUploadRef.current.resetState();
        }
      };

    onHandleSubmit = (e) => {
        e.preventDefault();
        const {selectedAccounts, newSignatory} = this.state;
        const {accounts} = this.props;
        if (this.validateForm()) {
            const submittingAccounts = selectedAccounts.map(selected => accounts[selected]);
            this.props.saveSignatory(this.state.newSignatory, submittingAccounts);
            this.setState({
                newSignatory: {},
                selectedAccounts : []
            });
             this.resetSignatureUpload();
             this.onHandleRemoveDocument.bind(this);
        }
    };

    _renderHeader() {
        return (
            <div style={{padding:'10px'}}>
                <div className="product-heading">
                    New Signatory
                </div>
                <div className="title-gradient"/>
            </div>
        )
    }

    _renderFooter() {
        return (
            <div className="action-button-container" style={{width: "100%", justifyContent: 'end'}}>
                <button
                    className="action-btn-primary btn-sm"
                    onClick={this.onHandleSubmit}
                    type="button">Add to List
                </button>
            </div>
        )
    }

    setSelectedAccount = (accountIndex) => {
        const {selectedAccounts} = this.state;
        if (selectedAccounts.includes(accountIndex)) {
            _.remove(selectedAccounts, function (arr) {
                return arr === accountIndex;
            });
        } else {
            // setAccountFilter(...accountFilter, signatories[change].account.accountNumber);
            selectedAccounts.push(accountIndex);
        }
        this.setState({selectedAccounts});
    }

    selectAllAccounts = () => {
        const {selectAll} = this.state;
        const {accounts} = this.props;
        const update = [];
        if (!selectAll) {
            accounts.forEach((account, i) =>
                update.push(i)
            );
        }
        this.setState({selectAll: !selectAll, selectedAccounts: update});
    };

    handleAccountSearch = (event) => {
        const searchValue = event.target.value.toLowerCase();

        // Filter accounts based on the search filter
        const filteredAccounts = this.filterAccounts(searchValue);

        this.setState({
            filteredAccounts: filteredAccounts,
            searchFilter: searchValue, // Update the search filter in the state
        });
    }

    // Filter accounts based on the search filter
    filterAccounts = (searchFilter) => {
        const { accounts } = this.props;
        return accounts.filter((account) => {
            return (
                account.name.toLowerCase().includes(searchFilter) ||
                account.accountNumber.toLowerCase().includes(searchFilter)
            );
        });
    };
    


    

    
    _renderForm() {
        const {isRSACitizen, isSearchComplete, newSignatory, errors, isSearchError} = this.state;
        const {accounts} = this.props;
        // const shouldDisableInput = (isSearchComplete && !isSearchError);
        const shouldDisableInput = !(!isRSACitizen || (isSearchComplete && !isSearchError));
        if (!newSignatory.address) {
            newSignatory.address = {}
        }
        return (
            <div className="modal-body" style={{padding:'0px'}}>
               
                <div className="row" style={{margin:'0'}}>
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12" style={{ padding: '0px' }}>
                        <div className="row" style={{ margin: '10px 0px' }}>
                            <div className="col-lg-8 col-md-8 col-xs-12 col-sm-12">
                                <Toggler
                                    label="South African Citizen / Resident?"
                                    isOn={isRSACitizen}
                                    onToggle={this.handleChangeSACitizen}
                                />
                            </div>
                        </div>
                        <div className="row" style={{ margin: '10px 0px' }}>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{padding:'0px'}}>
                                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                    <FormField className={"form-group"} error={errors && errors.idOrPassportNumber}>
                                        {
                                            !shouldDisableInput ?
                                                <div>
                                                    <label>ID/ Passport Number</label>
                                                    <input
                                                        className="form-control"
                                                        id="idOrPassportNumber"
                                                        onChange={(event) => {
                                                            errors && errors.idOrPassportNumber && this.handleResetError && this.handleResetError('idOrPassportNumber');
                                                            this.handleOnChange(event, 'idOrPassportNumber')
                                                        }}
                                                        type="text"
                                                        value={newSignatory.idOrPassportNumber || ''}
                                                    />
                                                </div>

                                                :
                                                <SearchComponent
                                                    label="ID Number"
                                                    onChange={(event) => {
                                                        errors && errors.idOrPassportNumber && this.handleResetError && this.handleResetError('idOrPassportNumber');
                                                        this.handleOnChange(event, 'idOrPassportNumber')
                                                    }}
                                                    onSearch={this.onHandleSearch}
                                                    searchValue={newSignatory.idOrPassportNumber || ''}
                                                />
                                        }
                                    </FormField>
                                </div>
                                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                    <FormField className={"form-group"} id="name" error={errors && errors.initialsOrFirstNames}>
                                        <label>First Name</label>
                                        <input
                                            className="form-control"
                                            disabled={shouldDisableInput}
                                            id="initialsOrFirstNames"
                                            value={newSignatory.initialsOrFirstNames || ''}
                                            onChange={(event) => {
                                                errors && errors.initialsOrFirstNames && this.handleResetError && this.handleResetError('initialsOrFirstNames');
                                                this.handleOnChange(event, 'initialsOrFirstNames')
                                            }}
                                            type="text"
                                        />
                                    </FormField>
                                </div>  
                                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                    <FormField className={"form-group"} id="lastName" error={errors && errors.lastName}>
                                        <label>Surname</label>
                                        <input
                                            className="form-control"
                                            disabled={shouldDisableInput}
                                            id="surname"
                                            onChange={(event) => {
                                                errors && errors.lastName && this.handleResetError && this.handleResetError('lastName');
                                                this.handleOnChange(event, 'lastName')
                                            }}
                                            type="text"
                                            value={newSignatory.lastName || ''}
                                        />
                                    </FormField>
                                </div>

                                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                        {(this.state.hasAddressOptions && shouldDisableInput) ? (
                                            <FormField  id="Address" className={"form-group"}  error={errors && errors.registeredAddress}>
                                                <label>Address</label>
                                                <Select
                                                    menuContainerStyle={{ zIndex: 999 }}
                                                    // disabled={shouldDisableInput}
                                                    name="registeredAddress"
                                                    onChange={(event) => this.handleSelectChange(event, "registeredAddress")}
                                                    options={this.state.addressOptions}
                                                    value={ newSignatory.registeredAddress || ""}>
                                                    <option value="">--SELECT--</option>
                                                </Select>
                                            </FormField>
                                        ) : (
                                            <FormField className={"form-group"} id="Address"  error={errors && errors.registeredAddress}>
                                                <label>Address</label>
                                                <input className="form-control"
                                                 // disabled={shouldDisableInput}
                                                    id="registeredAddress"
                                                    onChange={(event) => { this.handleOnChange(event, 'registeredAddress') }}
                                                    type="text"
                                                    value={newSignatory.registeredAddress || ""}
                                                />
                                            </FormField>
                                        )}
                                    
                                </div>
                                
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ padding: '0px' }}>
                                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                    {(this.state.hasAddressOptions && shouldDisableInput) ? (
                                        <FormField className={"form-group"} id="ContactNumber" error={errors && errors.contactNumber}>
                                            <label>Contact Number</label>
                                            <Select
                                                menuContainerStyle={{ zIndex: 999 }}
                                                // disabled={shouldDisableInput}
                                                name="contactNumber"
                                                onChange={(event) =>
                                                    this.handleSelectChange(event, "contactNumber")
                                                }
                                                options={this.state.phoneOptions}
                                                value={newSignatory.contactNumber || ""} >
                                                <option value="">--SELECT--</option>
                                            </Select>
                                        </FormField>
                                    ) : (
                                        <FormField  className={"form-group"} id="ContactNumber" error={errors && errors.contactNumber}>
                                            <label>Contact Number</label>
                                            <input
                                                className="form-control"
                                                // disabled={shouldDisableInput}
                                                id="contactNumber"
                                                onChange={(event) => {
                                                    errors && errors.contactNumber && this.handleResetError && this.handleResetError('contactNumber');
                                                    this.handleOnChange(event, 'contactNumber')
                                                }}
                                                type="text"
                                                value={newSignatory.contactNumber || ""}
                                            />
                                        </FormField>
                                    )}
                                </div>  
                                {/* <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                    <FormField className={"form-group"} id="emailAddress" error={errors && errors.emailAddress}>
                                        <label>Email Address</label>
                                        <input
                                            className="form-control"
                                            id="emailAddress"
                                            onChange={(event) => {
                                                errors && errors.emailAddress && this.handleResetError && this.handleResetError('emailAddress');
                                                this.handleOnChange(event, 'emailAddress')
                                            }}
                                            type="text"
                                            value={newSignatory.emailAddress || ''}
                                        />
                                    </FormField>
                                </div> */}
                                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                    <FormField className={"form-group"} id="instruction"
                                                error={errors && errors.instruction}>
                                        <label>Signing arrangements</label>
                                        <select
                                            type="text"
                                            // disabled={shouldDisableInput}
                                            className="form-control"
                                            id="capacity"
                                            value={newSignatory.instruction || ''}
                                            onChange={(event) => {
                                                errors && errors.instruction && this.handleResetError && this.handleResetError('instruction');
                                                this.handleOnChange(event, 'instruction')
                                            }}
                                        >
                                            <option value="">--SELECT--</option>
                                            <option value="SignPlusSingle">Single</option>
                                            <option value="SignPlusPlusAnyOther">Jointly</option>
                                        </select>
                                    </FormField>
                                </div>
                                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                    <FormField className="form-group " id="specimenDoc"
                                        style={{display: "flex", flexDirection: "column"}}
                                        error={errors && errors.signatureImageB64 ? ["Signature required"] : null}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center"
                                        }} >
                                            <label htmlFor="proofOfId" style={{margin: 0, padding: 0}}>Upload Signature </label>
                                        </div>
                                        <p style={{color: 'red', fontSize:12}}> Signature size limit upto 4kb (file extension- jpg,png,bmp.) </p>
                                        <SignatureUpload 
                                            ref={this.signatureUploadRef}
                                            documentType={DOC_TYPES.signatorySignature}
                                            onUpload={this.onHandleUpload.bind(this)}
                                            onRemoveDocument={this.onHandleRemoveDocument.bind(this)}
                                        />
                                        <p style={{ color: 'red' , fontSize : 12 }}>Kindly be advised that the information submitted will be utilized for subsequent validation purposes. Inaccurate information may result in complications and setbacks with forthcoming requests..</p>

                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{ padding: '0px' }}>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                            <div className={'col-lg-12 col-md-12 col-xs-12 col-sm-12'}>
                                <div className="product-heading" style={{ textAlign: 'left', fontWeight: 'bold' }}> Account/s to add to: </div>
                            </div>
                            <div className={"form-group col-lg-12 col-md-12 col-xs-12 col-sm-12"}>
                                <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Search Accounts"
                                    onChange={this.handleAccountSearch}
                                />
                            </div>
                            <div className={"col-lg-12 col-md-12 col-xs-12 col-sm-12"}>
                                <div className="inline-container" onClick={this.selectAllAccounts}>
                                    <i className={this.state.selectAll ? 'fa fa-check-circle-o icon' : 'fa fa-circle-o icon'} />
                                    Select All
                                </div>
                            </div>
                            <div className={"col-lg-12 col-md-12 col-xs-12 col-sm-12"}>
                                <FormField className={'form-group'} error={errors && errors.selectedAccounts}>
                                    <ListGroup style={{ maxHeight: '24em', overflowY: 'scroll', padding: '1em 0.5em' }}>
                                        {this.state.filteredAccounts
                                            .filter(account => account.active === true) // Filter only active accounts
                                            .map((account, i) => (
                                                <ListGroupItem
                                                    type={'button'}
                                                    onClick={() => this.setSelectedAccount(i)}
                                                    active={this.state.selectedAccounts.includes(i)}
                                                    key={i}
                                                >
                                                    <Row style={{ paddingLeft: '2em' }}>{account.name}</Row>
                                                    <Row style={{ paddingLeft: '2.5em' }}>
                                                        <span style={{ fontWeight: 'bold' }}>Account Number: </span>
                                                        <span
                                                            className={this.state.selectedAccounts.includes(i) ? '' : 'text-primary'}
                                                            style={{ fontWeight: 'bold' }}
                                                        >
                                                            {account.accountNumber}
                                                        </span>
                                                    </Row>
                                                </ListGroupItem>
                                            ))}
                                    </ListGroup>
                                </FormField>
                            </div>


                            
                        </div>
                    </div>
                </div>
                    
                <div className="row" style={{margin:'0'}}>
                    {this._renderFooter()}
                </div>
               
            </div>
        )
    }


     // Filter accounts based on the search filter
     filterAccounts = (searchFilter) => {
        const { accounts } = this.props;
        return accounts.filter((account) => {
            return (
                account.name.toLowerCase().includes(searchFilter) ||
                account.accountNumber.toLowerCase().includes(searchFilter)
            );
        });
    };

    render() {
        const { isRSACitizen, isAddingOrSearching } = this.state;

        return (
            <div>
                <hr />
                {this._renderHeader()}
                {this._renderForm()}
                {isAddingOrSearching && (
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444" />
                    </div>
                )}
            </div>
        );
    }
}

    // render() {
    //     const {isRSACitizen, isAddingOrSearching} = this.state;

    //     return (
    //         <div>
    //             <hr />
    //             {this._renderHeader()}
    //             {this._renderForm()}
    //             {
    //                 isAddingOrSearching &&
    //                 <div className="inner-spinner-container">
    //                     <ReactLoading type="spokes" color="#444"/>
    //                 </div>
    //             }
    //         </div>
    //     )
    // }


export default AddNewSignatoryForm

export const AddSignatoryMessage = ({success}) => {
    if (success == true) {
        return (
            <div>
                <div>
                    <div className="product-heading">
                        Successful
                    </div>
                    <div className="title-gradient"/>
                </div>
                <br/>
                <div className="popup-content">
                    <div className="flexColumn">
                        <div>
                            <p>
                                Your instruction has been captured.
                            </p>
                            <p>
                                It should take 24 hours for your request to be completed.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div>
                <div className="product-heading">
                    Unsuccessful
                </div>
                <div className="title-gradient"/>
            </div>
            <br/>
            <div className="popup-content">
                <div className="flexColumn">
                    <div>
                        <p>There was an error capturing your instruction.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
