import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Row from 'react-bootstrap/lib/Row';
import ListGroup from 'react-bootstrap/lib/ListGroup';
import ListGroupItem from 'react-bootstrap/lib/ListGroupItem';
import Col from 'react-bootstrap/lib/Col';
import Button from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Alert from 'react-bootstrap/lib/Alert';


export const AddExistingSignatory = (props) => {
    const [choices, setChoices] = useState([]);
    const [accountFilter, setAccountFilter] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState(false);
    const signatories = useSelector(state => state.copies.copies.signatories);
    const accounts = useSelector(state => state.copies.copies.accounts);
    const [error, setError] = useState(false);

    const dispatch = useDispatch();


    const handleChoice = (change, signer) => {
        const update = [...choices];
        if (update.includes(signer)) {
            _.remove(update, function (arr) {
                return arr === signer;
            });

        } else {
            setAccountFilter(...accountFilter, signer.account.accountNumber);
            update.push(signer);
        }
        setChoices(update);
    };

    const submitCopy = () => {
        if (choices && choices.length > 0) {
            // const signatory = choices.map(choice => signatories[choice]);
            props.saveSignatory(choices, selectedAccount);
        } else {
            setError(true);
        }
    };

    const signSection = () => {
        const filteredSignatories = selectedAccount ? signatories
                .filter(signatory => signatory.account.accountNumber !== selectedAccount.accountNumber)
            : signatories;
        return (
            <div className='row' style={{margin:0}}>
                <h4>Please select the signatory/s you want to copy from:</h4>
                <div className="row" style={{maxHeight: '25em', overflowY: 'scroll', overflowX: 'hidden', margin:0}}>
                    <ListGroup>
                        {
                            signatories && accounts && filteredSignatories.map((signer, i) =>
                                <ListGroupItem type={'button'} onClick={() => handleChoice(i, signer)}
                                            active={choices.includes(signer)}
                                            key={i} disabled={!selectedAccount} className={!selectedAccount ? 'pointerEvents-none' : null}>

                                    <Row style={{paddingLeft: '2em'}}>
                                        <i className={choices.includes(signer) ? 'fa fa-check-square-o icon' : 'fa fa-square-o icon'}/>
                                        {signer.initialsOrFirstNames} {signer.surname}
                                    </Row>
                                    <Row style={{paddingLeft: '2.5em'}}>
                                        <span style={{fontWeight: 'bold'}}>From Account :</span> {signer.account.name}&nbsp;
                                        <span className={choices.includes(signer) ? '' : 'text-primary'}
                                            style={{fontWeight: 'bold'}}>
                                            {signer.account.accountNumber}
                                        </span>
                                    </Row>
                                    <Row style={{paddingLeft: '2.5em'}}>
                                        <span style={{fontWeight: 'bold'}}>Signing Arrangements :</span> {signer.instruction}
                                    </Row>
                                </ListGroupItem>
                            )
                        }
                    </ListGroup>
                </div>
            </div>
        );
    };

    const accountsSection = () => {
        return (
            <div className='row' style={{margin:0}} >
                <h4>Please select the account to copy to:</h4>
                <div className="row" style={{maxHeight: '25em', overflowY: 'scroll'}}>
                    <ListGroup>
                        {
                            accounts && signatories && accounts.map((account, i) =>
                                <ListGroupItem type={'button'} onClick={() => setSelectedAccount(account)}
                                            active={selectedAccount.accountNumber === account.accountNumber}
                                            key={i}>

                                    <Row style={{paddingLeft: '2em'}}>
                                        {/*<i className={selectedAccount === i ? 'fa fa-check-circle-o icon' : 'fa fa-circle-o icon'}/>*/}
                                        {account.name}
                                    </Row>
                                    <Row style={{paddingLeft: '2.5em'}}>
                                        <span style={{fontWeight: 'bold'}}>Account Number: </span>
                                        <span className={selectedAccount.accountNumber === account.accountNumber
                                            ? '' : 'text-primary'}
                                            style={{fontWeight: 'bold'}}>
                                            {account.accountNumber}
                                        </span>
                                    </Row>
                                </ListGroupItem>
                            )
                        }
                    </ListGroup>
                </div>
            </div>
        );
    };

    return (
        <div className="modal-body" style={{padding:'0px'}} >
        <hr />
            <div className="row" style={{margin:0}} >
                <div className="row"  style={{margin:0}}>
                    <div style={{padding:'10px'}}>
                        <div className="product-heading">
                            Existing Signatory
                        </div>
                        <div className="title-gradient"/>
                    </div>
                </div>
                <div className="row" style={{margin:0}}>
                    <Col md={6} >
                        {accountsSection()}
                    </Col>
                    <Col md={6} >
                        {signSection()}
                    </Col>
                </div>
                {error &&
                <div className="row" style={{margin:0}}>
                    <Alert bsStyle={'danger'} style={{margin: '15px', width:'auto'}}>
                        At least one account and one Signatory needs to be selected.
                    </Alert>
                </div>
                }
            </div>
            <div className="row" style={{margin:'0'}}>
                <div className="action-button-container" style={{width: "100%",marginTop:'10px', justifyContent: 'end'}}>
                    <button
                        className="action-btn-primary btn-sm"
                        onClick={submitCopy}
                        type="button">Add to List
                    </button>
                </div>
            </div>
        </div>

    );
};
