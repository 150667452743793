import React from "react";
import _ from "lodash";
import Select from "react-select";
import "react-select/dist/react-select.css";
import SearchComponent from "../../SearchComponent";
import FormField from "../../formField/FormField";
import Toggler from "../../Toggler";
import SignatureUpload from "../../SignatureUpload";
import {
	InputGroup,
	FormControl,
	ControlLabel,
	Col,
	HelpBlock,
} from "react-bootstrap";

const DOC_TYPES = {
	signatorySignature: 'Signatory signature',
};
class NewSignatory extends React.Component {
	state = { isRSACitizen: true, isSearchComplete: false };

	onHandleSearch = () => {
		const { onIndividualSearch } = this.props;
		const onComplete = () => this.setState({ isSearchComplete: true });
		const onError = () => this.setState();
		onIndividualSearch && onIndividualSearch(onComplete);
	};

	onHandleUpload = (base64, inProgress, onComplete) => {
		const { newSignatory, errors } = this.state;
		const { onUploadDocument } = this.props;
		errors && errors.documentId && this.onResetError("documentId");

		const onSuccess = (documentId, data) => {
			const updatedSignatory = _.extend({}, newSignatory, { documentId });
			this.setState({ newSignatory: updatedSignatory });
		};
		onUploadDocument && onUploadDocument(base64, inProgress, onComplete, onSuccess);

	};

	onHandleRemoveDocument = () => {
		const { newSignatory } = this.state;
		const updatedSignatory = _.extend({}, newSignatory, { documentId: null });
		this.setState({ newSignatory: updatedSignatory });
	};

	handleChangeSACitizen = (isRSACitizen) => {
		this.setState({ isRSACitizen });
		if (!isRSACitizen) {
			const { newRP } = this.props;
			newRP.idNumber = "";
			newRP.firstName = "";
			newRP.lastName = "";
			newRP.addresses = "";
			newRP.telephones = "";
			newRP.address = "";
			newRP.contactNumber = "";
		}

	};

	_renderHeader() {
		return (
			<div className="container">
				<div className="product-heading">New Signatory</div>
				<div className="title-gradient" />
			</div>
		);
	}

	render() {
		const { isRSACitizen, isSearchComplete } = this.state;
		return (
			<div>
				{this._renderHeader()}
				<br />
				<div className="container">
					<Toggler
						label="South African Citizen / Resident?"
						isOn={isRSACitizen}
						onToggle={this.handleChangeSACitizen}
					/>
				</div>
				<NaturalPersonForm
					{...this.props}
					shouldDisableInput={!(!isRSACitizen || isSearchComplete)}
					onSearch={this.onHandleSearch}
				/>
			</div>
		);
	}
}

const NaturalPersonForm = (props) => {
	const {
		errors,
		handleNewRPChange,
		handleSelectAddress,
		newRP,
		onResetError,
		onNewRPSubmit,
		onSearch,
		shouldDisableInput,
		appEntity
	} = props;

	if (!newRP) return null;

	const phoneOptions = _.map(newRP.telephones, (t) => ({
		value: t.msisdn,
		label: t.msisdn,
	}));
	// const addressOptions = _.map(newRP.addresses, (t) => ({
	// 	value: t.line1,
	// 	label: t.line1,
	// }));

	const addressOptions = _.map(newRP.addresses, (t) => ({
		value: {street : t.line1,
		       suburb : t.line2,
		       province : t.line3,
		       city : t.line4,
		       postalCode : t.postalCode
		       },
		label: t.line1,
	}));

	const hasPhoneOptions = _.size(phoneOptions) > 0;
	const hasAddressOptions = _.size(addressOptions) > 0;

	const onHandleUpload = (base64, inProgress, onComplete) => {
        const {newSignatory, errors} = state;
        const {onUploadDocument} = props;
        errors && errors.documentId && onResetError("documentId");

        const onSuccess = (documentId, data) => {
            const updatedSignatory = _.extend({}, newSignatory, {documentId});
            setState({newSignatory: updatedSignatory});
        };
        onUploadDocument && onUploadDocument(base64, inProgress, onComplete, onSuccess);

    };

	const onHandleRemoveDocument = () => {
        const {newSignatory} = state;
        const updatedSignatory = _.extend({}, newSignatory, {documentId: null});
        setState({newSignatory: updatedSignatory});
    };




	if (!newRP.address) {
		newRP.address = {};
	}

	if (appEntity === 'Corporate') {
		return (
			<div className="container">
				<div className="container">
					<div className="col-xs-12 col-md-6">
						<div className="card-container-form">
							{!shouldDisableInput ? (
								<FormField
									className={"form-group"}
									error={errors && errors.idNumber}
								>
									<label>
										ID/ Passport Number <span className="text-danger">*</span>
									</label>
									<input
										className="form-control"
										id="idNumber"
										onChange={(event) => {
											errors &&
												errors.idNumber &&
												onResetError &&
												onResetError("idNumber");
											handleNewRPChange(event, "idNumber");
										}}
										type="text"
										value={newRP.idNumber || ""}
									/>
								</FormField>
							) : (
								<div>
									<FormField
										className={"form-group"}
										error={errors && errors.idNumber}
									>
										<SearchComponent
											label="ID Number"
											onChange={(event) => {
												errors &&
													errors.idNumber &&
													onResetError &&
													onResetError("idNumber");
												handleNewRPChange(event, "idNumber");
											}}
											onSearch={onSearch}
											searchValue={newRP.idNumber || ""}
										/>
										{/* <HelpBlock>Search by ID Number</HelpBlock> */}
									</FormField>
								</div>
							)}

							<FormField
								className={"form-group"}
								id="name"
								error={errors && errors.firstName}
							>
								<label>
									First Name <span className="text-danger">*</span>
								</label>
								<input
									className="form-control"
									disabled={shouldDisableInput}
									id="firstName"
									value={newRP.firstName || ""}
									onChange={(event) => {
										errors &&
											errors.firstName &&
											onResetError &&
											onResetError("firstName");
										handleNewRPChange(event, "firstName");
									}}
									type="text"
								/>
							</FormField>

							<FormField
								className={"form-group"}
								id="lastName"
								error={errors && errors.lastName}
							>
								<label>
									Surname <span className="text-danger">*</span>
								</label>
								<input
									className="form-control"
									disabled={shouldDisableInput}
									id="surname"
									onChange={(event) => {
										errors &&
											errors.lastName &&
											onResetError &&
											onResetError("lastName");
										handleNewRPChange(event, "lastName");
									}}
									type="text"
									value={newRP.lastName || ""}
								/>
							</FormField>

							{/* <FormField
								className={"form-group"}
								id="initial"
								error={errors && errors.initial}
							>
								<label>
									Initial <span className="text-danger">*</span>
								</label>
								<input
									className="form-control"
									disabled={shouldDisableInput}
									id="initial"
									onChange={(event) => {
										errors &&
											errors.initial &&
											onResetError &&
											onResetError("initial");
										handleNewRPChange(event, "initial");
									}}
									type="text"
									value={newRP.initial || ""}
								/>
							</FormField> */}
							<div className={"form-group"}>
								<label>
									Address <span className="text-danger">*</span>
								</label>
								{hasAddressOptions ? (
									<FormField id="number" error={errors && errors.address}>
										<Select
											menuContainerStyle={{ zIndex: 999 }}
											disabled={shouldDisableInput}
											name="address"
											onChange={(event) => handleSelectAddress(event, "address")}
											options={addressOptions}
											value={{label :newRP.address && newRP.address.street , value : newRP.address}}
										/>
									</FormField>
								) : (
									<FormField id="number" error={errors && errors.address}>
										<input
											disabled={shouldDisableInput}
											className="form-control"
											id="address"
											onChange={(event) => {
												errors &&
													errors.address &&
													onResetError &&
													onResetError("address");
												handleNewRPChange(event, "address", "street");
											}}
											type="text"
											value={
												(newRP.address && newRP.address.street) || ""
											}
										/>
									</FormField>
								)}
							</div>
						</div>
					</div>

					<div className="col-xs-12 col-md-6">
						<div className="card-container-form">
							{/* <div className={"form-group"}>
            <label>
              Address <span className="text-danger">*</span>
            </label>
            {hasAddressOptions ? (
              <FormField id="number" error={errors && errors.address}>
                <Select
                  menuContainerStyle={{ zIndex: 999 }}
                  disabled={shouldDisableInput}
                  name="address"
                  onChange={(event) => handleSelectAddress(event, "address")}
                  options={addressOptions}
                  value={
                    (newRP.address && newRP.address.address) || ""
                  }
                />
              </FormField>
            ) : (
              <FormField id="number" error={errors && errors.address}>
                <input
                  disabled={shouldDisableInput}
                  className="form-control"
                  id="address"
                  onChange={(event) => {
                    errors &&
                      errors.address &&
                      onResetError &&
                      onResetError("address");
                    handleNewRPChange(event, "address", "address");
                  }}
                  type="text"
                  value={
                    (newRP.address && newRP.address.address) || ""
                  }
                />
              </FormField>
            )}
            </div> */}

							<label>
								Contact Number <span className="text-danger">*</span>
							</label>
							{hasPhoneOptions ? (
								<FormField id="number" error={errors && errors.contactNumber}>
									<Select
										menuContainerStyle={{ zIndex: 999 }}
										disabled={shouldDisableInput}
										name="contactNumber"
										onChange={(event) =>
											props.handleSelectChange(event, "contactNumber")
										}
										options={phoneOptions}
										value={newRP.contactNumber || ""}
									/>
								</FormField>
							) : (
								<FormField id="number" error={errors && errors.contactNumber}>
									<input
										className="form-control"
										disabled={shouldDisableInput}
										id="contactNumber"
										onChange={(event) => {
											errors &&
												errors.contactNumber &&
												onResetError &&
												onResetError("contactNumber");
											handleNewRPChange(event, "contactNumber");
										}}
										type="text"
										value={newRP.contactNumber || ""}
									/>
								</FormField>
							)}

							<FormField
								className={"form-group"}
								id="emailAddress"
								error={errors && errors.emailAddress}
							>
								<label style={{ marginTop: "14px" }}>
									Email Address <span className="text-danger">*</span>
								</label>
								<input
									className="form-control"
									disabled={shouldDisableInput}
									id="email"
									onChange={(event) => {
										errors &&
											errors.emailAddress &&
											onResetError &&
											onResetError("emailAddress");
										handleNewRPChange(event, "emailAddress");
									}}
									type="text"
									value={newRP.emailAddress || ""}
								/>
							</FormField>

							<FormField className={"form-group"} id="signingLimits"
								error={errors && errors.signingLimits}>
								<Col xs={12} style={{ paddingLeft: 0 }}>
									<ControlLabel htmlFor={"percentage"}>Signing Limits <span
										className="text-danger">*</span></ControlLabel>
								</Col>
								<div className="input-group">
									<span className="input-group-addon">R</span>
									<input
										className="form-control"
										disabled={shouldDisableInput}
										id="percentage"
										onChange={(event) => {
											errors && errors.signingLimits && onResetError && onResetError('signingLimits');
											handleNewRPChange(event, 'signingLimits')
										}}
										type="text"
										value={newRP.signingLimits || ''}
									/>
									<span className="input-group-addon">.00</span>
								</div>
							</FormField>


							<FormField
								className={"form-group"}
								id="instruction"
								error={errors && errors.instruction}
							>
								<label>
									Signing arrangements <span className="text-danger">*</span>
								</label>
								<select
									type="text"
									disabled={shouldDisableInput}
									className="form-control"
									id="capacity"
									value={newRP.instruction || ""}
									onChange={(event) => {
										errors &&
											errors.instruction &&
											onResetError &&
											onResetError("instruction");
										handleNewRPChange(event, "instruction");
									}}
								>
									<option value="">--SELECT--</option>
									<option value="SignPlusSingle">Single</option>
									<option value="SignPlusPlusAnyOther">Jointly</option>
									{/* <option>Multi</option> */}
								</select>
							</FormField>

							{/* <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12" style={{padding:0}}>
								<FormField className="form-group " id="specimenDoc"
									style={{display: "flex", flexDirection: "column"}}
									error={errors && errors.documentId ? ["Document required"] : null}>
									<div style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center"
									}} >
										<label htmlFor="proofOfId" style={{margin: 0, padding: 0}}>Upload Signature </label>

									</div>

									<SignatureUpload 
									documentType={DOC_TYPES.signatorySignature}
										onUpload={onHandleUpload}
										onRemoveDocument={onHandleRemoveDocument}
									/>
									<p style={{ color: 'red' , fontSize : 10 }}>Kindly be advised that the information submitted will be utilized for subsequent validation purposes. Inaccurate information may result in complications and setbacks with forthcoming requests..</p>

								</FormField>
							</div> */}
						</div>
					</div>
				</div>

				<div className="action-button-container" style={{ width: "100%" }}>
					<div />
					<button
						className="action-btn-secondary"
						onClick={onNewRPSubmit}
						type="button"
					>
						Add
					</button>
				</div>
			</div>
		);
	}

	else {
		return (
			<div className="container">
				<div className="container">
					<div className="col-xs-12 col-md-6">
						<div className="card-container-form">
							{
								!shouldDisableInput ?
									<FormField className={"form-group"} error={errors && errors.idNumber}>
										<label>ID/ Passport Number <span className="text-danger">*</span></label>
										<input
											className="form-control"
											id="idNumber"
											onChange={(event) => {
												errors && errors.idNumber && onResetError && onResetError('idNumber');
												handleNewRPChange(event, 'idNumber')
											}}
											type="text"
											value={newRP.idNumber || ''}
										/>
									</FormField>

									:
									<div>
										<FormField className={"form-group"} error={errors && errors.idNumber}>
											<SearchComponent
												label="ID Number"
												onChange={(event) => {
													errors && errors.idNumber && onResetError && onResetError('idNumber');
													handleNewRPChange(event, 'idNumber')
												}}
												onSearch={onSearch}
												searchValue={newRP.idNumber || ''}
											/>
											{/* <HelpBlock>Search by ID Number</HelpBlock> */}
										</FormField>
									</div>
							}

							<FormField className={"form-group"} id="name" error={errors && errors.firstName}>
								<label>First Name <span className="text-danger">*</span></label>
								<input
									className="form-control"
									disabled={shouldDisableInput}
									id="firstName"
									value={newRP.firstName || ''}
									onChange={(event) => {
										errors && errors.firstName && onResetError && onResetError('firstName');
										handleNewRPChange(event, 'firstName')
									}}
									type="text"
								/>
							</FormField>

							<FormField className={"form-group"} id="lastName" error={errors && errors.lastName}>
								<label>Surname <span className="text-danger">*</span></label>
								<input
									className="form-control"
									disabled={shouldDisableInput}
									id="surname"
									onChange={(event) => {
										errors && errors.lastName && onResetError && onResetError('lastName');
										handleNewRPChange(event, 'lastName')
									}}
									type="text"
									value={newRP.lastName || ''}
								/>
							</FormField>

							<div className="form-group">
								<label>Address <span className="text-danger">*</span></label>
								{
									hasAddressOptions ?
										<FormField id="number" error={errors && errors.address}>
											<Select
												menuContainerStyle={{ zIndex: 999 }}
												disabled={shouldDisableInput}
												name="address"
												onChange={(event) => handleSelectAddress(event, 'address')}
												options={addressOptions}
												value={{label :newRP.address && newRP.address.street , value : newRP.address}}
											/>
										</FormField>
										:
										<FormField id="number" error={errors && errors.address}>
											<input
												disabled={shouldDisableInput}
												className="form-control"
												id="address"
												onChange={(event) => {
													errors && errors.address && onResetError && onResetError('address');
													handleNewRPChange(event, 'address', 'street')
												}}
												type="text"
												value={newRP.address && newRP.address.street || ''}
											/>
										</FormField>
								}
							</div>
						</div>
					</div>

					<div className="col-xs-12 col-md-6">
						<div className="card-container-form">
							<div className="form-group">
								<label>Contact Number <span className="text-danger">*</span></label>
								{
									hasPhoneOptions ?
										<FormField id="number" error={errors && errors.contactNumber}>
											<Select
												menuContainerStyle={{ zIndex: 999 }}
												disabled={shouldDisableInput}
												name="contactNumber"
												onChange={(event) => props.handleSelectChange(event, 'contactNumber')}
												options={phoneOptions}
												value={newRP.contactNumber || ''}
											/>
										</FormField>
										:
										<FormField id="number" error={errors && errors.contactNumber}>
											<input
												className="form-control"
												disabled={shouldDisableInput}
												id="contactNumber"
												onChange={(event) => {
													errors && errors.contactNumber && onResetError && onResetError('contactNumber');
													handleNewRPChange(event, 'contactNumber')
												}}
												type="text"
												value={newRP.contactNumber || ''}
											/>
										</FormField>
								}
							</div>

							<FormField className={"form-group"} id="emailAddress" error={errors && errors.emailAddress}>
								<label>Email Address <span className="text-danger">*</span></label>
								<input
									className="form-control"
									disabled={shouldDisableInput}
									id="email"
									onChange={(event) => {
										errors && errors.emailAddress && onResetError && onResetError('emailAddress');
										handleNewRPChange(event, 'emailAddress')
									}}
									type="text"
									value={newRP.emailAddress || ''}
								/>
							</FormField>

							<FormField className={"form-group"} id="signingLimits" error={errors && errors.signingLimits}>
								<Col xs={12} style={{ paddingLeft: 0 }}>
									<ControlLabel htmlFor={"percentage"}>
									Signing Limits 
									<span className="text-danger">*</span></ControlLabel>
								</Col>
								<div className="input-group">
									<span className="input-group-addon">R</span>
									<input
										className="form-control"
										disabled={shouldDisableInput}
										id="percentage"
										onChange={(event) => {
											errors && errors.signingLimits && onResetError && onResetError('signingLimits');
											handleNewRPChange(event, 'signingLimits')
										}}
										type="text"
										value={newRP.signingLimits || ''}
									/>
									<span className="input-group-addon">.00</span>
								</div>
							</FormField>

							<FormField className={"form-group"} id="instruction"
								error={errors && errors.instruction}>
								<label>Signing arrangements <span className="text-danger">*</span></label>
								<select
									type="text"
									disabled={shouldDisableInput}
									className="form-control"
									id="capacity"
									value={newRP.instruction || ''}
									onChange={(event) => {
										errors && errors.instruction && onResetError && onResetError('instruction');
										handleNewRPChange(event, 'instruction')
									}}
								>
									<option value="">--SELECT--</option>
									<option value="SignPlusSingle">Single</option>
									<option value="SignPlusPlusAnyOther">Jointly</option>
								</select>
							</FormField>
						</div>
					</div>
				</div>

				<div className="action-button-container" style={{ width: "100%" }}>
					<div />
					<button
						className="action-btn-secondary"
						onClick={onNewRPSubmit}
						type="button"
					>Add
					</button>
				</div>
			</div>
		);
	}
};

export default NewSignatory;
