import React from 'react';
import _ from "lodash";
import {connect} from 'react-redux'
import {bindActionCreators} from "redux";
import validate from 'validate.js'
import ReactLoading from "react-loading";

import * as actions from '../../actions/maintenance'
import {
    clearCopies,
    clearIndiviualInfo,
    fetchAccounts,
    fetchIndiviualInfo,
    fetchSignatoriesForAccount,
    selectAllSignatories,
    selectSignatory,
    uploadDoc
} from "../../actions/kyc";

import {fetchHierachy} from "../../actions/entity";

import FormField from "../formField/FormField";
import AccountsList from '../accounts/AccountsList';
import EntityList from "../legalentity/EntityList";
import NoLinkedEntitiesMessage from "../legalentity/NoLinkedEntitiesMessage";
import "../../styles/rules.css";
import RemoveSignatory, {RemovalMessage} from "../kyc/signatories/RemoveSignatory";
import Popup from "../Popup.js";
import AddNewSignatoryForm, {AddSignatoryMessage} from "./AddNewSignatoryForm";

const MAINTENANCE_ACTIONS = {
    DELETE: "DELETE",
    ADD: "ADD"
};

const INDIVIDUAL_TYPE = "signatoryCandidate";

class MaintenancePage extends React.Component {
    state = {
        selectedEntity: null,
        isFetchingAccounts: false,
        errors: {},
        showRemoveSignatories: false,
        showAddSignatories: false,
        showRemoveCompleteMessage: null,
        showAddSignatoryCompleteMessage: null,
        isSelectAll: false
    };

    componentWillMount() {
        this._fetchData();
    }

    _fetchData() {
        const {actions, impersonation} = this.props;
        actions.fetchHierachy(impersonation);
    }

    handleOnSelectEntity = (selectedEntity) => {
        const {actions} = this.props;
        this.setState({isFetchingAccounts: true, selectedEntity, selectedAccount: null});

        const onComplete = () => {
            this.setState({isFetchingAccounts: false});
        };
        actions.fetchAccounts(`cifNumber=${selectedEntity.cif}`, 'selectedEntityAccounts', onComplete, false);
    };

    handleOnFilterAccounts = (searchTerm) => {
        const {accounts} = this.props;
        let filteredAccounts = null;
        if (searchTerm) {
            filteredAccounts = _.filter(accounts, acc => {
                return acc && (acc.accountNumber.includes(searchTerm) || acc.name && acc.name.toLowerCase().includes(searchTerm.toLowerCase()))
            })
        }
        this.setState({filteredAccounts, searchTerm})
    };

    onHandleSelectAccount = (selectedAccount) => {
        const {errors} = this.state;
        errors && errors.selectedAccount && this.handleResetError("selectedAccount");
        this.setState({selectedAccount})
    };

    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors});
    };

    validateSelected = () => {
        const {selectedAccount} = this.state;
        const options = {fullMessages: true};

        const constraints = {selectedAccount: {presence: true}};
        const errors = validate({selectedAccount}, constraints, options);
        this.setState({errors});
        return _.isEmpty(errors)
    };

    onHandleShowRemoveSignatoriesPopUp = (e) => {
        e.preventDefault();
        if (this.validateSelected()) {
            const {actions} = this.props;
            const {selectedEntity, selectedAccount} = this.state;
            actions.clearCopies();

            this.setState({isFetchingSignatories: true});

            this.onToggleRemoveSignatoriesPopUp();
            const onComplete = () => {
                this.setState({isFetchingSignatories: false});
            };
            actions.fetchSignatoriesForAccount(`cif=${selectedEntity && selectedEntity.cif}`, selectedAccount.accountNumber, onComplete)
        }
    };
    onHandleShowAddSignatoriesPopUp = (e) => {
        e.preventDefault();

        if (this.validateSelected()) {
            this.onToggleAddSignatoriesPopUp();
        }
    };
    onHandleSignatorySelect = (e, signatory) => {
        const {actions, signatories} = this.props;
        const isSelected = !(signatory && signatory.isSelected);
        actions.selectSignatory(signatories, signatory, isSelected);
    };

    onSelectAllSignatory = () => {
        const {actions, signatories} = this.props;
        this.setState(prevState => {
            const isSelectAll = !prevState.isSelectAll;
            actions.selectAllSignatories(signatories, isSelectAll);
            return {isSelectAll}
        });
    };

    onToggleRemoveSignatoriesPopUp = () => {
        this.setState(prevState => ({showRemoveSignatories: !prevState.showRemoveSignatories}))
    };

    onToggleAddSignatoriesPopUp = () => {
        this.setState(prevState => ({showAddSignatories: !prevState.showAddSignatories}))
    };

    handleDeleteSignatories = (signatories, onFinish) => {
        const {actions, user, impersonation} = this.props;
        const {selectedEntity, selectedAccount} = this.state;
        const instructingUser = {
            identityNumber: user && user.identityNumber,
            firstName: user && user.firstName,
            surname: user && user.surname
        };
        const impersonatedUser = {
            identityNumber: impersonation && impersonation.identityNumber,
            firstName: '',
            surname: ''
        };
        const accountNumber = selectedAccount && selectedAccount.accountNumber;
        const filteredSignatories = _.filter(signatories, signatory => (signatory && signatory.isSelected));
        const instructions = _.map(filteredSignatories, sign => (_.extend({}, sign, {action: MAINTENANCE_ACTIONS.DELETE})));
        const onComplete = (success = false) => {
            onFinish && onFinish();
            this.onToggleRemoveSignatoriesPopUp();
            this.setState({showRemoveCompleteMessage: {success}, isSelectAll: false})
        };
        if(accountNumber) {
            actions.removeSignatory({selectedEntity, accountNumber, instructions, instructingUser, impersonatedUser}, onComplete)
        }
    };


    handleAddSignatory = (signatoryInfo, onFinish) => {
        const {actions, user, impersonation} = this.props;
        const {selectedEntity, selectedAccount} = this.state;
        const instructingUser = {
            identityNumber: user && user.identityNumber,
            firstName: user && user.firstName,
            surname: user && user.surname
        };
        const impersonatedUser = {
            identityNumber: impersonation && impersonation.impersonatorIdentityNumber,
            firstName: '',
            surname: ''
        };
        const accountNumber = selectedAccount && selectedAccount.accountNumber;

        const onComplete = (success = false) => {
            onFinish && onFinish();
            actions.clearIndiviualInfo(INDIVIDUAL_TYPE);
            this.onToggleAddSignatoriesPopUp();
            this.setState({showAddSignatoryCompleteMessage: {success}})
        };

        if(selectedAccount) {
            actions.addSignatory({
                selectedEntity,
                instructions: [_.extend({}, signatoryInfo, {action: MAINTENANCE_ACTIONS.ADD})],
                instructingUser,
                impersonatedUser,
                accountNumber
            }, onComplete);
        }
    };

    handleUploadDocument = (base64, inProgress, onComplete, onSuccess = null) => {
        const {actions} = this.props;
        actions.uploadDoc(base64, onSuccess, null, inProgress, onComplete);
    };

    handleIndividualSearch = (idNumber, onComplete) => {
        const {actions} = this.props;
        actions.fetchIndiviualInfo(idNumber, onComplete, INDIVIDUAL_TYPE);
    };

    _renderEntitiesSection() {
        const {entities} = this.props;
        const {selectedEntity} = this.state;

        return (
            <div style={{width: "100%"}}>
                <div>
                    <div className="product-heading">
                        Legal Entities
                    </div>
                    <div className="title-gradient"/>
                </div>

                <EntityList
                    className="card-container"
                    entities={entities}
                    onSelect={this.handleOnSelectEntity}
                    selectedEntity={selectedEntity}
                />
            </div>
        )
    }

    _renderMaintenanceSection() {
        const {accounts} = this.props;
        const {errors, filteredAccounts, form, isFetchingAccounts, selectedAccount} = this.state;
        if (isFetchingAccounts) {
            return (
                <div className="rule-spinner">
                    <ReactLoading type="spokes" color="#444"/>
                </div>
            )
        }
        const hasAccounts = _.size(accounts) > 0;
        return (
            <div>
                <div className="product-heading">
                    Accounts
                </div>
                <div className="title-gradient"/>
                <FormField
                    error={errors && errors.selectedAccount && ["One Should Be Selected"]}
                >
                    {
                        (!hasAccounts && !isFetchingAccounts) ?
                            <div>
                                <br/>
                                <p>Selected Entity Has No Accounts.</p>
                            </div>
                            :
                            <AccountsList
                                accounts={accounts}
                                filteredAccounts={filteredAccounts}
                                onFilterAccounts={this.handleOnFilterAccounts}
                                onSelect={() => {
                                }}
                                onSelectAll={() => {
                                }}
                                onSelectRadio={this.onHandleSelectAccount}
                                selectedAcc={selectedAccount}
                                isMultiSelect={false}
                                listStyle={{}}
                            />
                    }
                </FormField>
            </div>
        )
    }

    _renderPopUps() {
        const {candidate, signatories} = this.props;
        const {isFetchingSignatories, isSelectAll, showRemoveSignatories, showAddSignatories, showRemoveCompleteMessage, showAddSignatoryCompleteMessage} = this.state;
        if (showRemoveSignatories) {
            return (
                <Popup onClose={this.onToggleRemoveSignatoriesPopUp}>
                    <RemoveSignatory
                        signatories={signatories}
                        isFetchingSignatories={isFetchingSignatories}
                        isSelectAll={isSelectAll}
                        onSelectAll={this.onSelectAllSignatory}
                        onSelect={this.onHandleSignatorySelect}
                        onDeleteSignatories={this.handleDeleteSignatories}
                    />
                </Popup>
            )
        } else if (showAddSignatories) {
            return (
                <Popup onClose={this.onToggleAddSignatoriesPopUp}>
                    <AddNewSignatoryForm
                        candidate={candidate}
                        onAddSignatoryToAccount={this.handleAddSignatory}
                        onIndividualSearch={this.handleIndividualSearch}
                        onUploadDocument={this.handleUploadDocument}
                    />
                </Popup>
            )

        } else if (showRemoveCompleteMessage) {
            return (
                <Popup onClose={() => this.setState({showRemoveCompleteMessage: null})}>
                    <RemovalMessage
                        success={showRemoveCompleteMessage && showRemoveCompleteMessage.success}
                    />
                </Popup>
            )
        } else if (showAddSignatoryCompleteMessage) {
            return (
                <Popup onClose={() => this.setState({showAddSignatoryCompleteMessage: null})}>
                    <AddSignatoryMessage
                        success={showAddSignatoryCompleteMessage && showAddSignatoryCompleteMessage.success}
                    />
                </Popup>
            )
        }
        return null
    }

    _renderFooter() {
        const {selectedEntity, isFetchingAccounts} = this.state;
        if (!(selectedEntity && !isFetchingAccounts)) return null;
        return (
            <div className="action-button-container">
                <button
                    className="action-btn-primary"
                    onClick={this.onHandleShowAddSignatoriesPopUp}
                >Add Signatories
                </button>
                <button
                    className="action-btn-primary"
                    onClick={this.onHandleShowRemoveSignatoriesPopUp}
                >Remove Signatories
                </button>
            </div>
        )
    }

    render() {
        const {entities, loading, systemError} = this.props;
        const {selectedEntity, isLoading} = this.state;
        if (loading || systemError && systemError.show) return null;

        const hasEntities = _.size(entities) > 0;

        if (!hasEntities) return (
            <div className="page-container">
                <div className="page-main-section">
                    <NoLinkedEntitiesMessage/>
                </div>
            </div>
        );
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="flexRow" style={{width: "100%", alignItems: "flex-start"}}>

                        {this._renderEntitiesSection()}
                        <div style={{width: "100%", marginLeft: 20}}>
                            {selectedEntity && this._renderMaintenanceSection()}
                        </div>
                    </div>
                </div>
                <div className="page-footer-section" style={{width: "80%"}}>
                    {this._renderFooter()}
                </div>

                {this._renderPopUps()}
                {
                    isLoading &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps({accounts, entity, impersonation, individual, loading, systemError, copies, user}) {
    return {
        accounts: accounts && accounts.selectedEntityAccounts ? accounts.selectedEntityAccounts : [],
        entities: entity.entities ? entity.entities : [],
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        signatories: copies && copies.copies ? copies.copies : [],
        user: user && user.user ? user.user : {},
        candidate: individual && individual[INDIVIDUAL_TYPE] ? individual && individual[INDIVIDUAL_TYPE] : {},
        loading,
        systemError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...actions,
            clearCopies,
            fetchAccounts,
            fetchHierachy,
            fetchSignatoriesForAccount,
            selectSignatory,
            selectAllSignatories,
            fetchIndiviualInfo,
            clearIndiviualInfo,
            uploadDoc
        }, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePage);
