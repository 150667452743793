import React, {useEffect, useImperativeHandle, useState} from 'react';
import _ from 'lodash';
import {DataList} from '../../../../node_modules/primereact/components/datalist/DataList';
import ReactLoading from 'react-loading';

import {clearEntityInfo, fetchEntityInfo, removeSearched, saveNewEntityInfo} from '../../../actions/entity';
import NewRelatedParty from '../../kyc/related/NewRelatedParty';
import validate from 'validate.js';

import Popup from '../../Popup';
import FormField from '../../formField/FormField';
import {HelpBlock} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {clearIndiviualInfo, fetchIndiviualInfo, uploadDoc} from '../../../actions/kyc';


const ACTIONS = {
    add: 'add',
    copy: 'copy',
    none: 'none',
    update: 'update'
};

const BUSINESS_TYPE = {
    naturalPerson: 'Natural Person',
    juristicEntity: 'Juristic Entity',
    individual: 'individual'
};

const DOC_TYPES = {
    proofOfId: 'Proof of Identity',
    proofOfAddress: 'Proof Of Address'
};

export const NewEntityRelatedPartiesComponent = React.forwardRef((props, ref) => {

    const [action, setAction] = useState(ACTIONS.none);
    const [addNewRP, setAddNewRP] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [newRP, setNewRP] = useState({
        businessType: BUSINESS_TYPE.naturalPerson,
        supportingDocuments: []
    });


    const relatedPartyCandidate = useSelector(state => state.individual && state.individual.relatedPartyCandidate);
    const entityInfo = useSelector(state => state.entityInfo && state.entityInfo.entityInfo);
    const newEntityDetails = useSelector(state => state.entity && state.entity.newEntityDetails);

    const dispatch = useDispatch();

    useImperativeHandle(ref, () => {
        return {
            validateRelatedParties: validateRelatedParties
        };
    });

    useEffect(() => {
            if (relatedPartyCandidate) {
                let person = {};
                person.registrationNumber = relatedPartyCandidate.idNumber;
                person.registeredName = relatedPartyCandidate.firstName;
                person.lastName = relatedPartyCandidate.lastName;
                person.businessType = BUSINESS_TYPE.naturalPerson;
                person.telephones = relatedPartyCandidate.telephones;
                person.supportingDocuments = [];
                setNewRP(person);
            }
        }, [relatedPartyCandidate]
    );

    useEffect(() => {
            if (entityInfo) {
                let entity = {};

                entity.businessType = BUSINESS_TYPE.juristicEntity;
                entity.contactNumber = entityInfo.telephoneNumber;

                entity.street = entityInfo.address && entityInfo.address.street ? entityInfo.address.street : '';
                entity.registeredName = entityInfo.registeredName;
                entity.registrationNumber = entityInfo.registrationNumber;
                entity.supportingDocuments = [];
                setNewRP(entity);
            }
        }, [entityInfo]
    );


    const handleNewRPChange = (event, attribute) => {
        setNewRP(_.extend({}, newRP, {[attribute]: _.trimStart(event.target.value)}));
    };

    const handleAddNewRPClick = () => {
        setNewRP(_.extend({}, newRP, {supportingDocuments: []}));
        setAction(ACTIONS.add);
        setAddNewRP(true);

    };

    const addDoc = (fileType, file) => {
        const strippedBase64 = file.base64.split(',')[1];
        const doc = {
            documentId: strippedBase64,
            documentType: fileType,
            name: file.name,
            verified: true,
        };

        setNewRP(_.extend({}, newRP, {
            supportingDocuments: _.union(newRP.supportingDocuments, [doc])
        }));
    };

    const selectRPType = (event, selected) => {
        event.preventDefault();
        setNewRP({businessType: selected, supportingDocuments: []});
        setErrors({});
    };

    const removeRP = (selected) => {

        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;

        const relatedParties = _.filter(legalEntity && legalEntity.relatedParties, r => r.registrationNumber !== selected.registrationNumber);

        const updateLegalEntity = _.extend({}, legalEntity, {relatedParties});
        const entityInfo = _.extend({}, newEntityDetails, {legalEntity: updateLegalEntity});

        dispatch(saveNewEntityInfo(entityInfo, null, null));
    };

    const onCompleteSubmit = (isSuccess = false) => {
        loadingFinish();
        if (isSuccess) {
            dispatch(removeSearched());
            dispatch(clearIndiviualInfo('relatedPartyCandidate'));
            dispatch(clearEntityInfo());

            setAddNewRP(false);
            setNewRP({
                businessType: BUSINESS_TYPE.naturalPerson,
                supportingDocuments: []
            });
            setErrors({});
        }
    };

    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    };

    const validateRelatedParties = () => {

        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        const relatedParties = legalEntity && legalEntity.relatedParties;

        const options = {fullMessages: false};
        const constraints = {relatedParties: {presence: true}};

        const errors = validate({relatedParties: _.size(relatedParties) > 0 ? relatedParties : null}, constraints, options);
        setErrors(errors);
        return _.isEmpty(errors);
    };

    const validateForm = () => {
        const options = {fullMessages: false};

        const supportingDocuments = newRP && newRP.supportingDocuments;

        let constraints;
        let proofOfIdError = null;

        if (newRP.businessType === BUSINESS_TYPE.juristicEntity) {
            constraints = {
                registeredName: {presence: true, length: {minimum: 1, message: 'required'}},
                registrationNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                contactNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                sharePercentage: {presence: true, numericality: {greaterThan: 0, lessThanOrEqualTo: 100}},
                physicalAddress: {presence: true, length: {minimum: 1, message: 'required'}},
                headOfficeAddress: {presence: true, length: {minimum: 1, message: 'required'}},
                street: {presence: true, length: {minimum: 1, message: 'required'}}
            };
        } else {
            constraints = {
                registeredName: {presence: true, length: {minimum: 1, message: 'required'}},
                registrationNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                contactNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                sharePercentage: {presence: true, numericality: {greaterThan: 0, lessThanOrEqualTo: 100}},
                lastName: {presence: true, length: {minimum: 1, message: 'required'}},
                emailAddress: {presence: true, email: true, length: {minimum: 1, message: 'required'}},
                capacity: {presence: true, length: {minimum: 1, message: 'required'}}
            };

            const proofOfIdContraints = {proofOfId: {presence: true}};
            const proofOfId = _.find(supportingDocuments, doc => (doc.documentType === DOC_TYPES.proofOfId));
            proofOfIdError = validate({proofOfId}, proofOfIdContraints, options);
        }


        const proofOfAddressContraints = {proofOfAddress: {presence: true}};
        const proofOfAddress = _.find(supportingDocuments, doc => (doc.documentType === DOC_TYPES.proofOfAddress));

        const proofOfAddressError = validate({proofOfAddress}, proofOfAddressContraints, options);

        const newRPerrors = validate(newRP, constraints, options);

        const errors = _.extend({}, newRPerrors, proofOfAddressError, proofOfIdError);

        setErrors(errors);
        return _.isEmpty(errors);
    };

    const handleSubmit = () => {
        if (validateForm()) {
            const legalEntity = newEntityDetails && newEntityDetails.legalEntity;

            loadingStart();

            let relatedParties;
            const isFound = _.find(legalEntity && legalEntity.relatedParties, r => (r.registrationNumber === newRP.registrationNumber));
            if (isFound) {
                relatedParties = _.map(legalEntity && legalEntity.relatedParties, r => (r.registrationNumber === newRP.registrationNumber ? newRP : r));
            } else {
                relatedParties = _.union(legalEntity && legalEntity.relatedParties, [newRP]);
            }

            const updateLegalEntity = _.extend({}, legalEntity, {relatedParties});
            const entityInfo = _.extend({}, newEntityDetails, {legalEntity: updateLegalEntity});

            dispatch(saveNewEntityInfo(entityInfo, null, onCompleteSubmit, true, false, true));
        }
    };

    const onIndividualSearch = (onComplete) => {
        loadingStart();
        const onFinish = (success, data) => {
            loadingFinish();
            setNewRP({
                registrationNumber: data.idNumber,
                registeredName: `${data.firstName}`,
                lastName : `${data.lastName}`,
                businessType: 'Natural Person'
            });
            onComplete && onComplete();
        };
        const onError = () => {
            // this.setState({isLoading: false});
            loadingFinish();
            setErrors({registrationNumber: ['Individual not found on search. Check ID number.']});
        };
        dispatch(fetchIndiviualInfo(newRP.registrationNumber, onFinish, 'relatedPartyCandidate', onError));
    };

    const onEntitySearch = (onComplete) => {
        loadingStart();
        const onFinish = (data) => {
            loadingFinish();
            const rpData = {
                registrationNumber: data.registrationNumber,
                registeredName: data.registeredName,
                businessType: 'Juristic Entity'
            };
            if (data.primaryDataSource === 'PUBLIC'){
                rpData.street= `${data.registeredAddress.street} ${data.registeredAddress.suburb} ${data.registeredAddress.city} ${data.registeredAddress.province}`;
            } else {
                rpData.street = _.isEmpty(data.addresses) ? '' :
                    `${data.addresses[0].street} ${data.addresses[0].suburb} ${data.addresses[0].city} ${data.addresses[0].province}`;
            }
            setNewRP(rpData);
            onComplete && onComplete();
        };
        const onError = () => {
            // this.setState({isLoading: false});
            loadingFinish();
            setErrors({registrationNumber: ['Entity not found on search. Check Registration Number.']});
        };
        dispatch(fetchEntityInfo(newRP.registrationNumber, onFinish, null, onError));
    };

    const handleSelectChange = (event, attribute) => {
        setNewRP(_.extend({}, newRP, {[attribute]: event.value}));
    };

    const loadingStart = () => {
        setIsLoading(true);
    };

    const loadingFinish = () => {
        setIsLoading(false);
    };


    const handleCancel = () => {
        dispatch(removeSearched());
        dispatch(clearIndiviualInfo());
        dispatch(clearEntityInfo());

        setAddNewRP(false);
        setNewRP({
            businessType: BUSINESS_TYPE.naturalPerson,
            supportingDocuments: []
        });
        setErrors({});
    };


    const partyTemplate = (relatedParty) => {
        if (!relatedParty) return null;

        return (
            relatedParty.actionType !== 'REMOVED' &&
            <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-6">
                    <label>{relatedParty.registeredName}</label>
                </div>

                <div className="col-md-5 col-sm-5 col-xs-5">
                    <label
                        className="form-group"
                        id="shareHolderStructureStatus"
                    />
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1" style={{padding: 0}}>
                    <a
                        className="btn"
                        role="button"
                        id="remove-btn"
                        onClick={() => removeRP(relatedParty)}>
                        <i className="fa fa-times" aria-hidden="true"/>
                    </a>
                </div>
                <hr/>
            </div>);
    };

    const renderRelatedParties = () => {
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        const hasRelatedParties = _.size(legalEntity && legalEntity.relatedParties) > 0;

        if (!hasRelatedParties) return null;
        return (
            <DataList
                className="ui-datalist-nobullets"
                itemTemplate={partyTemplate.bind(this)}
                paginator={true}
                rows={3}
                value={legalEntity.relatedParties}
            />
        );
    };

    const renderSpinner = () => {
        console.log(isLoading);
        if (!isLoading) return null;
        return (
            <div className="inner-spinner-container">
                <ReactLoading type="spokes" color="#444"/>
            </div>
        );
    };

    const onHandleUpload = (base64, inProgress, onComplete, documentType) => {

        errors && errors[documentType] && resetError(documentType);

        const onSuccess = (documentId, data) => {
            const document = {
                documentType,
                verified: true,
                documentId,
                name: `${documentType}: ${newRP.idNumber}`
            };

            const supportingDocuments = _.union(newRP && newRP.supportingDocuments, [document]);
            const updatedNewRP = _.extend({}, newRP, {supportingDocuments});
            setNewRP(updatedNewRP);
        };
        dispatch(uploadDoc(base64, onSuccess, null, inProgress, onComplete, onSuccess));
    };

    const onHandleRemoveDocument = (documentType) => {
        const supportingDocuments = _.filter(newRP && newRP.supportingDocuments, doc => (doc.documentType !== documentType));

        const updatedNewRP = _.extend({}, newRP, {supportingDocuments});
        setNewRP(updatedNewRP);
    };


    return (
        <div className="flexColumn">
            <div className="card-container-form">
                <div className="section-title">
                    <span>Related Parties</span>
                    <HelpBlock>
                        <small>Beneficial Owners, Controllers or Senior Management</small>
                    </HelpBlock>
                </div>

                {renderRelatedParties()}

                {
                    !(addNewRP) &&

                    <FormField
                        className="form-group"
                        error={errors && errors.relatedParties ? ['At least One Related Party Should Be Added'] : ''}>
                        <a
                            className="btn btn-secondary addButton"
                            onClick={() => {
                                errors && errors.relatedParties && resetError('relatedParties');
                                handleAddNewRPClick();
                            }}
                        >Add a related party</a>
                        {errors && errors.relatedParties && <div><br/><br/></div>}
                    </FormField>
                }
                {
                    addNewRP &&
                    <Popup onClose={handleCancel}>
                        <NewRelatedParty
                            addDoc={addDoc}
                            errors={errors}
                            onCancel={handleCancel}
                            onChange={handleNewRPChange}
                            handleSelectChange={handleSelectChange}
                            onIndividualSearch={onIndividualSearch}
                            onEntitySearch={onEntitySearch}
                            onResetError={resetError}
                            onSubmit={handleSubmit}
                            showUpload={true}
                            newRP={newRP}
                            selectRPType={selectRPType}
                            onHandleUpload={onHandleUpload}
                            onRemoveDocument={onHandleRemoveDocument}
                        />

                        {renderSpinner()}
                    </Popup>
                }
                {renderSpinner()}
            </div>
        </div>
    );
});

export default NewEntityRelatedPartiesComponent;
