import React from 'react';

const DropDownIcon = ({isSelectable, shouldDropDown, isToggled, disabled}) => {
    const getColor = () => {
        if (disabled) return "grey";
        return "rgb(0, 51, 170)"
    };
    return (
        <i
            className={
                (isToggled || !isSelectable) ? (shouldDropDown ? "fa fa-minus" : "fa fa-check-square-o") : (shouldDropDown ? "fa fa-plus" : "fa fa-square-o")
            }
            style={{color: getColor(isSelectable, shouldDropDown), fontSize: 16, marginRight: 10}}
        />
    )
};

export default DropDownIcon;