import React from 'react';

const iconStyle = (isDisabled) => (
    {color: isDisabled ? "grey" : "rgb(0, 51, 170)", fontSize: 18, marginLeft: 5}
);

const Toggler = ({isOn, label, onToggle, isDisabled}) => (
    <div className="checkbox-inline row" style={{padding: 0, color: isDisabled ? "grey" : 'black', display: "block"}}>
         <div className={"col-md-6"}>
                {`${label}`}
        </div>
        <div className={"col-md-6"}>
            <span onClick={() => !isDisabled && onToggle && onToggle(true)} style={{ marginLeft:'2em'}}>
                <i className={isOn ? "fa fa-check-circle" : "fa fa-circle-o"} style={iconStyle(isDisabled)} /> Yes
            </span>
            <span onClick={() => !isDisabled && onToggle && onToggle(false)} style={{ marginLeft:'0.5em'}}>
                <i className={!isOn ? "fa fa-check-circle" : "fa fa-circle-o"} style={iconStyle(isDisabled)} /> No
            </span>
        </div>
    </div>
);

export default Toggler