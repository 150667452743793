import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function tpfaReducer(state = initialState.tpfa, action) {
    switch (action.type) {
        case types.TPFA_START:
            return {...state, ...action.tpfa};
        case types.TPFA_SAVE:
            return {...state, ...action.tpfa};
        case types.TP_ADDITIONALPRODUCT:
            console.log('action', action);
            const {application} = state;
            application.additionalProducts = action.additionalProducts;
            return {...state, application};
        case types.TP_CONTINUE:
            return {...state, ...action.tpfa, saved: false};
        default:
            return state;
    }
}