import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import {
    Button, Badge, Panel, ListGroup, ListGroupItem
} from "react-bootstrap";

class ProfileComponent extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {division, division: {code, name, users}, onProfileSelect, agreement, selected} = this.props;
        const wellStyles = {margin: '0 auto 10px'};
        return (
            <div>
                <div className="btn-block" style={wellStyles}>
                    <Button bsStyle="primary" className={selected ? selected === code ? 'active' : '' : ''}
                            bsSize="large"
                            block
                            onClick={() => onProfileSelect(code)} style={{cursor: 'pointer'}}>
                        <Badge>{code}</Badge> {name}&nbsp;
                        {
                            agreement ?
                                agreement.agreement === 'Principal' ?
                                    <Badge>PR</Badge>
                                    :
                                    <Badge>PA</Badge>
                                : false

                        }
                    </Button>
                    <Panel id="collapsible-panel-example-2">
                        <Panel.Heading>
                            <Panel.Title toggle>
                                <span className="caret"/>
                                Users linked to the Profile
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Collapse>
                            <Panel.Body>
                                <ListGroup>
                                    {
                                        users.map(user =>
                                            <ListGroupItem key={user.id}>
                                                <Badge>{user.id}</Badge>&nbsp;
                                                {user.name ? user.name : 'No Name Provided'}
                                            </ListGroupItem>
                                        )
                                    }
                                </ListGroup>
                            </Panel.Body>
                        </Panel.Collapse>
                    </Panel>
                </div>
            </div>
        );
    }
}

export default (ProfileComponent);