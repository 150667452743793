import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function errorReducer(state = initialState.error, action) {
  switch (action.type) {
      case types.SHOW_RESPONSE_ERROR:
          return {...state, errorMessage: action.errorMessage};
      case types.CLEAR_RESPONSE_ERROR:
          return {...state, errorMessage: null};
    default:
      return state;
  }
}