import React from "react";
import _ from 'lodash'

const Entity = (props) => {
        const {entity, level, onSelect, searchTerm, selectedEntity, showSelectableOnly} = props;

        const hasChildren = _.size(entity.children) > 0;
        const currentLevel = hasChildren ? level + 1 : level;
        const childClass = "child" + level;
        const upperSearch = searchTerm ? searchTerm.toUpperCase() : '';
        const display = entity && (entity.tradingAs && entity.tradingAs.toUpperCase().includes(upperSearch) || entity.registeredName && entity.registeredName.toUpperCase().includes(upperSearch)) || entity.registrationNumber && entity.registrationNumber.includes(upperSearch);
        const isSelected = selectedEntity && (entity && entity.goldTierId) === (selectedEntity.goldTierId);
        const canBeSelected = entity && entity.authorizedToBind;
        return (
            <div>
                    {
                            (showSelectableOnly ? (canBeSelected && display) : display) &&
                            <div

                                className={canBeSelected ? (isSelected ? "selection-list-item-selected" : "selection-list-item") : "selection-list-item-disabled"}
                                onClick={() => canBeSelected && onSelect && onSelect(entity)}
                            >
                                    <div style={level > 0 ? {marginRight: 10, paddingLeft: 40} : {marginRight: 10}}>
                                            {
                                                    isSelected ?
                                                        <i
                                                            className="fa fa-check-circle"
                                                            style={{color: "rgb(0, 51, 170)", fontSize: 20}}
                                                        />
                                                        :
                                                        <i className="fa fa-circle-o"
                                                           style={{
                                                                   color: canBeSelected ? "rgb(0, 51, 170)" : "grey",
                                                                   fontSize: 20
                                                           }}
                                                        />
                                            }
                                    </div>

                        <div className={childClass}>
                        <span className="icon-small"><i aria-hidden="true"
                                                        className="fa fa-level-up fa-rotate-90"/></span>
                            <span
                                className={canBeSelected ? "active-entity" : ""}> {(entity.tradingAs || entity.registeredName)} | </span>
                            <span className="greyText"> {entity.registrationNumber} | {entity.country} </span>
                        </div>
                    </div>
                }

                {
                    hasChildren &&
                    <div>
                        {_.map(entity.children, (child, i) => (
                                <Entity
                                    entity={child}
                                    selectedEntity={selectedEntity}
                                    key={child.goldTierId + ', -' + i}
                                    level={currentLevel}
                                    onSelect={onSelect}
                                    searchTerm={searchTerm}
                                    showSelectableOnly={showSelectableOnly}
                                />
                            )
                        )}
                    </div>
                }
            </div>
        )
    }
;
export default Entity
