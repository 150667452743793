import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {downloadReport} from "../../actions/reports";
import {REPORT_TYPES} from "../ConfirmApplicationPage";


export const CompleteBolPage = (props) => {

    const businessOnlineApplication = useSelector(state => state.businessOnline && state.businessOnline);

    const dispatch = useDispatch();

    const handleOnDownloadApplication = (e) => {

        dispatch(downloadReport(REPORT_TYPES.BOL_PROFILE_ONBOARD, {'applicationId': businessOnlineApplication.id}, null, true));
    }


    return (
        <div className='container'>
            <div className="card-container-form">
                <div className="col-md-offset-3">
                    <div className="section-title">Business Online Profile Confirmation</div>
                    <div className="input-group greyText fontSize">
                        <p>Thank you, you have completed capturing for your new business online profile.</p>
                        <p>You will receive a notification once the profile has been approved/validated.</p>
                    </div>

                </div>
                <div className="action-button-container col-md-offset-3">
                    <button
                        className="action-btn-primary"
                        onClick={handleOnDownloadApplication}
                        type="submit"
                    >Download Application
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CompleteBolPage;
