import React, {useState} from "react";
import _ from "lodash";
import 'react-select/dist/react-select.css';
import '../../../styles/bancs.css';
import {managedFundRoutes} from '../../../routers/routes'
import {useDispatch, useSelector} from 'react-redux';
import FormField from "../../formField/FormField";
import Toggler from "../../Toggler";
import validate from 'validate.js';
import ButtonsComponent from '../../ButtonsComponent';
import FileUpload from "../../FileUpload";
import ExemptionDtdEx from "../../../resources/cashDocuments/Exemption-DTD-EX.pdf";
import {saveCustodyManagedFund} from "../../../actions/custodyManagedFund";
import {uploadDoc} from "../../../actions/kyc";

export const AccountRelationsPage = (props) => {

    const custodyRequest = useSelector(state => state.custodyManagedFund && state.custodyManagedFund.custodyManagedFundRequest);
    const appId = useSelector(state => state.custodyManagedFund && state.custodyManagedFund.id);
    const [accountRelations, setAccountRelations] = useState(custodyRequest.accountRelations);
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();

    const saveAndContinue = () => {
        const {history} = props;
        const onSuccess = () => history.push('/saveapplication');
        custodyRequest.accountRelations=accountRelations;
        dispatch(saveCustodyManagedFund(appId, custodyRequest, 'saveContinue', onSuccess));
    };

    const saveDetails = () => {
        // event.preventDefault();
        const {history} = props;
        if (validateForm()) {
            const onSuccess = () => history.push(managedFundRoutes.custodyBeneficialOwnerDetails);
            custodyRequest.accountRelations=accountRelations;
            dispatch(saveCustodyManagedFund(appId, custodyRequest, 'UserTask_Account_Relations', onSuccess));
        }
    };

    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    };


    const onHandleUpload = (base64, inProgress, onComplete, documentType) => {

        const onSuccess = (documentId, data) => {
            setAccountRelations({...accountRelations, documentId: documentId});

        };
        dispatch(uploadDoc(base64, onSuccess, null, inProgress, onComplete));
    };

    const validateForm = () => {

        console.log(accountRelations);

        const options = {fullMessages: false};

        let constraints = {
            legalEntityName: {presence: true, length: {minimum: 1, message: 'required'}},
            clientType: {presence: true, length: {minimum: 1, message: 'required'}}
        };

        if (accountRelations.withholdingTax || accountRelations.dividendWithholdingTax) {
            if (accountRelations.mirrorAccount) {
                constraints = _.extend({}, constraints, {
                    mirrorAccount: {
                        presence: true,
                        length: {minimum: 12, message: 'required'}
                    }
                });
            } else {
                constraints = _.extend({}, constraints, {
                    documentId: {
                        presence: true,
                        length: {minimum: 1, message: 'required'}
                    }
                });
            }
        }

        const errors = validate(accountRelations, constraints, options);
        setErrors(errors);
        return _.isEmpty(errors);
    };

    const renderFooter = () => {
        return (
                <ButtonsComponent
                    history={history}
                    onNext={saveDetails}
                    hidePrevious={managedFundRoutes.custodyAccount}
                    onSaveAndContinue={saveAndContinue}
                />
        );
    };

    return (
        <div className="page-container">
            <div className="page-main-section">
                <div className="card-container-form">
                    <div className="row">
                        <div className="col-md-8 col-md-offset-2">

                            <FormField className="form-group" id="legalEntityName"
                                       error={errors && errors.legalEntityName}>
                                <label htmlFor="legalEntityName">Full legal Entity Name</label>
                                <input
                                    className="form-control"
                                    id="legalEntityName"
                                    onChange={(event) => {
                                        setAccountRelations({...accountRelations, legalEntityName: event.target.value});
                                    }}
                                    type="text"
                                    value={accountRelations.legalEntityName ? accountRelations.legalEntityName : ""}
                                />
                            </FormField>

                            <div className="input-group fontSize">

                                <p><b>Kindly indicate in which capacity is this account being opened</b></p>
                            </div>

                            <FormField className="form-group" id="entityName"
                                       error={errors && errors.clientType && ['One option must be selected']}>
                                <div className="inline-container">
                                    <div className="icon-container" style={{width:'300px'}}
                                         onClick={(event) => {
                                             errors && errors.clientType && resetError('clientType');
                                             setAccountRelations({...accountRelations, clientType : 'Beneficiary Account'});
                                         }}>
                                        <i
                                            className={(accountRelations && accountRelations.clientType == 'Beneficiary Account' ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                            style={{fontSize: 20}}
                                            id="beneficiaryAccount"

                                        />
                                         Beneficiary Account
                                    </div>
                                   
                                </div>

                                {/*<div className="inline-container">
                                    <div className="icon-container"
                                         onClick={(event) => {
                                             handleOnChange(event, 'clientType', 'Broker/FSB nominee')
                                         }}>
                                        <i
                                            className={(custodyRequest.accountRelations && custodyRequest.accountRelations.clientType == 'Broker/FSB nominee' ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                            style={{fontSize: 20}}
                                            id="beneficiaryAccount"
                                        />
                                    </div>
                                    Broker nominee or FSB approved nominee account (If yes additional documentation to be
                                    provided)
                                </div>

                                <div className="inline-container"
                                     onClick={(event) => {
                                         handleOnChange(event, 'clientType', 'Foreign Nominee Account')
                                     }}>
                                    <i
                                        className={(custodyRequest.accountRelations && custodyRequest.accountRelations.clientType == 'Foreign Nominee Account' ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                        style={{fontSize: 20}}
                                        id="beneficiaryAccount"

                                        type="checkbox"

                                    />
                                    Foreign nominee account (If yes additional documentation to be provided)
                                </div>

                                <div className="inline-container"
                                     onClick={(event) => {
                                         handleOnChange(event, 'clientType', 'JSE Member')
                                     }}>
                                    <i
                                        className={(custodyRequest.accountRelations && custodyRequest.accountRelations.clientType == 'JSE Member' ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                        style={{fontSize: 20}}
                                        id="beneficiaryAccount"
                                    />
                                    JSE member (If yes additional documentation to be provided)
                                </div>

                                <div className="inline-container"
                                     onClick={(event) => {
                                         handleOnChange(event, 'clientType', 'Own Name Account')
                                     }}>
                                    <i
                                        className={(custodyRequest.accountRelations && custodyRequest.accountRelations.clientType == 'Own Name Account' ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                        style={{fontSize: 20}}
                                        id="beneficiaryAccount"
                                    />
                                    Own name account (proprietary account)
                                </div>

                                <div className="inline-container"
                                     onClick={(event) => {
                                         handleOnChange(event, 'clientType', 'Segregated Depository Account')
                                     }}>
                                    <i
                                        className={(custodyRequest.accountRelations && custodyRequest.accountRelations.clientType == 'Segregated Depository Account' ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                        style={{fontSize: 20}}
                                        id="beneficiaryAccount"
                                    />
                                    Segregated depository account (SDA) (If yes additional documentation to be provided)
                                </div>*/}
                            </FormField>
                            <br/>


                            <div className="input-group fontSize">
                                <p><b>Indicate the types of accounts/Standing Instructions required</b></p>
                            </div>

                            <div>
                                <Toggler
                                    label="Bond account (J code)"
                                    isOn={accountRelations && accountRelations.bondAccount}
                                    onToggle={() => {
                                        setAccountRelations({...accountRelations, bondAccount : !accountRelations.bondAccount});
                                    }}
                                />
                            </div>
                            <div>
                                <Toggler
                                    label="Money market (SOR) account"

                                    isOn={accountRelations && accountRelations.moneyMarket}
                                    onToggle={() => {
                                        setAccountRelations({...accountRelations, moneyMarket : !accountRelations.moneyMarket});
                                    }}
                                />

                            </div>
                            <div>
                                <Toggler
                                    label="Exempt from interest Withholding Tax"
                                    isOn={accountRelations && accountRelations.withholdingTax}
                                    onToggle={() => {
                                        setAccountRelations({...accountRelations, withholdingTax : !accountRelations.withholdingTax});
                                    }}
                                />
                            </div>
                            <div>
                                <Toggler
                                    label="Money market auto mandate required"
                                    isOn={accountRelations && accountRelations.moneyMarketAutoMandate}
                                    onToggle={() => {
                                        setAccountRelations({...accountRelations, moneyMarketAutoMandate : !accountRelations.moneyMarketAutoMandate});
                                    }}
                                />
                            </div>
                            <div>
                                <Toggler
                                    label="Exempt from Dividend Withholding Tax"
                                    isOn={accountRelations && accountRelations.dividendWithholdingTax}
                                    onToggle={() => {
                                        setAccountRelations({...accountRelations, dividendWithholdingTax : !accountRelations.dividendWithholdingTax});
                                    }}
                                />
                            </div>

                            {

                                accountRelations && accountRelations.moneyMarket ?
                                    <div className="input-group redText fontSize">
                                        <br/>
                                        <FormField className="form-group" id="participantId"
                                                   error={errors && errors.participantId}>
                                            <label htmlFor="participantId">Participant ID(If existing)</label>
                                            <input
                                                className="form-control"
                                                id="participantId"
                                                onChange={(event) => {
                                                    errors && errors.participantId && resetError('participantId');
                                                    setAccountRelations({...accountRelations, participantId : event.target.value});
                                                }}
                                                type="text"
                                                value={accountRelations.participantId ? accountRelations.participantId : ""}
                                            />
                                        </FormField></div>
                                    :
                                    ''
                            }

                            {
                                accountRelations && accountRelations.withholdingTax || accountRelations.dividendWithholdingTax ?
                                    <div className="input-group redText fontSize">
                                        <br/>
                                        <FormField className="form-group" id="mirrorAccount"
                                                   error={errors && errors.mirrorAccount && [' Enter valid mirror account or upload file']}>
                                            <label htmlFor="mirrorAccount">Mirror Account</label>
                                            <input
                                                className="form-control"
                                                id="mirrorAccount"
                                                onChange={(event) => {
                                                    errors && errors.mirrorAccount && resetError('mirrorAccount');
                                                    setAccountRelations({...accountRelations, mirrorAccount : event.target.value});
                                                }}
                                                type="text"
                                                value={accountRelations.mirrorAccount ? accountRelations.mirrorAccount : ""}
                                            />
                                        </FormField></div>
                                    :
                                    ''
                            }

                        </div>
                    </div>
                    <div className="row" style={{padding: "10px 15px"}}>
                        {accountRelations && accountRelations.withholdingTax || accountRelations.dividendWithholdingTax ?
                            <div className="col-md-8 col-md-offset-2 input-group redText fontSize">
                                <p>Please enter Mirror Account or complete the Withholding Tax
                                    (WHT) declaration form if this new account is exempt from Dividend Withholding Tax
                                    and/or Interest Withholding Tax</p>
                                <div className="row">

                                    <div className="col-md-3 col-md-offset-9" style={{padding: 0}}>
                                        <div className="flat-button-primary" variant="contained" color="primary">
                                            <a className="btn btn-secondary addButton" href={ExemptionDtdEx} download
                                               target={'_blank'}>
                                                <i className="fa fa-download fa-fw"/> Download</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <FileUpload className="col-md-8 col-md-offset-2"
                                                documentType="withholding"
                                                onUpload={(base64, inProgress, onComplete) => {
                                                    errors && errors.withholding && resetError('withholding');
                                                    onHandleUpload && onHandleUpload(base64, inProgress, onComplete, 'withholding');
                                                }}
                                        // onRemoveDocument={() => {
                                        //     errors && errors.proofOfAddress && onResetError('withholding');
                                        //     onHandleRemoveDocument && onHandleRemoveDocument('withholding');
                                        // }}
                                    />
                                </div>
                            </div>

                            : ''}
                    </div>
                </div>
            </div>

            <div className="page-footer-section" style={{width: "80%"}}>
                {renderFooter()}
            </div>
        </div>
    );
};
export default (AccountRelationsPage);
