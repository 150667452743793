import React from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import validate from 'validate.js'

import "../../styles/ConfirmApplication.css";
import "../../styles/Global.css";
import * as actions from '../../actions/custody';
import FormField from "../formField/FormField";
import { newCustodyAccountRoutes} from "../../routers/routes";
import ButtonsComponent from "../ButtonsComponent";
import {Panel, PanelGroup} from "react-bootstrap";
import Select from "react-select";
import { confirmKYC} from "../../actions/kyc";

const SETTLEMENT_NOTIFICATIONS = [
    {description: 'Receive free confirmation - RFP', code: "MT544"},
    {description: 'Receive versus payment confirmation - RVP', code: "MT545"},
    {description: 'Deliver free confirmation -DFP', code: "MT546"},
    {description: 'Deliver versus payment confirmation - DVP', code: "MT547"},
    {description: 'Settlement status and processing advice', code: "MT548"},
    {description: 'Settlement alledgement', code: "MT578"},
    {description: 'Advice on charges,interest,and other adjustments', code: "MT590"},
    {description: 'Customer statement message', code: "MT940"}
]


const STATEMENT_GENERATION = [
    {label: 'Statement of Holdings - MT535 ', value: "MT535"},
    {label: 'Statement of Transaction - MT536', value: "MT536"},
    {label: 'Statement of Pending Transactions - MT537', value: "MT537"}
]

const FREQUENCY = [
    {value: 'Daily', label: "Daily"},
    {value: 'Weekly', label: "Weekly"},
    {value: 'Monthly', label: "Monthly"}
]


const MT535_BASIS = [
    {value: 'None', label: "Not Applicable"},
    {value: 'Settled Position', label: "Settled Position"},
    {value: 'Traded Position', label: "Traded Position"}
]

const NOTIFICATION_OPTIONS = [
    {value: 'MAIN', label: "Instructing Party"},
    {value: 'ALTERNATE', label: "Alternate Instructing Party"},
    {value: 'BOTH', label: "Both"}
]

const STATEMENT_OPTIONS = [
    {value: 'MAIN', label: "Instructing Party"},
    {value: 'ALTERNATE', label: "Alternate Instructing Party"}
]

const MT537_BASIS = [
    {value: 'None', label: "Not Applicable"},
    {value: 'Status', label: "Status"},
    {value: 'Transaction Id', label: "Transaction Id"}]

const CA_NOTIFICATIONS = [
    {description: 'Acknowledgement', code: "MT567"},
    {description: 'Complimentary', code: "MT568"},
    {description: 'Entitlement Advice', code: "MT564"},
    {description: 'Event Announcement', code: "MT564"},
    {description: 'Pre-Announcement', code: "MT564"},
    {description: 'Change in Client Position', code: "MT564"},
    {description: 'Payment Advice', code: "MT566"},
    {description: 'Reminder Advice 1', code: "MT564"},
    {description: 'Reminder Advice 2', code: "MT564"},
    {description: 'Reminder Advice 3', code: "MT564"}
]


class InstructingPartyPage extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            errors: {}, swiftValid: false
        };

        this.handleOnChange = this._handleOnChange.bind(this);
        this.handleSwiftMessages = this._handleSwiftMessages.bind(this);
        this.handleOnSwiftInput = this._handleOnSwiftInput.bind(this);
    }

    _handleOnChange({target}, attribute, innerAttribute) {

        let value = _.trimStart(target.value);

        if (innerAttribute) {
            const {newCustodyProfile} = this.props;
            value = _.extend({}, newCustodyProfile && newCustodyProfile[attribute], innerAttribute ? {[innerAttribute]: value} : value)
        }
        this.props.action.onChangeValue('instructingParty', attribute, value);
    }

    _handleOnStatementChange(event, attribute, innerAttribute) {

        let value = event.value;

        if (innerAttribute) {
            const {newCustodyProfile} = this.props;
            if(innerAttribute === 'basis' && value ==='None'){
               console.log('im in')
                value = {}
            }else {
                value = _.extend({}, newCustodyProfile && newCustodyProfile.statementGeneration
                    && newCustodyProfile.statementGeneration[attribute], innerAttribute ? {[innerAttribute]: value} : value)
            }
        }
        this.props.action.onChangeValue('statementGeneration', attribute, value);
    }

    _handleOnNotificationChange(event, attribute, innerAttribute) {

        let value = event.value;

        if (innerAttribute) {
            const {newCustodyProfile} = this.props;
            value = _.extend({}, newCustodyProfile && newCustodyProfile.instructingParty
                && newCustodyProfile.instructingParty[attribute], innerAttribute ? {[innerAttribute]: value} : value)
        }
        this.props.action.onChangeValue('instructingParty', attribute, value);
    }

    _handleOnSwiftInput(event, attribute, innerAttribute) {

        event.preventDefault();
        let value = event.target && event.target.value;
        const {errors} = this.state;

        // const onSuccess = (valid) => this.setState({swiftValid: valid});
        const onSuccess = (valid) =>
            this.setState({errors: _.extend({}, errors, valid ? {[attribute]: null} : {[attribute]: 'Invalid Swift address'})})
        if(value) this.props.action.checkSwiftAddress(value, onSuccess);
    }

    _handleSwiftMessages(e, code) {

        const {newCustodyProfile} = this.props;
        let arr = newCustodyProfile.instructingParty && newCustodyProfile.instructingParty.swiftSubcriptions;
        if (arr) {
            if (arr.includes(code)) {
                arr = _.remove(arr, function (c) {
                    return c !== code;
                });
            } else {
                arr.push(code);
            }
        } else {
            arr = [code];
        }
        this.props.action.onChangeValue('instructingParty', 'swiftSubcriptions', arr);
    }

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    saveDetails = (event) => {
        event.preventDefault();
        const {history, appId} = this.props;
        const {errors} = this.state;

        if (this.validateForm()) {
            const onSuccess = () => history.push(newCustodyAccountRoutes.confirmauthorisers);
            const onFailure = () =>  this.setState({errors: _.extend({}, errors, {'swiftAddress': 'Invalid Swift address'})})
            this.props.action.confirmKYC(appId);
            this.props.action.saveCustodyAfterValidation(this.props.appId, this.props.newCustodyProfile, 'UserTask_InstructingParty', onSuccess, onFailure);
        }
    };

    saveAndContinue = (event) => {
        event.preventDefault();
        const {history} = this.props;
        const onSuccess = () => history.push('/saveapplication');
        this.props.action.saveCustody(this.props.appId, this.props.newCustodyProfile, 'saveContinue', onSuccess);
    };

    validateForm = () => {

        const {instructingParty} = this.props.newCustodyProfile;
        let errors=[];
        let swiftAddresses  = [];
        if(instructingParty.swiftAddress){
            swiftAddresses.push(instructingParty.swiftAddress);
        }
        if(instructingParty.alternateSwiftAddress){
            if(swiftAddresses.includes(instructingParty.alternateSwiftAddress)){
               errors =  _.extend({}, errors, {addresses: 'Swift addresses cannot be the same'})
            }
            swiftAddresses.push(instructingParty.alternateSwiftAddress);

        }

        if(instructingParty.commsAddress){
            if(swiftAddresses.includes(instructingParty.commsAddress)){
                this.setState({errors: _.extend({}, errors, {addresses: 'Swift addresses cannot be the same'})})
                return _.isEmpty(errors) ;
            }
            swiftAddresses.push(instructingParty.commsAddress);

        }

        if(instructingParty.alternateCommsAddress){
            if(swiftAddresses.includes(instructingParty.alternateCommsAddress)){
                this.setState({errors: _.extend({}, errors, {addresses: 'Swift addresses cannot be the same'})})
                return _.isEmpty(errors) ;
            }
        }
        this.setState({errors});
        return _.isEmpty(errors) ;
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={this.saveDetails}
                    type="button"
                >Complete
                </button>
            </div>
        )
    }

    render() {
        const {newCustodyProfile} = this.props;
        const {errors} = this.state;
        // const style = this.state.swiftValid? {borderColor: 'green' }: { borderColor: 'red'};
        console.log(errors)
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="card-container-form">

                        <div className="row">
                            <div className="col-md-3">
                                {/*instructing party*/}
                                <div className="section-title">
                                    <span>Instructing Party</span>
                                </div>

                                <FormField className="form-group" id="instructingPartyName"
                                           error={errors && errors.instructingPartyName}>
                                    <label htmlFor="instructingPartyName">Full legal name of Fund Manager</label>
                                    <input
                                        className="form-control"
                                        id="instructingPartyName"
                                        onChange={(event) => {
                                            this.handleOnChange(event, 'instructingPartyName')
                                        }}
                                        type="text"
                                    />
                                </FormField>
                                <FormField className="form-group" id="swiftAddress"
                                           error={errors && errors.swiftAddress && ["Invalid Swift Address"]}>
                                    <label htmlFor="swiftAddress">Instructing Party Swift Address </label>
                                    <input
                                        className="form-control"
                                        // style={style}
                                        onChange={(event) => {
                                            this.handleOnChange(event, 'swiftAddress')
                                        }}
                                        onBlur={(event) => {
                                            this.handleOnSwiftInput(event, 'swiftAddress')
                                        }}
                                        type="text"


                                        value={newCustodyProfile && newCustodyProfile.instructingParty && newCustodyProfile.instructingParty.swiftAddress}
                                    />

                                </FormField>
                                <FormField className="form-group" id="alternateSwiftAddress"
                                           error={errors && errors.alternateSwiftAddress && ["Invalid Swift Address"]}>
                                    <label htmlFor="alternateSwiftAddress">Alternate Instructing Party Swift Address</label>
                                    <input
                                        className="form-control"
                                        id="alternateSwiftAddress"
                                        onChange={(event) => {
                                            this.handleOnChange(event, 'alternateSwiftAddress')
                                        }}
                                        onBlur={(event) => {
                                            this.handleOnSwiftInput(event, 'alternateSwiftAddress')
                                        }}
                                        type="text"
                                        value={newCustodyProfile && newCustodyProfile.instructingParty && newCustodyProfile.instructingParty.alternateSwiftAddress}
                                    />

                                </FormField>
                                <FormField className="form-group" id="commsAddress"
                                           error={errors && errors.commsAddress && ["Invalid Swift Address"]}>
                                    <label htmlFor="commsAddress">Link to IS Online</label>
                                    <input
                                        className="form-control"
                                        id="commsAddress"
                                        onChange={(event) => {
                                            this.handleOnChange(event, 'commsAddress')
                                        }}
                                        onBlur={(event) => {
                                            this.handleOnSwiftInput(event, 'commsAddress')
                                        }}
                                        type="text"
                                        value={newCustodyProfile && newCustodyProfile.instructingParty && newCustodyProfile.instructingParty.commsAddress}

                                    />
                                </FormField>
                                {/* <FormField className="form-group" id="alternateCommsAddress"
                                           error={errors && errors.alternateCommsAddress && ["Invalid Swift Address"]}>
                                    <label htmlFor="alternateCommsAddress">Alternate Custody Comms Address </label>
                                    <input
                                        className="form-control"
                                        id="alternateCommsAddress"
                                        onChange={(event) => {
                                            this.handleOnChange(event, 'alternateCommsAddress')
                                        }}
                                        onBlur={(event) => {
                                            this.handleOnSwiftInput(event, 'alternateCommsAddress')
                                        }}
                                        type="text"
                                        value={newCustodyProfile && newCustodyProfile.instructingParty && newCustodyProfile.instructingParty.alternateCommsAddress}

                                    />
                                    </FormField> */}

                                <FormField className="form-group" id="addresses"
                                           error={errors && errors.addresses && ["Swift addresses cannot be the same"]}>
                                </FormField>

                            </div>
                            <div className="col-md-1"></div>

                            <div className="col-md-8">

                                <div className="section-title centerAlign">
                                    <span>Messaging and Reporting</span>
                                </div>

                                <PanelGroup accordion id="search-results">
                                    <Panel eventKey="1">
                                        <Panel.Heading>
                                            <Panel.Title toggle>Settlement Notifications</Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                            <div className="col-md-12">
                                            <div className="col-md-8">
                                                <b>Send instructions to : </b>
                                            </div>
                                            <div className="col-md-4">
                                                <Select
                                                    onChange={(event) => {
                                                        this._handleOnNotificationChange(event, 'notificationAddress');
                                                    }}
                                                    options={NOTIFICATION_OPTIONS}
                                                    value={newCustodyProfile && newCustodyProfile.instructingParty
                                                    && newCustodyProfile.instructingParty && newCustodyProfile.instructingParty.notificationAddress}
                                                />
                                            </div>
                                            </div>

                                            <div className="col-md-12"> <hr /> </div>


                                            {SETTLEMENT_NOTIFICATIONS.map((item, i) =>
                                                <div className="col-md-12">
                                                    <div className="col-md-8">
                                                        {item.description}
                                                    </div>
                                                    <div className="col-md-3">
                                                        {item.code}
                                                    </div>
                                                    <div className="col-md-1">
                                                        <input
                                                            type="checkbox"
                                                            checked={newCustodyProfile && newCustodyProfile.instructingParty && newCustodyProfile.instructingParty.swiftSubcriptions
                                                            && newCustodyProfile.instructingParty.swiftSubcriptions.includes(item.code)}
                                                            onChange={(event) => {
                                                                this.handleSwiftMessages(event, item.code)
                                                            }}

                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Panel.Body>
                                    </Panel>

                                    <Panel eventKey="2">
                                        <Panel.Heading>
                                            <Panel.Title toggle>Statement Generation</Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                            <div className="col-md-12">
                                                <div className="col-md-4">
                                                    <b>Statement</b>
                                                </div>

                                                <div className="col-md-3">
                                                    <b>Statement Basis</b>
                                                </div>
                                                <div className="col-md-2">
                                                    <b>Frequency</b>
                                                </div>
                                                <div className="col-md-3">
                                                    <b>Instructing Party</b>
                                                </div>

                                            </div>
                                            <br/><br/>
                                            <div className="col-md-12">
                                                <div className="col-md-4">
                                                    Statement Of Holding - MT535
                                                </div>
                                                <div className="col-md-3">
                                                    <Select
                                                        onChange={(event) => {
                                                            this._handleOnStatementChange(event, 'holdings', 'basis');
                                                        }}
                                                        options={MT535_BASIS}
                                                        value={newCustodyProfile && newCustodyProfile.statementGeneration
                                                        && newCustodyProfile.statementGeneration.holdings && newCustodyProfile.statementGeneration.holdings.basis}
                                                    />
                                                </div>
                                                <div className="col-md-2">
                                                    <Select
                                                        onChange={(event) => {
                                                            this._handleOnStatementChange(event, 'holdings', 'frequency');
                                                        }}
                                                        options={FREQUENCY}
                                                        value={newCustodyProfile && newCustodyProfile.statementGeneration
                                                        && newCustodyProfile.statementGeneration.holdings && newCustodyProfile.statementGeneration.holdings.frequency}/>
                                                </div>
                                                <div className="col-md-3">

                                                    <Select
                                                        onChange={(event) => {
                                                            this._handleOnStatementChange(event, 'holdings', 'selected');
                                                        }}
                                                        options={STATEMENT_OPTIONS}
                                                        value={newCustodyProfile && newCustodyProfile.statementGeneration
                                                        && newCustodyProfile.statementGeneration.holdings && newCustodyProfile.statementGeneration.holdings.selected}/>
                                                </div>

                                            </div>
                                            <br/> <br/>

                                            <div className="col-md-12">
                                                <div className="col-md-4">
                                                    Statement Of Transaction - MT536
                                                </div>
                                                <div className="col-md-3">
                                                    <Select
                                                        onChange={(event) => {
                                                            this._handleOnStatementChange(event, 'transactions', 'basis');
                                                        }}
                                                        options={MT535_BASIS}
                                                        value={newCustodyProfile && newCustodyProfile.statementGeneration
                                                        && newCustodyProfile.statementGeneration.transactions && newCustodyProfile.statementGeneration.transactions.basis}/>
                                                </div>
                                                <div className="col-md-2">
                                                    <Select
                                                        onChange={(event) => {
                                                            this._handleOnStatementChange(event, 'transactions', 'frequency');
                                                        }}
                                                        options={FREQUENCY}
                                                        value={newCustodyProfile && newCustodyProfile.statementGeneration
                                                        && newCustodyProfile.statementGeneration.transactions && newCustodyProfile.statementGeneration.transactions.frequency}/>
                                                </div>

                                                <div className="col-md-3">

                                                    <Select
                                                        onChange={(event) => {
                                                            this._handleOnStatementChange(event, 'transactions', 'selected');
                                                        }}
                                                        options={STATEMENT_OPTIONS}
                                                        value={newCustodyProfile && newCustodyProfile.statementGeneration
                                                        && newCustodyProfile.statementGeneration.transactions && newCustodyProfile.statementGeneration.transactions.selected}/>
                                                </div>

                                            </div>

                                            <br/><br/>

                                            <div className="col-md-12">
                                                <div className="col-md-4">
                                                    Statement Of Pending Transactions - MT537
                                                </div>
                                                <div className="col-md-3">
                                                    <Select
                                                        onChange={(event) => {
                                                            this._handleOnStatementChange(event, 'pending', 'basis');
                                                        }}
                                                        options={MT537_BASIS}
                                                        value={newCustodyProfile && newCustodyProfile.statementGeneration
                                                        && newCustodyProfile.statementGeneration.pending && newCustodyProfile.statementGeneration.pending.basis}/>
                                                </div>
                                                <div className="col-md-2">
                                                    <Select
                                                        onChange={(event) => {
                                                            this._handleOnStatementChange(event, 'pending', 'frequency');
                                                        }}
                                                        options={FREQUENCY}
                                                        value={newCustodyProfile && newCustodyProfile.statementGeneration
                                                        && newCustodyProfile.statementGeneration.pending && newCustodyProfile.statementGeneration.pending.frequency}/>
                                                </div>

                                                <div className="col-md-3">

                                                    <Select
                                                        onChange={(event) => {
                                                            this._handleOnStatementChange(event, 'pending', 'selected');
                                                        }}
                                                        options={STATEMENT_OPTIONS}
                                                        value={newCustodyProfile && newCustodyProfile.statementGeneration
                                                        && newCustodyProfile.statementGeneration.pending && newCustodyProfile.statementGeneration.pending.selected}/>
                                                </div>

                                            </div>
                                        </Panel.Body>
                                    </Panel>
                                    <Panel eventKey="3">
                                        <Panel.Heading>
                                            <Panel.Title toggle>Corporate Actions Notifications</Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body collapsible>
                                            <div className="col-md-12">
                                                <div className="col-md-8">
                                                    <b>Send instructions to : </b>
                                                </div>
                                                <div className="col-md-4">
                                                    <Select
                                                        onChange={(event) => {
                                                            this._handleOnNotificationChange(event, 'caNotificationAddress');
                                                        }}
                                                        options={NOTIFICATION_OPTIONS}
                                                        value={newCustodyProfile && newCustodyProfile.instructingParty
                                                        && newCustodyProfile.instructingParty && newCustodyProfile.instructingParty.caNotificationAddress}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-12"> <hr /> </div>


                                            {CA_NOTIFICATIONS.map((item, i) =>
                                                <div className="col-md-12">
                                                    <div className="col-md-8">
                                                        {item.description}
                                                    </div>
                                                    <div className="col-md-3">
                                                        {item.code}
                                                    </div>
                                                    <div className="col-md-1">
                                                        <input
                                                            type="checkbox"
                                                            checked={true}
                                                           disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </Panel.Body>
                                    </Panel>
                                </PanelGroup>

                            </div>

                        </div>

                    </div>
                </div>
                <div className="page-footer-section">
                    <ButtonsComponent
                        history={this.props.history}
                        onNext={this.saveDetails}
                        onSaveAndContinue={this.saveAndContinue}
                        prevPage={newCustodyAccountRoutes.accountcashpurpose}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps({custody}) {

    return {
        newCustodyProfile: custody.custodyRequest,
        appId: custody && custody.id,

    }
}

function mapDispatchToProps(dispatch) {
    return {action: bindActionCreators({...actions, confirmKYC}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(InstructingPartyPage);
