import React from 'react';
import {MenuItem, NavDropdown} from 'react-bootstrap';
import _ from 'lodash';

export default class UserMenu extends React.Component {
    render() {
        const {
            impersonation, impersonationList, rulesEnabled, user, onActOnBehalfOf, onShowImpersonationPopUp, onShowSupportPopUp,
            onCancelImpersonation, isSBStaff, onAllowUserToProceed, history, logout
        } = this.props;
        return (
            <NavDropdown
                eventKey={3}
                style={{ width: "100%" }}
                id="nav-dropdown"
                onClick={(e) => {
                    e.preventDefault();
                }}
                title={impersonation && (impersonation.username || impersonation.firstName) ? 'Acting on behalf of ' + (impersonation.username || impersonation.firstName) : 'Logged in as ' + user.firstName}
            >
                {
                    impersonationList.length > 0 &&
                    <MenuItem eventKey={3.1}>Act on Behalf of</MenuItem>
                }
                {
                    isSBStaff &&
                    <MenuItem
                        onClick={() => {
                            onShowImpersonationPopUp && onShowImpersonationPopUp();
                        }}
                        eventKey={3.7}
                    > <i className="fa fa-user fa-fw"/> Act On Behalf Of Client
                    </MenuItem>
                }

                {
                    isSBStaff && <MenuItem divider/>
                }

                {
                    isSBStaff &&
                    <MenuItem
                        onClick={() => {
                            onShowSupportPopUp && onShowSupportPopUp();
                        }}
                        eventKey={3.8}
                    > <i className="fa fa-edit fa-fw"/> Client Support
                    </MenuItem>
                }

                {
                    _.map(impersonationList, (i, index) => (
                        <MenuItem
                            eventKey={3.2}
                            href="#"
                            key={'imp-' + index}
                            onClick={(event) => onActOnBehalfOf(event, i)}
                        >
                            <i className="fa fa-user fa-fw"/>{i && (i.username || i.firstName)}
                        </MenuItem>

                        )
                    )
                }

                {
                    impersonationList && impersonationList.length > 0 && <MenuItem divider/>
                }

                {
                    impersonation && (impersonation.username || impersonation.firstName) &&
                    <MenuItem
                        eventKey={3.4}
                        href="#"
                        onClick={(event) => onCancelImpersonation(event, impersonation)}
                    >
                        <i className="fa fa-sign-out fa-fw"/>Back to my user
                    </MenuItem>
                }

                {
                    impersonation && (impersonation.username || impersonation.firstName) &&
                    <MenuItem divider/>
                }

                {!isSBStaff && <MenuItem
                    eventKey={3.3}
                    onClick={() => {
                        if (onAllowUserToProceed && onAllowUserToProceed()) {
                            history && history.push('/delegate');
                        }
                    }}
                >
                    <i className="fa fa-user-plus fa-fw"/>Delegate Authority
                </MenuItem>
                }
                {!!isSBStaff && <MenuItem divider/>}

                {!isSBStaff && rulesEnabled &&
                <MenuItem
                    eventKey={3.3}
                    onClick={() => {
                        if (onAllowUserToProceed && onAllowUserToProceed()) {
                            history && history.push('/rules');
                        }
                    }}
                >
                    <i className="fa fa-cogs fa-fw"/>Authorisation Rules
                </MenuItem>
                }

                {!isSBStaff && rulesEnabled &&
                <MenuItem divider/>}

                <MenuItem
                    eventKey={3.5}
                    onClick={(event) => { logout && logout(event); }}
                >
                    <i className="fa fa-sign-out fa-fw"> </i>Logout
                </MenuItem>
            </NavDropdown>
        );
    }
}
