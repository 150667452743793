import React from "react";
import * as actions from '../actions/user';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import HomePage from "./HomePage";
import moment from "moment";
import {Redirect} from "react-router-dom";
const queryString = require("query-string");

class TokenReceiver extends React.Component {

    componentWillMount() {
        this.receiveToken();
    }

    receiveToken() {
        const {actions,history} = this.props;
        const parsedHash = queryString.parse(this.props.location.search);
        localStorage.setItem('access_token', parsedHash.token);
        const expiresAt = moment().add(parsedHash.expires_in * 1000, 'milliseconds');
        localStorage.setItem('token_expires_at', expiresAt.valueOf());
        // actions.loadProfile();
        // history.replace("/");
        console.log('token saved...now redirecting')

    }

    render() {
        return  <Redirect to="/" />
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(null, mapDispatchToProps)(TokenReceiver);
