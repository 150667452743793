import React from "react";
import _ from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ReactLoading from 'react-loading';
import validate from 'validate.js'

import * as actions from '../../actions/entity';
import {newAccountOpeningRoutes} from '../../routers/routes'
import ButtonsComponent from "../ButtonsComponent";
import FormField from '../formField/FormField'
import {trackData} from "../../actions/analytics";

class EntityDetailsPage extends React.Component {

    constructor(props) {
        super(props);
        const typeOfEntity = props.selectedEntity && props.selectedEntity.typeOfEntity ? props.selectedEntity.typeOfEntity : 'Private Company (Pty LTD)';
        const selectedEntity = _.extend({}, props.selectedEntity, {typeOfEntity})
        this.state = {selectedEntity, detailsUpdated: false, addressUpdated: false, haveVatNumber: false, errors: {}};
        this.handleChange = this.handleChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.saveDetails = this.saveDetails.bind(this);
        this.resetError = this._resetError.bind(this);
    }

    componentDidMount() {
        this.props.actions.trackData('pageName', 'New Account| Confirm Details| Step 2');
    }

    componentWillReceiveProps(nextProps, prevProps) {
        this.setState({selectedEntity: nextProps.selectedEntity});
    }

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    _validateForm() {
        const {selectedEntity, haveVatNumber} = this.state;
        const options = {fullMessages: false};

        const constraints =_.extend(
            {
                registeredName: {presence: true, length: {minimum: 1, message: 'required'}},
                ...(haveVatNumber && {vatNumber: {presence: true, length: {minimum: 10, message: 'VAT Number should be 10 digits.'}}}),
            },
            selectedEntity.typeOfEntity === 'Commercial' ? {
                tradingAs: {presence: true, length: {minimum: 1, message: 'required'}}
            }: null
        );


        const addressConstraints = {
            street: {presence: true, length: {minimum: 1, message: 'required'},},
            city: {presence: true, length: {minimum: 1, message: 'required'}},
            province: {presence: true, length: {minimum: 1, message: 'required'}},
            postalCode: {presence: true, length: {minimum: 1, message: 'required'}},
            country: {presence: true, length: {minimum: 1, message: 'required'}},
            suburb: {presence: true, length: {minimum: 1, message: 'required'}}
        };


        const selectedEntityErrors = validate(selectedEntity, constraints, options);
        const addressErrors = validate(selectedEntity && selectedEntity.address, addressConstraints, options);
        const errors = _.extend({}, selectedEntityErrors, addressErrors);
        this.setState({errors});
        return _.isEmpty(errors);
    }

    saveAndContinue = (event) => {
        this.saveDetails(event, true)
    }

    saveDetails(event, saveAndContinue=false) {
        event.preventDefault();
        if (this._validateForm()) {
            const {actions, applicationId, history} = this.props;
            let nextPage = saveAndContinue ? '/saveapplication' :  newAccountOpeningRoutes.confirmproduct;
            const onProceed = () => history.push(nextPage);
            actions.updateEntity(this.state.selectedEntity, applicationId, saveAndContinue, onProceed);
        }
    }

    handleChange({target}, attribute) {
        const selectedEntity = _.extend({}, this.state.selectedEntity, {
                [attribute]: _.trimStart(target.value),
                detailsUpdated: true
            }
        );

        this.setState({selectedEntity});
    }

    handleAssetValueChange = (value, attribute) => {
        console.log(value);
        const selectedEntity = _.extend({}, this.state.selectedEntity, {
                [attribute]: value,
                detailsUpdated: true
            }
        );

        this.setState({selectedEntity});
    };

    handleAddressChange({target}, attribute) {
        const {selectedEntity} = this.state;
        const address = _.extend({}, selectedEntity.address, {[attribute]: _.trimStart(target.value)});
        const selectedEntityChanges = _.extend({}, selectedEntity, {address, addressUpdated: true});
        this.setState({selectedEntity: selectedEntityChanges});
    }

    allowVatNumber = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^0-9]/g, '');
       // Limit the input to a maximum of 10 numbers
      value = value.slice(0, 10);
    
    this.setState({haveVatNumber : value.length >= 1 && value.length < 10});
    event.target.value = value;    
    }

    render() {
        const {loading, history, systemError} = this.props;
        const {errors, selectedEntity} = this.state;
        if (systemError && systemError.show) return null;
        return (
            <div className="page-container">
                <EntityDetailsCard
                    errors={errors}
                    handleAddressChange={this.handleAddressChange}
                    loading={loading}
                    onChange={this.handleChange}
                    onChangeAssetValue={this.handleAssetValueChange}
                    onResetError={this.resetError}
                    selectedEntity={selectedEntity}
                    allowVatNumber={this.allowVatNumber}
                />
                <div className="page-footer-section">
                    <ButtonsComponent
                        history={history}
                        onNext={this.saveDetails}
                        hidePrevious={true}
                        prevPage={newAccountOpeningRoutes.newAccount}
                        onSaveAndContinue={this.saveAndContinue}
                    />
                </div>
            </div>
        );
    }
}

const EntityDetailsCard = (props) => {
    const {errors, handleAddressChange, loading, onChange, onChangeAssetValue, onResetError, selectedEntity, allowVatNumber} = props;
    if (!selectedEntity) return null;
    if (loading) return <ReactLoading type="spokes" color="#444"/>
    if (selectedEntity.typeOfEntity=='Commercial'){
    return (
    <div className="page-main-section">
                <div className="flexColumn">
                    <div className="card-container-form">
                        <div className="section-title">
                            <span>Entity Details</span>
                        </div>
                        <FormField className="form-group"  error={errors && errors.tradingAs}>
                            <label htmlFor="tradingAs">Trading As <small className="text-danger">*</small></label>
                            <input
                                type="text"
                                className="form-control"
                                id="tradingAs"
                                maxLength={25}
                                value={selectedEntity.tradingAs ? selectedEntity.tradingAs : ""}
                                onChange={(event) => { errors && errors.tradingAs && onResetError && onResetError('tradingAs');
                                                        onChange(event, 'tradingAs')
                                }}
                            />
                        </FormField>

                        <FormField className="form-group" id="regName" error={errors && errors.registeredName}>
                            <label htmlFor="registerdName">Registered Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="registeredName"
                                required={true}
                                value={selectedEntity.registeredName ? selectedEntity.registeredName :""}
                                onChange={(event) => {
                                    errors && errors.registeredName && onResetError && onResetError('registeredName');
                                    onChange(event, 'registeredName')
                                }}
                            />
                        </FormField>
                        <FormField className="form-group" id="vatNum" error={errors && errors.vatNumber}>
                            <label htmlFor="vatNumber">VAT Number <small className="text-danger">Optional</small></label>
                            <input
                                type="text"
                                className="form-control"
                                id="vatNumber"
                                value={selectedEntity.vatNumber ? selectedEntity.vatNumber : ""}
                                onChange={(event) => {
                                    allowVatNumber(event);
                                    onChange(event, 'vatNumber')
                                }}
                            />
                        </FormField>
                        <div className="form-group">
                            <label htmlFor="telephoneNumber">Telephone Number <small
                                className="text-danger">Optional</small></label>
                            <input
                                type="text"
                                className="form-control"
                                id="telephoneNumber"
                                value={selectedEntity.telephoneNumber ? selectedEntity.telephoneNumber : ""}
                                onChange={(event) => onChange(event, 'telephoneNumber')}
                            />
                        </div>

                        <FormField className="form-group" error={errors && errors.faisRequired}>
                            <label htmlFor="telephoneNumber">What is your total Net Asset Value?<small
                                className="text-danger">*</small></label>
                            <hr/>
                            <div
                                className="inline-container"
                                onClick={() => onChangeAssetValue(true, 'faisRequired')}
                            >
                                <div className="icon-container">
                                    <i className={(selectedEntity.faisRequired ? 'fa fa-check-circle-o' : 'fa fa-circle-o') + ' icon'}
                                       style={{fontSize: 20}}/>
                                </div>
                                Over 20 Million Rands
                            </div>
                            <div
                                className="inline-container"
                                onClick={() => onChangeAssetValue(false, 'faisRequired')}
                            >
                                <div className="icon-container">
                                    <i className={(selectedEntity.faisRequired != null && !selectedEntity.faisRequired ? 'fa fa-check-circle-o' : 'fa fa-circle-o') + ' icon'}
                                       style={{fontSize: 20}}/>
                                </div>
                                Under 20 Million Rands
                            </div>
                        </FormField>

                    </div>
                </div>

                <div className="flexColumn">
                    <div className="card-container-form">
                        <div className="section-title">
                            <span>Trading Address <small className="text-danger">Required</small></span>
                        </div>

                        <FormField className="form-group" id="regAddress" error={errors && errors.street}>
                            <label htmlFor="registeredAddressLin1">Address</label>
                            <input
                                className="form-control"
                                id="registeredAddressLine1"
                                onChange={(event) => {
                                    errors && errors.street && onResetError && onResetError('street');
                                    handleAddressChange(event, 'street')
                                }}
                                type="text"
                                value={(selectedEntity.address && selectedEntity.address.street) ? selectedEntity.address.street : ""}

                            />
                        </FormField>

                        <FormField className="form-group" id="line2" error={errors && errors.suburb}>
                            <label htmlFor="registeredAddressLine2">Suburb</label>
                            <input
                                className="form-control"
                                id="registeredAddressLine2"
                                onChange={(event) => {
                                    errors && errors.suburb && onResetError && onResetError('suburb');
                                    handleAddressChange(event, 'suburb')
                                }}
                                type="text"
                                value={(selectedEntity.address && selectedEntity.address.suburb) ? selectedEntity.address.suburb : ""}
                            />
                        </FormField>

                        <FormField className="form-group" id="regCity" error={errors && errors.city}>
                            <label htmlFor="registeredCity">City</label>
                            <input
                                type="text"
                                className="form-control"
                                id="registeredCity"
                                value={(selectedEntity.address && selectedEntity.address.city) ? selectedEntity.address.city : ""}
                                onChange={(event) => {
                                    errors && errors.city && onResetError && onResetError('city');
                                    handleAddressChange(event, 'city')
                                }}
                            />
                        </FormField>

                        <FormField className="form-group" id="regProvince" error={errors && errors.province}>
                            <label htmlFor="stateOrProvince">State/Province</label>
                            <input
                                type="text"
                                className="form-control"
                                id="stateOrProvince"
                                value={(selectedEntity.address && selectedEntity.address.province) ? selectedEntity.address.province : ""}
                                onChange={(event) => {
                                    errors && errors.province && onResetError && onResetError('province');
                                    handleAddressChange(event, 'province');
                                }}
                            />
                        </FormField>

                        <FormField className="form-group" id="regPostalCode" error={errors && errors.postalCode}>
                            <label htmlFor="stateOrProvince">Zip/Postal Code</label>
                            <input
                                type="text"
                                className="form-control"
                                id="registoredZipOrPostalCode"
                                value={(selectedEntity.address && selectedEntity.address.postalCode) ? selectedEntity.address.postalCode : ""}
                                onChange={(event) => {
                                    errors && errors.postalCode && onResetError && onResetError('postalCode');
                                    handleAddressChange(event, 'postalCode')
                                }}
                            />
                        </FormField>

                        <FormField className="form-group" id="regCountry" error={errors && errors.country}>
                            <label htmlFor="stateOrProvince">Country</label>
                            <input
                                type="text"
                                className="form-control"
                                id="country"
                                value={(selectedEntity.address && selectedEntity.address.country ) ? selectedEntity.address.country : ""}
                                onChange={(event) => {
                                    errors && errors.country && onResetError && onResetError('country');
                                    handleAddressChange(event, 'country')
                                }}
                            />
                        </FormField>
                    </div>
                </div>
            </div>
    );}
    else {
    return (
        <div className="page-main-section">
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        <span>Entity Details</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="tradingAs">Trading As <small className="text-danger">Optional</small></label>
                        <input
                            type="text"
                            className="form-control"
                            id="tradingAs"
                            value={selectedEntity.tradingAs ? selectedEntity.tradingAs : ""}
                            onChange={(event) => onChange(event, 'tradingAs')}
                        />
                    </div>

                    <FormField className="form-group" id="regName" error={errors && errors.registeredName}>
                        <label htmlFor="registerdName">Registered Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="registeredName"
                            required={true}
                            value={selectedEntity.registeredName ? selectedEntity.registeredName :""}
                            onChange={(event) => {
                                errors && errors.registeredName && onResetError && onResetError('registeredName');
                                onChange(event, 'registeredName')
                            }}
                        />
                    </FormField>

                    <FormField className="form-group" id="vatNum" error={errors && errors.vatNumber}>
                            <label htmlFor="vatNumber">VAT Number <small className="text-danger">Optional</small></label>
                            <input
                                type="text"
                                className="form-control"
                                id="vatNumber"
                                value={selectedEntity.vatNumber ? selectedEntity.vatNumber : ""}
                                onChange={(event) => {
                                    allowVatNumber(event);
                                    onChange(event, 'vatNumber')
                                }}
                            />
                    </FormField>

                    {/* <div className="form-group">
                        <label htmlFor="telephoneNumber">Telephone Number <small
                            className="text-danger">Optional</small></label>
                        <input
                            type="text"
                            className="form-control"
                            id="telephoneNumber"
                            value={selectedEntity.telephoneNumber ? selectedEntity.telephoneNumber : ""}
                            onChange={(event) => onChange(event, 'telephoneNumber')}
                        />
                    </div> */}

                    {/* <FormField className="form-group" error={errors && errors.faisRequired}>
                        <label htmlFor="telephoneNumber">What is your total Net Asset Value?<small
                            className="text-danger">*</small></label>
                        <hr/>
                        <div
                            className="inline-container"
                            onClick={() => onChangeAssetValue(true, 'faisRequired')}
                        >
                            <div className="icon-container">
                                <i className={(selectedEntity.faisRequired ? 'fa fa-check-circle-o' : 'fa fa-circle-o') + ' icon'}
                                   style={{fontSize: 20}}/>
                            </div>
                            Over 20 Million Rands
                        </div>
                        <div
                            className="inline-container"
                            onClick={() => onChangeAssetValue(false, 'faisRequired')}
                        >
                            <div className="icon-container">
                                <i className={(selectedEntity.faisRequired != null && !selectedEntity.faisRequired ? 'fa fa-check-circle-o' : 'fa fa-circle-o') + ' icon'}
                                   style={{fontSize: 20}}/>
                            </div>
                            Under 20 Million Rands
                        </div>
                    </FormField> */}

                </div>
            </div>

            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        <span>Registered Address{/* <small className="text-danger">Required</small> */}</span>
                    </div>

                    <FormField className="form-group" id="regAddress" error={errors && errors.street}>
                        <label htmlFor="registeredAddressLin1">Address</label>
                        <input
                            className="form-control"
                            id="registeredAddressLine1"
                            onChange={(event) => {
                                errors && errors.street && onResetError && onResetError('street');
                                handleAddressChange(event, 'street')
                            }}
                            type="text"
                            value={(selectedEntity.address && selectedEntity.address.street) ? selectedEntity.address.street : ""}
                            readOnly
                        />
                    </FormField>

                    <FormField className="form-group" id="line2" error={errors && errors.suburb}>
                        <label htmlFor="registeredAddressLine2">Suburb</label>
                        <input
                            className="form-control"
                            id="registeredAddressLine2"
                            onChange={(event) => {
                                errors && errors.suburb && onResetError && onResetError('suburb');
                                handleAddressChange(event, 'suburb')
                            }}
                            type="text"
                            value={(selectedEntity.address && selectedEntity.address.suburb) ? selectedEntity.address.suburb : ""}
                            readOnly                        
                        />
                    </FormField>

                    <FormField className="form-group" id="regCity" error={errors && errors.city}>
                        <label htmlFor="registeredCity">City</label>
                        <input
                            type="text"
                            className="form-control"
                            id="registeredCity"
                            value={(selectedEntity.address && selectedEntity.address.city) ? selectedEntity.address.city : ""}
                            onChange={(event) => {
                                errors && errors.city && onResetError && onResetError('city');
                                handleAddressChange(event, 'city')
                            }}
                            readOnly
                        />
                    </FormField>

                    <FormField className="form-group" id="regProvince" error={errors && errors.province}>
                        <label htmlFor="stateOrProvince">State/Province</label>
                        <input
                            type="text"
                            className="form-control"
                            id="stateOrProvince"
                            value={(selectedEntity.address && selectedEntity.address.province) ? selectedEntity.address.province : ""}
                            onChange={(event) => {
                                errors && errors.province && onResetError && onResetError('province');
                                handleAddressChange(event, 'province');
                            }}
                            readOnly
                        />
                    </FormField>

                    <FormField className="form-group" id="regPostalCode" error={errors && errors.postalCode}>
                        <label htmlFor="stateOrProvince">Zip/Postal Code</label>
                        <input
                            type="text"
                            className="form-control"
                            id="registoredZipOrPostalCode"
                            value={(selectedEntity.address && selectedEntity.address.postalCode) ? selectedEntity.address.postalCode : ""}
                            onChange={(event) => {
                                errors && errors.postalCode && onResetError && onResetError('postalCode');
                                handleAddressChange(event, 'postalCode')
                            }}
                            readOnly
                        />
                    </FormField>

                    <FormField className="form-group" id="regCountry" error={errors && errors.country}>
                        <label htmlFor="stateOrProvince">Country</label>
                        <input
                            type="text"
                            className="form-control"
                            id="country"
                            value={(selectedEntity.address && selectedEntity.address.country ) ? selectedEntity.address.country : ""}
                            onChange={(event) => {
                                errors && errors.country && onResetError && onResetError('country');
                                handleAddressChange(event, 'country')
                            }}
                            readOnly
                        />
                    </FormField>
                </div>
            </div>
        </div>
    );}
};

function mapStateToProps(state) {
    const {application, loading, systemError} = state;
    const hasLegalEntity = application.application && application.application.legalEntity;
    const address = hasLegalEntity && application.application.legalEntity.address ? application.application.legalEntity.address : {};
    return {
        applicationId: application.application && application.application.id ? application.application.id : '',
        selectedEntity: hasLegalEntity ? _.extend({}, application.application.legalEntity, {address}) : {},
        loading,
        systemError
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, trackData}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityDetailsPage);
