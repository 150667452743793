import React from "react";

const SaveApplicationPage = (props) => (
    <div className="page-container">
        <div className="page-main-section">
            <div className="flexColumn">
                <div className="card-container">
                    <div className="section-title">Application Saved</div>

            <p>Thank you!</p>
            <p>Your application has been saved and you can log in anytime to continue with your application.</p>
            <label style={{marginBottom: 20}}>If you have any enquiries, please contact your Relationship Manager.</label>
                </div>
            </div>
        </div>
    </div>
);

export default (SaveApplicationPage);