import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function impersonationReducer(state = initialState.impersonation, action) {
    switch (action.type) {
        case types.START_IMPERSONATION:
            return {...state, impersonation: action.impersonation};
        case types.STOP_IMPERSONATION:
            return {...state, impersonation: initialState.impersonation};
        default:
            return state;
    }
}