import React, {useState} from 'react';
import _ from 'lodash';
import {useDispatch} from 'react-redux';
import ReactLoading from 'react-loading';

const REPORT_TYPES = {
    ACCOUNT_SIGNATORIES: 'ACCOUNT_SIGNATORIES',
    APPLICATION_STATUS: 'APPLICATION_STATUS',
    CONFIRMATION_LETTER: 'CONFIRMATION_LETTER'
};
const STATUS_FORMATTED = {
    'REJECTED': 'Rejected',
    'PENDING_APPROVAL': 'Pending Approval',
    'CONCLUDED': 'Concluded',
    'OPEN': 'Open'
};

const APPLICATION_TYPE_FORMATTED = {
    'CHEQUE_ACCOUNT': 'Cheque Account',
    'ENTITY_ONBOARD': 'Entity Onboard',
    'CUSTODY_ACCOUNT': 'Custody Account',
    'GLOBAL_MARKET': 'Global Market Account',
    'EBTC': 'EBTC',
    'KYC_REVIEW': 'KYC Review',
    'BOL_ONBOARD': 'Business  Online Profile',
    'TPFA_ACCOUNTS': 'TPFA Account Application',
    'SIGNATORY_MAINTENANCE': 'Signatory Maintenance',
    'CASH_MANAGED_FUND': 'Managed Fund-Cash Account',
    'CUSTODY_MANAGED_FUND': 'Managed Fund-Custody Account',
    'CASH_CUSTODY_MANAGED_FUND': 'Managed Fund-Cash & Custody Account',
    'ELECTRONIC_INDEMNITY': 'Electronic Indemnity',
    'SHARIAH':'Shariah Business Current Account',
    'SAVINGS': 'Savings Account'
};
export const PandingApplication = (props) => {
    const {application, userLoggedIn, acceptApp, rejectApp } = props;
    const [isDownloading, setIsDownloading] = useState(false);
    const dispatch = useDispatch();
    
    console.log(props);
    const applicationId = application && application.applicationId;

    const applicationStatus = application && application.applicationStatus;
    // const legalEntity = application && application.legalEntityName;
    const showEntity = application && application.legalEntityName;

    const downloadReport = (e, reportName, requestBody) => {
        e.preventDefault();
        const {onDownloadReport, application} = props;
        let repoName = reportName;

        switch (application.dgbkApplicationType) {
            case 'TPFA_ACCOUNTS':
                repoName = 'TPFA_REPORT';
                break;
            case 'ELECTRONIC_INDEMNITY':
                repoName = 'INDEMNITY_REPORT';
                break;
            default:
                break;
        }

        setIsDownloading(true);
        const onComplete = () => setIsDownloading(false);
        dispatch(onDownloadReport(repoName, requestBody, onComplete));
    };

    
    
    return (
        <div className={props.styleClass}>
            <div className="flexColumn" style={{ flex: 1, alignItems: 'flex-start' }}>
                <div className="product-heading">
                    Please Select Your Response
                </div>
                <div className="title-gradient" />

                <div style={{ width: '100%', minHeight:'20vh'}}>
                    {
                        <div className="flexRow" style={{justifyContent: 'space-between', marginTop: '1em'}}>
                            {
                                !showEntity ?
                                    <div/>
                                    :
                                    <div>
                                        <p style={{
                                            fontSize: '1.24em',
                                            color: '#0A2240',
                                            marginBottom: 3
                                        }}>{showEntity}</p>
                                        <p style={{
                                            color: '#858D9D',
                                            textTransform: 'uppercase',
                                            letterSpacing: '1px',
                                            fontSize: 9
                                        }}>Entity</p>
                                    </div>
                            }
                            <div style={{textAlign: 'right'}}>
                                <p style={{fontSize: '1.24em', color: '#0A2240', marginBottom: 3}}>{application.applicationId}</p>
                                <p style={{
                                    color: '#858D9D',
                                    textTransform: 'uppercase',
                                    letterSpacing: '1px',
                                    fontSize: 9
                                }}>Application Reference</p>
                            </div>
                        </div>
                    }

                    <div className="flexRow" style={{justifyContent: 'space-between', marginTop: '1em'}}>
                        <div>
                            <p style={{
                                fontSize: '1.24em',
                                color: '#0A2240',
                                marginBottom: 3
                            }}>
                            {/* {STATUS_FORMATTED[applicationStatus && applicationStatus.applicationOutcome]} */}
                            {APPLICATION_TYPE_FORMATTED[application.dgbkApplicationType]}
                            </p>
                            <p style={{
                                color: '#858D9D',
                                textTransform: 'uppercase',
                                letterSpacing: '1px',
                                fontSize: 9
                            }}>Application</p>
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <p style={{fontSize: '1.24em', color: '#0A2240', marginBottom: 3, width:'120px'}}>
                                {new Date(application.createdDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true })}
                            </p>
                            <p style={{
                                color: '#858D9D',
                                textTransform: 'uppercase',
                                letterSpacing: '1px',
                                fontSize: 9
                            }}>Last Update</p>
                        </div>
                    </div>
                </div>

                {/* <div className="action-button-container" style={{ width: '100%' }}> */}
                <div className="btn-group dropup" style={{ width: '100%'}}>
                    <a className="btn btn-default p-10 mr-10" style={{cursor: 'pointer', borderRadius: '20px'}} onClick={(e) => downloadReport(e, REPORT_TYPES.APPLICATION_STATUS, {applicationId})}> <i className="fa fa-download fa-lg"  /> Application Report </a>
                    <a className="btn btn-success p-10 mr-10 pull-right" style={{marginLeft:'10px', borderRadius: '20px'}}
                           onClick={(event) => acceptApp(event, application.applicationId, userLoggedIn.identityNumber)}
                        role="button"
                    ><span> <i className="fa fa-check-circle-o fa-lg p-5" style={{margin:'0 5px',}}/> Approve   </span></a>
                    <a className="btn btn-danger p-10 ml-10 pull-right" style={{marginLeft:'10px', borderRadius: '20px'}}
                           onClick={(event) => rejectApp(event, application.applicationId, userLoggedIn.identityNumber)}
                        role="button"
                    ><span> <i className="fa fa-times-circle-o fa-lg " style={{margin:'0 5px'}} /> Decline   </span></a>
                </div>
            </div>
            {
                    isDownloading &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
        </div>
    )

};

export default PandingApplication;