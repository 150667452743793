import axios from 'axios';
import * as types from './actionTypes';
import {API_ROOT} from "../api-config";
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from "./appLoading";
import {newAccountOpeningRoutes, newCustodyAccountRoutes} from "../routers/routes";
import _ from "lodash";

const CUSTODY_APPLICATION_URL = API_ROOT + '/api/custody'
const CUSTODY_SWIFT_URL = API_ROOT + '/api/business-partner/swift-validation'

const CUSTODY_PENDING_TASKS = {
    accountRelations: "UserTask_AccountRelations",
    beneficialDetails: "UserTask_BeneficialDetails",
    addressDetails: "UserTask_AddressDetails",
    contactDetails: "UserTask_ContactDetails",
    cashAccount: "UserTask_CashAccount",
    instructingParty: "UserTask_InstructingParty",
    notification: "UserTask_Notification"
};


export function onChangeValue(objName, attribute, value) {
    return (dispatch) => {
        dispatch({type: types.ON_CHANGE_CUSTODY_VALUE, objName, attribute, value})
    }
}

export function checkSwiftAddress(swift, onProceed) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(CUSTODY_SWIFT_URL + '?swiftAddress=' + swift).then(response => response.data);

            if (responseData.success) {
                onProceed && onProceed(responseData.data);
            }
        } catch (error) {
            console.log("custody.startCustodyApplication.error ===> ", error);
        }
    };
}

export function onAddCashAccountForCustody(accounts) {

    return(dispatch) => {
        dispatch({type: types.ON_ADD_CASH_ACCOUNT_FOR_CUSTODY, cashAccounts: accounts})
    }

}

export function startCustodyApplication({cif}, goldtierId, impersonation, onProceed = null) {

    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.post(CUSTODY_APPLICATION_URL, {
                custodyEntity: {
                    cif,
                    goldtierId
                },
                impersonatedIdNumber : impersonation.identityNumber
            }).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.FETCH_CUSTODY_DATA, data: responseData.data});
                onProceed && onProceed();
            } else {
                console.log("entity.fetchSelectedEntity.errorMessage ===> ", responseData.message);
                const hasNoApprovalRules = responseData && responseData.message && responseData.message.includes('no auth rules');
                const hasInsufficientApprovalRules = responseData && responseData.message && responseData.message.includes('Not enough');
                const noBpInfo = responseData && responseData.message && responseData.message.includes('No BP');
                if (hasNoApprovalRules) {
                    onProceed && onProceed(false, 'Authorisation Rules Need To Be Configured');
                } else if (hasInsufficientApprovalRules) {
                    onProceed && onProceed(false, 'Not enough registered users as per Authorisation Rules settings. Please update the rules or ensure enough authorisers are registered');
                } 
                else if (noBpInfo) { 
                    onProceed && onProceed(false, 'No Business Partner information available for selected entity');
                } 
                else {
                    dispatch({
                        type: types.SHOW_RESPONSE_ERROR,
                        errorMessage: "Couldn't retrieve the selected entity details."
                    });
                }
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("custody.startCustodyApplication.error ===> ", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            onProceed && onProceed(false, 'No Business Partner information available for selected entity');
            loadingPageDataFinish(dispatch,);
        }
    };
}


export function completeCustodyApplication(id, notificationEmail, task, onComplete) {
    return async dispatch => {
        try {

            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            let responseData = null;
            responseData = await axios.put(CUSTODY_APPLICATION_URL + '/complete-task/' + id + '/' + task, custodyRequest).then(response => response.data);
            if (responseData.success) {
                onComplete && onComplete(true);
            }
        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
        }
    }
}

export function fetchSelectedCustodyRequest(id, history, onFinish = null) {
    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(`${CUSTODY_APPLICATION_URL}/${id}`).then(response => response.data);

            if (responseData.success) {

                const application = responseData.data;
                dispatch({type: types.FETCH_CUSTODY_DATA, data: application});

                const pendingTasks = application && application.applicationStatus && application.applicationStatus.pendingTasks;

                let path = "/"

                if (_.find(pendingTasks, task => (task.name === CUSTODY_PENDING_TASKS.accountRelations))) {
                    path = newCustodyAccountRoutes.accountrelations;
                } else if (_.find(pendingTasks, task => (task.name === CUSTODY_PENDING_TASKS.beneficialDetails))) {
                    path = newCustodyAccountRoutes.beneficialowner;
                } else if (_.find(pendingTasks, task => (task.name === CUSTODY_PENDING_TASKS.addressDetails))) {
                    path = newCustodyAccountRoutes.addressdetails;
                } else if (_.find(pendingTasks, task => (task.name === CUSTODY_PENDING_TASKS.contactDetails))) {
                    path = newCustodyAccountRoutes.contactdetails;
                } else if (_.find(pendingTasks, task => (task.name === CUSTODY_PENDING_TASKS.cashAccount))) {
                    path = newCustodyAccountRoutes.accountcashpurpose;
                } else if (_.find(pendingTasks, task => (task.name === CUSTODY_PENDING_TASKS.instructingParty))) {
                    path = newCustodyAccountRoutes.instructingparty;
                } else if (_.find(pendingTasks, task => (task.name === CUSTODY_PENDING_TASKS.notification))) {
                    path = newCustodyAccountRoutes.confirmauthorisers;
                }else {
                    path = newCustodyAccountRoutes.complete;
                }

                history.push(path);
            } else {
                console.log("entity.fetchSelectedOnboardRequest.errorMessage ====>", responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: "Couldn't retrieve selected custody application."
                });
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function saveCustody(id, custodyRequest, task, onComplete, email = null) {
    return async dispatch => {
        try {

            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            let responseData = null;
            if(email ===null){
                responseData = await axios.put(CUSTODY_APPLICATION_URL + '/complete-task/' + id + '/' + task, custodyRequest).then(response => response.data);
            }else{
                responseData = await axios.put(CUSTODY_APPLICATION_URL + '/complete-task/' + id + '/' + task+'?email='+ email, custodyRequest).then(response => response.data);
            }
            if (responseData.success) {
                onComplete && onComplete(true);
            }
        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
        }
    }
}

export function saveCustodyAfterValidation(id, custodyRequest, task, onComplete, onFailure) {
    return async dispatch => {
        try {

            const {instructingParty} = custodyRequest;
            let responseData = '';
            if(instructingParty.swiftAddress){
                 responseData = await axios.get(CUSTODY_SWIFT_URL + '?swiftAddress=' + instructingParty.swiftAddress).then(response => response.data);
                 if(!responseData.success || !responseData.data) { onFailure();return;}
            }
            if(instructingParty.alternateSwiftAddress){
                responseData = await axios.get(CUSTODY_SWIFT_URL + '?swiftAddress=' + instructingParty.alternateSwiftAddress).then(response => response.data);
                if(!responseData.success || !responseData.data) { onFailure()}

            }

            if(instructingParty.commsAddress){
                responseData = await axios.get(CUSTODY_SWIFT_URL + '?swiftAddress=' + instructingParty.commsAddress).then(response => response.data);
                if(!responseData.success || !responseData.data) { onFailure()}

            }

            if(instructingParty.alternateCommsAddress){
                responseData = await axios.get(CUSTODY_SWIFT_URL + '?swiftAddress=' + instructingParty.alternateCommsAddress).then(response => response.data);
                if(!responseData.success || !responseData.data) { onFailure()}

            }

            if (responseData.success && responseData.data) {
                responseData = await axios.put(CUSTODY_APPLICATION_URL + '/complete-task/' + id + '/' + task, custodyRequest).then(response => response.data);
                onComplete(true);
            }


        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
        }
    }
}
