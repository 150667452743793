import React from "react";
import _ from "lodash";
import validate from "validate.js";
import Select from 'react-select';
import 'react-select/dist/react-select.css';

import FormField from "../formField/FormField";
import {LEVELS} from './ReleaseLevelsAndLimits';

export default class UserAccountAccessPaths extends React.Component {
    state = {
        form: {
            paymentsAndCollections: false,
            interAccountTransfers: false,
            balanceStatements: false,
            level: '',
            limit: ''
        },
        errors: {}
    };

    handleOnChange = ({target}, attribute) => {
        const value = target.value;
        const form = _.extend({}, this.state.form, {[attribute]: value});
        this.setState({form})
    };

    handleSelectChange = (event, attribute, innerAttribute, isCheckbox = true) => {
        const {form} = this.state;
        let value = isCheckbox ? event.target.checked : event.value;
        if (innerAttribute) {
            value = _.extend({}, form && form[attribute], {[innerAttribute]: value});
        }

        const updateForm = _.extend({}, form, {[attribute]: value});
        this.setState({form: updateForm});
    };

    validateForm = () => {
        const {form} = this.state;
        const options = {fullMessages: false};

        const constraints = {
            accountNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            level: {presence: true, numericality: true, length: {minimum: 1, message: 'required'}},
            limit: {presence: true, numericality: true, length: {minimum: 1, message: 'required'}},
        };

        const errors = validate(form, constraints, options);

        this.setState({errors});
        return _.isEmpty(errors);
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            const {onSubmit} = this.props;
            onSubmit && onSubmit(this.state.form);
        }
    };

    onResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    _renderHeader() {
        return (
            <div>
                <div className="product-heading">
                    Limited Access
                </div>
                <div className="title-gradient"/>
            </div>
        )
    }

    _renderFooter() {
        return (
            <div className="action-button-container" style={{width: "100%"}}>
                <div/>
                <button
                    className="action-btn-secondary"
                    onClick={this.handleSubmit}
                    type="submit"
                >Add
                </button>
            </div>
        )
    }

    _renderAccountSelection(){
        const {accounts} = this.props;
        const {form, errors} = this.state;
        const accountsOptions = _.map(accounts, (o) => ({
            value: `${o.accountNumber}`,
            label: `${o.name} | ${o.accountNumber}`,
            id: o.accountNumber
        }));
        return(
            <FormField className="form-group"
                       error={errors && errors.accountNumber ? ["One Should Be Selected"] : ""}>
                <label htmlFor="accountNumber">Account</label>
                <Select
                    name="accountNumber"
                    onChange={(event) => {
                        errors && errors.accountNumber && this.onResetError('accountNumber');
                        this.handleSelectChange(event, 'accountNumber', null, false);
                    }}
                    options={accountsOptions}
                    value={form && form.accountNumber || ''}
                />
            </FormField>
        )
    }

    _renderAccessSection(){
        const {form} = this.state;
        return(
            <div>
                <div className="section-title">
                    <span>Account Access</span>
                </div>

                <div className="flexColumn" style={{alignItems: "flex-start"}}>
                    <label className="checkbox-inline" style={{marginLeft: 10}}>
                        <input
                            onChange={(event) => {
                                this.handleSelectChange(event, 'balanceStatements');
                            }}
                            type="checkbox"
                            value={form && form.balanceStatements || ''}
                        />Balance And Statements
                    </label>

                    <label className="checkbox-inline" style={{marginLeft: 10}}>
                        <input
                            onChange={(event) => {
                                this.handleSelectChange(event, 'interAccountTransfers');
                            }}
                            type="checkbox"
                            value={form && form.interAccountTransfers || ''}
                        />Inter-Account Transfers
                    </label>

                    <label className="checkbox-inline" style={{marginLeft: 10}}>
                        <input
                            onChange={(event) => {
                                this.handleSelectChange(event, 'paymentsAndCollections');
                            }}
                            type="checkbox"
                            value={form && form.paymentsAndCollections || ''}
                        /> Payments/Collections
                    </label>
                </div>
            </div>
        )
    }
    _renderLimitsSection() {
        const {form, errors} = this.state;
        return (
            <div>
                <div className="section-title">
                    <span>Release Level And Limit</span>
                </div>

                <FormField className="form-group" error={errors && errors.level}>
                    <label htmlFor="level">Level</label>
                    <Select
                        name="levels"
                        onChange={(event) => {
                            errors && errors.level && this.handleResetError('level');
                            this.handleSelectChange(event, 'level', null, false)
                        }}
                        options={LEVELS}
                        value={form && form.level ? form.level : ''}
                    />
                </FormField>

                <FormField className="form-group" error={errors && errors.limit}>
                    <label htmlFor="level">Limit in USD</label>
                    <input
                        className="form-control"
                        onChange={(event) => {
                            errors && errors.limit && this.handleResetError('limit');
                            this.handleOnChange(event, 'limit');
                        }}
                        type="text"
                        value={form && form.limit ? form.limit : ''}
                    />
                </FormField>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this._renderHeader()}
                <div className="card-container-form">
                    {this._renderAccountSelection()}
                    {this._renderAccessSection()}
                    {this._renderLimitsSection()}
                </div>
                {this._renderFooter()}
            </div>
        )
    }
}