import React, {useState} from "react";
import _ from "lodash";
import {connect, useDispatch, useSelector} from "react-redux";
import {bindActionCreators} from "redux";
import validate from 'validate.js'
import "../../../styles/ConfirmApplication.css";
import "../../../styles/Global.css";
import * as actions from '../../../actions/custody';
import FormField from "../../formField/FormField";
import {managedFundRoutes} from "../../../routers/routes";
import ButtonsComponent from "../../ButtonsComponent";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import {saveCustodyManagedFund} from "../../../actions/custodyManagedFund";

const labelClassList = "col-xs-12 col-sm-4 col-md-4";
const inputClassList = "col-xs-12 col-sm-8 col-md-8";


const CONTACT_METHOD = [
    {value: 'EMAIL', label: "EMAIL"},
    {value: 'TEL', label: "TELEPHONE"}
]


export const ContactDetailsPage = (props) => {

    const custodyRequest = useSelector(state => state.custodyManagedFund && state.custodyManagedFund.custodyManagedFundRequest);
    const appId = useSelector(state => state.custodyManagedFund && state.custodyManagedFund.id);
    const [beneficialContactDetails, setBeneficialContactDetails] = useState(custodyRequest.beneficialContactDetails);
    const [bndContactDetails, setBndContactDetails] = useState(custodyRequest.bndContactDetails);
    const [sameAsBnd, setSameAsBnd] = useState(false);
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();


    const handleBNDContactSame = (isSame = false) => {

        setSameAsBnd(isSame);
        if (isSame) {
            setBndContactDetails({...beneficialContactDetails})
        }


    }

    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    }

    const validateForm = () => {
        const options = {fullMessages: false};

        const benefialConstraints = {
            modeOfCorrespondence: {presence: true},
            contactName: {presence: true, length: {minimum: 1, message: 'required'}},
            emailAddress: {presence: true, email:true, length: {minimum: 1, message: 'required'}},
            telephoneNumber: {presence: true, length: {minimum: 1, message: 'required'}}
        };

        const bndConstraints = {
            modeOfCorrespondence: {presence: true},
            contactName: {presence: true, length: {minimum: 1, message: 'required'}},
            emailAddress: {presence: true, email:true, length: {minimum: 1, message: 'required'}},
            telephoneNumber: {presence: true, length: {minimum: 1, message: 'required'}}
        };

        const beneficialErrors = validate(beneficialContactDetails, benefialConstraints, options);
        const bndErrors = validate(bndContactDetails, bndConstraints, options);

        const errors = _.extend({}, {beneficialErrors}, {bndErrors});
        setErrors(errors);
        return _.isEmpty(beneficialErrors) && _.isEmpty(bndErrors);
    };

    const saveDetails = (event) => {
        // event.preventDefault();
        const {history} = props;
        if (validateForm()) {
            const onSuccess = () => history.push(managedFundRoutes.custodyCashAccountPurpose);
            custodyRequest.beneficialContactDetails = beneficialContactDetails;
            custodyRequest.bndContactDetails = bndContactDetails;
            dispatch(saveCustodyManagedFund(appId, custodyRequest, 'UserTask_ContactDetails', onSuccess));
        }
    };

    const saveAndContinue = (event) => {
        const {history} = props;
        const onSuccess = () => history.push('/saveapplication');
        custodyRequest.beneficialContactDetails = beneficialContactDetails;
        custodyRequest.bndContactDetails = bndContactDetails;
        dispatch(saveCustodyManagedFund(appId, custodyRequest, 'saveContinue', onSuccess));
    };

    return (
        <div className="page-container">
            <div className="page-main-section">

                <div className="col-xs-12">
                    <div className="card-container-form">
                        <div className="section-title">
                            <span>Contact Details Beneficial Owner </span>
                        </div>
                        <div className="form-horizontal">
                            <div className="row">
                                <div className="col-md-6">
                                    <FormField className="form-group" id="contactName"
                                               error={errors && errors.beneficialErrors && errors.beneficialErrors.contactName}>
                                        <label className={labelClassList} htmlFor="province">Contact Name</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="contactName"
                                                onChange={(event) => {
                                                    const validName = event.target.value;
                                                   event.target.value = validName.replace(/[^A-Za-z-]+/g, '');
                                                    setBeneficialContactDetails({...beneficialContactDetails, contactName: event.target.value});
                                                }}
                                                type="text"
                                                value={beneficialContactDetails.contactName? beneficialContactDetails.contactName: ''}
                                            />
                                        </div>
                                    </FormField>
                                    <FormField className="form-group" id="telephoneNumber"
                                               error={errors && errors.beneficialErrors && errors.beneficialErrors.telephoneNumber}>
                                        <label className={labelClassList} htmlFor="province">Telephone Number</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="telephoneNumber"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && resetError('beneficialErrors');
                                                    setBeneficialContactDetails({...beneficialContactDetails, telephoneNumber: event.target.value});

                                                }}
                                                type="number"
                                                value={beneficialContactDetails.telephoneNumber ? beneficialContactDetails.telephoneNumber : ''}
                                            />
                                        </div>
                                    </FormField>
                                    <FormField className="form-group" id="alternateTelephoneNumber"
                                               error={errors && errors.beneficialErrors && errors.beneficialErrors.alternateTelephoneNumber}>
                                        <label className={labelClassList} htmlFor="province">Alternate Telephone
                                            Number</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="alternateTelephoneNumber"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && resetError('alternateTelephoneNumber');
                                                    setBeneficialContactDetails({...beneficialContactDetails, alternateTelephoneNumber: event.target.value});
                                                }}
                                                type="number"
                                                value={beneficialContactDetails.alternateTelephoneNumber ? beneficialContactDetails.alternateTelephoneNumber : ''}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                                <div className="col-md-6">
                                    <FormField className="form-group" id="modeOfCorrespondence"
                                               error={errors && errors.beneficialErrors && errors.beneficialErrors.modeOfCorrespondence}>
                                        <label className={labelClassList} htmlFor="province">Primary Mode Of
                                            Correspondence</label>
                                        <div className={inputClassList}>
                                            <Select
                                                name="modeOfCorrespondence" style={{marginBottom:'10px'}}
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && resetError('beneficialErrors');
                                                    setBeneficialContactDetails({...beneficialContactDetails, modeOfCorrespondence: event.value});
                                                }}
                                                options={CONTACT_METHOD}
                                                value={beneficialContactDetails.modeOfCorrespondence ? beneficialContactDetails.modeOfCorrespondence : ''}
                                            />
                                        </div>
                                    </FormField>
                                    <FormField className="form-group" id="emailAddress"
                                               error={errors && errors.beneficialErrors && errors.beneficialErrors.emailAddress}>
                                        <label className={labelClassList} htmlFor="emailAddress">Email Address</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="emailAddress"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && resetError('beneficialErrors');
                                                    setBeneficialContactDetails({...beneficialContactDetails, emailAddress: event.target.value});
                                                }}
                                                type="email"
                                                value={beneficialContactDetails.emailAddress ? beneficialContactDetails.emailAddress : ''}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                            </div>

                            <div className="col-md-12 form-group">

                                <div className="inline-container">
                                    <div className="icon-container" style={{width:'50%'}}
                                         onClick={(event) => {
                                             handleBNDContactSame(!sameAsBnd);
                                         }}>
                                        <i className={(sameAsBnd ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                           style={{fontSize: 20}}
                                           id="beneficiarySameAddress"
                                        />BND Contact Same As Beneficial Owner Contact
                                    </div>
                                    
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 form-group">

                            <div className="section-title">
                                <span>Contact Details BND </span>
                            </div>
                        </div>
                        <div className="form-horizontal">

                            <div className="row">
                                <div className="col-md-6">
                                    <FormField className="form-group" id="contactName"
                                               error={errors && errors.bndErrors && errors.bndErrors.contactName}>
                                        <label className={labelClassList} htmlFor="province">Contact Name</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="contactName"
                                                onChange={(event) => {
                                                    const validName = event.target.value;
                                                    event.target.value = validName.replace(/[^A-Za-z-]+/g, '');
                                                    errors && errors.beneficialErrors && resetError('bndErrors');
                                                    setBndContactDetails({...bndContactDetails, contactName: event.target.value});
                                                }}
                                                type="text"
                                                value={bndContactDetails.contactName ? bndContactDetails.contactName : ''}
                                                disabled={sameAsBnd}
                                            />
                                        </div>
                                    </FormField>

                                    <FormField className="form-group" id="telephoneNumber"
                                               error={errors && errors.bndErrors && errors.bndErrors.telephoneNumber}>
                                        <label className={labelClassList} htmlFor="province">Telephone Number</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="telephoneNumber"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && resetError('bndErrors');
                                                    setBndContactDetails({...bndContactDetails, telephoneNumber: event.target.value});
                                                }}
                                                type="number"
                                                value={bndContactDetails.telephoneNumber ? bndContactDetails.telephoneNumber : ''}
                                                disabled={sameAsBnd}
                                            />
                                        </div>
                                    </FormField>

                                    <FormField className="form-group" id="alternateTelephoneNumber"
                                               error={errors && errors.bndErrors && errors.bndErrors.alternateTelephoneNumber}>
                                        <label className={labelClassList} htmlFor="province">Alternate Telephone
                                            Number</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="alternateTelephoneNumber"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && resetError('bndErrors');
                                                    setBndContactDetails({...bndContactDetails, alternateTelephoneNumber: event.target.value});
                                                }}
                                                type="number"
                                                value={bndContactDetails.alternateTelephoneNumber ? bndContactDetails.alternateTelephoneNumber : ''}
                                                disabled={sameAsBnd}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                                <div className="col-md-6">
                                    <FormField className="form-group" id="modeOfCorrespondence"
                                               error={errors && errors.bndErrors && errors.bndErrors.modeOfCorrespondence}>
                                        <label className={labelClassList} htmlFor="province">Primary Mode Of
                                            Correspondence</label>
                                        <div className={inputClassList}>
                                            <Select
                                                name="modeOfCorrespondence" style={{marginBottom:'10px'}}
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && resetError('bndErrors');
                                                    setBndContactDetails({...bndContactDetails, modeOfCorrespondence: event.value});
                                                }}
                                                options={CONTACT_METHOD}
                                                disabled={sameAsBnd}
                                                value={bndContactDetails.modeOfCorrespondence ?bndContactDetails.modeOfCorrespondence : ''}
                                            />
                                        </div>
                                    </FormField>
                                    <FormField className="form-group" id="emailAddress"
                                               error={errors && errors.bndErrors && errors.bndErrors.emailAddress}>
                                        <label className={labelClassList} htmlFor="province">Email Address</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="emailAddress"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && resetError('bndErrors');
                                                    setBndContactDetails({...bndContactDetails, emailAddress: event.target.value});
                                                }}
                                                type="text"
                                                value={bndContactDetails.emailAddress ? bndContactDetails.emailAddress : ''}
                                                disabled={sameAsBnd}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div className="page-footer-section" style={{width: '80%'}}>
                <ButtonsComponent
                    history={props.history}
                    onNext={saveDetails}
                    onSaveAndContinue={saveAndContinue}
                    prevPage={managedFundRoutes.custodyAddressDetails}
                />
            </div>
        </div>
    );
};


export default (ContactDetailsPage);
