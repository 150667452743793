import axios from 'axios';
import _ from 'lodash';
import * as types from './actionTypes';
import {API_ROOT} from "../api-config";
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from "./appLoading";

const MANAGEMENT_ADDITIONAL_PRODUCTS_URL = API_ROOT + 'api/management/additional-products';
const MANAGEMENT_RESOURCES_URL = API_ROOT + 'api/management/application-resource';
const MANAGEMENT_APPROVAL_MESSAGES_URL = API_ROOT + '/api/management/approval-messages';

const CORPORATES_URL = API_ROOT + '/api/gt-corporates';
const AUTHORISATION_RULES_URL = API_ROOT + '/api/authorisation';

export function searchEntityByCIF(cif, onSuccess) {
    const errorMessage = "Couldn't find Entity [by CIF:" + cif + "]";
    return searchEntity("cif=" + cif, null, errorMessage, onSuccess);
}

export function searchEntityByGoldTierID(goldTierId, onSuccess) {
    const errorMessage = "Couldn't find Entity [by GoldTier ID:" + goldTierId + "]";
    return searchEntity("gtid=" + goldTierId, goldTierId, errorMessage, onSuccess);
}

export function searchEntityBySalesforceID(salesforceId, onSuccess) {
    const errorMessage = "Couldn't find Entity [by Salesforce ID:" + salesforceId + "]";
    return searchEntity("sfid=" + salesforceId, null, errorMessage, onSuccess);
}

export function searchEntityByRegNum(regNum, onSuccess) {
    const errorMessage = "Couldn't find Entity [by Registration Number:" + regNum + "]";
    return searchEntity("regnum=" + regNum, null, errorMessage, onSuccess);
}

function searchEntity(queryString, gtid, errorMessage, onSuccess) {
    return async dispatch => {

        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');

            const responseData = await axios.get(`${CORPORATES_URL}?${queryString}`).then(response => response.data);

            if (responseData.success) {
                const goldTierId = gtid ? gtid : (responseData.data[0] && responseData.data[0].goldTierId);

                const authorisedPersons = await axios.get(`${CORPORATES_URL}/${goldTierId}/authorized`).then(response => response.data);
                const authorisationRules = await axios.get(`${AUTHORISATION_RULES_URL}/${goldTierId}`).then(response => response.data);

                dispatch({
                    type: types.SUPPORT_SEARCH,
                    support: responseData.data,
                    authorisedPersons: authorisedPersons.data,
                    authorisationRules: authorisationRules.data,
                    errorMessage: null
                });

                onSuccess && onSuccess();
            } else {
                console.log("entity.supportSearch.error ====>", responseData.message);
                dispatch({type: types.SUPPORT_SEARCH, support: {}, authorisedPersons: [], errorMessage: errorMessage});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("entity.supportSearch.error ===>", error);
            dispatch({type: types.SUPPORT_SEARCH, support: {}, authorisedPersons: [], errorMessage: errorMessage});
            loadingPageDataFinish(dispatch);
        }
    }

}

export function searchApplicationByAppID(applicationId, onSuccess) {
    return async dispatch => {
        const errorMessage = "Couldn't find application [with ID:" + applicationId + "]";
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');

            const responseData = await axios.get(`${MANAGEMENT_RESOURCES_URL}/${applicationId}`).then(response => response.data);
            if (responseData.success) {
                const additionalProductsData = await axios.get(`${MANAGEMENT_ADDITIONAL_PRODUCTS_URL}/${applicationId}`).then(response => response.data);
                const approvalMessagesData = await axios.get(`${MANAGEMENT_APPROVAL_MESSAGES_URL}/${applicationId}`).then(response => response.data);

                dispatch({
                    type: types.SUPPORT_SEARCH, support: responseData.data,
                    additionalProducts: additionalProductsData.data,
                    approvalMessages: approvalMessagesData.data,
                    errorMessage: null
                });
                onSuccess && onSuccess();
            } else {
                console.log("entity.supportSearch.error ====>", responseData.message);
                dispatch({
                    type: types.SUPPORT_SEARCH,
                    support: {},
                    additionalProducts: [],
                    approvalMessages: [],
                    errorMessage: errorMessage
                });
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("entity.supportSearch.error ===>", error);
            dispatch({
                type: types.SUPPORT_SEARCH,
                support: {},
                additionalProducts: [],
                approvalMessages: [],
                errorMessage: errorMessage
            });
            loadingPageDataFinish(dispatch);
        }
    }
}

export function clearForm() {
    return async dispatch => {
        dispatch({
            type: types.SUPPORT_SEARCH,
            support: {},
            additionalProducts: [],
            approvalMessages: [],
            authorisedPersons: [],
            errorMessage: ""
        });
    }
}


