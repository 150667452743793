import React from 'react';

const GlobalMarketAccountInfo = () => (
    <div>
        <div>
            <div className="product-heading">
                Global Market Accounts
            </div>
            <div className="title-gradient"/>
        </div>
        <div className="popup-content">
            <div className="card-container-form" style={{maxWidth: "40em"}}>
                <p className="greyText">

                </p>
                <p>

                </p>
                <div className="row">
                    <div className="" style={{padding: 10}}>
                        <p className="boldText">
                            Global Market Account includes:
                        </p>
                        <ul className="greyText">
                            <li className="square">
                                Money Market Account
                            </li>
                            <li className="square">
                                Forex Trading Account
                            </li>
                            <li className="square">
                                Equity Account
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
export default GlobalMarketAccountInfo;