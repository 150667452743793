import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {businessOnlineRoutes} from '../../routers/routes';
import {saveBolOnboard} from '../../actions/businessOnline';
import {fetchAccounts, onSelectAccount} from '../../actions/kyc';
import {ControlLabel} from 'react-bootstrap';
import FormField from '../formField/FormField';
import AccountsList from '../accounts/AccountsList';
import ButtonsComponent from '../ButtonsComponent';
import Col from 'react-bootstrap/es/Col';
import Popup from '../Popup';

const ErrorLabel = ({error}) => (
    <div>
        <span className="form-error">{error}</span>
    </div>
);


export const TransactionDetailsPage = (props) => {

    const businessOnlineApplication = useSelector(state => state.businessOnline && state.businessOnline);
    const businessOnline = useSelector(state => state.businessOnline && state.businessOnline.bolRequest);
    const accounts = useSelector(state => state.accounts && state.accounts.selectedEntityAccounts ? state.accounts.selectedEntityAccounts : []);
    const loading = useSelector(state => state.loading);

    const [errors, setErrors] = useState({});
    const [count, setCount] = useState(0);
    const [showPopUp, setShowPopUp] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState({});
    const [shortName, setShortName] = useState('');
    const [filteredBillingAccounts, setFilteredBillingAccounts] = useState(null);
    const [filteredTransactionalAccounts, setFilteredTransactionalAccounts] = useState(null);
    const [selectedBillingAccount, setSelectedBillingAccount] = useState(null);
    const [transactionDetails, setTransactionDetails] = useState({
        collections: false,
        payments: false,
        interAccountTransfers: false,
        transactionalAccounts: []

    });


    const dispatch = useDispatch();


    useEffect(() => {
            dispatch(fetchAccounts(`cifNumber=${businessOnline.cif}`, 'selectedEntityAccounts', null, true));
        }, []
    );

    useEffect(() => {
            if (businessOnline.transactionDetails) {
                businessOnline.transactionDetails.transactionalAccounts = [];
                businessOnline.transactionDetails.billingAccount = {};
                setTransactionDetails(businessOnline.transactionDetails);
            }
        }, [businessOnline]
    );

    const filterAccounts = (searchTerm) => {
        let filteredAccounts;
        if (searchTerm) {
            filteredAccounts = accounts && accounts.selectedEntityAccounts.filter((acc) => {
                return acc && (acc.accountNumber.includes(searchTerm) || acc.name && acc.name.toLowerCase().includes(searchTerm.toString().toLowerCase()));
            });
            console.log('filtered', filteredAccounts);
        }
        return filteredAccounts;

    };


    const handleToggle = (attribute, value) => {
        setTransactionDetails(_.extend({}, transactionDetails, {[attribute]: value}));
    };

    const handleChange = ({target}, attribute) => {
        let {name, value, type} = target;
        if (type === 'number') {
            value = parseFloat(value);
        }
        setTransactionDetails(_.extend({}, transactionDetails, {[attribute]: value}));
    };

    const handleOnFilterBillingAccounts = (searchTerm) => {
        let filteredBillingAccounts = filterAccounts(searchTerm);
        setFilteredBillingAccounts(filteredBillingAccounts);
    };

    const handleOnFilterTransactionalAccounts = (searchTerm) => {
        let filteredTransactionalAccounts = filterAccounts(searchTerm);
        setFilteredTransactionalAccounts(filteredTransactionalAccounts);
    };

    const handleOnSelectAccount = ({target}, account) => {
        setSelectedAccount(account);
        const isSelected = !account.isSelected;
        if (isSelected) {
            setCount(count + 1);
        } else {
            setCount(count - 1);
        }
        dispatch(onSelectAccount(accounts, account, isSelected, 'selectedEntityAccounts', true, null, null));
    };

    const handleOnAddName = () => {
        const isSelected = !selectedAccount.isSelected;
        setShowPopUp(false);
        selectedAccount.shortName = shortName;
        const onProceed = (success = true) => {
            setSelectedAccount({});
            setShortName('');
            if (count > 1) {
                setTransactionDetails(_.extend({}, transactionDetails, {'interAccountTransfers': true}));
            }
        };
        dispatch(onSelectAccount(accounts, selectedAccount, isSelected, 'selectedEntityAccounts', true, null, onProceed));

    };
    const onHandleSelectBillingAccount = (selectedBillingAccount) => {
        setSelectedBillingAccount(selectedBillingAccount);
    };


    const onNext = (e) => {
        e.preventDefault();
        const {history} = props;

        const onProceed = (success = true) => {
            if (success) {
                history.push(businessOnlineRoutes.accountSetup);
            }
        };

        let valid = validateFields(['transactionName', 'overallAmountLimit', 'service', 'statements']);
        if (valid) {

            let updatedTransactionDetails =
                _.extend({}, transactionDetails, {
                    ['billingAccount']: {
                        'accountNumber': selectedBillingAccount.accountNumber,
                        'accountName': selectedBillingAccount.name
                    }
                });
            valid = false;
            _.each(accounts, (acc, i) => {
                    if (acc.isSelected) {
                        valid = true;
                        let account = {
                            'accountNumber': acc.accountNumber,
                            'accountName': acc.name,
                            'shortName': acc.shortName
                        };
                        updatedTransactionDetails = _.extend({}, updatedTransactionDetails, {['transactionalAccounts']: [...updatedTransactionDetails.transactionalAccounts, account]});
                    }
                }
            );
            if (valid) {
                dispatch(saveBolOnboard(businessOnlineApplication.id, _.extend({}, businessOnline, {['transactionDetails']: updatedTransactionDetails}), 'UserTask_AccountDetails', onProceed));
            } else {
                setErrors({'transactionalAccounts': 'Required'});
            }
        }
    };

    const validateFields = (fields) => {
        const errors = {};
        let vv = fields.map((field) => {
            if (transactionDetails[field]) {
                return true;
            } else {
                errors[field] = 'Required';
                return false;
            }
        });

        if (!transactionDetails.interAccountTransfers && !transactionDetails.payments && !transactionDetails.collections) {
            errors['transactionType'] = 'Required';
            vv = [...vv, 'false'];
        }

        if (selectedBillingAccount === null) {
            errors['selectedBillingAccount'] = 'Required';
            vv = [...vv, 'false'];
        }
        console.log(vv);

        setErrors(errors);
        return !vv.includes(false, 0);
    };

    const renderPopUp = () => {
        if (showPopUp) {
            return (
                <Popup onClose={() => setShowPopUp(false)}>

                    <div className="form-group">
                        <label
                            className="col-md-4 control-label">Add Account Short Name</label>
                        <FormField className="col-md-4">
                            <input className="form-control" type="text" onChange={(event) => {
                                setShortName(event.target.value);
                            }} value={shortName}
                            />

                        </FormField>
                        <button className="action-btn-primary" onClick={handleOnAddName}>Save</button>
                    </div>

                </Popup>
            );
        }
        return null;
    };


    const renderTransactionDetails = () => {
        return (
            <div className="card-container-form">
                <div className="form-group">
                    <div className="row">
                        <label
                            className="col-md-4 control-label">Transaction Type<span
                            className="text-danger">*</span></label></div>

                    <div className="row">
                        <div className="col-md-3">
                            <div className="inline-container">
                                <div className="icon-container"
                                     onClick={(event) => {
                                         setTransactionDetails(_.extend({}, transactionDetails, {
                                             'payments': !transactionDetails.payments,
                                             'service': 'Real Time Clearing'
                                         }));
                                     }}>
                                    <i className={(transactionDetails.payments ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                                       style={{fontSize: 20}}
                                       id="payments"
                                    />
                                </div>
                                Payments
                            </div>
                        </div>
                        {/*<div className="col-md-3">*/}

                        {/*    <div className="inline-container">*/}
                        {/*        <div className="icon-container"*/}
                        {/*             onClick={(event) => {*/}
                        {/*                 handleToggle('collections', !transactionDetails.collections)*/}
                        {/*             }}>*/}
                        {/*            <i className={(transactionDetails.collections ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}*/}
                        {/*               style={{fontSize: 20}}*/}
                        {/*               id="collections"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*        Collections*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="col-md-3">

                            <div className="inline-container">
                                <div className="icon-container"
                                     onClick={(event) => {
                                         handleToggle('interAccountTransfers', !transactionDetails.interAccountTransfers);
                                     }}>
                                    <i className={(transactionDetails.interAccountTransfers ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                                       style={{fontSize: 20}}
                                       id="interAccountTransfers"
                                    />
                                </div>
                                Inter-account Transfers
                            </div>
                        </div>
                    </div>
                    {errors.transactionType &&
                    <div className="col-md-4 col-md-offset-8"><ErrorLabel error='At least one option is required!'/>
                    </div>}
                </div>

                <div className="form-group row">
                    <label
                        className="col-md-4 control-label">Owner ID<span
                        className="text-danger">*</span></label>
                    <FormField className="col-md-4">
                        <input className="form-control" type="text" onChange={(event) => {
                            handleChange(event, 'ownerId');
                        }} value={transactionDetails.ownerId || ''}/>
                        {errors.ownerId && <ErrorLabel error={errors.ownerId}/>}
                    </FormField>
                </div>

                <div className="form-group row">
                    <label
                        className="col-md-4 control-label">Transaction Name<span
                        className="text-danger">*</span></label>
                    <FormField className="col-md-4">
                        <input className="form-control" type="text" onChange={(event) => {
                            handleChange(event, 'transactionName');
                        }} value={transactionDetails.transactionName || ''}/>
                        {errors.transactionName && <ErrorLabel error={errors.transactionName}/>}
                    </FormField>
                </div>
                <div className="form-group row">
                    <label className="col-md-4 control-label">Please Indicate the overall amount limit<span
                        className="text-danger">*</span></label>
                    <FormField className="col-md-4">
                        <input className="form-control" type="number" onChange={(event) => {
                            handleChange(event, 'overallAmountLimit');
                        }} min={0} value={transactionDetails.overallAmountLimit || ''}/>
                        {errors.overallAmountLimit && <ErrorLabel error={errors.overallAmountLimit}/>}
                    </FormField>
                </div>

                <div className="form-group">
                    <div className="row">
                        <label className="col-md-4 control-label">Please indicate the type of service you would
                            like<span className="text-danger">*</span></label>
                        <div className="col-md-8">
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        value="Same Day Normal"
                                        checked={transactionDetails.service === 'Same Day Normal'}
                                        onChange={(event) => {
                                            handleChange(event, 'service');
                                        }}
                                    />
                                    Same Day Payment - Normal
                                </label>
                            </div>


                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-md-offset-4">
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        value="One or Two Day Service"
                                        checked={transactionDetails.service === 'One or Two Day Service'}
                                        onChange={(event) => {
                                            handleChange(event, 'service');
                                        }}
                                    />
                                    One or Two Day Service
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-md-offset-4">
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        value="Both"
                                        checked={transactionDetails.service === 'Both'}
                                        onChange={(event) => {
                                            handleChange(event, 'service');
                                        }}
                                    />
                                    Both
                                </label>
                            </div>
                        </div>
                        {errors.service &&
                        <div className="col-md-8 col-md-offset-4"><ErrorLabel error={errors.service}/></div>}
                    </div>
                </div>

                <div className="form-group">
                    <div className="row">
                        <label className="col-md-4 control-label">How would you like to view statements on your
                            accounts<span className="text-danger">*</span></label>
                        <div className="col-md-2">
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        value="consolidated"
                                        checked={transactionDetails.statements === 'consolidated'}
                                        onChange={(event) => {
                                            handleChange(event, 'statements');
                                        }}
                                    />
                                    Consolidated
                                </label>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        value="itemized"
                                        checked={transactionDetails.statements === 'itemized'}
                                        onChange={(event) => {
                                            handleChange(event, 'statements');
                                        }}
                                    />
                                    Itemized
                                </label>
                            </div>
                        </div>
                    </div>
                    {errors.statements &&
                    <div className="col-md-8 col-md-offset-4"><ErrorLabel error={errors.statements}/></div>}
                </div>

            </div>
        );
    };

    const renderTransactionalAccounts = () => {
        return (
            <div className="form-horizontal">
                <ControlLabel>Please select the accounts that you would like to link to your profile
                    {errors.transactionalAccounts && <ErrorLabel error={errors.transactionalAccounts}/>}
                </ControlLabel>
                <br/>
                <div className="card">
                    <AccountsList
                        accounts={accounts}
                        filteredAccounts={filteredTransactionalAccounts}
                        onFilterAccounts={handleOnFilterTransactionalAccounts}
                        onSelect={handleOnSelectAccount}
                        onSelectRadio={handleOnSelectAccount}
                        isMultiSelect={true}
                        listStyle={{maxHeight: '63vh'}}
                        hideDate={true}
                        hasShortName={true}
                    />
                </div>
            </div>
        );
    };


    const renderBillingAccounts = () => {

        return (
            <div className="form-horizontal">
                <ControlLabel>Please select billing account
                    {errors.selectedBillingAccount && <ErrorLabel error={errors.selectedBillingAccount}/>}
                </ControlLabel>

                <br/>
                <div className="card">
                    <AccountsList
                        accounts={accounts}
                        filteredAccounts={filteredBillingAccounts}
                        onFilterAccounts={handleOnFilterBillingAccounts}
                        onSelect={(selectedAccount) => {
                            onHandleSelectBillingAccount(selectedAccount);
                        }}
                        onSelectRadio={(selectedAccount) => {
                            onHandleSelectBillingAccount(selectedAccount);
                        }}
                        selectedAcc={selectedBillingAccount}
                        isMultiSelect={false}
                        listStyle={{maxHeight: '63vh'}}
                        hideDate={true}
                    /></div>
            </div>
        );
    };


    if (_.isEmpty(accounts) && !loading) {
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="no-entities-message-card">
                        <h3 className="login_title">We could not find accounts for your entity!</h3>
                        <p>Please open an account for the selected entity before attempting to create a new profile
                            / choose a different entity.</p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="container-fluid">
                <h2>Transaction Details</h2>
                <hr/>
                <Col sm={12}>
                    <div className="col-md-6">
                        {renderTransactionDetails()}
                    </div>
                    <div className="col-md-6">
                        {renderTransactionalAccounts()}
                        {renderBillingAccounts()}
                        {renderPopUp()}
                    </div>
                </Col>

                <div className="col-md-12">
                    <ButtonsComponent
                        history={props.history}
                        prevPage={businessOnlineRoutes.businessOnline}
                        onNext={onNext}/>
                </div>

            </div>
        );
    }
};

export default TransactionDetailsPage;
