import axios from 'axios';
import * as types from './actionTypes';
import { API_ROOT } from '../api-config';
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from "./appLoading"; 

const IMPORTANT_NOTICE_URL = API_ROOT + '/api/notices';

export function loadImportantNotices() {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(IMPORTANT_NOTICE_URL).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.LOAD_IMPORTANT_NOTICE_SUCCESS, importantNotices: responseData.data})
            } else {
                console.log("importantNotice.loadImportantNotices.errorMessage ===>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Couldn't retrieve notices."});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("importantNotice.loadImportantNotices.error ===>", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}