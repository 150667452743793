import React, {Component} from 'react';
import Form from "react-bootstrap/es/Form";
import FormField from '../formField/FormField'
import {bindActionCreators} from "redux";
import {fetchIndiviualInfo} from '../../actions/kyc';
import branches from '../../data/branches-std-only';
import {connect} from "react-redux";
import validate from 'validate.js';
import FormControl from 'react-bootstrap/es/FormControl';
import InputGroup from 'react-bootstrap/es/InputGroup';
import Button from 'react-bootstrap/es/Button';
import ButtonGroup from 'react-bootstrap/es/ButtonGroup';
import Jumbotron from 'react-bootstrap/lib/Jumbotron';
import {saveApplication, submitApplication, additionalProductChange} from '../../actions/tpfaActions';
import ButtonsComponent from '../ButtonsComponent';
import _ from 'lodash';
import Alert from 'react-bootstrap/lib/Alert';
import Modal from 'react-bootstrap/lib/Modal';
import {fetchBolProfiles} from '../../actions/bol';
import { Right } from 'react-bootstrap/lib/Media';
import { tpfaRoutes } from '../../routers/routes';


class ThirdPartyDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newAccount: {
                accountName: '',
                accountBranch: '',
                accountType:'',
                idType: '',
                idNumber: '',
                tpName: '',
                onCashManDisclaimer: '',
                cashmanGroupNo:'',
                bolProfile:'',
              
            },
            accCounter: 1,
            accName:'TPFA', 
            combinedValue:'',
            accountsError:[],
            duplicateAccountsError:[],
            tpErrors: [],
            errors: [],
            submittingErrors: [],
            submitting: false,
            cashmanDisclaimer: false,
            tpAddresses: null,
            searching: false,
            disabled:false,
            disabledAfterTenReco:false
        };

    }

    componentDidMount() {
        const {application} = this.props;
        const accounts = application.accounts ? application.accounts : [];
        this.setState({accCounter: accounts.length + 1});
        this.props.actions.fetchBolProfiles(this.props.application.cif);
        if(application.accounts){
            this.setState(prevState => ({
                newAccount: {
                    ...prevState.newAccount,
                    onCashManDisclaimer: 'YES',
                    accountName: '',
                    accountBranch: '',
                    idType: '',
                    idNumber: '',
                    tpName: '',
                    accountType: application.accounts[0].accountType,
                    cashmanGroupNo:application.accounts[0].cashmanGroupNo,
                    bolProfile:application.accounts[0].bolProfile,
                },
                disabled: true,
                duplicateAccountsError: ''
            }));
        }
        if(accounts.length >= 10){
            this.setState(prevState => ({
                newAccount: {
                    ...prevState.newAccount,
                    onCashManDisclaimer: 'YES',
                    accountName: '',
                    accountBranch: '',
                    idType: '',
                    idNumber: '',
                    tpName: '',
                },
                disabled: true,
                disabledAfterTenReco: true,
                duplicateAccountsError: ''
            }));
            return;
        }
    };

    handleAdditionalProduct = (product, change) => {
        const {application: {additionalProducts}, actions} = this.props;
        let additionalProductsChange = {...additionalProducts};
        additionalProducts[product] = change;
        actions.additionalProductChange(additionalProductsChange);
    };

    handleUserChange = ({target}) => {
        const {newAccount} = this.state;
        const {name, value} = target;
        newAccount[name] = value;
        this.setState({newAccount});
        this.setState({duplicateAccountsError:''});
    };

    handleBranchChange = ({target}) => {
        const {newAccount} = this.state;
        const {name, value} = target;
        newAccount[name] = value;
        const selectedBranch = branches.find(branch => branch.centreName === value);
        if (selectedBranch) {
            newAccount['accountBranchCode'] = selectedBranch.centreNumber;
        }
        this.setState({newAccount});
    };
    
  
    checkID = () => {
        const {newAccount} = this.state;
        let check = newAccount.idType === 'Identity Document';
        return !(check && (newAccount.idNumber && newAccount.idNumber.length === 13));
    };

    handleIDLookup = () => {
        const {newAccount, newAccount: {idNumber}} = this.state;
        const {actions} = this.props;
        this.setState({searching: true});
        let tpAddresses = [];
        const onFinish = (success, person) => {
            if (success) {
                newAccount.tpName = `${person.firstName} ${person.lastName}`;
                tpAddresses = person.addresses;
            }
            this.setState({searching: false, newAccount, tpAddresses});
        };
        const onError = () => {
            // this.setState({isLoading: false});
            this.setState({
                    tpErrors: [<span>Individual not found on search.</span>],
                    searching: false
                }
            );
        };
        actions.fetchIndiviualInfo(idNumber, onFinish, null, onError);
    };

    checkAccountDetails = () => {
        const {newAccount: {accountName, accountBranch, idType, idNumber, tpName}} = this.state;
        const check1 = (accountName && accountBranch && idType);
        const check2 = (idNumber && tpName);
        return !(check1 && check2);
    };

    validateForm() {
        const {newAccount,  accName, accCounter, duplicateAccountsError} = this.state;
        const {application} = this.props;
        const options = {fullMessages: false};
        const accounts = application.accounts ? application.accounts : [];
        
        const constraints = {
            accountName: {presence: true, length: {minimum: 1, message: 'required' }},
            accountBranch: {presence: true, length: {minimum: 1, message: 'required'}},
            accountType: {presence: true, length: {minimum: 1, message: 'required'}},
            // idType: {presence: true, length: {minimum: 1, message: 'required'}},
            // idNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            // tpName: {presence: true, length: {minimum: 1, message: 'required'}},
            cashmanDisclaimer: {presence: true, length: {minimum:1, message: 'you must agree to cashman disclaimer'}},
            bolProfile: {presence: true, length: {minimum: 1, message: 'required'}},
            cashmanGroupNo: {presence: true, length: {minimum: 1, maximum: 10, message: 'Enter max 10 Alpha numeric value'}}
        };

        if(newAccount.onCashManDisclaimer === "YES"){
            delete constraints.cashmanDisclaimer;
        } 

        const NewAccountName = `${accName} - ${accCounter} - ${newAccount.accountName}`;

        if(accounts && accounts.some(account => account.accountName == NewAccountName)) {
            this.setState( prevState => ({ newAccount:{...prevState.newAccount,  accountName: ''}, duplicateAccountsError : 'Please enter different name. This account name already exist.'}));
            return;
        }

        const newAccountErrors = validate(newAccount, constraints, options);
        const errors = _.extend({}, newAccountErrors);
        this.setState({errors});
        return _.isEmpty(errors);
    }

    handleAddAccount = () => {
        const { newAccount, accName, accCounter } = this.state;
        const { actions, application, id } = this.props;
        if (this.validateForm()) {
            const accounts = application.accounts ? application.accounts : [];
            newAccount.accountName = `${accName} - ${accCounter} - ${newAccount.accountName}`;
            accounts.push(newAccount);
            application.accounts = accounts;
            this.setState(prevState => ({
                newAccount: {
                    ...prevState.newAccount,
                    onCashManDisclaimer: 'YES',
                    accountName: '',
                    accountBranch: '',
                    idType: '',
                    idNumber: '',
                    tpName: '',
                },
                accCounter: accCounter + 1 ,
                disabled: true,
                duplicateAccountsError: ''
            }));

            if(accounts.length >= 10){
                this.setState(prevState => ({
                    newAccount: {
                        ...prevState.newAccount,
                        onCashManDisclaimer: 'YES',
                        accountName: '',
                        accountBranch: '',
                        idType: '',
                        idNumber: '',
                        tpName: '',
                    },
                    disabled: true,
                    disabledAfterTenReco: true,
                    duplicateAccountsError: ''
                }));
                return;
            }
        }
    };

    handleDeleteAccount = (accountId) => {
        const {actions, application, application: {accounts}, id} = this.props;
        accounts.splice(accountId, 1);
        application.accounts = accounts;
        actions.saveApplication(application, id, true);
        this.setState(prevState => ({
            accCounter: prevState.accCounter - 1 ,
            disabledAfterTenReco: false,
        }));
    };

    saveAndContinue = (event) => {
        event.preventDefault();
        const {history, actions, application, id } = this.props;
      
        actions.saveApplication(application, id, true);
        history.push('/');
    };

    handleNext = () => { 
        const {history, actions, application, id } = this.props;
        this.setState({accountsError: [], duplicateAccountsError :[]});
        if(_.isEmpty(application.accounts)){
            this.setState({accountsError: [<span>Add accounts before proceeding</span>]});
            return;
        }       
        actions.saveApplication(application, id, true);
        let nextPage = tpfaRoutes.tpfaConfirmAuthorisers;
        history.push(nextPage);
    };

    handleCheckApplication = () => {
        const {application: {additionalProducts}} = this.props;
        const submittingErrors = [];
        if (additionalProducts) {
            additionalProducts.businessOnlineProfile
                ? _.isEmpty(additionalProducts.businessOnlineProfile) ? submittingErrors.push(
                <span>Please provide/choose a <strong>Business online Profile</strong>.</span>) : false
                : submittingErrors.push(
                <span>Please provide/choose a <strong>Business online Profile</strong>.</span>);
            additionalProducts.cashMan
                ? _.isEmpty(additionalProducts.cashMan) ?
                submittingErrors.push(<span>Please provide a <strong>Cashman Group Number</strong>.</span>) : false
                : submittingErrors.push(<span>Please provide a <strong>Cashman Group Number</strong>.</span>);
        } else {
            submittingErrors.push(<span>Please provide/choose a <strong>Business online Profile and a CashMan Group Number</strong>.</span>);
        }

        _.isEmpty(submittingErrors) ? this.handleSubmit() : this.setState({submittingErrors});
    };

    handleSubmit = () => {
        const {application, id, history} = this.props;
        this.setState({submitting: false});
        this.props.actions.submitApplication(id, application, history);
    }

    allowOnlyCharacters = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^A-Za-z\s]/g, '');
        event.target.value = value;
    }

    allowOnlyNumbers = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^0-9]/g, '');
        event.target.value = value;
    }

    allowAlphaNumeric = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^a-zA-Z0-9\s]/g, '');
        event.target.value = value;
    }

    bolProfles = () => {
        const {profiles, application: {additionalProducts}} = this.props;
        const profilesOptions = [];
        profiles.forEach((entity) => {
            entity.divisions.forEach((div) => {
                profilesOptions.push({'value': div.code, 'label': `${div.code}: ${div.name}`})
            })
        });
        return (
            <select className={'form-control'} id={'bol'}
                    onChange={({target: {value}}) => this.handleAdditionalProduct("businessOnlineProfile", value)}
                    value={additionalProducts ? additionalProducts.businessOnlineProfile : ""}>
                <option value={''}>-SELECT-</option>
                {
                    profilesOptions.map(profile =>
                        <option key={profile.value} value={profile.value}>{profile.label}</option>
                    )
                }
            </select>
        )
    };

    onCashManDisclaimerClick = () => {
        this.setState(prevState => ({ newAccount:{...prevState.newAccount, onCashManDisclaimer : 'YES' }, cashmanDisclaimer: false }));
    }
    renderTable = () => {
        const {application} = this.props;
        const accounts = application.accounts ? application.accounts : [];

        console.log(accounts);
        return (
            <div className="row" style={{margin:0}}>
                <div className="col-lg-12 col-md-12">
                    <table className="table responsive striped">
                        <thead>
                            <tr>
                                <th className="col-lg-2 col-md-2 col-xs-12">Account Name</th>
                                <th className="col-lg-2 col-md-2 col-xs-12">Account Branch</th>
                                {/* <th className="col-lg-2 col-md-2 col-xs-12">Third Party ID Type</th>
                                <th className="col-lg-2 col-md-2 col-xs-12">Third Party ID Number</th> 
                                <th className="col-lg-2 col-md-2 col-xs-12">Third Party Name</th>*/}
                                <th className="col-lg-2 col-md-2 col-xs-12">Bol Profile</th>
                                <th className="col-lg-2 col-md-2 col-xs-12">Cashman Group No.</th>
                                <th className="col-lg-1 col-md-1 col-xs-12"  style={{ textAlign:"center" }}>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            accounts.map((account, i) =>
                                <tr key={i} style={{ padding:"10px 5px" }}>
                                    <td className="col-lg-2 col-md-2 col-xs-12">{account.accountName}</td>
                                    <td className="col-lg-2 col-md-2 col-xs-12">{account.accountBranch} {account.centreName}</td>
                                    {/* <td className="col-lg-2 col-md-2 col-xs-12">{account.idType}</td>
                                    <td className="col-lg-2 col-md-2 col-xs-12">{account.idNumber}</td> 
                                    <td className="col-lg-2 col-md-2 col-xs-12">{account.tpName}</td> */}
                                    <td className="col-lg-2 col-md-2 col-xs-12">{account.bolProfile}</td>
                                    <td className="col-lg-2 col-md-2 col-xs-12">{account.cashmanGroupNo}</td>
                                    <td className="col-lg-1 col-md-1 col-xs-12" style={{ textAlign:"center" }}>
                                        <a style={{ width: "auto" }} onClick={() => this.handleDeleteAccount(i)}>
                                            <i style={{
                                                color: "firebrick", 
                                                fontSize: 20,
                                                marginRight: 10, 
                                                cursor: "pointer"
                                            }} className={"fa fa-trash"}/>
                                        </a>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    };

    render() {
        const {errors, submitting, submittingErrors, disabled, disabledAfterTenReco, accountsError, newAccount, searching, tpErrors, accCounter, accName ,duplicateAccountsError} = this.state;
        const {application, profiles} = this.props;
        const {additionalProducts} = application;
        const enterName = newAccount.idType !== 'Passport';
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="row" style={{ margin: 0, width: "100%" }}>
                        <div className="row" style={{ margin: 0 }}>
                            <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">  <Form>
                                <h5>Account Details</h5>
                                <hr />
                                <FormField className="form-group col" error={errors && errors.accountName}>
                                    <div className="row" style={{ margin: 0 }}>
                                        <label htmlFor="">Account Name</label>
                                    </div>
                                    <div className="row" style={{ margin: 0 }}>
                                        <div className="col-md-2 col-lg-2 col-sm-2" style={{ padding: 0 }}>
                                            <input type='text'
                                                className="form-control"
                                                style={{borderRight:0, borderRadius:0}}
                                                id="accountNameCounter"
                                                required={true}
                                                name="accountNameCounter"
                                                value={`${accName} - ${accCounter}`}
                                                readOnly
                                            />
                                        </div>
                                        <div className="col-md-10 col-lg-10 col-sm-2" style={{ padding: 0 }}>

                                            <input type='text'
                                                className="form-control"
                                                id="accountName"
                                                maxLength={30}
                                                disabled = {disabledAfterTenReco}
                                                required={true}
                                                style={{borderLeft:0, borderRadius:0}}
                                                name="accountName"
                                                value={newAccount.accountName || ""}
                                                // onKeyPress={(event) => { this.handleKeyPress(event) }}
                                                onChange={(event) => {
                                                    this.allowAlphaNumeric(event);
                                                    this.handleUserChange(event);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {duplicateAccountsError && (<span style={{ color: 'firebrick', textTransform: 'capitalize', fontSize: '0.85em' }}> {duplicateAccountsError} </span>)}
                                </FormField>
                               

                                <FormField className="form-group col" error={errors && errors.accountBranch}>
                                    <label htmlFor="" >Account Branch</label>
                                    <select className="form-control" 
                                        name="accountBranch"
                                        id="accountBranch"
                                        disabled = {disabledAfterTenReco}
                                        required={true}
                                        value={newAccount && newAccount.accountBranch || ''}
                                        
                                        onChange={(event) => this.handleBranchChange(event)}>
                                        <option value=''>SELECT</option>
                                        {
                                            branches.map(branch => <option key={branch.centreNumber}
                                                value={branch.centreName}>{branch.centreName}</option>)
                                        }
                                    </select>
                                </FormField>

                                <FormField className="form-group col" error={errors && errors.accountType}>
                                    <label htmlFor="">Account Type</label>
                                    <select className="form-control" 
                                        name="accountType"
                                        id="accountType"
                                        value={newAccount && newAccount.accountType || ''}
                                        required={true}  
                                        disabled={disabled}
                                        onChange={this.handleUserChange}>
                                        <option value=''>SELECT</option>
                                        <option value="TPFAA">THIRD PARTY FUND ADMINISTRATION ACCOUNT</option>
                                        <option value="ECA">EXECUTORS CURRENT ACCOUNT</option>
                                        <option value="BCA">BUSINESS CURRENT ACCOUNT</option>
                                    </select>
                                </FormField>
                                    {/* 
                                        <FormField className="form-group col" error={errors && errors.idType}>
                                            <label htmlFor="">Third Party ID Type</label>
                                            <select className="form-control" 
                                                name="idType"   
                                                id="idType"
                                                required={true} 
                                                value={newAccount && newAccount.idType || ''}
                                                onChange={this.handleUserChange}>
                                                    <option value=''>SELECT</option>
                                                    <option value="Identity Document">Identity Document</option>
                                                    <option value="Passport">Passport</option>
                                            </select>
                                        </FormField>
                                    
                                    
                                        {
                                            newAccount.idType === "Passport" ? (
                                                <FormField className="form-group col" error={errors && errors.idNumber}>
                                                <label htmlFor="" style={{ width: "100%" }}>Third Party Passport Number </label>
                                                    <input type="text"
                                                        name="idNumber"
                                                        id="idNumber"
                                                        required={true}
                                                        value={newAccount.idNumber || ""}
                                                        onChange={this.handleUserChange} className="form-control" />
                                                </FormField>
                                            ) : (
                                                <FormField className="form-group col" error={errors && errors.idNumber}>
                                                    <label htmlFor="" style={{ width: "100%" }}>Third Party Identity Number </label>
                                                    <InputGroup>
                                                        <input type="text"
                                                            name="idNumber"
                                                            id="idNumber"
                                                            required={true}
                                                            value={newAccount.idNumber || ""}
                                                            onChange={(event) => {
                                                                this.allowOnlyNumbers(event);
                                                                this.handleUserChange(event);
                                                            }}
                                                            className="form-control" />
                                                        <InputGroup.Button >
                                                            <Button className="btn-primary" onClick={this.handleIDLookup}>{searching ? 'Searching' : 'Search'}</Button>
                                                        </InputGroup.Button>
                                                    </InputGroup>
                                                </FormField>
                                                
                                            )
                                        }
                                

                                        <FormField className="form-group col" error={errors && errors.tpName}>
                                            <label htmlFor="">Third Party Name</label> 
                                            <input type='text' name="tpName"
                                                className="form-control" 
                                                id="tpName"
                                                required={true}
                                                value={newAccount.tpName || ""}
                                                // disabled={enterName}
                                                onChange={this.handleUserChange} />
                                        </FormField>
                                    */}
                                <div>
                                    {
                                        !_.isEmpty(tpErrors) && tpErrors.map((error, i) =>
                                            <Alert key={i} bsStyle={'danger'}>{error}</Alert>
                                        )
                                    }
                                </div>

                            </Form>
                            </div>
                            <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <h5>Bol and Cashman Information</h5>
                                <hr />
                                <FormField className="form-group col" error={errors && errors.bolProfile}>
                                    <label>Business Online Profile</label>
                                    <input type="text" 
                                        id="bolProfile"
                                        name="bolProfile"
                                        className="form-control"
                                        placeholder="Business online Profile ID"
                                        disabled={disabled}
                                        onChange={this.handleUserChange}
                                        value={newAccount.bolProfile || ""}
                                    />
                                </FormField>
                                <FormField className="form-group col" error={errors && errors.cashmanDisclaimer && ["You must agree to cashman disclaimer."]}>
                                    <Button style={{width:'auto', margin:'25px 0px 0px 0px' }}  bsStyle={'primary'} disabled={disabled } onClick={() => this.setState({ cashmanDisclaimer: true })}>Accept Cashman Disclaimer</Button>
                                </FormField>
                          
                                <FormField className="form-group col" error={errors && errors.cashmanGroupNo}>
                                    <label>Cashman Group Number</label>
                                    <input type="text" 
                                        className="form-control" 
                                        id="cashmanGroupNo" 
                                        disabled={disabled}
                                        name="cashmanGroupNo"
                                        maxLength={10}
                                        // onChange={this.handleUserChange}
                                        onChange={(event) => {
                                            this.allowAlphaNumeric(event);
                                            this.handleUserChange(event);
                                        }}
                                        placeholder="Cashman Group Number"
                                        value={newAccount.cashmanGroupNo || ""} />
                                </FormField>

                            </div>
                        </div>
                        <div className="row" style={{ margin: 0 }}>
                            <ButtonGroup style={{ float: "right" }}>
                                <Button bsStyle={'primary'} disabled = {disabledAfterTenReco} onClick={() => this.handleAddAccount()}>Add</Button>
                            </ButtonGroup>
                        </div>
                        <div className="row" style={{ margin: '10px 0' }}>
                            {
                                disabledAfterTenReco && (
                                    <span class="form-error" style={{float:'right'}}>You can not add more than 10 accounts</span>
                                )

                            }
                           
                        </div>
                        <hr />
                        <div className="row" style={{ margin: 0 }}>
                            {this.renderTable()}
                        </div>

                        {
                            !_.isEmpty(accountsError) && accountsError.map((error, i) =>
                                <Alert key={i} bsStyle={'danger'}>{error}</Alert>
                            )
                        }
                    </div>
                </div>
                <div className="page-footer-section" style={{ width: "80%" }}>
                    <ButtonsComponent
                        onSaveAndContinue={this.saveAndContinue}
                        onNext={this.handleNext}
                        hidePrevious={false}
                    />
                </div>

                {/* <Modal show={submitting} onHide={() => this.setState({ submitting: false })}>
                    <Modal.Header>
                        <h4>Before submitting</h4>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            submittingErrors && submittingErrors.map((error, index) => <Alert key={index}
                                bsStyle={'danger'}>{error}</Alert>)
                        }
                        <div>
                            <label>Business Online Profile</label>
                            {
                                profiles && profiles.length > 0 ?
                                    this.bolProfles() :
                                    <FormControl type={'text'} id={'bol'} placeholder={'Business online Profile ID'}
                                        onChange={({ target: { value } }) => this.handleAdditionalProduct("businessOnline", value)}
                                        value={additionalProducts ? additionalProducts.businessOnline : ""}
                                    />
                            }
                        </div>
                        <br />
                        <div>
                            <label>Cashman Group Number</label>
                            <FormControl type={'text'} id={'cashman'}
                                onChange={({ target: { value } }) => this.handleAdditionalProduct("cashManagement", value)}
                                placeholder={'Cashman Group Number'}
                                value={additionalProducts ? additionalProducts.cashManagement : ""} />

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button onClick={() => this.setState({ submitting: false })}>Cancel</Button>
                            <Button bsStyle={'primary'} onClick={this.handleCheckApplication}>Submit</Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal> */}


                <Modal show={this.state.cashmanDisclaimer} onHide={() => this.setState({ cashmanDisclaimer: false })} bsSize={'lg'} keyboard={false} backdrop={'static'}>
                    <Modal.Header style={{padding:'5px 10px'}}><h4 style={{margin:'10px 0px' }}><b>Disclaimer</b></h4></Modal.Header>
                    <Modal.Body>
                        <p>
                            Please read the below disclaimer regarding Cash Management and
                            indicate whether you agree or do not agree.
                        </p>
                        <Jumbotron style={{overflowY: 'scroll', maxHeight: '25em'}}>
                            <p>
                                The Standard Bank of South Africa Limited (“<strong>the Bank</strong>”) has created a digital
                                platform for
                                Account Opening which allows our customers to open bank accounts on the digital channel.
                                The digital channel has the capability to link new bank accounts to existing Cash Management
                                (“<strong>Cash Man</strong>”) structures, if any. The customers must be aware that two scenarios
                                exist:
                            </p>
                            <h4><b><u>Scenario 1</u></b></h4>
                            <p>
                                In the event that the bank account that is to be loaded to the digital channel relates to a
                                legal entity that <u>has not</u> already been onboarded onto an existing Cash Man structure then
                                the
                                relevant internal due diligence procedures would need to be conducted and legal documents
                                relevant to the Cash Man product that is being offered to the customer would need to be signed
                                prior to the onboarding of the bank account to the digital channel and the Cash Man structure
                                being implemented.
                            </p>
                            <p>
                                The above processes take time to conduct and the Bank will conduct these internal process
                                requirements within a reasonable time, after receipt of such written request from the customer
                                that they are intending on opening a bank account on the digital channel and add such bank
                                account on the Cash Man structure.
                            </p>
                            <h4> <b><u>Scenario 2</u></b></h4>
                            <p>
                                In the event that the bank account that is to be loaded to the digital channel relates to a
                                legal entity that has already been onboarded onto an existing Cash Man structure then the
                                relevant internal due diligence procedure would not be required as these would have already
                                have been conducted when the Cash Man product was onboarded and the process will be finalised
                                within a reasonable period of time.
                            </p>
                            <p>
                                <strong>
                                    The Customer indemnifies the Bank against any loss, damage, claims, costs or any other
                                    liability which may arise as a result of the Bank conducting the various internal process
                                    requirements before the bank account and Cash Man structure are onboarded and implemented
                                    respectively.
                                </strong>
                            </p>
                        </Jumbotron>
                    </Modal.Body>
                    <Modal.Footer style={{padding:'5px 10px'}}>
                        <ButtonGroup>
                            <Button bsStyle={'primary'}  style={{margin:'10px'}}
                                onClick={ this.onCashManDisclaimerClick }> Agree
                            </Button>
                            {/* <Button bsStyle={'default'}  style={{margin:'10px'}}
                                 onClick={()=> this.setState({ newAccount:{onCashManDisclaimer : 'NO' }, cashmanDisclaimer: false })}> Do Not Agree
                            </Button> */}
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
   
            </div>
        )
    }

}


function mapStateToProps({tpfa: {id, application}, bol: {profiles}, impersonation, loading}) {
    return {
        id,
        application,
        loading,
        profiles,
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            fetchIndiviualInfo, saveApplication,
            submitApplication, additionalProductChange, fetchBolProfiles
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ThirdPartyDetails);
