import React from 'react';
import _ from "lodash";
import DropDownIcon from "../DropDownIcon";
import FormField from "../formField/FormField";
import validate from "validate.js";

export default class ProductsAndServices extends React.Component {
    state = {validateableProducts: [], errors: {}};

    componentDidMount() {
        _.defer(()=>this.setDefaults());
    }

    setDefaults = (hardReset = false) => {
        const {productsAndServices, productsServicesSections, onUpdateProductsAndServices} = this.props;
        const hasProducts = _.size(productsAndServices) > 0;
        if (!hasProducts || hardReset) {

            onUpdateProductsAndServices && onUpdateProductsAndServices([])
        }
        const validateableProducts = _.filter(productsServicesSections, ({optionRequired}) => (optionRequired));
        this.setState({validateableProducts})
    };

    handleOnResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    validateProductsAndServices = () => {
        const {productsAndServices} = this.props;
        const {validateableProducts} = this.state;

        const options = {fullMessages: false};
        const hasProducts = _.size(productsAndServices) > 0;
        let errors = {};
        if (hasProducts) {
            let constraints = {};
            let selectedOptions = {};
            _.map(validateableProducts, ({sectionId}) => {
                const found = _.find(productsAndServices, ps => (ps.productId === sectionId));
                if (found) {
                    constraints = _.extend({}, constraints, {[sectionId]: {presence: true}});
                    selectedOptions = _.extend({}, selectedOptions, {[sectionId]: found.selectedOption});
                }
            });
            errors = validate(selectedOptions, constraints, options)
        }

        this.setState({errors});
        return _.isEmpty(errors);
    };

    handleSelectProduct = (productId) => {
        const {productsAndServices, onUpdateProductsAndServices} = this.props;
        const isFound = _.find(productsAndServices, ps => (ps.productId === productId));

        let updatedProductsAndServices = [];
        if (isFound) {
            updatedProductsAndServices = _.filter(productsAndServices, ps => (ps.productId !== productId))
        } else {
            updatedProductsAndServices = _.union(productsAndServices, [{productId, selectedOption: null}])
        }
        onUpdateProductsAndServices && onUpdateProductsAndServices(updatedProductsAndServices)
    };

    handleSelectOption = (productId, selectedOption) => {
        const {productsAndServices, onUpdateProductsAndServices} = this.props;

        const updatedProductsAndServices = _.map(productsAndServices, ps => (ps.productId === productId ? _.extend({}, ps, {selectedOption}) : ps));

        onUpdateProductsAndServices && onUpdateProductsAndServices(updatedProductsAndServices)
    };

    handleOnChange = (isSelected, sectionId, attribute = null, innerAttribute = null) => {
        const {productsAndServices, onUpdateProductsAndServices} = this.props;
        const section = productsAndServices && productsAndServices[sectionId];
        let value = isSelected;
        if (innerAttribute) {
            value = _.extend({}, section && section[attribute], {[innerAttribute]: value});
        }
        if (attribute) {
            value = _.extend({}, section, {[attribute]: value});
        }
        const uppdatedProductsAndServices = _.extend({}, productsAndServices, {[sectionId]: value});

        onUpdateProductsAndServices && onUpdateProductsAndServices(uppdatedProductsAndServices);
    };

    _renderMainSection(mainSection, index) {
        const {productsAndServices, shouldDisable} = this.props
        const {errors} = this.state;
        const {sectionId, sectionTitle, options} = mainSection;

        const hasOptions = _.size(options) > 0;
        const sectionStyle = _.extend({marginLeft: 0, marginBottom: 5, paddingLeft: 0},);

        const isSelected = _.find(productsAndServices, ps => (ps.productId === sectionId));
        return (
            <div key={`main-section/${sectionId}/${index}`}>
                <FormField error={errors && errors[sectionId] && ["Please Select Option"]}>
                    <div
                        className="checkbox-inline " style={sectionStyle}
                        onClick={(e) => {
                            e.stopPropagation();
                            errors && errors[sectionId] && this.handleOnResetError(sectionId);
                            !shouldDisable && this.handleSelectProduct(sectionId);
                        }}>
                        <div
                            className="product-heading"
                            style={{width: "100%", paddingLeft: 0, marginLeft: 0, marginBottom: 5, fontSize: 16}}
                        ><DropDownIcon
                            isToggled={isSelected}
                            shouldDropDown={false}
                            isSelectable={true}
                            disabled={shouldDisable}
                        /><span style={{color: shouldDisable ? "grey" : "black"}}> {sectionTitle}</span>
                        </div>
                        <div className="title-gradient"/>
                    </div>
                    <br/>
                    {
                        hasOptions &&
                        <div className="flexColumn" style={{alignItems: "flex-start"}}>
                            {
                                _.map(options, (sec, i) => (this._renderInnerSections(sectionId, sec, i, (!isSelected))))
                            }
                        </div>
                    }
                </FormField>
                <br/>
            </div>
        )
    }

    _renderInnerSections(mainSectionId, option, index, shouldDisable) {
        const {productsAndServices} = this.props;
        const {errors} = this.state;
        const {description, id} = option;

        const isSelected = _.find(productsAndServices, ({productId, selectedOption}) => (productId === mainSectionId && (selectedOption && selectedOption.id === id)));
        const sectionStyle = _.extend({
            marginLeft: 25,
            marginBottom: 5,
            paddingLeft: 0
        }, {color: shouldDisable ? "grey" : "black"});

        return (
            <div key={`option/${mainSectionId}/${id}/${index}`} className="checkbox-inline"
                 style={sectionStyle}
                 onClick={(e) => {
                     e.stopPropagation();
                     errors && errors[mainSectionId] && this.handleOnResetError(mainSectionId);
                     !shouldDisable && this.handleSelectOption(mainSectionId, option)
                 }}>
                <i
                    className={(isSelected) ? "fa fa-check-circle" : "fa fa-circle-o"}
                    style={{color: shouldDisable ? "grey" : "rgb(0, 51, 170)", fontSize: 18, marginRight: 10}}
                />
                <span>{description}</span>
            </div>
        )
    }

    render() {
        const {productsServicesSections} = this.props;
        return (
            <div className="flexColumn" style={{alignItems: "flex-start"}}>
                {
                    _.map(productsServicesSections, (mainSection, i) => (
                        this._renderMainSection(mainSection, i)
                    ))
                }
            </div>
        );
    }
}