import React from 'react';
import _ from "lodash";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";

import * as actions from "../../actions/bol";
import TokenDeliveryDetails from './TokenDeliveryDetails'
import UserDetails from './UserDetails';


const CHANNELS = {
    bol: {type: "businessOnline", label: "Business Online"},
    host: {type: "hostToHost", label: "Host To Host"}
};

const HOST_TO_HOST_TYPE = {partialAutomated: "partialAutomated", fullyAutomated: "fullyAutomated"};

class UsersInfoPage extends React.Component {

    determineNavigation = () => {
        const {newBolProfile} = this.props;
        const channelDetails = newBolProfile && newBolProfile.channelDetails;
        const channelRequirement = channelDetails && channelDetails.channelRequirement;
        const isPartialAutomated = (channelDetails && channelDetails.hostToHostType) === HOST_TO_HOST_TYPE.partialAutomated;

        const isHostToHostType = channelRequirement === CHANNELS.host.type;
        const path = (isPartialAutomated && isHostToHostType) ? "/bol/channels" : "/bol/accounts";
        return path
    };


    handlePrevious = () => {
        const {history} = this.props;
        const path = this.determineNavigation();
        history.push(path);
    };

    handleSaveAndContinue = () => {
        const {actions, newBolProfile, history} = this.props;
        const onProceed = () => history.replace('/');
        const updateDetails = _.extend({}, newBolProfile, {status: 'users'});
        actions.saveNewBolProfile(updateDetails, onProceed)
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const isTokenDetailsValid = this.tokenDeliveryRef && this.tokenDeliveryRef.validateTokenDetails && this.tokenDeliveryRef.validateTokenDetails();
        const isUserDetailsValid = this.UserDetailsRef && this.UserDetailsRef.validateUserDetails && this.UserDetailsRef.validateUserDetails();
        if (isTokenDetailsValid && isUserDetailsValid) {
            const {actions, newBolProfile, history} = this.props;
            const onProceed = () => history.push("/bol/complete");
            const updateDetails = _.extend({}, newBolProfile, {status: 'complete'});
            actions.saveNewBolProfile(updateDetails, onProceed);
        }
    };

    _renderFooter() {
        return (
            <div className="action-button-container">
                <button
                    className="action-btn-primary"
                    onClick={this.handlePrevious}
                    type="button"
                >Previous
                </button>
                <button
                    className="action-btn-secondary"
                    onClick={this.handleSaveAndContinue}
                    type="button"
                > Save And Continue Later
                </button>
                <button
                    className="action-btn-primary"
                    type="submit"
                >Next
                </button>
            </div>
        )
    }

    render() {
        const {newBolProfile} = this.props;
        const tokenDetails = newBolProfile && newBolProfile.tokenDetails;
        return (
            <div>
                <form className="page-container" onSubmit={this.handleSubmit}>
                    <div className="page-main-section">
                        <div className="flexColumn">
                            <TokenDeliveryDetails
                                ref={(ref) => {
                                    if (ref) this.tokenDeliveryRef = ref.getWrappedInstance()
                                }}
                            />
                        </div>
                        <div className="flexColumn">
                            <UserDetails
                                showDeliveryAddress={!(tokenDetails && tokenDetails.bulkDelivery)}
                                ref={(ref) => {
                                    if (ref) this.UserDetailsRef = ref.getWrappedInstance()
                                }}
                                />
                        </div>
                        <div className="flexColumn">
                        </div>
                    </div>
                    <div className="page-footer-section">
                        {this._renderFooter()}
                    </div>
                </form>
            </div>
        )
    }
}


function mapStateToProps({bol}) {
    return {newBolProfile: bol && bol.newBolProfile ? bol.newBolProfile : {}}
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({actions}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersInfoPage);