import React from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import validate from 'validate.js'
import "../../styles/ConfirmApplication.css";
import "../../styles/Global.css";
import * as actions from '../../actions/custody';
import FormField from "../formField/FormField";
import {newCustodyAccountRoutes} from "../../routers/routes";
import ButtonsComponent from "../ButtonsComponent";
import Select from 'react-select';
import 'react-select/dist/react-select.css';

const labelClassList = "col-xs-12 col-sm-4 col-md-4";
const inputClassList = "col-xs-12 col-sm-8 col-md-8";


const CONTACT_METHOD = [
    {value: 'EMAIL', label: "EMAIL"},
    {value: 'TEL', label: "TELEPHONE"}
]


class ContactDetailsPage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            errors: {}
        };

        this.handleOnChange = this._handleOnChange.bind(this);
        this.handleOnBNDContactChange = this._handleOnBNDContactChange.bind(this);
        this.handleBNDContactSame = this._handleBNDContactSame.bind(this);

    }


    _handleOnChange({target}, attribute) {
        const {newCustodyProfile} = this.props;
        let value = _.trimStart(target.value);
        this.props.action.onChangeValue('beneficialContactDetails', attribute, value)
        if (newCustodyProfile.bndContactDetails.isSameContact) {
            this.props.action.onChangeValue('bndContactDetails', attribute, value)
        }
    }

    _handleOnBNDContactChange({target}, attribute) {

        let value = _.trimStart(target.value);
        this.props.action.onChangeValue('bndContactDetails', attribute, value)
    }

    _handleBNDContactSame(isSame = false) {

        const {newCustodyProfile} = this.props;

        let bndContactDetails;
        if (isSame) {
            bndContactDetails = newCustodyProfile.beneficialContactDetails;
        } else {
            bndContactDetails = {
                contactName: "",
                modeOfCorrespondence: "",
                telephoneNumber: "",
                faxNumber: "",
                emailAddress: "",
                alternateTelephoneNumber:""
            };
        }

        bndContactDetails.isSameContact = isSame;
        this.props.action.onChangeValue("bndContactDetails", bndContactDetails);
    }

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    validateForm = () => {
        const options = {fullMessages: false};

        const benefialConstraints = {
            modeOfCorrespondence: {presence: true},
            contactName: {presence: true, length: {minimum: 1, message: 'required'}},
            emailAddress: {presence: true, length: {minimum: 1, message: 'required'}},
            telephoneNumber: {presence: true, length: {minimum: 1, message: 'required'}}
        };

        const bndConstraints = {
            modeOfCorrespondence: {presence: true},
            contactName: {presence: true, length: {minimum: 1, message: 'required'}},
            emailAddress: {presence: true, length: {minimum: 1, message: 'required'}},
            telephoneNumber: {presence: true, length: {minimum: 1, message: 'required'}}
        };

        const beneficialErrors = validate(this.props.newCustodyProfile.beneficialContactDetails, benefialConstraints, options);
        const bndErrors = validate(this.props.newCustodyProfile.bndContactDetails, bndConstraints, options);

        const errors = _.extend({}, {beneficialErrors}, {bndErrors});
        this.setState({errors});
        return _.isEmpty(beneficialErrors) && _.isEmpty(bndErrors);
    };

    saveDetails = (event) => {
        event.preventDefault();
        const {history} = this.props;
        if (this.validateForm()) {
            const onSuccess = () => history.push(newCustodyAccountRoutes.accountcashpurpose);
            this.props.action.saveCustody(this.props.appId, this.props.newCustodyProfile, 'UserTask_ContactDetails', onSuccess);
        }
    };

    saveAndContinue = (event) => {
        event.preventDefault();
        const {history} = this.props;
        const onSuccess = () => history.push('/saveapplication');
        this.props.action.saveCustody(this.props.appId, this.props.newCustodyProfile, 'saveContinue', onSuccess);

    };


    handleSelectChange = (event, attribute, innerAttribute) => {
        const {newCustodyProfile} = this.props;
        let value = event.value;

        if (innerAttribute === 'beneficialContactDetails') {
        this.props.action.onChangeValue('beneficialContactDetails', attribute, value)
        }
        else{
                    this.props.action.onChangeValue('bndContactDetails', attribute, value)

        }


    };

    isContactDetailsTheSame = (isTheSame = false) => {

        this.setState(prevState => ({contactTheSame: !prevState.contactTheSame}))
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={this.saveDetails}
                    type="button"
                >Complete
                </button>
            </div>
        )
    }

    render() {
        const {errors, contactTheSame} = this.state;
        const {newCustodyProfile} = this.props;
        // console.log("state :::" + JSON.stringify(this.state))
        return (
            <div className="page-container">
                <div className="page-main-section">

                    <div className="col-xs-12">
                        <div className="card-container-form">
                            <div className="section-title">
                                <span>Contact Details Beneficial Owner </span>
                            </div>
                            <div className="form-horizontal">
                                <div className="row">
                                    <div className="col-md-6">
                                    <FormField className="form-group" id="contactName" error={errors && errors.beneficialErrors && errors.beneficialErrors.contactName}>
                                        <label className={labelClassList} htmlFor="province">Contact Name</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="contactName"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && this._resetError('beneficialErrors');
                                                    this.handleOnChange(event, 'contactName')
                                                }}
                                                type="text"
                                                value={newCustodyProfile && newCustodyProfile.beneficialContactDetails && newCustodyProfile.beneficialContactDetails.contactName
                                                    ? newCustodyProfile.beneficialContactDetails.contactName : ''}
                                            />
                                        </div>
                                    </FormField>

                                    <FormField className="form-group" id="telephoneNumber"
                                               error={errors && errors.beneficialErrors && errors.beneficialErrors.telephoneNumber}>
                                        <label className={labelClassList} htmlFor="province">Telephone Number</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="telephoneNumber"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && this._resetError('beneficialErrors');
                                                    this.handleOnChange(event, 'telephoneNumber')
                                                }}
                                                type="text"
                                                value={newCustodyProfile && newCustodyProfile.beneficialContactDetails && newCustodyProfile.beneficialContactDetails.telephoneNumber
                                                    ? newCustodyProfile.beneficialContactDetails.telephoneNumber : ''}
                                            />
                                        </div>
                                    </FormField>
                                    <FormField className="form-group" id="alternateTelephoneNumber"
                                               error={errors && errors.beneficialErrors && errors.beneficialErrors.alternateTelephoneNumber}>
                                        <label className={labelClassList} htmlFor="province">Alternate Telephone Number</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="alternateTelephoneNumber"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && this._resetError('alternateTelephoneNumber');
                                                    this.handleOnChange(event, 'alternateTelephoneNumber')
                                                }}
                                                type="text"
                                                value={newCustodyProfile && newCustodyProfile.beneficialContactDetails && newCustodyProfile.beneficialContactDetails.alternateTelephoneNumber
                                                    ? newCustodyProfile.beneficialContactDetails.alternateTelephoneNumber : ''}
                                            />
                                        </div>
                                    </FormField>
                                    </div>
                                    <div className="col-md-6">
                                    <FormField className="form-group" id="modeOfCorrespondence"
                                               error={errors && errors.beneficialErrors && errors.beneficialErrors.modeOfCorrespondence}>
                                        <label className={labelClassList} htmlFor="province">Primary Mode Of
                                            Correspondence</label>
                                        <div className={inputClassList}>
                                            <Select
                                                name="modeOfCorrespondence"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && this._resetError('beneficialErrors');
                                                    this.handleSelectChange(event, 'modeOfCorrespondence', 'beneficialContactDetails')
                                                }}
                                                options={CONTACT_METHOD}
                                                value={newCustodyProfile && newCustodyProfile.beneficialContactDetails && newCustodyProfile.beneficialContactDetails.modeOfCorrespondence ? newCustodyProfile.beneficialContactDetails.modeOfCorrespondence : ''}
                                            />
                                        </div>
                                    </FormField>
                                    <FormField className="form-group" id="emailAddress" error={errors && errors.beneficialErrors && errors.beneficialErrors.emailAddress}>
                                        <label className={labelClassList} htmlFor="emailAddress">Email Address</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="emailAddress"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && this._resetError('beneficialErrors');
                                                    this.handleOnChange(event, 'emailAddress')
                                                }}
                                                type="email"
                                                value={newCustodyProfile && newCustodyProfile.beneficialContactDetails && newCustodyProfile.beneficialContactDetails.emailAddress
                                                    ? newCustodyProfile.beneficialContactDetails.emailAddress : ''}
                                            />
                                        </div>
                                    </FormField>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">

                                    <div className="inline-container">
                                        <div className="icon-container"
                                             onClick={(event) => {
                                                 this.handleBNDContactSame(!newCustodyProfile.bndContactDetails.isSameContact)
                                             }}>
                                            <i className={(newCustodyProfile.bndContactDetails.isSameContact ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                               style={{fontSize: 20}}
                                               id="beneficiarySameAddress"
                                            />
                                        </div>
                                        BND Contact Same As Beneficial Owner Contact
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 form-group">

                                <div className="section-title">
                                    <span>Contact Details BND </span>
                                </div>
                            </div>
                            <div className="form-horizontal">

                            <div className="row">
                                <div className="col-md-6">
                                <FormField className="form-group" id="contactName" error={errors && errors.bndErrors && errors.bndErrors.contactName}>
                                        <label className={labelClassList} htmlFor="province">Contact Name</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="contactName"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && this._resetError('bndErrors');
                                                    this.handleOnBNDContactChange(event, 'contactName')
                                                }}
                                                type="text"
                                                value={newCustodyProfile && newCustodyProfile.bndContactDetails && newCustodyProfile.bndContactDetails.contactName
                                                    ? newCustodyProfile.bndContactDetails.contactName : ''}
                                                disabled={newCustodyProfile.bndContactDetails.isSameContact}
                                            />
                                        </div>
                                    </FormField>

                                    <FormField className="form-group" id="telephoneNumber"
                                               error={errors && errors.bndErrors && errors.bndErrors.telephoneNumber}>
                                        <label className={labelClassList} htmlFor="province">Telephone Number</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="telephoneNumber"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && this._resetError('bndErrors');
                                                    this.handleOnBNDContactChange(event, 'telephoneNumber')
                                                }}
                                                type="text"
                                                value={newCustodyProfile && newCustodyProfile.bndContactDetails && newCustodyProfile.bndContactDetails.telephoneNumber
                                                    ? newCustodyProfile.bndContactDetails.telephoneNumber : ''}
                                                disabled={newCustodyProfile.bndContactDetails.isSameContact}
                                            />
                                        </div>
                                    </FormField>

                                    <FormField className="form-group" id="alternateTelephoneNumber"
                                               error={errors && errors.bndErrors && errors.bndErrors.alternateTelephoneNumber}>
                                        <label className={labelClassList} htmlFor="province">Alternate Telephone Number</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="alternateTelephoneNumber"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && this._resetError('bndErrors');
                                                    this.handleOnBNDContactChange(event, 'alternateTelephoneNumber')
                                                }}
                                                type="text"
                                                value={newCustodyProfile && newCustodyProfile.bndContactDetails && newCustodyProfile.bndContactDetails.alternateTelephoneNumber
                                                    ? newCustodyProfile.bndContactDetails.alternateTelephoneNumber : ''}
                                                disabled={newCustodyProfile.bndContactDetails.isSameContact}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                                <div className="col-md-6">
                                <FormField className="form-group" id="modeOfCorrespondence"
                                               error={errors && errors.bndErrors && errors.bndErrors.modeOfCorrespondence}>
                                        <label className={labelClassList} htmlFor="province">Primary Mode Of
                                            Correspondence</label>
                                        <div className={inputClassList}>
                                            <Select
                                                name="modeOfCorrespondence"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && this._resetError('bndErrors');
                                                    this.handleSelectChange(event, 'modeOfCorrespondence','bndContactDetails')
                                                }}
                                                options={CONTACT_METHOD}
                                                value={newCustodyProfile && newCustodyProfile.bndContactDetails && newCustodyProfile.bndContactDetails.modeOfCorrespondence ? newCustodyProfile.bndContactDetails.modeOfCorrespondence : ''}
                                            />
                                        </div>
                                    </FormField>
                                    <FormField className="form-group" id="emailAddress" error={errors && errors.bndErrors && errors.bndErrors.emailAddress}>
                                        <label className={labelClassList} htmlFor="province">Email Address</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="emailAddress"
                                                onChange={(event) => {
                                                    errors && errors.beneficialErrors && this._resetError('bndErrors');
                                                    this.handleOnBNDContactChange(event, 'emailAddress')
                                                }}
                                                type="text"
                                                value={newCustodyProfile && newCustodyProfile.bndContactDetails && newCustodyProfile.bndContactDetails.emailAddress
                                                    ? newCustodyProfile.bndContactDetails.emailAddress : ''}
                                                disabled={newCustodyProfile.bndContactDetails.isSameContact}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                            </div>
                        </div>

                        </div>
                    </div>

                </div>

                <div className="page-footer-section">
                    <ButtonsComponent
                        history={this.props.history}
                        onNext={this.saveDetails}
                        onSaveAndContinue={this.saveAndContinue}
                        prevPage={newCustodyAccountRoutes.addressdetails}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps({custody}) {
    return {
        newCustodyProfile: custody.custodyRequest,
        appId: custody && custody.id
    }
}

function mapDispatchToProps(dispatch) {
    return {action: bindActionCreators(actions, dispatch)}
}


export default connect(mapStateToProps, mapDispatchToProps)(ContactDetailsPage);
