import _ from 'lodash';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function remediationReducer(state = initialState.remediation, action) {

    switch (action.type) {
        case types.FETCH_BOL_SUCCESS:
            return {...state, bolHierarchy: action.bolHierarchy};
        case types.LR_SELECT_ENTITY:
            return {...state, entity: action.entity};
        case types.LR_ACCOUNTS:
            return {...state, accounts: action.accounts};
        case types.LR_PROFILES:
            return {...state, profiles: action.profiles};
        case types.LR_ENTITIES:
            return {...state, entities: action.entities};
        case types.LR_CHANGE:
            return {...state, agreements: action.agreements};
        case types.LR_START:
            return {...state, ...action.agreements};
        case types.LR_DOC:
            return {...state, doc: action.documentStr};
        default:
            return state;
    }

}