import _ from 'lodash';
import * as types from '../actions/actionTypes';

const initialState = {}


export default function custodyREducer(state = initialState, action) {


    switch (action.type) {
        case types.ON_CHANGE_CUSTODY_VALUE :
            return {

                ...state, custodyRequest: {...state.custodyRequest,[action.objName]:

                    action.objName == 'cashAccountDetails' || action.objName == 'swiftMessages' ?
                        action.attribute
                        :
                        action.value == undefined || action.value == null && action.objName != 'cashAccountDetails' && action.objName != 'swiftMessages' ?
                            _.extend({}, state.custodyRequest[action.objName], action.attribute)
                            :
                            _.extend({}, state.custodyRequest[action.objName], action.attribute ? {[action.attribute]: action.value} : action.value)
            }};

        case types.ON_ADD_CASH_ACCOUNT_FOR_CUSTODY : {
            return action.accounts;
        }

        case types.FETCH_CUSTODY_DATA : {

            return {...state,...action.data};
        }

        case types.CUSTODY_APPLICATION_SUCCESS : {
            const update = action.custody ? {custody: action.custody} : {};
            return {...state, ...update, errorMessage: action.errorMessage};
        }


        default:
            return state

    }

}