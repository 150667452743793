import React, {useEffect, useState} from 'react';
import {businessOnlineRoutes} from '../../routers/routes';
import {useDispatch, useSelector} from 'react-redux';
import {clearIndiviualInfo, fetchIndiviualInfo} from '../../actions/kyc';
import FormField from '../formField/FormField';
import {Button, ControlLabel, Table} from 'react-bootstrap';
import ButtonsComponent from '../ButtonsComponent';
import {saveBolOnboard} from '../../actions/businessOnline';
import _ from 'lodash';
import countries from '../../data/countries';
import ReactLoading from 'react-loading';
import Select from 'react-select';

const roleOptions = [
    { value: 'Viewer', label: 'Viewer' },
    { value: 'Capturer', label: 'Capturer' },
    { value: 'Authoriser', label: 'Authoriser' },
    { value: 'Full Access', label: 'Full Access' },
    { value: 'DP Only', label: 'DP Only' }
];


const ErrorLabel = ({error}) => (
    <div>
        <span className="form-error">{error}</span>
    </div>
);

export const AuthorizationDetailsPage = (props) => {

    const businessOnlineApplication = useSelector(state => state.businessOnline && state.businessOnline);
    const businessOnline = useSelector(state => state.businessOnline && state.businessOnline.bolRequest);
    const individual = useSelector(state => state.individual && state.individual.individual);

    const [errors, setErrors] = useState({});
    const [isRSACitizen, setIsRSACitizen] = useState(true);
    const [isSearchComplete, setIsSearchComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [authorizationDetails, setAuthorizationDetails] = useState({operators: [], operator: {}});
    const [selectedOption, setSelectedOption] = useState(null);


    const shouldDisableInput = !(!isRSACitizen || isSearchComplete);

    const dispatch = useDispatch();

    useEffect(() => {
            if (individual && individual.firstName) {
                setAuthorizationDetails({
                    ...authorizationDetails, 'operator':
                        {
                            ...authorizationDetails.operator,
                            'firstName': individual.firstName,
                            'lastName': individual.lastName
                        }
                });
            }
        }, [individual]
    );

    useEffect(() => {
            if (businessOnline.authorisationDetails) {
                setAuthorizationDetails({...businessOnline.authorisationDetails, operator: {}});
            }
        }, []
    );

    const handleChangeSACitizen = (isRSACitizen) => {
        setIsRSACitizen({isRSACitizen});
    };

    const onIndividualSearch = () => {
        const onFinish = () => {
            setIsSearchComplete(true);
            setLoading(false);
        };
        const onError = () => {
            // this.setState({isLoading: false});
            setIsSearchComplete(false);
            setLoading(false);
            setErrors({idNumber: ['Individual not found on search. Check ID Number.']});
        };
        setLoading(true);
        dispatch(fetchIndiviualInfo(authorizationDetails.operator.idNumber, onFinish, null, onError));
    };

    const handleToggle = (attribute, value) => {
        setAuthorizationDetails({
            ...authorizationDetails,
            'operator': {...authorizationDetails.operator, [attribute]: value}
        });
    };

    const handleChange = ({target}, attribute) => {
        let {value, type} = target;
        if (type === 'number') {
            value = parseFloat(value);
        }
        setAuthorizationDetails({
            ...authorizationDetails,
            'operator': {...authorizationDetails.operator, [attribute]: value}
        });
    };

    const addUser = () => {
        let valid = validateFields(['existingUser', 'idType', 'idNumber', 'firstName', 'lastName', 'contactNumber', 'designatedPerson', 'userRole']);
        let userRoleValid = false;
        if (valid) {
            if (authorizationDetails.operator.userRole && authorizationDetails.operator.userRole !== 'Capturer') {
                userRoleValid = validateFields(['releaseLevel', 'mandatory', 'limitFrom', 'limitTo']);
            } else {
                userRoleValid = true;
            }
        }
        if (valid && userRoleValid) {
            let op = {...authorizationDetails.operator};
            console.log(op);

            setIsSearchComplete(false);
            setAuthorizationDetails({
                ...authorizationDetails,
                'operators': [...authorizationDetails.operators, op],
                'operator': {}
            });
            dispatch(clearIndiviualInfo());
        }
    };

    const validateFields = (fields) => {
        const errors = {};
        let op = {...authorizationDetails.operator};
        const vv = fields.map((field) => {
            if (op[field]) {
                return true;
            } else {
                errors[field] = 'Required';
                return false;
            }
        });
        setErrors(errors);
        return !vv.includes(false, 0);
    };


    const onNext = () => {
        const onSuccess = () => {
            props.history.push(businessOnlineRoutes.contactDetails);
        };
        if(authorizationDetails.operators && authorizationDetails.operators.length > 0) {
            dispatch(saveBolOnboard(businessOnlineApplication.id, _.extend({}, businessOnline, {['authorisationDetails']: authorizationDetails}), 'UserTask_OperatorDetails', onSuccess));
        }else{
            setErrors({'operators': 'Please add operators'});
        }
    };


    function handleDeleteUser(user) {
        console.log(user.idNumber);
        let arr = [...authorizationDetails.operators];
        _.remove(arr, i => i.idNumber === user.idNumber);
        setAuthorizationDetails({...authorizationDetails, 'operators': arr});

    }

    const renderLoading = () => {
        if (loading) return (
            <div className="rule-spinner">
                <ReactLoading type="spokes" color="#444"/>
            </div>);
    };


    const renderUserSummary = () => {
        return (
            <div id="userSummary">
                {errors.operators && <ErrorLabel error={errors.operators}/>}
                <Table striped>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Release Level</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {authorizationDetails.operators && authorizationDetails.operators.map((user, i) => {
                        return (
                            <tr key={i}>
                                <td>{user.firstName}</td>
                                <td>{user.userRole}</td>
                                <td>{user.releaseLevel}</td>
                                <td><Button onClick={() => handleDeleteUser(user)}><i
                                    className="fa fa-trash"/></Button></td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </div>
        );
    };

    const phoneOptions = _.map(individual && individual.telephones, (t) => ({value: t.msisdn, label: t.msisdn}));

    const hasPhoneOptions = _.size(phoneOptions) > 0;

    return (

        <div className="container-fluid">
            <h3>Please provide operator details</h3>
            <hr/>
            <div className="row">

                <div className="col-md-6">

                    <div className="card-container-form">

                        <div className="form-group">
                            <div className="row">
                                <label className="col-md-4 control-label">Is this an exisisting operator?</label>
                                <div className="col-md-2">
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                value="Yes"
                                                checked={authorizationDetails.operator.existingUser === 'Yes'}
                                                onChange={(event) => {
                                                    handleChange(event, 'existingUser');
                                                }}
                                            />
                                            Yes
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                value="No"
                                                checked={authorizationDetails.operator.existingUser === 'No'}
                                                onChange={(event) => {
                                                    handleChange(event, 'existingUser');
                                                }}
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                                {errors.existingUser &&
                                <div className="col-md-8 col-md-offset-4"><ErrorLabel error={errors.existingUser}/>
                                </div>}
                            </div>
                        </div>

                        {authorizationDetails.operator.existingUser === 'Yes' &&
                        <div className="form-group row">
                            <label
                                className="col-md-4 control-label">Operator ID</label>
                            <FormField className="col-md-4">
                                <input className="form-control" type="text" onChange={(event) => {
                                    handleChange(event, 'operatorId');
                                }} value={authorizationDetails.operator.operatorId || ''}/>
                            </FormField>
                        </div>}


                        <div className="form-group row">
                            <label
                                className="col-md-4 control-label">ID Type</label>
                            <FormField className="col-md-4">
                                <select
                                    type="text"
                                    className="form-control"
                                    id="idType"
                                    value={authorizationDetails.operator.idType || ''}
                                    onChange={(event) => {
                                        handleChange(event, 'idType');
                                    }}>
                                    <option value="">--SELECT--</option>
                                    <option>South African ID</option>
                                    <option>Passport</option>
                                    <option>Other</option>
                                </select>
                                {errors.idType && <ErrorLabel error={errors.idType}/>}
                            </FormField>
                        </div>

                        {authorizationDetails.operator.idType === 'Other' &&
                        <div className="form-group row">
                            <label
                                className="col-md-4 control-label">Specify ID Type </label>
                            <FormField className="col-md-4">
                                <input className="form-control" type="text" onChange={(event) => {
                                    handleChange(event, 'otherIdType');
                                }} value={authorizationDetails.operator.otherIdType || ''}/>
                            </FormField>
                        </div>}

                        {
                            !shouldDisableInput || authorizationDetails.operator.idType !== 'South African ID' ?
                                <div className="form-group row">
                                    <label
                                        className="col-md-4 control-label">Passport Number</label>
                                    <FormField className="col-md-4">
                                        <input className="form-control" type="text" onChange={(event) => {
                                            handleChange(event, 'idNumber');
                                        }} value={authorizationDetails.operator.idNumber || ''}/>
                                        {errors.idNumber && <ErrorLabel error={errors.idNumber}/>}
                                    </FormField>
                                </div>
                                :
                                <div className="form-group row">
                                    <label className="col-md-4 control-label">Search ID Number</label>
                                    <FormField className="col-md-4" error={errors && errors.idNumber}>
                                        <div className="input-group">
                                            <input className="form-control" type="text" onChange={(event) => {
                                                handleChange(event, 'idNumber');
                                            }} value={authorizationDetails.operator.idNumber || ''}/>
                                            <span className="input-group-btn">
                                     <button
                                         disabled={_.size(authorizationDetails.operator.idNumber) === 0}
                                         className="btn btn-primary" style={{color: 'white', fontWeight: '700'}}
                                         type="button"
                                         onClick={onIndividualSearch}
                                     >Search</button>
                                </span>
                                        </div>
                                    </FormField>
                                </div>

                        }

                        <div className="form-group row">
                            <label
                                className="col-md-4 control-label">First Name</label>
                            <FormField className="col-md-4">
                                <input className="form-control" type="text" onChange={(event) => {
                                    handleChange(event, 'firstName');
                                }} value={authorizationDetails.operator.firstName || ''}/>
                                {errors.firstName && <ErrorLabel error={errors.firstName}/>}
                            </FormField>
                        </div>

                        <div className="form-group row">
                            <label
                                className="col-md-4 control-label">Surname</label>
                            <FormField className="col-md-4">
                                <input className="form-control" type="text" onChange={(event) => {
                                    handleChange(event, 'lastName');
                                }} value={authorizationDetails.operator.lastName || ''}/>
                                {errors.lastName && <ErrorLabel error={errors.lastName}/>}
                            </FormField>
                        </div>

                        <div className="form-group row">
                            <label
                                className="col-md-4 control-label">Cellphone Number</label>
                            {
                                hasPhoneOptions ?

                                    <FormField className="col-md-4">
                                        <select
                                            type="text"
                                            className="form-control"
                                            id="contactNumber"
                                            value={authorizationDetails.operator.contactNumber || ''}
                                            onChange={(event) => {
                                                handleChange(event, 'contactNumber');
                                            }}>
                                            <option value="">--SELECT--</option>
                                            {phoneOptions.map((e) => <option>{e.value}</option>)}

                                        </select>
                                        {errors.contactNumber && <ErrorLabel error={errors.contactNumber}/>}
                                    </FormField>
                                    :
                                    <FormField className="col-md-4">
                                        <input
                                            className="form-control"
                                            id="contactNumber"
                                            onChange={(event) => {
                                                handleChange(event, 'contactNumber');
                                            }}
                                            type="text"
                                            value={authorizationDetails.operator.contactNumber || ''}
                                        />
                                        {errors.contactNumber && <ErrorLabel error={errors.contactNumber}/>}
                                    </FormField>
                            }
                        </div>


                        <div className="form-group row">
                            <label
                                className="col-md-4 control-label">Country</label>
                            <FormField className="col-md-4">
                                <select
                                    type="text"
                                    className="form-control"
                                    id="country"
                                    value={authorizationDetails.operator.country || ''}
                                    onChange={(event) => {
                                        handleChange(event, 'country');
                                    }}>
                                    <option value="">--SELECT--</option>
                                    <option>South Africa</option>
                                    {countries.map((e) => <option>{e.value}</option>)}

                                </select>

                                {errors.country && <ErrorLabel error={errors.country}/>}
                            </FormField>
                        </div>

                        <div className="form-group">
                            <div className="row">
                                <label className="col-md-4 control-label">Designated Person</label>
                                <div className="col-md-2">
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                value="Yes"
                                                checked={authorizationDetails.operator.designatedPerson === 'Yes'}
                                                onChange={(event) => {
                                                    handleChange(event, 'designatedPerson');
                                                }}
                                            />
                                            Yes
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                value="No"
                                                checked={authorizationDetails.operator.designatedPerson === 'No'}
                                                onChange={(event) => {
                                                    handleChange(event, 'designatedPerson');
                                                }}
                                            />
                                            No
                                        </label>
                                    </div>
                                </div>
                                {errors.designatedPerson &&
                                <div className="col-md-8 col-md-offset-4"><ErrorLabel error={errors.designatedPerson}/>
                                </div>}

                            </div>
                        </div>

                        <div className="form-group row">
                            <label
                                className="col-md-4 control-label">Operator Role</label>
                            <FormField className="col-md-4">
                                <Select options={roleOptions}  simpleValue={true} multi={true}
                                        value={authorizationDetails.operator.userRole? roleOptions.filter(obj => authorizationDetails.operator.userRole.includes(obj.value)) : []}
                                        onChange={(event) => {
                                    setAuthorizationDetails({
                                        ...authorizationDetails, 'operator':
                                            {
                                                ...authorizationDetails.operator,
                                                'userRole': event
                                            }
                                    });
                                    console.log(event);
                                }} />
                                {errors.userRole && <ErrorLabel error={errors.userRole}/>}
                            </FormField>
                        </div>

                        {authorizationDetails.operator.userRole && authorizationDetails.operator.userRole !== 'Capturer' &&
                        <div>
                            <div className="form-group row">
                                <label
                                    className="col-md-4 control-label">Release Level</label>
                                <FormField className="col-md-4">
                                    <select
                                        type="text"
                                        className="form-control"
                                        id="idType"
                                        value={authorizationDetails.operator.releaseLevel}
                                        onChange={(event) => {
                                            handleChange(event, 'releaseLevel');
                                        }}
                                    >
                                        <option value="">--SELECT--</option>
                                        <option>Level 1</option>
                                        <option>Level 2</option>
                                    </select>
                                    {errors.releaseLevel && <ErrorLabel error={errors.releaseLevel}/>}
                                </FormField>
                            </div>

                            <div className="form-group row">
                                <label className="col-md-4 control-label"></label>
                                <div className="col-md-2">
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                value="Yes"
                                                checked={authorizationDetails.operator.mandatory === 'Yes'}
                                                onChange={(event) => {
                                                    handleChange(event, 'mandatory');
                                                }}
                                            />
                                            Mandatory
                                        </label>
                                    </div>
                                </div>

                                <div className="col-md-2">
                                    <div className="radio">
                                        <label>
                                            <input
                                                type="radio"
                                                value="No"
                                                checked={authorizationDetails.operator.mandatory === 'No'}
                                                onChange={(event) => {
                                                    handleChange(event, 'mandatory');
                                                }}
                                            />
                                            Non-Mandatory
                                        </label>
                                    </div>
                                </div>
                                {errors.mandatory &&
                                <div className="col-md-8 col-md-offset-4"><ErrorLabel error={errors.mandatory}/></div>}
                            </div>
                            <div className="form-group row">
                                <label className="col-md-4 control-label">Limit From<span
                                    className="text-danger">*</span></label>
                                <FormField className="col-md-2">
                                    <input className="form-control" type="number" onChange={(event) => {
                                        handleChange(event, 'limitFrom');
                                    }} min={0} value={authorizationDetails.operator.limitFrom}/>
                                    {errors.limitFrom && <ErrorLabel error={errors.limitFrom}/>}
                                </FormField>

                                <label className="col-md-1 control-label">Limit To</label>
                                <FormField className="col-md-2">
                                    <input className="form-control" type="number" onChange={(event) => {
                                        handleChange(event, 'limitTo');
                                    }} min={0} value={authorizationDetails.operator.limitTo}/>
                                    {errors.limitTo && <ErrorLabel error={errors.limitTo}/>}
                                </FormField>
                            </div>
                        </div>

                        }
                        <div className="action-button-container" style={{width: '100%'}}>
                            <div className="col-md-4 col-md-offset-4">
                                <button
                                    className="action-btn-secondary"
                                    onClick={addUser}
                                    type="button"
                                >Add
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-md-6">
                    <h4>Summary of Operators</h4>
                    {renderLoading()}
                    {renderUserSummary()}
                </div>
            </div>

            <div className="row">
                <br/>
                <div className='col-md-12'>
                    <ButtonsComponent
                        history={props.history}
                        prevPage={businessOnlineRoutes.accountSetup}
                        onNext={onNext}/>
                </div>
                <div/>
            </div>
        </div>
    );
};

export default AuthorizationDetailsPage;
