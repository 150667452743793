import React, { useState } from "react";
import _ from "lodash";
import { ListSearchComponent } from "../SearchComponent";
import moment from "moment";
const ConfirmAndSignatoriesAccountList = (props) => {
  const {
    accounts,
    filteredAccounts,
    onSelect,
    onSelectAll,
    onFilterAccounts,
    listStyle,
    hideDate,
    hasShortName,
    searchAccount,
    canSelectAll,
    searchButton,
    searchTerm,
    searchError
  } = props;

  

  if (!accounts) return null;

  const groupedAccs = filteredAccounts || accounts;
  const notAllSelected =
    _.size(_.find(groupedAccs, (acc) => acc[0] && !acc[0].isSelected)) > 0;
  const tstamp = new Date().getTime();
  const [selectedCount, setSelectedCount] = useState(0);

  // State for search button visibility
  const handleSelect = (e, account) => {
    onSelect(e, account);
    setSelectedCount((prevCount) =>
      account.isSelected ? prevCount - 1 : prevCount + 1
    );
  };
  const handleSelectAll = () => {
    const newCount = notAllSelected ? groupedAccs.length : 0;
    onSelectAll(notAllSelected);
    setSelectedCount(newCount);
  };

  return (
    <div>
      {/* Unselected Accounts List */}
      <div className="row" style={{ margin: 0 }}>
        {canSelectAll && (
          <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: 0 }}>
            <div className="selection-list-item" onClick={handleSelectAll}>
              <i
                className={
                  !notAllSelected ? "fa fa-check-circle" : "fa fa-circle-o"
                }
                style={{
                  color: "rgb(0, 51, 170)",
                  fontSize: 20,
                  marginRight: 10,
                }}
              />
              <span className="active-entity" style={{ marginLeft: 10 }}>
                Select All
              </span>
            </div>
          </div>
        )}
        <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: 0 }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
            <ListSearchComponent
              onFilter={(query) => onFilterAccounts(query)}
              placeholder="Find account..."
            />
          </div>
          <span className="form-error">{searchError}</span>
        </div>

        {/* Conditional rendering of the Search Account button */}
        {searchButton && !isNaN(searchTerm) && (
          <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: 0 }}>
            <button className="action-btn-primary btn-sm" style={{ width: "auto", marginLeft: "20px" }} onClick={searchAccount} >
              Search Account
            </button>
          </div>
        )}
      </div>
      <div className="row" style={{ margin: 0 }}>
        <div
          className="col-lg-6 col-md-6 col-sm-12"
          style={{ padding: 0, borderLeft: "1px solid #c0c0c0", borderRight: "1px solid #c0c0c0"}}>
          <div
            className="selection-list"
            style={listStyle ? listStyle : { maxHeight: "450px", margin: 0 }}
          >
            {_.map(groupedAccs, (acc, i) => {
              const isSelected = acc.isSelected;
              return (
                !isSelected && (
                  <div
                    className="selection-list-item"
                    key={`acc-${i}`}
                    onClick={(e) => handleSelect(e, acc)}
                  >
                    <div className="icon-container">
                      <i
                        className="fa fa-square-o icon"
                        style={{ fontSize: 20 }}
                      />
                    </div>
                    <div className="column">
                      <div key={`acc-${acc.accountNumber}-${i}-${tstamp}`}>
                        <span>{acc.name} | </span>
                        <span className="greyText">
                          {acc.accountNumber}
                          {!hideDate &&
                            "| " +
                              moment(acc.accountOpenDate).format(
                                "MMMM D, YYYY"
                              )}
                        </span>
                        <span>
                          {hasShortName &&
                            acc.shortName &&
                            "| " + acc.shortName}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
        {/* Selected Accounts List */}
        <div
          className="col-lg-6 col-md-6 col-sm-12"
          style={{ padding: 0, borderRight: "1px solid #c0c0c0" }}>
          <div>
            {_.map(groupedAccs, (acc, i) => {
              const isSelected = acc.isSelected;
              return (
                isSelected && (
                  <div
                    className="selection-list-item-selected"
                    key={`selected-acc-${i}`}
                    onClick={(e) => handleSelect(e, acc)}
                  >
                    <div className="icon-container">
                      <i
                        className="fa fa-check-square-o icon"
                        style={{ fontSize: 20 }}
                      />
                    </div>
                    <div className="column">
                      <div key={`acc-${acc.accountNumber}-${i}-${tstamp}`}>
                        <span>{acc.name} | </span>
                        <span className="greyText">
                          {acc.accountNumber}
                          {!hideDate &&
                            "| " +
                              moment(acc.accountOpenDate).format(
                                "MMMM D, YYYY"
                              )}
                        </span>
                        <span>
                          {hasShortName &&
                            acc.shortName &&
                            "| " + acc.shortName}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ConfirmAndSignatoriesAccountList;
