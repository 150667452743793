import * as types from './actionTypes'

export function loadingPageDataStart(dispatch) {
    setTimeout(() => dispatch({type: types.CLEAR_SYSTEM_ERROR}), 1);
    setTimeout(() => dispatch({type: types.LOADING_START}), 1)
}

export function loadingPageDataFinish(dispatch, hasSystemError = false, status = null) {
    setTimeout(() => dispatch({type: types.LOADING_FINISH}), 1);
    if (hasSystemError) {
        setTimeout(() => dispatch({type: types.SYSTEM_ERROR, status}),1)
    }
}

export function authCheck(dispatch, error) {
    const status = error && error.response && error.response.status;
    if(status == 401){
        dispatch({type: types.AUTH_ERROR, status})
    }
}