import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

class CompletePage extends Component {
    
    handleOnNavgiateToHome = (event) => {
        event.preventDefault();
        const {history} = this.props;
        history.replace("/");
    };

    render() {
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="card-container">
                        <div className="page-main-section">
                            <div className="card-container">
                                <div className="section-title">Application Submitted</div>
                                <div>
                                    <div className="product-heading">
                                        TPFA Accounts
                                    </div>
                                    <div className="title-gradient" />
                                </div>

                                <br />
                                <p>
                                    Once approved, please expect your application to be processed and accounts to be loaded onto Business Online within 24 hours. 
                                    Please note that requests need to be submitted by 14h00 Monday -Friday, 
                                    excluding public holidays to ensure that the accounts are approved and loaded by the next business working day.
                                </p>
                                <p>
                                    When the account/s are available on Business Online please ensure that you capture the information related to the Investor Principal to activate the accounts at your earliest convenience.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-footer-section" style={{width:'80%'}}>
                    <div className="action-button-container" style={{float:"inline-end"}}>
                        {/* <button
                            className="action-btn-primary btn-sm"
                            onClick={this.handleOnDownloadApplication}
                            type="submit"
                        >Download Application
                        </button> */}
                        <button
                            className="action-btn-primary btn-sm"
                            onClick={this.handleOnNavgiateToHome} 
                            type="button"
                        >Home
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps({accounts, individual: {individual}, businessOnline: {id, document}, impersonation, loading}) {
    return {
        id,
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletePage);