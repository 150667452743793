import React, {Component, useEffect, useImperativeHandle, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Col from "react-bootstrap/es/Col";
import BolComponent from "./BolComponent";
import HelpBlock from "react-bootstrap/es/HelpBlock";
import FormField from "../formField/FormField";
import {additionalProductChange} from "../../actions/tpfaActions";

const AdditionalProducts = () => {

    const application = useSelector(state => state.tpfa.application);
    const additionalProducts = application.additionalProducts;

    const dispatch = useDispatch();

    const handleAdditionalProduct = (product, active = false) => {
        console.log(product, active);
        additionalProducts[product] = active ? {} : null;
        dispatch(additionalProductChange(additionalProducts));
    };
    const handleAdditionalProductChange = (product, change) => {
        additionalProducts[product] = change;
        dispatch(additionalProductChange(additionalProducts));
    };

    const onCashManChange = ({target}) => {
        const {cashManagement} = additionalProducts;
        // const {application: {additionalProducts: {cashManagement}}} = this.props;
        cashManagement.cashManGroupNumber = target.value;
        dispatch(handleAdditionalProductChange("cashManagement", cashManagement));
    };

    const onBolChange = (product) => {
        // const {onProductChange} = this.props;
        // businessOnline[name] = value;
        dispatch(handleAdditionalProductChange("businessOnline", product));
    };

    const businessOnline = () => {
        return (
            <div>
                <div
                    className="inline-container"
                    // onClick={() => handleAdditionalProduct("businessOnline", !(additionalProducts && additionalProducts.businessOnline))}
                >
                    {/*<div className="icon-container">*/}
                    {/*<i className={(additionalProducts && additionalProducts.businessOnline ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}*/}
                    {/*style={{fontSize: 20}}/>*/}
                    {/*</div>*/}
                    <h3>Business Online</h3>
                </div>
                <br/>
                <BolComponent
                    // bolProduct={additionalProducts.businessOnline}
                    bolProfiles={null}
                    bolProfileOperators={null}
                    errors={null}
                    onFetchOperators={null}
                    onChange={onBolChange}
                    // resetErrors={this.resetError}
                />
            </div>
        )

    };

    const cashMan = () => {
        // const {cashManagement} = additionalProducts;
        return (
            <div>
                <div
                    className="inline-container"
                    // onClick={() => handleAdditionalProduct("cashManagement", !(additionalProducts && additionalProducts.cashManagement))}
                >
                    {/*<div className="icon-container">*/}
                    {/*<i className={((additionalProducts && additionalProducts.cashManagement) ? "fa fa-check-square-o" : "fa fa-square-o") + " icon"}*/}
                    {/*style={{fontSize: 20}}/>*/}
                    {/*</div>*/}
                    <h3>Cash Management</h3>
                </div>
                <br/>
                <FormField className="form-group" id="cashman"
                           error={null}>
                    <label>Cashman Group Number</label>

                    <input
                        className="form-control"
                        id="cashManGroupNumber"
                        name="cashManGroupNumber"
                        maxLength={5}
                        min={0}
                        max={99999}
                        onChange={(event) => {
                            // errors && errors.cashManGroupNumber && this.resetError('cashManGroupNumber');
                            onCashManChange(event);
                        }}
                        type="number"
                        // value={cashManagement && cashManagement.cashManGroupNumber ? cashManagement.cashManGroupNumber : null}
                    />
                </FormField>
            </div>
        )

    };

    return (
        <div className={'container-fluid'}>
            <Col sm={12}>
                <h2>Additional Products</h2>
                <HelpBlock>Please check boxes for the additional products you'd like to add the accounts to.</HelpBlock>
            </Col>
            <Col md={9} mdOffset={2}>
                <Col sm={6}>
                    {businessOnline()}
                </Col>
                <Col sm={6}>
                    {cashMan()}
                </Col>
            </Col>
        </div>
    );
};

export default AdditionalProducts;