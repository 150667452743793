import React, { Component } from 'react';
import _ from 'lodash';
import connect from 'react-redux/es/connect/connect';
import { Button, PanelGroup, Modal, Panel, Badge, Col } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import NewAuthorizedPerson from './NewAuthorisedPerson';
import ConfirmDelete from './ConfirmDelete';
import FormField from '../formField/FormField';
import { bindActionCreators } from "redux";
import validate from 'validate.js';
import * as entityActions from "../../actions/entity";
import { clearIndiviualInfo, fetchIndiviualInfo, uploadDoc, verifyDoc } from "../../actions/kyc";

class AuthorisedPersonsComponent extends Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleNewAuthorisedPerson = this.handleNewAuthorisedPerson.bind(this);
        this.state = {
            activeKey: null,
            errors: null,
            newAuthorisedErrors: {},
            newAuthorised: false,
            isLoading: false,
            newAP: {
                new: true,
                identityDocument: {},
                address: {
                    line1: '',
                    line2: '',
                    line3: '',
                    postalCode: ''
                },
            },
            searchComplete: false,
            removing: false,
            removingAP: null,
            editMode: false,
            selectedAddress: {
                line1: '',
                line2: '',
                line3: '',
                postalCode: ''
            },
            selectedAddresses: [],
            saCitizen: true,
        };
    }

    handleNewAuthorisedPerson() {
        const { newAP } = this.state;
        const { handleAddAuthorisedPerson } = this.props;
        let newAuthorisedErrors = {};

        // Check if newAP.address is undefined
        if (!newAP.address) {
            console.error("newAP.address is undefined");
            // Handle the case where newAP.address is undefined
            // You might want to display an error message or return early
            return;
        }

        const { firstName, lastName, identityNumber, email, phoneNumber, address } = newAP;

        // Destructure address properties
        const { line1, line2, line3, postalCode } = address;

        // Validate required fields
        if (!firstName || !lastName || !identityNumber || !email || !phoneNumber || !line1 || !line2 || !line3 || !postalCode) {
            newAuthorisedErrors = { error: 'One or more fields are missing' };
        }

        if (_.isEmpty(newAuthorisedErrors)) {
            // If there are no validation errors, add the new authorized person
            handleAddAuthorisedPerson(newAP);

            // Reset state after successful addition
            this.setState({
                newAuthorisedErrors: {},
                newAuthorised: false,
                newAP: {
                    // Reset newAP to some default values or an empty object
                    // Modify this part according to your requirements
                    firstName: '',
                    lastName: '',
                    identityNumber: '',
                    // Add other properties with default values if needed
                },
                selectedAddresses: [
                    ...this.state.selectedAddresses,
                    { line1: '', line2: '', line3: '', postalCode: '', email: '' }
                ],
            });
        } else {
            // If there are validation errors, update the state with errors
            this.setState({ newAuthorisedErrors });
        }
    };

    validateForm = (person, personIndex) => {
        const { selectedAddresses, errors } = this.state;
        const options = { fullMessages: false };
    
        const constraints = {
            email: {
                presence: true,
                email: {
                    message: 'should be a valid email address',
                },
            },
            line1: { presence: true, length: { minimum: 1, message: 'required' } },
            line2: { presence: true, length: { minimum: 1, message: 'required' } },
            line3: { presence: true, length: { minimum: 1, message: 'required' } },
            postalCode: { presence: true, length: { minimum: 1, message: 'required' } },
        };
    
        const activePersonAddress = { ...person.address, email: person.email };
    
        const addressErrors = validate(activePersonAddress, constraints, options);
    
        const updatedErrors = { ...errors };
        updatedErrors[personIndex] = { ...addressErrors };
    
        // If there are no errors in the address, also clear the person-level address errors
        if (_.isEmpty(addressErrors)) {
            updatedErrors[personIndex] = null;
        }
    
        this.setState((prevState) => {
            // Update state based on the validation result
            return {
                errors: { ...prevState.errors, ...updatedErrors },
            };
        });
    
        return _.isEmpty(addressErrors);
    };
    

    // onSave = () => {
    //     const {handleSave} = this.props;
    //     // handleSave && handleSave();
    //     this.setState({activeKey: true});
    // };

    onSave = (person) => {
        const { personsList, handleSave, onAddressChange } = this.props;
        const { selectedAddresses, activeKey } = this.state;

        // if(personsList.new = true){

        //     const selectedAddresses = personsList.filter((record) => {
        //         return record.new === true;
        //     })[0];
            
        //     console.log(newRecord);
        //     person.address.line1 = newRecord.address.line1;
        //     person.address.line2 = newRecord.address.line2;
        //     person.address.line3 = newRecord.address.line3;
        //     person.address.postalCode = newRecord.address.postalCode;
        //     person.email = newRecord.email;
        //     selectedAddresses = newRecord;
        // }
        
        // Assuming you have the index of the active person in personsList
        const activePersonIndex = activeKey;

        // Ensure the index is valid
        if (activePersonIndex >= 0 && activePersonIndex < personsList.length) {
            // Validate the form for the active person
            if (this.validateForm(person, activePersonIndex)) {
                const activePerson = personsList[activePersonIndex];

                // Update the address of the active person with the selectedAddress
                // const updatedPerson = {
                //     ...activePerson,
                //     email: selectedAddresses[activeKey].email, // Include the email value
                //     address: {
                //         line1: selectedAddresses[activeKey].line1,
                //         line2: selectedAddresses[activeKey].line2,
                //         line3: selectedAddresses[activeKey].line3,
                //         postalCode: selectedAddresses[activeKey].postalCode,
                //     },
                // };

                // Dispatch an action or handle the save logic with the updatedPerson
                onAddressChange(activePersonIndex, activePerson);

                // Optionally, you can call handleSave if needed
                handleSave && handleSave();

                // Reset the activeKey to null to close the panel
                this.setState({ activeKey: null });

            }
        }
    };

    handleSelect(activeKey) {
        this.setState({ activeKey });
    }

    onIndividualSearch = () => {
        this.setState({ errors: {} });
        this.loadingStart();

        const onFinish = (finish, individual) => {
            let { newAP } = this.state;
            this.loadingFinish();
            newAP = { ...newAP, ...individual };
            this.setState({ newAP, searchComplete: true });
        };
        const onError = () => {
            this.loadingFinish();
            this.setState({
                searchComplete: true,
                errors: { registrationNumber: ['Individual not found on search.'] }
            });
        };
        this.props.actions.fetchIndiviualInfo(this.state.newAP.identityNumber, onFinish, "AP", onError);
    };

    loadingStart = () => {
        this.setState({ isLoading: true })
    };

    loadingFinish = () => {
        this.setState({ isLoading: false })
    };

    handleNewAPChange = ({ target: { value } }, property) => {
        const { newAP } = this.state;
        newAP[property] = value;
        this.setState({ newAP })
    };

    // handleNewAPAddressChange = ({target: {value}}, property) => {
    //     let {newAP, newAP: {address}} = this.state;
    //     address = address ? address : {};
    //     address[property] = value;
    //     newAP.address = address;
    //     this.setState({newAP})
    // };

    handleNewAPAddressChange = ({ target: { value } }, property) => {
        this.setState((prevState) => {
            const { newAP } = prevState;
            const { address } = newAP;

            // Create a copy of the address to ensure immutability
            const updatedAddress = { ...address };

            // Update the specific property of the address
            updatedAddress[property] = value;

            // Update the newAP object with the updated address
            const updatedNewAP = { ...newAP, address: updatedAddress };

            // Return the updated state
            return { newAP: updatedNewAP };
        });
    };

    handleNewAPSelect = ({ value }, property) => {
        const { newAP } = this.state;
        const addrObj = newAP.addresses;
        const { individual: { individual } } = this.props;
        if (!newAP.address) { newAP.address = {}; }
        switch (property) {
            case 'phoneNumber':
                newAP[property] = value;
                break;
            case 'address':
                const filteredAddrObj = addrObj.filter((addr) => addr.line1 === value);

                if (filteredAddrObj.length > 0) {
                    const matchedAddr = filteredAddrObj[0];
                    newAP.address.line1 = matchedAddr.line1;
                    newAP.address.line2 = matchedAddr.line2;
                    newAP.address.line3 = matchedAddr.line3;
                    newAP.address.postalCode = matchedAddr.postalCode;
                } else {
                    // Handle the case when no matching element was found
                    console.error('No matching address found');
                    // You might want to set default values or show an error message
                }

                break;
        }
        this.setState({ newAP });
    };

    onRemoveAP = (value) => {
        this.setState({ removing: true, removingAP: value })
    }

    confirmRemove = () => {
        const { handleRemoveAP } = this.props;
        handleRemoveAP(this.state.removingAP);
        this.cancelRemove();
    }
    cancelRemove = () => {
        this.setState({ removing: false, removingAP: null });
    }
    onHandleUpload = (base64, inProgress, onComplete, onSuccess) => {
        const { actions } = this.props;
        const { newAP } = this.state;

        if(!newAP.identityDocument) {
            newAP.identityDocument = {};
        }
        const handleOnSuccess = (documentId) => {
            newAP.identityDocument.isUpload = true;
            newAP.identityDocument.updated = true;
            newAP.identityDocument.externalId = documentId;
            this.setState({ newAP });
            onSuccess && onSuccess(documentId);
        };
        actions.uploadDoc(base64, handleOnSuccess, null, inProgress, onComplete)
    };

    removeConfirm = () => {
        const { personsList } = this.props;
        const person = personsList[this.state.removingAP];
        return (
            <ConfirmDelete
                type={'Authorised Person'}
                confirmAction={this.confirmRemove}
                cancelAction={this.cancelRemove}
                detail={`${person.firstName} ${person.middleName ? person.middleName : ''} ${person.lastName}`}
            />
        )
    }

    // onAddressUpdate = (item, change, person, value) => {
    //     const {onAddressChange} = this.props;
    //     const address = person.address ? person.address : {};
    //     address[item] = change;
    //     person.address = {...address};
    //     onAddressChange(value, person);
    // };

    onAddressUpdate = (item, change, person, value) => {
        const { onAddressChange } = this.props;
        if (item === 'email') {
            person.email = change; // Update the email property
        } else {
            const address = person.address ? { ...person.address } : {};
            address[item] = change;
            person.address = { ...address };
        }
        onAddressChange(value, person);
    };

    // onAddressChoice = (choice, value, person) => {
    //     const {onAddressChange} = this.props;
    //     person.address = {...person.addresses[choice]};
    //     onAddressChange(value, person);
    //     // this.onSave();
    // };

    // onAddressChoice = (choice, index, person) => {
    //     const { addresses } = person;
    //     const selectedAddress = addresses[choice];

    //     if (selectedAddress) {
    //         this.setState((prevState) => {
    //             const { selectedAddresses } = prevState;
    //             const updatedAddresses = [...selectedAddresses];
    //             updatedAddresses[index] = {
    //                 ...updatedAddresses[index], // Preserve existing fields
    //                 line1: selectedAddress.line1 || '',
    //                 line2: selectedAddress.line2 || '',
    //                 line3: selectedAddress.line3 || '',
    //                 postalCode: selectedAddress.postalCode || '',
    //             };

    //             return { selectedAddresses: updatedAddresses };
    //         });
    //     } else {
    //         // Handle the case when the selected address is not available
    //         this.setState((prevState) => {
    //             const { selectedAddresses } = prevState;
    //             const updatedAddresses = [...selectedAddresses];
    //             updatedAddresses[index] = {
    //                 line1: '',
    //                 line2: '',
    //                 line3: '',
    //                 postalCode: '',
    //                 // Preserve existing email field
    //                 email: updatedAddresses[index] ? updatedAddresses[index].email || '' : '',
    //             };

    //             return { selectedAddresses: updatedAddresses };
    //         });
    //     }
    // };


    handleInputChange = (field, value, person) => {
        this.setState((prevState) => {
            // const { selectedAddresses, activeKey } = prevState;
    
            // Create a copy of the selected addresses array
            const updatedAddresses = person;
    
            // Create a copy of the selected address object
            const updatedAddress = { ...updatedAddresses.address };
    
            // Dynamically set the field in the selected address object
            updatedAddress[field] = value;
    
            // Set the updated address object back to the array
            updatedAddresses.address = updatedAddress;
    
            // Log the updated person
            console.log(person);
    
            return { person: updatedAddresses };
        });
    };
    


    handleEmailChange = (field, value, person) => {
        this.setState((prevState) => {
            const { selectedAddresses, activeKey } = prevState;
    
            // Create a copy of the selected addresses array
            const updatedAddresses = [...selectedAddresses];
    
            // Create a copy of the selected address object
            const updatedAddress = { ...updatedAddresses[activeKey], [field]: value };
    
            // Update the person's email
            person.email = value;
    
            // Log the updated person
            console.log(person);
    
            // Uncomment the following lines if you need to update the state
            updatedAddresses[activeKey] = updatedAddress;
            return { selectedAddresses: updatedAddresses };
        });
    };
    

    // Handle address field changes and update local state
    handleAddressChange = (property, value,) => {
        this.setState((prevState) => {
            const { newAP } = prevState;
            const updatedAddress = { ...newAP.address, [property]: value };
            const updatedNewAP = { ...newAP, address: updatedAddress };
            return { newAP: updatedNewAP };
        });
    };

    allowOnlyCharacters = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^A-Za-z\s]/g, '');
        event.target.value = value;
    }

    allowOnlyNumbers = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^0-9]/g, '');
        event.target.value = value;
    }
     
    onAddressChoice = (choice, index, person) => {
        const { addresses } = person;
        const selectedAddress = addresses[choice];
    
        if (selectedAddress) {
            // Update the person.address object with selected address values
            person.address = {
                line1: selectedAddress.line1 || '',
                line2: selectedAddress.line2 || '',
                line3: selectedAddress.line3 || '',
                postalCode: selectedAddress.postalCode || '',
            };
        } else {
            // If the selected address is not available, reset person.address to default values
            person.address = {
                line1: '',
                line2: '',
                line3: '',
                postalCode: '',
            };
        }
    
        // Now, update the state with the modified person object
        this.setState((prevState) => {
            const { selectedAddresses } = prevState;
            const updatedAddresses = [...selectedAddresses];
            updatedAddresses[index] = {
                ...updatedAddresses[index], // Preserve existing fields
                line1: person.address.line1 || '',
                line2: person.address.line2 || '',
                line3: person.address.line3 || '',
                postalCode: person.address.postalCode || '',
                // Preserve existing email field
                email: person.email || '' ,
            };
    
            return { selectedAddresses: updatedAddresses };
        });
    };
    

   
    authorisedPerson = (person, index) => {
        const wellStyles = { maxWidth: 600, margin: '0 auto 10px' };
        const { selectedAddress } = this.state;
        const { errors, newAP } = this.props;
        // console.log(person);
        // console.log(newAP);
        // console.log(selectedAddress);
        
        const addresses = person.addresses || [];
        const addressChoice = addresses.map(addressC => addressC.line1 + ', ' + addressC.line2 + ', ' +addressC.line3 + ', ' + addressC.postalCode);
        const { address } = person;
               
        return (
            <Panel eventKey={index} key={person.identityNumber} bsStyle="primary">
                <Panel.Heading>
                    <Panel.Title toggle>
                        <span className="caret" /><Badge>OPEN</Badge>&nbsp;
                        {person.firstName} {person.middleName ? person.middleName : ''} {person.lastName}
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                    <div className="row" style={{ margin: '10px 0' }}>
                        <div className="card-container-form">
                            <div className="form-group">
                                <label>ID Number</label>
                                <input className="form-control" disabled={true} defaultValue={person.identityNumber} />
                            </div>
                            <FormField className="form-group" id={`email-${index}`}>
                                <label>Email Address <span class="text-danger">*</span></label>
                                <input
                                    className="form-control"
                                    onChange={(e) => this.handleEmailChange('email', e.target.value, person)}
                                    type="text"
                                    defaultValue={person.email || ""}
                                    // value={(this.state.selectedAddresses[index] && this.state.selectedAddresses[index].email) || ''}
                                />

                                {this.state.errors && this.state.errors[index] && this.state.errors[index].email && (
                                    <div className="form-error">
                                        {this.state.errors[index] && this.state.errors[index].email && this.state.errors[index].email[0]}
                                    </div>
                                )}
                            </FormField>

                            {addressChoice && (
                                <FormField className="form-group" id="regAddress">
                                    <label>Choose address <span class="text-danger">*</span></label>
                                    <select
                                        name="address"
                                        className={'form-control'}
                                        onChange={(e) => this.onAddressChoice(e.target.value, index, person)}>
                                        <option value=''>Select Address</option>
                                        {addressChoice.map((addressItem, i) => (
                                            <option key={i} value={i}>{addressItem}</option>
                                        ))}
                                    </select>
                                </FormField>
                            )}

                            <div className="section-title">
                                <span>Physical Address <span className='text-danger'>*</span></span>
                            </div>
                            {/* {this.state.errors && this.state.errors[index] && (
                                    <div style={{ color: 'red', margin: '10px 0' }}>
                                        {Object.keys(this.state.errors[index]).map((field, i) => (
                                            <div key={i}>{`${field}: ${this.state.errors[index][field]}`}</div>
                                        ))}
                                    </div>
                                )} */}
                            <FormField className="form-group" id={`line1-${index}`} error={errors && errors[index] && errors[index].line1}>
                                <label htmlFor={`line1-${index}`}>Address <span class="text-danger">*</span></label>
                                <input
                                    className="form-control"
                                    id={`line1-${index}`}
                                    type="text"
                                    defaultValue={person.address && person.address.line1 || ""}
                                    // value={(this.state.selectedAddresses[index] && this.state.selectedAddresses[index].line1) || ''}
                                    onChange={(e) => this.handleInputChange('line1', e.target.value, person)}
                                />
                                {this.state.errors && this.state.errors[index] && this.state.errors[index].line1 && (
                                    <div className="form-error">
                                        {this.state.errors[index] && this.state.errors[index].line1 && this.state.errors[index].line1[0]}
                                    </div>
                                )}
                            </FormField>

                            <FormField className="form-group" id={`line2-${index}`} error={errors && errors[index] && errors[index].line2}>
                                <label htmlFor={`line2-${index}`}>Suburb <span class="text-danger">*</span></label>
                                <input
                                    className="form-control"
                                    id={`line2-${index}`}
                                    type="text"
                                    defaultValue={person.address && person.address.line2 || ""}
                                    // value={(this.state.selectedAddresses[index] && this.state.selectedAddresses[index].line2) || ''}
                                    onChange={(e) => {
                                        this.allowOnlyCharacters(e);
                                        this.handleInputChange('line2', e.target.value, person);
                                    }}
                                />
                                {this.state.errors && this.state.errors[index] && this.state.errors[index].line2 && (
                                    <div className="form-error">
                                        {this.state.errors[index] && this.state.errors[index].line2 && this.state.errors[index].line2[0]}
                                    </div>
                                )}
                            </FormField>

                            <FormField className="form-group" id={`line3-${index}`}>
                                <label htmlFor={`line3-${index}`}>City <span class="text-danger">*</span></label>
                                <input
                                    className="form-control"
                                    id={`line3-${index}`}
                                    type="text"
                                    defaultValue={person.address && person.address.line3 || ""}
                                    // value={(this.state.selectedAddresses[index] && this.state.selectedAddresses[index].line3) || ''}
                                    onChange={(e) => {
                                        this.allowOnlyCharacters(e);
                                        this.handleInputChange('line3', e.target.value, person);
                                    }}
                                />
                                {this.state.errors && this.state.errors[index] && this.state.errors[index].line3 && (
                                    <div className="form-error">
                                        {this.state.errors[index] && this.state.errors[index].line3 && this.state.errors[index].line3[0]}
                                    </div>
                                )}
                            </FormField>

                            <FormField className="form-group" id={`postalCode-${index}`} error={errors && errors[index] && errors[index].postalCode}>
                                <label htmlFor={`postalCode-${index}`}>Zip / Postal Code <span class="text-danger">*</span></label>
                                <input
                                    className="form-control"
                                    id={`postalCode-${index}`}
                                    type="text"
                                    defaultValue={person.address && person.address.postalCode || ""}
                                    // value={(this.state.selectedAddresses[index] && this.state.selectedAddresses[index].postalCode) || ''}
                                    onChange={(e) => {
                                        this.allowOnlyNumbers(e);
                                        this.handleInputChange('postalCode', e.target.value, person);
                                    }}
                                />
                                {this.state.errors && this.state.errors[index] && this.state.errors[index].postalCode && (
                                    <div className="form-error">
                                        {this.state.errors[index] && this.state.errors[index].postalCode && this.state.errors[index].postalCode[0]}
                                    </div>
                                )}
                            </FormField>
                        </div>
                    </div>
                    <div className="row" style={{ margin: '10px 0' }}>
                        <Col md={12} style={{ padding: '10px 0' }}>
                            <div className="btn-block">
                                <Button bsStyle="primary" bsSize="small" style={{ margin: '10px 10px', float: 'right' }}
                                    onClick={() => this.onSave(person)}>
                                    Save
                                </Button>
                                <Button bsStyle="danger" bsSize="small" style={{ margin: '10px 10px', float: 'right' }}
                                    onClick={() => this.onRemoveAP(index)}>
                                    Remove
                                </Button>
                            </div>
                        </Col>
                    </div>
                </Panel.Body>
            </Panel>
        );
    }

    onToggleCitizen =(isRSACitizen) =>{
        const { newAP, searchComplete } = this.state;
        this.setState((prevState) => ({
            saCitizen: !prevState.saCitizen,
          }));
        this.setState({ newAP: { new: true } , searchComplete: false })
    }
       
    render() {
        const { personsList } = this.props;
        const { newAP, searchComplete } = this.state;
        return (
            <div>
                <PanelGroup
                    accordion
                    id="accordion-controlled-example"
                    activeKey={this.state.activeKey}
                    onSelect={this.handleSelect}
                >
                    {
                        personsList &&
                        personsList.map((person, i) =>
                            this.authorisedPerson(person, i)
                        )
                    }
                </PanelGroup>
                <Modal show={this.state.newAuthorised}
                    onHide={() =>  this.setState({newAuthorised: false, newAP: { new: true } , searchComplete: false })}
                    bsSize={'large'}>
                    <Modal.Body>
                        <NewAuthorizedPerson
                            {...this.props}
                            newAP={newAP}
                            onSearch={this.onIndividualSearch}
                            handleSelectChange={this.handleNewAPSelect}
                            onChange={this.handleNewAPChange}
                            isSearchComplete={searchComplete}
                            onSubmit={this.handleNewAuthorisedPerson}
                            onAddressChange={this.handleNewAPAddressChange}
                            allowOnlyCharacters = { this.allowOnlyCharacters }
                            allowOnlyNumbers = { this.allowOnlyNumbers }
                            handleUpload={this.onHandleUpload}
                            saCitizen={this.state.saCitizen}
                            onToggleCitizen={this.onToggleCitizen}
                        />
                    </Modal.Body>
                    {
                        this.state.isLoading &&
                        <div className="inner-spinner-container">
                            <ReactLoading type="spokes" color="#444" />
                        </div>
                    }
                </Modal>
                {
                    this.state.removing && this.removeConfirm()

                }
                <Button style={{width:'auto'}} bsStyle={'primary'}  onClick={() => this.setState({ newAuthorised: true, saCitizen: true })}>Add Authorised Person</Button>
            </div>

        )
    }
}


function mapStateToProps({ individual }) {
    return { individual };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...entityActions,
            uploadDoc, verifyDoc, clearIndiviualInfo, fetchIndiviualInfo
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorisedPersonsComponent);

