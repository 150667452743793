import _ from 'lodash';
import * as types from './actionTypes';
import axios from 'axios';
import {API_ROOT} from '../api-config';
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from './appLoading';

const DELEGATION_URL = API_ROOT + '/api/impersonations/';
const STAFF_URL = API_ROOT + '/api/staff_impersonation';

export function addDelegate(delegate, onComplete = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.post(DELEGATION_URL, delegate).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.FETCH_DELEGATES_SUCCESS, delegatedList: responseData.data});
                onComplete && onComplete();
            } else {
                console.log('delegation.addDelegate.errorMessage ====>', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t add delegate.'});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('delegation.addDelegate.error ====>', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchDelegates() {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(DELEGATION_URL + '?reverse=true').then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.FETCH_DELEGATES_SUCCESS, delegatedList: responseData.data});
            } else {
                console.log('delegation.fetchDelegates.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t retrieve delegated persons.'});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('delegation.fetchDelegates.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchThoseIcanImpersonate() {
    return async (dispatch) => {
        try {
            dispatch({type: types.LOADING_START});
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(DELEGATION_URL + '?reverse=false').then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.FETCH_IMPERSONATIONS_SUCCESS, impersonationList: responseData.data});
            } else {
                console.log('delegation.fetchThoseIcanImpersonate.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t fetch list of those you can impersonate.'});
            }
            dispatch({type: types.LOADING_FINISH});
        } catch (error) {
            dispatch({type: types.LOADING_FINISH});
            console.log('delegation.fetchThoseIcanImpersonate.error ===> ', error);
            authCheck(dispatch, error);
        }
    };
}

export function startImpersonation(impersonation) {
    return (dispatch) => dispatch({type: types.START_IMPERSONATION, impersonation});
}

export function cancelImpersonation(impersonation) {
    return (dispatch) => dispatch({type: types.STOP_IMPERSONATION, impersonation});
}

export function startImpersonationsStaffMemberOnly({idNumber,termsAccepted}, impersonationList, onComplete = null, onFinish = null) {
    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(`${STAFF_URL}/${idNumber}?termsAccepted=${termsAccepted}`).then(response => response.data);
            if (responseData.success) {
                const impersonation = responseData.data;
                if (impersonation) {
                    const foundImpersonation = _.find(impersonationList, i=>(i.identityNumber === impersonation.identityNumber));
                    dispatch({
                        type: types.FETCH_IMPERSONATIONS_SUCCESS,
                        impersonationList: foundImpersonation ? impersonationList :_.union(impersonationList, [impersonation])
                    });
                    dispatch({type: types.START_IMPERSONATION, impersonation});
                    onComplete && onComplete();
                }
                onFinish && onFinish();
            } else {
                console.log('delegation.startImpersonationsStaffMemberOnly.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage:  'Couldn\'t start staff impersonation.'});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('delegation.startImpersonationsStaffMemberOnly.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function updateDelegate(delegate) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.put(DELEGATION_URL + delegate.id, delegate).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.FETCH_DELEGATES_SUCCESS, delegatedList: responseData.data});
            } else {
                console.log('delegation.updateDelegate.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage:  'Couldn\'t Update delegate.'});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('delegation.updateDelegate.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function removeDelegate(delegate) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.delete(DELEGATION_URL + delegate.id).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.FETCH_DELEGATES_SUCCESS, delegatedList: responseData.data});
            } else {
                console.log("delegation.removeDelegate.errorMessage ===> ", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage:  "Couldn't remove delegate."});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("delegation.removeDelegate.error ===> ", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}
