import * as types from '../actions/actionTypes';

const initialState = {};

export default function cashManagedFundReducer(state = initialState, action) {

    switch (action.type) {

        case types.FETCH_CASH_MANAGED_FUND_DATA : {

            return {...state,...action.data};
        }

        case types.CUSTODY_APPLICATION_SUCCESS : {
            const update = action.custody ? {custody: action.custody} : {};
            return {...state, ...update, errorMessage: action.errorMessage};
        }

        default:
            return state

    }
}
