import React from 'react';
import _ from 'lodash'
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import SearchComponent from "../SearchComponent";
import FormField from "../formField/FormField";
import Toggler from "../Toggler";
import {InputGroup, FormControl, ControlLabel, Col, HelpBlock} from 'react-bootstrap';

class NewSignatory extends React.Component {
    state = {isRSACitizen: true, isSearchComplete: false};

    onHandleSearch = () => {
        const {onIndividualSearch} = this.props;
        const onComplete = () => this.setState({isSearchComplete: true});
        const onError = () => this.setState();
        onIndividualSearch && onIndividualSearch(onComplete);
    };

    handleChangeSACitizen = (isRSACitizen) => {
        const {onResetNewRP} =this.props;
        this.setState({isRSACitizen})
        onResetNewRP && onResetNewRP();
    };

    _renderHeader() {
        return (
            <div className="container">
                <div className="product-heading">
                    New Signatory
                </div>
                <div className="title-gradient"/>
            </div>
        )
    }

    render() {
        const {isRSACitizen, isSearchComplete} = this.state;
        return (
            <div>
                {this._renderHeader()}
                <br/>
                <div className="container">
                    <Toggler
                        label="South African Citizen / Resident?"
                        isOn={isRSACitizen}
                        onToggle={this.handleChangeSACitizen}
                    />
                </div>
                <NaturalPersonForm
                    {...this.props}
                    shouldDisableInput={!(!isRSACitizen || isSearchComplete)}
                    onSearch={this.onHandleSearch}
                    rsaCitizen={isRSACitizen}
                />
            </div>
        )
    }
}

const NaturalPersonForm = (props) => {
    const {errors, handleNewRPChange, handleSelectAddress, newRP, onResetError, onNewRPSubmit, onSearch, shouldDisableInput, rsaCitizen} = props;
    if (!newRP) return null;

    const phoneOptions = _.map(newRP.telephones, (t) => ({value: t.msisdn, label: t.msisdn}));
    const addressOptions = _.map(newRP.addresses, (t) => ({value: t.line1, label: t.line1}));

    const hasPhoneOptions = _.size(phoneOptions) > 0;
    const hasAddressOptions = _.size(addressOptions) > 0;

    if (!newRP.address) {
        newRP.address = {}
    }
    if (!newRP.signingLimits) {
       newRP.signingLimits = 0;
    }
    

    return (
        <div className="container" style={{padding: 0}}>
            <div className="container" style={{padding: 0}}>
                <div className="col-xs-12 col-md-6">
                    <div className="card-container-form">
                        {
                            (!rsaCitizen) ?
                                <FormField className={"form-group"} error={errors && errors.idNumber}>
                                    <label>ID/ Passport Number <span className="text-danger">*</span></label>
                                    <input
                                        className="form-control"
                                        id="idNumber"
                                        onChange={(event) => {
                                            errors && errors.idNumber && onResetError && onResetError('idNumber');
                                            handleNewRPChange(event, 'idNumber')
                                        }}
                                        type="text"
                                        value={newRP.idNumber || ''}
                                    />
                                </FormField>

                                :
                                <div>
                                    <FormField className={"form-group"} error={errors && errors.idNumber}>
                                        <SearchComponent
                                            label="ID Number"
                                            onChange={(event) => {
                                                errors && errors.idNumber && onResetError && onResetError('idNumber');
                                                handleNewRPChange(event, 'idNumber')
                                            }}
                                            onSearch={onSearch}
                                            searchValue={newRP.idNumber || ''}
                                        />
                                    </FormField>
                                </div>
                        }
                            {rsaCitizen ? (
                        <FormField className={"form-group"} id="name" error={errors && errors.firstName}>
                            <label>First Name <span className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="firstName"
                                value={newRP.firstName || ''}
                                onChange={(event) => {
                                    errors && errors.firstName && onResetError && onResetError('firstName');
                                    handleNewRPChange(event, 'firstName')
                                }}
                                type="text"
                                readOnly
                            />
                        </FormField>
                            ) : (
                                <FormField className={"form-group"} id="name" error={errors && errors.firstName}>
                            <label>First Name <span className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="firstName"
                                value={newRP.firstName || ''}
                                onChange={(event) => {
                                    errors && errors.firstName && onResetError && onResetError('firstName');
                                    handleNewRPChange(event, 'firstName')
                                }}
                                type="text"
                            />
                        </FormField>
                            )}

                            { rsaCitizen ? (
                        <FormField className={"form-group"} id="lastName" error={errors && errors.lastName}>
                            <label>Surname <span className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="surname"
                                onChange={(event) => {
                                    errors && errors.lastName && onResetError && onResetError('lastName');
                                    handleNewRPChange(event, 'lastName')
                                }}
                                type="text"
                                readOnly
                                value={newRP.lastName || ''}
                            />
                        </FormField>
                            ) : (
                                <FormField className={"form-group"} id="lastName" error={errors && errors.lastName}>
                                <label>Surname <span className="text-danger">*</span></label>
                                <input
                                    className="form-control"
                                    disabled={shouldDisableInput}
                                    id="surname"
                                    onChange={(event) => {
                                        errors && errors.lastName && onResetError && onResetError('lastName');
                                        handleNewRPChange(event, 'lastName')
                                    }}
                                    type="text"
                                    value={newRP.lastName || ''}
                                />
                            </FormField>
                            )}
                        <div className="form-group">
                            <label>Address <span className="text-danger">*</span></label>
                            {
                                hasAddressOptions ?
                                    <Select
                                        menuContainerStyle={{zIndex: 999}}
                                        disabled={shouldDisableInput}
                                        name="address"
                                        onChange={(event) => handleSelectAddress(event, 'address')}
                                        options={addressOptions}
                                        value={newRP.address && newRP.address.registeredAddress || ''}
                                    />
                                    :
                                    <FormField id="number" error={errors && errors.registeredAddress}>
                                        <input
                                            disabled={shouldDisableInput}
                                            className="form-control"
                                            id="address"
                                            onChange={(event) => {
                                                errors && errors.registeredAddress && onResetError && onResetError('registeredAddress');
                                                handleNewRPChange(event, 'address', 'registeredAddress')
                                            }}
                                            type="text"
                                            value={newRP.address && newRP.address.registeredAddress || ''}
                                        />
                                    </FormField>
                            }
                        </div>
                    </div>
                </div>

                <div className="col-xs-12 col-md-6">
                    <div className="card-container-form">
                        <div className="form-group">
                            <label>Contact Number <span className="text-danger">*</span></label>
                            {
                                hasPhoneOptions ?
                                    <Select
                                        menuContainerStyle={{zIndex: 999}}
                                        disabled={shouldDisableInput}
                                        name="contactNumber"
                                        onChange={(event) => props.handleSelectChange(event, 'contactNumber')}
                                        options={phoneOptions}
                                        value={newRP.contactNumber || ''}
                                    />
                                    :
                                    <FormField id="number" error={errors && errors.contactNumber}>
                                        <input
                                            className="form-control"
                                            disabled={shouldDisableInput}
                                            id="contactNumber"
                                            onChange={(event) => {
                                                errors && errors.contactNumber && onResetError && onResetError('contactNumber');
                                                handleNewRPChange(event, 'contactNumber')
                                            }}
                                            type="text"
                                            value={newRP.contactNumber || ''}
                                        />
                                    </FormField>
                            }
                        </div>
{/* 
                        <FormField className={"form-group"} id="emailAddress" error={errors && errors.emailAddress}>
                            <label>Email Address <span className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="email"
                                onChange={(event) => {
                                    errors && errors.emailAddress && onResetError && onResetError('emailAddress');
                                    handleNewRPChange(event, 'emailAddress')
                                }}
                                type="text"
                                value={newRP.emailAddress || ''}
                            />
                        </FormField> */}

                        <FormField className={"form-group"} id="signingLimits" error={errors && errors.signingLimits} >
                            <Col xs={12} style={{paddingLeft: 0}}>
                                <ControlLabel htmlFor={"mfpercentage"}>
                                    Signing Limits 
                                    {/* <span className="text-danger">*</span> */}
                                </ControlLabel>
                            </Col>
                            <div className="input-group">
                                <span className="input-group-addon">R</span>
                                <input
                                    className="form-control"
                                    disabled={shouldDisableInput}
                                    id="mfpercentage"
                                    onChange={(event) => {
                                        errors && errors.signingLimits && onResetError && onResetError('signingLimits');
                                        handleNewRPChange(event, 'signingLimits')
                                    }}
                                    type="text"
                                    value={newRP.signingLimits || ''} />
                                <span className="input-group-addon">.00</span>
                            </div>
                        </FormField>

                        <FormField className={"form-group"} id="signingArrangements"
                                   error={errors && errors.signingArrangements}>
                            <label>Signing arrangements <span className="text-danger">*</span></label>
                            <select
                                type="text"
                                disabled={shouldDisableInput}
                                className="form-control"
                                id="capacity"
                                value={newRP.signingArrangements || ''}
                                onChange={(event) => {
                                    errors && errors.signingArrangements && onResetError && onResetError('signingArrangements');
                                    handleNewRPChange(event, 'signingArrangements')
                                }}
                            >
                                <option value="">--SELECT--</option>
                                <option value="SignPlusSingle">Single</option>
                                <option value="SignPlusPlusAnyOther">Jointly</option>
                            </select>
                        </FormField>
                    </div>
                </div>
            </div>

            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-secondary"
                    onClick={onNewRPSubmit}
                    type="button"
                >Add
                </button>
            </div>
        </div>
    )
};

export default NewSignatory
