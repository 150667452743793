import React from 'react';
import _ from 'lodash'
import validate from "validate.js";

import SearchComponent from "../SearchComponent";
import FormField from "../formField/FormField";
import Toggler from "../Toggler";
import ReactLoading from "react-loading";
import FileUpload from "../FileUpload";
import specimenTemplate from "../../resources/New_Signatory_specimens.pdf";
import ListGroup from "react-bootstrap/lib/ListGroup";
import ListGroupItem from "react-bootstrap/lib/ListGroupItem";
import Row from "react-bootstrap/lib/Row";

const DOCUMENTS_TYPE = {
    specimen: "specimen"
};

class AddNewSignatoryForm extends React.Component {
    state = {
        errors: {},
        isRSACitizen: true,
        isAddingOrSearching: false,
        isSearchComplete: false,
        newSignatory: {},
        selectedAccounts: [],
        selectAll: false
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.candidate) {
            const {newSignatory} = this.state;
            let updatedSignatory = _.extend({}, nextProps.candidate, newSignatory)
            this.setState({newSignatory: _.extend({}, updatedSignatory, {initialsOrFirstNames: nextProps.candidate.firstName})});
        }
    }

    onHandleSearch = () => {
        const {newSignatory} = this.state;
        const {onIndividualSearch} = this.props;
        this.setState({isAddingOrSearching: true});
        const onComplete = (success, individual) => {
            this.setState({isSearchComplete: true, isAddingOrSearching: false, isSearchError: false,
            newSignatory: {
                idOrPassportNumber: individual.idNumber,
                initialsOrFirstNames: individual.firstName,
                lastName: individual.lastName
            }});
        }
        const onError = () => this.setState({isSearchComplete: true, isAddingOrSearching:false, isSearchError:true, errors: {idOrPassportNumber:['ID number not found, check ID number']}});
        onIndividualSearch && onIndividualSearch(newSignatory && newSignatory.idOrPassportNumber, onComplete, onError);
    };

    handleChangeSACitizen = (isRSACitizen) => {
        this.setState({isRSACitizen})
    };

    handleOnChange = ({target}, attribute, innerAttribute) => {
        const {newSignatory} = this.state;
        let value = _.trimStart(target.value);
        if (innerAttribute) {
            value = _.extend({}, newSignatory[attribute], {[innerAttribute]: value});
        }
        this.setState({newSignatory: _.extend({}, newSignatory, {[attribute]: value})});
    };

    onHandleUpload = (base64, inProgress, onComplete) => {
        const {newSignatory, errors} = this.state;
        const {onUploadDocument} = this.props;
        errors && errors.documentId && this.onResetError("documentId");

        const onSuccess = (documentId, data) => {
            const updatedSignatory = _.extend({}, newSignatory, {documentId});
            this.setState({newSignatory: updatedSignatory});
        };
        onUploadDocument && onUploadDocument(base64, inProgress, onComplete, onSuccess);

    };

    onHandleRemoveDocument = () => {
        const {newSignatory} = this.state;
        const updatedSignatory = _.extend({}, newSignatory, {documentId: null});
        this.setState({newSignatory: updatedSignatory});
    };

    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors});
    };

    validateForm = () => {
        const {newSignatory} = this.state;
        const {selectedAccounts} = this.state;
        const options = {fullMessages: false};

        const constraints = {
            idOrPassportNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            initialsOrFirstNames: {presence: true, length: {minimum: 1, message: 'required'}},
            lastName: {presence: true, length: {minimum: 1, message: 'required'}},
            signingArrangements: {presence: true, length: {minimum: 1, message: 'required'}},
            documentId: {presence: true}
        };

        const errors = validate(newSignatory, constraints, options);
        if (selectedAccounts.length < 1) {
            errors.selectedAccounts = ['At least 1 account required.'];
        }

        this.setState({errors});
        return _.isEmpty(errors);
    };

    onHandleSubmit = (e) => {
        e.preventDefault();
        console.log('submit hit')
        const {selectedAccounts, newSignatory} = this.state;
        const {accounts} = this.props;
        if (this.validateForm()) {
            const submittingAccounts = selectedAccounts.map(selected => accounts[selected]);
            this.props.saveSignatory(this.state.newSignatory, submittingAccounts);
            //     const {onAddSignatoryToAccount} = this.props;
            //
            // this.setState({isAdding: true});
            // const onFinish = () => this.setState({isAdding: false});
            // onAddSignatoryToAccount && onAddSignatoryToAccount(this.state.newSignatory, onFinish);
        }
    };

    _renderHeader() {
        return (
            <div>
                <div className="product-heading">
                    New Signatory
                </div>
                <div className="title-gradient"/>
            </div>
        )
    }

    _renderFooter() {
        return (
            <div className="action-button-container" style={{width: "100%"}}>
                <div/>
                <button
                    className="action-btn-secondary btn-sm"
                    onClick={this.onHandleSubmit}
                    type="button"
                >Add
                </button>
            </div>
        )
    }

    setSelectedAccount = (accountIndex) => {
        const {selectedAccounts} = this.state;
        if (selectedAccounts.includes(accountIndex)) {
            _.remove(selectedAccounts, function (arr) {
                return arr === accountIndex;
            });
        } else {
            // setAccountFilter(...accountFilter, signatories[change].account.accountNumber);
            selectedAccounts.push(accountIndex);
        }
        this.setState({selectedAccounts});
    }

    selectAllAccounts = () => {
        const {selectAll} = this.state;
        const {accounts} = this.props;
        const update = [];
        if (!selectAll) {
            accounts.forEach((account, i) =>
                update.push(i)
            );
        }
        this.setState({selectAll: !selectAll, selectedAccounts: update});
    };

    _renderForm() {
        const {isRSACitizen, isSearchComplete, newSignatory, errors, isSearchError} = this.state;
        const {accounts} = this.props;
        const shouldDisableInput = !(!isRSACitizen || (isSearchComplete && !isSearchError));

        if (!newSignatory.address) {
            newSignatory.address = {}
        }
        return (
            <div className="modal-body">
                <div className="row" style={{margin:'0'}}>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="col-lg-12 col-md-12">
                            <FormField className={"form-group"} error={errors && errors.idOrPassportNumber}>
                                {
                                    !shouldDisableInput ?
                                        <div>
                                            <label>ID/ Passport Number</label>
                                            <input
                                                className="form-control"
                                                id="idOrPassportNumber"
                                                onChange={(event) => {
                                                    errors && errors.idOrPassportNumber && this.handleResetError && this.handleResetError('idOrPassportNumber');
                                                    this.handleOnChange(event, 'idOrPassportNumber')
                                                }}
                                                type="text"
                                                value={newSignatory.idOrPassportNumber || ''}
                                            />
                                        </div>

                                        :
                                        <SearchComponent
                                            label="ID Number"
                                            onChange={(event) => {
                                                errors && errors.idOrPassportNumber && this.handleResetError && this.handleResetError('idOrPassportNumber');
                                                this.handleOnChange(event, 'idOrPassportNumber')
                                            }}
                                            onSearch={this.onHandleSearch}
                                            searchValue={newSignatory.idOrPassportNumber || ''}
                                        />
                                }
                            </FormField>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <FormField className={"form-group"} id="name" error={errors && errors.initialsOrFirstNames}>
                                <label>First Name</label>
                                <input
                                    className="form-control"
                                    disabled={shouldDisableInput}
                                    id="initialsOrFirstNames"
                                    value={newSignatory.initialsOrFirstNames || ''}
                                    onChange={(event) => {
                                        errors && errors.initialsOrFirstNames && this.handleResetError && this.handleResetError('initialsOrFirstNames');
                                        this.handleOnChange(event, 'initialsOrFirstNames')
                                    }}
                                    type="text"
                                />
                            </FormField>
                        </div>  
                        <div className="col-lg-12 col-md-12">
                            <FormField className={"form-group"} id="lastName" error={errors && errors.lastName}>
                                <label>Surname</label>
                                <input
                                    className="form-control"
                                    disabled={shouldDisableInput}
                                    id="surname"
                                    onChange={(event) => {
                                        errors && errors.lastName && this.handleResetError && this.handleResetError('lastName');
                                        this.handleOnChange(event, 'lastName')
                                    }}
                                    type="text"
                                    value={newSignatory.lastName || ''}
                                />
                            </FormField>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="col-lg-12 col-md-12">
                            <FormField className={"form-group"} id="signingArrangements"
                                        error={errors && errors.signingArrangements}>
                                <label>Signing arrangements</label>
                                <select
                                    type="text"
                                    disabled={shouldDisableInput}
                                    className="form-control"
                                    id="capacity"
                                    value={newSignatory.signingArrangements || ''}
                                    onChange={(event) => {
                                        errors && errors.signingArrangements && this.handleResetError && this.handleResetError('signingArrangements');
                                        this.handleOnChange(event, 'signingArrangements')
                                    }}
                                >
                                    <option value="">--SELECT--</option>
                                    <option value="SignPlusSingle">Single</option>
                                    <option value="SignPlusPlusAnyOther">Jointly</option>
                                </select>
                            </FormField>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <FormField className="form-group " id="specimenDoc"
                                style={{display: "flex", flexDirection: "column"}}
                                error={errors && errors.documentId ? ["Document required"] : null}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }} >
                                    <label htmlFor="proofOfId" style={{margin: 0, padding: 0}}>New Signatory
                                        Specimens </label>
                                    <a className="btn btn-secondary addButton" href={specimenTemplate} download="SpecimenTemplate.pdf" target={'_blank'}> 
                                    <i className="fa fa-download fa-fw"/> Specimen Template</a>
                                </div>

                                <FileUpload
                                    disabled={shouldDisableInput}
                                    documentType={DOCUMENTS_TYPE.specimen}
                                    onUpload={this.onHandleUpload.bind(this)}
                                    onRemoveDocument={this.onHandleRemoveDocument.bind(this)}
                                />
                            </FormField>
                        </div>
                    </div>
                </div>
                    
                <div className="row" style={{margin:'0'}}>
                    <div className={'col-lg-offset-2 col-md-offset-2 col-lg-8 col-md-8 col-xs-12 col-sm-12'}>
                        <div className="product-heading" style={{textAlign:'center', fontWeight:'bold'}}> Account/s to add to: </div>
                    </div>
                    <div className={"col-lg-offset-2 col-md-offset-2 col-lg-8 col-md-8 col-xs-12 col-sm-12"}>
                        <div className="inline-container" onClick={this.selectAllAccounts}>
                            <i className={this.state.selectAll ? 'fa fa-check-circle-o icon' : 'fa fa-circle-o icon'}/>
                            Select All
                        </div>
                    </div>
                    <div className={"col-lg-offset-2 col-md-offset-2 col-lg-8 col-md-8 col-xs-12 col-sm-12"}>
                        <FormField className={'form-group'} error={errors && errors.selectedAccounts}>
                            <ListGroup style={{maxHeight: '9em', overflowY: 'scroll', padding:'1em 0.5em'}}>
                                {
                                    accounts ? accounts.map((account, i) =>
                                            <ListGroupItem type={'button'}
                                                            onClick={() => this.setSelectedAccount(i)}
                                                            active={this.state.selectedAccounts.includes(i)}
                                                            key={i}>

                                                <Row style={{paddingLeft: '2em'}}>
                                                    {/*<i className={selectedAccount === i ? 'fa fa-check-circle-o icon' : 'fa fa-circle-o icon'}/>*/}
                                                    {account.name}
                                                </Row>
                                                <Row style={{paddingLeft: '2.5em'}}>
                                                    <span style={{fontWeight: 'bold'}}>Account Number: </span>
                                                    <span
                                                        className={this.state.selectedAccounts.includes(i)
                                                            ? '' : 'text-primary'}
                                                        style={{fontWeight: 'bold'}}>
                                                        {account.accountNumber}
                                                    </span>
                                                </Row>
                                            </ListGroupItem>
                                        ) :
                                        <div className="">
                                            <h3 className="login_title">You have no Accounts to add too.</h3>
                                            {/*<p>Please either add a new signatory or copy an existing.</p>*/}
                                        </div>
                                }
                            </ListGroup>
                        </FormField>
                    </div>
                </div>
                {this._renderFooter()}
            </div>
        )
    }

    render() {
        const {isRSACitizen, isAddingOrSearching} = this.state;

        return (
            <div>
                {this._renderHeader()}
                <br/>
                <Toggler
                    label="South African Citizen / Resident?"
                    isOn={isRSACitizen}
                    onToggle={this.handleChangeSACitizen}
                />
                {this._renderForm()}
                {
                    isAddingOrSearching &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
            </div>
        )
    }
}

export default AddNewSignatoryForm

export const AddSignatoryMessage = ({success}) => {
    if (success == true) {
        return (
            <div>
                <div>
                    <div className="product-heading">
                        Successful
                    </div>
                    <div className="title-gradient"/>
                </div>
                <br/>
                <div className="popup-content">
                    <div className="flexColumn">
                        <div>
                            <p>
                                Your instruction has been captured.
                            </p>
                            <p>
                                It should take 24 hours for your request to be completed.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div>
                <div className="product-heading">
                    Unsuccessful
                </div>
                <div className="title-gradient"/>
            </div>
            <br/>
            <div className="popup-content">
                <div className="flexColumn">
                    <div>
                        <p>There was an error capturing your instruction.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
