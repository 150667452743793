import React from 'react';
import Button from 'react-bootstrap/lib/Button';

const KycCompletePage = (props) => {

    return (
        <div className='container'>
            <div className="card-container-form" style={{textAlign:'center'}}>
                <div className='row' style={{margin:'8vh 0'}}>
                    <p>Thank you, for submitting your <b>KYC Review </b> request.</p>
                    <p>We'll have our team review the information you have provided and update on our systems.</p>
                </div>
                <Button bsStyle={'primary'} style={{width:'auto'}} onClick={() => props.history.replace('/')}>Home</Button>
            </div>
        </div>
    );
};

export default KycCompletePage;