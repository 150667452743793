import * as types from './actionTypes';

export function previous(page, history) {
    return (dispatch) => dispatch({type: types.GO_PREVIOUS, page, history});
}

export function saveUserApplications(application, history) {
    return async (dispatch) => {
        history && history.push('/saveapplication');
        dispatch({type: types.SAVE_USER_APPLICATIONS_SUCCESS});
    };
}