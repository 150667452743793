import React from "react";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as actions from '../actions/navigation';

class ButtonsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.saveAndContinue = this.saveAndContinue.bind(this);
        this.onPrevious = this.onPrevious.bind(this);
    }

    saveAndContinue() {
        const {actions, application, history} = this.props;
        actions.saveUserApplications(application, history);
    }

    onPrevious() {
        const {actions, history, prevPage} = this.props;
        actions.previous(prevPage, history);
    }

    render() {
        const hidePrevious = this.props.hidePrevious;
        const hideSaveAndContinue = this.props.hideSaveAndContinue;
        return (
            <div className="action-button-container" style={{width:'100%'}}>
                { hidePrevious ?

                    <div></div> :

                    <button
                        className="action-btn-primary btn-sm"
                        onClick={this.onPrevious}
                        type="button"
                    >Previous
                    </button>
                }
                { hideSaveAndContinue ?

                <div></div> :
                <button
                    className="action-btn-secondary btn-sm" 
                    onClick={this.props.onSaveAndContinue}
                    type="button"
                > Save And Continue Later
                </button>
                }
                <button
                    className="action-btn-primary btn-sm"
                    onClick={this.props.onNext}
                    type="button"
                >
                    Next
                </button>
            </div>
        );
    }
}

function mapStateToProps({application}, ownProps) {
    return {
        application: application && application.application ? application.application : {}
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonsComponent);
