import React from 'react';
import _ from 'lodash'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import validate from 'validate.js'
import ReactLoading from "react-loading";

import * as actions from '../../actions/kyc';
import FormField from "../formField/FormField";
import AccountsList from '../accounts/AccountsList';

const ACTION_TYPE = {
    copied: "COPIED",
};

class CopySignatory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            isLoading: false,
            isDoneFetchingSignatories: false,
            selectedAccount: null,
            filteredAccounts: null,
            isSelectAll: false,
        };
    }

    componentWillMount() {
        const {actions} = this.props;
        actions.clearCopies();
        this.fetchAccounts();
    }

    fetchAccounts() {
        const {actions, onLoadingStart, onLoadingFinish,cifNumber } = this.props;

        onLoadingStart && onLoadingStart();
        const onCompleted = () => onLoadingFinish && onLoadingFinish();

        actions.fetchAccounts(`cifNumber=${cifNumber}`, 'selectedEntityAccounts', onCompleted, false);

    }

    onHandleSignatorySelect = (e, signatory) => {
        const {actions, copies} = this.props;
        const {errors} = this.state;
        errors && errors.newSignatories && this.handleResetError("newSignatories");
        const isSelected = !(signatory && signatory.isSelected);
        actions.selectSignatory(copies, signatory, isSelected);
    };

    onSelectAllSignatory = () => {
        const {actions, copies} = this.props;
        const {errors} = this.state;
        errors && errors.newSignatories && this.handleResetError("newSignatories");

        this.setState(prevState => {
            const isSelectAll = !prevState.isSelectAll;
            actions.selectAllSignatories(copies, isSelectAll);

            return {isSelectAll}
        });
    };

    onHandleSelectAccount = (selectedAccount) => {
        const {actions, applicationId} = this.props;
        const {errors} = this.state;

        errors && errors.newSignatories && this.handleResetError("newSignatories");

        this.setState({
            isDoneFetchingSignatories: false,
            isLoading: true,
            selectedAccount
        });
        const onComplete = () => {
            this.setState({isLoading: false, isDoneFetchingSignatories: true});
        };
        actions.clearCopies();
        actions.fetchSignatoriesForAccount(`applicationId=${applicationId}`, selectedAccount.accountNumber, onComplete);
    };

    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors});
    };

    validateSignatories = (newSignatories) => {
        const options = {fullMessages: false};
        const constraints = {newSignatories: {presence: true}};
        const value = _.size(newSignatories) > 0 ? newSignatories : null;
        const errors = validate({newSignatories: value}, constraints, options);
        this.setState({errors});
        return _.isEmpty(errors);
    };

    onHandleSubmit = () => {
        const {actions, applicationId, copies, onCopySubmit, onLoadingStart, onLoadingFinish} = this.props;
        const newSignatories = _.filter(copies, copy => (copy.isSelected));

        console.log('accc----'+this.state.selectedAccount.accountNumber);
        if (this.validateSignatories(newSignatories)) {
            onLoadingStart && onLoadingStart();

            const signatories = _.map(newSignatories, (signatory) => {
                return {
                    actionType: ACTION_TYPE.copied,
                    firstName: signatory.initialsOrFirstNames,
                    idNumber: signatory.idOrPassportNumber,
                    lastName: signatory.surname,
                    signingArrangements: signatory.instruction,
                    fromAccount: this.state.selectedAccount.accountNumber
                };
            });

            const onCompleted = () => {
                onCopySubmit && onCopySubmit();
                onLoadingFinish && onLoadingFinish();
            };
            console.log(signatories)
            actions.addSignatories(applicationId, signatories, onCompleted);
        }
    };

    handleOnFilterAccounts = (searchTerm) => {
        const {accounts} = this.props;
        let filteredAccounts = null;
        if (searchTerm) {
            filteredAccounts = _.filter(accounts, acc => {
                return acc && (acc.accountNumber.includes(searchTerm) || acc.name && acc.name.toLowerCase().includes(searchTerm.toLowerCase()))
            });
        }
        this.setState({ filteredAccounts:searchTerm ? filteredAccounts: null,  searchTerm})
    };

    _renderAccounts() {
        const {accounts, accountsEmpty} = this.props;
        const {selectedAccount} = this.state;
        return (
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        Accounts
                    </div>
                    {
                        accountsEmpty ?
                            <p>
                                No linked accounts for CIF
                            </p>
                            :

                            <AccountsList
                                accounts={accounts}
                                filteredAccounts={filteredAccounts}
                                onFilterAccounts={this.handleOnFilterAccounts}
                                onSelect={() => {
                                }}
                                onSelectAll={() => {
                                }}
                                onSelectRadio={(selectedAccount) => {
                                    this.onHandleSelectAccount(selectedAccount)
                                }}
                                selectedAcc={selectedAccount}
                                isMultiSelect={false}
                                listStyle={{}}
                                hideDate={true}
                            />
                    }
                </div>
            </div>
        )
    }

    _renderSelectAllButton() {
        const {isSelectAll} = this.state;
        const iconStyle = {
            color: "rgb(0, 51, 170)",
            fontSize: 20, marginRight: 5
        };
        return (
            <div style={{width: 100, padding: 0}}>
                <label
                    className="checkbox-inline"
                    style={{marginLeft: 0, paddingLeft: 0}}// verticalAlign: "middle"}}
                    onClick={this.onSelectAllSignatory}
                >
                    <i
                        className={isSelectAll ? "fa fa-check-circle" : "fa fa-circle-o"}
                        style={iconStyle}
                    />Select All
                </label>
            </div>
        )
    }

    _renderSelectedAccountSignatories() {
        const {copies} = this.props;
        const {errors, isDoneFetchingSignatories} = this.state;

        const hasSignatoriesCopies = _.size(copies) > 0;
        const iconStyle = {
            color: "rgb(0, 51, 170)",
            fontSize: 20, marginRight: 5
        };
        return (
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        Account Signatories
                    </div>
                    <FormField
                        className="form-group"
                        error={errors && errors.newSignatories && ["At Least One Signatory Should Be Selected"]}
                    >
                        {
                            isDoneFetchingSignatories &&
                            <div>
                                {
                                    hasSignatoriesCopies ?
                                        <div>
                                            {this._renderSelectAllButton()}
                                            <br/>
                                            <br/>
                                            <div>
                                                {
                                                    _.map(copies, (signatory, i) => {
                                                        const isSelected = signatory && signatory.isSelected;
                                                        return (
                                                            <label
                                                                className={`checkbox-inline ${isSelected ? "selection-list-item-selected" : "selection-list-item"}`}
                                                                key={`signatory-${i}`}
                                                                style={{
                                                                    padding: 3,
                                                                    paddingLeft: 0,
                                                                    marginLeft: 0,
                                                                    width: "100%"
                                                                }}
                                                                onClick={(event) => this.onHandleSignatorySelect(event, signatory)}
                                                            >
                                                                <i
                                                                    className={isSelected ? "fa fa-check-square-o" : "fa fa-square-o"}
                                                                    style={isSelected ? {
                                                                        ...iconStyle,
                                                                        fontSize: 19.5
                                                                    } : iconStyle}
                                                                />
                                                                {` ${signatory.initialsOrFirstNames} ${signatory.surname} | Instruction: ${signatory && signatory.instruction ? signatory.instruction : ''}`}
                                                            </label>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            No Signatories For Selected Account
                                        </div>
                                }
                                {errors && errors.newSignatories && <div><br/><br/></div>}
                            </div>
                        }
                    </FormField>
                </div>
            </div>
        )
    }

    _renderHeader() {
        return (
            <div>
                <div className="product-heading">
                    Copy Account Signatories
                </div>
                <div className="title-gradient"/>
            </div>

        )
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-secondary"
                    onClick={this.onHandleSubmit}
                    type="button"
                >Add Selected
                </button>
            </div>
        )
    }

    render() {
        const {isLoading} = this.state;
        return (
            <div>
                {this._renderHeader()}
                <div className="popup-content">
                    {this._renderAccounts()}
                    {this._renderSelectedAccountSignatories()}
                </div>
                {this._renderFooter()}
                {
                    isLoading &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const {accounts, managedFund, copies, loading} = state;
    return {
        accounts: accounts && accounts.accounts ? accounts.accounts : [],
        accountsEmpty: accounts.accountsEmpty,
        applicationId: managedFund && managedFund.id,
        copies: copies && copies.copies ? copies.copies : [],
        cifNumber: managedFund && managedFund.cashManagedFundRequest.managedFundEntity ? managedFund.cashManagedFundRequest.managedFundEntity.cif : '',
        loading
    };
}


function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CopySignatory);
