import React, { Component } from 'react';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../actions/user';
import {AUTH_URL} from "../api-config";


class LogoutPage extends Component {


    componentWillMount() {

        this.logoutUser();
    }

    logoutUser(){

        const authorizeUrl = AUTH_URL.concat('/exit')
        console.log("To navigate to auth for env :", authorizeUrl);
        localStorage.removeItem('token_expires_at');
        localStorage.removeItem('access_token');
        localStorage.removeItem('state');
        window.location.replace(authorizeUrl);
        localStorage.removeItem('flag');
    }


    render() {
        return (
            <div className="form">
                <div className=" App ">

                    <header className="App-header">
                        <h1 className="App-title">Standard Bank</h1>
                    </header>

                    <div className="row">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">

                            <div className="card card-container">
                                <h3 className='login_title'>You have successfully logged out</h3>
                                <hr />


                            </div>
                        </div>
                        <div className="col-sm-2">
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}


export default connect(null, mapDispatchToProps)(LogoutPage);
