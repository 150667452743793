
import React from 'react';

const KycReviewInfo = () => (
    <div>
        <div>
            <div className="product-heading">
             <b> Documents Required</b> 
            </div>
            <div className="title-gradient"/>
        </div>
        <div className="popup-content">
            <div className="card-container-form" style={{maxWidth: "40em"}}>
                <div className="row">
                    <div className="" style={{padding: 10}}>
                        <ul style={{padding: '0 10px'}}>
                            <li> <span style={{padding: '0 10px'}}>-</span>  Ownership Structure</li>
                            <li> <span style={{padding: '0 10px'}}>-</span>  CIPC Document</li>
                            <li> <span style={{padding: '0 10px'}}>-</span>  Resolution</li>
                            <li> <span style={{padding: '0 10px'}}>-</span>  Proof Of Financial Status</li>
                            <li> <span style={{padding: '0 10px'}}>-</span>  Any Additional Supporting Document</li>
                        </ul>
                    </div>
                    <code>Note: Documents to be in PDF format.</code>
                </div>
            </div>
        </div>
    </div>
);
export default KycReviewInfo;