import axios from 'axios';
import * as types from './actionTypes';
import {API_ROOT, AUTH_URL} from "../api-config";
import {authCheck} from "./appLoading";
import _ from "lodash";

const APP_URL = API_ROOT + '/api/management/in-app-response';
const PENDING_APP_URL = API_ROOT + '/api/management/in-app-approval-messages/';


export function loadUserPendingforApprovalApp(userIdentityNumber, onComplete = null) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(PENDING_APP_URL + userIdentityNumber).then(response => response.data);
            onComplete && onComplete();
            if (responseData.success) {
                const pandingApplications = _.reverse(_.sortBy(responseData.data, application => application.id));
                dispatch({type: types.LOAD_USER_PENDING_FOR_APPROVAL_APPLICATIONS_SUCCESS, pandingApplications, errorMessage: null});
            } else {
                console.log("user.loadUserApplications.errorMessage ===>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage:  "Couldn't retrieve your applications."});
            }
        } catch (error) {
            onComplete && onComplete();
            console.log("user.loadUserApplications.error ===>", error);
            authCheck(dispatch,error);
        }
    };
}



export function approveApplication(applicationId, userLoggedInIdentityNumber, onProceed = null) {
    return async (dispatch) => {
        try {
            const appApprove = {
                userLoggedInIdentityNumber: userLoggedInIdentityNumber,
                appResponse: applicationId + ' ' + 'Yes',
                applicationId: applicationId
            };
 
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const response = await axios.post(APP_URL, appApprove);
            const responseData = response.data;
 
            if (responseData.success) {
                const applications = _.reverse(_.sortBy(responseData.data, application => application.id));
                dispatch({
                    type: types.SAVE_USER_APPLICATIONS_SUCCESS,
                    applications,
                    errorMessage: null
                });
 
                onProceed && onProceed();
            } else {
                console.log("user.loadUserApplications.errorMessage ===>", responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: "Couldn't retrieve your applications."
                });
            }
        } catch (error) {
            console.log("user.loadUserApplications.error ===>", error);
            authCheck(dispatch, error);
        }
    };
}

export function rejectApplication(applicationId, userLoggedInIdentityNumber, onProceed = null) {
    return async (dispatch) => {
        try {
            const appReject = {
                userLoggedInIdentityNumber: userLoggedInIdentityNumber,
                appResponse: applicationId + ' ' + 'No',
                applicationId: applicationId
            };
 
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const response = await axios.post(APP_URL, appReject);
            const responseData = response.data;
 
            if (responseData.success) {
                const applications = _.reverse(_.sortBy(responseData.data, application => application.id));
                dispatch({
                    type: types.SAVE_USER_APPLICATIONS_SUCCESS,
                    applications,
                    errorMessage: null
                });
 
                onProceed && onProceed();
            } else {
                console.log("user.loadUserApplications.errorMessage ===>", responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: "Couldn't retrieve your applications."
                });
            }
        } catch (error) {
            console.log("user.loadUserApplications.error ===>", error);
            authCheck(dispatch, error);
        }
    };
}