import React, {useEffect, useImperativeHandle, useState} from 'react';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import '../../../styles/kyc.css';
import Popup from '../../Popup';
import DocumentViewer from '../../DocumentViewer';
import FileUpload from "../../FileUpload";
import FormField from '../../formField/FormField';
import validate from 'validate.js';
import {HelpBlock} from 'react-bootstrap';
import {updateDoc, uploadDoc, verifyDoc} from '../../../actions/kyc';
import { Button } from 'react-bootstrap/lib/InputGroup';

import ELECBANK_ParticipantBankingTermsandConditions from "../../../resources/cashDocuments/ELECBANK_ParticipantBankingTermsandConditions.pdf";
import CDDRelianceQuestionnaire from "../../../resources/cashDocuments/CDD-Reliance-Questionnaire.pdf";
import SharedClientCertificate from "../../../resources/cashDocuments/Shared-Client-Certificate.pdf";

const DOC_TYPES = {
    powerofAttorney:'Power of Attorney',
    sharedClientCertificate: 'Shared Client Certificate',
    cddQuestionaire: 'CDD Questionaire',
    proofOfAuthorised: 'Proof of Authorised Individuals',
    participantTermsAndCondition: 'Participant Terms and Condition'
};

export const EntityDocsComponent = React.forwardRef((props, ref) => {

    const [documentType, setDocumentType] = useState('');
    const [entityDocs, setEntityDocs] = useState(props.entityDocs);
    const [showDocument, setShowDocument] = useState(false);
    const [errors, setErrors] = useState({});
    const [documentId, setDocumentId] = useState('');
    const dispatch = useDispatch();

    const findDoc = (entityDocs, documentType) => {
        let docFound = _.find(entityDocs, (doc) => (doc.documentType == documentType));
         return docFound ? docFound : {documentId: null,documentType:documentType};
    };

    useImperativeHandle(ref, () => {
        return {
            validateDocs: validateDocs
        };
    });



    const onHandleUpload = (base64, inProgress, onComplete, onSuccess) => {
        const handleOnSuccess = (documentId, data) => {
            onSuccess && onSuccess(documentId);
            setDocumentId(documentId);
        };
        dispatch(uploadDoc(base64, handleOnSuccess, null, inProgress, onComplete));
    };

    const onHandleRemoveDocument = (documentType) => {
        const {onRemoveDocument} = props;
        onRemoveDocument && onRemoveDocument(documentType);
    };

    const handleResetError = (attribute) => {
        setErrors({errors: _.extend({}, errors, {[attribute]: null})});
    };

    const validateDocs = () => {

        const options = {fullMessages: false};

        const checkDocument = (documentType) => {
            const doc = findDoc(entityDocs, documentType);
            return doc && doc.verified ? doc : null;
        };

        const constraints = {
            //changed values true to false 27/7/22
            [DOC_TYPES.resolution]: {presence: false},
            [DOC_TYPES.cipcDocument]: {presence: false},
            [DOC_TYPES.ownershipStructure]: {presence: false},
            [DOC_TYPES.financialStatements]: {presence: false},
        };
        const errors = validate({
            [DOC_TYPES.resolution]: checkDocument(DOC_TYPES.resolution),
            [DOC_TYPES.cipcDocument]: checkDocument(DOC_TYPES.cipcDocument),
            [DOC_TYPES.ownershipStructure]: checkDocument(DOC_TYPES.ownershipStructure),
            [DOC_TYPES.financialStatements]: checkDocument(DOC_TYPES.financialStatements),

        }, constraints, options);
        setErrors(errors);
        return _.isEmpty(errors);
    };

    const onHandleVerify = (documentId, documentType, onSuccess) => {
        const {handleDocUpdate} = props;

        _.map(entityDocs, doc => {
            if (doc.documentType === documentType) {
                doc.verified = true;
                doc.documentId = documentId;
            }
        });

        handleDocUpdate(entityDocs);
        onSuccess && onSuccess();

        // const onHandleSuccess = () => {
        //
        //     onHandleCloseDocument();
        //     onSuccess && onSuccess();
        //     onUploadComplete && onUploadComplete(documentType, documentId);
        // };
        // if (props.applicationId) {
        //     dispatch(verifyDoc(entityDocs, documentType, props.applicationId, documentId, onHandleSuccess));
        // } else {
        //     onHandleSuccess();
        // }
    };

    const handleViewDocument = (event, fileId, documentType) => {
        setShowDocument(true);
        setDocumentType(documentType);
        setDocumentId(fileId);
    };

    const onHandleCloseDocument = () => {
        setShowDocument(false);
    };

    const renderDocument = () => {
        const {isUpload} = props;

        if (!showDocument) return null;
        return (
            <Popup onClose={onHandleCloseDocument}>
                <DocumentViewer
                    isUpload={isUpload}
                    fileId={documentId}
                    documentType={documentType}
                    onUpload={onHandleUpload}
                    onRemove={onHandleRemoveDocument}
                    onVerify={onHandleVerify}
                    onClose={onHandleCloseDocument}
                />
            </Popup>
        );
    };


    const powerofAttorney = findDoc(entityDocs, DOC_TYPES.powerofAttorney);
    const clientCertDoc = findDoc(entityDocs, DOC_TYPES.sharedClientCertificate);
    const cddQuestionaireDoc = findDoc(entityDocs, DOC_TYPES.cddQuestionaire);
    const proofOfAuthorisedDoc = findDoc(entityDocs, DOC_TYPES.proofOfAuthorised);
    const participantTermsDoc = findDoc(entityDocs, DOC_TYPES.participantTermsAndCondition);

    console.log('powerofAttorney'+ JSON.stringify(powerofAttorney));

    return (
        <div>
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        Managed Funds Entity Documentation
                        <br/>
                        <HelpBlock>
                            <small>
                                For new managed fund accounts a POA and Shared Client Certificate is required.<br></br>
                                Documents are subject to due diligence, should any document be irregular KYC department will be in contact.<br></br>
                                To add account to IS Online please complete the participant Terms and Conditions.<br></br>
                            </small>
                        </HelpBlock>
                    </div>
                    <hr style={{marginTop: 10, marginBottom: 10}}/>



{/* ******************************************** Power of Attorney *************************************************** */}
                    <FormField
                        className="form-group"
                        error={errors && errors[DOC_TYPES.powerofAttorney] ? ['Document Required'] : ''}
                    >
                        <div className="flexRow">
                            <div className="col-md-6 col-sm-4  col-xs-4" style={{padding: 0}}>
                                <label className=" blackText">Power of Attorney </label>
                                <label
                                    className="form-group"
                                    id="powerOfAttorney"/>
                            </div>

                           <div className="col-md-3  col-sm-4  col-xs-4" style={{padding: 0}}>

                            </div>

                            <div className="col-md-3  col-sm-4  col-xs-4" style={{padding: 0}}>
                            {/* <label style={{width:'100%'}}>{props.isUpload ? 'Upload' : 'Upload'} </label>
                            <div style={{width:'100%'}}>
                                <FileUpload style={{width:'100%'}} documentType={DOC_TYPES.powerofAttorney} onUpload={onHandleUpload} />
                            </div> */}


                                <div
                                    className="flat-button-primary"
                                    onClick={(event) => {
                                        errors && errors[DOC_TYPES.powerofAttorney] && handleResetError(DOC_TYPES.powerofAttorney);
                                        handleViewDocument(event, powerofAttorney.documentId, DOC_TYPES.powerofAttorney);
                                    }}>
                                    {powerofAttorney && powerofAttorney.verified ? 'Confirmed' : 'Upload'}
                                    {/* {props.isUpload ? 'Upload' : 'Verify'} <i className="fa fa-angle-right fa-lg"/> */}
                                    {/* <i className="fa fa-angle-right fa-lg"/> */}
                                 </div>
                            </div>

                            {/*<div className="col-md-3  col-sm-4  col-xs-4" style={{padding: 0}}>
                                 <div
                                    className="flat-button-primary"
                                    onClick={(event) => {
                                        errors && errors[DOC_TYPES.cipcDocument] && handleResetError(DOC_TYPES.cipcDocument);
                                        handleViewDocument(event, proofOfLegalDoc.documentId, DOC_TYPES.cipcDocument);
                                    }}>
                                    {props.isUpload ? 'Upload' : 'Verify'} <i className="fa fa-angle-right fa-lg"/>
                                    {props.isUpload ? 'Upload' : 'Upload'}
                                    <i className="fa fa-angle-right fa-lg"/>
                                </div>
                            </div>  */}



                        </div>
                    </FormField>

                    <hr style={{marginTop: 10, marginBottom: 10}}/>


                    {/*************************************** Shared Client Certificate ******************************************************/}

                    <FormField
                        className="form-group"
                        error={errors && errors[DOC_TYPES.sharedClientCertificate] ? ['Document Required'] : ''}>
                        <div className="flexRow">
                            <div className="col-md-6  col-sm-4 col-xs-4" style={{padding: 0}}>
                                <label className=" blackText">Shared Client Certificate</label>
                                <label className="form-group" id="sharedClientCertificate"/>
                            </div>
                            <div className="col-md-3  col-sm-4 col-xs-4" style={{padding: 0}}>
                                <div className="flat-button-primary" variant="contained" color="primary">
                                    <a className="btn btn-secondary addButton" href={SharedClientCertificate} download target={'_blank'}>
                                    <i className="fa fa-download fa-fw"/> Download</a>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-4  col-xs-4" style={{padding: 0}}>
                                <div className="flat-button-primary" onClick={(event) => {
                                        errors && errors[DOC_TYPES.sharedClientCertificate] && handleResetError(DOC_TYPES.sharedClientCertificate);
                                        handleViewDocument(event, clientCertDoc.documentId, DOC_TYPES.sharedClientCertificate);
                                    }}>  {clientCertDoc && clientCertDoc.verified ? 'Confirmed' : 'Upload'}


                                </div>
                            </div>
                        </div>
                    </FormField>

                    <hr style={{marginTop: 10, marginBottom: 10}}/>

                    {/* ****************************************  CDD Questionaire ***************************************** */}
                    <FormField
                        className="form-group"
                        error={errors && errors[DOC_TYPES.cddQuestionaire] ? ['Document Required'] : ''}>
                        <div className="flexRow">
                            <div className="col-md-6 col-sm-4 col-xs-4" style={{padding: 0}}>
                                <label className=" blackText">CDD Questionaire</label>
                                <label className="form-group" id="cddQuestionaire"/>
                            </div>
                            {/* <div className="col-md-3  col-sm-4 col-xs-4" style={{padding: 0}}>
                                <label className="form-group" id="cddQuestionaire"/>
                                 {shareholderDoc.verified ? (props.isUpload ? 'Confirmed' : 'Verified') : (props.isUpload ? 'Unconfirmed' : 'Unverified')}
                             </div> */}

                                <div className="col-md-3 col-sm-4  col-xs-4 " style={{padding: 0}}>

                                    <div className="flat-button-primary" variant="contained" color="primary">
                                        <a className="btn btn-secondary addButton" href={CDDRelianceQuestionnaire} download target={'_blank'}>
                                        <i className="fa fa-download fa-fw"/> Download</a>
                                    </div>
                                </div>



                            <div className="col-md-3 col-sm-4  col-xs-4 " style={{padding: 0}}>
                                <div
                                    className="flat-button-primary" onClick={(event) => {
                                        errors && errors[DOC_TYPES.cddQuestionaire] && handleResetError(DOC_TYPES.cddQuestionaire);
                                        handleViewDocument(event, cddQuestionaireDoc.documentId, DOC_TYPES.cddQuestionaire);
                                    }}>  {cddQuestionaireDoc && cddQuestionaireDoc.verified ? 'Confirmed' : 'Upload'}

                                </div>
                            </div>
                        </div>
                    </FormField>
                    <hr style={{marginTop: 10, marginBottom: 10}}/>

                    {/* *********************************** Proof of Authorised ********************************************** */}
                    <FormField
                        className="form-group"
                        error={errors && errors[DOC_TYPES.proofOfAuthorised] ? ['Document Required'] : ''}>
                        <div className="flexRow">
                            <div className="col-md-6 col-sm-4 col-xs-4" style={{padding: 0}}>
                                <label className=" blackText">Proof of Authorised Individuals</label>
                                <label
                                    className="form-group"
                                    id="proofOfAuthorised"
                                />
                            </div>
                            <div className="col-md-3  col-sm-4 col-xs-4" style={{padding: 0}}>
                                <label
                                    className="form-group"
                                    id="financialStatementsStatus"
                                />
                                {/* {financialDoc.verified ? (props.isUpload ? 'Confirmed' : 'Verified') : (props.isUpload ? 'Unconfirmed' : 'Unverified')} */}
                            </div>
                            <div className="col-md-3 col-sm-4  col-xs-4 " style={{padding: 0}}>
                                <div
                                    className="flat-button-primary"
                                    onClick={(event) => {
                                        errors && errors[DOC_TYPES.proofOfAuthorised] && handleResetError(DOC_TYPES.proofOfAuthorised);
                                        handleViewDocument(event, proofOfAuthorisedDoc.documentId, DOC_TYPES.proofOfAuthorised);
                                    }}>
                                    {proofOfAuthorisedDoc && proofOfAuthorisedDoc.verified ? 'Confirmed' : 'Upload'}

                                </div>
                            </div>



                        </div>

                    </FormField>


                    <hr style={{marginTop: 10, marginBottom: 10}}/>
                    {/* ************************************************ Participant Terms and Condition ************************************************************* */}

                    <FormField
                        className="form-group"
                        error={errors && errors[DOC_TYPES.participantTermsAndCondition] ? ['Document Required'] : ''}>
                        <div className="flexRow">
                            <div className="col-md-6 col-sm-4 col-xs-4" style={{padding: 0}}>
                                <label className=" blackText">Participant Terms and Condition</label>
                                <label className="form-group" id="participantTermsAndCondition"/>
                            </div>
                                <div className="col-md-3 col-sm-4  col-xs-4 " style={{padding: 0}}>
                                    <div className="flat-button-primary" variant="contained" color="primary">
                                        <a className="btn btn-secondary addButton" href={ELECBANK_ParticipantBankingTermsandConditions} download target={'_blank'}>
                                    <i className="fa fa-download fa-fw"/> Download</a>

                                    </div>
                                    {/* <div
                                        className="flat-button-primary" onClick={(event) => {
                                            errors && errors[DOC_TYPES.ownershipStructure] && handleResetError(DOC_TYPES.ownershipStructure);
                                            handleViewDocument(event, shareholderDoc.documentId, DOC_TYPES.ownershipStructure);
                                        }}>{props.isUpload ? 'Upload' : 'Download'}
                                        <i className="fa fa-angle-right fa-lg"/>
                                    </div> */}
                                </div>


                            <div className="col-md-3 col-sm-4  col-xs-4 " style={{padding: 0}}>
                                <div
                                    className="flat-button-primary" onClick={(event) => {
                                        errors && errors[DOC_TYPES.participantTermsAndCondition] && handleResetError(DOC_TYPES.participantTermsAndCondition);
                                        handleViewDocument(event, participantTermsDoc.documentId, DOC_TYPES.participantTermsAndCondition);
                                    }}>{participantTermsDoc && participantTermsDoc.verified ? 'Confirmed' : 'Upload'}

                                </div>
                            </div>
                        </div>
                    </FormField>
                    <hr style={{marginTop: 10, marginBottom: 10}}/>

                </div>
            </div>
            {renderDocument()}
        </div>
    );
});
export default EntityDocsComponent;


