import {
    businessOnlineRoutes,
    digitalCreditRoutes,
    newAccountOpeningRoutes,
    newBolProfileRoutes,
    newCustodyAccountRoutes,managedFundRoutes,
    globalMarketAccountRoutes,
    remediationRoutes, tpfaRoutes, kycRoutes, signatoryRoots,
    shariahBcaRoutes,
    savingsAccountRoutes,
     electronicIndemnityRoutes
} from '../routers/routes';

export const ACCOUNT_OPENING_STEPS = [
    {name: 'Entity', path: newAccountOpeningRoutes.newAccount, isSelectable: false},
    {name: 'Confirm Details', path: newAccountOpeningRoutes.confirmdetails},
    {name: 'Confirm Product', path: newAccountOpeningRoutes.confirmproduct},
    {name: 'KYC', path: newAccountOpeningRoutes.kyc},
    {name: 'Confirm Authorisers', path: newAccountOpeningRoutes.confirmauthorisers},
    {name: 'Complete', path: newAccountOpeningRoutes.confirmapplication},
];

export const CUSTODY_ACCOUNT_STEPS = [
    {name: 'Entity', path: newCustodyAccountRoutes.cashAccount, isSelectable: false},
    {name: 'Account Capacity/Type', path: newCustodyAccountRoutes.accountrelations},
    {name: 'Beneficial Owner Details', path: newCustodyAccountRoutes.beneficialowner},
    {name: 'Address Details', path: newCustodyAccountRoutes.addressdetails},
    {name: 'Contact Details', path: newCustodyAccountRoutes.contactdetails},
    {name: 'Cash Account Purpose', path: newCustodyAccountRoutes.accountcashpurpose},
    {name: 'Instructing Party', path: newCustodyAccountRoutes.instructingparty},
    {name: 'Confirm Authorisers', path: newCustodyAccountRoutes.confirmauthorisers},
    {name: 'Complete', path: newCustodyAccountRoutes.complete}
];

export const MANAGED_FUND_CASH_ACCOUNT_STEPS = [
    {name: 'Entity', path: managedFundRoutes.cashAccount, isSelectable: false},
    {name: 'Confirm Details', path: managedFundRoutes.cashConfirmdetails},
    {name: 'Confirm Product', path: managedFundRoutes.cashConfirmProductdetails},
    {name: 'KYC', path: managedFundRoutes.cashKyc},
    {name: 'Confirm Authorisers', path: managedFundRoutes.cashConfirmAuthorisers},
    {name: 'Complete', path: managedFundRoutes.cashConfirmApplication}
];

export const MANAGED_FUND_CUSTODY_ACCOUNT_STEPS = [
    {name: 'Entity', path: managedFundRoutes.custodyAccount, isSelectable: false},
    {name: 'Account Capacity/Type ', path: managedFundRoutes.custodyAccountRelations},
    {name: 'Beneficial Owner Details ', path: managedFundRoutes.custodyBeneficialOwnerDetails},
    {name: 'Address Details', path: managedFundRoutes.custodyAddressDetails},
    {name: 'Contact Details', path: managedFundRoutes.custodyContactDetails},
    {name: 'Cash Account Purpose', path: managedFundRoutes.custodyCashAccountPurpose},
    {name: 'Instructing Party', path: managedFundRoutes.custodyInstructingParty},
    {name: 'IS Online and Document Upload', path: managedFundRoutes.custodyIsOnlineAndDocUpload},
    {name: 'Confirm Authorizers', path: managedFundRoutes.custodyConfirmAuthorizers},
    {name: 'Complete', path: managedFundRoutes.custodyConfirmApplicationPage}
];
export const MANAGED_FUND_CASH_AND_CUSTODY_ACCOUNT_STEPS = [
    {name: 'Entity', path: managedFundRoutes.cashAndCustodyAccount, isSelectable: false},
    {name: 'Confirm Details ', path: managedFundRoutes.cashAndCustodyConfirmdetails},
    {name: 'Confirm Product ', path: managedFundRoutes.cashAndCustodyConfirmProduct},
    {name: 'KYC', path: managedFundRoutes.cashAndCustodyKyc},
    {name: 'Account Capacity Type', path: managedFundRoutes.cashAndCustodyCapacityType},
    {name: 'Beneficial Owner Details', path: managedFundRoutes.cashAndCustodyBeneficialOwnerDetails},
    {name: 'Address Details', path: managedFundRoutes.cashAndCustodyAddressDetails},
    {name: 'Contact Details', path: managedFundRoutes.cashAndCustodyContactDetails},
    {name: 'Cash Account Purpose', path: managedFundRoutes.cashAndCustodyAccountPurpose},
    {name: 'Instructing Party', path: managedFundRoutes.cashAndCustodyInstructingParty},
    {name: 'Confirm Authorizers', path: managedFundRoutes.cashAndCustodyInstructingParty},
    {name: 'Complete', path: managedFundRoutes.cashAndCustodyInstructingParty}
];

export const ELECTRONIC_INDEMNITY_STEPS=[
    {name:'Entity', path: electronicIndemnityRoutes.electronicIndemnity, isSelectable: false},
    {name: 'Clauses', path: electronicIndemnityRoutes.electronicIndemnityClauses},
    {name: 'Authorised Individual For Email Indemnity', path: electronicIndemnityRoutes.authorisedIndividual},
    {name: 'Confirm Authorisers', path: electronicIndemnityRoutes.confirmAuthorisers},
    {name: 'Complete', path: electronicIndemnityRoutes.CompleteElectronicIndemnity}
];

export const ONBOARDING_NEW_ENTITY_STEPS = [
    {name: 'Lookup', path: '/onboard/lookup', isSelectable: false},
    {name: 'Register information', path: '/onboard/reginfo', isSelectable: false},
    {name: 'Entity Details', path: '/onboard/details', isSelectable: false},
    {name: 'KYC', path: '/onboard/kyc', isSelectable: false},
    {name: 'Complete', path: '/onboard/confirmed', isSelectable: false},
];

export const NEW_BOL_DOMESTIC_PRFOILE_ON_TAKE = [
    {name: 'Entity', path: newBolProfileRoutes.bol, isSelectable: false},
    {name: 'Customer Details', path: newBolProfileRoutes.details, isSelectable: false},
    {name: 'Channel Products & Services', path: newBolProfileRoutes.channel, isSelectable: false},
    {name: 'Levels And Options', path: newBolProfileRoutes.options, isSelectable: false},
    {name: 'Banking Accounts', path: newBolProfileRoutes.accounts, isSelectable: false},
    {name: 'Users Information', path: newBolProfileRoutes && newBolProfileRoutes.users, isSelectable: false},
    {name: 'Complete', path: newBolProfileRoutes.complete, isSelectable: false},
];

export const DIGITAL_CREDIT_STEPS = [
    {name: 'Entity', path: digitalCreditRoutes.root},
    {name: 'Details', path: digitalCreditRoutes.details},
    {name: 'Complete', path: digitalCreditRoutes.complete},
];

export const LEGAL_REMEDIATION = [
    {name: 'Entity', path: remediationRoutes.root, isSelectable: false},
    {name: 'EBTCs', path: remediationRoutes.legals, isSelectable: false},
    {name: 'Complete', path: remediationRoutes.complete, isSelectable: false}
];
export const BUSINESS_ONLINE_PROFILE = [
    {name: 'Legal Entity', path: businessOnlineRoutes.businessOnline},
    {name: 'Transaction Details', path: businessOnlineRoutes.transaction},
    {name: 'Accounts Setup', path: businessOnlineRoutes.accountSetup},
    {name: 'Authorization', path: businessOnlineRoutes.authorization},
    {name: 'Contact Details', path: businessOnlineRoutes.contactDetails},
    {name: 'Complete', path: businessOnlineRoutes.complete}
];
export const TPFA = [
    {name: 'Entity', path: tpfaRoutes.root, isSelectable: false},
    {name: 'Third Party Details', path: tpfaRoutes.thirdParty, isSelectable: true},
    {name: 'Confirm Authorisers', path: tpfaRoutes.tpfaConfirmAuthorisers, isSelectable: true},
    {name: 'Complete', path: tpfaRoutes.complete, isSelectable: false},
];

export const KYC = [
    {name: 'Legal Entity', path: kycRoutes.root, isSelectable: false},
    {name: 'Entity Details', path: kycRoutes.entityDetails, isSelectable: false},
    {name: 'People', path: kycRoutes.peopleDetails, isSelectable: false},
    {name: 'Documents', path: kycRoutes.documents, isSelectable: false},
    {name: 'Complete', path: kycRoutes.complete, isSelectable: false},
];


export const GLOBAL_MARKET_STEPS = [
    {name: 'Entity', path: globalMarketAccountRoutes.entity, isSelectable: true},
    {name: 'Confirm Entity', path: globalMarketAccountRoutes.confirmentity, isSelectable: true},
    // {name: "Customers", path: globalMarketAccountRoutes.customers, isSelectable: true},
    {name: 'Settlement Instructions', path: globalMarketAccountRoutes.instructions, isSelectable: false},
    {name: 'Complete', path: globalMarketAccountRoutes.complete},
];

export const SIGNATORY = [
    {name: 'Entity', path: signatoryRoots.addStart, isSelectable: false},
    {name: 'Add Signatory Instructions', path: signatoryRoots.addInstructions, isSelectable: false},
    {name: 'Confirm Authorizers', path: signatoryRoots.confirmAuthorizers, isSelectable: false}
];

export const SHARIAH_BCA_STEPS = [
    {name: 'Entity', path: shariahBcaRoutes.shariahBcaEntity, isSelectable: false},
    {name: 'Confirm Details', path: shariahBcaRoutes.shariahBcaConfirmDetails, isSelectable: false},
    {name: 'Confirm Products', path: shariahBcaRoutes.shariahBcaConfirmProduct, isSelectable: false},
    {name: 'KYC', path: shariahBcaRoutes.shariahBcaKYC, isSelectable: false},
    {name: 'Confirm Authorisers', path: shariahBcaRoutes.shariahBcaConfirmAuthorisers, isSelectable: false},
    {name: 'Complete', path: shariahBcaRoutes.complete, isSelectable: false}
];

export const SAVINGS_ACCOUNT_STEPS = [
    {name: 'Entity', path: savingsAccountRoutes.savingsAccountEntity, isSelectable: false},
    {name: 'Confirm Details', path: savingsAccountRoutes.savingsAccountConfirmDetails, isSelectable: false},
    {name: 'Confirm Products', path: savingsAccountRoutes.savingsAccountConfirmProduct, isSelectable: false},
    {name: 'Signatories', path: savingsAccountRoutes.savingsAccountKYC, isSelectable: false},
    {name: 'Confirm Authorisers', path: savingsAccountRoutes.savingsAccountConfirmAuthorisers, isSelectable: false},
    {name: 'Complete', path: savingsAccountRoutes.savingsAccountComplete, isSelectable: false}
];