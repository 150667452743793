import React from "react";
import { useState } from 'react';
import {useSelector, useDispatch} from "react-redux";
import ReactLoading from "react-loading";

export const REPORT_TYPES = {
    APPLICATION_STATUS: 'ELECTRONIC_INDEMNITY_CONFIRMATION_LETTER',
};


export const ConfirmApplicationPage = (props) => {

    const applicationId = useSelector(state => state.electronicIndemnity && state.electronicIndemnity.id);
    const dispatch = useDispatch();
    const [isLoading, setisLoading] = useState(false);


   const loadingStart = ()=> {
        setisLoading(false);
    };

    const loadingFinish = ()=> {
        setisLoading(true);
    };

 const handleOnDownloadApplication = (e) => {
        // e.preventDefault();
        const {actions} = props;
        loadingStart();

        const onComplete = () => {
            loadingFinish();
        };
        actions.downloadReport(REPORT_TYPES.APPLICATION_STATUS, {applicationId}, onComplete, true);
    };



   const handleOnNavgiateToHome = (e) => {
        // e.preventDefault();
        const {history} = props;
        history.replace("/");
    };

    const renderFooter = () => {
        return(
            <div className="action-button-container">
                {/* <button
                    className="action-btn-primary btn-sm"
                    onClick={()=>handleOnDownloadApplication()}
                    type="submit">Download Application
                </button>  */}
                <button
                    className="action-btn-primary btn-sm"
                    onClick={()=>handleOnNavgiateToHome()}
                    type="button">Home </button>
            </div>
        )
    }

    return(
            <div className="page-container">
                <div className="page-main-section" style={{minHeight:"45vh"}}>
                    <div className="flexColumn">
                        <div className="card-container" style={{marginTop:'10vh'}}>
                            <div className="section-title">The Electronic Indemnity Details has been successfully completed and submitted</div>
                        </div>
                    </div>
                </div>
                <div className="page-footer-section">
                    {renderFooter()}
                </div>
                {
                    isLoading &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
            </div>
    )


}

export default (ConfirmApplicationPage);