import React from 'react';
import {bindActionCreators} from "redux";
import * as actions from "../../../actions/reports";
import connect from "react-redux/es/connect/connect";
import ReactLoading from "react-loading";

const REPORT_TYPES = {
    ONBOARD_ENTITY: "ONBOARD_ENTITY"
};

class OnboardConfirmationPage extends React.Component {
    state = {
        isLoading: false
    };

    _loadingStart() {
        this.setState({isLoading: true})
    };

    _loadingFinish() {
        this.setState({isLoading: false})
    };

    onDownloadReport = (event) => {
        event.preventDefault();
        this._loadingStart();

        const {actions, newEntityDetails} = this.props;
        const onComplete = () => {
            this._loadingFinish()
        };

        const applicationId = newEntityDetails && newEntityDetails.id;
        actions.downloadReport(REPORT_TYPES.ONBOARD_ENTITY, {applicationId}, onComplete, true);
    };

    onNavigateToHome = () =>{
        const {history} = this.props;
        history && history.replace("/");
    }

    _renderFooter() {
        return (
            <div className="action-button-container" style={{width: "100%"}}>
                <button
                    className="action-btn-primary"
                    onClick={this.onDownloadReport}
                    type="submit"
                >Download Application
                </button>
                <button
                    className="action-btn-primary"
                    onClick={this.onNavigateToHome}
                    type="button"
                >Home
                </button>
            </div>
        )
    }

    render() {
        const {isLoading} = this.state;

        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="flexColumn">
                        <div className="card-container-form">
                            <div className="section-title">
                                Onboarding Confirmation
                            </div>
                            <p>Thank you!</p>
                            <p>The entity has been submitted for onboarding. KYC Contact will receive a notification,
                                once it has been approved/validated.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
                {
                    isLoading &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps({entity, loading}) {
    return {
        newEntityDetails: entity && entity.newEntityDetails ? entity.newEntityDetails : {},
        loading
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardConfirmationPage);