import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function errorReducer(state = false, action) {
  switch (action.type) {
      case types.LOADING_START:
          return true;
      case types.LOADING_FINISH:
          return false;
    default:
      return state;
  }
}