import React, {useState} from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import validate from 'validate.js'

import "../../../styles/ConfirmApplication.css";
import "../../../styles/Global.css";
import FormField from "../../formField/FormField";
import {managedFundRoutes} from "../../../routers/routes";
import ButtonsComponent from "../../ButtonsComponent";
import {Panel, PanelGroup} from "react-bootstrap";
import Select from "react-select";
import {saveCashCustodyManagedFund} from "../../../actions/cashAndCustodyManagedFund";
import {MONTH_DAYS, WEEK_DAYS} from "../../custodyConstants";

const SETTLEMENT_NOTIFICATIONS = [
    {description: 'Receive free confirmation - RFP', code: "MT544"},
    {description: 'Receive versus payment confirmation - RVP', code: "MT545"},
    {description: 'Deliver free confirmation -DFP', code: "MT546"},
    {description: 'Deliver versus payment confirmation - DVP', code: "MT547"},
    {description: 'Settlement status and processing advice', code: "MT548"},
    {description: 'Settlement alledgement', code: "MT578"},
    {description: 'Advice on charges,interest,and other adjustments', code: "MT590"},
    {description: 'Customer statement message', code: "MT940"}
];
const FREQUENCY = [
    {value: 'Daily', label: "Daily"},
    {value: 'Weekly', label: "Weekly"},
    {value: 'Monthly', label: "Monthly"}
];


const MT535_BASIS = [
    {value: 'None', label: "Not Applicable"},
    {value: 'Settled Position', label: "Settled Position"},
    {value: 'Traded Position', label: "Traded Position"}
];

const NOTIFICATION_OPTIONS = [
    {value: 'MAIN', label: "Instructing Party"},
    {value: 'ALTERNATE', label: "Alternate Instructing Party"}
];

const STATEMENT_OPTIONS = [
    {value: 'MAIN', label: "Instructing Party"},
    {value: 'ALTERNATE', label: "Alternate Instructing Party"}
];

const MT537_BASIS = [
    {value: 'None', label: "Not Applicable"},
    {value: 'Status', label: "Status"},
    {value: 'Transaction Id', label: "Transaction Id"}];

const CA_NOTIFICATIONS = [
    {description: 'Acknowledgement', code: "MT567"},
    {description: 'Complimentary', code: "MT568"},
    {description: 'Entitlement Advice', code: "MT564"},
    {description: 'Event Announcement', code: "MT564"},
    {description: 'Pre-Announcement', code: "MT564"},
    {description: 'Change in Client Position', code: "MT564"},
    {description: 'Payment Advice', code: "MT566"},
    {description: 'Reminder Advice 1', code: "MT564"},
    {description: 'Reminder Advice 2', code: "MT564"},
    {description: 'Reminder Advice 3', code: "MT564"}
];


export const InstructingPartyPage = (props) => {

    const custodyRequest = useSelector(state => state.cashCustodyManagedFund && state.cashCustodyManagedFund.cashCustodyManagedFundRequest);
    const appId = useSelector(state => state.cashCustodyManagedFund && state.cashCustodyManagedFund.id);
    const [instructingParty, setInstructingParty] = useState(custodyRequest.instructingParty);
    const [pendingStatement, setPendingStatement] = useState(custodyRequest.instructingParty.pendingStatement);
    const [transactionStatement, setTransactionStatement] = useState(custodyRequest.instructingParty.transactionStatement);
    const [holdingsStatement, setHoldingsStatement] = useState(custodyRequest.instructingParty.holdingsStatement);
    const [settlementNotifications, setSettlementNotifications] = useState(custodyRequest.instructingParty.settlementNotifications);
    const [corporateNotifications, setCorporateNotifications] = useState(custodyRequest.instructingParty.corporateNotifications);

    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();

    const handleSwiftMessages = (e, code) => {

        let instructions = settlementNotifications.instructions;

        if (instructions) {
            if (instructions.includes(code)) {
                instructions = _.remove(instructions, function (c) {
                    return c !== code;
                });
            } else {
                instructions.push(code);
            }
        } else {
            instructions = [code];
        }
        setSettlementNotifications({...settlementNotifications, instructions});
    };

    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    };

    const saveDetails = (event) => {
        event.preventDefault();
        // if (validateForm()) {

            instructingParty.holdingsStatement = holdingsStatement;
            instructingParty.transactionStatement = transactionStatement;
            instructingParty.pendingStatement = pendingStatement;
            instructingParty.settlementNotifications = settlementNotifications;
            instructingParty.corporateNotifications = corporateNotifications;
            custodyRequest.instructingParty = instructingParty;

            const onSuccess = () => props.history.push(managedFundRoutes.cashAndCustodyConfirmAuthorisers);
            dispatch(saveCashCustodyManagedFund(appId, custodyRequest, 'UserTask_InstructingParty', onSuccess));
        // }
    };

    const saveAndContinue = (event) => {
        event.preventDefault();

        instructingParty.holdingsStatement = holdingsStatement;
        instructingParty.transactionStatement = transactionStatement;
        instructingParty.pendingStatement = pendingStatement;
        instructingParty.settlementNotifications = settlementNotifications;
        instructingParty.corporateNotifications = corporateNotifications;
        custodyRequest.instructingParty = instructingParty;
        
        const onSuccess = () => props.history.push('/saveapplication');
        dispatch(saveCashCustodyManagedFund(appId, custodyRequest, 'saveContinue', onSuccess));

    };

    // const validateForm = () => {
    //     const options = {fullMessages: false};

    //     let constraints = {
    //         "instructingPartyName": {presence: true, length: {minimum: 3, message: 'required'}}, 
    //         // "swiftAddress": {presence: true, length: {minimum: 3, message: 'required'}}, 
    //     };
    //     const errors = validate(instructingParty, constraints, options);

    //     setErrors(errors);
    //     return _.isEmpty(errors);
    // };


    return (
        <div className="page-container">
            <div className="page-main-section">
                <div className="card-container-form">

                    <div className="row">
                        <div className="col-md-3">
                            {/*instructing party*/}
                            <div className="section-title">
                                <span>Instructing Party</span>
                            </div>

                            <FormField className="form-group" id="instructingPartyName"
                                       error={errors && errors.instructingPartyName}>
                                <label htmlFor="instructingPartyName">Full legal name of Fund Manager</label>
                                <input
                                    className="form-control"
                                    id="instructingPartyName"
                                    onChange={(event) => {
                                        setInstructingParty({
                                            ...instructingParty,
                                            instructingPartyName: event.target.value
                                        });
                                    }}
                                    type="text"
                                    value={instructingParty && instructingParty.instructingPartyName}

                                />
                            </FormField>
                            <FormField className="form-group" id="swiftAddress"
                                       error={errors && errors.swiftAddress && ["Invalid Swift Address"]}>
                                <label htmlFor="swiftAddress">Instructing Party Swift Address </label>
                                <input
                                    className="form-control"
                                    // style={style}
                                    onChange={(event) => {
                                        setInstructingParty({...instructingParty, swiftAddress: event.target.value});
                                    }}

                                    type="text"
                                    value={instructingParty && instructingParty.swiftAddress}
                                />

                            </FormField>
                            <FormField className="form-group" id="alternateSwiftAddress"
                                       error={errors && errors.alternateSwiftAddress && ["Invalid Swift Address"]}>
                                <label htmlFor="alternateSwiftAddress">Alternate Instructing Party Swift Address</label>
                                <input
                                    className="form-control"
                                    id="alternateSwiftAddress"
                                    onChange={(event) => {
                                        setInstructingParty({
                                            ...instructingParty,
                                            alternateSwiftAddress: event.target.value
                                        });
                                    }}
                                    type="text"
                                    value={instructingParty && instructingParty.alternateSwiftAddress}
                                />

                            </FormField>


                        </div>
                        <div className="col-md-1"></div>

                        <div className="col-md-8">

                            <div className="section-title centerAlign">
                                <span>Messaging and Reporting</span>
                            </div>

                            <PanelGroup accordion id="search-results">
                                <Panel eventKey="1">
                                    <Panel.Heading>
                                        <Panel.Title toggle>Settlement Notifications</Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        <div className="col-md-12">
                                            <div className="col-md-8">
                                                <b>Send instructions to : </b>
                                            </div>
                                            <div className="col-md-4">
                                                <Select
                                                    onChange={(event) => {
                                                        setSettlementNotifications({
                                                            ...settlementNotifications,
                                                            sendInstructionTo: event.value
                                                        });
                                                    }}
                                                    options={NOTIFICATION_OPTIONS}
                                                    value={settlementNotifications && settlementNotifications.sendInstructionTo}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <hr/>
                                        </div>


                                        {SETTLEMENT_NOTIFICATIONS.map((item, i) =>
                                            <div className="col-md-12">
                                                <div className="col-md-8">
                                                    {item.description}
                                                </div>
                                                <div className="col-md-3">
                                                    {item.code}
                                                </div>
                                                <div className="col-md-1">
                                                    <input
                                                        type="checkbox"
                                                        checked={settlementNotifications && settlementNotifications.instructions && settlementNotifications.instructions.includes(item.code)}
                                                        onChange={(event) => {
                                                            handleSwiftMessages(event, item.code);
                                                        }}

                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Panel.Body>
                                </Panel>

                                <Panel eventKey="2">
                                    <Panel.Heading>
                                        <Panel.Title toggle>Statement Generation</Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        <div className="col-md-12">
                                            <div className="col-md-3">
                                                <b>Statement</b>
                                            </div>

                                            <div className="col-md-2">
                                                <b>Statement Basis</b>
                                            </div>
                                            <div className="col-md-2">
                                                <b>Frequency</b>
                                            </div>
                                            <div className="col-md-3">
                                                <b>Instructing Party</b>
                                            </div>
                                            <div className="col-md-2">
                                                <b>Day</b>
                                            </div>

                                        </div>
                                        <br/><br/>
                                        <div className="col-md-12">
                                            <div className="col-md-3">
                                                Statement Of Holding - MT535
                                            </div>
                                            <div className="col-md-2">
                                                <Select
                                                    onChange={(event) => {
                                                        setHoldingsStatement({
                                                            ...holdingsStatement,
                                                            statementBasis: event.value
                                                        });
                                                    }}
                                                    options={MT535_BASIS}
                                                    value={holdingsStatement && holdingsStatement.statementBasis}
                                                />
                                            </div>
                                            <div className="col-md-2">
                                                <Select
                                                    onChange={(event) => {
                                                        setHoldingsStatement({
                                                            ...holdingsStatement,
                                                            frequency: event.value
                                                        });
                                                    }}
                                                    options={FREQUENCY}
                                                    value={holdingsStatement && holdingsStatement.frequency}/>
                                            </div>
                                            <div className="col-md-3">

                                                <Select
                                                    onChange={(event) => {
                                                        setHoldingsStatement({
                                                            ...holdingsStatement,
                                                            selected: event.value
                                                        });
                                                    }}
                                                    options={STATEMENT_OPTIONS}
                                                    value={holdingsStatement && holdingsStatement.selected}/>
                                            </div>
                                            <div className="col-md-2">

                                                {holdingsStatement && holdingsStatement.frequency === 'Weekly' &&
                                                    <Select
                                                        onChange={(event) => {
                                                            setHoldingsStatement({
                                                                ...holdingsStatement,
                                                                day: event.value
                                                            });
                                                        }}
                                                        options={WEEK_DAYS}
                                                        value={holdingsStatement && holdingsStatement.day}/>}

                                                {holdingsStatement && holdingsStatement.frequency === 'Monthly' &&

                                                    <Select
                                                        onChange={(event) => {
                                                            setHoldingsStatement({
                                                                ...holdingsStatement,
                                                                day: event.value
                                                            });
                                                        }}
                                                        options={MONTH_DAYS}
                                                        value={holdingsStatement && holdingsStatement.day}/>
                                                }
                                            </div>

                                        </div>
                                        <br/> <br/>

                                        <div className="col-md-12">
                                            <div className="col-md-3">
                                                Statement Of Transaction - MT536
                                            </div>
                                            <div className="col-md-2">
                                                <Select
                                                    onChange={(event) => {
                                                        setTransactionStatement({
                                                            ...transactionStatement,
                                                            statementBasis: event.value
                                                        });
                                                    }}
                                                    options={MT535_BASIS}
                                                    value={transactionStatement && transactionStatement.statementBasis}/>
                                            </div>
                                            <div className="col-md-2">
                                                <Select
                                                    onChange={(event) => {
                                                        setTransactionStatement({
                                                            ...transactionStatement,
                                                            frequency: event.value
                                                        });
                                                    }}
                                                    options={FREQUENCY}
                                                    value={transactionStatement && transactionStatement.frequency}/>
                                            </div>

                                            <div className="col-md-3">

                                                <Select
                                                    onChange={(event) => {
                                                        setTransactionStatement({
                                                            ...transactionStatement,
                                                            selected: event.value
                                                        });
                                                    }}
                                                    options={STATEMENT_OPTIONS}
                                                    value={transactionStatement && transactionStatement.selected}/>
                                            </div>
                                            <div className="col-md-2">

                                                {transactionStatement && transactionStatement.frequency === 'Weekly' &&
                                                    <Select
                                                        onChange={(event) => {
                                                            setTransactionStatement({
                                                                ...transactionStatement,
                                                                day: event.value
                                                            });
                                                        }}
                                                        options={WEEK_DAYS}
                                                        value={transactionStatement && transactionStatement.day}/>}

                                                {transactionStatement && transactionStatement.frequency === 'Monthly' &&

                                                    <Select
                                                        onChange={(event) => {
                                                            setTransactionStatement({
                                                                ...transactionStatement,
                                                                day: event.value
                                                            });
                                                        }}
                                                        options={MONTH_DAYS}
                                                        value={transactionStatement && transactionStatement.day}/>
                                                }
                                            </div>

                                        </div>

                                        <br/><br/>

                                        <div className="col-md-12">
                                            <div className="col-md-3">
                                                Statement Of Pending Transactions - MT537
                                            </div>
                                            <div className="col-md-2">
                                                <Select
                                                    onChange={(event) => {
                                                        setPendingStatement({
                                                            ...pendingStatement,
                                                            statementBasis: event.value
                                                        });
                                                    }}
                                                    options={MT537_BASIS}
                                                    value={pendingStatement && pendingStatement.statementBasis}/>
                                            </div>
                                            <div className="col-md-2">
                                                <Select
                                                    onChange={(event) => {
                                                        setPendingStatement({
                                                            ...pendingStatement,
                                                            frequency: event.value
                                                        });
                                                    }}
                                                    options={FREQUENCY}
                                                    value={pendingStatement && pendingStatement.frequency}/>
                                            </div>

                                            <div className="col-md-3">

                                                <Select
                                                    onChange={(event) => {
                                                        setPendingStatement({
                                                            ...pendingStatement,
                                                            selected: event.value
                                                        });
                                                    }}
                                                    options={STATEMENT_OPTIONS}
                                                    value={pendingStatement && pendingStatement.selected}/>
                                            </div>

                                            <div className="col-md-2">

                                                {pendingStatement && pendingStatement.frequency === 'Weekly' &&
                                                    <Select
                                                        onChange={(event) => {
                                                            setPendingStatement({
                                                                ...pendingStatement,
                                                                day: event.value
                                                            });
                                                        }}
                                                        options={WEEK_DAYS}
                                                        value={pendingStatement && pendingStatement.day}/>}

                                                {pendingStatement && pendingStatement.frequency === 'Monthly' &&

                                                    <Select
                                                        onChange={(event) => {
                                                            setPendingStatement({
                                                                ...pendingStatement,
                                                                day: event.value
                                                            });
                                                        }}
                                                        options={MONTH_DAYS}
                                                        value={pendingStatement && pendingStatement.day}/>
                                                }
                                            </div>

                                        </div>
                                    </Panel.Body>
                                </Panel>
                                <Panel eventKey="3">
                                    <Panel.Heading>
                                        <Panel.Title toggle>Corporate Actions Notifications</Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        <div className="col-md-12">
                                            <div className="col-md-8">
                                                <b>Send instructions to : </b>
                                            </div>
                                            <div className="col-md-4">
                                                <Select
                                                    onChange={(event) => {
                                                        setCorporateNotifications({
                                                            ...corporateNotifications,
                                                            sendInstructionTo: event.value
                                                        });
                                                    }}
                                                    options={NOTIFICATION_OPTIONS}
                                                    value={corporateNotifications && corporateNotifications.sendInstructionTo}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <hr/>
                                        </div>


                                        {CA_NOTIFICATIONS.map((item, i) =>
                                            <div className="col-md-12">
                                                <div className="col-md-8">
                                                    {item.description}
                                                </div>
                                                <div className="col-md-3">
                                                    {item.code}
                                                </div>
                                                <div className="col-md-1">
                                                    <input
                                                        type="checkbox"
                                                        checked={true}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </Panel.Body>
                                </Panel>
                            </PanelGroup>

                        </div>

                    </div>

                </div>
            </div>
            <div className="page-footer-section" style={{width: '80%'}}>
                <ButtonsComponent
                    history={props.history}
                    onNext={saveDetails}
                    onSaveAndContinue={saveAndContinue}
                    prevPage={managedFundRoutes.cashAndCustodyAccountPurpose}
                />
            </div>
        </div>
    );
};

export default (InstructingPartyPage);
