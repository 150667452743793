import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import Popup from '../Popup';
import DocumentViewer from '../DocumentViewer';
import Modal from 'react-bootstrap/lib/Modal';
import Alert from 'react-bootstrap/lib/Alert';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import validate from 'validate.js';
import {uploadDoc, verifyDoc, clearIndiviualInfo, fetchIndiviualInfo, updateDoc} from '../../actions/kyc';
import {onDirChangeValue, onNewDoc, completeKYC, kycSaved, onChangeValue, saveKYC} from '../../actions/kycMaintenance';
import ButtonsComponent from '../ButtonsComponent';
import FileUpload from '../FileUpload';


const wellStyles = {maxWidth: 400, margin: '0 auto 10px'};
const DOC_TYPES = {
    proofOfId: 'Proof of Identity',
    proofOfAddress: 'Proof Of Address',
    cipcDocument: 'CIPC Document',
    ownerDocument: 'Ownership Structure',
    resolution: 'Resolution',
    financialStatements: 'Financial Statements'
};
const exlusions = [DOC_TYPES.ownerDocument, DOC_TYPES.resolution, DOC_TYPES.cipcDocument, DOC_TYPES.financialStatements];
export const DocumentsPage = (props) => {
    const id = useSelector(state => state.kyc && state.kyc.id);
    const [selectedDocument, setSelectedDocument] = useState(undefined);
    const [errors, setErrors] = useState(false);
    const [document, setDocument] = useState(undefined);
    const [submitting, setSubmitting] = useState(false);
    const [submittingError, setSubmittingError] = useState(false);
    const [validationErrors, setValidationErrors] = useState(false);
    const [addDoc, setAddDoc] = useState(false);
    const [newDoc, setNewDoc] = useState(null);
    const [financials, setFinancials] = useState(true);
    const [accountStatements, setAccountStatements] = useState(false);
    const [cfoLetter, setCfoLetter] = useState(false);
    const [auditorLetter, setAuditorLetter] = useState(false);
    const application = useSelector(state => state.kyc && state.kyc.kycRequest);
    const dispatch = useDispatch();
    const _findDoc = (entityDocs, documentType) => {
        const docFound = _.find(entityDocs, (doc) => (doc.type === documentType));
        return docFound ? docFound : {type: documentType, isNew: true};
    };
    const shareholderDoc = _findDoc(application.documents, DOC_TYPES.ownerDocument);
    const proofOfAuthDoc = _findDoc(application.documents, DOC_TYPES.resolution);
    const proofOfLegalDoc = _findDoc(application.documents, DOC_TYPES.cipcDocument);
    const finStatementsDoc = _findDoc(application.documents, DOC_TYPES.financialStatements);


    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const submitReview = () => {
        // const {kyc: {id, kycRequest}, actions, history} = this.props;
        const onSuccess = () => {
            dispatch(kycSaved());
        };
        setSubmittingError(null);
        if (validateEmail(application.contactEmail)) {
            setSubmitting(false);
            dispatch(completeKYC(id, application, props.history));
        } else {
            setSubmittingError(<span>Please enter a valid <strong>email address</strong>.</span>);
        }

    };

    const onNext = () => {
        // submitReview();
        setSubmitting(true);
    };

    const handleResetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    };

    const handleEntityInfoChange = ({target}) => {
        const {name, value} = target;
        dispatch(onChangeValue(name, value));
    };

    const handleViewDocument = (selectedDocument) => {
        errors && errors.selectedDocument && handleResetError('selectedDocument');
        if (selectedDocument && selectedDocument.externalId) {
            setSelectedDocument({
                id: selectedDocument.externalId,
                type: selectedDocument.type
            });
            setDocument(selectedDocument);
        } else {
            setSelectedDocument({
                id: null,
                type: selectedDocument.type
            });
            setDocument(selectedDocument);
        }

    };

    const onHandleUpload = (base64, inProgress, onComplete, onSuccess) => {
        const handleOnSuccess = (documentId) => {
            selectedDocument.isUpload = true;
            selectedDocument.updated = true;
            selectedDocument.id = documentId;
            setSelectedDocument(selectedDocument);
            onSuccess && onSuccess(documentId);
        };
        dispatch(uploadDoc(base64, handleOnSuccess, null, inProgress, onComplete));
    };
    const onHandleNewDocUpload = (base64, inProgress, onComplete, onSuccess) => {
        const selectedDocument = newDoc;
        const handleOnSuccess = (documentId) => {
            selectedDocument.isUpload = true;
            selectedDocument.updated = true;
            selectedDocument.id = documentId;
            setNewDoc(selectedDocument);
            onSuccess && onSuccess(documentId);
        };
        dispatch(uploadDoc(base64, handleOnSuccess, null, inProgress, onComplete));
    };

    const onHandleVerify = () => {
        // const {document, selectedDocument} = this.state;
        if (document.externalId || selectedDocument.id) {
            if (selectedDocument.isUpload)
                document.externalId = selectedDocument.id;
            document.verified = true;
            if (document.name) {
                dispatch(onDirChangeValue('', 'documents', document));
            } else {
                dispatch(onNewDoc(document));
            }
            setSelectedDocument(null);
            setDocument(null);
        }
    };
    const onHandleNewDocVerify = () => {
        // const {document, selectedDocument} = this.state;
        if (newDoc.id) {
            newDoc.externalId = newDoc.id;
            newDoc.verified = true;
            dispatch(onNewDoc(newDoc));
            setNewDoc(null);
        }
    };

    const onHandleRemoveDocument = (documentType) => {
        const {onRemoveDocument} = this.props;
        onRemoveDocument && onRemoveDocument(documentType);
    };
    const onHandleNewDocRemoveDocument = (documentType) => {
        const {onRemoveDocument} = this.props;
        onRemoveDocument && onRemoveDocument(documentType);
    };

    const checkDocument = (type, {documents}) => {
        const document = _findDoc(documents, type);
        return document.verified ? null : (<span>Please upload / verify  <strong>"{type} Document"</strong></span>);
    };

    const validateDocs = () => {
        const {entityDocs} = this.state;
        const options = {fullMessages: false};


        const constraints = {
            [DOC_TYPES.resolution]: {presence: true},
            [DOC_TYPES.cpicDocument]: {presence: true},
            [DOC_TYPES.ownershipStructure]: {presence: true},
        };
        const errors = validate({
            [DOC_TYPES.resolution]: checkDocument(DOC_TYPES.resolution),
            [DOC_TYPES.cipcDocument]: checkDocument(DOC_TYPES.cipcDocument),
            [DOC_TYPES.ownershipStructure]: checkDocument(DOC_TYPES.ownershipStructure),

        }, constraints, options);
        this.setState({errors});
        return _.isEmpty(errors);
    };

    const handleDocDesc = (desc) => {
        finStatementsDoc.description = desc;
        switch (desc) {
            case 'cfoLetter':
                setFinancials(false);
                setAccountStatements(false);
                setCfoLetter(true);
                setAuditorLetter(false);
                break;
            case 'accountStatements':
                setFinancials(false);
                setAccountStatements(true);
                setCfoLetter(false);
                setAuditorLetter(false);
                break;
            case 'auditorLetter':
                setFinancials(false);
                setAccountStatements(false);
                setCfoLetter(false);
                setAuditorLetter(true);
                break;
            case 'financials':
            default:
                setFinancials(true);
                setAccountStatements(false);
                setCfoLetter(false);
                setAuditorLetter(false);
                break;
        }
        dispatch(updateDoc(financialDoc));
    };

    const completeSection = () => {
        let validationErrors = [];
        // const {directors, children} = application;
        // children.forEach(relatedParty => {
        //     const empty = this.checkRelatedPartyAddress(relatedParty);
        //     empty && validationErrors.push(
        //         <span>Please complete an address for <strong>"{relatedParty.name}"</strong></span>);
        // });
        // directors.forEach(director => {
        //     const empty = checkAuthorisedAddress(director);
        //     empty && validationErrors.push(
        //         <span>Please complete/choose an address for <strong>"{director.firstName} {director.lastName}"</strong></span>);
        // });
        let checker = checkDocument(DOC_TYPES.ownerDocument, application);
        checker && validationErrors.push(checker);
        checker = checkDocument(DOC_TYPES.cipcDocument, application);
        checker && validationErrors.push(checker);
        checker = checkDocument(DOC_TYPES.resolution, application);
        checker && validationErrors.push(checker);
        checker = checkDocument(DOC_TYPES.financialStatements, application);
        checker && validationErrors.push(checker);
        // let checker = this.checkEntityAddress(kycRequest, 'Principal');
        // checker && validationErrors.unshift(checker);
        // checker = this.checkEntityAddress(kycRequest, 'Trading');
        // checker && validationErrors.unshift(checker);
        // validationErrors = KycMaintenance.checkEntityDetails(kycRequest, validationErrors);
        // typeof application.pip !== 'boolean' &&
        // validationErrors.unshift(
        //     <span>Please verify if any of the authorised/controlling persons
        //             are either a public official in a position of authority or
        //             related/associated to a public official in a position of authority?.</span>);
        // application.pip && _.isEmpty(application.pips) &&
        // validationErrors.unshift(<span>Please add the authorised/controlling persons that are
        //             either a public official in a position of authority or
        //             related/associated to a public official in a position of authority</span>);
        // _.isEmpty(application.controllers) &&
        // validationErrors.unshift(<span>Please add entity <strong>Controllers</strong>.</span>);
        validationErrors.length > 0 ? setValidationErrors(validationErrors) : onNext();
    };

    const handleNewDocNameChange = (e) => {
        setNewDoc({type: e.target.value});
    };

    const renderDocumentErrors = () => {
        return (
            <div className="row" style={{ margin: 0 }}>
                {Object.keys(validationErrors).map((docType, i) => (
                    <Alert bsStyle="danger" key={i} style={{ padding: '5px', fontSize: '0.8em' }}>
                        {validationErrors[docType]}
                    </Alert>
                ))}
            </div>
        );
    };

    const onHandleSaveContinue = () => {
        const {history} = props;
        dispatch(saveKYC(id, application, history, true));
    };

    return (
        <div className="page-container">
            <div className="page-main-section">
                <div className="row" style={{margin:0, width:'100%'}}>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <h4><b>Required Documents</b></h4>
                        <hr />
                        <div className="row" style={{margin:0, padding:'10px 0px'}}>
                            <label htmlFor=""> Ownership Structure <span class="text-danger">*</span></label>
                            <button
                                onClick={() => handleViewDocument(shareholderDoc)}
                                style={{width:'auto', float:'right'}}
                                disabled={shareholderDoc.verified}
                                className={shareholderDoc.verified ? 'btn btn-success' : 'btn btn-primary'}>
                                {shareholderDoc.verified ? 'Verified' : 'View / Update'}
                            </button>
                        </div>
                        <div className="row" style={{margin:0}}>
                            {
                                validationErrors &&
                                Object.entries(validationErrors).map(([docType, error], i) => 
                                    docType === `0` && (
                                        <Alert bsStyle="danger" key={i} style={{ padding: '5px', fontSize: '0.8em', margin:0 }}>
                                                Error: {error}
                                        </Alert>
                                    )
                                )
                            }
                        </div>
                        <hr />
                        <div className="row" style={{margin:0, padding:'10px 0px'}}>
                            <label htmlFor=""> CIPC Document <span class="text-danger">*</span></label>
                            <button
                                onClick={() => handleViewDocument(proofOfLegalDoc)}
                                style={{width:'auto', float:'right'}}
                                disabled={proofOfLegalDoc.verified}
                                className={proofOfLegalDoc.verified ? 'btn btn-success' : 'btn btn-primary'}>
                                {proofOfLegalDoc.verified ? 'Verified' : 'View / Update'}
                            </button>
                        </div>
                        <div className="row" style={{margin:0}}>
                            {
                                validationErrors &&
                                Object.entries(validationErrors).map(([docType, error], i) => 
                                    docType === `1` && (
                                        <Alert bsStyle="danger" key={i} style={{ padding: '5px', fontSize: '0.8em', margin:0 }}>
                                                Error: {error}
                                        </Alert>
                                    )
                                )
                            }
                        </div>
                        <hr />
                        <div className="row" style={{margin:0, padding:'10px 0px'}}>
                            <label htmlFor=""> Resolution <span class="text-danger">*</span></label>
                            <button
                                onClick={() => handleViewDocument(proofOfAuthDoc)}
                                style={{width:'auto', float:'right'}}
                                disabled={proofOfAuthDoc.verified}
                                className={proofOfAuthDoc.verified ? 'btn btn-success' : 'btn btn-primary'}>
                                {proofOfAuthDoc.verified ? 'Verified' : 'View / Update'}
                            </button>
                        </div>
                        <div className="row" style={{margin:0}}>
                            {
                                validationErrors &&
                                Object.entries(validationErrors).map(([docType, error], i) => 
                                    docType === `2` && (
                                        <Alert bsStyle="danger" key={i} style={{ padding: '5px', fontSize: '0.8em', margin:0 }}>
                                                Error: {error}
                                        </Alert>
                                    )
                                )
                            }
                        </div>
                        <hr />
                        <div className="row" style={{margin:0, padding:'10px 0px'}}>
                            <label htmlFor=""> Proof of Financial Status <span class="text-danger">*</span></label>
                            <button
                                onClick={() => handleViewDocument(finStatementsDoc)}
                                style={{width:'auto', float:'right'}}
                                disabled={finStatementsDoc && finStatementsDoc.verified}
                                className={finStatementsDoc && finStatementsDoc.verified ? 'btn btn-success' : 'btn btn-primary'}>
                                {finStatementsDoc && finStatementsDoc.verified ? 'Verified' : 'View / Update'}
                            </button>
                        </div>
                        <div className="row" style={{margin:0}}>
                            {
                                validationErrors &&
                                Object.entries(validationErrors).map(([docType, error], i) => 
                                    docType === `3` && (
                                        <Alert bsStyle="danger" key={i} style={{ padding: '5px', fontSize: '0.8em', margin:0 }}>
                                                Error: {error}
                                        </Alert>
                                    )
                                )
                            }
                        </div>
                        <hr />

                        {/*<div  className="row" style={{margin:0, padding:'10px 0px'}}>
                             {
                                validationErrors &&
                                validationErrors.map((error, i) => (
                                  
                                    <Alert bsStyle="danger" key={i} style={{ padding: '5px', fontSize: '0.8em' }}>
                                        {i}  {error}
                                    </Alert>
                                ))
                            } 
                        </div>*/}

                        {finStatementsDoc.externalId &&
                            <div className="row" style={{margin:0}}>
                                <label htmlFor=""></label>
                                <h5>
                                    Please indicate the type of document uploaded as Proof of Financial Status.
                                </h5>
                                <div
                                    className="inline-container"
                                    onClick={() => handleDocDesc('financials')}>
                                    <i className={financials === true ? 'fa fa-check-circle-o icon' : 'fa fa-circle-o icon'} /> &nbsp;
                                    Financial Statements.
                                </div>
                                <div
                                    className="inline-container"
                                    onClick={() => handleDocDesc('auditorLetter')}>
                                    <i className={auditorLetter === true ? 'fa fa-check-circle-o icon' : 'fa fa-circle-o icon'} /> &nbsp;
                                    Letter from the Auditor confirming the Net Asset value.
                                </div>
                                <div
                                    className="inline-container"
                                    onClick={() => handleDocDesc('accountStatements')} >
                                    <i className={accountStatements === true ? 'fa fa-check-circle-o icon' : 'fa fa-circle-o icon'} /> &nbsp;
                                    Management Account Statements.
                                </div>
                                <div
                                    className="inline-container"
                                    onClick={() => handleDocDesc('cfoLetter')} >
                                    <i className={cfoLetter === true ? 'fa fa-check-circle-o icon' : 'fa fa-circle-o icon'} /> &nbsp;
                                    A letter from the CFO confirming the value of Net Assets.
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <h4><b>Additional Supporting Documents</b></h4>
                        <hr />
                        {application.documents.map(doc =>
                            !exlusions.includes(doc.type) &&
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" key={doc.barcodeId}>
                                {/* <div className="panel panel-default">
                                    <div className="panel-body">
                                        <div className="col-md-8">
                                            {doc.type}
                                        </div>
                                        <div className="col-md-4">
                                            <button
                                                onClick={() => handleViewDocument(doc)}
                                                disabled={doc.verified}
                                                className={doc.verified ? 'btn btn-success' : 'btn btn-primary'} >
                                                {doc.verified ? 'Verified' : 'View / Update'}
                                            </button>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row" style={{margin:0, padding:'10px 0px'}}>
                                    <label htmlFor=""> {doc.type}</label>
                                    <button
                                        onClick={() => handleViewDocument(doc)}
                                        style={{width:'auto', float:'right'}}
                                        disabled={doc.verified}
                                        className={doc.verified ? 'btn btn-success' : 'btn btn-primary'} >
                                        {doc.verified ? 'Verified' : 'View / Update'}
                                    </button>
                                </div>
                            </div>
                        )}
                        <div className="row" style={{margin:0, padding:'10px 0px'}}>
                            <button className={'btn btn-primary'}  onClick={() => setAddDoc(true)}>
                                Add New Document
                            </button>
                        </div>
                    </div>
                </div>
                {
                    selectedDocument && (
                        <Popup onClose={() => setSelectedDocument(undefined)}>
                            <DocumentViewer
                                fileId={selectedDocument.id} documentType={selectedDocument.type}
                                onUpload={onHandleUpload}
                                onRemove={onHandleRemoveDocument}
                                onVerify={onHandleVerify}
                            />
                        </Popup>
                    )
                }
                <Modal show={addDoc} onHide={() => setAddDoc(false)}>
                    <Modal.Header closeButton><h4>Add Additional Document</h4></Modal.Header>
                    <Modal.Body>
                        {/*<h5>Provide a contact email address for us to contact should we need any more information:</h5>*/}
                        {submittingError && <Alert bsStyle={'danger'}>{submittingError}</Alert>}
                        <label>Document Neme (<code>Document name must have min 4 characters.</code>)</label>
                        <input type={'text'} name={'documentType'} className={'form-control'}
                            id={'document-type'} value={newDoc ? newDoc.type : ''} placeholder={'Document Type'}
                            onChange={handleNewDocNameChange} />
                        {newDoc && newDoc.type.length > 3 &&
                            <DocumentViewer
                                fileId={newDoc.id} 
                                documentType={newDoc.type}
                                onUpload={onHandleNewDocUpload}
                                onRemove={onHandleNewDocRemoveDocument}
                                onVerify={onHandleNewDocVerify}
                            />
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button style={{width:'auto', margin:'0 10px', borderRadius:'5px'}}  bsStyle={'danger'} onClick={() => setAddDoc(false)}>Cancel</Button>
                            <Button bsStyle={'primary'} style={{width:'auto', margin:'0 10px', borderRadius:'5px'}}>Add Document</Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>

                <Modal show={submitting} onHide={() => setSubmitting(false)}>
                    <Modal.Header closeButton><h4>Contact Email Address</h4></Modal.Header>
                    <Modal.Body>
                        <h5>Provide a contact email address for us to contact should we need any more information:</h5>
                        {submittingError && <Alert bsStyle={'danger'}>{submittingError}</Alert>}
                        <input type={'email'} name={'contactEmail'} className={'form-control'}
                            id={'contact-email'} defaultValue={application.contactEmail}
                            onChange={handleEntityInfoChange} />
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonGroup>
                            <Button style={{width:'auto', margin:'0 10px', borderRadius:'5px'}}  bsStyle={'danger'}  onClick={() => setSubmitting(false)}>Cancel</Button>
                            <Button bsStyle={'primary'} style={{width:'auto', margin:'0 10px', borderRadius:'5px'}} onClick={submitReview}>Complete Review</Button>
                        </ButtonGroup>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className="page-footer-section" style={{width: "80%"}}>
                <ButtonsComponent
                    history={props.history}
                    hidePrevious={false}
                    // onSaveAndContinue={onHandleSaveContinue}
                    hideSaveAndContinue={true}
                    prevPage={'/kyc/entity-people'}
                    onNext={completeSection}
                />
            </div>
        </div>
    );
};
