import React, {useEffect, useImperativeHandle, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import RelatedPartiesNew from './RelatedPartiesNew';
import AuthorisedPersonsComponent from './AuthorisedPersonsComponent';
import AdditionalQuestions from './AdditionalQuestions';
import Col from 'react-bootstrap/lib/Col';
import {
    startKycMaintenance,
    onChangeValue,
    onDirChangeValue,
    setEntity,
    onNewDoc,
    saveKYC,
    completeKYC,
    onNewRelatedParty,
    onRemoveRelatedParty,
    onNewAuthorisedPerson,
    onRemoveAuthorisedPerson,
    kycSaved,
    onAddressChangeValue,
    addOwner,
    pip,
    updatePips,
    updateController
} from '../../actions/kycMaintenance';
import ButtonsComponent from '../ButtonsComponent';
import _ from 'lodash';
import Alert from 'react-bootstrap/lib/Alert';

export const PeoplePage = (props) => {
    const id = useSelector(state => state.kyc && state.kyc.id);
    const application = useSelector(state => state.kyc && state.kyc.kycRequest);
    const dispatch = useDispatch();
    const [validationErrors, setValidationErrors] = useState(undefined);

    const [pipErrors, setPipErrors] = useState([]);
    const [pipsErrors, setPipsErrors] = useState([]);
    const [controllersErrors, setControllersErrors] = useState([]);

    const [directorErrors, setDirectorErrors] = useState([]);
    const [relatedPartyErrors, setrelatedPartyErrors] = useState([]);

    const onHandleSave = () => {
        // const {actions} = this.props;
        dispatch(saveKYC(id, application));
    };

    const handleEntityInfoChange = ({target}) => {
        const {actions} = props;
        const {name, value} = target;
        dispatch(onChangeValue(name, value));
    };

    const handleAddressChange = ({target}, changingAddress) => {
        const {actions} = props;
        const {name, value} = target;
        changingAddress[name] = value;
        dispatch(onAddressChangeValue('', 'addresses', changingAddress));
    };

    const handleDirectorAddressChoice = (selectedDirector, value) => {
        const {directors} = application;
        const {actions} = props;
        const director = directors[selectedDirector];
        const {addresses} = director;

        // Clear the error for the current director when a change is made
        setDirectorErrors((prevErrors) => {
            const updatedErrors = [...prevErrors];
            updatedErrors[selectedDirector] = null;
            return updatedErrors;
        });


        director.address = addresses[value];
        dispatch(onDirChangeValue('', 'directors', director));
    };

    const handleDirectorChange = (selectedDirector, item, value) => {
        const {directors} = application;
        const {actions} = props;
        const director = directors[selectedDirector];
        director[item] = value;
        dispatch(onDirChangeValue('', 'directors', director));
    };

    const handlePipChange = (pipChange) => {
        const {actions} = props;
        dispatch(pip(pipChange));
    };
    const handlePipsUpdate = (pipChange) => {
        const {actions} = props;
        dispatch(updatePips(pipChange));
    };
    const handleControlChange = (controllerChange) => {
        const {actions} = props;
        dispatch(updateController(controllerChange));
    };

    const handleDirectorAddressChange = (selectedDirector, person) => {
        const {directors} = application;
        const {actions} = props;
        // const director = directors[selectedDirector];
        // director.address = address;

        dispatch(onDirChangeValue('', selectedDirector, person));
    };

    const handleRelatedPartyAddressChange = (selectedRelatedParty, changedAddress) => {
        const {children} = application;
        const {actions} = props;
        const relatedParty = children[selectedRelatedParty];
        relatedParty.address = changedAddress;
        dispatch(onDirChangeValue('', 'children', relatedParty));
    };

    const handleRelatedPartyChange = ({target}, selectedRelatedParty) => {
        const {children} = application;
        const {actions} = props;
        const relatedParty = children[selectedRelatedParty];
        const {name, value} = target;
        relatedParty[name] = value;
        dispatch(onDirChangeValue('', 'children', relatedParty));
    };

    const allowOnlyCharacters = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^A-Za-z\s]/g, '');
        event.target.value = value;
    }

    const allowOnlyNumbers = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^0-9]/g, '');
        event.target.value = value;
    }

    const handleAddRelatedParty = (relatedParty) => {
        const {actions} = props;
        dispatch(onNewRelatedParty(relatedParty));
    };
    const handleAddOwner = (updatedChild) => {
        const {actions} = props;
        dispatch(addOwner(updatedChild));
    };
    const handleAddAuthorisedPerson = async (authorisedPerson) => {
        const {actions} = props;
        await dispatch(onNewAuthorisedPerson(authorisedPerson));
        onHandleSave();
    };

    const handleRemoveRelatedParty = (relatedParty) => {
        const {actions} = props;
        dispatch(onRemoveRelatedParty(relatedParty));
    };
    const handleRemoveAP = (authorisedPerson) => {
        const {actions} = props;
        setDirectorErrors((prevErrors) => {
            const updatedErrors = [...prevErrors];
            updatedErrors[authorisedPerson] = null;
            return updatedErrors;
        });
        dispatch(onRemoveAuthorisedPerson(authorisedPerson));
    };

    const onNext = () => {
        props.history.push('/kyc/entity-documents');
    };

    // const checkAuthorisedAddress = ({address}, email) => {
    //     if (!address || _.isEmpty(address))
    //         return true;
    //     const {line1, line2, line3, postalCode} = address;
    //     return !(line1 && line2 && line3 && postalCode);
    // };

    const checkAuthorisedAddress = ({ address }) => {
        if (!address) {
            return "Address is missing.";
        }
    
        const { line1, line2, line3, postalCode } = address;
        const missingFields = [];
    
        if (!line1) {
            missingFields.push("Line 1");
        }
        if (!line2) {
            missingFields.push("Line 2");
        }
        if (!line3) {
            missingFields.push("Line 3");
        }
        if (!postalCode) {
            missingFields.push("Postal Code");
        }
    
        if (missingFields.length > 0) {
            return `Missing fields: ${missingFields.join(', ')}`;
        }
    
        return null; // No errors
    };
    
    // const checkRelatedPartyAddress = ({address}) => {
    //     if (!address || _.isEmpty(address))
    //         return true;
    //     const {crcCountryId, zipCode, crcRegionId, crcCityId, suburb, streetAddressLine1} = address;
    //     return !(crcCountryId && zipCode && crcRegionId && crcCityId && suburb && streetAddressLine1);
    // };

    const checkRelatedPartyAddress = ({ address }) => {
        if (!address) {
            return "Address is missing.";
        }
    
        const { crcCountryId, zipCode, crcRegionId, crcCityId, suburb, streetAddressLine1 } = address;
        const missingFields = [];
    
        if (!crcCountryId) {
            missingFields.push("Country");
        }
        if (!zipCode) {
            missingFields.push("Zip Code");
        }
        if (!crcRegionId) {
            missingFields.push("Region");
        }
        if (!crcCityId) {
            missingFields.push("City");
        }
        if (!suburb) {
            missingFields.push("Suburb");
        }
        if (!streetAddressLine1) {
            missingFields.push("Street Address Line 1");
        }
    
        if (missingFields.length > 0) {
            return `Missing fields: ${missingFields.join(', ')}`;
        }
    
        return null; // No errors
    };
    

    const isValidEmail  = ({email}) => {
        // You can use a regular expression or a library like 'validator' to validate email addresses.
        // Here's a simple regex example:
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        return emailRegex.test(email);
    }

    const completeSection = () => {
        let validationErrors = [];
        setPipErrors([]);
        setPipsErrors([]);
        setControllersErrors([]);
        setrelatedPartyErrors([]);

        const {directors, children} = application;

         // Ensure that application and directors are defined
        if (!application || !application.directors) {
            console.error('Application or directors are undefined.');
            return;
        }

        children.forEach(relatedParty => {
            const empty = checkRelatedPartyAddress(relatedParty);
            empty && validationErrors.push(
                <span>Please complete an address for <strong>"{relatedParty.name}"</strong></span>);
        });

        // Check for errors in pip and pips and add messages to the respective arrays
        if (
            typeof application.pip !== 'boolean' ||
            (application.pip && _.isEmpty(application.pips))
        ) {
            // Check for errors in pip
            if (typeof application.pip !== 'boolean') {
                const pipError = (
                    <span>
                        Please verify if any of the authorised/controlling persons
                        are either a public official in a position of authority or
                        related/associated to a public official in a position of authority?
                    </span>
                );

                // Set the error for pip
                setPipErrors([pipError]);

                // Add the error to the general validationErrors array
                validationErrors.push(pipError);
            }

            // Check for errors in pips
            if (application.pip && _.isEmpty(application.pips)) {
                const pipsError = (
                    <span>
                        Please add the authorised/controlling persons that are
                        either a public official in a position of authority or
                        related/associated to a public official in a position of authority?
                    </span>
                );

                // Set the error for pips
                setPipsErrors([pipsError]);

                // Add the error to the general validationErrors array
                validationErrors.push(pipsError);
            }
        }

        // Check if there are errors in directors and add corresponding messages
        if (_.isEmpty(application.controllers)) {
            const controllersError = (
                <span>Please add entity <strong>Controllers</strong>.</span>
            );

            // Set the error for controllers
            setControllersErrors([controllersError]);

            // Add the error to the general validationErrors array
            validationErrors.push(controllersError);
        }

        // Check if there are errors in directors and add corresponding messages
        if (_.isEmpty(application.directors)) {
            const directorsError = (
                <span>Please add at least one director.</span>
            );

            // Set the error for directors
            setDirectorErrors([directorsError]);

            // Add the error to the general validationErrors array
            validationErrors.push(directorsError);
        }

         // Check if there are errors in directors and add corresponding messages
         if (_.isEmpty(application.children)) {
            const childrenError = (
                <span>Please add at least one Related party.</span>
            );

            // Set the error for directors
            setrelatedPartyErrors([childrenError]);

            // Add the error to the general validationErrors array
            validationErrors.push(childrenError);
        }


          // Check if there are errors in directors and add corresponding messages
        application.children.forEach((child, index) => {
            const emptyAddressError = checkRelatedPartyAddress(child);
            if (child.businessType == "Individual") {
                child.contactNumber = child.phoneNumber;
            }
            const invalidContactError = !child.contactNumber;

            // const invalidEmailError = !director.email || !isValidEmail(director.email);
            // Combine the errors if either address or email is invalid
            const childError = emptyAddressError || invalidContactError
                ? (
                    <span>
                        Please {emptyAddressError ? 'choose an address' : 'provide a valid contact'} for{' '}
                        <strong>"{child.name || child.registeredName}"</strong>
                    </span>
                )
                : null;

            // Update the error for the current director
            setrelatedPartyErrors((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = childError;
                return updatedErrors;
            });

            // Add the error to the general validationErrors array
            if (childError) {
                validationErrors.push(childError);
            }
        });

        // Check if there are errors in directors and add corresponding messages
        application.directors.forEach((director, index) => {
            const emptyAddressError = checkAuthorisedAddress(director);
            const invalidEmailError = !director.email;
            // const invalidEmailError = !director.email || !isValidEmail(director.email);

            // Combine the errors if either address or email is invalid
            const directorError = emptyAddressError || invalidEmailError
                ? (
                    <span>
                        Please {emptyAddressError ? 'choose an address' : 'provide a valid email'} for{' '}
                        <strong>"{director.firstName} {director.lastName}"</strong>
                    </span>
                )
                : null;

            // Update the error for the current director
            setDirectorErrors((prevErrors) => {
                const updatedErrors = [...prevErrors];
                updatedErrors[index] = directorError;
                return updatedErrors;
            });

            // Add the error to the general validationErrors array
            if (directorError) {
                validationErrors.push(directorError);
            }
        });

        // Check if there are errors and add messages to the respective arrays
        if (validationErrors.length > 0) {
            setValidationErrors(validationErrors);
        } else {
            onNext();
        }
        
    };

    const onHandleSaveContinue = () => {
        const {history} = props;
        dispatch(saveKYC(id, application, history, true));
    };

    return (
        <div className="page-container">
            <div className="page-main-section">
                {/* <div className="row" style={{margin:0}}>
                    {
                        validationErrors &&
                        validationErrors.map((error, i) => (
                            <Alert bsStyle="danger" key={i} style={{padding:'5px', fontSize:'0.8em'}}>
                                {error}
                            </Alert>
                        ))
                    }
                </div> */}
                <div className="row" style={{margin:0}}>
                    <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div className="row" style={{margin:0}}>
                            <h4><b>Related Parties</b></h4>
                            {application.children && application.children.map((child, index) => (
                                <div key={index}  style={{margin: '5px 15px'}}>
                                    {/* Other director-related JSX */}
                                    {relatedPartyErrors[index] && (
                                        <span className="form-error">
                                            {relatedPartyErrors[index]}
                                        </span>
                                    )}
                                </div>
                            ))}
                            <RelatedPartiesNew kycStructure={application}
                                handleAddRelatedParty={handleAddRelatedParty}
                                handleRelatedPartyAddressChange={handleRelatedPartyAddressChange}
                                handleRelatedPartyChange={handleRelatedPartyChange}
                                allowOnlyCharacters={allowOnlyCharacters}
                                allowOnlyNumbers={allowOnlyNumbers}
                                handleRemoveRelatedParty={handleRemoveRelatedParty}
                                handleAddOwner={handleAddOwner}
                                handleSave={onHandleSave}
                            />
                        </div>
                        <hr/>
                        <div className="row" style={{margin:0}}>
                            <h4><b>Authorised Persons</b></h4>
                            {/* <div className="row" style={{ margin: 0 }}>
                                {validationErrors &&
                                    validationErrors.map((error, i) => (
                                        <Alert bsStyle="danger" key={i} style={{ padding: '5px', fontSize: '0.8em' }}>
                                            {error}
                                        </Alert>
                                    ))}
                            </div> */}


                            {application.directors && application.directors.map((director, index) => (
                                <div key={index}  style={{margin: '5px 15px'}}>
                                    {/* Other director-related JSX */}
                                    {directorErrors[index] && (
                                        <span className="form-error">
                                            {directorErrors[index]}
                                        </span>
                                    )}
                                </div>
                            ))}
                            <AuthorisedPersonsComponent
                                personsList={application.directors}
                                handleAddAuthorisedPerson={handleAddAuthorisedPerson}
                                onChange={handleDirectorChange}
                                handleRemoveAP={handleRemoveAP}
                                onAddressChange={handleDirectorAddressChange}
                                handleSave={onHandleSave}
                            />

                            {/* <AuthorisedPersonsComponent personsList={application.directors}
                                handleAddAuthorisedPerson={handleAddAuthorisedPerson}
                                onChange={handleDirectorChange}
                                handleRemoveAP={handleRemoveAP}
                                onAddressChange={handleDirectorAddressChange}
                                handleSave={onHandleSave}
                            /> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div className="row" style={{margin:0}}>
                            <hr/>
                            <div className="row" style={{margin:0}}>
                            {controllersErrors.map((error, i) => (
                                <div key={i} style={{margin: '0px 15px'}}>
                                    <span className="form-error">{error}</span>
                                </div>
                            ))}
                            </div>    
                            <AdditionalQuestions
                                onPipChange={handlePipChange}
                                onPipsUpdate={handlePipsUpdate}
                                onControlChange={handleControlChange}
                                onControllerUpdate={handleControlChange}
                            />   
                            
                        </div>
                        <div className="row" style={{margin:'20px 0'}}>

                            {pipErrors.map((error, i) => (
                                <div key={i} style={{margin: '0px 15px'}}>
                                    <span className="form-error">{error}</span>
                                </div>
                            ))}

                            {pipsErrors.map((error, i) => (
                                <div key={i} style={{margin: '0px 15px'}}>
                                    <span className="form-error">{error}</span>
                                </div>
                            ))}

                        </div>    

                    </div>
                </div>

               
            </div>
            <div className="page-footer-section" style={{width: "80%"}}>
                <ButtonsComponent
                    history={props.history}
                    hidePrevious={false}
                    prevPage={'/kyc/entity-details'}
                    hideSaveAndContinue={true}
                    // onSaveAndContinue={onHandleSaveContinue}
                    onNext={completeSection}
                />
            </div>
        </div>
    );
};
