import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function signatoriesReducer(state = initialState.accounts, action) {
    switch (action.type) {
        case types.FETCH_SIGNATORY_FOR_ACC_SUCCESS:
            return {...state, copies: {...action.copies}};
        case types.UPDATE_COPIES:
            return {...state, copies: {...action.copies}};
        case types.CLEAR_COPIES:
            return {...state, copies: null};
        default:
            return state;
    }
}
