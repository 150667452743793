import React from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import validate from 'validate.js';
import ReactLoading from 'react-loading';

import * as actions from '../../actions/reports';
import {fetchHierachy} from '../../actions/entity';
import {fetchAccounts, onSelectAccount, onSelectAllAccounts} from '../../actions/kyc';
import FormField from '../formField/FormField';
import ReportStatementAccount from '../accounts/reportStatementAccounts';
import EntityList from '../legalentity/EntityList';
import NoLinkedEntitiesMessage from '../legalentity/NoLinkedEntitiesMessage';
import '../../styles/rules.css';
import Popup from '../Popup';

const REPORT_TYPES = [
    {
        label: 'Account Signatories',
        value: 'ACCOUNT_SIGNATORIES'
    },
    {
        label: 'Confirmation Letter',
        value: 'CONFIRMATION_LETTER'
    }
];

const DELIVERY_METHOD = {
    email: 'Email',
    download: 'Download'
};

const EmailReportMessage = ({success}) => {
    if (success === true) {
        return (
            <div>
                <div>
                    <div className="product-heading">
                        Successful
                    </div>
                    <div className="title-gradient"/>
                </div>
                <br/>
                <div className="popup-content">
                    <div className="flexColumn">
                        <div>
                            <p>
                                Email report request sent.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div>
            <div>
                <div className="product-heading">
                    Unsuccessful
                </div>
                <div className="title-gradient"/>
            </div>
            <br/>
            <div className="popup-content">
                <div className="flexColumn">
                    <div>
                        <p>There was an error emailing the report.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

class statements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            filteredAccounts: null,
            form: {
                accounts: null,
                emailAddress: null,
                reportName: REPORT_TYPES[0].value,
                deliveryMethod: DELIVERY_METHOD.download,
                isFetchingAccounts: false,
                isLoading: false,
                isProcessingReport: false,
            },
            selectedAccount: null,
            selectedEntity: null,
            searchTerm: '',
            isMultiAccountSelect: true,
            showEmailReportCompleteMessage: null,
        };
        this.handleOnChange = this._handleOnChange.bind(this);
        this.handleOnFilterAccounts = this._handleOnFilterAccounts.bind(this);
        this.handleOnSelectAccountRadio = this._handleOnSelectAccountRadio.bind(this);
        this.handleOnSelectAccount = this._handleOnSelectAccount.bind(this);
        this.handleOnSelectAllAccounts = this._handleOnSelectAllAccounts.bind(this);
        this.handleOnSelectEntity = this._handleOnSelectEntity.bind(this);
        this.handleSubmitReportRequest = this._handleSubmitReportRequest.bind(this);
    }

    componentWillMount() {
        this._fetchData();
    }

    _fetchData() {
        const {actions, impersonation} = this.props;
        actions.fetchHierachy(impersonation);
    }

    _handleOnChange({target}, attribute) {
        const {accounts, actions} = this.props;
        const {form} = this.state;

        let value = target.value;
        if (attribute === 'emailAddress') {
            value = _.size(value) === 0 ? null : _.split(value, ',');
        }

        let update = {form: _.extend({}, form, {[attribute]: value})};
        if (attribute === 'reportName') {
            update = _.extend(update, {
                isMultiAccountSelect: value !== 'CONFIRMATION_LETTER',
                selectedAccount: null,
                filteredAccounts: null
            });
            actions.onSelectAllAccounts(accounts, false, 'selectedEntityAccounts');
        }
        this.setState(update);
    };

    _handleOnFilterAccounts(searchTerm) {
        const {accounts} = this.props;
        let filteredAccounts = null;
        if (searchTerm) {
            filteredAccounts = _.filter(accounts, acc => {
                return acc && (acc.accountNumber.includes(searchTerm) || acc.name && acc.name.toLowerCase().includes(searchTerm.toLowerCase()));
            });
        }
        this.setState({filteredAccounts, searchTerm});
    }

    _handleOnSelectEntity(selectedEntity) {
        const {actions} = this.props;
        this.setState({isFetchingAccounts: true, selectedEntity});

        const onComplete = () => {
            this.setState({isFetchingAccounts: false});
        };
        actions.fetchAccounts(`cifNumber=${selectedEntity.cif}`, 'selectedEntityAccounts', onComplete, false);
    }


    _handleOnSelectAccount({target}, account) {
        const {accounts, actions} = this.props;
        const {errors, filteredAccounts, searchTerm} = this.state;
        const isSelected = !account.isSelected;
        errors && errors.accounts && this._resetError('accounts');
        const onComplete = () => {
            if (_.size(filteredAccounts) > 0) {
                this._handleOnFilterAccounts(searchTerm);
            }
        };
        actions.onSelectAccount(accounts, account, isSelected, 'selectedEntityAccounts', true, null, onComplete);
    }

    _handleOnSelectAllAccounts(isSelected) {
        const {accounts, actions} = this.props;
        const {errors, filteredAccounts, searchTerm} = this.state;
        errors && errors.accounts && this._resetError('accounts');
        const onComplete = () => {
            if (_.size(filteredAccounts) > 0) {
                this._handleOnFilterAccounts(searchTerm);
            }
        };
        actions.onSelectAllAccounts(accounts, isSelected, 'selectedEntityAccounts', filteredAccounts, onComplete);
    }

    _handleOnSelectAccountRadio(account) {
        const {accounts, actions} = this.props;
        const {errors, filteredAccounts, searchTerm} = this.state;
        const selectedAccount = _.extend({}, account, {isSelected: true});
        errors && errors.accounts && this._resetError('accounts');
        const onComplete = () => {
            if (_.size(filteredAccounts) > 0) {
                this._handleOnFilterAccounts(searchTerm);
            }
        };
        actions.onSelectAccount(accounts, selectedAccount, true, 'selectedEntityAccounts', false, this.state.selectedAccount, onComplete);
        this.setState({selectedAccount});
    }

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})});
    }

    _validateForm(hasAccounts) {
        const {form} = this.state;
        const isEmail = form && form.deliveryMethod === DELIVERY_METHOD.email;

        const options = {fullMessages: false};
        const constraints = _.extend(
            {deliveryMethod: {presence: true}, reportName: {presence: true}},
            !hasAccounts ? {accounts: {presence: true}} : null
        );

        let errors = validate(form, constraints, options);

        if (isEmail) {
            const emailConstraint = {emailAddress: {presence: true, email: true}};

            const emails = _.split(form && form.emailAddress, ',');

            _.map(emails, emailAddress => (
                    errors = _.extend({}, errors, validate({emailAddress}, emailConstraint, options))
                )
            );
        }
        this.setState({errors});
        return _.isEmpty(errors);
    }

    _filterSelectedAccounts(accountsList) {
        let selectedAccounts = [];
        _.filter(accountsList, acc => {
            if (acc.isSelected) {
                const account = _.pick(acc, ['originalAccountNumber','accountNumber', 'name', 'branch', 'accountOpenDate','accountType']);
                selectedAccounts = _.union(selectedAccounts, [account]);
            }
        });
        return {selectedAccounts};
    }

    _handleSubmitReportRequest(e) {
        e.preventDefault();
        const {accounts, actions} = this.props;
        const {form, isMultiAccountSelect, selectedAccount, selectedEntity} = this.state;
        const legalEntityId = selectedEntity && selectedEntity.goldTierId;

        const accs = isMultiAccountSelect ? accounts : (selectedAccount ? [selectedAccount] : []);

        const {selectedAccounts} = this._filterSelectedAccounts(accs);

        const hasAccounts = _.size(selectedAccounts) > 0;

        if (this._validateForm(hasAccounts)) {
            this.setState({isProcessingReport: true});
            const deliveryMethod = form && form.deliveryMethod;
            const reportName = form && form.reportName;
            const requestBody = {accounts: selectedAccounts, legalEntityId};

            if (deliveryMethod === DELIVERY_METHOD.email) {
                const emailAddress = form && form.emailAddress;
                const onEmailComplete = (success = false)=>  {
                    this.setState({isProcessingReport: false, showEmailReportCompleteMessage: {success}});
                };
                actions.emailReport(reportName, _.extend({}, requestBody, {emailAddress}),onEmailComplete);
            } else {
                const onDownloadComplete = ()=> this.setState({isProcessingReport: false});
                actions.downloadReport(reportName, requestBody, onDownloadComplete);
            }
        }
        return false;
    };

    _renderEntitiesSection() {
        const {entities} = this.props;
        const {selectedEntity} = this.state;

        return (
            <div style={{width: '100%'}}>
                <div>
                    <div className="product-heading">
                        Legal Entities
                    </div>
                    <div className="title-gradient"/>
                </div>

                <EntityList
                    className="card-container"
                    entities={entities}
                    onSelect={this.handleOnSelectEntity}
                    selectedEntity={selectedEntity}
                />
            </div>
        );
    }

    _renderReportSection() {
        const {accounts, products} = this.props;
        const {errors, filteredAccounts, form, isFetchingAccounts, isProcessingReport, selectedAccount, isMultiAccountSelect} = this.state;
        if (isFetchingAccounts) {
            return (
                <div className="rule-spinner">
                    <ReactLoading type="spokes" color="#444"/>
                </div>
            );
        }
        const hasAccounts = _.size(accounts) > 0;
        return (
            <div>
                {/* <div>
                    <div className="product-heading">
                        Reports
                    </div>
                    <div className="title-gradient"/>
                </div> */}
                <div>
                    <div className="card-container" style={{minHeight: 0}}>


                        
                        <div className="row" style={{ margin: 0 }}>
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0 10px 0 0' }}>
                                <div className="form-group">
                                    <label>Products</label>
                                    <select className="form-control"
                                    // onChange={(event) => { this.handleOnChange(event, 'reportName'); }}
                                    >
                                        {
                                            products && products
                                                .filter(product =>
                                                    product.name === "Business Current Account" ||
                                                    product.name === "TPFA Accounts" ||
                                                    product.name === "Shari’ah Business Current Account")
                                                .map((product, i) => {
                                                    return (
                                                        <option key={i} value={product.id}>{product.name}</option>
                                                    );
                                                })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0 0 0 10px' }}>
                                <div className="form-group">
                                    <label>Category</label>
                                    <select className="form-control">
                                        <option value="PS">Previous Statements</option>
                                        <option value="SS">Stamped Statements</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <div className="from-group">
                            <div className="row" style={{ margin: 0 }}>
                                <label>Statement Date From</label>
                            </div>
                            <div className="row" style={{ margin: 0 }}>
                                <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0 10px 0 0' }}>
                                    <input type="date" className="form-control" name="" id="" />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: '0 0 0 10px' }}>
                                    <input type="date" className="form-control" name="" id="" />
                                </div>

                            </div>
                        </div>

                        {/* <div className="form-group">
                            <label>Report</label>
                            <select
                                className="form-control"
                                onChange={(event) => {
                                    this.handleOnChange(event, 'reportName');
                                }}
                            >
                                {_.map(REPORT_TYPES, (r, i) => (
                                    <option key={`reo-${i}`} value={r.value}>{r.label}</option>))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label>How would you like to get the report?</label>
                            <select
                                className="form-control"
                                onChange={(event) => {
                                    this.handleOnChange(event, 'deliveryMethod');
                                }}
                            >
                                <option>{DELIVERY_METHOD.download}</option>
                                <option>{DELIVERY_METHOD.email}</option>
                            </select>
                        </div> */}

                        {
                            form && form.deliveryMethod === DELIVERY_METHOD.email &&
                            <FormField className="form-group" id="turnover" error={errors && errors.emailAddress}>
                                <label>Recipients</label>
                                <input
                                    className="form-control"
                                    placeholder={'Comma separated'}
                                    onChange={(event) => {
                                        errors && errors.emailAddress && this._resetError('emailAddress');
                                        this.handleOnChange(event, 'emailAddress');
                                    }}
                                    multiple='multiple'
                                    name="emailaddress"
                                    type="email"
                                />
                            </FormField>
                        }
                        <div className="section-title">
                            Accounts
                        </div>
                        <FormField error={errors && errors.accounts ? ['An account should be selected.'] : ''}>
                            {
                                (!hasAccounts && !isFetchingAccounts) ?
                                    <p>Selected Entity Has No Accounts.</p>
                                    :
                                    <ReportStatementAccount
                                        accounts={accounts}
                                        filteredAccounts={filteredAccounts}
                                        onFilterAccounts={this.handleOnFilterAccounts}
                                        onSelect={this.handleOnSelectAccount}
                                        onSelectAll={this.handleOnSelectAllAccounts}
                                        onSelectRadio={this.handleOnSelectAccountRadio}
                                        selectedAcc={selectedAccount}
                                        isMultiSelect={isMultiAccountSelect}
                                    />
                            }
                        </FormField>
                    </div>
                    {
                        isProcessingReport && <div className="rule-spinner">
                            <ReactLoading type="spokes" color="#444"/>
                        </div>
                    }
                </div>
            </div>
        );
    }
    _renderPopUps(){
        const {showEmailReportCompleteMessage} = this.state;
        if (showEmailReportCompleteMessage) {
            return (
                <Popup onClose={() => this.setState({showEmailReportCompleteMessage: null})}>
                    <EmailReportMessage
                        success={showEmailReportCompleteMessage && showEmailReportCompleteMessage.success}
                    />
                </Popup>
            );
        }
        return null;
    }

    _renderFooter() {
        const {selectedEntity, isFetchingAccounts} = this.state;
        return (
            <div className="action-button-container">
                <div/>
                {
                    (selectedEntity && !isFetchingAccounts) &&
                    <button
                        className="action-btn-primary btn-sm" style={{width:'auto'}}
                        onClick={this.handleSubmitReportRequest}
                    >Generate Statement
                    </button>
                }
            </div>
        );
    }

    render() {
        const {entities, loading, systemError} = this.props;
        if (loading || systemError && systemError.show) return null;
        const {selectedEntity} = this.state;
        const hasEntities = _.size(entities) > 0;
        if (!hasEntities) return (
            <div className="page-container">
                <div className="page-main-section">
                    <NoLinkedEntitiesMessage/>
                </div>
            </div>
        );
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="flexRow" style={{width: '100%', alignItems: 'flex-start'}}>

                        {this._renderEntitiesSection()}
                        <div style={{width: '100%', marginLeft: 20}}>
                            {selectedEntity && this._renderReportSection()}
                        </div>
                    </div>
                </div>
                <div className="page-footer-section" style={{width: '80%'}}>
                    {this._renderFooter()}
                </div>
                {this._renderPopUps()}
            </div>
        );
    }
}

function mapStateToProps({product, accounts, entity, impersonation, loading, systemError}) {
    return {
        accounts: accounts && accounts.selectedEntityAccounts ? accounts.selectedEntityAccounts : [],
        entities: entity.entities ? entity.entities : [],
        products: product && product.products ? product.products : [],
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading,
        systemError
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...actions,
            fetchAccounts,
            fetchHierachy,
            onSelectAccount,
            onSelectAllAccounts
        }, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(statements);
