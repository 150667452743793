import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function signatoryReducer(state = initialState.signatories , action) {
    switch (action.type) {
        case types.FETCH_SIGNATORY_APPLICATION_SUCCESS:
            return {
                ...state, signatories: action.signatories
            };
        default:
            return state;
    }
}
