import _ from 'lodash';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function bolReducer(state = initialState.entities, action) {
    switch (action.type) {
        case types.SAVE_NEW_BOL_PROFILE_SUCCESS:
            const update = action.newBolProfile ? {newBolProfile: action.newBolProfile} : {};
            return {...state, ...update};

        case types.ON_CHANGE_BOL_VALUE:
            return {
                ...state,
                [action.objName]: _.extend({}, state[action.objName], action.attribute ? {[action.attribute]: action.value} : action.value)
            };

        case types.FETCH_BOL_PROFILES_SUCCESS:
            return {...state, profiles: action.profiles};

        case types.FETCH_BOL_OPERATORS_SUCCESS:
            return {...state, operators: action.operators};

        case types.CLEAR_BOL_PROFILE_OPERATORS:
            return {...state, operators: null};

        default:
            return state;
    }
}