import React, {Component} from 'react';
import '../styles/landing.css';
import {ONEHUB_URL} from "../api-config";

class LandingPage extends Component {

    navigateToLogin() {
        console.log('To login');
        window.location.replace(ONEHUB_URL);
    }

    navigateToSignup() {
        console.log('To Signup');
        window.location.replace(ONEHUB_URL + '/s/registration-request-form');
    }

    render() {
        return (
            <div className="pages-w-background">


                <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-6">

                        <div className="pages-w-logo"></div>
                        <div class="card card-container">
                            <h3 class='login_title'>Welcome to Service Online</h3>
                            <hr/>
                            <div className="card-logo">
                                <div className="card-logo-image"></div>
                            </div>
                            <p>
                                The Service Online & Maintenance Application will now be hosted on Standard Banks OneHub platform.
                                Standard Bank’s OneHub platform is an online marketplace that gives corporate clients access
                                to a range of digital solutions. The OneHub offering is part of Standard Bank Group’s future ready
                                strategy. </p>

                                You will now be redirected to OneHub to sign in.


                            <div className="card-footer">
                                <p>
                                    <button class="btn btn-primary" type="button" onClick={this.navigateToLogin}>SIGN IN</button>
                                </p>
                                <button class="btn secondary" type="button" onClick={this.navigateToSignup}>SIGN UP</button>
                                <p class="text">By signing in you accept the
                                    <a target="_blank" href="https://www.standardbank.co.za/static_file/South%20Africa/PDF/Business%20Ts%20and%20Cs/Business%20General%20Terms%20and%20Conditions.pdf"> terms and conditions</a>
                                </p>
                            </div>

                        </div>
                    </div>
                    <div class="col-sm-2">
                    </div>
                </div>
            </div>
        );
    }
}

export default LandingPage;
