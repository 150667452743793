import React from 'react';
import _ from "lodash";
import moment from "moment";
import connect from "react-redux/es/connect/connect";
import {ListSearchComponent} from "../SearchComponent";
import {bindActionCreators} from "redux";
import ReactLoading from "react-loading";

import {Tracking} from './Tracker';
import * as actions from "../../actions/tracking";

const STATUS = {
    Rejected: {
        value: "Rejected",
        reason: "Rejected"
    },
    InProgress: {
        value: "InProgress",
        reason: "In Progress"
    },
    Completed: {
        value: "Completed",
        reason: "Completed"
    },
    OnHold: {
        value: "OnHold",
        reason: "On Hold"
    },
    NotTraceable: {
        value: "NotTraceable",
        reason: "No longer traceable"
    }
};

const DIRECTION = {
    outgoing: "OUT",
    incoming: "IN"
};

class PaymentTrackingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredTransactions: null,
            selectedTransaction: null,
            isFetchingTransactionDetails: false,
            showSelectedDetails: false,
        };
        this.handleSelect = this._handleSelect.bind(this);
        this.handleFilterTransactions = this._handleFilterTransactions.bind(this);
    }

    _handleSelect(selectedTransaction) {
        const {actions} = this.props;
        this.setState({isFetchingTransactionDetails: true, selectedTransaction, showSelectedDetails: false});
        const onComplete = (success = true) => {
            let updates = {isFetchingTransactionDetails: false, showSelectedDetails: success};
            if (!success) {
                updates = _.extend({}, updates, {selectedTransaction: null});
            }
            this.setState(updates);
        };
        actions.fetchTransaction(selectedTransaction.id, onComplete)
    }

    _handleFilterTransactions(searchTerm) {
        const {transactions} = this.props;
        let filteredTransactions = null;
        if (searchTerm) {
            filteredTransactions = _.filter(transactions, trans => {
                return trans && (trans.transactionReference && trans.transactionReference.toLowerCase().includes(searchTerm.toLowerCase()) || trans.currency && trans.currency.toLowerCase().includes(searchTerm.toLowerCase()) || trans.name && trans.name.toLowerCase().includes(searchTerm.toLowerCase()) || trans.status && trans.status.toLowerCase().includes(searchTerm.toLowerCase()))
            })
        }
        this.setState({filteredTransactions, searchTerm})
    }

    _renderTransactions() {
        const {transactions} = this.props;
        const {filteredTransactions, selectedTransaction} = this.state;
        const inwards = _.filter(transactions, t => (t.direction === DIRECTION.incoming));
        const outwards = _.filter(transactions, t => (t.direction === DIRECTION.outgoing))
        return (
            <div style={{width: "100%"}}>
                <div>
                    <div className="product-heading">
                        Inward Transactions
                    </div>
                    <div className="title-gradient"/>
                </div>
                {
                    _.size(inwards) > 0 ?
                        <TransactionList
                            transactions={inwards}
                            onSelect={this.handleSelect}
                            filteredTransactions={filteredTransactions}
                            onFilterTransaction={this.handleFilterTransactions}
                            selectedTransaction={selectedTransaction}
                            isInward={true}
                        /> :
                        <div className="card-container"><p>There are no inwards transactions.</p></div>
                }
                <div>
                    <div className="product-heading">
                        Outward Transactions
                    </div>
                    <div className="title-gradient"/>
                </div>

                {
                    _.size(outwards) ?
                        <TransactionList
                            transactions={outwards}
                            onSelect={this.handleSelect}
                            filteredTransactions={filteredTransactions}
                            onFilterTransaction={this.handleFilterTransactions}
                            selectedTransaction={selectedTransaction}
                            isInward={false}
                        /> :
                        <div className="card-container"><p>There are no outwards transactions.</p></div>
                }
            </div>
        )
    }

    _renderHop(hop, shouldShow) {
        if (shouldShow) {
            const institution = hop && hop.institution;
            if (institution) {
                return (
                    <div className="status-container">
                        <div>
                            <span className="section-title">{institution.name}</span>
                        </div>
                        <hr style={{padding: 0, margin: 0}}/>
                        <div>
                            <span>Bic: </span>
                            <span> {institution.bic8}</span>
                        </div>
                        <div>
                            Country: {institution.country}
                        </div>
                        <div>
                            Status: {hop.status ? STATUS[hop.status].reason : ''}
                        </div>
                        <div>
                            Amount: {hop.amount}
                        </div>
                        <div>
                            Charge Amount: {hop.deduction}
                        </div>
                        <div>
                            Date: {hop.time}
                        </div>
                    </div>
                )
            }
            return null
        }
        return <div style={{width: 250}}/>;
    }

    checkStatus = (status) => {
        return status === STATUS.InProgress.value;
    };

    _renderTransactionDetails() {
        const {selectedTransactionDetails,} = this.props
        if (!selectedTransactionDetails) return null;

        const {isFetchingTransactionDetails, showSelectedDetails} = this.state;

        if (isFetchingTransactionDetails) return (
            <div className="rule-spinner">
                <ReactLoading type="spokes" color="#444"/>
            </div>
        );
        if (!showSelectedDetails) return null;
        const hops = _.orderBy(selectedTransactionDetails.hops, s => (s.time));
        const numStatus = hops && hops.length;
        const lastHop = hops && hops[numStatus - 1];
        const now = moment();
        const startDate = moment(hops[0] && hops[0].time);
        const endDate = moment(lastHop && lastHop.time);
        const duration = endDate.isAfter(now) ? now.diff(startDate, "milliseconds") : endDate.diff(startDate, "milliseconds");

        const creditedAmount = (_.toNumber(selectedTransactionDetails.amount) - _.toNumber(selectedTransactionDetails.deduction ? selectedTransactionDetails.deduction : 0));
        const formatDuration = (milliseconds) => {
            let days, hours, minutes, seconds;
            seconds = Math.floor(milliseconds / 1000);
            minutes = Math.floor(seconds / 60);
            seconds = seconds % 60;
            hours = Math.floor(minutes / 60);
            minutes = minutes % 60;
            days = Math.floor(hours / 24);
            hours = hours % 24;

            return `${days > 0 ? (days + " Day(s)") : '' } ${hours > 0 ? (hours + " Hour(s)") : '' } ${minutes + " Minutes(s)"}`;
        };
        return (
            <div>
                <div>
                    <div className="product-heading">
                        Transaction: {selectedTransactionDetails.transactionReference ? selectedTransactionDetails.transactionReference : ''}
                    </div>
                    <div className="title-gradient"/>
                </div>

                <div className="card-container">
                    <div className="flexRow" style={{justifyContent: "space-between"}}>
                        <div>
                            <p className="section-title">Final Status</p>
                            <p>{lastHop.status ? STATUS[lastHop.status].reason : ''}</p>
                        </div>
                        <div>
                            <p className="section-title">Instructed Amount</p>
                            <p>{`${selectedTransactionDetails.amount} ${selectedTransactionDetails.currency ? selectedTransactionDetails.currency : '0.00'}`}</p>
                        </div>
                        <div>
                            <p className="section-title">Credited Amount</p>
                            <p>{`${creditedAmount ? creditedAmount : '0.00'}  ${creditedAmount ? selectedTransactionDetails.currency : ''}`}</p>
                        </div>
                        <div>
                            <p className="section-title">Total Duration</p>
                            <p>{formatDuration(duration + 4000)}</p>
                        </div>
                    </div>
                    <div>
                        <span className="section-title">Tracking Tree</span>
                    </div>
                    <br/>

                    <div className="list-scroller" style={{maxHeight: "70vh"}}>
                        {
                            _.map(hops, (hop, i) => {
                                const isStartPoint = i === 0;
                                const isEndpoint = i === (_.size(hops) - 1)
                                const isEven = i % 2 === 0;
                                return (
                                    <div key={i} className="flexRow">
                                        {this._renderHop(hop, isEven)}
                                        <Tracking
                                            isStartPoint={isStartPoint}
                                            isEndpoint={isEndpoint}
                                            isCompleted={this.checkStatus(hop.status)}
                                            predecessorCompleted={isStartPoint ? false : this.checkStatus(selectedTransactionDetails.hops[i - 1] && selectedTransactionDetails.hops[i - 1].status)}
                                        />
                                        {this._renderHop(hop, !isEven)}
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {selectedTransaction} = this.state;
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="flexRow" style={{width: "100%", alignItems: "flex-start"}}>
                        {this._renderTransactions()}
                        <div style={{width: "100%", marginLeft: 20}}>
                        {selectedTransaction && this._renderTransactionDetails()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const TransactionList = (props) => {
    const {transactions, filteredTransactions, onSelect, onFilterTransaction, selectedTransaction, isInward} = props;
    if (!transactions) return null;
    const transactionList = filteredTransactions ? filteredTransactions : transactions;

    return (
        <div className="card-container">
            <div style={{display: 'flex', alignItems: 'center', padding: 2, justifyContent: "space-between"}}>
                <div/>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <ListSearchComponent
                        onFilter={onFilterTransaction}
                        placeholder="Find transaction..."
                    />

                    {false && <i className="fa fa-info-circle" style={{marginLeft: 10, fontSize: 16}}/>}
                </div>
            </div>
            <fieldset className="selection-list" name="transactions" style={{maxHeight: "30vh"}}>
                {
                    _.map(transactionList, (transaction, i) => {
                            const isSelected = (transaction && transaction.id) === (selectedTransaction && selectedTransaction.id);
                            const amount = transaction && transaction.settlementAmount;
                            return (
                                <div
                                    className={isSelected ? 'selection-list-item-selected' : 'selection-list-item'}
                                    onClick={(e) => onSelect(transaction)}
                                    key={`transaction/${i}/${transaction && transaction.id}`}
                                >
                                    <i
                                        className={isSelected ? "fa fa-check-circle" : "fa fa-circle-o"}
                                        style={{color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10}}
                                    />
                                    <div>
                                        {transaction && transaction.transactionReference &&
                                        <span>{transaction.transactionReference} | </span>}
                                        <span
                                            className="greyText"
                                        >
                                            {amount && transaction.currency} | {amount && amount} | {transaction.valueDate} | {transaction.status}
                                            </span>
                                    </div>
                                </div>
                            )
                        }
                    )
                }
            </fieldset>
        </div>
    )
};

function mapStateToProps({accounts, entity, impersonation, loading, systemError}) {
    return {
        transactions: accounts && accounts.transactions ? accounts.transactions : [],
        selectedTransactionDetails: accounts && accounts.selectedTransactionDetails ? accounts.selectedTransactionDetails : {},
        loading,
        systemError
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTrackingPage);