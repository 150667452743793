import React from 'react';
import SearchComponent from '../../components/SearchComponent'
import FormField from "../formField/FormField";
import FileUpload from "../FileUpload";
import Select from 'react-select';
import Toggler from "../Toggler";
import {Col, FormGroup} from "react-bootstrap";

const BUSINESS_TYPE = {
    juristicEntity: 'Juristic Entity',
    naturalPerson: 'Natural Person',
    individual: 'individual'
};

const DOC_TYPES = {
    proofOfId: 'Proof of Identity',
    proofOfAddress: 'Proof Of Address',
    CompanyRegistrationDocument: 'Company Registration Document'
};

class NewRelatedParty extends React.Component {
    state = {isRSACitizen: true, isSearchComplete: false};

    handleChangeSACitizen = (isRSACitizen) => {
        this.setState({isRSACitizen})
    };

    handleEntitySearch = () => {
        this.props.onEntitySearch(this.onSearchComplete)
    };

    handleIndividualSearch = () => {
        this.props.onIndividualSearch(this.onSearchComplete)
    };

    handleSelectRType = (e, type) => {
        this.setState({isSearchComplete: false});
        this.props.selectRPType(e, type);
    };

    onSearchComplete = () => {
        this.setState({isSearchComplete: true});
    };

    _renderHeader() {
        return (
            <div className="container">
                <div className="product-heading">
                    New Related Party
                </div>
                <div className="title-gradient"/>
            </div>
        )
    }

    _renderTypeSelector() {
        const {newRP} = this.props;
        const isNaturalPerson = newRP && (newRP.businessType === BUSINESS_TYPE.naturalPerson || newRP.individual === BUSINESS_TYPE.individual);
        const iconStyle = {color: "rgb(0, 51, 170)", fontSize: 18, margin: "0 10px"};
        return (
            <div className="container" style={{ padding: 0 }}>
                <div className="checkbox-inline" style={{ padding: 0 }}>
                    Type:
                    <span onClick={(e) => this.handleSelectRType(e, BUSINESS_TYPE.naturalPerson)} style={{margin:"0 15px "}}>
                        <i className={isNaturalPerson ? "fa fa-check-circle" : "fa fa-circle-o"} style={iconStyle} /> 
                        {`${BUSINESS_TYPE.naturalPerson}`}
                    </span>
                    <span onClick={(e) => this.handleSelectRType(e, BUSINESS_TYPE.juristicEntity)} style={{margin:"0 15px"}}>
                        <i className={!isNaturalPerson ? "fa fa-check-circle" : "fa fa-circle-o"} style={iconStyle} /> 
                        {`${BUSINESS_TYPE.juristicEntity}`}
                    </span>
                </div>
            </div>
        )
    }


    allowOnlyCharacters = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^A-Za-z\s]/g, '');
        event.target.value = value;
    }

    allowOnlyNumbers = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^0-9]/g, '');
        event.target.value = value;
    }

    allowOnlyDecimal = (event) => {
        let { value } = event.target;
    
        // Replace characters that are not digits or a period with an empty string
        value = value.replace(/[^0-9.]/g, '');
    
        // Make sure there is only one period in the value
        const periods = value.split('.').length - 1;
        if (periods > 1) {
            value = value.slice(0, value.lastIndexOf('.'));
        }
    
        // Allow only two digits after the period
        const parts = value.split('.');
        if (parts[1] && parts[1].length > 2) {
            value = `${parts[0]}.${parts[1].slice(0, 2)}`;
        }
    
        event.target.value = value;
    }

    render() {
        const {isRSACitizen, isSearchComplete} = this.state;
        const {newRP, errors} = this.props;

        const isNaturalPerson = newRP && (newRP.businessType === BUSINESS_TYPE.naturalPerson || newRP.individual === BUSINESS_TYPE.individual);
        return (
            <div>
                <div className="container">
                    <div className="row" style={{ margin: "10px 0" }}>
                        {this._renderHeader()}
                    </div>
                   
                    <div className="row" style={{ margin: 0 }}>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            {this._renderTypeSelector()}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            {
                                isNaturalPerson && !isSearchComplete &&
                                <Toggler
                                    label="South African Citizen / Resident?"
                                    isOn={isRSACitizen}
                                    onToggle={this.handleChangeSACitizen}
                                />
                            }
                        </div>
                    </div>
                </div>
                <hr />
                {
                    isNaturalPerson ?
                        <NaturalPerson
                            {...this.props}
                            onSearch={this.handleIndividualSearch}
                            rsaCitizen={isRSACitizen}
                            allowOnlyCharacters = {this.allowOnlyCharacters}
                            allowOnlyNumbers = {this.allowOnlyNumbers}
                            allowOnlyDecimal = {this.allowOnlyDecimal}
                            shouldDisableInput={!(!isRSACitizen || isSearchComplete)}
                        />
                        :
                        <JuristicEntity
                            {...this.props}
                            onSearch={this.handleEntitySearch}
                            allowOnlyCharacters = {this.allowOnlyCharacters}
                            allowOnlyNumbers = {this.allowOnlyNumbers}
                            allowOnlyDecimal = {this.allowOnlyDecimal}
                            shouldDisableInput={!isSearchComplete}
                        />
                }
            </div>
        );
    }
};
export default NewRelatedParty;

export const JuristicEntity = (props) => {
    let {
        errors, newRP, shouldDisableInput, onChange, onResetError, onSubmit, onHandleUpload,
        onHandleRemoveDocument, showUpload, onSearch, idDoc, handleUpload, onHandleUploadCompanyRegistrationDocument, allowOnlyNumbers, allowOnlyCharacters, allowOnlyDecimal
    } = props;

    if (newRP.registeredAddress && newRP.registeredAddress.street && newRP.registeredAddress.suburb) {
        newRP.street = (`${newRP.registeredAddress.street} , ${newRP.registeredAddress.suburb}`);
        newRP.telephoneNumber = (`${newRP.telephoneNumber}`);
    }

      if (!newRP) return null;
    const showUploadButtons = showUpload ? showUpload : null;
    console.log(newRP);

    return (

        <div className="container">
            <div className="container">
                <div className="col-xs-12 col-md-6">
                    <div className="card-container-form">
                        <FormField className="form-group" id="registrationNum" error={errors && errors.registrationNumber}>
                            <SearchComponent
                                label={"Registration Number"}
                                onChange={(event) => {
                                    errors && errors.registrationNumber && onResetError && onResetError('registrationNumber');
                                    onChange(event, 'registrationNumber')
                                }}
                                onSearch={onSearch}
                                searchValue={newRP && newRP.registrationNumber || ''}
                            />

                            {/* {
                                !shouldDisableInput ?
                                    <div>
                                        <label>Registration Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="registrationNumber"
                                            value={newRP.registrationNumber || ''}
                                            onChange={(event) => {
                                                errors && errors.registrationNumber && onResetError && onResetError('registrationNumber');
                                                onChange(event, 'registrationNumber')
                                            }}
                                        />
                                    </div> :
                                    <SearchComponent
                                        label={"Registration Number"}
                                        onChange={(event) => {
                                            errors && errors.registrationNumber && onResetError && onResetError('registrationNumber');
                                            onChange(event, 'registrationNumber')
                                        }}
                                        onSearch={onSearch}
                                        searchValue={newRP && newRP.registrationNumber || ''}
                                    />
                            } */}
                        </FormField>

                        <FormField className={"form-group"} id="registeredname" error={errors && errors.registeredName}>
                            <label>Registered Name <span class="text-danger">*</span></label>
                            <input
                                type="text"
                                // disabled={shouldDisableInput}
                                disabled={!!newRP.registeredName || shouldDisableInput}
                                className="form-control"
                                id="registeredName"
                                value={newRP.registeredName || ''}
                                onChange={(event) => {
                                    allowOnlyCharacters(event);
                                    errors && errors.registeredName && onResetError && onResetError('registeredName');
                                    onChange(event, 'registeredName')
                                }}
                            />
                        </FormField>

                        <FormField className={"form-group"} id="contactNumr" error={errors && errors.telephoneNumber}>
                            <label>Contact Number <span class="text-danger">*</span></label>
                            <input
                                className="form-control"
                                id="telephoneNumber"
                                disabled={shouldDisableInput}
                                value={newRP.telephoneNumber || ''}
                                type="text"
                                // onChange={(event) => {
                                //     errors && errors.telephoneNumber && onResetError && onResetError('telephoneNumber');
                                //     onChange(event, 'telephoneNumber')
                                // }}

                                onChange={(event) => {
                                    const cleanedValue = event.target.value.replace(/[^0-9]/g, '');
                                    allowOnlyNumbers(event);
                                    errors && errors.telephoneNumber && onResetError && onResetError('telephoneNumber');
                                    onChange({ ...event, target: { ...event.target, value: cleanedValue } }, 'telephoneNumber');
                                }}
                            />
                        </FormField>

                        

                        <FormField className={"form-group"} id="sharePercentage"
                                   error={errors && errors.sharePercentage}>
                            <Col xs={12} style={{paddingLeft: 0}}>
                                <label>Shareholding Percentage <span class="text-danger">*</span></label>
                            </Col>
                            <div className="input-group">
                                <input
                                    type="test"
                                    disabled={shouldDisableInput}
                                    className="form-control"
                                    id="entitypercentage"
                                    value={newRP.sharePercentage || ''}
                                    onChange={(event) => {
                                        allowOnlyDecimal(event);
                                        errors && errors.sharePercentage && onResetError && onResetError('sharePercentage');
                                        onChange(event, 'sharePercentage')
                                    }}
                                />
                                <span className="input-group-addon">%</span>
                            </div>
                        </FormField>
                    </div>
                </div>

                <div className="col-xs-12 col-md-6">
                    <div className="card-container-form">
                        <FormField className={"form-group"} id="address" error={errors && errors.street}>
                            <label>Registered Address <span class="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                disabled={!!newRP.street || shouldDisableInput}
                                id="registeredAddress"
                                value={newRP.street || ''}
                                onChange={(event) => {
                                    errors && errors.street && onResetError && onResetError('street');
                                    onChange(event, 'street')
                                }}
                            />
                        </FormField>

                        <FormField className={"form-group"} id="addressHome" error={errors && errors.headOfficeAddress}>
                            <label>Head Office Address <span class="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="headOfficeAddress"
                                value={newRP.headOfficeAddress || ''}
                                onChange={(event) => {
                                    errors && errors.headOfficeAddress && onResetError && onResetError('headOfficeAddress');
                                    onChange(event, 'headOfficeAddress')
                                }}
                            />
                        </FormField>

                        <FormField className={"form-group"} id="addressPhysical"
                                   error={errors && errors.physicalAddress}>
                            <label>Physical Business Address <span class="text-danger">*</span></label>
                            <input
                                type="text"
                                disabled={shouldDisableInput}
                                className="form-control"
                                id="physicalAddress"
                                value={newRP.physicalAddress || ''}
                                onChange={(event) => {
                                    errors && errors.physicalAddress && onResetError && onResetError('physicalAddress');
                                    onChange(event, 'physicalAddress')
                                }}
                            />
                        </FormField>
                        {idDoc &&
                        <FormGroup>
                            <div className={'row'} style={{marginLeft: '0'}}>
                                <label className={'control-label'}>Company Registration Document</label>
                            </div>
                            {/* <FileUpload
                                disabled={false}
                                documentType={'CompanyRegistrationDocument'}
                                onUpload={handleUpload}
                                onRemoveDocument={false}
                            /> */}

                            <FileUpload
                                documentType={DOC_TYPES.CompanyRegistrationDocument}
                                onUpload={(base64, inProgress, onComplete) => {
                                    errors && errors.CompanyRegistrationDocument && onResetError('CompanyRegistrationDocument');
                                    onHandleUploadCompanyRegistrationDocument && onHandleUploadCompanyRegistrationDocument(base64, inProgress, onComplete, DOC_TYPES.CompanyRegistrationDocument)
                                }}
                                onRemoveDocument={() => {
                                    errors && errors.proofOfAddress && onResetError('CompanyRegistrationDocument');
                                    onHandleRemoveDocument && onHandleRemoveDocument(DOC_TYPES.CompanyRegistrationDocument);
                                }}
                            />
                         
                        </FormGroup>
                        }
                        <div style={{zIndex: -1, padding: 0, margin: 0}}>
                            {
                                showUploadButtons &&
                                <FormField
                                    className="form-group " id="proofOfAddressDoc"
                                    style={{display: "flex", flexDirection: "column"}}
                                    error={errors && errors.proofOfAddress ? ["Document required"] : null}
                                >
                                    <label htmlFor="proofOfAddressDoc">Proof of Address</label>
                                    <FileUpload
                                        documentType={DOC_TYPES.proofOfAddress}
                                        onUpload={(base64, inProgress, onComplete) => {
                                            errors && errors.proofOfAddress && onResetError('proofOfAddress');
                                            onHandleUpload && onHandleUpload(base64, inProgress, onComplete, DOC_TYPES.proofOfAddress)
                                        }}
                                        onRemoveDocument={() => {
                                            errors && errors.proofOfAddress && onResetError('proofOfAddress');
                                            onHandleRemoveDocument && onHandleRemoveDocument(DOC_TYPES.proofOfAddress);
                                        }}
                                    />
                                </FormField>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="action-button-container" style={{width: "100%"}}>
                <div/>
                <button
                    className="action-btn-secondary"
                    onClick={onSubmit}
                    type="button"
                >Add
                </button>
            </div>
        </div>
    )
};

export const NaturalPerson = (props) => {
    const {
        errors, newRP, onChange, onResetError, onHandleUploadProofOfAddress, onHandleUploadIdentityDocument, onHandleRemoveDocument, onSubmit, showUpload,
        shouldDisableInput, onSearch, idDoc, rsaCitizen, allowOnlyNumbers, allowOnlyCharacters, allowOnlyDecimal
    } = props;

    if (!newRP) return null;
    const showUploadButtons = showUpload ? showUpload : null;

    const phoneOptions = _.map(newRP.telephones, (t) => ({value: t.msisdn, label: t.msisdn}));
    const addressOptions = _.map(newRP.addresses, (t) => ({value: t.line1, label: t.line1}));

    const hasPhoneOptions = _.size(phoneOptions) > 0;
    
    console.log(newRP);
    
    return (
        <div className="container">
            <div className="row" style={{ margin:0 }}>
                <div className="col-lg-4 col-md-4 col-xs-12" >
                    <div className="card-container-form" style={{padding: 0}}>
                        <FormField id="regNumber" className="form-group"
                                   error={errors && errors.registrationNumber}>
                            {
                                 !rsaCitizen ?
                                    <div>
                                        <label>ID/Passport Number <span class="text-danger">*</span></label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="idNumber"
                                            value={newRP.registrationNumber || ''}
                                            onChange={(event) => {
                                                errors && errors.registrationNumber && onResetError && onResetError('registrationNumber');
                                                onChange(event, 'registrationNumber')
                                            }}
                                        />
                                    </div> :
                                    <SearchComponent
                                        label={"ID Number"}
                                        onChange={(event) => {
                                            errors && errors.registrationNumber && onResetError && onResetError('registrationNumber');
                                            onChange(event, 'registrationNumber')
                                        }}
                                        onSearch={onSearch}
                                        searchValue={newRP && newRP.registrationNumber || ''}
                                    />
                            }
                        </FormField>

                        <FormField className={"form-group"} id="firstname" error={errors && errors.firstName}>
                            <label>First Name <span class="text-danger">*</span></label>
                            <input
                                type="text"
                                disabled={shouldDisableInput || rsaCitizen}
                                className="form-control"
                                id="firstName"
                                value={newRP.firstName || ''}
                                onChange={(event) => {
                                    allowOnlyCharacters(event);
                                    errors && errors.firstName && onResetError && onResetError('firstName');
                                    onChange(event, 'firstName')
                                }}
                            />
                        </FormField>

                        <FormField className={"form-group"} id="lastName" error={errors && errors.lastName}>
                            <label>Surname <span class="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                disabled={shouldDisableInput || rsaCitizen}
                                id="surname"
                                value={newRP.lastName || ''}
                                onChange={(event) => {
                                    allowOnlyCharacters(event);
                                    errors && errors.lastName && onResetError && onResetError('lastName');
                                    onChange(event, 'lastName')
                                }}
                            />
                        </FormField>



                        <FormField className={"form-group"} id="contactNum" error={errors && errors.contactNumber}>
                            <label>Contact Number <span class="text-danger">*</span></label>
                            {
                                hasPhoneOptions ?
                                    <Select
                                        menuContainerStyle={{ zIndex: 999 }}
                                        disabled={shouldDisableInput}
                                       
                                        name="contactNumber"
                                        onChange={(event) => {
                                            props.handleSelectChange(event, 'contactNumber')
                                        }}
                                        options={phoneOptions}
                                        value={newRP.contactNumber || ''}
                                    />
                                    :

                                    <input
                                        className="form-control"
                                        disabled={shouldDisableInput}
                                        id="contactNumber"
                                        onChange={(event) => {
                                            allowOnlyNumbers(event);
                                            errors && errors.contactNumber && onResetError && onResetError('contactNumber');
                                            onChange(event, 'contactNumber')
                                        }}
                                        type="text"
                                        value={newRP.contactNumber || ''}
                                    />
                            }
                        </FormField>


                        <FormField className={"form-group"} id="emailAddress" error={errors && errors.emailAddress}>
                            <label>Email Address <span class="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="email"
                                value={newRP.emailAddress || ''}
                                onChange={(event) => {
                                    errors && errors.emailAddress && onResetError && onResetError('emailAddress');
                                    onChange(event, 'emailAddress')
                                }}
                            />
                        </FormField>

                        
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card-container-form" style={{padding: 0}}>
                        
                    <FormField className={"form-group"} id="sharePercentage"
                                   error={errors && errors.sharePercentage}>
                            <Col xs={12} style={{paddingLeft: 0}}>
                                <label>Shareholding Percentage <span class="text-danger">*</span></label>
                            </Col>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={shouldDisableInput}
                                    id="percentage"
                                    value={newRP.sharePercentage || ''}
                                    onChange={(event) => {
                                        allowOnlyDecimal(event);
                                        errors && errors.sharePercentage && onResetError && onResetError('sharePercentage');
                                        onChange(event, 'sharePercentage')
                                    }}
                                />
                                <span className="input-group-addon">%</span>
                            </div>
                        </FormField>

                        <FormField className={"form-group"} id="cap" error={errors && errors.capacity}>
                            <label>Capacity <span class="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="capacity"
                                value={newRP.capacity || ''}
                                onChange={(event) => {
                                    allowOnlyCharacters(event);
                                    errors && errors.capacity && onResetError && onResetError('capacity');
                                    onChange(event, 'capacity')
                                }}
                            />
                        </FormField>

                        <FormField className="form-group" id="streetAddress" error={errors && errors.streetAddress} >
                            <label htmlFor="streetAddress">Address <span
                                className="text-danger">*</span></label>
                            <input
                                className="form-control"
                                id="streetAddress"
                                disabled={shouldDisableInput}
                                onChange={(event) => {
                                    // allowOnlyCharacters(event);
                                    onChange(event, 'streetAddress')
                                }}
                                type="text"
                                value={newRP.streetAddress || ""}

                            />
                        </FormField>

                        <FormField className="form-group" id="suburb" error={errors && errors.suburb} >
                            <label htmlFor="suburb">Suburb <span class="text-danger">*</span></label>
                            <input
                                className="form-control"
                                id="individualAddressLine2"
                                disabled={shouldDisableInput}
                                onChange={(event) => {
                                    allowOnlyCharacters(event);
                                    onChange(event, 'suburb')
                                }}
                                type="text"
                                value={newRP.suburb || ""}
                            />
                        </FormField>

                        <FormField className="form-group" id="city" error={errors && errors.city}  >
                            <label htmlFor="city">City <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                disabled={shouldDisableInput}
                                className="form-control"
                                id="city"
                                value={newRP.city || ""}
                                onChange={(event) => {
                                    allowOnlyCharacters(event);
                                    onChange(event, 'city')
                                }}
                            />
                        </FormField>

                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-xs-12">
                    <div className="card-container-form" style={{padding: 0}}>

                        <FormField className="form-group" id="city" error={errors && errors.province}  >
                            <label htmlFor="province">State/Province <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                disabled={shouldDisableInput}
                                className="form-control"
                                id="province"
                                value={newRP.province || ""}
                                onChange={(event) => {
                                    allowOnlyCharacters(event);
                                    onChange(event, 'province')
                                }}
                            />
                        </FormField>

                        <FormField className="form-group" id="postalCode" error={errors && errors.postalCode} >
                            <label htmlFor="postalCode">Zip/Postal Code <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="individualZipOrPostalCode"
                                value={newRP.postalCode || ""}
                                onChange={(event) => {
                                    allowOnlyNumbers(event);
                                    onChange(event, 'postalCode')
                                }}
                            />
                        </FormField>

                        <FormField className="form-group" id="city" error={errors && errors.country}  >
                            <label htmlFor="country"> Country <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                disabled={shouldDisableInput}
                                className="form-control"
                                id="country"
                                value={newRP.country || ""}
                                onChange={(event) => {
                                    allowOnlyCharacters(event);
                                    onChange(event, 'country')
                                }}
                            />
                        </FormField>
                       
                         
                        {idDoc &&
                            <div style={{ zIndex: -1, padding: 0, margin: 0 }}>
                                <FormField className="form-group" error={errors && errors.proofOfAddress ? ["Document required"] : null}>
                                    <div className={'row'} style={{ marginLeft: '0' }}>
                                        <label className={'control-label'}>Proof of Address <span class="text-danger">*</span></label>
                                    </div>
                                    <FileUpload
                                        documentType={DOC_TYPES.proofOfAddress}
                                        onUpload={(base64, inProgress, onComplete) => {
                                            errors && errors.proofOfAddress && onResetError('proofOfAddress');
                                            onHandleUploadProofOfAddress && onHandleUploadProofOfAddress(base64, inProgress, onComplete, DOC_TYPES.proofOfAddress)
                                        }}
                                        onRemoveDocument={() => {
                                            errors && errors.proofOfAddress && onResetError('proofOfAddress');
                                            onHandleRemoveDocument && onHandleRemoveDocument(DOC_TYPES.proofOfAddress);
                                        }}
                                    />
                                </FormField>

                                <FormField className="form-group" error={errors && errors.proofOfId ? ["Document required"] : null}>
                                    <div className={'row'} style={{ marginLeft: '0' }}>
                                        <label className={'control-label'}>Identity Document <span class="text-danger">*</span></label>
                                    </div>
                                    <FileUpload
                                        documentType={DOC_TYPES.proofOfId}
                                        onUpload={(base64, inProgress, onComplete) => {
                                            errors && errors.proofOfId && onResetError('proofOfId');
                                            onHandleUploadIdentityDocument && onHandleUploadIdentityDocument(base64, inProgress, onComplete, DOC_TYPES.proofOfId)
                                        }}
                                        onRemoveDocument={() => {
                                            errors && errors.proofOfId && onResetError('proofOfId');
                                            onHandleRemoveDocument && onHandleRemoveDocument(DOC_TYPES.proofOfId);
                                        }}
                                    />
                                </FormField>
                            </div>
                        }
                        <div style={{zIndex: -1, padding: 0, margin: 0}}>
                            {
                                showUploadButtons &&
                                <FormField
                                    className="form-group"
                                    style={{display: "flex", flexDirection: "column"}}
                                    error={errors && errors.proofOfId ? ["Document required"] : null}
                                >
                                    <label>Proof of ID</label>
                                    <FileUpload
                                        documentType={DOC_TYPES.proofOfId}
                                        onUpload={(base64, inProgress, onComplete) => {
                                            errors && errors.proofOfId && onResetError('proofOfId');
                                            onHandleUpload && onHandleUpload(base64, inProgress, onComplete, DOC_TYPES.proofOfId)
                                        }}
                                        onRemoveDocument={() => {
                                            errors && errors.proofOfId && onResetError('proofOfId');
                                            onHandleRemoveDocument && onHandleRemoveDocument(DOC_TYPES.proofOfId);
                                        }}
                                    />
                                </FormField>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="action-button-container" style={{width: "100%"}}>
                <div/>
                <button
                    className="action-btn-secondary"
                    onClick={onSubmit}
                    type="button"
                >Add
                </button>
            </div>
        </div>
    )
};
