import React, {Component} from 'react';
import _ from 'lodash';
import {
    Panel,
    Badge,
    ToggleButton,
    ToggleButtonGroup,
    OverlayTrigger,
    Popover
} from 'react-bootstrap'
import PanelTitle from "react-bootstrap/es/PanelTitle";
import PanelBody from "react-bootstrap/es/PanelBody";
import PanelHeading from "react-bootstrap/es/PanelHeading";

const principalDef = (
    <Popover id="principal" title='Principal (PR)'>
        A <strong>Principal Resolution</strong> is the formal written mandate from your business (e.g. Board or
        equivalent) which
        outlines who is authorised to contract (i.e. accept and sign) on behalf of your business (Authorised Signatory)
        and who is authorised to make decisions on behalf of your business with regards to your Business Online Profile
        (Designated Person).
    </Popover>
);
const participantDef = (
    <Popover id="participant" title='Participant (PA)'>
        A <strong>Participant Resolution</strong> is the formal written mandate from an entity (e.g. Board or
        equivalent) which
        authorises you to contract (i.e. accept and sign) and transact on behalf of their business (Authorised
        Signatory).
    </Popover>
);
class AccountsComponent extends Component {

    render() {
        const {accounts, entityAccounts, agreement, onAgreementChange, profile} = this.props;
        return (
            <div className='container-fluid'>
                <Panel>
                    <PanelHeading>
                        <PanelTitle>
                            <p>
                                Are the accounts listed Below&nbsp;
                                <OverlayTrigger placement="top" overlay={principalDef}>
                                    <a target='_blank'
                                       href='https://www.businessonline.standardbank.co.za/docs/Standard%20Bank%20Business%20Online%20Principal%20Electronic%20Banking%20Terms%20and%20Conditions.pdf'>Principal
                                        accounts</a>
                                </OverlayTrigger>
                                &nbsp;or&nbsp;
                                <OverlayTrigger placement="bottom" overlay={participantDef}>
                                    <a target='_blank'
                                       href='https://www.businessonline.standardbank.co.za/docs/Participant%20Electronic%20Banking%20Terms%20and%20Conditions.pdf'>Participant
                                        accounts</a>
                                </OverlayTrigger>

                            </p>
                        </PanelTitle>
                    </PanelHeading>
                    <PanelBody>
                        <ToggleButtonGroup type="radio" name="agreement"
                                           value={agreement}
                                           onChange={(agreement) => onAgreementChange({
                                               agreement,
                                               profile
                                           })}
                        >
                            <ToggleButton value={'Principal'}>Principal</ToggleButton>
                            <ToggleButton value={'Participant'}>Participant</ToggleButton>
                        </ToggleButtonGroup>
                    </PanelBody>
                </Panel>
                {
                    accounts.map(account => {
                        const entityAccount = entityAccounts[account];
                        if (entityAccount) {
                            return (
                                <Panel key={entityAccount.number}>
                                    <Panel.Body>Account Name: {entityAccount.name} &nbsp; <Badge>Account
                                        Number: {entityAccount.number}</Badge></Panel.Body>
                                </Panel>

                            );
                        } else {
                            return false;
                        }
                    })

                }

            </div>
        );
    }

}

export default (AccountsComponent);