import React from "react";
import _ from "lodash";
import Entity from "./Entity";
import {ListSearchComponent} from "../SearchComponent";
import FormField from "../formField/FormField";

class EntityList extends React.Component {
    state = {
        searchTerm: null,
        showSelectableOnly: false,
    };

    onFilter = (searchTerm) => {
        this.setState({searchTerm: _.size(searchTerm) > 0 ? searchTerm : null})
    };

    onToggleSelectableOnly = (e) => {
        e.preventDefault();
        this.setState(prevState => ({showSelectableOnly: !prevState.showSelectableOnly}));
        return false;
    };

    render() {
        const {className, entities, onSelect, selectedEntity, style, error} = this.props;
        if (!entities) return null;
        const {searchTerm, showSelectableOnly} = this.state;
        const hasSearchTerm = _.size(searchTerm) > 0;
        const entitiesList = (showSelectableOnly || hasSearchTerm) ? _.uniqBy(entities, 'goldTierId') : entities;
        const selectableItemsCount = (showSelectableOnly) && _.size(entitiesList);
        return (
            <FormField id="entities" error={error && ["One Should Be Selected"]} className={className ? className : ''}>
                <div className="card-header">
                    <div
                        className="selection-list-item-selected"
                        onClick={(e) => this.onToggleSelectableOnly(e)}
                    >
                        <i
                            className={showSelectableOnly ? "fa fa-check-circle" : "fa fa-circle-o"}
                            style={{color: "rgb(0, 51, 170)", fontSize: 20}}
                        />
                        <span className="active-entity" style={{marginLeft: 10}}>Show Selectable Only</span>
                        {
                            showSelectableOnly &&
                            <span className="active-entity" style={{
                                marginLeft: 10,
                                color: "rgb(0, 51, 170)"
                            }}>{(selectableItemsCount > 0) ? selectableItemsCount : ''}</span>
                        }
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <ListSearchComponent
                            onFilter={this.onFilter}
                            placeholder="Find entity..."
                        />
                    </div>
                </div>
                <fieldset className="selection-list" name="entity" style={style ? style : {maxHeight: '70vh'}}>
                    {
                        _.map(entitiesList, (entity, i) => (
                            <Entity
                                key={entity.goldTierId + ',' + i}
                                entity={entity}
                                onSelect={onSelect}
                                selectedEntity={selectedEntity}
                                showSelectableOnly={showSelectableOnly}
                                searchTerm={searchTerm}
                                level={0}
                            />
                        ))
                    }
                </fieldset>
            </FormField>
        )
    }
};

export default EntityList;