import React from "react";
import _ from "lodash";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";

import * as actions from "../../actions/reports";
import EntityList from "../legalentity/EntityList";
import {fetchHierachy} from "../../actions/entity";
import {fetchAccounts} from "../../actions/kyc";
import {fetchAccountTransactions} from "../../actions/tracking";
import ReactLoading from "react-loading";
import NoLinkedEntitiesMessage from "../legalentity/NoLinkedEntitiesMessage";
import validate from "validate.js";
import FormField from "../formField/FormField";
import AccountsList from '../accounts/AccountsList'

class TrackingLandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAccount: null,
            selectedEntity: null,
            filteredAccounts: null,
            errors: {}
        };
        this.handleOnFilterAccounts = this._handleOnFilterAccounts.bind(this);
        this.handleOnSelectAccount = this._handleOnSelectAccount.bind(this);
        this.handleOnSelectEntity = this._handleOnSelectEntity.bind(this);
        this.handleFetchTransactions = this._handleFetchTransactions.bind(this);
    }

    componentWillMount() {
        this._fetchData();
    }

    _fetchData() {
        const {actions, entities, impersonation} = this.props;
        if (_.size(entities) === 0) {
            actions.fetchHierachy(impersonation);
        }
    }

    _handleOnFilterAccounts(searchTerm) {
        const {accounts} = this.props;
        let filteredAccounts = null;
        if (searchTerm) {
            filteredAccounts = _.filter(accounts, acc => {
                return acc && (acc.accountNumber.includes(searchTerm) || acc.name && acc.name.toLowerCase().includes(searchTerm.toLowerCase()))
            })
        }
        this.setState({filteredAccounts, searchTerm})
    }

    _handleOnSelectEntity(selectedEntity) {
        const {actions} = this.props;
        this.setState({isFetchingAccounts: true, selectedEntity});

        const onComplete = () => {
            this.setState({isFetchingAccounts: false});
        };
        actions.fetchAccounts(`cifNumber=${selectedEntity.cif}`, 'selectedEntityAccounts', onComplete, false);
    }

    _handleOnSelectAccount(selectedAccount) {
        this.setState({selectedAccount});
    }

    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    validateSelected = () => {
        const {selectedAccount} = this.state;
        const options = {fullMessages: false};

        const errors = validate({selectedAccount}, {selectedAccount: {presence: true}}, options)

        this.setState({errors});
        return _.isEmpty(errors);
    };

    _handleFetchTransactions(e) {
        e.preventDefault();
        if (this.validateSelected()) {
            const {actions, history} = this.props;
            const {selectedAccount} = this.state;
            const accountNumber = selectedAccount && selectedAccount.accountNumber;

            const onComplete = (isSuccess = true) => {
                if (isSuccess) {
                    history.push("/track/transactions");
                }
            };
            actions.fetchAccountTransactions([accountNumber], onComplete);
        }
    }

    _renderEntitiesSection() {
        const {entities} = this.props;
        const {selectedEntity} = this.state;
        return (
            <div style={{width: "100%"}}>
                <div>
                    <div className="product-heading">
                        Legal Entities
                    </div>
                    <div className="title-gradient"/>
                </div>
                <EntityList
                    className="card-container"
                    entities={entities}
                    onSelect={this.handleOnSelectEntity}
                    selectedEntity={selectedEntity}
                />
            </div>
        )
    }

    _renderAccounts() {
        const {accounts} = this.props;
        const {errors, filteredAccounts, isFetchingAccounts, selectedAccount} = this.state;
        const hasAccounts = _.size(accounts) > 0;
        if (isFetchingAccounts) {
            return (
                <div className="rule-spinner">
                    <ReactLoading type="spokes" color="#444"/>
                </div>
            )
        }

        return (
            <div>
                <div>
                    <div className="product-heading">
                        Accounts
                    </div>
                    <div className="title-gradient"/>
                </div>
                <FormField
                    error={errors && errors.selectedAccount && ["One Should Be Selected"]}
                >


                    {
                        hasAccounts ?
                            <AccountsList
                                accounts={accounts}
                                filteredAccounts={filteredAccounts}
                                onFilterAccounts={this.handleOnFilterAccounts}
                                onSelect={() => {}}
                                onSelectAll={() => {}}
                                onSelectRadio={(selectedAccount) => {
                                    errors && errors.selectedAccount && this.handleResetError("selectedAccount");
                                    this.handleOnSelectAccount(selectedAccount)
                                }}
                                selectedAcc={selectedAccount}
                                isMultiSelect={false}
                                listStyle={{}}
                            />
                            : <p style={{marginTop: "0.937em"}}>Selected Entity Has No Accounts.</p>
                    }
                </FormField>
            </div>
        );
    }

    _renderFooter() {
        const {selectedEntity, isFetchingAccounts} = this.state;
        return (
            <div className="action-button-container">
                <div/>
                {
                    (selectedEntity && !isFetchingAccounts) &&
                    <button
                        className="action-btn-primary"
                        onClick={this.handleFetchTransactions}
                    >View Transactions
                    </button>
                }
            </div>
        )
    }

    render() {
        const {entities, loading, systemError} = this.props;
        if (loading || systemError && systemError.show) return null;
        const {selectedEntity} = this.state;
        const hasEntities = _.size(entities) > 0;
        if (!hasEntities) return (
            <div className="page-container">
                <div className="page-main-section">
                    <NoLinkedEntitiesMessage/>
                </div>
            </div>
        );
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="flexRow" style={{width: "100%", alignItems: "flex-start"}}>
                        {this._renderEntitiesSection()}
                        <div style={{width: "100%", marginLeft: 20}}>
                            {selectedEntity && this._renderAccounts()}
                        </div>
                    </div>
                </div>
                <div className="page-footer-section" style={{width: "80%"}}>
                    {this._renderFooter()}
                </div>
            </div>
        )
    }
}

function mapStateToProps({accounts, entity, impersonation, loading, systemError}) {
    return {
        accounts: accounts && accounts.selectedEntityAccounts ? accounts.selectedEntityAccounts : [],
        entities: entity.entities ? entity.entities : [],
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading,
        systemError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({...actions, fetchHierachy, fetchAccounts, fetchAccountTransactions}, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingLandingPage);
