import _ from "lodash"
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function authRulesReducer(state = initialState.authRules, action) {
    switch (action.type) {
        case types.FETCH_AUTH_RULES_SUCCESS:
            return {...state, ...action.authRules};

        case types.SELECT_DIRECTOR_AUTH_LEVEL:
            const authorisers = _.map(state.authorisers, (a) => {
                if (a.idNumber === action.idNumber) {
                    return _.extend({}, a, {authLevel: action.authLevel})
                }
                return a
            });

            return {...state, authorisers};
        case types.ADD_AUTH_RULE:
            return {...state, rules: _.union(state.rules, [action.rule])};

        case types.SAVE_AUTH_RULES_SUCCESS:
            return {...state, saveAuthConfigSuccess: true};

        case types.SAVE_AUTH_RULES_ERROR:
            return {...state, saveAuthConfigError: action.errorMessage};

        case types.REMOVE_AUTH_RULE:
            return {...state, rules: _.filter(state.rules, (rule, i) => (i !== action.index))};

        case types.CLOSE_MESSAGE_MODAL:
            return {...state, saveAuthConfigSuccess: false, saveAuthConfigError: false};

        default:
            return state;
    }
}