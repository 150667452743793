import React, { useEffect, useState } from "react";
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import validate from 'validate.js';
import { trackData } from '../../actions/analytics';
import {saveElectronicIndemnityUsers, saveAndContinueElectronicIndemnityUsers} from "../../actions/electronicIndemnity";
import {electronicIndemnityRoutes} from '../../routers/routes'
import ButtonsComponent from "../ButtonsComponent";
import FormField from '../formField/FormField';
import axios from 'axios';
import { API_ROOT } from '../../api-config';


export const authorisedIndividual = (props) => {
    const {history, systemError } = props;
    const indemnityRequest = useSelector(state => state.electronicIndemnity);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const applicationId = useSelector(state => state.electronicIndemnity && state.electronicIndemnity.id);
    const [auIndividualList, setAuIndividualList] = useState(indemnityRequest.indemnityUser);
    // const [existingUserList, setExistingUserList] = useState({});
    const [inputAuIndividualData, setInputAuIndividualData] = useState({firstName:'', surname:'', idNumber:'', emailId:'' })
    if (systemError && systemError.show) return null;
    const tagged = useState(true);

    const ENTITY_EXISTING_USERS = API_ROOT + '/api/electronic-indemnity/' + applicationId;
    useEffect ( () => {
        axios.get(ENTITY_EXISTING_USERS).then((res)=>{
            let existingUser = res.data.data.indemnityUser;
            setAuIndividualList(existingUser);
            // setExistingUserList(existingUser);
        });
       dispatch(trackData('Electronic Indemnity', 'New Account| Authorised Individual| Step 3'));
     },[],tagged);

    const saveDetails = (e, saveAndContinue) => {
        e.preventDefault();
        if(auIndividualList.length > 0) {
            const {history} = props;
            indemnityRequest.indemnityUser = Object.values(auIndividualList);
            let electronicIndemnityRequest = indemnityRequest.indemnityUser;
            let nextPage = saveAndContinue ? '/saveapplication' :  electronicIndemnityRoutes.confirmAuthorisers;
            const onProceed = () => history.push(nextPage);
            dispatch(saveElectronicIndemnityUsers(applicationId, electronicIndemnityRequest, 'UserTask_ConfirmAPDetails', onProceed));
            history.push(nextPage);
        }
    };
    const saveAndContinue = (event) => {
        const {history} = props;
        indemnityRequest.indemnityUser = Object.values(auIndividualList);
        let electronicIndemnityRequest = indemnityRequest.indemnityUser;
        let nextPage = '/saveapplication';
        let saveAndContinue = true;
        const onProceed = () => history.push(nextPage);
        dispatch(saveAndContinueElectronicIndemnityUsers(applicationId, electronicIndemnityRequest, saveAndContinue, onProceed));
        history.push(nextPage);
    };

    const handleIndividual = (e) => {
        e.preventDefault();
        setInputAuIndividualData({...inputAuIndividualData,[e.target.name]:e.target.value})
    }
  
    const validateForm = () => {
        
        const options = {fullMessages: false};
        const constraints = {
            firstName: {presence: true, length: {minimum: 1, message: 'required'}},
            surname: {presence: true, length: {minimum: 1, message: 'required'}},
            idNumber: {presence: true, length: {minimum: 13, message: 'required'}},
            emailId: {presence: true, email: true, length: {minimum: 1, message: 'required'}}
        };

        const auIndividualErrors = validate(inputAuIndividualData,constraints, options);

        const errors = _.extend({}, {auIndividualErrors});
        setErrors(errors);
        return _.isEmpty(auIndividualErrors);
    
    };

    let {firstName, surname, idNumber, emailId} = inputAuIndividualData;
    const addIndividual = (e) => {
        e.preventDefault();
        if (validateForm()) {
            setAuIndividualList([...auIndividualList,{firstName, surname, idNumber, emailId}]);
            setInputAuIndividualData({firstName:"", surname:"", idNumber:"", emailId:""})
        }
    }

    const  numberOnly = (id) => {
        // Get element by id which passed as parameter within HTML element event
        let element = document.getElementById("id_number");
        // This removes any other character but numbers as entered by user
        element.value = element.value.replace(/[^0-9]/gi, "");
    }

    const  charOnlySurname = (id) => {
        // Get element by id which passed as parameter within HTML element event
        let element = document.getElementById("surname_charOnly");
        // This removes any other character but numbers as entered by user
        element.value = element.value.replace(/[^a-z]/gi, "");
    }

    const  charOnlyFirstname = (id) => {
        // Get element by id which passed as parameter within HTML element event
        let element = document.getElementById("first_charOnly");
        // This removes any other character but numbers as entered by user
        element.value = element.value.replace(/[^a-z]/gi, "");
    }
  

    //update auIndividual
    // const handleUpdate =(i)=>{
    //     const {firstName, surname, idNumber, emailId} = auIndividualList[i];
    //     setInputAuIndividualData({ firstName, surname, idNumber, emailId})
    // }

    //delete auIndividual
    const handleDelete = (i) => {
        const updatedauIndividualList = [...auIndividualList]
        updatedauIndividualList.splice(i,1)
        setAuIndividualList(updatedauIndividualList);
    }

    return (
        <div className="page-container">
            <div className="page-main-section">
                <div className="flexColumn">
                    <div className="card-container-form">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <p style={{ margin: '0' }} className="section-title">Persons authorised in terms of email indemnity</p>
                                {/* {
                                    (existingUserList.length > 0) ?
                                        [
                                        <div style={{textAlign:'left', color: 'firebrick'}}>
                                            <b style={{ margin: '0' }}> *Should be amendmends in existing indemnity users.</b>
                                        </div>
                                        ]: null
                                } */}
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <p style={{ color: "#ff0000", margin: '0' }}><b>Note :</b></p>
                                <b style={{ margin: '0' }}>1. Please click the ADD button to add more records. </b> <br />
                                <b style={{ margin: '0' }}>2. Please click DELETE button to delete record. </b>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <FormField className="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12" error={errors && errors.auIndividualErrors && errors.auIndividualErrors.firstName}>
                                <input type="text"  className="form-control" id="first_charOnly" onInput={charOnlyFirstname} value={inputAuIndividualData.firstName || ""} name="firstName" 
                                onChange={(event)=> handleIndividual(event)} 
                                maxLength="30"
                                required="required" placeholder="Enter name"/>
                            </FormField>
                             <FormField className="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12" error={errors && errors.auIndividualErrors && errors.auIndividualErrors.surname}>
                                <input type="text" className="form-control" maxLength="30" id="surname_charOnly" onInput={charOnlySurname} value={inputAuIndividualData.surname || ""} name="surname" onChange={(event)=> handleIndividual(event)}  required="required" placeholder="Enter surname"/>
                             </FormField>
                             <FormField className="form-group col-lg-2 col-md-2 col-sm-12 col-xs-12" error={errors && errors.auIndividualErrors && errors.auIndividualErrors.idNumber}>
                                <input type="text" onInput={numberOnly} maxLength="13" id="id_number" className="form-control" value={inputAuIndividualData.idNumber || ""}  name="idNumber" onChange={(event)=> handleIndividual(event)} required="required" placeholder="identity number"/> 
                            </FormField>
                             <FormField className="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12" error={errors && errors.auIndividualErrors && errors.auIndividualErrors.emailId}>
                                <input type="text" className="form-control" value={inputAuIndividualData.emailId || ""}  name="emailId" onChange={(event)=> handleIndividual(event)}  required="required" placeholder="Enter email"/>
                            </FormField>
                            <div className="form-group col-lg-1 col-md-1 col-sm-12 col-xs-12" >
                                <button className="btn btn-sm btn-primary" style={{width:'auto'}} onClick={addIndividual}>Add</button>
                            </div>
                        </div>
                        <hr />
                        <table className="table table-electronic" style={{width:'100%'}}>
                            <thead>
                                <th className="col-lg-3 col-md-3 col-sm-12 col-xs-12 individual-td">Name</th>
                                <th className="col-lg-3 col-md-3 col-sm-12 col-xs-12 individual-td">Surname</th>
                                <th className="col-lg-2 col-md-2 col-sm-12 col-xs-12 individual-td">Identity Number</th>
                                <th className="col-lg-3 col-md-3 col-sm-12 col-xs-12 individual-td">Email</th>
                                <th className="col-lg-1 col-md-1 col-sm-12 col-xs-12 individual-td">Action</th>
                            </thead>
                        </table>
                        <div className="scrollable-div">
                            <table className="table table-electronic" style={{width:'100%'}}>
                                <tbody>
                                {
                                    auIndividualList && auIndividualList.map((auIndividual, i) => (
                                        <tr key={i}>
                                            <td className="col-lg-3 col-md-3 col-sm-12 col-xs-12 individual-td"><span>{auIndividual.firstName}</span></td>
                                            <td className="col-lg-3 col-md-3 col-sm-12 col-xs-12 individual-td"><span>{auIndividual.surname}</span></td>
                                            <td className="col-lg-2 col-md-2 col-sm-12 col-xs-12 individual-td"><span>{auIndividual.idNumber}</span></td>
                                            <td className="col-lg-3 col-md-3 col-sm-12 col-xs-12 individual-td"><code>{auIndividual.emailId}</code></td>
                                            <td className="col-lg-1 col-md-1 col-sm-12 col-xs-12 individual-td">
                                                {/* <button className="btn btn-sm btn-primary" onClick={()=>handleUpdate(i)}>Update</button> */}
                                                <button className="btn btn-sm btn-danger" style={{width:'auto'}} onClick={()=>handleDelete(i)}>Delete</button>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {
                                (auIndividualList == '') ?
                                    [
                                    <div style={{textAlign:'center', color: 'firebrick'}}>
                                        *At least one Authorised Individual should be added
                                    </div>
                                    ]: null
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-footer-section" style={{ width: "80%" }}>
                <ButtonsComponent
                    history={history}
                    onNext={saveDetails}
                    hidePrevious={false}
                    prevPage={electronicIndemnityRoutes.electronicIndemnityClauses}
                    onSaveAndContinue={saveAndContinue}
                />
            </div>
        </div>
    );
};


export default (authorisedIndividual);
