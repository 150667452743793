import React, {useEffect, useImperativeHandle, useState} from 'react';
import axios from 'axios';
import { API_ROOT } from '../../../api-config';
import _ from 'lodash';
import {DataList} from 'primereact/components/datalist/DataList';
import {useDispatch, useSelector} from 'react-redux';
import ReactLoading from 'react-loading';
import {addSignatories, clearIndiviualInfo, fetchIndiviualInfo, removeSignatory} from '../../../actions/kyc';
import NewSignatory from './NewSignatory';
import CopySignatory from './CopySignatory';
import '../../../styles/kyc.css';
import '../../../styles/Global.css';
import NewSignatorySpecimenComponent from '../../kyc/NewSignatorySpecimenComponent';
import {uploadDoc} from '../../../actions/kyc';
import validate from 'validate.js';
import Popup from '../../Popup';
import FormField from '../../formField/FormField';
import {Glyphicon, HelpBlock, OverlayTrigger, Tooltip} from 'react-bootstrap';

const ACTIONS = {
    add: 'add',
    remove: 'remove',
    copy: 'copy',
    none: 'none',
    update: 'update'
};
const signatoryActionType = {
    ADD: 'ADD'
};
const specimen_url = API_ROOT + '/api/signatories/specimen';

export const SignatoriesComponent = React.forwardRef((props, ref) => {

    const applicationId = useSelector(state => state.application && state.application.application.id);
    const individual = useSelector(state => state.individual && state.individual.individual);
    const appEntity = useSelector(state => state.application && state.application.application.legalEntity && state.application.application.legalEntity.typeOfEntity );
    const isEntityCorp = (appEntity === 'Corporate')
    const signatories = useSelector(state =>
        state.application.application.bankAccount.signatories ? state.application.application.bankAccount.signatories : []);
    const systemError = useSelector(state => state.systemError);
    const [action, setAction] = useState(ACTIONS.none);
    const [addNewRP, setAddNewRP] = useState(false);
    const [copy, setCopy] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [newRP, setNewRP] = useState({});
    const [toggleSpecimenDownloadUpload, setToggleSpecimenDownloadUpload] = useState(false);
    const dispatch = useDispatch();

    const newSignatorySpecimenRef = React.useRef(null);

      useImperativeHandle(ref, () => {
            return {
                validateSignatories: validateSignatories
            };
        });

        useEffect(() => {
                const signatoriesList = _.find(signatories, s => (s && s.signPlusAction === signatoryActionType.ADD));
                setToggleSpecimenDownloadUpload(!_.isEmpty(signatoriesList) && true);
            }, []
        );

        useEffect(() => {
                setNewRP(_.extend({}, individual, newRP));
            }, [individual]
        );

        const handleNewRPChange = ({target}, attribute, innerAttribute) => {
            let value = _.trimStart(target.value);
            if (target.type && target.type === 'number')
                value = parseInt(value, 10);
            if (innerAttribute) {
                value = _.extend({}, newRP[attribute], {[innerAttribute]: value});
            }
            setNewRP(_.extend({}, newRP, {[attribute]: value}));
            console.log(newRP);
        };

        const handleSelectChange = (event, attribute) => {
            setNewRP(_.extend({}, newRP, {[attribute]: event.value}));
        };


        const handleSelectAddress = (event, attribute) => {
            const updatedRP = _.extend({}, newRP,
            {address: _.extend({}, newRP.address , {street : event.value.street}, {suburb : event.value.suburb}, {province : event.value.province}, {postalCode : event.value.postalCode}, {city : event.value.city})});
            setNewRP(updatedRP);
            console.log(newRP);


        };

        const onHandleUpload = (base64, inProgress, onComplete) => {

             errors && errors.signatureImageB64 && resetError("signatureImageB64");
             const trimmedBase64 = base64.split(",")[1];
             const updatedRP = _.extend({}, newRP, {signatureImageB64: trimmedBase64});
             setNewRP(updatedRP);
             const handleOnSuccess = (signatureImageB64) => {
                onSuccess && onSuccess(signatureImageB64);
            };
            console.log(newRP);
            dispatch(uploadDoc(base64, handleOnSuccess, null, inProgress, onComplete));
        };


        const onHandleRemoveDocument = () => {

            const updatedRP={...newRP, signatureImageB64: null};
            setNewRP(updatedRP);
        };

        const handleCopyClick = () => {
            setAddNewRP(false);
            setCopy(true);
            setAction(ACTIONS.add);
            setNewRP(_.extend({}, individual, newRP));
        };

        const handleAddNewRPClick = () => {
            setAddNewRP(true);
            setCopy(false);
            setAction(ACTIONS.add);
            setNewRP(_.extend({}, {newRP :{}}, {signPlusAction: signatoryActionType.ADD}));
        };

        const removeSelectedSignatory = (selected) => {
            loadingStart();
            const onComplete = (success = false) => {
                loadingFinish();
                if (success) {
                    const filteredSignatories = _.filter(signatories, s => (s.idNumber !== selected.idNumber));
                    const isFound = _.find(filteredSignatories, signa => (signa && signa.signPlusAction === signatoryActionType.ADD));
                    setToggleSpecimenDownloadUpload(!!isFound);
                }
            };
            dispatch(removeSignatory(applicationId, selected, onComplete));
        };

        const resetError = (attribute) => {
            setErrors(_.extend({}, errors, {[attribute]: null}));
        };

        const validateSignatories = () => {

            const options = {fullMessages: false};
            const constraints = {signatories: {presence: true}};
            const errors = validate({signatories: _.size(signatories) > 0 ? signatories : null}, constraints, options);

            let isNewSignatorySpecimenValid = true;
            if (toggleSpecimenDownloadUpload && !isEntityCorp) {
                isNewSignatorySpecimenValid = newSignatorySpecimenRef.current && newSignatorySpecimenRef.current.validateDocuments();
            }

            setErrors(errors);
            return _.isEmpty(errors) && isNewSignatorySpecimenValid;
        };

        const validateForm = () => {
            const options = {fullMessages: false};

            const checkAddress = {...newRP};
            console.log(checkAddress.address);

            const constraints = {
                firstName: {presence: true, length: {minimum: 1, message: 'required'}},
                lastName: {presence: true, length: {minimum: 1, message: 'required'}},
                idNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                contactNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                instruction: {presence: true, length: {minimum: 1, message: 'required'}},
                ...(isEntityCorp
                ? { signatureImageB64: {presence: true}}
                : 
                { emailAddress: {presence: true, email: true, length: {minimum: 1, message: 'required'}},
                    signingLimits: { presence: true, numericality: { onlyInteger: true } } }
                )

            };

            if (checkAddress.address && Object.keys(checkAddress.address).length === 0) {
                constraints.address = { presence: true, length: { minimum: 1, message: "Can't Be Blank" } };
            }

            let errors = validate(newRP, constraints, options);

            errors = _.extend({}, errors);

            setErrors(errors);
            return _.isEmpty(errors);
        };

        const onNewRPSubmit = () => {
            if (validateForm()) {
                loadingStart();
                const onComplete = () => loadingFinish();
                dispatch(addSignatories(applicationId, [newRP], onComplete));
                dispatch(clearIndiviualInfo());

                _.defer(() => {
                    setAddNewRP(false);
                    setNewRP({});
                    setToggleSpecimenDownloadUpload(true);
                });
            }
        };

        const onIndividualSearch = (onComplete) => {
            loadingStart();
        
            const onFinish = (success, data) => {
                loadingFinish();
                if (success) {
                    setNewRP((prevNewRP) => ({
                        ...prevNewRP,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        idNumber: data.idNumber,
                        telephones: data.telephones || [], // Assuming telephon is an array
                        // contactNumber: data.telephon && data.telephon.length > 0 ? data.telephon[0] : "", // Set contactNumber to the first telephone number, or an empty string if the array is empty
                        addresses: data.addresses
                        // Add other properties as needed
                    }));
                }
                onComplete && onComplete();
            };
        
            const onError = () => {
                loadingFinish();
                // Assuming setErrors is defined somewhere else.
                setErrors({ idNumber: ['Individual not found on search.'] });
            };
        
            // Assuming "dispatch" is passed as a prop to the component
            dispatch(fetchIndiviualInfo(newRP.idNumber, onFinish, null, onError));
        };
        
        

        const loadingStart = () => {
            setIsLoading(true);
        };

        const loadingFinish = () => {
            setIsLoading(false);
        };

        const onCopySubmit = () => {
            setCopy(false);
            setNewRP({});
        };

        const onHandleCancel = () => {
            dispatch(clearIndiviualInfo());
            _.defer(() => {
                setAddNewRP(false);
                setErrors({});
                setNewRP({});
            });
        };

        const onHandleCancelCopy = () => {
            setCopy(false);
        };

    const renderSignatory = (signatory) => {
        if (!signatory) return null;
        return (
            <div>
                <div className="flexRow" style={{padding: 0}}>
                    <div className="col-md-3 col-sm-3 col-xs-3" style={{padding: 0}}>
                        <label>{`${signatory.firstName} ${signatory.lastName}`}</label>
                    </div>
                    <div className="col-md-8 col-sm-8 col-xs-8" style={{padding: 0}}>
                        <label>{signatory.instruction}</label>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1" style={{padding: 0}}>
                        <a
                            className="btn"
                            role="button"
                            id="remove-btn"
                            onClick={() => removeSelectedSignatory(signatory)}>
                            <i className="fa fa-times" aria-hidden="true"/>
                        </a>
                    </div>
                </div>
                <hr/>
            </div>);
    };

    const renderSignatories = () => {
        const hasSignatories = _.size(signatories) > 0;
        if (!hasSignatories) return null;
        return (
            <div>
                <div className="row">
                    <div className="col-md-3 col-sm-3 col-xs-3">
                        <b>Name</b>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-3">
                        <b>Instruction</b>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-3"/>
                </div>
                <DataList
                    className="ui-datalist-nobullets"
                    itemTemplate={renderSignatory.bind(this)}
                    paginator={true}
                    rows={6}
                    value={signatories}
                />
            </div>
        );
    };

    const statementTooltip = (
        <Tooltip id="tooltip">
            Click here to copy signatory/ies from the other existing accounts.
        </Tooltip>
    );

    return (
            <div className="flexColumn">
                <div className="card-container-form" style={{minWidth: '22em'}}>
                    <div className="section-title">
                        Transactional Signatories
                        <br/>
                        <HelpBlock>
                            <small>
                                Signatories can sign checks and withdraw or deposit funds against the account.
                            </small>
                        </HelpBlock>
                    </div>

                    {renderSignatories()}

                    {
                        !addNewRP &&
                        <FormField
                            className="form-group"
                            error={errors && errors.signatories ? ['At least One Signatory Should Be Added'] : ''}>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                <a className="btn btn-secondary addButton"
                                   onClick={() => {
                                       errors && errors.signatories && resetError('signatories');
                                       handleAddNewRPClick();
                                   }}
                                >Add Signatory</a>
                                <a className="btn btn-secondary addButton"
                                   onClick={handleCopyClick}
                                >Copy signatory&nbsp;
                                    <OverlayTrigger placement="right" overlay={statementTooltip}>
                                        <Glyphicon glyph="question-sign"/>
                                    </OverlayTrigger>
                                </a>
                            </div>
                            {
                                toggleSpecimenDownloadUpload && !isEntityCorp &&
                                <NewSignatorySpecimenComponent ref={newSignatorySpecimenRef}/>
                            }
                            {errors && errors.signatories && <br/>}
                        </FormField>
                    }
                {
                    addNewRP &&
                    <Popup onClose={onHandleCancel}>
                        <NewSignatory
                            cancel={onHandleCancel}
                            errors={errors}
                            handleNewRPChange={handleNewRPChange}
                            handleSelectChange={handleSelectChange}
                            handleSelectAddress={handleSelectAddress}
                            newRP={newRP}
                            onNewRPSubmit={onNewRPSubmit}
                            onIndividualSearch={onIndividualSearch}
                            onResetError={resetError}
                            appEntity = {appEntity}
                            onHandleUpload ={onHandleUpload}
                            onHandleRemoveDocument ={onHandleRemoveDocument}
                        />
                        {
                            isLoading &&
                            <div className="inner-spinner-container">
                                <ReactLoading type="spokes" color="#444"/>
                            </div>
                        }
                    </Popup>
                }

                {
                    copy &&
                    <Popup onClose={onHandleCancelCopy}>
                        <CopySignatory
                            onCopySubmit={onCopySubmit}
                            onLoadingFinish={loadingFinish}
                            onLoadingStart={loadingStart}
                        />
                    </Popup>

                }
                {
                    isLoading &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
            </div>
        </div>
    );
});


export default SignatoriesComponent;
