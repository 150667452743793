import React, {useState} from 'react';
import _ from 'lodash';
import {useDispatch} from 'react-redux';
import ReactLoading from 'react-loading';

const REPORT_TYPES = {
    ACCOUNT_SIGNATORIES: 'ACCOUNT_SIGNATORIES',
    APPLICATION_STATUS: 'APPLICATION_STATUS',
    CONFIRMATION_LETTER: 'CONFIRMATION_LETTER'
};
const Status = {
    REJECTED: 'REJECTED',
    PENDING: 'PENDING_APPROVAL',
    CONCLUDED: 'CONCLUDED',
    OPEN: 'OPEN'
};
const STATUS_FORMATTED = {
    'REJECTED': 'Rejected',
    'PENDING_APPROVAL': 'Pending Approval',
    'CONCLUDED': 'Concluded',
    'OPEN': 'Open'
};

export const Application = (props) => {

   
    const [isDownloading, setIsDownloading] = useState(false);
    const dispatch = useDispatch();


    const downloadReport = (e, reportName, requestBody) => {
        e.preventDefault();
        const {onDownloadReport} = props;
        setIsDownloading(true);
        const onComplete = () => setIsDownloading(false);
        dispatch(onDownloadReport(reportName, requestBody, onComplete));
    };

    const continueApp = (e, applicationId, applicationType) => {
        e.preventDefault();
        const {onContinue} = props;
        setIsDownloading(true);
        const onFinish = () => setIsDownloading(false);
        onContinue && onContinue(e, applicationId, applicationType, onFinish);
    };

    const renderHeader = () => {
        const {title} = props;
        return (
            <div>
                <div className="product-heading">
                    {title}
                </div>
                <div className="title-gradient"/>
            </div>

        );
    };

    const renderMainContent = () => {
        const {application, signatoriesApplications} = props;
        const applicationStatus = application && application.applicationStatus;
        const legalEntity = application && application.legalEntity;
        const showEntity = legalEntity && (legalEntity.registeredName || legalEntity.registeredNumber);

        // for signatories application
        const filteredArray = signatoriesApplications.filter((element) => {if (element.id == application.id){ return element;}});
        const filteredItem = filteredArray.find(element => element.id);
        
        // let maintenanceReq, signatoriesInstructions;
        // if (filteredArray.length > 0) {        
        //     maintenanceReq = filteredItem.maintenanceRequest;
        //     if (maintenanceReq) {
        //         signatoriesInstructions = maintenanceReq.instructions;
        //         console.log(signatoriesInstructions);
        //     }
        // }

        let maintenanceReq, signatoriesInstructions, actionText, signatoryText;

        if (filteredArray.length > 0) {
            maintenanceReq = filteredItem.maintenanceRequest;
            if (maintenanceReq) {
                signatoriesInstructions = maintenanceReq.instructions;
                // console.log(signatoriesInstructions);
            }
            actionText = filteredItem.maintenanceRequest.action;
            // Define a mapping object for actions
            const actionTextMap = {
                ADD: 'Add Signatory',
                DELETE: 'Delete Signatory',
                COPIED: 'Copied Signatory'
            };
            // Use the mapping to get the signatoryText
            signatoryText = actionTextMap[actionText] || 'Signatory Modified';
        }

           
        return (
            <div style={{width: '100%'}}>
                {
                    <div className="flexRow" style={{justifyContent: 'space-between', marginTop: '1em'}}>
                        {
                            !showEntity ?
                                <div/>
                                :
                                <div>
                                    <p style={{
                                        fontSize: '1.24em',
                                        color: '#0A2240',
                                        marginBottom: 3
                                    }}>{(legalEntity.registeredName || legalEntity.registeredNumber)}</p>
                                    <p style={{
                                        color: '#858D9D',
                                        textTransform: 'uppercase',
                                        letterSpacing: '1px',
                                        fontSize: 9
                                    }}>Entity</p>
                                </div>
                        }
                        <div style={{textAlign: 'right'}}>
                            <p style={{fontSize: '1.24em', color: '#0A2240', marginBottom: 3}}>{application.id}</p>
                            <p style={{
                                color: '#858D9D',
                                textTransform: 'uppercase',
                                letterSpacing: '1px',
                                fontSize: 9
                            }}>Application Reference</p>
                        </div>
                    </div>
                }

                <div className="flexRow" style={{justifyContent: 'space-between', marginTop: '1em'}}>
                    <div>
                        <p style={{
                            fontSize: '1.24em',
                            color: '#0A2240',
                            marginBottom: 3
                        }}>{STATUS_FORMATTED[applicationStatus && applicationStatus.applicationOutcome]}</p>
                        <p style={{
                            color: '#858D9D',
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                            fontSize: 9
                        }}>STATUS</p>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <p style={{fontSize: '1.24em', color: '#0A2240', marginBottom: 3}}>{application.modifiedAt}</p>
                        <p style={{
                            color: '#858D9D',
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                            fontSize: 9
                        }}>Last Update</p>
                    </div>
                </div>



                {filteredArray.length > 0 && (
                    <div>
                        <p style={{
                            fontSize: '1.24em',
                            color: '#0A2240',
                            marginBottom: 3
                        }}>{signatoryText}</p>
                        <p style={{
                            color: '#858D9D',
                            textTransform: 'uppercase',
                            letterSpacing: '1px',
                            fontSize: 9
                        }}>Action Type</p>
                        <div>
                          
                            
                           
                           {signatoriesInstructions && signatoriesInstructions.length > 0 && (
                                <div>
                                    <ul style={{paddingLeft:'0px', marginBottom:'5px'}}>
                                        {signatoriesInstructions.map((signatoriesInstruction, index) => (
                                            <li key={index} >
                                                <span style={{
                                                    color: '#0a2240',
                                                    textTransform: 'uppercase',
                                                    letterSpacing: '1px',
                                                    fontSize: 12
                                                }}>Acc. Number - <b>{signatoriesInstruction.account.accountNumber}</b></span>
                                                <span style={{marginLeft:'10px', marginRight:'10px'}}> || </span>
                                                <span style={{
                                                    color: '#0a2240',
                                                    textTransform: 'uppercase',
                                                    letterSpacing: '1px',
                                                    fontSize: 12
                                                }}>ID Number - <b>{signatoriesInstruction.idOrPassportNumber}</b></span>
                                            </li>
                                        ))}
                                    </ul>
                                    <p style={{
                                    color: '#858D9D',
                                    textTransform: 'uppercase',
                                    letterSpacing: '1px',
                                    fontSize: 9
                                }}>Account list</p>

                                </div>
                            )}
                                
                           

                        </div>
                    </div>
                 
                )}
    
    
                

                {/* console.log(filteredArray[0].id);  */}

                <div className="flexRow" style={{justifyContent: 'space-between', marginTop: '1em'}}>
                    <div style={{overflow: 'hidden'}}>
                        {
                            _.map(applicationStatus && applicationStatus.logs, (log, i) => (
                                <h6 key={'log-' + i} className="logs-name">{log}</h6>))
                        }
                    </div>
                </div>
            </div>
        );

    };

    const renderFooter = () => {
        const {application, onContinue} = props;
        const applicationStatus = application && application.applicationStatus;
        const isConcluded = applicationStatus && applicationStatus.applicationOutcome === Status.CONCLUDED;
        const isPending = applicationStatus && applicationStatus.applicationOutcome === Status.OPEN && applicationStatus.applicationOutcome.logs && applicationStatus.applicationOutcome.logs.contains('PENDING_APPROVAL');
        const isIncomplete = applicationStatus && applicationStatus.applicationOutcome === Status.OPEN;
        const applicationId = application && application.id;
        const legalEntity = application && application.legalEntity;
        const legalEntityId = legalEntity && legalEntity.goldTierId;
        const hide = application.type === 'EBTC' || application.type === 'KYC_REVIEW';

        return (
            <div className="action-button-container" style={{width: '100%'}}>
                {
                    (isConcluded || isPending) && hide &&
                    <a className="flat-button-primary"
                       onClick={(e) => downloadReport(e, REPORT_TYPES.APPLICATION_STATUS, {applicationId})}
                       role="button">
                        <i className="fa fa-download fa-fw"/> Application Status
                    </a>
                }

                {
                    isConcluded && hide &&
                    <a className="flat-button-primary"
                       onClick={(e) => downloadReport(e, REPORT_TYPES.ACCOUNT_SIGNATORIES, {
                           applicationId,
                           legalEntityId
                       })}
                       role="button"
                    >
                        <i className="fa fa-download fa-fw"/> Account Signatories
                    </a>
                }

                {
                    isConcluded && hide &&
                    <a className="flat-button-primary"
                       onClick={(e) => downloadReport(e, REPORT_TYPES.CONFIRMATION_LETTER, {applicationId})}
                       role="button"
                    >
                        <i className="fa fa-download fa-fw"/> Confirmation Letter
                    </a>
                }

                {isIncomplete && !isPending && <div/>}

                {
                    isIncomplete &&
                    <a className="flat-button-primary"
                       onClick={(event) => continueApp(event, application.id, application.applicationType)}
                       role="button"
                    ><span>Continue With Application  <i className="fa fa-angle-right fa-lg"/> </span></a>
                }
            </div>
        );
    };

    if (!props.application) return null;

    return (
        <div className={props.styleClass}>
            <div className="flexColumn" style={{flex: 1, alignItems: 'flex-start'}}>
                {renderHeader()}
                {renderMainContent()}
                {renderFooter()}
                {
                    isDownloading &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
            </div>
        </div>
    );
};

export default Application;

