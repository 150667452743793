import React from 'react';
import _ from "lodash";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import {DataList} from "../../../node_modules/primereact/components/datalist/DataList";

import * as actions from "../../actions/bol";
import FormField from "../formField/FormField";
import Toggler from "../Toggler";
import UserAccountAccessPaths from "./UserAccountAccessPaths";
import Popup from "../Popup";


class UserAccessDetails extends React.Component {
    state = {
        errors: {},
        limitedUserAccess: false,
        showUserAccountAccessPaths: false,
        accountAccesses: []
    };

    componentDidMount(){
        const {onUpdateUser} = this.props;
        const {limitedUserAccess} = this.state;
        onUpdateUser && onUpdateUser({limitedUserAccess})
    }

    getAccessDetails = ()=>{
        const {accountAccesses, limitedUserAccess} = this.state;
        return {accountAccesses, limitedUserAccess}
    };

    handleChangeLimitedAccess = () => {
        this.setState(prevState => ({limitedUserAccess: !prevState.limitedUserAccess}));
    };


    handelAddAccessToAccount = (accAccess) => {
        const {accountAccesses} = this.state;
        const isFound = _.find(accountAccesses, o => (o.accountNumber === accAccess.accountNumber));
        let updatedAccountAccesses = [];
        if (isFound) {
            updatedAccountAccesses = _.map(accountAccesses, o => ((o.accountNumber === accAccess.accountNumber) ? accAccess : o));
        } else {
            updatedAccountAccesses = _.union(accountAccesses, [accAccess]);
        }
        this.setState({accountAccesses: updatedAccountAccesses});
        this.handelToggleUserAccountAccessPaths()
    };

    _renderUserAccesesToAccountsTemplate = (access) => {
        if (!access) return null;
        return (
            <div>
                <div className="flexRow" style={{padding: 0}}>
                    <div className="col-md-4 col-sm-4 col-xs-4" style={{padding: 0}}>
                        <label>{`${access.accountNumber}`}</label>
                    </div>
                    <div className="col-md-7 col-sm-7 col-xs-7" style={{padding: 0}}>
                        <label>
                            {access.level && <span className="greyText"> {`Level: ${access.level} | `}</span>}
                            {access.limit && <span className="greyText"> {`Limit: ${access.limit} | `}</span>}
                            {access.balanceStatements && <span className="greyText"> Balances And Statements | </span>}
                            {access.interAccountTransfers &&
                            <span className="greyText">  Inter-Account Transfers |</span>}
                            {access.paymentsAndCollections && <span className="greyText"> Payments/Collections</span>}
                        </label>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1" style={{padding: 0}}>
                        <a
                            className="btn"
                            role="button"
                            id="remove-btn"
                            onClick={() => this.handleRemoveProfileAccountsOperators(access)}>
                            <i className="fa fa-times" aria-hidden="true"/>
                        </a>
                    </div>
                </div>
                <hr/>
            </div>
        );
    };

    _renderAccountUserAccess() {
        const {accountAccesses} = this.state;
        const hasAccountAccesses = _.size(accountAccesses) > 0;
        if (!hasAccountAccesses) return null;
        return (
            <div>
                <br/>
                <div className="row">
                    <div className="col-md-4 col-sm-4 col-xs-4">
                        <label style={{color: "black"}}><b>Account</b></label>
                    </div>
                    <div className="col-md-7 col-sm-7 col-xs-7" style={{paddingLeft: 8}}>
                        <label style={{color: "black"}}><b>Access Paths</b></label>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1"/>
                </div>
                <DataList
                    className="ui-datalist-nobullets"
                    itemTemplate={this._renderUserAccesesToAccountsTemplate}
                    paginator={true}
                    rows={3}
                    value={accountAccesses}
                />
            </div>
        )
    }

    _renderUserAccesesToAccounts() {
        const {errors} = this.state;

        return (
            <div>
                {this._renderAccountUserAccess()}
                <FormField
                    className="form-group"
                    error={errors && errors.accounts ? ["At Least One Operator Should Be Added Per Account"] : ""}
                >
                    <div style={{paddingLeft: 12}}>
                        <a
                            className="btn btn-secondary addButton"
                            onClick={() => {
                                errors && errors.accounts && this.handleOnResetError("accounts");
                                this.handelToggleUserAccountAccessPaths()
                            }}
                        >Set Access To Account</a>
                    </div>
                </FormField>
            </div>
        );
    }

    handelToggleUserAccountAccessPaths = () => {
        this.setState(prevState => ({showUserAccountAccessPaths: !prevState.showUserAccountAccessPaths}))
    };

    _renderPopUps() {
        const {newBolProfile} = this.props;
        const {showUserAccountAccessPaths} = this.state;

        const bankAccountsDetails = newBolProfile && newBolProfile.bankAccountsDetails;
        const profileAccounts =  bankAccountsDetails && bankAccountsDetails.profileAccounts;
        console.log("profileAccounts =====>", profileAccounts)

        if (showUserAccountAccessPaths) {
            return (
                <Popup onClose={this.handelToggleUserAccountAccessPaths}>
                    <UserAccountAccessPaths
                        accounts={profileAccounts}
                        onSubmit={this.handelAddAccessToAccount}
                    />
                </Popup>
            )
        }
        return null;
    }

    render() {
        const {limitedUserAccess} = this.state;
        return (
            <div>
                <Toggler
                    label="Limit User Access?"
                    isOn={limitedUserAccess}
                    onToggle={(e) => this.handleChangeLimitedAccess(e)}
                />

                {limitedUserAccess && this._renderUserAccesesToAccounts()}

                {this._renderPopUps()}
            </div>
        )
    }
}

function mapStateToProps({bol}) {
    return {newBolProfile: bol && bol.newBolProfile ? bol.newBolProfile : {}}
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(UserAccessDetails);
