import React from "react";
import _ from "lodash";
import FormField from "../../formField/FormField";
import {ButtonToolbar, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';

import "../../../styles/ConfirmApplication.css";
import "../../../styles/Global.css";
import "react-datepicker/dist/react-datepicker.css";

const labelClassList = "col-xs-12 col-sm-4 col-md-4";
const inputClassList = "col-xs-12 col-sm-8 col-md-8";

export const CorrespondentComponent = (props) => {

    return (
        <div className="col-md-12" style={{minHeight: 200}}>
            <div className="form-horizontal col-md-12" style={{padding: "0 10"}}>
                <div className="col-md-12">

                    <div className="section-title col-md-12" style={{paddingBottom: 0, marginBottom: "0px"}}>
                        <span>Correspondent (Field 57)</span>
                        <hr style={{padding: 0}}/>
                    </div>

                    <div className="col-md-12 form-group">
                        <ButtonToolbar>
                            <ToggleButtonGroup
                                type="radio"
                                name="correspondentBIC"
                                value={props.instruction && props.instruction.correspondentBIC}
                                onChange={(event) => props.handleOnToggle(event, "correspondentBIC")}>
                                <ToggleButton value={true}>Have BIC</ToggleButton>
                                <ToggleButton value={false}>No BIC</ToggleButton>
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>

                    {
                        props.instruction.correspondentBIC ? (
                            <div className="col-md-12 form-group">

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="corrSwiftAddress">Swift Address</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="corrSwiftAddress" type="text" name="corrSwiftAddress"
                                                    value={props.instruction && props.instruction.corrSwiftAddress}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="corrAccountNumber">Account Number</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="corrAccountNumber" type="text" name="corrAccountNumber"
                                                    value={props.instruction && props.instruction.corrAccountNumber}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="corrClearingCode">Clearing Code</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="corrClearingCode" type="text" name="corrClearingCode"
                                                    value={props.instruction && props.instruction.corrClearingCode}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ) : (
                            <div>
                            <div className="col-md-12 form-group">

                                <div className="col-md-4">
                                        <label htmlFor="corrInstitutionName">Institution Name</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="corrInstitutionName" type="text" name="corrInstitutionName"
                                                    value={props.instruction && props.instruction.corrInstitutionName}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="corrAccountNumber">Account Number</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="corrAccountNumber" type="text" name="corrAccountNumber"
                                                    value={props.instruction && props.instruction.corrAccountNumber}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="corrAddress1">Address</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="corrAddress1" type="text" name="corrAddress1"
                                                    value={props.instruction && props.instruction.corrAddress1}
                                                    onChange={(event) => props.handleOnChange(event)} placeholder="Address Line 1"/>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="corrAddress2">&nbsp;</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="corrAddress2" type="text" name="corrAddress2"
                                                    value={props.instruction && props.instruction.corrAddress2}
                                                    onChange={(event) => props.handleOnChange(event)} placeholder="Address Line 2"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="corrAddress3">&nbsp;</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="corrAddress3" type="text" name="corrAddress3"
                                                    value={props.instruction && props.instruction.corrAddress3}
                                                    onChange={(event) => props.handleOnChange(event)} placeholder="Address Line 3"/>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        )
                    }

                </div>
            </div>
        </div>
    )

}