import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/lib/Row';
import ButtonsComponent from '../ButtonsComponent';
import ListGroup from 'react-bootstrap/lib/ListGroup';
import ListGroupItem from 'react-bootstrap/lib/ListGroupItem';
import Col from 'react-bootstrap/lib/Col';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSignatoriesForCif } from '../../actions/kyc';
import Button from 'react-bootstrap/lib/Button';
import { saveSignatoryApplication, saveAndContinueSignatoryApplication} from '../../actions/signatoryActions';
import { signatoryRoots } from '../../routers/routes';

export const DeleteSignatory = (props) => {

    const application = useSelector(state => state.signatoriesMaintenance.maintenanceRequest);
    const id = useSelector(state => state.signatoriesMaintenance.id);
    const signatories = useSelector(state => state.copies.copies && state.copies.copies.signatories);
    const accounts = useSelector(state => state.copies.copies && state.copies.copies.accounts);
    const [instructions, setInstructions] = useState(application.instructions);
    const [errors, setErrors] = useState(false);
    const dispatch = useDispatch();

    const [searchQuery, setSearchQuery] = useState('');


    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        dispatch(fetchSignatoriesForCif(application.entityDTO.cif, null));
    }, []);

    const active = (signatory) => {
        return !!_.find(instructions, (instruction) =>
            instruction.idOrPassportNumber === signatory.idOrPassportNumber &&
            instruction.account === signatory.account);
    };

    const onChoice = (signatory) => {
        const tempInstructions = instructions ? [...instructions] : [];
        tempInstructions.push(signatory);
        setInstructions(tempInstructions);
        dispatch(saveSignatoryApplication(id, tempInstructions));
    };

    const deleteInstruction = (index) => {
        const tempInst = [...instructions];
        tempInst.splice(index, 1);
        setInstructions(tempInst);
        dispatch(saveSignatoryApplication(id, tempInst));
    };

    const onSave = () => {
        const { history } = props;
        if (instructions && instructions.length > 0) {
            let nextPage = '/saveapplication';
            let saveAndContinue = true;
            const onProceed = () => history.push(nextPage);
            dispatch(saveAndContinueSignatoryApplication(id, instructions, saveAndContinue, onProceed));
        }
        else{
            setErrors(true);
        }
    };

    const onNext = () => {
        if (instructions && instructions.length > 0) {
            instructions.forEach(instruction => instruction.signPlusAction = 'DELETE');
            dispatch(saveSignatoryApplication(id, instructions));
            props.history.push(signatoryRoots.confirmAuthorizers);
        } else {
            setErrors(true);
        }
    };

    const currentSignatories = (accountNumber) => {
    const filteredAccounts = signatories.filter(signatory => (signatory.account.accountNumber === accountNumber) && !active(signatory));
    if (filteredAccounts.length === 0) {
        return (
            <small className="text-danger">Signatories not available for this account.</small>
        );
    }
    return (
            <ul className="list-group" style={{margin:0, maxHeight:'150px', overflowY:'scroll'}}>
                {filteredAccounts.map((signatory, i) =>
                    <li type={'button'} className="list-group-item list-group-item-warning"
                        style={{padding:'5px 5px', cursor:'pointer'}}
                        onClick={() => onChoice(signatory)}
                        active={active(signatory)}
                        key={i}>

                        <p style={{ textTransform: 'capitalize', margin:0}}>
                           <b>Signatory name :</b> &nbsp; {signatory.initialsOrFirstNames.toLowerCase()} {signatory.surname.toLowerCase()}
                        </p>

                        <p style={{ textTransform: 'capitalize', margin:0}}>
                            <b>Signing Arrangements:</b> &nbsp;
                            {_.isEmpty(signatory.instruction) ? 'None Specified' : signatory.instruction}
                        </p>
                    </li>
                )}
            </ul>
        );
    };

    const deleteInstructions = () => {
        return (
            <div>
                <ul className="list-group">
                    {!_.isEmpty(instructions) ? instructions.map((instruction, i) =>
                        <li type={'button'}
                            className="list-group-item"
                            style={{border:'none', padding:0}}
                            // onClick={() => onChoice(signatory)}
                            // active={active(signatory)}
                            key={i}>
                            <div className="row" style={{margin:0}}>
                                <div className="panel panel-danger" style={{ padding: '0' }}>

                                    <div className="panel-heading" style={{padding:'5px 5px'}}>
                                        <h3 className="panel-title"> 
                                            <strong style={{textTransform:'capitalize'}}>{instruction.initialsOrFirstNames.toLowerCase()} {instruction.surname.toLowerCase()}  </strong>
                                            <Button className="btn-danger btn-sm w-auto pull-right" 
                                                style={{ width: 'auto', padding: '2px 5px', position: 'relative', top:'-4px'}} 
                                                type="button" 
                                                onClick={() => deleteInstruction(i)}>
                                                <i className={'fa fa-undo'} /> Undo
                                            </Button>
                                        </h3>
                                    </div>
                                    <div className="panel-body" style={{padding:'2px 5px'}}>
                                        <div className="row" style={{margin:0}}>
                                            <p style={{margin:0}}>
                                                <b style={{ fontWeight: 'bold' }}>Remove From Account :</b>
                                                <span>{instruction.account.name} &nbsp;</span>
                                                <b className={'text-primary'}>
                                                    {instruction.account.accountNumber}
                                                </b>
                                            </p>
                                        </div>
                                        <div className="row" style={{margin:0}}>
                                            <b>Signing Arrangements: </b>
                                            {_.isEmpty(instruction.instruction) ? 'None Specified' : instruction.instruction}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ) :
                        <div className="">
                            <p className="text-info">You currently have no delete instructions.</p>
                            <p className="text-info">Please choose a signatory to delete from the list/s.</p>
                        </div>
                    }
                </ul>
            </div>
        );
    };


    const filteredAccounts = accounts
    ? accounts.filter(account => {
        const matchingSignatories = signatories.filter(signatory =>
          `${signatory.initialsOrFirstNames.toLowerCase()} ${signatory.surname.toLowerCase()}`
            .includes(searchQuery.toLowerCase())
        );
    
        return (
          matchingSignatories.length > 0 ||
          account.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          account.accountNumber.includes(searchQuery)
        );
      })
    : [];
  

    return (
        <div>
            <div style={{ marginTop: '2em' }} className={'container'}>
                <div className="page-container">
                    <div className="page-main-section">
                        <div className="container" style={{ minHeight: '50em' }}>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className='row' style={{margin:0}}>
                                        <div className="product-heading">List of Account with signatories :</div>
                                        <div className="title-gradient"></div>
                                    </div>
                                    <hr />
                                    
                                    <div className='row' style={{margin:0}} >
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search by account name and number"
                                            value={searchQuery}
                                            onChange={handleSearchQueryChange}
                                        />
                                    </div>
                                    <div className='row' style={{margin:'10px 0'}}>
                                       
                                        {filteredAccounts.length > 0 ? (
                                            filteredAccounts.map(account => (
                                                <div key={account.accountNumber} className="panel panel-info">
                                                <div className="panel-heading" style={{padding:'2px 5px'}}>
                                                    <h3 className="panel-title"> 
                                                        <strong>{account.name} - </strong>
                                                        <span className="text-primary">{account.accountNumber}</span>
                                                    </h3>
                                                </div>
                                                    <div className="panel-body" style={{padding:'2px 5px'}}>
                                                        {currentSignatories(account.accountNumber)}
                                                    </div>
                                                </div>

                                            
                                            ))
                                        ) : (
                                            <div className="">
                                                <h3 className="login_title">No matching accounts found.</h3>
                                            </div>
                                        )}


                                        {/* accounts ? accounts.map((account) =>
                                        <div key={account.accountNumber}>
                                            <p>
                                                <strong className={'text-primary'}>{account.name} {account.accountNumber}</strong>:
                                            </p>
                                            {currentSignatories(account.accountNumber)}
                                        </div>
                                         ) :
                                        <div className="">
                                            <h3 className="login_title">You currently have no Accounts.</h3>
                                            <p>You have no accounts that could have signatories to remove.</p>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div><div className="product-heading">Signatories to be deleted:</div>
                                        <div className="title-gradient"></div></div>
                                    <hr />
                                    {deleteInstructions()}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="page-footer-section" style={{ width: "80%" }}>
                        <ButtonsComponent
                            onNext={onNext}
                            onSaveAndContinue={onSave}
                            hidePrevious={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
