import React, {Component} from "react";
import _ from "lodash";
import {DataList} from "../../../../node_modules/primereact/components/datalist/DataList";
import ReactLoading from "react-loading";
import Select from 'react-select';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import validate from 'validate.js'

import * as actions from "../../../actions/kyc";
import {saveNewEntityInfo, startNewEntityOnboard} from "../../../actions/entity";
import SearchComponent from "../../SearchComponent";
import FormField from "../../formField/FormField";

import Popup from "../../Popup";
import FileUpload from "../../FileUpload";
import Toggler from "../../Toggler";
import {HelpBlock} from "react-bootstrap";

const ACTIONS = {
    add: 'add',
    copy: 'copy',
    none: 'none',
    update: 'update'
};

const DOC_TYPES = {
    proofOfId: 'Proof of Identity',
    proofOfAddress: 'Proof Of Address'
};

const AuthorisersCandidateForm = (props) => {
    const {
        errors, onChange, onResetError, onSubmit, onSearch, item, onCheckboxSelect,
        shouldDisableInput, rsaCitizen, onHandleUpload, onHandleRemoveDocument
    } = props;
    if (!item) return null;
    const phoneOptions = _.map(item.telephones, (t) => ({value: t.msisdn, label: t.msisdn}));
    const addressOptions = _.map(item.addresses, (t) => ({value: t.line1, label: t.line1}));
    const hasPhoneOptions = _.size(phoneOptions) > 0;
    const hasAddressOptions = _.size(addressOptions) > 0;

    return (
        <div className="flexColumn">
            <div className="popup-content">
                <div className="flexColumn">
                    <div className="card-container-form">
                        <FormField className="form-group" id="idNumber" error={errors && errors.idNumber}>
                            {
                                (shouldDisableInput || rsaCitizen) ?
                                    <SearchComponent
                                        label="ID Number"
                                        onChange={(event) => {
                                            errors && errors.idNumber && onResetError('idNumber');
                                            onChange(event, 'idNumber')
                                        }}
                                        onSearch={onSearch}
                                        searchValue={item.idNumber || ''}
                                    />
                                    :
                                    <div>
                                        <label>ID/ Passport Number</label>
                                        <input
                                            className="form-control"
                                            id="idNumber"
                                            onChange={(event) => {
                                                errors && errors.idNumber && onResetError('idNumber');
                                                onChange(event, 'idNumber')
                                            }}
                                            type="text"
                                            value={item.idNumber || ''}
                                        />
                                    </div>
                            }
                        </FormField>

                        <FormField className={"form-group"} id="firstname" error={errors && errors.firstName}>
                            <label>First Name</label>
                            <input
                                className="form-control"
                                disabled={shouldDisableInput || rsaCitizen}
                                id="firstName"

                                onChange={(event) => {
                                    errors && errors.firstName && onResetError('firstName');
                                    onChange(event, 'firstName')
                                }}
                                type="text"
                                value={item.firstName || ''}
                            />
                        </FormField>

                        <FormField className={"form-group"} id="lastname" error={errors && errors.lastName}>
                            <label>Surname</label>
                            <input
                                className="form-control"
                                disabled={shouldDisableInput || rsaCitizen}
                                id="lastName"
                                onChange={(event) => {
                                    errors && errors.lastName && onResetError('lastName');
                                    onChange(event, 'lastName')
                                }}
                                type="text"
                                value={item.lastName || ''}
                            />
                        </FormField>

                        <FormField className="form-group" id="position1" error={errors && errors.position}>
                            <label>Company Position</label>
                            <input
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="position"
                                onChange={(event) => {
                                    errors && errors.position && onResetError('position');
                                    onChange(event, 'position')
                                }}
                                type="text"
                                value={item.position || ''}
                            />
                        </FormField>


                        <Toggler
                            label="Principal Executive Officer?"
                            isOn={item && item.peoIndicator}
                            onToggle={() => !shouldDisableInput && onCheckboxSelect(!(item && item.peoIndicator), 'peoIndicator')}
                            isDisabled={shouldDisableInput}
                        />
                    </div>
                </div>
                <div className="flexColumn">
                    <div className="card-container-form">

                        <div className="form-group">
                            <label>Contact Number</label>
                            {
                                hasPhoneOptions ?
                                <FormField id="contact" error={errors && errors.contactNumber}>
                                    <Select
                                        menuContainerStyle={{zIndex: 999}}
                                        name="contactNumber"
                                        disabled={shouldDisableInput}
                                        onChange={(event) => onChange(event, 'contactNumber')}
                                        options={phoneOptions}
                                        value={item.contactNumber || ''}
                                    />
                                 </FormField>
                                    :
                                    <FormField id="contact" error={errors && errors.contactNumber}>
                                        <input
                                            className="form-control"
                                            disabled={shouldDisableInput}
                                            id="contactNumber"
                                            onChange={(event) => {
                                                errors && errors.contactNumber && onResetError('contactNumber');
                                                onChange(event, 'contactNumber')
                                            }}
                                            type="text"
                                            value={item.contactNumber || ''}
                                        />
                                    </FormField>
                            }
                        </div>

                        <div className="form-group">
                            <label>Address</label>
                            {
                                hasAddressOptions ?
                                <FormField id="selectedAddress" error={errors && errors.street}>
                                    <Select
                                        menuContainerStyle={{zIndex: 999}}
                                        name="address"
                                        disabled={shouldDisableInput}
                                        onChange={(event) => onChange(event, 'address', 'street')}
                                        options={addressOptions}
                                        value={item.address && item.address.street || ''}
                                    />
                                </FormField>
                                    :
                                    <FormField id="selectedAddress" error={errors && errors.street}>
                                        <input
                                            className="form-control"
                                            disabled={shouldDisableInput}
                                            id="address"
                                            onChange={(event) => {
                                                errors && errors.street && onResetError('street');
                                                onChange(event, 'address', 'street')
                                            }}
                                            type="text"
                                            value={item.address && item.address.street || ''}
                                        />
                                    </FormField>
                            }
                        </div>

                        <FormField
                            className="form-group" id="proofOfAddressDoc"
                            error={errors && errors.proofOfAddress ? ["Document required"] : null}
                        >
                           <div className={"row"} style={{ marginLeft: "0" }}>
                             <label className={"control-label"}>Proof of Address</label>
                            </div>
                                <FileUpload
                                    documentType={DOC_TYPES.proofOfAddress}
                                    onUpload={(base64, inProgress, onComplete) => {
                                        errors && errors.proofOfAddress && onResetError('proofOfAddress');
                                        onHandleUpload && onHandleUpload(base64, inProgress, onComplete, DOC_TYPES.proofOfAddress)
                                    }}
                                    onRemoveDocument={() => {
                                        errors && errors.proofOfAddress && onResetError('proofOfAddress');
                                        onHandleRemoveDocument && onHandleRemoveDocument(DOC_TYPES.proofOfAddress);
                                    }}
                                />
                        </FormField>


                        <FormField
                            className="form-group"
                            id="proofOfIdDoc"
                            error={errors && errors.proofOfId ? ["Document required"] : null}>
                            <div className={"row"} style={{ marginLeft: "0" }}>
                               <label className={"control-label"}>Proof of ID</label>
                            </div>
                            <FileUpload
                                documentType={DOC_TYPES.proofOfId}
                                onUpload={(base64, inProgress, onComplete) => {
                                    errors && errors.proofOfId && onResetError('proofOfId');
                                    onHandleUpload && onHandleUpload(base64, inProgress, onComplete, DOC_TYPES.proofOfId)
                                }}
                                onRemoveDocument={() => {
                                    errors && errors.proofOfId && onResetError('proofOfId');
                                    onHandleRemoveDocument && onHandleRemoveDocument(DOC_TYPES.proofOfId);
                                }}
                            />
                        </FormField>
                    </div>
                </div>
            </div>
            <div className="action-button-container" style={{width: "100%"}}>
                <div/>
                <button
                    className="action-btn-secondary"
                    onClick={onSubmit}
                    type="button"
                >Add
                </button>
            </div>
        </div>
    );
};

class AuthorisedComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            action: ACTIONS.none,
            errors: {},
            authorisersCandidate: _.extend({}, props.authorisersCandidate, {peoIndicator: false}),
            isAddToggled: false,
            isLoading: false,
            isRSACitizen: true,
            isSearchComplete: false,
        };

        this.handleSubmit = this._handleSubmit.bind(this);
        this.handleAddAuthorised = this._handleAddAuthorised.bind(this);
        this.handleSearch = this._handleSearch.bind(this);
        this.handleCancel = this._handleCancel.bind(this);

        this.handleChange = this._handleChange.bind(this);
        this.handleChangeSACitizen = this._handleChangeSACitizen.bind(this);
        this.loadingFinish = this._loadingFinish.bind(this);
        this.loadingStart = this._loadingStart.bind(this)
        this.onCompleteSubmit = this._onCompleteSubmit.bind(this);
        this.handleResetError = this._resetError.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.authorisersCandidate) {
            const authorisersCandidate = _.extend({}, this.state.authorisersCandidate, nextProps.authorisersCandidate);
            this.setState({authorisersCandidate});
        }

    }

    _handleChange(event, attribute, innerAttribute) {
        const {authorisersCandidate} = this.state;
        let value = event.value ? event.value : _.trimStart(event.target.value);
        if (innerAttribute) {
            value = _.extend({}, authorisersCandidate[attribute], {[innerAttribute]: value});
        }
        this.setState({authorisersCandidate: _.extend({}, authorisersCandidate, {[attribute]: value})});
    }

    handleCheckboxSelect = (isSelected, attribute, innerAttribute = null) => {
        const {authorisersCandidate} = this.state;
        let value = isSelected;
        if (innerAttribute) {
            value = _.extend({}, authorisersCandidate[attribute], {[innerAttribute]: value});
        }
        this.setState({authorisersCandidate: _.extend({}, authorisersCandidate, {[attribute]: value})});
    };


    _handleChangeSACitizen(isRSACitizen) {
        this.setState({isRSACitizen})
    }

    _handleAddAuthorised() {
        this.setState({isAddToggled: true, isRSACitizen: true});
    }

    _handleCancel() {
        this.props.actions.clearIndiviualInfo('authorisersCandidate');
        _.defer(() => this.setState({
            isAddToggled: false,
            isSearchComplete: false,
            errors: {},
            authorisersCandidate: {peoIndicator: false}
        }));
    }

    _onCompleteSubmit(isSuccess = false) {
        this.loadingFinish();
        if (isSuccess) {
            const {actions} = this.props;
            actions.clearIndiviualInfo('authorisersCandidate');
            _.defer(() => this.setState({
                isAddToggled: false,
                isSearchComplete: false,
                authorisersCandidate: {peoIndicator: false}
            }));
        }
    }

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    validateAuthorisers = () => {
        console.log('validating authorisers');
        const {newEntityDetails} = this.props;
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        const authorisers = legalEntity && legalEntity.authorisers;

        const options = {fullMessages: false};
        const constraints = {authorisers: {presence: true}};

        const errors = validate({authorisers: _.size(authorisers) > 0 ? authorisers : null}, constraints, options);
        this.setState({errors});
        return _.isEmpty(errors);
    };

    _validateForm() {
        const {authorisersCandidate} = this.state;
        const supportingDocuments = authorisersCandidate && authorisersCandidate.supportingDocuments;
        const options = {fullMessages: false};

        const constraints = {
            firstName: {presence: true, length: {minimum: 1, message: 'required'}},
            lastName: {presence: true, length: {minimum: 1, message: 'required'}},
            contactNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            idNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            position: {presence: true, length: {minimum: 1, message: 'required'}}
        };
        const addressContraints = {
            street: {presence: true, length: {minimum: 1, message: 'required'}},
        };

        const proofOfAddressContraints = {proofOfAddress: {presence: true}};

        const proofOfIdContraints = {proofOfId: {presence: true}};

        const legalEntityErrors = validate(authorisersCandidate, constraints, options);
        const addressErrors = validate(authorisersCandidate && authorisersCandidate.address, addressContraints, options);

        const proofOfAddress = _.find(supportingDocuments, doc => (doc.documentType === DOC_TYPES.proofOfAddress));

        const proofOfId = _.find(supportingDocuments, (doc => (doc.documentType === DOC_TYPES.proofOfId)));

        const proofOfAddressError = validate({proofOfAddress}, proofOfAddressContraints, options);
        const proofOfIdError = validate({proofOfId}, proofOfIdContraints, options);
        const errors = _.extend({}, legalEntityErrors, addressErrors, proofOfAddressError, proofOfIdError);

        this.setState({errors});
        return _.isEmpty(errors);
    }

    _handleSubmit() {
        if (this._validateForm()) {

            const {action, authorisersCandidate} = this.state;
            const {actions, newEntityDetails} = this.props;
            const legalEntity = newEntityDetails && newEntityDetails.legalEntity;

            this.loadingStart();

            let authorisers;
            if (action === ACTIONS.update) {
                authorisers = _.map(legalEntity && legalEntity.authorisers, a => (a.idNumber === authorisersCandidate.idNumber ? authorisersCandidate : a));
            } else {
                authorisers = _.union(legalEntity && legalEntity.authorisers, [authorisersCandidate]);
            }

            const updateLegalEntity = _.extend({}, legalEntity, {authorisers});
            const entityInfo = _.extend({}, newEntityDetails, {legalEntity: updateLegalEntity});

            actions.saveNewEntityInfo(entityInfo, null, this.onCompleteSubmit, true, false, true);
        }
    }

    _handleSearch() {
        this.setState({errors: {}})
        this.loadingStart();
        const onFinish = () => {
            this.setState({isSearchComplete: true});
            this.loadingFinish();
        }
        const onError = () => {
            this.loadingFinish();
            this.setState({errors: {idNumber: ["Individual not found from search. Check ID."]}});
        }
        this.props.actions.fetchIndiviualInfo(this.state.authorisersCandidate.idNumber, onFinish, 'authorisersCandidate', onError);
    }

    _loadingStart() {
        this.setState({isLoading: true})
    };

    _loadingFinish() {
        this.setState({isLoading: false})
    };

    handleRemoveCandidate = (authorisersCandidate) => {
        const {actions, newEntityDetails} = this.props;
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        const authorisers = _.filter(legalEntity && legalEntity.authorisers, authoriser => (authoriser.idNumber !== authorisersCandidate.idNumber));

        const updateLegalEntity = _.extend({}, legalEntity, {authorisers});
        const entityInfo = _.extend({}, newEntityDetails, {legalEntity: updateLegalEntity});

        actions.saveNewEntityInfo(entityInfo, null, null);
    };

    onHandleUpload = (base64, inProgress, onComplete, documentType) => {
        const {actions} = this.props;
        const {authorisersCandidate, errors} = this.state;
        errors && errors[documentType] && this.onResetError(documentType);

        const onSuccess = (documentId, data) => {
            const document = {
                documentType,
                verified: true,
                documentId,
                name: `${documentType}: ${authorisersCandidate.idNumber}`
            };

            const supportingDocuments = _.union(authorisersCandidate && authorisersCandidate.supportingDocuments, [document]);
            const updatedAuthorisersCandidate = _.extend({}, authorisersCandidate, {supportingDocuments});
            this.setState({authorisersCandidate: updatedAuthorisersCandidate});
        };
        actions.uploadDoc(base64, onSuccess, null, inProgress, onComplete, onSuccess);
    };

    onHandleRemoveDocument = (documentType) => {
        const {authorisersCandidate} = this.state;
        const supportingDocuments = _.filter(authorisersCandidate && authorisersCandidate.supportingDocuments, doc => (doc.documentType !== documentType));

        const updatedCandidate = _.extend({}, authorisersCandidate, {supportingDocuments});
        this.setState({authorisersCandidate: updatedCandidate});
    };

    authorisersTemplate(authorisersCandidate) {
        if (!authorisersCandidate) return null;
        return (
            <div className="row">
                <div className="col-md-3 col-sm-3 col-xs-3">
                    <label>{`${authorisersCandidate.firstName}`}</label>
                </div>
                <div className="col-md-8 col-sm-8 col-xs-8">
                    <label>{authorisersCandidate.lastName}</label>
                </div>
                <div className="col-md-1 col-sm-1 col-xs-1" style={{padding: 0}}>
                    <a
                        className="btn"
                        role="button"
                        id="remove-btn"
                        onClick={() => this.handleRemoveCandidate(authorisersCandidate)}>
                        <i className="fa fa-times" aria-hidden="true"/>
                    </a>
                </div>
            </div>);
    }

    _renderAuthorisers() {
        const {newEntityDetails} = this.props;
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        const hasAuthorisers = _.size(legalEntity && legalEntity.authorisers) > 0;

        if (!hasAuthorisers) return null;
        return (
            <div>
                <div className="row">
                    <div className="col-md-3 col-sm-3 col-xs-3">
                        <b>Name</b>
                    </div>
                    <div className="col-md-8 col-sm-8 col-xs-8">
                        <b>Surname</b>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1"/>
                </div>
                <DataList
                    className="ui-datalist-nobullets"
                    itemTemplate={this.authorisersTemplate.bind(this)}
                    paginator={true}
                    rows={3}
                    value={legalEntity.authorisers}
                />
            </div>
        )
    }

    _renderSpinner() {
        if (!this.state.isLoading) return null;
        return (
            <div className="inner-spinner-container">
                <ReactLoading type="spokes" color="#444"/>
            </div>
        )
    }

    _renderToggleAddAuthorisers() {
        const {authorisersCandidate, isAddToggled, errors, isRSACitizen, isSearchComplete} = this.state;
        if (!isAddToggled) return null;
        return (
            <Popup onClose={this.handleCancel}>
                <div>
                    <div className="product-heading">
                        Authorised Person's Details
                    </div>
                    <div className="title-gradient"/>
                </div>
                {!isSearchComplete && (
                    <Toggler
                        label="South African Citizen / Resident?"
                        isOn={isRSACitizen}
                        onToggle={this.handleChangeSACitizen}
                    />
                )}
                <AuthorisersCandidateForm
                    errors={errors}
                    item={authorisersCandidate}
                    onChange={this.handleChange}
                    onCheckboxSelect={this.handleCheckboxSelect}
                    onResetError={this.handleResetError}
                    onSubmit={this.handleSubmit}
                    onHandleUpload={this.onHandleUpload}
                    onHandleRemoveDocument={this.onHandleRemoveDocument}
                    onSearch={this.handleSearch}
                    shouldDisableInput={!(!isRSACitizen || isSearchComplete)}
                    rsaCitizen={isRSACitizen}
                />
                {this._renderSpinner()}
            </Popup>
        )
    }

    render() {
        const {errors} = this.state;
        return (
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        Authorised Persons
                        <HelpBlock>
                            <small>Directors or Mandated Officials</small>
                        </HelpBlock>
                    </div>
                    {this._renderAuthorisers()}

                    <FormField
                        className="form-group"
                        error={errors && errors.authorisers ? ["At least One Authoriser Should Be Added"] : ""}
                    >
                        <a
                            className="btn btn-secondary addButton"
                            onClick={() => {
                                errors && errors.authorisers && this.handleResetError("authorisers");
                                this.handleAddAuthorised()
                            }}
                        >Add authorised person</a>
                        {errors && errors.authorisers && <div><br/><br/></div>}
                    </FormField>

                    {this._renderToggleAddAuthorisers()}
                    {this._renderSpinner()}
                </div>
            </div>
        );
    }
}

function mapStateToProps({entity, individual}, ownProps) {
    return {
        authorisersCandidate: individual && individual.authorisersCandidate ? individual.authorisersCandidate : {},
        newEntityDetails: entity && entity.newEntityDetails ? entity && entity.newEntityDetails : []
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, saveNewEntityInfo,startNewEntityOnboard}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(AuthorisedComponent);
