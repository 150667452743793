import React, {useState} from 'react';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';

import '../../../styles/kyc.css';
import '../../../styles/Global.css';
import '../../../styles/verifyModal.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/omega/theme.css';
import {onboardNewEntity, onChangeValue, saveNewEntityInfo} from '../../../actions/entity';
import AuthorisersComponent from './AuthorisersComponent';
import RelatedPartiesComponent from './NewEntityRelatedPartiesComponent';
import EntityDocsComponent from '../../kyc/EntityDocsComponent';

import 'react-select/dist/react-select.css';
import FormField from '../../formField/FormField';
import {updateNewEntityDoc} from '../../../actions/kyc';

export const NewEntitykycPage = (props) => {

    const newEntityDetails = useSelector(state => state.entity && state.entity.newEntityDetails);
    const systemError = useSelector(state => state.systemError);

    const [errors, setErrors] = useState({});
    const legalEntity = useSelector(state => state.entity.newEntityDetails && state.entity.newEntityDetails.legalEntity);

    const entityDocsRef = React.useRef(null);
    const authorisersRef = React.useRef(null);
    const relatedPartyRef = React.useRef(null);

    const dispatch = useDispatch();


    const handleComplete = (event) => {
        console.log('checking');
        setErrors({});
        event.preventDefault();
        const isDocsValid = entityDocsRef.current && entityDocsRef.current.validateDocs();
        const isAuthorisersValid = authorisersRef.current && authorisersRef.current.validateAuthorisers();
        const isRelatedPartiesValid = relatedPartyRef.current && relatedPartyRef.current.validateRelatedParties();
        if (isDocsValid && isAuthorisersValid && isRelatedPartiesValid) {
            const {history} = props;
            const onSuccess = () => history.replace('/onboard/confirmed');
            const updateEntityDetails = _.extend({}, newEntityDetails, {status: 'confirmed'});
            dispatch(onboardNewEntity(updateEntityDetails, onSuccess));
        } else if (!isAuthorisersValid) {
            setErrors({authorisers: 'Required'});
        }
    };

    const handlePrevious = () => {
        props.history.push('/onboard/details');
    };

    const handleSaveAndContinue = () => {
        const {history} = props;
        const onProceed = () => history.replace('/');
        const updateEntityDetails = _.extend({}, newEntityDetails, {status: 'kyc'});
        dispatch(saveNewEntityInfo(updateEntityDetails, onProceed));
    };

    const renderFooter = () => {
        return (
            < div className="action-button-container">
                <button
                    className="action-btn-primary"
                    onClick={handlePrevious}
                    type="button"
                >Previous
                </button>
                <button
                    className="action-btn-secondary"
                    onClick={handleSaveAndContinue}
                    type="button"
                > Save And Continue Later
                </button>
                <button
                    className="action-btn-primary"
                    type="submit"
                    onClick={handleComplete}
                >Complete
                </button>
            </div>
        );
    };

    const onUploadComplete = (documentType, documentId) => {
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        const entityDocs = legalEntity && legalEntity.entityDocuments;
        const document = {
            documentType,
            verified: true,
            documentId,
            name: `${documentType}: ${legalEntity && legalEntity.registeredName}`
        };

        let entityDocuments;
        const isFound = _.find(entityDocs, d => (d.documentType === document.documentType));
        if (isFound) {
            entityDocuments = _.map(entityDocs, d => ((d.documentType === document.documentType) ? document : d));
        } else {
            entityDocuments = _.union(entityDocs, [document]);
        }
        const value = _.extend({}, newEntityDetails['legalEntity'], {entityDocuments});
        dispatch(onChangeValue('newEntityDetails', 'legalEntity', value));
    };

    const onHandleRemoveDocument = (documentType) => {
        const {newEntityDetails} = props;
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        const entityDocs = legalEntity && legalEntity.entityDocuments;

        const entityDocuments = _.filter(entityDocs, d => ((d.documentType !== documentType)));

        const value = _.extend({}, newEntityDetails['legalEntity'], {entityDocuments});
        dispatch(onChangeValue('newEntityDetails', 'legalEntity', value));
    };

    const handleDocDesc = (financialDoc, desc) => {
        financialDoc.description = desc;
        dispatch(updateNewEntityDoc(financialDoc));
    };

    const renderEntityDocs = () => {

        const entityDocs = legalEntity && legalEntity.entityDocuments;
        return (
            <EntityDocsComponent
                ref={entityDocsRef}
                isUpload={true}
                applicationId={null}
                entityDocs={entityDocs}
                onUploadComplete={onUploadComplete}
                onRemoveDocument={onHandleRemoveDocument}
                handleDocDesc={handleDocDesc}
            />
        );
    };


    if (systemError && systemError.show) return null;
    return (
        <div className="page-container">
            <div className="container-fluid" style={{minHeight: '50em'}}>
                <div className="col-md-4">
                    {renderEntityDocs()}
                </div>
                <div className="col-md-4">
                    <AuthorisersComponent
                        ref={authorisersRef}
                    />
                    <FormField
                        className="form-group"
                        error={errors && errors['authorisers'] ? ['Authorisers Required'] : ''}/>
                </div>
                <div className="col-md-4">
                    <RelatedPartiesComponent
                        ref={relatedPartyRef}
                    />
                </div>
            </div>
            <div className="page-footer-section">
                {renderFooter()}
            </div>
        </div>
    );

};

export default NewEntitykycPage;
