import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, FormControl, FormGroup, Nav, Navbar, NavItem, Panel, Row, Table } from 'react-bootstrap';
import { getSignatoryApplications } from '../../actions/signatoryActions';
import { signatoryRoots } from '../../routers/routes';
import { NavLink } from 'react-router-dom';

export const SignApplications = (props) => {
    const [activePill, setActivePill] = useState(1);
    const [signItems, setSignItems] = useState([]);
    const applications = useSelector(state => state.signatoriesMaintenance.applications);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(getSignatoryApplications());
    // }, []);


    const filterItems = (pillChange) => {
        setActivePill(pillChange);
        switch (pillChange) {
            case 2:
                setSignItems(signItems.filter(signItem => signItem.status === 'Approved'));
                break;
            case 3:
                setSignItems(signItems.filter(signItem => signItem.status === 'Rejected'));
                break;
            case 4:
                setSignItems(signItems.filter(signItem => signItem.status === 'Pending Approval'));
                break;
            case 1:
            default:
                setSignItems(signItems);
                break;
        }
    };

    const statusContext = (status) => {
        switch (status.toLowerCase()) {
            case 'approved':
                return 'success';
            case 'rejected':
                return 'danger';
            case 'pending approval':
                return 'warning';
            default:
                return 'info';
        }
    };

    const addSignatory = () => {
        props.history.push(signatoryRoots.addStart);
    };
    const deleteSignatory = () => {
        props.history.push(signatoryRoots.deleteStart);
    };

    return (
        <div className={'container'} style={{ marginTop: '2em', marginBottom: '2em' }}>
            {/* <Navbar>
                <Nav bsStyle="pills" activeKey={activePill} onSelect={filterItems}>
                    <NavItem eventKey={1}>
                        All Requests
                    </NavItem>
                    <NavItem eventKey={2} title="Approved Requests">
                        Approved
                    </NavItem>
                    <NavItem eventKey={3}>
                        Rejected
                    </NavItem>
                    <NavItem eventKey={4}>
                        Pending Approval
                    </NavItem>
                </Nav>
                <Navbar.Form pullLeft>
                    <FormGroup>
                        <FormControl type="text" placeholder="Search" />
                    </FormGroup>{' '}
                    <Button type="submit">Submit</Button>
                </Navbar.Form>
            </Navbar> */}

            <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-start", alignItems: "flex-start"}}>
                <div className={"product-btn"} style={{padding: '10px', margin: '10px'}}>
                    <div className="product-heading">Add Signatory </div>
                    <div className="title-gradient" />
                    <p style={{ paddingTop: '3px',  margin: 0, paddingBottom: '3px',  fontSize: '0.9em', color: '#858D9D'  }}> 
                        This Functionality Will Allow You To Add Account Signatories To Existing Accounts.
                    </p>

                    <div className="flexRow" style={{ justifyContent: 'space-between' }}>
                        <div style={{ textAlign: 'right' }}>
                            <p style={{ fontSize: '1.6em', color: '#0A2240', marginBottom: 3 }}>Anytime</p>
                            <p style={{ color: '#858D9D', textTransform: 'uppercase', letterSpacing: '1px', fontSize: 9 }}>Access</p>
                        </div>
                    </div>
                    <div style={{ flex: 1 }} />
                    <div className="flexRow" style={{ alignItems: 'flex-end', margin: 0, padding: 0 }}>
                        <div style={{ flex: 1 }} />
                        <a className="flat-button-primary" style={{ padding: 0 }}
                            onClick={addSignatory}
                            role="button"><span> Apply Now <i className="fa fa-angle-right fa-lg" /> </span>
                        </a>

                    </div>
                </div>
          
                <div className={"product-btn"} style={{padding: '10px', margin: '10px'}}>
                    <div className="product-heading"> Delete Signatory </div>
                    <div className="title-gradient" />
                    <p style={{ paddingTop: '3px',  margin: 0, paddingBottom: '3px',  fontSize: '0.9em', color: '#858D9D'  }}> 
                        This Functionality Will Allow You To Delete Account Signatories From Existing Accounts.
                    </p>

                    <div className="flexRow" style={{ justifyContent: 'space-between' }}>
                        <div style={{ textAlign: 'right' }}>
                            <p style={{ fontSize: '1.6em', color: '#0A2240', marginBottom: 3 }}>Anytime</p>
                            <p style={{ color: '#858D9D', textTransform: 'uppercase', letterSpacing: '1px', fontSize: 9 }}>Access</p>
                        </div>
                    </div>
                    <div style={{ flex: 1 }} />
                    <div className="flexRow" style={{ alignItems: 'flex-end', margin: 0, padding: 0 }}>
                        <div style={{ flex: 1 }} />
                        <a className="flat-button-primary" style={{ padding: 0 }}
                             onClick={deleteSignatory}
                            role="button"><span> Apply Now <i className="fa fa-angle-right fa-lg" /> </span>
                        </a>

                    </div>
                </div>
            </div>


            {/* <div className="action-button-container">
                <a
                    className="btn action-btn-primary"
                    onClick={addSignatory}
                >Add Signatory
                </a>
                <NavLink to='/'>
                    <button
                        className="btn action-btn-secondary"
                        type="button"
                    > Home
                    </button>
                </NavLink>
                <a
                    className="btn action-btn-primary"
                    type="button"
                    onClick={deleteSignatory}
                >
                    Delete Signatory
                </a>
            </div> */}
            {/* <div style={{ marginTop: '1em' }}>

                <div>   
                <Table striped responsive>
                    <thead>
                        <th className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{padding:0}}>Entity Name</th>
                        <th className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{padding:0}}>Date Submitted</th>
                        <th className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{padding:0}}>Amendment Type</th>
                        <th className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{padding:0}}>Status</th>
                    </thead>
                    </Table>
                </div>
                <div style={{maxHeight:'75vh', overflowY:'scroll'}}>
                    <Table striped responsive>
                        {!_.isEmpty(applications) ?
                            <tbody>
                                {applications && applications.map((item) => (
                                    <tr key={item.id}>
                                        <td className="col-lg-6 col-md-6 col-sm-12 col-xs-12">{item.maintenanceRequest.entityDTO.registeredName}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-12 col-xs-12">{item.date}</td>
                                        <td className="col-lg-2 col-md-2 col-sm-12 col-xs-12">{item.maintenanceRequest.action}</td>
                                        <td className={`col-lg-2 col-md-2 col-sm-12 col-xs-12 ${statusContext(item.applicationStatus.applicationOutcome)}`}>{item.applicationStatus.applicationOutcome}</td>
                                    </tr>
                                ))}

                            </tbody>
                            :
                            <div className="">
                                <h3 className="login_title">You currently have no signatory Applications.</h3>
                            </div>
                        }
                    </Table>
                </div>
            </div> */}

        </div>
    );
};
