import axios from 'axios';
import * as types from './actionTypes';
import {API_ROOT} from '../api-config';
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from './appLoading';
import _ from "lodash";
import {managedFundRoutes} from "../routers/routes";


const CASH_AND_CUSTODY_MANAGED_FUND_URL = API_ROOT + '/api/cash-custody-managed-fund';

const CASH_AND_CUSTODY_MANAGED_FUND_PENDING_TASKS = {
    confirmDetails:"UserTask_ConfirmEntityDetails",
    confirmProduct:"UserTask_ConfirmProduct",
    kycDocuments:"UserTask_KYC",
    accountRelations: "UserTask_CapacityType",
    beneficialDetails: "UserTask_BeneficialDetails",
    beneficialAddress: "UserTask_AddressDetails",
    contactDetails: "UserTask_ContactDetails",
    cashAccount: "UserTask_CashAccountPurpose",
    instructingParty: "UserTask_InstructingParty",
    notification: "UserTask_Notification"
};

export function startCashAndCustodyManagedFundApplication(advisorGoldtierId, application,impersonation, onProceed = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            application.advisorGoldtierId = advisorGoldtierId;
            application.impersonatedIdNumber = impersonation.identityNumber;

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.post(CASH_AND_CUSTODY_MANAGED_FUND_URL, application).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.FETCH_CASH_CUSTODY_MANAGED_FUND_DATA, data: responseData.data});
                onProceed && onProceed();
            } else {
                console.log('entity.fetchSelectedEntity.errorMessage ===> ', responseData.message);
                const hasNoApprovalRules = responseData && responseData.message && responseData.message.includes('no auth rules');
                const hasInsufficientApprovalRules = responseData && responseData.message && responseData.message.includes('Not enough');
                if (hasNoApprovalRules) {
                    onProceed && onProceed(false, 'Authorisation Rules Need To Be Configured');
                } else if (hasInsufficientApprovalRules) {
                    onProceed && onProceed(false, 'Not enough registered users as per Authorisation Rules settings. Please update the rules or ensure enough authorisers are registered');
                } else {
                    dispatch({
                        type: types.SHOW_RESPONSE_ERROR,
                        errorMessage: 'Couldn\'t retrieve the selected entity details.'
                    });
                }
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.error('entity.fetchSelectedEntity.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchSelectedCashAndCustodyManagedFundRequest(id, history, onFinish = null) {
    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(`${CASH_AND_CUSTODY_MANAGED_FUND_URL}/${id}`).then(response => response.data);

            if (responseData.success) {

                const application = responseData.data;
                dispatch({type: types.FETCH_CASH_CUSTODY_MANAGED_FUND_DATA, data: application});

                const pendingTasks = application && application.applicationStatus && application.applicationStatus.pendingTasks;

                let path = "/"
                if (_.find(pendingTasks, task => (task.name === CASH_AND_CUSTODY_MANAGED_FUND_PENDING_TASKS.confirmDetails))) {
                    path = managedFundRoutes.cashAndCustodyConfirmdetails;
                } else if (_.find(pendingTasks, task => (task.name === CASH_AND_CUSTODY_MANAGED_FUND_PENDING_TASKS.confirmProduct))) {
                    path = managedFundRoutes.cashAndCustodyConfirmProduct;
                } else if (_.find(pendingTasks, task => (task.name === CASH_AND_CUSTODY_MANAGED_FUND_PENDING_TASKS.kycDocuments))) {
                    path = managedFundRoutes.cashAndCustodyKyc;
                } else if (_.find(pendingTasks, task => (task.name === CASH_AND_CUSTODY_MANAGED_FUND_PENDING_TASKS.accountRelations))) {
                    path = managedFundRoutes.cashAndCustodyCapacityType;
                } else if (_.find(pendingTasks, task => (task.name === CASH_AND_CUSTODY_MANAGED_FUND_PENDING_TASKS.beneficialDetails))) {
                    path = managedFundRoutes.cashAndCustodyBeneficialOwnerDetails;
                } else if (_.find(pendingTasks, task => (task.name === CASH_AND_CUSTODY_MANAGED_FUND_PENDING_TASKS.beneficialAddress))) {
                    path = managedFundRoutes.cashAndCustodyAddressDetails;
                } else if (_.find(pendingTasks, task => (task.name === CASH_AND_CUSTODY_MANAGED_FUND_PENDING_TASKS.contactDetails))) {
                    path = managedFundRoutes.cashAndCustodyContactDetails;
                } else if (_.find(pendingTasks, task => (task.name === CASH_AND_CUSTODY_MANAGED_FUND_PENDING_TASKS.cashAccount))) {
                    path = managedFundRoutes.cashAndCustodyAccountPurpose;
                } else if (_.find(pendingTasks, task => (task.name === CASH_AND_CUSTODY_MANAGED_FUND_PENDING_TASKS.instructingParty))) {
                    path = managedFundRoutes.cashAndCustodyInstructingParty;
                } else if (_.find(pendingTasks, task => (task.name === CASH_AND_CUSTODY_MANAGED_FUND_PENDING_TASKS.notification))) {
                    path = managedFundRoutes.cashAndCustodyConfirmAuthorisers;
                } else {
                    path = managedFundRoutes.cashAndCustodyConfirmApplication;
                }

                history.push(path);
            } else {
                console.log("entity.fetchSelectedOnboardRequest.errorMessage ====>", responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: "Couldn't retrieve selected custody application."
                });
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function saveCashCustodyManagedFund(id, managedFundRequest, task, onComplete, email = null ) {
        return async dispatch => {
            try {

                axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
                let responseData = null;
                if(email ===null ){

                    responseData = await axios.put(CASH_AND_CUSTODY_MANAGED_FUND_URL + '/complete-task/' + id + '/' + task, managedFundRequest).then(response => response.data);

                }else{
                    responseData = await axios.put(CASH_AND_CUSTODY_MANAGED_FUND_URL + '/complete-task/' + id + '/' + task+'?email='+ email, managedFundRequest).then(response => response.data);
                }
                if (responseData.success) {
                    onComplete && onComplete(true);
                }
            } catch (error) {
                console.log("entity.fetchCustody.error ===>", error);
            }
        };
}


