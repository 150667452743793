import React from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import validate from 'validate.js'
import "../../styles/ConfirmApplication.css";
import "../../styles/Global.css";
import * as actions from '../../actions/custody';
import FormField from "../formField/FormField";
import {newCustodyAccountRoutes} from "../../routers/routes";
import ButtonsComponent from "../ButtonsComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import countries from "../../data/countries";
import Toggler from "../Toggler";


const GENDER = [
    {value: 'MALE', label: "MALE"},
    {value: 'FEMALE', label: "FEMALE"}
]


const MARKET_TYPE = [
    {value: 'individual', label: "Individual"},
    {value: 'company', label: "Private and Public Company"},
    {value: 'government', label: "Government Entity"},
    {value: 'fund', label: "Retirement Fund"},
    {value: 'trust', label: "Inter vivos Trusts"},
    {value: 'unIncorpTrust', label: "Un-Incorporated Body of Trust"}];

const TITLE = [
    {value: 'ADMIN', label: "ADMIN"},
    {value: 'ADML', label: "ADML"},
    {value: 'ADV', label: "ADV"},
    {value: 'AJ', label: "AJ"},
    {value: 'BARON', label: "BARON"},
    {value: 'BARONESS', label: "BARONESS"},
    {value: 'BRIG', label: "BRIG"},
    {value: 'BWYLE', label: "BWYLE"},
    {value: 'CAPT', label: "CAPT"},
    {value: 'CHIEF', label: "CHIEF"},
    {value: 'COL', label: "COL"},
    {value: 'COMM', label: "COMM"},
    {value: 'COUNT', label: "COUNT"},
    {value: 'COUNTESS', label: "COUNTESS"},
    {value: 'DAME', label: "DAME"},
    {value: 'DR', label: "DR"},
    {value: 'DS', label: "DS"},
    {value: 'DUCHESS', label: "DUCHESS"},
    {value: 'DUKE', label: "DUKE"},
    {value: 'EKS', label: "EKS"},
    {value: 'EST LATE', label: "EST LATE"},
    {value: 'ESTATE OF', label: "ESTATE OF"},
    {value: 'EX', label: "EX"},
    {value: 'FATHER', label: "FATHER"},
    {value: 'GEN', label: "GEN"},
    {value: 'GENMAJ', label: "GENMAJ"},
    {value: 'HON', label: "HON"},
    {value: 'HRH', label: "HRH"},
    {value: 'INSP', label: "INSP"},
    {value: 'JUDGE', label: "JUDGE"},
    {value: 'JUSTICE', label: "JUSTICE"},
    {value: 'KAPT', label: "KAPT"},
    {value: 'KOL', label: "KOL"},
    {value: 'KMDT', label: "KMDT"},
    {value: 'KONST', label: "KONST"},
    {value: 'LADY', label: "LADY"},
    {value: 'LT', label: "LT"},
    {value: 'MADAM', label: "MADAM"},
    {value: 'MAJ', label: "MAJ"},
    {value: 'MASTER', label: "MASTER"},
    {value: 'ME', label: "ME"},
    {value: 'MEJ', label: "MEJ"},
    {value: 'MESSRS', label: "MESSRS"},
    {value: 'MEV', label: "MEV"},
    {value: 'MRS', label: "MRS"},
    {value: 'MISS', label: "MISS"},
    {value: 'MNR', label: "MNR"},
    {value: 'MR', label: "MR"},
    {value: 'MS', label: "MS"},
    {value: 'PASTOR', label: "PASTOR"},
    {value: 'PRINCE', label: "PRINCE"},
    {value: 'PRINCESS', label: "PRINCESS"},
    {value: 'PROF', label: "PROF"},
    {value: 'RABBI', label: "RABBI"},
    {value: 'REV', label: "REV"},
    {value: 'SIR', label: "SIR"},
    {value: 'SISTER', label: "SISTER"},
    {value: 'THE ESTATE', label: "THE ESTATE"}]


export const KYC_OPTIONS = [
    {name: "individual", label: "0001 - RSA Citizens", value: "27"},
    {name: "individual", label: "0002 - RSA Permanent Residents", value: "28"},
    {name: "individual", label: "0003 - Emigrants", value: "29"},
    {name: "individual", label: "0004 - Foreign Citizens", value: "30"},
    {name: "company", label: "0005 - RSA Close Corporations", value: "31"},
    {name: "company", label: "0006 - RSA Companies", value: "32"},
    {name: "company", label: "0007 - Foreign Companies", value: "33"},
    {name: "government", label: "0008 - Other Legal Persons", value: "34"},
    {name: "fund", label: "0008 - Other Legal Persons", value: "34"},
    {name: "trust", label: "0009 - Trusts", value: "35"},
    {name: "unIncorpTrust", label: "0008 - Other Legal Persons", value: "34"},
]

export const CENTRAL_BANK_OPTIONS = [

    {
        marketType: "individual",
        kycType: "27",
        label: "1300 - Individuals and Unincorporated Business Enterprises",
        value: "84"
    },
    {
        marketType: "individual",
        kycType: "28",
        label: "1300 - Individuals and Unincorporated Business Enterprises",
        value: "84"
    },
    {marketType: "individual", kycType: "29", label: "0100 - Non-residents and companies", value: "65"},
    {marketType: "individual", kycType: "30", label: "0100 - Non-residents and companies", value: "65"},

    {marketType: "company", kycType: "31", label: "0510 - Long Term Insurers", value: "69"},
    {
        marketType: "company",
        kycType: "31",
        label: "0520 - Short Term Insurers (including Medical Schemes)",
        value: "70"
    },
    {
        marketType: "company",
        kycType: "31",
        label: "0730 - Financial Public Enterprises and Financial Auxiliaries",
        value: "75"
    },
    {
        marketType: "company",
        kycType: "31",
        label: "0740 - Other Finance Companies and Sundry Financial Institutions",
        value: "76"
    },
    {
        marketType: "company",
        kycType: "31",
        label: "1120 - Non-financial Public Enterprises (Corporations)",
        value: "81"
    },
    {
        marketType: "company",
        kycType: "31",
        label: "1130 - Sundry Public Sector( Agricultural control boards, Univs)",
        value: "82"
    },

    {
        marketType: "company",
        kycType: "32",
        label: "0200 - S African Reserve Bank and Corporation for Public Deposits",
        value: "66"
    },
    {marketType: "company", kycType: "32", label: "0300 - Banks, Mutual Banks, Landbank and Postbank", value: "67"},
    {marketType: "company", kycType: "32", label: "0510 - Long Term Insurers", value: "69"},
    {
        marketType: "company",
        kycType: "32",
        label: "0520 - Short Term Insurers (including Medical Schemes)",
        value: "70"
    },
    {
        marketType: "company",
        kycType: "32",
        label: "0730 - Financial Public Enterprises and Financial Auxiliaries",
        value: "75"
    },
    {
        marketType: "company",
        kycType: "32",
        label: "0740 - Other Finance Companies and Sundry Financial Institutions",
        value: "76"
    },
    {
        marketType: "company",
        kycType: "32",
        label: "1120 - Non-financial Public Enterprises (Corporations)",
        value: "81"
    },
    {
        marketType: "company",
        kycType: "32",
        label: "1130 - Sundry Public Sector( Agricultural control boards, Univs)",
        value: "82"
    },
    {
        marketType: "company",
        kycType: "32",
        label: "1400 - Nominee Companies Rgstrd in Terms of Companies Act",
        value: "85"
    },

    {marketType: "company", kycType: "33", label: "0100 - Non-residents and companies", value: "65"},
    {marketType: "company", kycType: "33", label: "0300 - Banks, Mutual Banks, Landbank and Postbank", value: "67"},
    {marketType: "company", kycType: "33", label: "0510 - Long Term Insurers", value: "69"},
    {
        marketType: "company",
        kycType: "33",
        label: "0520 - Short Term Insurers (including Medical Schemes)",
        value: "70"
    },
    {
        marketType: "company",
        kycType: "33",
        label: "0730 - Financial Public Enterprises and Financial Auxiliaries",
        value: "75"
    },
    {
        marketType: "company",
        kycType: "33",
        label: "0740 - Other Finance Companies and Sundry Financial Institutions",
        value: "76"
    },
    {
        marketType: "company",
        kycType: "33",
        label: "1120 - Non-financial Public Enterprises (Corporations)",
        value: "81"
    },
    {
        marketType: "company",
        kycType: "33",
        label: "1130 - Sundry Public Sector( Agricultural control boards, Univs)",
        value: "82"
    },
    {
        marketType: "company",
        kycType: "33",
        label: "1400 - Nominee Companies Rgstrd in Terms of Companies Act",
        value: "85"
    },

    {marketType: "fund", kycType: "34", label: "0400 - Public Investment Corporation (PIC)", value: "68"},
    {
        marketType: "fund",
        kycType: "34",
        label: "0610 - Private Pension and Provident Funds (Self-administered)",
        value: "71"
    },
    {
        marketType: "fund",
        kycType: "34",
        label: "0620 - Official Pension and Provident Funds (Transnet, Telkom)",
        value: "72"
    },
    {
        marketType: "fund",
        kycType: "34",
        label: "0800 - Central Government including Social Security Funds",
        value: "77"
    },
    {marketType: "fund", kycType: "34", label: "1110 - Government Enterprises", value: "80"},


    {
        marketType: "government",
        kycType: "34",
        label: "0200 - S African Reserve Bank and Corporation for Public Deposits",
        value: "66"
    },
    {
        marketType: "government",
        kycType: "34",
        label: "0610 - Private Pension and Provident Funds (Self-administered)",
        value: "71"
    },
    {
        marketType: "government",
        kycType: "34",
        label: "0620 - Official Pension and Provident Funds (Transnet, Telkom)",
        value: "72"
    },
    {
        marketType: "government",
        kycType: "34",
        label: "0730 - Financial Public Enterprises and Financial Auxiliaries",
        value: "75"
    },
    {
        marketType: "government",
        kycType: "34",
        label: "0740 - Other Finance Companies and Sundry Financial Institutions",
        value: "76"
    },
    {marketType: "government", kycType: "34", label: "0900 - Provincial Governance", value: "78"},
    {marketType: "government", kycType: "34", label: "1000 - Local Authorities", value: "79"},
    {marketType: "government", kycType: "34", label: "1110 - Government Enterprises", value: "80"},
    {
        marketType: "government",
        kycType: "34",
        label: "1120 - Non-financial Public Enterprises (Corporations)",
        value: "82"
    },
    {
        marketType: "government",
        kycType: "34",
        label: "1200 - Non-profit Institutions, Co-operative Societies, Close Corps",
        value: "83"
    },

    {
        marketType: "unIncorpTrust",
        kycType: "34",
        label: "1200 - Non-profit Institutions, Co-operative Societies, Close Corps",
        value: "83"
    },
    {marketType: "unIncorpTrust", kycType: "34", label: "0710 - Unit Trusts", value: "73"},
    {
        marketType: "unIncorpTrust",
        kycType: "34",
        label: "0730 - Financial Public Enterprises and Financial Auxiliaries",
        value: "75"
    },
    {
        marketType: "unIncorpTrust",
        kycType: "34",
        label: "0740 - Other Finance Companies and Sundry Financial Institutions",
        value: "76"
    },
    {
        marketType: "unIncorpTrust",
        kycType: "34",
        label: "1120 - Non-financial Public Enterprises (Corporations)",
        value: "81"
    },
    {
        marketType: "unIncorpTrust",
        kycType: "34",
        label: "1130 - Sundry Public Sector( Agricultural control boards, Univs)",
        value: "82"
    },
    {
        marketType: "unIncorpTrust",
        kycType: "34",
        label: "1200 - Non-profit Institutions, Co-operative Societies, Close Corps",
        value: "83"
    },

    {
        marketType: "trust",
        kycType: "35",
        label: "0740 - Other Finance Companies and Sundry Financial Institutions",
        value: "76"
    },
    {
        marketType: "trust",
        kycType: "35",
        label: "1300 - Individuals and Unincorporated Business Enterprises",
        value: "84"
    },

]

export const INST_SECTOR__OPTIONS = [
    {marketType: "individual", label: "04-HOUSEHOLD", value: "194"},
    {marketType: "individual", label: "02-NON FINANCIAL CORPORATE", value: "192"},
    {marketType: "company", label: "01-FINANCIAL CORPORATE", value: "191"},
    {marketType: "government", label: "03-GENERAL GOVERNMENT", value: "193"},
    {marketType: "government", label: "02-NON FINANCIAL CORPORATE", value: "192"},
    {marketType: "fund", label: "03-GENERAL GOVERNMENT", value: "193"},
    {marketType: "fund", label: "02-NON FINANCIAL CORPORATE", value: "192"},
    {marketType: "trust", label: "01-FINANCIAL CORPORATE", value: "191"},
    {marketType: "trust", label: "04-HOUSEHOLD", value: "194"},
    {marketType: "unIncorpTrust", label: "01-FINANCIAL CORPORATE", value: "191"},
    {marketType: "unIncorpTrust", label: "02-NON FINANCIAL CORPORATE", value: "192"},
    {marketType: "unIncorpTrust", label: "03-GENERAL GOVERNMENT", value: "193"},
]


const INDUSTRIAL_CLASSIFICATION = {
    agriculture: '01 : Agriculture, hunting, forestry and fishing',
    miningAndQuarrying: '02 : Mining and quarrying',
    manufacturing: '03 : Manufacturing',
    electricityGasAndWaterSupply: '04 : Electricity, gas and water supply',
    construction: '05 : Construction',
    wholesaleAndRetailTrade: '06 : Wholesale and retail trade; repair of motor vehicles, motor cycles and personal and household goods; hotels and restaurants',
    transportStorageCommunication: '07 : Transport, storage and communication',
    majorServices: '08 : Financial intermediation, insurance, real estate and business services',
    minorServices: 'Community, social and personal services',
};


class BeneficialOwnerPage extends React.Component {
    constructor(props) {

        super(props);

        this.state = {
            errors: {}
        };
        this.handleOnChange = this._handleOnChange.bind(this);
    }


    _handleOnChange({target}, attribute, innerAttribute) {

        let value = _.trimStart(target.value);

        if (innerAttribute) {
            const {newCustodyProfile} = this.props;
            value = _.extend({}, newCustodyProfile && newCustodyProfile[attribute], innerAttribute ? {[innerAttribute]: value} : value)
        }

        this.props.action.onChangeValue('beneficialOwner', attribute, value);
    }

    handleSelectChange = (event, attribute, innerAttribute) => {
        let value = event.value;
        if (innerAttribute) {
            const {newCustodyProfile} = this.props;
            value = _.extend({}, newCustodyProfile && newCustodyProfile[attribute], innerAttribute ? {[innerAttribute]: value} : value)
        }

        this.props.action.onChangeValue('beneficialOwner', attribute, value);
    };

    handleToggle(attribute) {
        const {beneficialOwner} = this.props.newCustodyProfile;
        this.props.action.onChangeValue('beneficialOwner', attribute, !beneficialOwner[attribute]);
    }

    handleDateChange(event, attribute) {
        this.props.action.onChangeValue('beneficialOwner', attribute, event);
    }


    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    validateForm = () => {

        console.log('validTING');
        const {beneficialOwner} = this.props.newCustodyProfile;
        const options = {fullMessages: false};

        const accConstraints = {
            accountName: {presence: true, length: {minimum: 1, message: 'required'}},
            securityShortName: {presence: true, length: {minimum: 1, message: 'required'}},
            registeredName: {presence: true, length: {minimum: 1, message: 'required'}},
            taxResidentCountry: {presence: true, length: {minimum: 1, message: 'required'}},
            marketType: {presence: true, length: {minimum: 1, message: 'required'}},
            internalClassification: {presence: true, length: {minimum: 1, message: 'required'}},
            centralBankClass: {presence: true, length: {minimum: 1, message: 'required'}},
            institutionalSector: {presence: true, length: {minimum: 1, message: 'required'}},
        };

        let entityConstraints;

        if (beneficialOwner.marketType !== 'individual') {
            entityConstraints = {
                countryOfIncorporation: {presence: true, length: {minimum: 1, message: 'required'}},
                nationalTaxNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                registrationNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                dateOfIncorporation: {presence: true}
            };
        } else if (beneficialOwner.marketType === 'individual') {
            entityConstraints = {
                firstName: {presence: true, length: {minimum: 1, message: 'required'}},
                surname: {presence: true, length: {minimum: 1, message: 'required'}},
                gender: {presence: true, length: {minimum: 1, message: 'required'}},
                tittle: {presence: true, length: {minimum: 1, message: 'required'}},
                countryOfBirth: {presence: true, length: {minimum: 1, message: 'required'}},
            };
        }

        const entityErrors = validate(beneficialOwner, entityConstraints, options);
        const accErrors = validate(beneficialOwner, accConstraints, options);

        const errors = _.extend({}, {entityErrors}, {accErrors});
        console.log(errors)
        this.setState({errors});
        return _.isEmpty(entityErrors) && _.isEmpty(accErrors);
    };

    saveDetails = (event) => {
        event.preventDefault();
        const {history} = this.props;
        if (this.validateForm()) {
            const onSuccess = () => history.push(newCustodyAccountRoutes.addressdetails);
            this.props.action.saveCustody(this.props.appId, this.props.newCustodyProfile, 'UserTask_BeneficialDetails', onSuccess);
        }

    };

    saveAndContinue = (event) => {
        event.preventDefault();
        const {history} = this.props;
        const onSuccess = () => history.push('/saveapplication');
        this.props.action.saveCustody(this.props.appId, this.props.newCustodyProfile, 'saveContinue', onSuccess);

    };


    handleChange = (e) => {
        const {form} = this.state;
        const emailAddress = _.trimStart(e.target.value);
        this.setState({form: _.extend({}, form, {emailAddress})});
    };


    _renderFooter() {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={this.saveDetails}
                    type="button"
                >Complete
                </button>
            </div>
        )
    }

    _renderIndividualDetails() {

        const {newCustodyProfile} = this.props;
        const {errors} = this.state;

        return (
            <div>

                <FormField className="form-group" id="firstName"
                           error={errors && errors.entityErrors && errors.entityErrors.firstName}>
                    <label htmlFor="fullLegalEntityName">First Name</label>
                    <input
                        className="form-control"
                        id="firstName"
                        onChange={(event) => {
                            this.handleOnChange(event, 'firstName')
                        }}
                        type="text"
                        value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.firstName}
                    />
                </FormField>

                <FormField className="form-group" id="surname"
                           error={errors && errors.entityErrors && errors.entityErrors.surname}>
                    <label htmlFor="fullLegalEntityName">Surname</label>
                    <input
                        className="form-control"
                        id="surname"
                        onChange={(event) => {
                            this.handleOnChange(event, 'surname')
                        }}
                        type="text"
                        value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.surname}
                    />
                </FormField>

                <FormField className="form-group" id="gender"
                           error={errors && errors.entityErrors && errors.entityErrors.gender}>
                    <label htmlFor="fullLegalEntityName">Gender</label>
                    <Select
                        name="gender"
                        onChange={(event) => {
                            this.handleSelectChange(event, 'gender')
                        }}
                        options={GENDER}
                        value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.gender ? newCustodyProfile.beneficialOwner.gender : ''}
                    />
                </FormField>

                <FormField className="form-group" id="tittle"
                           error={errors && errors.entityErrors && errors.entityErrors.tittle}>
                    <label htmlFor="tittle">Title</label>
                    <Select
                        name="tittle"
                        onChange={(event) => {
                            this.handleSelectChange(event, 'tittle')
                        }}
                        options={TITLE}
                        value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.tittle}
                    />
                </FormField>

                <FormField className="form-group" id="countryOfBirth"
                           error={errors && errors.entityErrors && errors.entityErrors.countryOfBirth}>
                    <label htmlFor="fullLegalEntityName">Country Of Birth</label>
                    <Select
                        name="tittle"
                        onChange={(event) => {
                            this.handleSelectChange(event, 'countryOfBirth')
                        }}
                        options={countries}
                        value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.countryOfBirth}
                    />
                </FormField>
                <FormField className="form-group" id="beneficialOwner"
                           error={errors && errors.entityErrors && errors.entityErrors.idNumber}>
                    <label htmlFor="fullLegalEntityName">ID/Passport Number</label>
                    <input
                        className="form-control"
                        id="beneficialOwner"
                        onChange={(event) => {
                            this.handleOnChange(event, 'idNumber')
                        }}
                        type="text"
                        value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.idNumber}
                    />
                </FormField>
                <FormField className="form-group" id="dateOfBirth"
                           error={errors && errors.entityErrors && errors.entityErrors.dateOfBirth}>
                    <label htmlFor="dateOfBirth">Date Of Birth(YYYYMMDD)&nbsp;&nbsp;</label>
                    <DatePicker
                        className="form-control"
                        selected={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.dateOfBirth && new Date(newCustodyProfile.beneficialOwner.dateOfBirth)}
                        onChange={(event) => {
                            this.handleDateChange(event, 'dateOfBirth')
                        }}
                        showYearDropdown
                        showMonthDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={30}
                        maxDate={new Date()}
                        dateFormat="yyyyMMdd"

                    />

                </FormField>

                <FormField className="form-group" id="incomeTaxNumber"
                           error={errors && errors.entityErrors && errors.entityErrors.incomeTaxNumber}>
                    <label htmlFor="fullLegalEntityName">Income Tax Number</label>
                    <input
                        className="form-control"
                        id="incomeTaxNumber"
                        onChange={(event) => {
                            this.handleOnChange(event, 'incomeTaxNumber')
                        }}
                        type="text"
                        value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.incomeTaxNumber}
                    />
                </FormField>
            </div>

        )

    }

    _renderIfClientTypeIsBrokerOrJse() {

        const {errors} = this.state;
        const {newCustodyProfile} = this.props;

        return (
            <div>
                {newCustodyProfile.accountRelations && newCustodyProfile.accountRelations.clientType == 'Broker/FSB nominee' || newCustodyProfile.accountRelations.clientType == 'JSE Member' ?

                    <div>

                        <FormField className="form-group" id="settlementAccount">
                            <label htmlFor="clientType">Broker Type</label>
                            <select
                                className="form-control"
                                id="accountNumber"
                                onChange={(event) => {
                                    this.handleOnChange(event, 'brokerType')
                                }}>
                                <option> --Please select--</option>
                                <option> Member Propriety</option>
                                <option>Member Settled</option>

                            </select>
                        </FormField>

                        <FormField className="form-group" id="brokerId"
                                   error={errors && errors.BeneficialOwner}>
                            <label htmlFor="fullLegalEntityName">Broker Code</label>
                            <input
                                className="form-control"
                                id="brokerId"
                                onChange={(event) => {
                                    this.handleOnChange(event, 'brokerId')
                                }}
                                type="text"
                            />
                        </FormField>
                    </div>
                    :
                    ""
                }

            </div>
        )
    }

    _renderIfHiportCode() {
        const {newCustodyProfile} = this.props;

        return (
             <div> {newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.hiportCode ?

                 <FormField className="form-group" id="desPortfolio">
                     <label htmlFor="desPortfolio">DAS Portfolio</label>
                     <Toggler
                         label=""
                         isOn={newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.desPortfolio}
                         onToggle={() => {
                             this.handleToggle('desPortfolio')
                         }}
                     />
                 </FormField>
                  : ""}
             </div>
        )
    }


    _renderCompanyDetails() {
        const {newCustodyProfile} = this.props;
        const {errors} = this.state;

        return (

            <div>
                <FormField className="form-group" id="registrationNumber"
                           error={errors && errors.entityErrors && errors.entityErrors.registrationNumber}>
                    <label htmlFor="fullLegalEntityName">Company Registration Number</label>
                    <input
                        className="form-control"
                        id="registrationNumber"
                        onChange={(event) => {
                            this.handleOnChange(event, 'registrationNumber')
                        }}
                        type="text"
                        value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.registrationNumber}
                    />
                </FormField>
                <FormField className="form-group" id="nationalTaxNumber"
                           error={errors && errors.entityErrors && errors.entityErrors.nationalTaxNumber}>
                    <label htmlFor="fullLegalEntityName">Company National Tax Number</label>
                    <input
                        className="form-control"
                        id="nationalTaxNumber"
                        onChange={(event) => {
                            this.handleOnChange(event, 'nationalTaxNumber')
                        }}
                        type="text"
                        value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.nationalTaxNumber}
                    />
                </FormField>
                <FormField className="form-group" id="vatNumber"
                           error={errors && errors.entityErrors && errors.entityErrors.vatNumber}>
                    <label htmlFor="fullLegalEntityName">Vat Number</label>
                    <input
                        className="form-control"
                        id="vatNumber"
                        onChange={(event) => {
                            this.handleOnChange(event, 'vatNumber')
                        }}
                        type="text"
                        value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.vatNumber}
                    />
                </FormField>
                <FormField className="form-group" id="countryOfIncorporation"
                           error={errors && errors.entityErrors && errors.entityErrors.countryOfIncorporation}>
                    <label htmlFor="fullLegalEntityName">Country Of Incorporation</label>
                    <Select
                        name="countryOfIncorporation"
                        onChange={(event) => {
                            this.handleSelectChange(event, 'countryOfIncorporation')
                        }}
                        options={countries}
                        value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.countryOfIncorporation}
                    />
                </FormField>
                <FormField className="form-group" id="dateOfIncorporation"
                           error={errors && errors.entityErrors && errors.entityErrors.dateOfIncorporation}>
                    <label htmlFor="fullLegalEntityName">Incorporation Date (YYYYMMDD)&nbsp;&nbsp; </label>


                    <DatePicker
                        className="form-control"
                        selected={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.dateOfIncorporation && new Date(newCustodyProfile.beneficialOwner.dateOfIncorporation)}
                        onChange={(event) => {
                            this.handleDateChange(event, 'dateOfIncorporation')
                        }}
                        showYearDropdown
                        showMonthDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={30}
                        maxDate={new Date()}
                        dateFormat="yyyyMMdd"
                    />
                </FormField>
            </div>
        )
    }


    render() {
        const {newCustodyProfile} = this.props;
        const {errors} = this.state;

        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="flexColumn">
                        <div className="card-container-form">
                            <FormField className="form-group" id="accountName"
                                       error={errors && errors.accErrors && errors.accErrors.accountName}>
                                <label htmlFor="accountName">Security Account Name</label>
                                <input
                                    className="form-control"
                                    id="accountName"
                                    onChange={(event) => {
                                        this.handleOnChange(event, 'accountName')
                                    }}
                                    type="text"
                                    value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.accountName}
                                />
                            </FormField>
                            <FormField className="form-group" id="securityShortName"
                                       error={errors && errors.accErrors && errors.accErrors.securityShortName}>
                                <label htmlFor="fullLegalEntityName">Security Account Short Name</label>
                                <input
                                    className="form-control"
                                    id="securityShortName"
                                    onChange={(event) => {
                                        this.handleOnChange(event, 'securityShortName')
                                    }}
                                    type="text"
                                    value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.securityShortName}
                                />
                            </FormField>
                            <FormField className="form-group" id="registeredName"
                                       error={errors && errors.accErrors && errors.accErrors.registeredName}>
                                <label htmlFor="fullLegalEntityName">Registered Name</label>
                                <input
                                    className="form-control"
                                    id="registeredName"
                                    onChange={(event) => {
                                        this.handleOnChange(event, 'registeredName')
                                    }}
                                    type="text"
                                    value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.registeredName}
                                />
                            </FormField>
                            <FormField className="form-group" id="taxResidentCountry"
                                       error={errors && errors.accErrors && errors.accErrors.taxResidentCountry}>
                                <label htmlFor="fullLegalEntityName">Tax Resident Country</label>
                                <Select
                                    name="taxResidentCountry"
                                    onChange={(event) => {
                                        this.handleSelectChange(event, 'taxResidentCountry')
                                    }}
                                    options={countries}
                                    value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.taxResidentCountry}
                                />
                            </FormField>

                            <FormField className="form-group" id="hiportCode"
                                       error={errors && errors.BeneficialOwner}>
                                <label htmlFor="fullLegalEntityName">Hi Portfolio Code</label>
                                <input
                                    className="form-control"
                                    id="hiportCode"
                                    onChange={(event) => {
                                        this.handleOnChange(event, 'hiportCode')
                                    }}
                                    type="text"
                                    value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.hiportCode}
                                />
                            </FormField>
                            {this._renderIfHiportCode()}

                            {this._renderIfClientTypeIsBrokerOrJse()}


                        </div>
                    </div>
                    <div className="flexColumn">
                        <div className="card-container-form">


                            <FormField className="form-group"
                                       error={errors && errors.accErrors && errors.accErrors.marketType ? ["One Should Be Selected"] : ""}>
                                <label htmlFor="marketType">Market Type</label>
                                <Select
                                    name="marketType"
                                    onChange={(event) => {
                                        this.handleSelectChange(event, 'marketType')
                                    }}
                                    options={MARKET_TYPE}
                                    value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.marketType ? newCustodyProfile.beneficialOwner.marketType : ''}
                                />
                            </FormField>

                            <FormField className="form-group" id="internalClassification"
                                       error={errors && errors.accErrors && errors.accErrors.internalClassification ? ["One Should Be Selected"] : ""}>
                                <label htmlFor="internalClassification">Internal Classification</label>

                                <Select
                                    id="internalClassification"
                                    onChange={(event) => {
                                        this.handleSelectChange(event, 'internalClassification')
                                    }}
                                    value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.internalClassification}
                                    options={KYC_OPTIONS.filter(e => newCustodyProfile.beneficialOwner && e.name === newCustodyProfile.beneficialOwner.marketType)}/>

                            </FormField>

                            <FormField className="form-group" id="centralBankClass"
                                       error={errors && errors.accErrors && errors.accErrors.centralBankClass ? ["One Should Be Selected"] : ""}>
                                <label htmlFor="centralBankClass">Central Bank Classification</label>

                                <Select
                                    id="centralBankClass"
                                    onChange={(event) => {
                                        this.handleSelectChange(event, 'centralBankClass')
                                    }}
                                    value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.centralBankClass}
                                    options={CENTRAL_BANK_OPTIONS.filter(e => newCustodyProfile.beneficialOwner && e.kycType === newCustodyProfile.beneficialOwner.internalClassification && e.marketType === newCustodyProfile.beneficialOwner.marketType)}/>

                            </FormField>


                            <FormField className="form-group" id="institutionalSector"
                                       error={errors && errors.accErrors && errors.accErrors.institutionalSector ? ["One Should Be Selected"] : ""}>
                                <label htmlFor="institutionalSector">Sector/Institutional Sector</label>

                                <Select
                                    id="institutionalSector"
                                    onChange={(event) => {
                                        this.handleSelectChange(event, 'institutionalSector')
                                    }}
                                    value={newCustodyProfile && newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.institutionalSector}
                                    options={INST_SECTOR__OPTIONS.filter(e => newCustodyProfile.beneficialOwner && e.marketType === newCustodyProfile.beneficialOwner.marketType)}/>

                            </FormField>
                        </div>
                    </div>
                    <div className="flexColumn">
                        <div className="card-container-form">

                            <div className="form-group">

                                {newCustodyProfile.beneficialOwner && newCustodyProfile.beneficialOwner.marketType == 'individual' ?
                                    this._renderIndividualDetails() :
                                    this._renderCompanyDetails()

                                }
                            </div>

                        </div>
                    </div>


                </div>


                <div className="page-footer-section">
                    <ButtonsComponent
                        history={this.props.history}
                        onNext={this.saveDetails}
                        onSaveAndContinue={this.saveAndContinue}
                        prevPage={newCustodyAccountRoutes.accountrelations}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps({custody}) {
    return {
        newCustodyProfile: custody && custody.custodyRequest,
        appId: custody && custody.id
    }
}

function mapDispatchToProps(dispatch) {
    return {action: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(BeneficialOwnerPage);
