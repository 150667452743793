import React from 'react';
import {bindActionCreators} from "redux";
import * as actions from "../../actions/bol";
import {clearIndiviualInfo, fetchIndiviualInfo} from "../../actions/kyc";
import connect from "react-redux/es/connect/connect";
import FormField from "../formField/FormField";
import _ from "lodash";
import {DataList} from "primereact/components/datalist/DataList";
import Popup from "../Popup";
import NaturalPersonForm from "./NaturalPersonForm";
import ReactLoading from "react-loading";
import validate from "validate.js";
import UserAccessDetails from "./UserAccessDetails";
import Alert from "react-bootstrap/lib/Alert";

const USER_CANDIDATE_INFO = "userCandidateInfo";

class UserDetails extends React.Component {
    state = {
        showUserDetailsForm: false,
        searchError : false
    };

    handleToggleUserForm = () => {
       this.setState(prevState=>({showUserDetailsForm: !prevState.showUserDetailsForm}))
    };

    validateUserDetails = () =>{
        const {newBolProfile} = this.props;
        const userDetails = newBolProfile && newBolProfile.userDetails;

        const options = {fullMessages: false};
        const constraints = {users: {presence: true}};

        const errors = validate(userDetails, constraints, options);

        this.setState({errors});
        return _.isEmpty(errors);
    };

    handleOnSearch = (formType, idNumber, onComplete = null) => {
        const {actions} = this.props;

        this.setState({isLoading: true});
        const onFinish = () => {
            this.setState({isLoading: false});
            onComplete && onComplete();
        };
        const onError = () => {
            this.setState({isLoading: false});
            // setErrors({registrationNumber: ['Individual not found on search.']});
        };
        actions.fetchIndiviualInfo(idNumber, onFinish, USER_CANDIDATE_INFO, onError);
    };

    handleOnResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    handleAddUser = (user) => {
        const {actions, newBolProfile} = this.props;
        const {errors} = this.state;
        const usersDetails = newBolProfile && newBolProfile.usersDetails;
        const accessDetails = this.UserAccessDetailsRef && this.UserAccessDetailsRef.getAccessDetails && this.UserAccessDetailsRef.getAccessDetails()

        const selectedUserDetails = _.pick(user, ["address", "contactNumber", "emailAddress", "firstName", "idNumber", "lastName"]);
        const isFound = _.find(usersDetails && usersDetails.users, o => (o.idNumber === user.idNumber));
        let users;
        if (isFound) {
            users = _.map(usersDetails && usersDetails.users, o => ((o.idNumber === user.idNumber) ? {...selectedUserDetails, ...accessDetails} : o));
        } else {
            users = _.union(usersDetails && usersDetails.users, [{...selectedUserDetails, ...accessDetails}]);
        }
        const updatedUsersDetails = _.extend({}, usersDetails, {users});

        errors && errors.users && this.handleResetError("users");
        actions.onChangeValue("newBolProfile", "usersDetails", updatedUsersDetails);
        actions.clearIndiviualInfo(USER_CANDIDATE_INFO);
        _.defer(()=>this.handleToggleUserForm());
    };

    handleRemoveUser = (user) => {
        const {actions, newBolProfile} = this.props;
        const usersDetails = newBolProfile && newBolProfile.usersDetails;
        const filteredUsers = _.filter(usersDetails && usersDetails.users, o => (o.idNumber !== user.idNumber));
        const users = _.size(filteredUsers) > 0 ? filteredUsers : null;
        const updatedUsersDetails = _.extend({}, usersDetails, {users});
        actions.onChangeValue("newBolProfile", "usersDetails", updatedUsersDetails);
    };

    _renderUsersTemplate = (person) => {
        if (!person) return null;
        return (
            <div>
                <div className="flexRow" style={{padding: 0}}>
                    <div className="col-md-3 col-sm-3 col-xs-3" style={{padding: 0}}>
                        <label>{person.firstName + ' ' + person.lastName}</label>
                    </div>
                    <div className="col-md-8 col-sm-8 col-xs-8" style={{padding: 0}}>
                        <label>{person.idNumber}</label>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1" style={{padding: 0}}>
                        <a
                            className="btn"
                            role="button"
                            id="remove-btn"
                            onClick={() => this.handleRemoveUser(person)}>
                            <i className="fa fa-times" aria-hidden="true"/>
                        </a>
                    </div>
                </div>
                <hr/>
            </div>
        );
    };

    _renderUsers() {
        const {newBolProfile} = this.props;
        const usersDetails = newBolProfile && newBolProfile.usersDetails;
        const users = usersDetails && usersDetails.users;
        const hasUsers = _.size(users) > 0;
        if (!hasUsers) return null;
        return (
            <div>
                <div className="row">
                    <div className="col-md-3 col-sm-3 col-xs-3">
                        <label style={{color: "black"}}><b>Names</b></label>
                    </div>
                    <div className="col-md-8 col-sm-8 col-xs-8" style={{paddingLeft: 8}}>
                        <label style={{color: "black"}}><b>Identity Number</b></label>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1"/>
                </div>
                <DataList
                    className="ui-datalist-nobullets"
                    itemTemplate={this._renderUsersTemplate}
                    paginator={true}
                    rows={3}
                    value={users}
                />
            </div>
        )
    }

    _renderPopUp() {
        const {actions, candidate, showDeliveryAddress} = this.props;
        const {isLoading, showUserDetailsForm, searchError} = this.state;

        if (showUserDetailsForm) {
            return (
                <Popup onClose={() => {
                    actions && actions.clearIndiviualInfo(USER_CANDIDATE_INFO);
                    this.handleToggleUserForm();
                }}>
                    <NaturalPersonForm
                        title={"User Details"}
                        onSubmit={this.handleAddUser}
                        onCancel={this.handleCancel}
                        onSearch={this.handleOnSearch}
                        person={candidate}
                        formType={"userDetailsForm"}
                        showWorkInfo={showDeliveryAddress}
                    >
                    <UserAccessDetails
                        ref={(ref) => {
                            if (ref) this.UserAccessDetailsRef = ref.getWrappedInstance()
                        }}
                    />
                    </NaturalPersonForm>
                    {
                        isLoading &&
                        <div className="inner-spinner-container">
                            <ReactLoading type="spokes" color="#444"/>
                        </div>
                    }
                    {
                        searchError &&
                        <Alert bsStyle={'danger'}>
                            Individual not found from search, check the ID Number.
                        </Alert>
                    }
                </Popup>
            )
        }
        return null;
    }

    render() {
        const {errors} = this.state;
        return (
            <div className="card-container-form">
                <div className="section-title">
                    <span>Users Details</span>
                </div>
                {this._renderUsers()}

                <FormField
                    className="form-group"
                    error={errors && errors.users ? ["At least One Operator Should Be Added"] : ""}
                >
                    <div>
                        <a
                            className="flat-button-primary"
                            onClick={() => {
                                errors && errors.users && this.handleOnResetError('users');
                                this.handleToggleUserForm();
                            }}
                        >Add User</a>
                        <br/>
                    </div>
                </FormField>

                {this._renderPopUp()}
            </div>
        )
    }
}

function mapStateToProps({bol, individual}) {
    return {
        newBolProfile: bol && bol.newBolProfile ? bol.newBolProfile : {},
        candidate: individual && individual[USER_CANDIDATE_INFO] ? individual[USER_CANDIDATE_INFO] : {},
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, clearIndiviualInfo, fetchIndiviualInfo}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(UserDetails);
