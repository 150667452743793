import {authCheck, loadingPageDataFinish, loadingPageDataStart} from './appLoading';
import axios from 'axios';
import * as types from './actionTypes';
import {API_ROOT} from '../api-config';
import _ from 'lodash';
import {businessOnlineRoutes} from '../routers/routes';

const EBTC_URL = `${API_ROOT}/api/legal-remediation`;
const ONBOARDING_URL = `${API_ROOT}/api/bol-onboard`;


const BOL_PENDING_TASKS = {
    accountDetails: 'UserTask_AccountDetails',
    accountsSetup: 'UserTask_AccountsSetup',
    operatorDetails: 'UserTask_OperatorDetails',
    contactDetails: 'UserTask_ContactInfo'
};

export function startBolOnboard(legalEntity, idNumber, onCompleted) {
    idNumber = idNumber ? idNumber : '';
    return async dispatch => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.post(
                `${ONBOARDING_URL}?idNumber=${idNumber}`, legalEntity)
                .then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.START_BOL_ONBOARD_SUCCESS, businessOnline: responseData.data});
                onCompleted && onCompleted(true);
            } else {
                console.log("entity.fetchProfilesByAccount.errorMessage ====>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Could't onboard new entity."});
            }
        } catch (e) {
            console.log("entity.startLegalRem.error ===>", e);
            authCheck(dispatch, e);
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function saveBolOnboard(id, request, task, onSuccess) {

    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.put(ONBOARDING_URL + '/complete-task/' + id + '/' + task, request).then(response => response.data);

            if (responseData.success) {
                onSuccess && onSuccess();
                dispatch({type: types.START_BOL_ONBOARD_SUCCESS, businessOnline: responseData.data});
            } else {
                console.log('entity.fetchProfilesByAccount.errorMessage ====>', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Could\'t onboard new entity.'});
            }
            loadingPageDataFinish(dispatch);
        } catch (e) {
            console.log('entity.startLegalRem.error ===>', e);
            authCheck(dispatch, e);
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function fetchSelectedBolOnboardRequest(id, history, onFinish = null) {
    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(`${ONBOARDING_URL}/${id}`).then(response => response.data);

            if (responseData.success) {

                const application = responseData.data;

                dispatch({type: types.START_BOL_ONBOARD_SUCCESS, businessOnline: application});


                const pendingTasks = application.applicationStatus && application.applicationStatus.pendingTasks;

                let path = businessOnlineRoutes.transaction;

                if (_.find(pendingTasks, task => (task.name === BOL_PENDING_TASKS.accountDetails))) {
                    path = businessOnlineRoutes.transaction;
                } else if (_.find(pendingTasks, task => (task.name === BOL_PENDING_TASKS.accountsSetup))) {
                    path = businessOnlineRoutes.accountSetup;
                }
                else if (_.find(pendingTasks, task => (task.name === BOL_PENDING_TASKS.operatorDetails))) {
                    path = businessOnlineRoutes.authorization;
                }
                else if (_.find(pendingTasks, task => (task.name === BOL_PENDING_TASKS.contactDetails))) {
                    path = businessOnlineRoutes.contactDetails;
                }
                else {
                    path = businessOnlineRoutes.complete;
                }

                history.push(path);
            } else {

                console.log('entity.fetchSelectedOnboardRequest.errorMessage ====>', responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: 'Couldn\'t retrieve selected onboard application.'
                });
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('entity.fetchCustody.error ===>', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function completeBolOnboard(id, request, onSuccess) {
    const url = `${ONBOARDING_URL}/${id}/complete`;
    console.log(request);
    console.log(JSON.stringify(request));
    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.put(
                url, request)
                .then(response => response.data);
            if (responseData.success) {
                onSuccess && onSuccess();
                dispatch({type: types.START_BOL_ONBOARD_SUCCESS, businessOnline: responseData.data});
            } else {
                console.log("entity.fetchProfilesByAccount.errorMessage ====>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Could't onboard new entity."});
            }
            loadingPageDataFinish(dispatch);
        } catch (e) {
            console.log("entity.startLegalRem.error ===>", e);
            authCheck(dispatch, e);
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function startLegalRemediation(legalEntity) {
    return async dispatch => {
        const accountNumber = '021329885';
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.post(
                EBTC_URL, {legalEntity: legalEntity})
                .then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.FETCH_BOL_SUCCESS, bolHierarchy: responseData.data});
            } else {
                console.log("entity.fetchProfilesByAccount.errorMessage ====>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Could't onboard new entity."});
            }
            loadingPageDataFinish(dispatch);
        } catch (e) {
            console.log("entity.startLegalRem.error ===>", e);
            authCheck(dispatch, e);
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function fetchProfilesByAccount(accountNumber) {
    return async dispatch => {
        accountNumber = '021329885';
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(
                `/api/bol-profiles?accounts=${accountNumber}`)
                .then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.FETCH_BOL_SUCCESS, bolHierarchy: responseData.data});
            } else {
                console.log("entity.fetchProfilesByAccount.errorMessage ====>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Could't onboard new entity."});
            }
            loadingPageDataFinish(dispatch);
        } catch (e) {
            console.log("entity.fetchProfilesByAccount.error ===>", e);
            authCheck(dispatch, e);
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function fetchBusinessOnlineDocument(id) {
    return async dispatch => {
        loadingPageDataStart(dispatch);
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const axiosResponse = await axios.get(ONBOARDING_URL + '/document/' + id).then(response => response.data);

            if (axiosResponse.success) {
                const {message} = axiosResponse;
                dispatch({type: 'ONBOARING_DOC', documentStr: message});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("ONBOARING_DOC.document.error ===> ", JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function onChangeValue(objName, attribute, value) {
    return (dispatch) => {
        dispatch({type: types.ON_BOL_VALUE_CHANGE, objName, attribute, value})
    }
}
