import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function supportReducer(state = initialState.support, action) {
    switch (action.type) {
        case types.SUPPORT_SEARCH:
            return {
                support: action.support,
                additionalProducts: action.additionalProducts,
                approvalMessages: action.approvalMessages,
                authorisedPersons: action.authorisedPersons,
                authorisationRules: action.authorisationRules,
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
}