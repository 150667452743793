import React, {useEffect, useState} from 'react';
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux';
import ReactLoading from 'react-loading'
import {
    addRule,
    getAuthRules,
    onCloseModal,
    removeRule,
    saveAuthRulesConfig,
    selectDirectorAuthLevel
} from '../../actions/rules';
import {fetchHierachy} from '../../actions/entity'
import EntityList from '../legalentity/EntityList'
import NoLinkedEntitiesMessage from "../legalentity/NoLinkedEntitiesMessage";
import AuthorisersList from './AuthorisersList'
import Rules from './Rules'
import PopUp from '../Popup'
import validate from 'validate.js'

export const ConfigureRulesPage = (props) => {

    const impersonation = useSelector(state => state.impersonation && state.impersonation.impersonation);
    const authRules = useSelector(state => state.authRules);
    const saveAuthConfigError = useSelector(state => state.authRules && state.authRules.saveAuthConfigError);
    const saveAuthConfigSuccess = useSelector(state => state.authRules && state.authRules.saveAuthConfigSuccess);
    const entities = useSelector(state => state.entity && state.entity.entities);
    const loading = useSelector(state => state.loading);

    const [isFetchingEntityConfig, setIsFetchingEntityConfig] = useState(false);
    const [isSavingAuthConfig, setIsSavingAuthConfig] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();


    useEffect(() => {
            dispatch(fetchHierachy(impersonation));
        }, []
    );


    const handleEntitySelect = (selectedEntity) => {
        setIsFetchingEntityConfig(true);
        setSelectedEntity(selectedEntity)
        const onComplete = () => setIsFetchingEntityConfig(false);
        dispatch(getAuthRules(selectedEntity && selectedEntity.goldTierId, impersonation, onComplete));
    }

    const handleSelectAuthLevel = (idNumber, authLevel) => {
        errors && errors.authorisers && handleResetError('authorisers');
        dispatch(selectDirectorAuthLevel(idNumber, authLevel));
    }

    const handleAddRule = (rule) => {
        errors && errors.rules && handleResetError('rules');
        dispatch(addRule(rule));
    }

    const handleResetError = (attribute) => {
        const errors = _.extend({}, errors, {[attribute]: null});
        setErrors(errors);
    };

    const validateConfig = () => {

        const hasValidAuthorisers = !_.find(authRules && authRules.authorisers, a => (a.authLevel === ''));
        const hasRules = _.size(authRules && authRules.rules) > 0;

        const options = {fullMessages: false};
        const constraints = {authorisers: {presence: true}, rules: {presence: true}};

        const errors = validate({
            authorisers: !hasValidAuthorisers && null,
            rules: !hasRules && null
        }, constraints, options);
        setErrors(errors);
        return _.isEmpty(errors)
    };

    const handleSaveAuthRulesConfig = () => {
        if (validateConfig()) {
            const authRulesConfig = _.pick(authRules, ['legalEntityId', 'authorisers', 'rules']);
            setIsSavingAuthConfig(true);
            const onComplete = () => setIsSavingAuthConfig(false);
            dispatch(saveAuthRulesConfig(authRulesConfig, onComplete));
        }
    }

    const handleRemoveRule = (index) => {
        dispatch(removeRule(index));
    }

    const closeModal = () => {
        dispatch(onCloseModal());
    };

    const renderSaveConfigMessage = () => {
        if (!saveAuthConfigSuccess && !saveAuthConfigError) return null;
        if (saveAuthConfigSuccess) {
            return (
                <PopUp onClose={closeModal}>
                    <div>
                        <div>
                            <div className="product-heading">
                                Successful
                            </div>
                            <div className="title-gradient"/>
                        </div>
                        <br/>
                        <p>Authorisation configuration saved.</p>
                    </div>
                </PopUp>
            )
        } else if (saveAuthConfigError) {
            return (
                <PopUp onClose={closeModal}>
                    <div>
                        <div>
                            <div className="product-heading">
                                Unsuccessful
                            </div>
                            <div className="title-gradient"/>
                        </div>
                        <br/>
                        <p>Couldn't save authorisation configuration</p>
                    </div>
                </PopUp>
            )
        }
    }

    const renderEntities = () => {
        return (
            <div style={{width: '100%'}}>
                <div>
                    <div className="product-heading">
                        Legal Entities
                    </div>
                    <div className="title-gradient"/>
                </div>
                <EntityList
                    className="card-container"
                    entities={entities}
                    onSelect={handleEntitySelect}
                    selectedEntity={selectedEntity}
                />
            </div>
        )
    }

    const renderAuthRules = () => {
        return (
            <div style={{width: '100%'}}>
                {
                    (() => {
                        if (isFetchingEntityConfig || isSavingAuthConfig) {
                            return (
                                <div className="rule-spinner">
                                    <ReactLoading type="spokes" color="#444"/>
                                </div>
                            )
                        } else if (selectedEntity && !isFetchingEntityConfig) {
                            return (
                                <div className="flexColumn" style={{alignItems: "flex-start", width: "100%"}}>
                                    <div style={{width: "100%"}}>
                                        <div className="product-heading">
                                            Authorisation Configuration
                                        </div>
                                        <div className="title-gradient"/>
                                    </div>
                                    <div style={{width: "100%"}}>
                                        <AuthorisersList
                                            error={errors && errors.authorisers && ["Please Ensure All Directors Level Are Set To A Or B"]}
                                            authorisers={authRules && authRules.authorisers}
                                            onSelect={handleSelectAuthLevel.bind(this)}
                                        />
                                        {errors && errors.authorisers && <br/>}
                                        <Rules
                                            error={errors && errors.rules && ['At Least One Rule Should Be Added.']}
                                            onAddRule={handleAddRule.bind(this)}
                                            onRemoveRule={handleRemoveRule.bind(this)}
                                            rules={authRules && authRules.rules}
                                        />
                                        <br/>
                                        <div>
                                            <button
                                                style={{marginRight: 15}}
                                                className="action-btn-primary pull-right"
                                                onClick={handleSaveAuthRulesConfig.bind(this)}
                                                type="button">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        return null
                    })()
                }
            </div>
        )
    }

    if (loading) return null;

    const hasEntities = _.size(entities) > 0;
    if (!hasEntities)

        return (
            <div className="page-container">
                <div className="page-main-section">
                    <NoLinkedEntitiesMessage/>
                </div>
            </div>
        );

    return (
        <div className="page-container">
            <div className="page-main-section">
                <div className="flexRow" style={{width: '100%', alignItems: 'flex-start'}}>
                    {renderEntities()}
                    <div style={{width: '100%', marginLeft: 20}}>
                        {renderAuthRules()}
                    </div>
                </div>
                {renderSaveConfigMessage()}
            </div>
        </div>
    );

}
export default ConfigureRulesPage;
