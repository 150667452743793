import React, {Component} from 'react';
import {browserHistory} from 'react-router';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {fetchDocument} from '../../actions/legal-remidiation'
import {Button} from "react-bootstrap";

class EbtcComplete extends Component {

    componentWillMount() {
        const {actions, remediation: {id, entity: {goldTierId}}} = this.props;
        actions.fetchDocument(id, goldTierId);
    }

    render() {
        const {remediation: {doc}} = this.props;
        return (
            <div className='container'>
                <div className="card-container-form">
                    <div className="section-title">Your application has been successfully completed</div>
                    <div className="input-group greyText fontSize">
                        <p>Thank you for completing your legal terms.</p>
                        <p>You can download a document indicating the agreements you have chosen using the link
                            below.</p>
                    </div>
                    <div>
                        <Button href={'data:application/pdf;base64,' + doc} download={"EBTC" + Date.now() + ".pdf"}>
                            Download Document
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps({entity, remediation, impersonation, loading}) {
    return {
        remediation: remediation,
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({fetchDocument}, dispatch)}
}


export default connect(mapStateToProps, mapDispatchToProps)(EbtcComplete);