import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import validate from 'validate.js'
import ButtonsComponent from "../ButtonsComponent";
import FormField from '../formField/FormField'
import { trackData } from '../../actions/analytics';
import { electronicIndemnityRoutes } from '../../routers/routes'
import { fetchAuthorizers, confirmElectronicIndemnityApplication} from "../../actions/electronicIndemnity";
import { SelectiveRulesPage } from '../selectiveapproval/SelectiveRulesPage'

export const AuthorisedEmailIndemnity = (props) => {
    const [errors, setErrors] = useState({});
    const indemnityRequest = useSelector(state => state.electronicIndemnity);
    const goldTierId = useSelector(state => state.electronicIndemnity && state.electronicIndemnity.legalEntity.goldTierId);
    const applicationId = useSelector(state => state.electronicIndemnity && state.electronicIndemnity.id);
    const [notificationEmailAddress, setNotificationEmailAddress] = useState(indemnityRequest.notificationEmailAddress);
    const dispatch = useDispatch();
    const tagged = useState(true);
    //Selective approver List start
    const [selectedApprovers,setSelectedApprovers] = useState([]);
    const [errormsg , setErrormsg]  = useState(false);
    const [currentselected, setCurrentselected] = useState('');
    const [selectiveauthorisers , setSelectiveauthorisers] = useState([]);
    const [authorisersA , setAuthorisersA] = useState([]);
    const [authorisersB , setAuthorisersB] = useState([]);
    const [levelDataList, setLevelDataList] = useState([]);
    const [levelAApprovers , setLevelAApprovers] = useState([]);
    const [levelBApprovers , setLevelBApprovers] = useState([]);


    useEffect(() => {
        dispatch(fetchAuthorizers(applicationId));
        dispatch(trackData('confirmAuthorisers', 'New Account| Confirm Authorisers| Step 4'));
    }, tagged);
  
    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, { [attribute]: null }));
    };

    const handleChange = (value) => {
        setNotificationEmailAddress(value);
    };
    
    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    const saveDetails = (event) => {
        event.preventDefault();
        if (!(validateEmail(notificationEmailAddress))){
            setErrors({notificationEmailAddress: [" please enter a valid email address "]})
            }
        if ( validateCount() && validateEmail(notificationEmailAddress)) {
            const {history} = props;
            
            const onSuccess = () => history && history.push(electronicIndemnityRoutes.completeElectronicIndemnity);
            if (currentselected === 'No'){
                const prevSelectedApprovers = [];
                //setting state
                indemnityRequest.selectedApprovers = prevSelectedApprovers;
                indemnityRequest.notificationEmailAddress = notificationEmailAddress;
                setNotificationEmailAddress(indemnityRequest.notificationEmailAddress);

                //manage paload and parameter dispatched value to api
                let indemnityRequestPayload = { selectedApprovers, notificationEmailAddress };
                dispatch(confirmElectronicIndemnityApplication(applicationId, indemnityRequestPayload, 'UserTask_ConfirmAuthorisers', onSuccess ));
            }
            else {
                //setting state
                indemnityRequest.selectedApprovers = selectedApprovers;
                indemnityRequest.notificationEmailAddress = notificationEmailAddress;
                setNotificationEmailAddress(indemnityRequest.notificationEmailAddress);

                //manage paload and parameter dispatched value to api
                let indemnityRequestPayload = { selectedApprovers, notificationEmailAddress };
                dispatch(confirmElectronicIndemnityApplication(applicationId, indemnityRequestPayload, 'UserTask_ConfirmAuthorisers', onSuccess ));
            }
        }
    };

    const validateCount = () => {
         const isCountValid = () => {
             for ( const level of levelDataList){
                 const counts = Object.values(level)[0];
                 if (levelAApprovers.length === counts.countOfA && levelBApprovers.length === counts.countOfB){
                 return true;
                 }}
                 return false;
          };
         if (currentselected==='Yes' && !isCountValid() ) {
           setErrormsg(true);
           return false
        }
        return true;
    }

    const updateLevelDataList = (levelData) => {

           setLevelDataList(levelData);
        };

    const updateSelection = (selected) => {
        setCurrentselected(selected);
    };

    const updateauthorisers = (authorizers) => {
        setSelectiveauthorisers(authorizers);
        setAuthorisersA(authorizers.filter(apA => apA.authLevel === 'A' ));
        setAuthorisersB(authorizers.filter(apB => apB.authLevel === 'B' ));
    
    };

    const handleSelections =(e, idNumber, authLevel) => {
        const authorizer = { identityNumber:idNumber, approvalLevel:authLevel };
        errormsg && setErrormsg(false);

        if (selectedApprovers.some(a => a.identityNumber === idNumber)) {
              setSelectedApprovers(prevState => (
                prevState.filter(a => a.identityNumber !== idNumber)));
              setLevelAApprovers(prevState => (
                prevState.filter(a => a.identityNumber !== idNumber)));
              setLevelBApprovers(prevState => (
                prevState.filter(a => a.identityNumber !== idNumber)));


        } else {
              setSelectedApprovers(prevState => (
                [...prevState, authorizer]));
              if (authLevel === 'A') {
                 setLevelAApprovers(prevState => (
                [...prevState,authorizer]));
                }
              else {
               setLevelBApprovers(prevState => (
                [...prevState , authorizer]));
                }
        }
    };
    const renderList = (sc) => {
        if (!sc) return null;
        const message = sc.registered === true ?
            `${sc.firstName} ${sc.surname} is registered and will be sent an approval request` :
            `${sc.firstName} ${sc.surname} is not registered`
        return (<div className="row">
            <div className="col-md-12">
                <label>{message}</label>
            </div>
            <hr />
        </div>);
    };

    const renderFilteredList = (sc) => {
        return (
            <div className="row">
                <div className="checkbox-inline column">
                    <FormField >
                        <div className ={sc.registered===false? " disable flexRow " : "flexRow"} style={{ alignItems: "flex-start", textAlign: "left" }}>
                            <div className="icon-container"  >
                                <i className={selectedApprovers.some(a => a.identityNumber === sc.idNumber)  && !(sc.registered===false)? "fa fa-check-square-o" : "fa fa-square-o"}
                                    style={{ color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10 }}
                                     checked={selectedApprovers.some(a => a.identityNumber === sc.idNumber) }
                                     onClick={(event) => handleSelections(event, sc.idNumber,sc.authLevel)} />
                            </div>
                            <p style={{ margin: 0 }}> {sc.firstName} {sc.surname} </p>
                        </div>
                    </FormField>
                </div>
            </div>
        );
    }
    //Selective approver List End

    return (
        <div className="page-container">
            <div style={{ minHeight: '50em', marginTop:'40px'}}>
                <div className="row" style={{ margin: "0" }}>
                    <SelectiveRulesPage
                        selectedApprovers = {selectedApprovers}
                        renderList = {renderList.bind(this)}
                        renderFilteredList = {renderFilteredList.bind(this)}
                        authorisers = {selectiveauthorisers}
                        result1 = {authorisersA}
                        result2 = {authorisersB}
                        updateLevelDataList = {updateLevelDataList}
                        updateSelection ={updateSelection}
                        updateauthorisers = {updateauthorisers}
                        goldTierId = {goldTierId}
                        errormsg = {errormsg}
                     />           
                </div>
                <div className="row" style={{ margin: "0" }}>
                    <FormField id="kycEmail" error={errors && errors.notificationEmailAddress}>
                        <label>Please Enter Email Address Where Copy Of The Electronic Indemnity Will Be Sent. </label>
                        <input
                            className="form-control"
                            id="notificationEmailAddress"
                            onChange={(event) => {
                                errors && errors.notificationEmailAddress && resetError('notificationEmailAddress');
                                handleChange(event.target.value);
                            }}
                            type="text"
                            value={notificationEmailAddress}
                        />
                    </FormField>
                </div>
            </div>
            <div className="page-footer-section" style={{ width: "80%", textAlign:'right' }}>
                {/* <ButtonsComponent
                    history={props.history}
                    onNext={saveDetails}
                    hidePrevious={true}
                    hideSaveAndContinue={true}
                /> */}
                <button  className="action-btn-primary btn-sm" onClick={saveDetails}>Submit</button>
            </div>
        </div>
    );

};

export default (AuthorisedEmailIndemnity);