import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/omega/theme.css';
import {confirmKYC, updateDoc} from '../../actions/kyc';
import {shariahBcaRoutes} from '../../routers/routes';
import EntityDocsComponent from './shariahEntityDocs';
import RelatedPartiesComponent from './shariahRelated/RelatedPartiesComponent';
import SignatoriesComponent from './shariahSignatories/SignatoriesComponent';
import ButtonsComponent from '../ButtonsComponent';
import {trackData} from '../../actions/analytics';
     
export const shariahBcaEntitykyc = (props) => {

    const application = useSelector(state => state.application && state.application.application);
    const entityDocs = useSelector(state => state.application.application && state.application.application.legalEntity.supportingDocuments ? state.application.application.legalEntity.supportingDocuments : []);
    const systemError = useSelector(state => state.systemError);
    const tagged = useState(true);

    const [errors, setErrors] = useState({});

    const entityDocsRef = React.useRef(null);
    const signatoriesRef = React.useRef(null);
    const relatedPartyRef = React.useRef(null);

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(trackData('pageName', 'New Account| KYC| step 4'));
        // dispatch(setTrackData('pageSubSection1', 'Entity Documents'));
        // dispatch(setTrackData('pageSubSection2', 'Account Signatories'));
        // dispatch(setTrackData('pageSubSection3', 'Entity Related Parties'));
        // dispatch(submitTracking());
    }, tagged);

    const saveAndContinue = (event) => {
        props.history.push('/saveapplication');
    };

    const saveDetails = (event) => {
        event.preventDefault();
        const isDocsValid = entityDocsRef.current && entityDocsRef.current.validateDocs();
        const isSignatoriesValid = signatoriesRef.current && signatoriesRef.current.validateSignatories();
        const isRelatedPartiesValid = relatedPartyRef.current && relatedPartyRef.current.validateRelatedParties();
        const {history} = props;
        if (isDocsValid && isSignatoriesValid && isRelatedPartiesValid) {
            const onProceed = history.push(shariahBcaRoutes.shariahBcaConfirmAuthorisers);
            dispatch(confirmKYC(application.id, onProceed));
        }
    };

    const renderFooter = () => {
        const {history} = props;
        return (
            <ButtonsComponent
                history={history}
                onNext={saveDetails}
                prevPage={shariahBcaRoutes.shariahBcaConfirmProduct}
                onSaveAndContinue={saveAndContinue}
            />
        );
    };

    const handleDocDesc = (financialDoc, desc) => {
        financialDoc.description = desc;
        dispatch(updateDoc(financialDoc));
        // switch (desc) {
        //     case 'cfoLetter':
        //         setCfoLetter(!cfoLetter);
        //         setAccountStatements(false);
        //         setAuditorLetter(false);
        //         break;
        //     case 'accountStatements':
        //         setAccountStatements(!accountStatements);
        //         setCfoLetter(false);
        //         setAuditorLetter(false);
        //         break;
        //     case 'auditorLetter':
        //         setAuditorLetter(!auditorLetter);
        //         setCfoLetter(false);
        //         setAccountStatements(false);
        //         break;
        //     default:
        //         break;
        // }
    };

    if (systemError && systemError.show) return null;
    return (
        <div className="page-container">
            <div className="page-main-section">
                <div className="container" style={{minHeight: '50em'}}>
                    <div className="col-md-4">
                        <EntityDocsComponent
                            ref={entityDocsRef}
                            applicationId={application.id}
                            entityDocs={entityDocs}
                            handleDocDesc={handleDocDesc}
                        />

                    </div>
                    <div className="col-md-4">
                        <SignatoriesComponent ref={signatoriesRef}/>
                    </div>
                    <div className="col-md-4">
                        <RelatedPartiesComponent ref={relatedPartyRef}/>
                    </div>
                </div>
                <div className="page-footer-section" style={{width: "100%"}}>
                    {renderFooter()}
                </div>
            </div>
        </div>
    );

};

export default shariahBcaEntitykyc;
