import React, {useState, useEffect} from 'react';
import {DataList} from 'primereact/components/datalist/DataList';
import FormField from '../formField/FormField';
import {useDispatch, useSelector} from 'react-redux';
import Row from 'react-bootstrap/lib/Row';
import {fetchAuthorizers} from '../../actions/entity';
import {completeSignatoryApplication} from '../../actions/signatoryActions';
import Alert from 'react-bootstrap/lib/Alert';
import {SelectiveRulesPage} from '../selectiveapproval/SelectiveRulesPage'

export const SignatoryConfirmPage = (props) => {
    const authorisers = useSelector(state => state.authorisers && state.authorisers.authorisers ? state.authorisers.authorisers : []);
    const application = useSelector(state => state.signatoriesMaintenance);
    const goldTierId = application.maintenanceRequest.entityDTO.goldTierId;
    const [form, setForm] = useState(null);
    const [emailAddress, setEmailAddress] = useState('');
    const [errors, setErrors] = useState({});
    const [selectedApprovers,setSelectedApprovers] = useState([]);
    const [levelDataList, setLevelDataList] = useState([]);
    const result1 = authorisers.filter(apA => apA.approvalLevel === 'A' );
    const result2 = authorisers.filter(apB => apB.approvalLevel === 'B' );
    const [errormsg , setErrormsg]  = useState(false);
    const [currentselected,setCurrentselected] = useState('');
    const [levelAApprovers , setLevelAApprovers] = useState([]);
    const [levelBApprovers , setLevelBApprovers] = useState([]);


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAuthorizers(application.id));
        console.log(props);
    }, []);

    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    };

    const submit = () => {
        if (!(validateEmail(emailAddress))){
        setErrors({emailAddress: [" please enter a valid email address "]})
        }
        const onComplete = () => {
            props.history.replace('/');
        };
        if ( validateCount() && validateEmail(emailAddress)  ) {
            if (currentselected === 'No'){
            const prevSelectedApprovers = [];
            dispatch(completeSignatoryApplication(application.id, application.maintenanceRequest.instructions, emailAddress,prevSelectedApprovers,onComplete));
            }
            else {
            dispatch(completeSignatoryApplication(application.id, application.maintenanceRequest.instructions, emailAddress, selectedApprovers , onComplete));
            }
        }

    };


    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };


    const validateCount = () => {
         const isCountValid = () => {
             for ( const level of levelDataList){
                 const counts = Object.values(level)[0];
                 if (levelAApprovers.length === counts.countOfA && levelBApprovers.length === counts.countOfB){
                 return true;
                 }}
                 return false;
          };

         if (currentselected==='Yes' && !isCountValid() ) {
             setErrormsg(true);
             return false
          }
          return true;
         };

    const updateLevelDataList = (levelData) => {

           setLevelDataList(levelData);
        };

    const updateSelection = (selected) => {

          setCurrentselected(selected);
    };


    const handleSelections =(e, identityNumber, approvalLevel) => {
          const authorizer = { identityNumber, approvalLevel };
          errormsg && setErrormsg(false);

          if (selectedApprovers.some(a => a.identityNumber === identityNumber)) {
                setSelectedApprovers(prevState => (
                  prevState.filter(a => a.identityNumber !== identityNumber)));
                setLevelAApprovers(prevState => (
                  prevState.filter(a => a.identityNumber !== identityNumber)));
                setLevelBApprovers(prevState => (
                  prevState.filter(a => a.identityNumber !== identityNumber)));

          } else {
                setSelectedApprovers(prevState => (
                  [...prevState, authorizer]));
                if (approvalLevel === 'A') {
                   setLevelAApprovers(prevState => (
                  [...prevState,authorizer]));
                  }
                else {
                 setLevelBApprovers(prevState => (
                  [...prevState , authorizer]));
                  }
          }
    };


    const renderList = (sc) => {
        if (!sc) return null;
        return (
            <div className="row">
                <div className="col-md-12">
                    <label>{sc.approvalMessage}</label>
                </div>
                <hr/>
            </div>
        );
    };


    const renderFilteredList =(sc) => {
        return (
            <div className="row">
                <div className="checkbox-inline column">
                    <FormField >
                        <div className ={sc.approvalMessage.includes('not')? " disable flexRow " : "flexRow"} style={{ alignItems: "flex-start", textAlign: "left" }}>
                            <div className="icon-container"  >
                                <i className={selectedApprovers.some(a => a.identityNumber === sc.identityNumber)  && !(sc.approvalMessage.includes('not'))? "fa fa-check-square-o" : "fa fa-square-o"}
                                    style={{ color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10 }}
                                     checked={selectedApprovers.some(a => a.identityNumber === sc.identityNumber) }
                                     onClick={(event) => handleSelections(event, sc.identityNumber, sc.approvalLevel)} />
                            </div>
                            <p style={{ margin: 0 }}> {sc.approvalMessage.substring(0,sc.approvalMessage.indexOf("is"))} </p>
                        </div>
                    </FormField>
                </div>
            </div>
        );
    }

    const handleChange = (value) => {
        setEmailAddress(value);
    };

    const renderFooter = () => {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary"
                    type="button" onClick={submit}
                >Complete
                </button>
            </div>
        );
    };

    return (
        <div className="page-container">
            <div className="page-main-section" style={{minHeight: '30em'}}>
                <div className="flexColumn">
                    <div className="card-container-form">
                        <div style={{width: "100%"}}>
                            <div className="product-title">
                              <SelectiveRulesPage
                               selectedApprovers = {selectedApprovers}
                               renderList = {renderList.bind(this)}
                               renderFilteredList = {renderFilteredList.bind(this)}
                               authorisers = {authorisers}
                               result1 = {result1}
                               result2 = {result2}
                               updateLevelDataList = {updateLevelDataList}
                               updateSelection = {updateSelection}
                               goldTierId = {goldTierId}
                               errormsg = {errormsg}
                               />
                            </div>
                        </div>
                        <FormField id="kycEmail" error={errors && errors.emailAddress}>
                            <label>Please Enter Email Address Where The Signatory Confirmation Letter Will Be Sent </label>
                            <input
                                className="form-control"
                                id="emailAddress"
                                onChange={(event) => {
                                    errors && errors.emailAddress && resetError('emailAddress');
                                    handleChange(event.target.value);
                                }}
                                type="text"
                                value={emailAddress}
                            />
                        </FormField>

                    </div>
                </div>
            </div>
            <div className="page-footer-section">
                {renderFooter()}
            </div>
        </div>
    );
};
