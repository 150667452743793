import {authCheck, loadingPageDataFinish, loadingPageDataStart} from "./appLoading";
import axios from 'axios';
import {API_ROOT} from "../api-config";
import * as types from './actionTypes';

const TPFAURL = `${API_ROOT}/api/tpfa`;

export function startTpfaApplication(identityNumber, request, onProceed) {
    identityNumber = identityNumber ? identityNumber : '';
    return async dispatch => {
        loadingPageDataStart(dispatch);
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.post(TPFAURL + "?idNumber=" + identityNumber, request).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.TPFA_START, tpfa: responseData.data});
                loadingPageDataFinish(dispatch);
                onProceed && onProceed(true);
            } else {
                console.log("tpfa.tpfaApplication.errorMessage ===> ", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Couldn't fetch TPFA Application details."});
                loadingPageDataFinish(dispatch, true, status);
                onProceed && onProceed(false, "Couldn't fetch TPFA Application details.");
            }
        } catch (error) {
            console.log("tpfa.start.error ===> ", JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function saveApplication(request, id, load = false) {
    return async dispatch => {
        load && loadingPageDataStart(dispatch);
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.put(`${TPFAURL}/${id}`, request).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.TPFA_SAVE, tpfa: responseData.data});
                load && loadingPageDataFinish(dispatch);
            } else {
                console.log("tpfa.tpfaApplication.errorMessage ===> ", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Couldn't save TPFA Application details."});
                load && loadingPageDataFinish(dispatch, true, status);
            }
        } catch (error) {
            console.log('tpfa.start.error ===> ', JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            load && loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function fetchSelectedTPFARequest(id, history) {
    return async dispatch => {
        loadingPageDataStart(dispatch);
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(TPFAURL + '/' + id).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.TP_CONTINUE, tpfa: responseData.data});
                history.push('/TPFA/TP-Details');
                loadingPageDataFinish(dispatch);
            } else {
                console.log('tpfa.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Error Fetchng TPFA.'});
                loadingPageDataFinish(dispatch, true, status);
            }
        } catch (error) {
            console.log('lr.finishRem.error ===> ', JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function additionalProductChange(additionalProducts) {
    return (dispatch) => {
        dispatch({type: types.TP_ADDITIONALPRODUCT, additionalProducts})
    }
}

export function submitApplication(id, payload, history) {
    return async dispatch => {
        loadingPageDataStart(dispatch);
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.put(`${TPFAURL}/${id}/complete`, payload).then(response => response.data);
            if (responseData.success) {
                loadingPageDataFinish(dispatch);
                history.push("/TPFA/Complete");
            } else {
                console.log("tpfa.tpfaApplication.errorMessage ===> ", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Couldn't save TPFA Application details."});
                loadingPageDataFinish(dispatch, true, status);
            }
        } catch (error) {
            console.log("tpfa.start.error ===> ", JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

