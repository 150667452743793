import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function impersonationListReducer(state = initialState.impersonationList, action) {
    switch (action.type) {
        case types.FETCH_IMPERSONATIONS_SUCCESS:
            return {...state, impersonationList: action.impersonationList};
        default:
            return state;
    }
}