import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function delegatesReducer(state = initialState.delegatedList, action) {
    switch (action.type) {
        case types.FETCH_DELEGATES_SUCCESS:
            return {...state, delegatedList: action.delegatedList};

        default:
            return state;
    }
}