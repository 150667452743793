import React from 'react';
import _ from 'lodash';
import validate from 'validate.js'

import {connect} from 'react-redux';
import {bindActionCreators} from "redux";

import * as actions from '../../../actions/entity';
import FormField from "../../formField/FormField";
import Alert from "react-bootstrap/lib/Alert";
import Row from "react-bootstrap/lib/Row";
import SearchComponent from '../../SearchComponent';
import { fetchEntityInfo } from '../../../actions/entity';

const EntitySearch = ({errors, shouldDisableInput, onCancel, onChangeText, onResetError, search, hideCancel, onSearch}) => (
    <div>
        <FormField className={"form-group"} id="registeredNumber" error={errors && errors.registeredNumber}>
           {/* <label>Registration Number</label>
             <input
                className="form-control"
                id="registeredNumberSearch"
                onChange={(e) => {
                    errors && errors.registeredNumber && onResetError('registeredNumber');
                        onChangeText(e, "registeredNumber")
                    }}
                    type="text"
                    value={search.registeredNumber || ''}
                /> */}
                {!shouldDisableInput ? (
                <input
                className="form-control"
                id="registeredNumberSearch"
                onChange={(e) => {
                    errors && errors.registeredNumber && onResetError('registeredNumber');
                        onChangeText(e, "registeredNumber")
                    }}
                    type="text"
                    value={search.registeredNumber || ''}
                    readOnly={true}
                />
                ) : ( 
                <SearchComponent
                                           label={"Registration Number"}
                                           onChange={(e) => {
                                               errors && errors.registeredNumber && onResetError('registeredNumber');
                                               onChangeText(e, "registeredNumber")
                                           }}
                                           onSearch={onSearch}
                                           searchValue={search.registeredNumber || ''}
                                       />
                )}
                </FormField>
               
            <FormField className={"form-group"} id="registeredName" error={errors && errors.registeredName}>
                <label>Registration Name</label>
                <input
                    className="form-control"
                    id="registeredNameSearch"
                    onChange={(e) => {
                        errors && errors.registeredName && onResetError('registeredName');
                        onChangeText(e, "registeredName")
                    }}
                    type="text"
                    value={search.registeredName || ''}
                />
            </FormField>
        </div>

    )
;

class EntityLookupPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {search: false},
            search: {registeredName: '', registeredNumber: ''},
            isSearchComplete: false,
            isLoading:false
        };
        this.handleCancel = this._handleCancel.bind(this);
        this.resetError = this._resetError.bind(this);
        this.handleSearch = this._handleSearch.bind(this);
        this.handleOnChange = this._handleOnChange.bind(this);
        this.handleOnChangeSearchText = this._handleOnChangeSearchText.bind(this);
        this.clearValues = this.clearValues.bind(this);
    }

    _handleCancel() {
        this.props.history.replace("/");
    }

    _handleOnChange({target}, attribute, innerAttribute) {
        let value = _.trimStart(target.value);
        if (innerAttribute) {
            const {newEntityDetails} = this.props;
            value = _.extend({}, newEntityDetails[attribute], {[innerAttribute]: value})
        }
        this.props.actions.onChangeValue('newEntityDetails', attribute, value);
    }

     onEntitySearch = (onComplete) => {
        const {search, isSearchComplete, errors} = this.state;
        this.loadingStart();
        const onFinish = (data) => {
           this.loadingFinish();
           this.setState({
            search: {
            ...search,
            registeredNumber: data.registrationNumber,
            registeredName: data.registeredName,
           },
           isSearchComplete: true
        })
            onComplete && onComplete();
        };
        const onError = () => {
            this.loadingFinish();
            this.setState({errors: {registeredNumber: ['Entity not found on Search.']}});
        };
        this.props.fetchEntityInfo(search.registeredNumber, null, onFinish, onError);
    };

     loadingStart = () => {
        this.setState({isLoading: true});
    };

     loadingFinish = () => {
        this.setState({isLoading: false});
    };
    handleEntitySearch = () => {
        this.onEntitySearch(this.onSearchComplete)
    };

    onSearchComplete = () => {
        this.setState({isSearchComplete: true});
    };

    _handleOnChangeSearchText({target}, attribute) {
        const search = _.extend({}, this.state.search, {[attribute]: _.trimStart(target.value)});
        this.setState({search})
    }

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    clearValues() {
        this.setState({
                search: {
                registeredNumber: '',
                registeredName: '',
               },
               isSearchComplete: false
        })
        this.props.actions.onChangeValue('newEntityDetails', 'description', '');
    }

    _validateForm() {
        const {search} = this.state;
        const {newEntityDetails} = this.props;
        const options = {fullMessages: false};

        const constraints = {
            registeredName: {presence: true, length: {minimum: 1, message: 'required'}},
            registeredNumber: {presence: true, length: {minimum: 1, message: 'required'}},
        };

        const searchErrors = validate(search, constraints, options);
        const newEntityDetailsErrors = validate(newEntityDetails, {
            description: {presence: true, length: {minimum: 1, message: 'required'}}
        }, options);

        const errors = _.extend({}, searchErrors, newEntityDetailsErrors);

        this.setState({errors});
        return _.isEmpty(errors);
    }

    _handleSearch() {
        const {actions, history, newEntityDetails} = this.props;
        const {search} = this.state;

        if (this._validateForm()) {
            const onProceed = () => history.push('/onboard/reginfo');
            const onError = () => this.setState({errors: {registeredNumber: ['Registration number not found from search, check registration number.']}});
            actions.entityInfoLookup(search, newEntityDetails, onProceed, onError);
        }
    }

    handlePrevious = () => {
        const {history} = this.props;
        history && history.push("/onboard");
    };

    _renderFooter() {
        return (
            <div className="action-button-container">
                <button
                    className="action-btn-primary"
                    onClick={this.handleSearch}
                    type="button"
                    style={{marginRight: '30px'}}
                >Next
                </button>
                <button
                    className="btn btn-danger"
                    onClick={this.clearValues}
                    type="button"
                    style={{height: '44px', width: '160px'}}
                >Clear
                </button>

            </div>
        )
    }

    render() {
        const {newEntityDetails} = this.props;
        const {search, errors, isSearchComplete} = this.state;
        return (
            <div className="card-container-form">
                <div className="row">
                    <div className="col-md-4 col-md-offset-3">
                        <div className="section-title">
                            <span>New Subsidiary Details</span>
                        </div>
                        <EntitySearch
                            errors={errors}
                            onCancel={this.handleCancel}
                            onChangeText={this.handleOnChangeSearchText}
                            onResetError={this.resetError}
                            shouldDisableInput={!isSearchComplete}
                            search={search}
                            onSearch={this.handleEntitySearch}
                        />
                        <FormField className={"form-group"} id="descript" error={errors && errors.description}>
                            <label htmlFor="description">Description</label>
                            <input
                                className="form-control"
                                id="description"
                                onChange={(event) => {
                                    errors && errors.description && this.resetError('description');
                                    this.handleOnChange(event, 'description')
                                }}
                                ref="description"
                                type="text"
                                value={newEntityDetails && newEntityDetails.description || ''}
                            />
                        </FormField>

                    </div>

                </div>
                <Row>
                    <div className="col-md-4 col-md-offset-3">
                        {errors.search && (
                            <Alert bsStyle={'danger'}>Entity not Found!</Alert>
                        )}
                    </div>
                </Row>
                <div className="row">
                    <div className="col-md-4 col-md-offset-3">
                        <div className="page-footer-section">
                            {this._renderFooter()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps({entity}) {
    return {newEntityDetails: entity && entity.newEntityDetails ? entity.newEntityDetails : {}}
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        fetchEntityInfo: (regNum, finish, onSuccess, onError) => {
            dispatch(fetchEntityInfo(regNum, finish, onSuccess, onError));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntityLookupPage);
