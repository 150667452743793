import React, {Component} from 'react';
import {
    Panel,
    Button,
    Popover,
    Badge
} from "react-bootstrap";


class CustomerComponent extends Component {

    render() {
        const {customers, onSelectCustomer, selected} = this.props;
        const wellStyles = {maxWidth: 400, margin: '0 auto 10px'};
        return (
            <div>
                {
                    customers &&
                    customers.map( customer => {
                        return (
                            <div className="btn-block" style={wellStyles} key={customer.id}>
                                <Button /*bsStyle="primary"*/
                                        className={selected ? selected === customer.id ? 'active' : '' : ''}
                                        bsSize="large" block onClick={() => onSelectCustomer(customer)}
                                        style={{cursor: 'pointer', fontSize: '12px'}}>
                                    <Badge>{customer.id}</Badge>&nbsp;-&nbsp;{customer.customerShortName}
                                </Button>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

export default (CustomerComponent);