import React, {useState} from "react";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import validate from 'validate.js';
import "../../../styles/ConfirmApplication.css";
import "../../../styles/Global.css";
import FormField from "../../formField/FormField";
import {managedFundRoutes} from "../../../routers/routes";
import ButtonsComponent from "../../ButtonsComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import countries from "../../../data/countries";
import Toggler from "../../Toggler";
import {saveCustodyManagedFund} from "../../../actions/custodyManagedFund";
import * as custodyConstants from '../../custodyConstants';
import {saveCashCustodyManagedFund} from "../../../actions/cashAndCustodyManagedFund";

export const BeneficialOwnerPage = (props) => {

    const custodyRequest = useSelector(state => state.cashCustodyManagedFund && state.cashCustodyManagedFund.cashCustodyManagedFundRequest);
    const appId = useSelector(state => state.cashCustodyManagedFund && state.cashCustodyManagedFund.id);
    const [beneficialOwner, setBeneficialOwner] = useState(custodyRequest.beneficialOwner);
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();

    const validateForm = () => {

        const options = {fullMessages: false};

        const accConstraints = {
            accountName: {presence: true, length: {minimum: 1, message: 'required'}},
            securityShortName: {presence: true, length: {minimum: 1, message: 'required'}},
            registeredName: {presence: true, length: {minimum: 1, message: 'required'}},
            taxResidentCountry: {presence: true, length: {minimum: 1, message: 'required'}},
            marketType: {presence: true, length: {minimum: 1, message: 'required'}},
            internalClassification: {presence: true, length: {minimum: 1, message: 'required'}},
            centralBankClass: {presence: true, length: {minimum: 1, message: 'required'}},
            institutionalSector: {presence: true, length: {minimum: 1, message: 'required'}},
        };

        let entityConstraints;

        if (beneficialOwner.marketType !== 'individual') {
            entityConstraints = {
                countryOfIncorporation: {presence: true, length: {minimum: 1, message: 'required'}},
                // nationalTaxNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                // registrationNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                dateOfIncorporation: {presence: true}
            };
        } else if (beneficialOwner.marketType === 'individual') {
            entityConstraints = {
                firstName: {presence: true, length: {minimum: 1, message: 'required'}},
                surname: {presence: true, length: {minimum: 1, message: 'required'}},
                gender: {presence: true, length: {minimum: 1, message: 'required'}},
                title: {presence: true, length: {minimum: 1, message: 'required'}},
                countryOfBirth: {presence: true, length: {minimum: 1, message: 'required'}},
            };
        }

        const entityErrors = validate(beneficialOwner, entityConstraints, options);
        const accErrors = validate(beneficialOwner, accConstraints, options);

        const errors = _.extend({}, {entityErrors}, {accErrors});
        setErrors(errors);
        return _.isEmpty(entityErrors) && _.isEmpty(accErrors);
    };

    const saveDetails = (event) => {
        // event.preventDefault();
        const {history} = props;
        if (validateForm()) {
            const onSuccess = () => history.push(managedFundRoutes.cashAndCustodyAddressDetails);
            custodyRequest.beneficialOwner=beneficialOwner;
            dispatch(saveCashCustodyManagedFund(appId, custodyRequest, 'UserTask_BeneficialDetails', onSuccess));

        }

    };

    const saveAndContinue = (event) => {
        event.preventDefault();
        const {history} = props;
        custodyRequest.beneficialOwner=beneficialOwner;
        const onSuccess = () => history.push('/saveapplication');
        dispatch(saveCashCustodyManagedFund(appId, custodyRequest, 'saveContinue', onSuccess));
    };


    const renderIndividualDetails = () => {

        return (
            <div>

                <FormField className="form-group" id="firstName"
                           error={errors && errors.entityErrors && errors.entityErrors.firstName}>
                    <label htmlFor="firstName">First Name</label>
                    <input
                        className="form-control"
                        id="firstName"
                        onChange={(event) => {
                            setBeneficialOwner({...beneficialOwner, firstName: event.target.value});
                        }}
                        type="text"
                        value={beneficialOwner && beneficialOwner.firstName}
                    />
                </FormField>

                <FormField className="form-group" id="surname"
                           error={errors && errors.entityErrors && errors.entityErrors.surname}>
                    <label htmlFor="surname">Surname</label>
                    <input
                        className="form-control"
                        id="surname"
                        onChange={(event) => {
                            setBeneficialOwner({...beneficialOwner, surname: event.target.value});
                        }}
                        type="text"
                        value={beneficialOwner && beneficialOwner.surname}
                    />
                </FormField>

                <FormField className="form-group" id="gender"
                           error={errors && errors.entityErrors && errors.entityErrors.gender}>
                    <label htmlFor="gender">Gender</label>
                    <Select
                        name="gender"
                        onChange={(event) => {
                            setBeneficialOwner({...beneficialOwner, gender: event.value});
                        }}
                        options={custodyConstants.GENDER}
                        value={beneficialOwner && beneficialOwner.gender ? beneficialOwner.gender : ''}
                    />
                </FormField>

                <FormField className="form-group" id="title"
                           error={errors && errors.entityErrors && errors.entityErrors.title}>
                    <label htmlFor="title">Title</label>
                    <Select
                        name="title"
                        onChange={(event) => {
                            setBeneficialOwner({...beneficialOwner, title: event.value});
                        }}
                        options={custodyConstants.TITLE}
                        value={beneficialOwner && beneficialOwner.title}
                    />
                </FormField>

                <FormField className="form-group" id="countryOfBirth"
                           error={errors && errors.entityErrors && errors.entityErrors.countryOfBirth}>
                    <label htmlFor="countryOfBirth">Country Of Birth</label>
                    <Select
                        name="title"
                        onChange={(event) => {
                            setBeneficialOwner({...beneficialOwner, countryOfBirth: event.value});
                        }}
                        options={countries}
                        value={beneficialOwner && beneficialOwner.countryOfBirth}
                    />
                </FormField>
                <FormField className="form-group" id="beneficialOwner"
                           error={errors && errors.entityErrors && errors.entityErrors.idNumber}>
                    <label htmlFor="idNumber">ID/Passport Number</label>
                    <input
                        className="form-control"
                        id="beneficialOwner"
                        onChange={(event) => {
                            setBeneficialOwner({...beneficialOwner, idNumber: event.target.value});
                        }}
                        type="text"
                        value={beneficialOwner && beneficialOwner.idNumber}
                    />
                </FormField>
                <FormField className="form-group" id="dateOfBirth"
                           error={errors && errors.entityErrors && errors.entityErrors.dateOfBirth}>
                    <label htmlFor="dateOfBirth">Date Of Birth(YYYYMMDD)&nbsp;&nbsp;</label>
                    <DatePicker
                        className="form-control"
                        selected={beneficialOwner && beneficialOwner.dateOfBirth && new Date(beneficialOwner.dateOfBirth)}
                        onChange={(event) => {
                            setBeneficialOwner({...beneficialOwner, dateOfBirth: event});
                        }}
                        showYearDropdown
                        showMonthDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={30}
                        maxDate={new Date()}
                        dateFormat="yyyyMMdd"

                    />

                </FormField>

                <FormField className="form-group" id="incomeTaxNumber"
                           error={errors && errors.entityErrors && errors.entityErrors.incomeTaxNumber}>
                    <label htmlFor="fullLegalEntityName">Income Tax Number</label>
                    <input
                        className="form-control"
                        id="incomeTaxNumber"
                        onChange={(event) => {
                            setBeneficialOwner({...beneficialOwner, incomeTaxNumber: event.target.value});
                        }}
                        type="text"
                        value={beneficialOwner && beneficialOwner.incomeTaxNumber}
                    />
                </FormField>
            </div>

        );
    };

    const renderIfClientTypeIsBrokerOrJse = () => {

        return (
            <div>
                {custodyRequest.accountRelations && custodyRequest.accountRelations.clientType == 'Broker/FSB nominee' || custodyRequest.accountRelations.clientType == 'JSE Member' ?

                    <div>

                        <FormField className="form-group" id="settlementAccount">
                            <label htmlFor="clientType">Broker Type</label>
                            <select
                                className="form-control"
                                id="accountNumber"
                                onChange={(event) => {
                                    setBeneficialOwner({...beneficialOwner, brokerType: event.value});
                                }}>
                                <option> --Please select--</option>
                                <option> Member Propriety</option>
                                <option>Member Settled</option>

                            </select>
                        </FormField>

                        <FormField className="form-group" id="brokerCode"
                                   error={errors && errors.BeneficialOwner}>
                            <label htmlFor="fullLegalEntityName">Broker Code</label>
                            <input
                                className="form-control"
                                id="brokerCode"
                                onChange={(event) => {
                                    setBeneficialOwner({...beneficialOwner, brokerCode: event.target.value});
                                }}
                                type="text"
                                value={beneficialOwner && beneficialOwner.brokerCode}
                            />
                        </FormField>
                    </div>
                    :
                    ""
                }

            </div>
        );
    };

    const renderIfHiportCode = () => {

        return (
            <div> {beneficialOwner && beneficialOwner.hiportCode ?

                <FormField className="form-group" id="desPortfolio">
                    <label htmlFor="desPortfolio">DAS Portfolio</label>
                    <Toggler
                        label=""
                        isOn={beneficialOwner && beneficialOwner.desPortfolio}
                        onToggle={() => {
                            setBeneficialOwner({...beneficialOwner, desPortfolio: !beneficialOwner.desPortfolio});
                        }}
                    />
                </FormField>
                : ""}
            </div>
        );
    };


    const renderCompanyDetails = () => {

        return (

            <div>
                <FormField className="form-group" id="registrationNumber"
                           error={errors && errors.entityErrors && errors.entityErrors.registrationNumber}>
                    <label htmlFor="fullLegalEntityName">Company Registration Number</label>
                    <input
                        className="form-control"
                        id="registrationNumber"
                        onChange={(event) => {
                            setBeneficialOwner({...beneficialOwner, registrationNumber: event.target.value});
                        }}
                        type="text"
                        value={beneficialOwner && beneficialOwner.registrationNumber}
                    />
                </FormField>
                <FormField className="form-group" id="nationalTaxNumber"
                           error={errors && errors.entityErrors && errors.entityErrors.nationalTaxNumber}>
                    <label htmlFor="fullLegalEntityName">Company National Tax Number</label>
                    <input
                        className="form-control"
                        id="nationalTaxNumber"
                        onChange={(event) => {
                            setBeneficialOwner({...beneficialOwner, nationalTaxNumber: event.target.value});
                        }}
                        type="text"
                        value={beneficialOwner && beneficialOwner.nationalTaxNumber}
                    />
                </FormField>
                <FormField className="form-group" id="vatNumber"
                           error={errors && errors.entityErrors && errors.entityErrors.vatNumber}>
                    <label htmlFor="fullLegalEntityName">Vat Number</label>
                    <input
                        className="form-control"
                        id="vatNumber"
                        onChange={(event) => {
                            setBeneficialOwner({...beneficialOwner, vatNumber: event.target.value});
                        }}
                        type="text"
                        value={beneficialOwner && beneficialOwner.vatNumber}
                    />
                </FormField>
                <FormField className="form-group" id="countryOfIncorporation"
                           error={errors && errors.entityErrors && errors.entityErrors.countryOfIncorporation}>
                    <label htmlFor="fullLegalEntityName">Country Of Incorporation</label>
                    <Select
                        name="countryOfIncorporation"
                        onChange={(event) => {
                            setBeneficialOwner({...beneficialOwner, countryOfIncorporation: event.value});
                        }}
                        options={countries}
                        value={beneficialOwner && beneficialOwner.countryOfIncorporation}
                    />
                </FormField>
                <FormField className="form-group" id="dateOfIncorporation"
                           error={errors && errors.entityErrors && errors.entityErrors.dateOfIncorporation}>
                    <label htmlFor="fullLegalEntityName">Incorporation Date (YYYYMMDD)&nbsp;&nbsp; </label>


                    <DatePicker
                        className="form-control"
                        selected={beneficialOwner && beneficialOwner.dateOfIncorporation && new Date(beneficialOwner.dateOfIncorporation)}
                        onChange={(event) => {
                            setBeneficialOwner({...beneficialOwner, dateOfIncorporation: event});
                        }}
                        showYearDropdown
                        showMonthDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={30}
                        maxDate={new Date()}
                        dateFormat="yyyyMMdd"
                    />
                </FormField>
            </div>
        );
    };


    return (
        <div className="page-container">
            <div className="page-main-section">
                <div className="row" style={{marginTop: '2em'}}>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        {/* <div className="flexColumn"> */}
                        <div className="form-group">
                            <FormField className="form-group" id="accountName"
                                       error={errors && errors.accErrors && errors.accErrors.accountName}>
                                <label htmlFor="accountName">Security Account Name</label>
                                <input
                                    className="form-control"
                                    id="accountName"
                                    onChange={(event) => {
                                        setBeneficialOwner({...beneficialOwner, accountName: event.target.value});
                                    }}
                                    type="text" maxLength={105}
                                    value={beneficialOwner && beneficialOwner.accountName}
                                />
                            </FormField>
                            <FormField className="form-group" id="securityShortName"
                                       error={errors && errors.accErrors && errors.accErrors.securityShortName}>
                                <label htmlFor="fullLegalEntityName">Security Account Short Name</label>
                                <input
                                    className="form-control"
                                    id="securityShortName"
                                    onChange={(event) => {
                                        setBeneficialOwner({...beneficialOwner, securityShortName: event.target.value});
                                    }}
                                    type="text" maxLength={35}
                                    value={beneficialOwner && beneficialOwner.securityShortName}
                                />
                            </FormField>
                            <FormField className="form-group" id="registeredName"
                                       error={errors && errors.accErrors && errors.accErrors.registeredName}>
                                <label htmlFor="fullLegalEntityName">Registered Name</label>
                                <input
                                    className="form-control"
                                    id="registeredName"
                                    onChange={(event) => {
                                        setBeneficialOwner({...beneficialOwner, registeredName: event.target.value});
                                    }}
                                    type="text" maxLength={65}
                                    value={beneficialOwner && beneficialOwner.registeredName}
                                />
                            </FormField>
                            <FormField className="form-group" id="taxResidentCountry"
                                       error={errors && errors.accErrors && errors.accErrors.taxResidentCountry}>
                                <label htmlFor="fullLegalEntityName">Tax Resident Country</label>
                                <Select
                                    name="taxResidentCountry"
                                    onChange={(event) => {
                                        setBeneficialOwner({...beneficialOwner, taxResidentCountry: event.value});
                                    }}
                                    options={countries}
                                    value={beneficialOwner && beneficialOwner.taxResidentCountry}
                                />
                            </FormField>

                            <FormField className="form-group" id="hiportCode"
                                       error={errors && errors.BeneficialOwner}>
                                <label htmlFor="fullLegalEntityName">Hi Portfolio Code</label>
                                <input
                                    className="form-control"
                                    id="hiportCode"
                                    onChange={(event) => {
                                        setBeneficialOwner({...beneficialOwner, hiportCode: event.target.value});
                                    }}
                                    type="text"
                                    value={beneficialOwner && beneficialOwner.hiportCode}
                                />
                            </FormField>
                            {renderIfHiportCode()}

                            {renderIfClientTypeIsBrokerOrJse()}


                        </div>
                        {/* </div> */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        {/* <div className="flexColumn"> */}
                        <div className="form-group">


                            <FormField className="form-group"
                                       error={errors && errors.accErrors && errors.accErrors.marketType ? ["One Should Be Selected"] : ""}>
                                <label htmlFor="marketType">Market Type</label>
                                <Select
                                    name="marketType"
                                    onChange={(event) => {
                                        setBeneficialOwner({...beneficialOwner, marketType: event.value});
                                    }}
                                    options={custodyConstants.MARKET_TYPE}
                                    value={beneficialOwner && beneficialOwner.marketType ? beneficialOwner.marketType : ''}
                                />
                            </FormField>

                            <FormField className="form-group" id="internalClassification"
                                       error={errors && errors.accErrors && errors.accErrors.internalClassification ? ["One Should Be Selected"] : ""}>
                                <label htmlFor="internalClassification">Internal Classification</label>

                                <Select
                                    id="internalClassification"
                                    onChange={(event) => {
                                        setBeneficialOwner({...beneficialOwner, internalClassification: event.value});
                                    }}
                                    value={beneficialOwner && beneficialOwner.internalClassification}
                                    options={custodyConstants.KYC_OPTIONS.filter(e => beneficialOwner && e.name === beneficialOwner.marketType)}/>

                            </FormField>

                            <FormField className="form-group" id="centralBankClass"
                                       error={errors && errors.accErrors && errors.accErrors.centralBankClass ? ["One Should Be Selected"] : ""}>
                                <label htmlFor="centralBankClass">Central Bank Classification</label>

                                <Select
                                    id="centralBankClass"
                                    onChange={(event) => {
                                        setBeneficialOwner({...beneficialOwner, centralBankClass: event.value});
                                    }}
                                    value={beneficialOwner && beneficialOwner.centralBankClass}
                                    options={custodyConstants.CENTRAL_BANK_OPTIONS.filter(e => beneficialOwner && e.kycType === beneficialOwner.internalClassification && e.marketType === beneficialOwner.marketType)}/>

                            </FormField>


                            <FormField className="form-group" id="institutionalSector"
                                       error={errors && errors.accErrors && errors.accErrors.institutionalSector ? ["One Should Be Selected"] : ""}>
                                <label htmlFor="institutionalSector">Sector/Institutional Sector</label>

                                <Select
                                    id="institutionalSector"
                                    onChange={(event) => {
                                        setBeneficialOwner({...beneficialOwner, institutionalSector: event.value});
                                    }}
                                    value={beneficialOwner && beneficialOwner.institutionalSector}
                                    options={custodyConstants.INST_SECTOR__OPTIONS.filter(e => beneficialOwner && e.marketType === beneficialOwner.marketType)}/>

                            </FormField>
                        </div>
                        {/* </div> */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4">
                        {/* <div className="flexColumn"> */}
                        <div className="form-group">

                            <div className="form-group">

                                {beneficialOwner && beneficialOwner.marketType == 'individual' ?
                                    renderIndividualDetails() :
                                    renderCompanyDetails()
                                }
                            </div>

                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>


            <div className="page-footer-section" style={{width: '80%'}}>
                <ButtonsComponent
                    history={props.history}
                    onNext={saveDetails}
                    onSaveAndContinue={saveAndContinue}
                    prevPage={managedFundRoutes.cashAndCustodyCapacityType}
                />
            </div>
        </div>
    );
};

export default BeneficialOwnerPage;
