import React, {Component} from 'react';
import {browserHistory} from 'react-router';
import {
    Panel,
    Button,
    Popover, Badge, Glyphicon,
} from "react-bootstrap";


class EntitiesComponent extends Component {
    constructor(props) {
        super(props);

    }

    render() {
        const {entities, onEntitySelect, selected, profiles, agreements} = this.props;
        const wellStyles = {margin: '0 auto 10px'};
        return (
            <div>
                {
                    entities.map(entity => {
                        const divisions = profiles[entity.id];
                        const complete = agreements ?
                            !divisions.map(division => {
                            const agreement = agreements[division.code];
                            return !!agreement;
                            }).includes(false)

                            : false;

                        return (
                            <div className="btn-block" style={wellStyles} key={entity.id}>
                                <Button bsStyle="primary"
                                        className={selected ? selected === entity.id ? 'active' : '' : ''}
                                        bsSize="large" block onClick={() => onEntitySelect(entity.id)}
                                        style={{cursor: 'pointer'}}>
                                    {entity.name}&nbsp;
                                    <Badge className='text-danger'>
                                        {
                                            complete ?
                                                <Glyphicon glyph='ok'/>

                                                :
                                                'O'
                                        }
                                    </Badge>
                                </Button>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

export default (EntitiesComponent);