import React from 'react';
import SearchComponent from '../../../components/SearchComponent'
import FormField from "../../formField/FormField";
import FileUpload from "../../FileUpload";
import Select from 'react-select';
import Toggler from "../../Toggler";
import {Col, FormGroup} from "react-bootstrap";

const BUSINESS_TYPE = {
    juristicEntity: 'Juristic Entity',
    naturalPerson: 'Natural Person',
    individual: 'individual'
};

const DOC_TYPES = {
    proofOfId: 'Proof of Identity',
    proofOfAddress: 'Proof Of Address'
};

class NewRelatedParty extends React.Component {
    state = {isRSACitizen: true, isSearchComplete: false};

    handleChangeSACitizen = (isRSACitizen) => {
        this.setState({isRSACitizen})
    };

    handleEntitySearch = () => {
        this.props.onEntitySearch(this.onSearchComplete)
    };

    handleIndividualSearch = () => {
        this.props.onIndividualSearch(this.onSearchComplete)
    };

    handleSelectRType = (e, type) => {
        this.setState({isSearchComplete: false});
        this.props.selectRPType(e, type);
    };

    onSearchComplete = () => {
        this.setState({isSearchComplete: true});
    };

    _renderHeader() {
        return (
            <div className="container">
                <div className="product-heading">
                    New Related Party
                </div>
                <div className="title-gradient"/>
            </div>
        )
    }

    _renderTypeSelector() {
        const {newRP} = this.props;
        const isNaturalPerson = newRP && (newRP.businessType === BUSINESS_TYPE.naturalPerson || newRP.individual === BUSINESS_TYPE.individual);
        const iconStyle = {color: "rgb(0, 51, 170)", fontSize: 18, marginLeft: 5};
        return (
            <div className="container">
                <div className="checkbox-inline" style={{padding: 0}}>
                    Type:
                    <span
                        onClick={(e) => this.handleSelectRType(e, BUSINESS_TYPE.naturalPerson)}>
                    <i
                        className={isNaturalPerson ? "fa fa-check-circle" : "fa fa-circle-o"}
                        style={iconStyle}
                    /> {`${BUSINESS_TYPE.naturalPerson}`}
                </span>
                    <span onClick={(e) => this.handleSelectRType(e, BUSINESS_TYPE.juristicEntity)}>
                    <i
                        className={!isNaturalPerson ? "fa fa-check-circle" : "fa fa-circle-o"}
                        style={iconStyle}
                    /> {`${BUSINESS_TYPE.juristicEntity}`}
                </span>
                </div>
            </div>
        )
    }

    render() {
        const {isRSACitizen, isSearchComplete} = this.state;
        const {newRP, errors} = this.props;

        const isNaturalPerson = newRP && (newRP.businessType === BUSINESS_TYPE.naturalPerson || newRP.individual === BUSINESS_TYPE.individual);
        return (
            <div>
                {this._renderHeader()}
                <br/>

                {this._renderTypeSelector()}
                <div className="container">
                    {
                        isNaturalPerson && !isSearchComplete &&
                        <Toggler
                            label="South African Citizen / Resident?"
                            isOn={isRSACitizen}
                            onToggle={this.handleChangeSACitizen}
                        />
                    }
                </div>
                {
                    isNaturalPerson ?
                        <NaturalPerson
                            {...this.props}
                            onSearch={this.handleIndividualSearch}
                            rsaCitizen={isRSACitizen}
                            shouldDisableInput={!(!isRSACitizen || isSearchComplete)}
                        />
                        :
                        <JuristicEntity
                            {...this.props}
                            onSearch={this.handleEntitySearch}
                            shouldDisableInput={!isSearchComplete}
                        />
                }
            </div>
        );
    }
};
export default NewRelatedParty;

export const JuristicEntity = (props) => {
    const {
        errors, newRP, shouldDisableInput, onChange, onResetError, onSubmit, onHandleUpload,
        onHandleRemoveDocument, showUpload, onSearch, idDoc, handleUpload
    } = props;


    if (!newRP) return null;
    const showUploadButtons = showUpload ? showUpload : null;


    return (

        <div className="container">
            <div className="container">
                <div className="col-xs-12 col-md-6">
                    <div className="card-container-form">
                        <FormField className="form-group" id="registrationNum"
                                   error={errors && errors.registrationNumber}>
                            {
                                !shouldDisableInput ?
                                    <div>
                                        <label>Registration Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="registrationNumber"
                                            value={newRP.registrationNumber || ''}
                                            onChange={(event) => {
                                                errors && errors.registrationNumber && onResetError && onResetError('registrationNumber');
                                                onChange(event, 'registrationNumber')
                                            }}
                                        />
                                    </div> :
                                    <SearchComponent
                                        label={"Registration Number"}
                                        onChange={(event) => {
                                            errors && errors.registrationNumber && onResetError && onResetError('registrationNumber');
                                            onChange(event, 'registrationNumber')
                                        }}
                                        onSearch={onSearch}
                                        searchValue={newRP && newRP.registrationNumber || ''}
                                    />
                            }
                        </FormField>

                        <FormField className={"form-group"} id="registeredname" error={errors && errors.registeredName}>
                            <label>Registered Name</label>
                            <input
                                type="text"
                                disabled={shouldDisableInput}
                                className="form-control"
                                id="registeredName"
                                value={newRP.registeredName || ''}
                                onChange={(event) => {
                                    errors && errors.registeredName && onResetError && onResetError('registeredName');
                                    onChange(event, 'registeredName')
                                }}
                            />
                        </FormField>

                        <FormField className={"form-group"} id="contactNumr" error={errors && errors.contactNumber}>
                            <label>Contact Number</label>
                            <input
                                className="form-control"
                                id="contactNumber"
                                disabled={shouldDisableInput}
                                value={newRP.contactNumber || ''}
                                type="text"
                                onChange={(event) => {
                                    errors && errors.contactNumber && onResetError && onResetError('contactNumber');
                                    onChange(event, 'contactNumber')
                                }}
                            />
                        </FormField>

                        <FormField className={"form-group"} id="sharePercentage"
                                   error={errors && errors.sharePercentage}>
                            <Col xs={12} style={{paddingLeft: 0}}>
                                <label>Shareholding Percentage</label>
                            </Col>
                            <div className="input-group">
                                <input
                                    type="test"
                                    disabled={shouldDisableInput}
                                    className="form-control"
                                    id="entitypercentage"
                                    value={newRP.sharePercentage || ''}
                                    onChange={(event) => {
                                        errors && errors.sharePercentage && onResetError && onResetError('sharePercentage');
                                        onChange(event, 'sharePercentage')
                                    }}
                                />
                                <span className="input-group-addon">%</span>
                            </div>
                        </FormField>
                    </div>
                </div>

                <div className="col-xs-12 col-md-6">
                    <div className="card-container-form">
                        <FormField className={"form-group"} id="address" error={errors && errors.street}>
                            <label>Registered Address</label>
                            <input
                                type="text"
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="registeredAddress"
                                value={newRP.street || ''}
                                onChange={(event) => {
                                    errors && errors.street && onResetError && onResetError('street');
                                    onChange(event, 'street')
                                }}
                            />
                        </FormField>

                        <FormField className={"form-group"} id="addressHome" error={errors && errors.headOfficeAddress}>
                            <label>Head Office Address</label>
                            <input
                                type="text"
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="headOfficeAddress"
                                value={newRP.headOfficeAddress || ''}
                                onChange={(event) => {
                                    errors && errors.headOfficeAddress && onResetError && onResetError('headOfficeAddress');
                                    onChange(event, 'headOfficeAddress')
                                }}
                            />
                        </FormField>

                        <FormField className={"form-group"} id="addressPhysical"
                                   error={errors && errors.physicalAddress}>
                            <label>Physical Business Address</label>
                            <input
                                type="text"
                                disabled={shouldDisableInput}
                                className="form-control"
                                id="physicalAddress"
                                value={newRP.physicalAddress || ''}
                                onChange={(event) => {
                                    errors && errors.physicalAddress && onResetError && onResetError('physicalAddress');
                                    onChange(event, 'physicalAddress')
                                }}
                            />
                        </FormField>
                        {idDoc &&
                        <FormGroup>
                            <div className={'row'} style={{marginLeft: '0'}}>
                                <label className={'control-label'}>Company Registration Document</label>
                            </div>
                            <FileUpload
                                disabled={false}
                                documentType={'Company Registration Document'}
                                onUpload={handleUpload}
                                onRemoveDocument={false}
                            />
                        </FormGroup>
                        }
                        <div style={{zIndex: -1, padding: 0, margin: 0}}>
                            {
                                showUploadButtons &&
                                <FormField
                                    className="form-group " id="proofOfAddressDoc"
                                    style={{display: "flex", flexDirection: "column"}}
                                    error={errors && errors.proofOfAddress ? ["Document required"] : null}
                                >
                                    <label htmlFor="proofOfAddressDoc">Proof of Address</label>
                                    <FileUpload
                                        documentType={DOC_TYPES.proofOfAddress}
                                        onUpload={(base64, inProgress, onComplete) => {
                                            errors && errors.proofOfAddress && onResetError('proofOfAddress');
                                            onHandleUpload && onHandleUpload(base64, inProgress, onComplete, DOC_TYPES.proofOfAddress)
                                        }}
                                        onRemoveDocument={() => {
                                            errors && errors.proofOfAddress && onResetError('proofOfAddress');
                                            onHandleRemoveDocument && onHandleRemoveDocument(DOC_TYPES.proofOfAddress);
                                        }}
                                    />
                                </FormField>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="action-button-container" style={{width: "100%"}}>
                <div/>
                <button
                    className="action-btn-secondary"
                    onClick={onSubmit}
                    type="button"
                >Add
                </button>
            </div>
        </div>
    )
};

export const NaturalPerson = (props) => {
    const {
        errors, newRP, onChange, onResetError, onHandleUpload, onHandleRemoveDocument, onSubmit, showUpload,
        shouldDisableInput, onSearch, idDoc, handleUpload, rsaCitizen
    } = props;

    if (!newRP) return null;
    const showUploadButtons = showUpload ? showUpload : null;

    const phoneOptions = _.map(newRP.telephones, (t) => ({value: t.msisdn, label: t.msisdn}));
    const addressOptions = _.map(newRP.addresses, (t) => ({value: t.line1, label: t.line1}));

    const hasPhoneOptions = _.size(phoneOptions) > 0;
    
    
    
    return (
        <div className="container">
            <div className="container">
                <div className="col-xs-12 col-md-6">
                    <div className="card-container-form">
                        <FormField id="regNumber" className="form-group"
                                   error={errors && errors.registrationNumber}>
                            {
                                !shouldDisableInput ?
                                    <div>
                                        <label>ID/Passport Number</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="idNumber"
                                            value={newRP.registrationNumber || ''}
                                            onChange={(event) => {
                                                errors && errors.registrationNumber && onResetError && onResetError('registrationNumber');
                                                onChange(event, 'registrationNumber')
                                            }}
                                        />
                                    </div> :
                                    <SearchComponent
                                        label={"ID Number"}
                                        onChange={(event) => {
                                            errors && errors.registrationNumber && onResetError && onResetError('registrationNumber');
                                            onChange(event, 'registrationNumber')
                                        }}
                                        onSearch={onSearch}
                                        searchValue={newRP && newRP.registrationNumber || ''}
                                    />
                            }
                        </FormField>

                        <FormField className={"form-group"} id="firstname" error={errors && errors.registeredName}>
                            <label>First Name</label>
                            <input
                                type="text"
                                disabled={shouldDisableInput || rsaCitizen}
                                className="form-control"
                                id="firstName"
                                value={newRP.registeredName || ''}
                                onChange={(event) => {
                                    errors && errors.registeredName && onResetError && onResetError('registeredName');
                                    onChange(event, 'registeredName')
                                }}
                            />
                        </FormField>

                        <FormField className={"form-group"} id="lastName" error={errors && errors.lastName}>
                            <label>Surname</label>
                            <input
                                type="text"
                                className="form-control"
                                disabled={shouldDisableInput || rsaCitizen}
                                id="surname"
                                value={newRP.lastName || ''}
                                onChange={(event) => {
                                    errors && errors.lastName && onResetError && onResetError('lastName');
                                    onChange(event, 'lastName')
                                }}
                            />
                        </FormField>


                      
                            <label>Contact Number</label>
                            {
                                hasPhoneOptions ?
                                    <FormField className={"form-group"} id="contactNum" error={errors && errors.contactNumber}>
                                        <Select
                                            menuContainerStyle={{zIndex: 999}}
                                            disabled={shouldDisableInput}
                                            name="contactNumber"
                                            onChange={(event) => props.handleSelectChange(event, 'contactNumber')}
                                            options={phoneOptions}
                                            value={newRP.contactNumber || ''}
                                        />
                                    </FormField>
                                    :
                                    <FormField id="number" error={errors && errors.contactNumber}>
                                        <input
                                            className="form-control"
                                            disabled={shouldDisableInput}
                                            id="contactNumber"
                                            onChange={(event) => {
                                                errors && errors.contactNumber && onResetError && onResetError('contactNumber');
                                                onChange(event, 'contactNumber')
                                            }}
                                            type="text"
                                            value={newRP.contactNumber || ''}
                                        />
                                    </FormField>
                            }
                        
                    </div>
                </div>
                <div className="col-xs-12 col-md-6">
                    <div className="card-container-form">
                        <FormField className={"form-group"} id="emailAddress" error={errors && errors.emailAddress}>
                            <label>Email Address</label>
                            <input
                                type="text"
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="email"
                                value={newRP.emailAddress || ''}
                                onChange={(event) => {
                                    errors && errors.emailAddress && onResetError && onResetError('emailAddress');
                                    onChange(event, 'emailAddress')
                                }}
                            />
                        </FormField>
                        <FormField className={"form-group"} id="sharePercentage"
                                   error={errors && errors.sharePercentage}>
                            <Col xs={12} style={{paddingLeft: 0}}>
                                <label>Shareholding Percentage</label>
                            </Col>
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    disabled={shouldDisableInput}
                                    id="percentage"
                                    value={newRP.sharePercentage || ''}
                                    onChange={(event) => {
                                        errors && errors.sharePercentage && onResetError && onResetError('sharePercentage');
                                        onChange(event, 'sharePercentage')
                                    }}
                                />
                                <span className="input-group-addon">%</span>
                            </div>
                        </FormField>

                        <FormField className={"form-group"} id="cap" error={errors && errors.capacity}>
                            <label>Capacity</label>
                            <input
                                type="text"
                                className="form-control"
                                disabled={shouldDisableInput}
                                id="capacity"
                                value={newRP.capacity || ''}
                                onChange={(event) => {
                                    errors && errors.capacity && onResetError && onResetError('capacity');
                                    onChange(event, 'capacity')
                                }}
                            />
                        </FormField>
                        {idDoc &&
                        <FormGroup>
                            <div className={'row'} style={{marginLeft: '0'}}>
                                <label className={'control-label'}>Identity Document</label>
                            </div>
                            <FileUpload
                                disabled={false}
                                documentType={'Identity Document'}
                                onUpload={handleUpload}
                                onRemoveDocument={false}
                            />
                        </FormGroup>
                        }
                        <div style={{zIndex: -1, padding: 0, margin: 0}}>

                            {
                                showUploadButtons &&
                                <FormField
                                    className="form-group"
                                    style={{display: "flex", flexDirection: "column"}}
                                    error={errors && errors.proofOfAddress ? ["Document required"] : null}
                                >
                                    <label>Proof of Address</label>
                                    <FileUpload
                                        documentType={DOC_TYPES.proofOfAddress}
                                        onUpload={(base64, inProgress, onComplete) => {
                                            errors && errors.proofOfAddress && onResetError('proofOfAddress');
                                            onHandleUpload && onHandleUpload(base64, inProgress, onComplete, DOC_TYPES.proofOfAddress)
                                        }}
                                        onRemoveDocument={() => {
                                            errors && errors.proofOfAddress && onResetError('proofOfAddress');
                                            onHandleRemoveDocument && onHandleRemoveDocument(DOC_TYPES.proofOfAddress);
                                        }}
                                    />
                                </FormField>
                            }

                            {
                                showUploadButtons &&
                                <FormField
                                    className="form-group"
                                    style={{display: "flex", flexDirection: "column"}}
                                    error={errors && errors.proofOfId ? ["Document required"] : null}
                                >
                                    <label>Proof of ID</label>
                                    <FileUpload
                                        documentType={DOC_TYPES.proofOfId}
                                        onUpload={(base64, inProgress, onComplete) => {
                                            errors && errors.proofOfId && onResetError('proofOfId');
                                            onHandleUpload && onHandleUpload(base64, inProgress, onComplete, DOC_TYPES.proofOfId)
                                        }}
                                        onRemoveDocument={() => {
                                            errors && errors.proofOfId && onResetError('proofOfId');
                                            onHandleRemoveDocument && onHandleRemoveDocument(DOC_TYPES.proofOfId);
                                        }}
                                    />
                                </FormField>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="action-button-container" style={{width: "100%"}}>
                <div/>
                <button
                    className="action-btn-secondary"
                    onClick={onSubmit}
                    type="button"
                >Add
                </button>
            </div>
        </div>
    )
};
