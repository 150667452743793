import initialState from './initialState';
import * as types from '../actions/actionTypes';


export default function trackingReducer(state = initialState.tracking, action) {
    switch (action.type) {
        case types.LOGIN_TRACKING:
        case types.TRACKING: {
            const {key, value} = action;
            const update = {...state};
            update[key] = `${value}`;
            if (key === 'pageName') {
                update.pageSubSection1 = '';
                update.pageSubSection2 = '';
                update.pageSubSection3 = '';
            }
            window.dataLayer = update;
            try {
                _satellite.track('globalVirtualPageView', dataLayer);
            } catch (e) {
                //satellite failed to load
            }
            return update;
        }
        case types.TRACKING_DATA: {
            const {key, value} = action;
            const update = {...state};
            update[key] = `${value}`;
            window.dataLayer = update;
            return update;
        }
        case types.TRACKING_SUBMIT: {
            try {
                _satellite.track('globalVirtualPageView', dataLayer);
            } catch (e) {
                //satellite failed to load
            }
            return state;
        }
        case types.START_APPLICATION: {
            const update = {...state};
            update.applicationStart = true;
            update.applicationName = action.applicationName;
            window.dataLayer = update;
            _satellite.track('globalApplicationStart');
            return update;
        }
        case types.COMPLETE_APPLICATION: {
            const update = {...state};
            update.applicationComplete = true;
            window.dataLayer = update;
            _satellite.track('globalApplicationComplete');
            return update;
        }
        default:
            return state;
    }
}
