import React from 'react';
import {withRouter} from 'react-router';
import moment from 'moment';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {trackData} from "../actions/analytics";
import * as actions from '../actions/user';
import {loadAnnouncement} from '../actions/announcement';
import {loadImportantNotices} from '../actions/importantNotice';
import {AUTH_URL, HOSTNAME} from "../api-config";
import LandingPage from "./LandingPage";

const queryString = require("query-string");

export default function requireAuth(Component) {

    class AuthenticatedComponent extends React.Component {
        isLoggedIn = false;

        componentWillMount() {
            this.checkAuth();
        }

        checkAuth() {
            console.log("checking host" + HOSTNAME);
            console.log("checking auth" + AUTH_URL);
            const {actions} = this.props;
            const parsedHash = queryString.parse(window.location.hash);
            if (parsedHash.access_token !== undefined) { //We just received a token ...
                console.log("Received new token, we good!");
                localStorage.setItem('access_token', parsedHash.access_token);
                const expiresAt = moment().add(parsedHash.expires_in * 1000, 'milliseconds');
                localStorage.setItem('token_expires_at', expiresAt.valueOf());
                this.isLoggedIn = true;
                actions.trackData("loginStatus", "Logged in");
                actions.loadProfile();
                localStorage.setItem('flag', true); 
                actions.loadAnnouncement();
                actions.loadImportantNotices();
            } else {
                console.log("Didn't receive token, checking localStorage ...");
                const now = moment();
                const accessToken = localStorage.getItem('access_token');
                const tokenExpiresAt = localStorage.getItem('token_expires_at');

                let doLogin = true;
                if (accessToken) {
                    const expiresAt = moment(parseInt(tokenExpiresAt));
                    const timeFormat = "YYYY-MM-DD HH:mm:SSSSSSSSS";
                    console.log("Token will expire at :" + expiresAt.format(timeFormat));
                    doLogin = expiresAt === null || expiresAt.isBefore(now);
                    console.log("Token expired?", doLogin);
                    this.isLoggedIn = !(doLogin);
                    if (this.isLoggedIn) {
                        actions.trackData("loginStatus", "Logged in");
                        actions.loadProfile();
                        localStorage.setItem('flag', true); 
                        actions.loadAnnouncement();
                        actions.loadImportantNotices();
                    }

                }

                if (doLogin && window.location.href.includes('staff')) {
                    actions.trackData("loginStatus", "Guest");
                    console.log("Token expired, initialize outh ...");
                    localStorage.removeItem('token_expires_at');
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('state');
                    window.location.hash = '';
                    // const redirectUrl = window.location.href.replace('/#', '');
                    const redirectUrl = window.location.protocol.concat('//').concat(window.location.hostname).concat('/');
                    const authorizePayload = "?response_type=token".concat("&redirect_uri=").concat(redirectUrl)
                        .concat("&client_id=dgbk-clientonboarding-web")
                        .concat("&scope=read write")
                        .concat("&state=dgbk-oauth");


                    const authorizeUrl = AUTH_URL.concat('/oauth/authorize').concat(encodeURI(authorizePayload));
                    console.log("To navigate to auth for env :", authorizeUrl);
                    window.location.replace(authorizeUrl);
                }
            }
            // Remove hash
            window.location.hash = '';
        }

        render() {
            return this.isLoggedIn ? <Component isLoggedIn={this.isLoggedIn} {...this.props} /> : <LandingPage/>
        }
    }

    function mapDispatchToProps(dispatch) {
        return {actions: bindActionCreators({...actions, loadAnnouncement, loadImportantNotices, trackData}, dispatch)}
    }

    return withRouter(connect(null, mapDispatchToProps)(AuthenticatedComponent));
}
