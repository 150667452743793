import React from "react";
import _ from "lodash";
import FormField from "../../formField/FormField";
import {ButtonToolbar, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';

import "../../../styles/ConfirmApplication.css";
import "../../../styles/Global.css";
import "react-datepicker/dist/react-datepicker.css";

const labelClassList = "col-xs-12 col-sm-4 col-md-4";
const inputClassList = "col-xs-12 col-sm-8 col-md-8";

export const BankBeneficiaryComponent = (props) => {

    return (
       <div className="col-md-12" style={{minHeight: 200}}>
            <div className="form-horizontal col-md-12" style={{padding: "0 10"}}>
                <div className="col-md-12">

                    <div className="section-title col-md-12" style={{paddingBottom: 0, marginBottom: "0px"}}>
                        <span>Bank Beneficiary (Field 58)</span>
                        <hr style={{padding: 0}}/>
                    </div>

                    <div className="col-md-12 form-group">
                        <ButtonToolbar>
                            <ToggleButtonGroup
                                type="radio"
                                name="bankBeneficiaryBIC"
                                value={props.instruction && props.instruction.bankBeneficiaryBIC}
                                onChange={(event) => props.handleOnToggle(event, "bankBeneficiaryBIC")}>
                                <ToggleButton value={true}>Have BIC</ToggleButton>
                                <ToggleButton value={false}>No BIC</ToggleButton>
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>

                    {
                        props.instruction.bankBeneficiaryBIC ? (
                            <div className="col-md-12 form-group">

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="bankBenSwiftAddress">Swift Address</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="bankBenSwiftAddress" type="text" name="bankBenSwiftAddress"
                                                    value={props.instruction && props.instruction.bankBenSwiftAddress}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="bankBenAccountNumber">Account Number</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="bankBenAccountNumber" type="text" name="bankBenAccountNumber"
                                                    value={props.instruction && props.instruction.bankBenAccountNumber}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="bankBenClearingCode">Clearing Code</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="bankBenClearingCode" type="text" name="bankBenClearingCode"
                                                    value={props.instruction && props.instruction.bankBenClearingCode}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        ) : (
                            <div>
                            <div className="col-md-12 form-group">

                                <div className="col-md-4">
                                        <label htmlFor="bankBenInstitutionName">Institution Name</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="bankBenInstitutionName" type="text" name="bankBenInstitutionName"
                                                    value={props.instruction && props.instruction.bankBenInstitutionName}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="bankBenAccountNumber">Account Number</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="bankBenAccountNumber" type="text" name="bankBenAccountNumber"
                                                    value={props.instruction && props.instruction.bankBenAccountNumber}
                                                    onChange={(event) => props.handleOnChange(event)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="bankBenAddress1">Address</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="bankBenAddress1" type="text" name="bankBenAddress1"
                                                    value={props.instruction && props.instruction.bankBenAddress1}
                                                    onChange={(event) => props.handleOnChange(event)} placeholder="Address Line 1"/>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="bankBenAddress2">&nbsp;</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="bankBenAddress2" type="text" name="bankBenAddress2"
                                                    value={props.instruction && props.instruction.bankBenAddress2}
                                                    onChange={(event) => props.handleOnChange(event)} placeholder="Address Line 2"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-12 form-group">
                                    <div className="col-md-4">
                                        <label htmlFor="bankBenAddress3">&nbsp;</label>
                                    </div>
                                    <div className="col-md-8">
                                        <div>
                                            <input className="form-control" id="bankBenAddress3" type="text" name="bankBenAddress3"
                                                    value={props.instruction && props.instruction.bankBenAddress3}
                                                    onChange={(event) => props.handleOnChange(event)} placeholder="Address Line 3"/>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        )
                    }

                    <div className="col-md-12 form-group">
                        <div className="col-md-4">
                            <label htmlFor="bankBenIbanNo">Iban Number</label>
                        </div>
                        <div className="col-md-8">
                            <div>
                                <input className="form-control" id="bankBenIbanNo" type="text" name="bankBenIbanNo"
                                        value={props.instruction && props.instruction.bankBenIbanNo}
                                        onChange={(event) => props.handleOnChange(event)} placeholder="Iban Number"/>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )

}