let backendHost = 'https://businessonboarding.standardbank.co.za';
let authHost = 'https://auth.businessonboarding.standardbank.co.za';
let onehubUrl = 'https://onehub.standardbank.com';
const onehubUrlSit ='https://release-standardbankgroupcommunity.cs173.force.com';
const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
    backendHost = 'https://sit.businessonboarding.standardbank.co.za';
    authHost='https://auth.sit.businessonboarding.standardbank.co.za';
    onehubUrl=onehubUrlSit;
} else if(hostname === 'sit.businessonboarding.standardbank.co.za') {
    backendHost = 'https://sit.businessonboarding.standardbank.co.za';
    authHost='https://auth.sit.businessonboarding.standardbank.co.za';
    onehubUrl=onehubUrlSit;
}

export const API_ROOT = `${backendHost}`;
export const AUTH_URL= `${authHost}`;
export const HOSTNAME= `${hostname}`;
export const ONEHUB_URL=`${onehubUrl}`;
