import React, {Component} from 'react';
import _ from 'lodash';

import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import '../../styles/App.scss';
import '../../styles/EntityDetails.css';
import '../../styles/NewEntityDetails.css';
import '../../styles/Global.css';

import * as entityActions from '../../actions/entity';
import {uploadDoc, verifyDoc, clearIndiviualInfo, fetchIndiviualInfo, updateDoc} from '../../actions/kyc';
import {
    startKycMaintenance,
    onChangeValue,
    onDirChangeValue,
    setEntity,
    onNewDoc,
    saveKYC,
    completeKYC,
    onNewRelatedParty,
    onRemoveRelatedParty,
    onNewAuthorisedPerson,
    onRemoveAuthorisedPerson,
    kycSaved,
    onAddressChangeValue,
    addOwner,
    pip,
    updatePips,
    updateController
} from '../../actions/kycMaintenance';
import {clearEntityInfo, removeSearched} from '../../actions/entity';
import validate from 'validate.js';
import FormField from '../formField/FormField';
import DocumentViewer from '../DocumentViewer';
import Popup from '../Popup';
import AuthorisedPersonsComponent from './AuthorisedPersonsComponent';
import Alert from 'react-bootstrap/es/Alert';
import Button from 'react-bootstrap/es/Button';
import RelatedPartiesNew from './RelatedPartiesNew';
import Col from 'react-bootstrap/lib/Col';
import AdditionalQuestions from './AdditionalQuestions';
import Modal from 'react-bootstrap/lib/Modal';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Row from "react-bootstrap/lib/Row";
import InputGroup from "react-bootstrap/lib/InputGroup";
import ButtonsComponent from "../ButtonsComponent";


const DOC_TYPES = {
    proofOfId: 'Proof of Identity',
    proofOfAddress: 'Proof Of Address'
};

class KycMaintenance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            validationErrors: [],
            submitting: false,
            submittingError: null,
            financials: true,
            cfoLetter: null,
            auditorLetter: null,
            accountStatements: null,
            netAssetValue: '',
            formattedValue: '',
            isEditing: false, // To track if the input is being edited
        };

    }

    handleDocDesc = (desc) => {
        financialDoc.description = desc;
        dispatch(updateDoc(financialDoc));
    };


    handleEntityInfoChange = ({target}) => {
        const {actions} = this.props;
        const {name, value} = target;
        actions.onChangeValue(name, value);
    };

    handleAddressChange = ({target}, changingAddress) => {
        const {actions} = this.props;
        const {name, value} = target;
        changingAddress[name] = value;
        actions.onAddressChangeValue('', 'addresses', changingAddress);
    };

    handleDirectorAddressChoice = (selectedDirector, value) => {
        const {actions, kyc: {kycRequest: {directors}}} = this.props;
        const director = directors[selectedDirector];
        const {addresses} = director;
        director.address = addresses[value];
        actions.onDirChangeValue('', 'directors', director);
    };

    handleDirectorChange = (selectedDirector, item, value) => {
        const {actions, kyc: {kycRequest: {directors}}} = this.props;
        const director = directors[selectedDirector];
        director[item] = value;
        actions.onDirChangeValue('', 'directors', director);
    };

    handlePipChange = (pipChange) => {
        const {actions} = this.props;
        actions.pip(pipChange);
    };
    handlePipsUpdate = (pipChange) => {
        const {actions} = this.props;
        actions.updatePips(pipChange);
    };
    handleControlChange = (controllerChange) => {
        const {actions} = this.props;
        actions.updateController(controllerChange);
    };

    handleDirectorAddressChange = (selectedDirector, person) => {
        const {actions, kyc: {kycRequest: {directors}}} = this.props;
        // const director = directors[selectedDirector];
        // director.address = address;

        actions.onDirChangeValue('', selectedDirector, person);
    };

    handleRelatedPartyAddressChange = (selectedRelatedParty, changedAddress) => {
        const {actions, kyc: {kycRequest: {children}}} = this.props;
        const relatedParty = children[selectedRelatedParty];
        relatedParty.address = changedAddress;
        actions.onDirChangeValue('', 'children', relatedParty);
    };

    handleRelatedPartyChange = ({target}, selectedRelatedParty) => {
        const {actions, kyc: {kycRequest: {children}}} = this.props;
        const relatedParty = children[selectedRelatedParty];
        const {name, value} = target;
        relatedParty[name] = value;
        actions.onDirChangeValue("", "children", relatedParty);
    };

    handleAddRelatedParty = (relatedParty) => {
        const {actions} = this.props;
        actions.onNewRelatedParty(relatedParty);
    };
    handleAddOwner = (updatedChild) => {
        const {actions} = this.props;
        actions.addOwner(updatedChild);
    };
    handleAddAuthorisedPerson = async (authorisedPerson) => {
        const {actions} = this.props;
        await actions.onNewAuthorisedPerson(authorisedPerson);
        this.onHandleSave();
    };

    handleRemoveRelatedParty = (relatedParty) => {
        const {actions} = this.props;
        actions.onRemoveRelatedParty(relatedParty);
    };
    handleRemoveAP = (authorisedPerson) => {
        const {actions} = this.props;
        actions.onRemoveAuthorisedPerson(authorisedPerson);
    };

    _findDoc = (entityDocs, documentType) => {
        const docFound = _.find(entityDocs, (doc) => (doc.type === documentType));
        return docFound ? docFound : {type: documentType, isNew: true}
    };
    _findAddress = (addresses, addressType) => {
        const addressFound = _.find(addresses, (address) => (address.type === addressType));
        return addressFound ? addressFound : {type: addressType}
    };

    onHandleUpload = (base64, inProgress, onComplete, onSuccess) => {
        const {actions} = this.props;
        const {selectedDocument} = this.state;
        const handleOnSuccess = (documentId) => {
            selectedDocument.isUpload = true;
            selectedDocument.updated = true;
            selectedDocument.id = documentId;
            this.setState({selectedDocument});
            onSuccess && onSuccess(documentId);
        };
        actions.uploadDoc(base64, handleOnSuccess, null, inProgress, onComplete)
    };


    onHandleSave = () => {
        const {kyc: {id, kycRequest}, actions} = this.props;
        actions.saveKYC(id, kycRequest);
    };
    onHandleSaveContinue = () => {
        const {kyc: {id, kycRequest}, actions, history} = this.props;
        actions.saveKYC(id, kycRequest, history, true);
    };


    


  

    validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    submitReview = () => {
        const {kyc: {id, kycRequest}, actions, history} = this.props;
        const onSuccess = () => {
            actions.kycSaved();
        };
        this.setState({submittingError: null});
        if (this.validateEmail(kycRequest.contactEmail)) {
            this.setState({submitting: false})
            actions.completeKYC(id, kycRequest, history);
        } else {
            this.setState({submittingError: (<span>Please enter a valid <strong>email address</strong>.</span>)})
        }

    }

    checkEntityAddress({addresses}, addressType) {
        const address = this._findAddress(addresses, addressType);
        const {crcCountryId, zipCode, crcRegionId, crcCityId, suburb, streetAddressLine1} = address;
        const check1 = (crcCountryId && zipCode && crcRegionId);
        const check2 = (crcCityId && suburb && streetAddressLine1);
        return !(check1 && check2)
            ? <span>Complete <strong>{addressType} address</strong>.</span> : null;
    }


    static checkAuthorisedAddress({address}) {
        if (!address || _.isEmpty(address))
            return true;
        const {line1, line2, line3, postalCode} = address;
        return !(line1 && line2 && line3 && postalCode);
    }

    checkEntityDetails({name, telephoneNumber, sourceOfIncome, pip, pips, controllers}, validationErrors) {
        _.isEmpty(name) &&
        validationErrors.unshift(<span><strong>Registered Name</strong> cannot be empty!</span>);
        _.isEmpty(telephoneNumber) &&
        validationErrors.unshift(<span><strong>Telephone Number</strong> cannot be empty!</span>);
        _.isEmpty(sourceOfIncome) &&
        validationErrors.unshift(<span><strong>Source of Income</strong> cannot be empty!</span>);
        // typeof pip !== 'boolean' &&
        // validationErrors.unshift(
        //     <span>Please verify if any of the authorised/controlling persons
        //             either a public official in a position of authority or
        //             related/associated to a public official in a position of authority?.</span>);
        // pip && _.isEmpty(pips) &&
        // validationErrors.unshift(<span>Please add the authorised/controlling persons
        //             either a public official in a position of authority or
        //             related/associated to a public official in a position of authority</span>);
        // _.isEmpty(controllers) &&
        // validationErrors.unshift(<span>Please add entity <strong>Controllers</strong>.</span>);
        return validationErrors;
    }

    checkDocument(type, {documents}) {
        const document = this._findDoc(documents, type);
        return document.verified ? null : (<span>Please upload/verify  <strong>"{type} Document"</strong></span>);
    }

    onHandleRemoveDocument = (documentType) => {
        const {onRemoveDocument} = this.props;
        onRemoveDocument && onRemoveDocument(documentType);
    };

    handleResetError = (attribute) => {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    };

    validateDocs = () => {
        const {entityDocs} = this.state;
        const options = {fullMessages: false};

        const checkDocument = (documentType) => {
            const doc = this._findDoc(entityDocs, documentType);
            return doc && doc.verified ? doc : null;
        };

        const constraints = {
            [DOC_TYPES.resolution]: {presence: true},
            [DOC_TYPES.cpicDocument]: {presence: true},
            [DOC_TYPES.ownershipStructure]: {presence: true},
        };
        const errors = validate({
            [DOC_TYPES.resolution]: checkDocument(DOC_TYPES.resolution),
            [DOC_TYPES.cpicDocument]: checkDocument(DOC_TYPES.cpicDocument),
            [DOC_TYPES.ownershipStructure]: checkDocument(DOC_TYPES.ownershipStructure),

        }, constraints, options);
        this.setState({errors});
        return _.isEmpty(errors);
    };

    onNext = () => {
        this.props.history.push('/kyc/entity-people');
    };


    handleViewDocument = (selectedDocument, available = true) => {
        const {errors} = this.state;
        errors && errors.selectedDocument && this.handleResetError('selectedDocument');
        if (available) {
            this.setState({
                selectedDocument: {
                    id: selectedDocument.externalId,
                    type: selectedDocument.type
                },
                document: selectedDocument
            });
        } else {
            this.setState({
                selectedDocument: {
                    id: null,
                    type: selectedDocument.type
                },
                document: selectedDocument
            });
        }

    }

    validateForm() {
        const kycRequest = this.props.kyc.kycRequest;
        const principalAddress = this._findAddress(kycRequest.addresses, 'Principal');
        const tradingAddress = this._findAddress(kycRequest.addresses, 'Trading');
        const options = { fullMessages: false };
    
        const constraints = {
            name: { presence: true, length: { minimum: 1, message: 'required' } },
            // telephoneNumber: { presence: true, length: { minimum: 1, message: 'required' } },
            telephoneNumber: {
                presence: true,
                format: {
                  pattern: /^[0-9]+$/,
                  message: 'Only numbers are allowed.'
                }
              },
            sourceOfFunds: { presence: true, length: { minimum: 1, message: 'required' } },
            sourceOfIncome: { presence: true, length: { minimum: 1, message: 'required' } },
            netAssetValue: { presence: true, numericality: {message: 'required' } },
        };
    
        const primaryAddressConstraints = {
            streetAddressLine1: { presence: true, length: { minimum: 1, message: 'required' } },
            suburb: { presence: true, length: { minimum: 1, message: 'required' } },
            crcCityId: { presence: true, length: { minimum: 1, message: 'required' } },
            crcRegionId: { presence: true, length: { minimum: 1, message: 'required' } },
            crcCountryId: { presence: true, length: { minimum: 1, message: 'required' } },
            zipCode: { presence: true, length: { minimum: 1, message: 'required' } },
        };
    
        const tradingAddressConstraints = {
            streetAddressLine1: { presence: true, length: { minimum: 1, message: 'required' } },
            suburb: { presence: true, length: { minimum: 1, message: 'required' } },
            crcCityId: { presence: true, length: { minimum: 1, message: 'required' } },
            crcRegionId: { presence: true, length: { minimum: 1, message: 'required' } },
            crcCountryId: { presence: true, length: { minimum: 1, message: 'required' } },
            zipCode: { presence: true, length: { minimum: 1, message: 'required' } },
        };
    
        const kycRequestErrors = validate(kycRequest, constraints, options);
        const primaryAddressErrors = validate(principalAddress, primaryAddressConstraints, options);
        const tradingAddressErrors = validate(tradingAddress, tradingAddressConstraints, options);
    
        const errors = { ...kycRequestErrors, ...primaryAddressErrors, ...tradingAddressErrors };
        this.setState({ errors });
    
        return _.isEmpty(errors);
    }
    

    // completeSection = () => {
    //     const {kyc: {kycRequest}} = this.props;
    //     let validationErrors = [];
        // const {directors, children} = kycRequest;
        // children.forEach(relatedParty => {
        //     const empty = KycMaintenance.checkRelatedPartyAddress(relatedParty);
        //     empty && validationErrors.push(
        //         <span>Please complete an address for <strong>"{relatedParty.name}"</strong></span>);
        // });
        // directors.forEach(director => {
        //     const empty = KycMaintenance.checkAuthorisedAddress(director);
        //     empty && validationErrors.push(
        //         <span>Please complete/choose an address for <strong>"{director.firstName} {director.lastName}"</strong></span>);
        // });
        // let checker = this.checkDocument('Ownership Structure', kycRequest);
        // checker && validationErrors.push(checker);
        // checker = this.checkDocument('Resolution', kycRequest);
        // checker && validationErrors.push(checker);
        // checker = this.checkDocument('CIPC Document', kycRequest);
        // checker && validationErrors.push(checker);
        // if (kycRequest.netAssetValue > 20000000) {
        //     checker = this.checkDocument('Financial Statements', kycRequest);
        //     checker && validationErrors.push(checker);
        // }
    //     let checker = this.checkEntityAddress(kycRequest, 'Principal');
    //     checker && validationErrors.unshift(checker);
    //     checker = this.checkEntityAddress(kycRequest, 'Trading');
    //     checker && validationErrors.unshift(checker);
    //     validationErrors = this.checkEntityDetails(kycRequest, validationErrors);
    //     validationErrors.length > 0 ? this.setState({validationErrors}) : this.onNext();
    // };

    completeSection = () => {
        if (this.validateForm()) {
            this.onNext();
        }
    };

    formatCurrency = (value) => {
        const numericValue = parseFloat(value) || 0;
        return numericValue.toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    handleEntityInfoChangeForNetAssetValue = (event) => {
        const { value } = event.target;
        // Remove any commas from the value and check if it's a valid number
        const numericValue = value.replace(/,/g, '');
        
        if (/^-?\d{0,13}(\.\d{0,2})?$/.test(numericValue)) {
            this.setState({
                netAssetValue: value,
            });
        }
    };

    handleInputBlur = () => {
        const kycRequest = this.props.kyc.kycRequest;
        let { netAssetValue } = this.state;

        // Remove any commas and trailing ".00"
        const numericValue = netAssetValue
            .replace(/,/g, '')  // Remove commas
            .replace(/\.00$/, '');  // Remove ".00"
        let formattedValue;

        if (numericValue !== '' && !isNaN(numericValue) && parseFloat(numericValue)) {
            // Add ".00" if it's not already there
            const finalValue = numericValue.includes('.00')
                ? numericValue
                : numericValue + '.00';

            // Update the property in the kycRequest object without commas
            kycRequest.netAssetValue = numericValue;

            // Format the value using the formatCurrency function
            formattedValue = this.formatCurrency(finalValue);

            // Set the formatted value with commas to the state for display
            this.setState({
                netAssetValue: formattedValue,
            });
        } else {
            // Handle case when input is not a valid number or less than or equal to 0
            formattedValue = '';
            kycRequest.netAssetValue = '';
        }
        this.setState({
            netAssetValue: formattedValue,
        });
    };
    
    allowOnlyCharacters = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^A-Za-z\s]/g, '');
        event.target.value = value;
    }

    allowOnlyNumbers = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^0-9]/g, '');
        event.target.value = value;
    }


    renderEntityDetails = () => {
        const {errors} = this.state;
        const { netAssetValue } = this.state;
        // const formattedValue = this.formatCurrency(netAssetValue);
        // const {kyc: {kycRequest}} = this.props;
        const kycRequest = this.props.kyc.kycRequest;

        const principalAddress = this._findAddress(kycRequest.addresses, 'Principal');
        const tradingAddress = this._findAddress(kycRequest.addresses, 'Trading');

      
        return (
            <div className="row" style={{margin:0}}>
                <div className="row" style={{margin:0}}>
                    <div className="col-md-6">
                        <FormField className="form-group">
                            <label htmlFor="tradingAs">Trading As</label>
                            <input
                                type="text"
                                className="form-control"
                                id="tradingAs"
                                name='tradingAs'
                                value={kycRequest.tradingAs ? kycRequest.tradingAs : ''}
                                onChange={(event) => this.handleEntityInfoChange(event)}
                            />
                        </FormField>
                    </div>
                    <div className="col-md-6">
                        <FormField className="form-group" id="regName" error={errors && errors.name}>
                            <label htmlFor="name">Registered Name <span
                                className='text-danger'>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name='name'
                                value={kycRequest.name ? kycRequest.name : ""}
                                onChange={(event) => {
                                    errors && errors.name && onResetError && onResetError('name');
                                    this.handleEntityInfoChange(event);
                                }}
                            />
                        </FormField>
                    </div>
                    <div className="col-md-6">
                        <FormField className="form-group">
                            <label htmlFor="vatNumber">VAT Number</label>
                            <input
                                type="text"
                                className="form-control"
                                id="vatNumber"
                                maxLength={10}
                                name='vatNumber'
                                value={kycRequest.vatNumber ? kycRequest.vatNumber : ""}
                                onChange={(event) => {
                                    this.allowOnlyNumbers(event);
                                    this.handleEntityInfoChange(event);
                                }}
                            />
                        </FormField>
                    </div>
                    <div className="col-md-6">
                        <FormField className="form-group" error={errors && errors.telephoneNumber}>
                            <label htmlFor="telephoneNumber">Telephone Number <span
                                className='text-danger'>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="telephoneNumber"
                                name='telephoneNumber'
                                // value={kycRequest.telephoneNumber ? kycRequest.telephoneNumber : ""}
                                value={kycRequest.telephoneNumber && kycRequest.telephoneNumber !== 'unknown' ? kycRequest.telephoneNumber : ""}
                                onChange={(event) => {
                                    this.allowOnlyNumbers(event);
                                    this.handleEntityInfoChange(event);
                                }}
                            />
                        </FormField>
                    </div>
                    <div className="col-md-6">
                        <FormField className="form-group" id="sourceOfFunds" error={errors && errors.sourceOfFunds}>
                            <label htmlFor="sourceOfFunds">Source of Funds</label>
                            <input
                                type="text"
                                className="form-control"
                                name='sourceOfFunds'
                                id="sourceOfFunds" disabled={true} readOnly={true}
                                value={kycRequest.sourceOfFunds ? kycRequest.sourceOfFunds : ""}
                            />
                        </FormField>
                    </div>
                    <div className="col-md-6">
                        <FormField className="form-group" id="sourceOfIncome" error={errors && errors.sourceOfIncome}>
                            <label htmlFor="sourceOfIncome">Source of Income <span
                                className='text-danger'>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="sourceOfIncome"
                                name='sourceOfIncome'
                                onChange={(event) => {
                                    this.allowOnlyCharacters(event);
                                    this.handleEntityInfoChange(event);
                                }}
                                value={kycRequest.sourceOfIncome ? kycRequest.sourceOfIncome : ""}
                            />
                        </FormField>
                    </div>
                    <div className="col-md-6">
                        <FormField className="form-group" id="netAssetValue" error={errors && errors.netAssetValue}>
                            <Row style={{marginLeft: '0'}}>
                                <label htmlFor="sourceOfIncome">Net Asset Value <span
                                    className='text-danger'>*</span></label>
                            </Row>
                            <InputGroup>
                                <InputGroup.Addon>R</InputGroup.Addon>
                                {/* <input
                                    type="number"
                                    className="form-control"
                                    id="netAssetValue"
                                    name='netAssetValue'
                                    onChange={e => this.handleEntityInfoChange(e)}
                                    value={kycRequest.netAssetValue ? kycRequest.netAssetValue : ""}
                                /> */}
                                <input
                                    type="text" 
                                    className="form-control"
                                    id="netAssetValue"
                                    name="netAssetValue"
                                    onChange={this.handleEntityInfoChangeForNetAssetValue}
                                    onBlur={this.handleInputBlur}
                                    value={netAssetValue || kycRequest.netAssetValue}
                                />
                            </InputGroup>
                        </FormField>
                    </div>
                </div>

                <div className="row" style={{margin:0}}>
                    <div className="col-md-6">
                        <div className="section-title">
                            <span>Primary Address <span className='text-danger'>*</span></span>
                        </div>

                        <FormField className="form-group" id="regAddress" error={errors && errors.streetAddressLine1}>
                            <label htmlFor="registeredAddressLin1">Address</label>
                            <input
                                className="form-control"
                                id="registeredAddressLine1"
                                name='streetAddressLine1'
                                onChange={(event) => {
                                errors && errors.street && onResetError && onResetError('street');
                                this.handleAddressChange(event, principalAddress);
                                }}
                                type="text"
                                value={principalAddress.streetAddressLine1 ? principalAddress.streetAddressLine1 : ""}
                            />
                        </FormField>

                        <FormField className="form-group" id="line2" error={errors && errors.suburb} >
                            <label htmlFor="registeredAddressLine2">Suburb</label>
                            <input
                                className="form-control"
                                id="registeredAddressLine2"
                                name='suburb'
                                onKeyPress={this.handleKeyPress}
                                onChange={(event) => {
                                    errors && errors.suburb && onResetError && onResetError('suburb');
                                    this.allowOnlyCharacters(event); 
                                    this.handleAddressChange(event, principalAddress);
                                }}
                                type="text"
                                value={principalAddress.suburb ? principalAddress.suburb : ""}
                            />
                        </FormField>

                        <FormField className="form-group" id="regCity" error={errors && errors.crcCityId}>
                            <label htmlFor="registeredCity">City</label>
                            <input
                                type="text"
                                className="form-control"
                                id="registeredCity"
                                name='crcCityId'
                                value={principalAddress.crcCityId ? principalAddress.crcCityId : ""}
                                onChange={(event) => {
                                    errors && errors.city && onResetError && onResetError('city');
                                    this.allowOnlyCharacters(event); 
                                    this.handleAddressChange(event, principalAddress);
                                }}
                            />
                        </FormField>

                        <FormField className="form-group" id="regProvince" error={errors && errors.crcRegionId}>
                            <label htmlFor="stateOrProvince">State/Province</label>
                            <input
                                type="text"
                                className="form-control"
                                id="stateOrProvince"
                                name='crcRegionId'
                                value={principalAddress.crcRegionId ? principalAddress.crcRegionId : ""}
                                onChange={(event) => {
                                    errors && errors.province && onResetError && onResetError('province');
                                    this.allowOnlyCharacters(event); 
                                    this.handleAddressChange(event, principalAddress);
                                }}
                            />
                        </FormField>
                    
                        <FormField className="form-group" id="regPostalCode" error={errors && errors.zipCode}>
                            <label htmlFor="stateOrProvince">Zip/Postal Code</label>
                            <input
                                type="text"
                                className="form-control"
                                id="registoredZipOrPostalCode"
                                name='zipCode'
                                value={principalAddress.zipCode ? principalAddress.zipCode : ""}
                                onChange={(event) => {
                                    errors && errors.postalCode && onResetError && onResetError('postalCode');
                                    this.allowOnlyNumbers(event);
                                    this.handleAddressChange(event, principalAddress);
                                }}
                            />
                        </FormField>

                        <FormField className="form-group" id="regCountry" error={errors && errors.crcCountryId}>
                            <label htmlFor="stateOrProvince">Country</label>
                            <input
                                type="text"
                                className="form-control"
                                id="country"
                                disabled="disabled"
                                name='crcCountryId'
                                value={principalAddress.crcCountryId ? principalAddress.crcCountryId : "SOUTH AFRICA"}
                                onChange={(event) => {
                                    errors && errors.country && onResetError && onResetError('country');
                                    this.allowOnlyCharacters(event); 
                                    this.handleAddressChange(event, principalAddress);
                                }}
                            />
                        </FormField>
                    </div>
                    <div className="col-md-6">
                        <div className="section-title">
                            <span>Trading Address <span className='text-danger'>*</span></span>
                        </div>

                        <FormField className="form-group" id="regAddress" error={errors && errors.streetAddressLine1}>
                            <label htmlFor="registeredAddressLin1">Address</label>
                            <input
                                className="form-control"
                                id="tradingAddressLine1"
                                name='streetAddressLine1'
                                onChange={(event) => {
                                    errors && errors.street && onResetError && onResetError('street');
                                    this.handleAddressChange(event, tradingAddress);
                                }}
                                type="text"
                                value={tradingAddress.streetAddressLine1 ? tradingAddress.streetAddressLine1 : ""}

                            />
                        </FormField>

                        <FormField className="form-group" id="line2" error={errors && errors.suburb}>
                            <label htmlFor="registeredAddressLine2">Suburb</label>
                            <input
                                className="form-control"
                                id="tradingAddressLine2"
                                name='suburb'
                                onChange={(event) => {
                                    errors && errors.suburb && onResetError && onResetError('suburb');
                                    this.allowOnlyCharacters(event); 
                                    this.handleAddressChange(event, tradingAddress);
                                }}
                                type="text"
                                value={tradingAddress.suburb ? tradingAddress.suburb : ""}
                            />
                        </FormField>

                        <FormField className="form-group" id="regCity" error={errors && errors.crcCityId}>
                            <label htmlFor="tradingCity">City</label>
                            <input
                                type="text"
                                className="form-control"
                                id="tradingCity"
                                name='crcCityId'
                                value={tradingAddress.crcCityId ? tradingAddress.crcCityId : ""}
                                onChange={(event) => {
                                    errors && errors.city && onResetError && onResetError('city');
                                    this.allowOnlyCharacters(event); 
                                    this.handleAddressChange(event, tradingAddress);
                                }}
                            />
                        </FormField>

                        <FormField className="form-group" id="regProvince" error={errors && errors.crcRegionId}>
                            <label htmlFor="stateOrProvince">State/Province</label>
                            <input
                                type="text"
                                className="form-control"
                                id="tradingStateOrProvince"
                                name='crcRegionId'
                                value={tradingAddress.crcRegionId ? tradingAddress.crcRegionId : ""}
                                onChange={(event) => {
                                    errors && errors.province && onResetError && onResetError('province');
                                    this.allowOnlyCharacters(event); 
                                    this.handleAddressChange(event, tradingAddress);
                                }}
                            />
                        </FormField>

                        <FormField className="form-group" id="regPostalCode" error={errors && errors.zipCode}>
                            <label htmlFor="stateOrProvince">Zip/Postal Code</label>
                            <input
                                type="text"
                                className="form-control"
                                id="tradingZipOrPostalCode"
                                name='zipCode'
                                value={tradingAddress.zipCode ? tradingAddress.zipCode : ""}
                                onChange={(event) => {
                                    errors && errors.postalCode && onResetError && onResetError('postalCode');
                                    this.allowOnlyNumbers(event);
                                    this.handleAddressChange(event, tradingAddress);
                                }}
                            />
                        </FormField>

                        <FormField className="form-group" id="regCountry" error={errors && errors.crcCountryId}>
                            <label htmlFor="stateOrProvince">Country</label>
                            <input
                                type="text"
                                className="form-control"
                                id="tradingCountry"
                                disabled="disabled"
                                name='crcCountryId'
                                value={tradingAddress.crcCountryId ? tradingAddress.crcCountryId : "SOUTH AFRICA"}
                                onChange={(event) => {
                                    errors && errors.country && onResetError && onResetError('country');
                                    this.allowOnlyCharacters(event); 
                                    this.handleAddressChange(event, tradingAddress)
                                }}
                            />
                        </FormField>
                    </div>
                </div>

            </div>
        );
    }

    renderUpdater = () => {
        const {kyc: {kycRequest}} = this.props;
        if (kycRequest) {
            const wellStyles = {maxWidth: 400, margin: '0 auto 10px'};
            const shareholderDoc = this._findDoc(kycRequest.documents, 'Ownership Structure');
            const proofOfAuthDoc = this._findDoc(kycRequest.documents, 'Resolution');
            const proofOfLegalDoc = this._findDoc(kycRequest.documents, 'CIPC Document');
            const finStatementsDoc = this._findDoc(kycRequest.documents, 'Financial Statements');
            const handleDocDesc = (desc) => {
                finStatementsDoc.description = desc;
                switch (desc) {
                    case 'financials':
                        this.setState({
                            financials: true,
                            accountStatements: false,
                            cfoLetter: false,
                            auditorLetter: false
                        })
                        break;
                    case 'cfoLetter':
                        this.setState({
                            financials: false,
                            accountStatements: false,
                            cfoLetter: true,
                            auditorLetter: false
                        })
                        break;
                    case 'accountStatements':
                        this.setState({
                            financials: false,
                            accountStatements: true,
                            cfoLetter: false,
                            auditorLetter: false
                        })
                        break;
                    case 'auditorLetter':
                        this.setState({
                            financials: false,
                            accountStatements: false,
                            cfoLetter: false,
                            auditorLetter: true
                        });
                        break;
                    default:
                        break;
                }
                // dispatch(updateDoc(financialDoc));
            };
            return (

                <div className="page-container">
                    <div className="page-main-section">
                        {this.renderEntityDetails()}
                        <hr />
                        {
                            this.state.validationErrors.map((error, i) => (
                                <Alert bsStyle="danger" key={i}>
                                    {error}
                                </Alert>
                            ))
                        }
                    </div>
                    <div className="page-footer-section" style={{width: "80%"}}>
                        <ButtonsComponent
                            history={this.props.history}
                            hidePrevious={true}
                            hideSaveAndContinue={true}
                            // onSaveAndContinue={this.onHandleSaveContinue}
                            onNext={this.completeSection}
                        />
                    </div>
                </div>
            );
        }
    }


    render() {
        const {kyc} = this.props;
        const {selectedDocument} = this.state;
        return (
            <div className={'container-fluid'} style={{overflowY: 'auto'}}>
                <div className="col-xs-12">
                    {
                        this.renderUpdater()
                    }
                    {
                        kyc.saved && this.renderSaved()
                    }
                </div>
            </div>
        );

    }
}


function mapStateToProps({kyc, impersonation, loading, individual}) {
    return {
        kyc,
        individual,
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...entityActions,
            startKycMaintenance,
            uploadDoc,
            verifyDoc,
            onChangeValue,
            onDirChangeValue,
            setEntity,
            onNewDoc,
            saveKYC,
            onNewRelatedParty,
            onRemoveRelatedParty,
            onNewAuthorisedPerson,
            onRemoveAuthorisedPerson,
            kycSaved,
            onAddressChangeValue,
            addOwner,
            completeKYC,
            clearEntityInfo,
            removeSearched,
            clearIndiviualInfo,
            fetchIndiviualInfo,
            pip,
            updatePips,
            updateController
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KycMaintenance);
