import React from 'react';
import Popup from "../Popup";

const ImpersonationRequiredMessage = ({onClose})=>(
    <Popup onClose={onClose}>
        <div>
            <div className="product-heading">
                Info
            </div>
            <div className="title-gradient"/>
            <br/>
            <span style={{color: "black"}}>
                Please impersonate a customer.
            </span>
        </div>
    </Popup>
);

export default  ImpersonationRequiredMessage;