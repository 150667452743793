import React from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {DataList} from 'primereact/components/datalist/DataList';
import validate from 'validate.js'
import "../styles/ConfirmApplication.css";
import "../styles/Global.css";
import * as actions from '../actions/kyc';
import FormField from "./formField/FormField";
import {newAccountOpeningRoutes} from "../routers/routes";
import {trackData} from "../actions/analytics";
import {SelectiveRulesPage} from "./selectiveapproval/SelectiveRulesPage";
import { ListSearchComponent } from "./SearchComponent";


class ConfirmApplicationPage extends React.Component {
    state = {
        form: {emailAddress: ''}, errors: {},
        searchTerm: null,
        selectedApprovers:[],
        approversA : [],
        approversB : [],
        errormsg : false,
        currentSelected : '',
        levelDataList : []
    };



    componentDidMount() {

        this.props.actions.trackData('pageName', 'New Account| Confirm Authorizers| step 5');
    }

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }


    validateCount = () => {

         const { approversA,approversB,currentSelected,levelDataList} = this.state;
         console.log(levelDataList);
          function isCountValid (){
            for ( const level of levelDataList){
                const counts = Object.values(level)[0];
                if (approversA.length === counts.countOfA && approversB.length === counts.countOfB){
                return true;
                }}
                return false;
         };

         if (currentSelected==='Yes' && !isCountValid()) {
            this.setState({errormsg : true});
            return false
         }
         return true;
        }

    validateForm = () => {
        const {form} = this.state;
        const options = {fullMessages: false};
        const constraints = {emailAddress: {presence: true, email: true, length: {minimum: 1, message: 'required'}}};
        const errors = validate(form, constraints, options);
        this.setState({errors});
        return this.validateCount() && _.isEmpty(errors) ;
    };

    updateLevelDataList = (levelData) => {
      this.setState({
              levelDataList : levelData
      })
    }


    updateSelection = (selected) => {
        this.setState({
                currentSelected:selected
        })
        }

    saveDetails = (event) => {
        event.preventDefault();
        const {form,currentSelected} = this.state;
        if (this.validateForm()) {
            const {actions, applicationId, history} = this.props;
            const onProceed = () => history && history.push(newAccountOpeningRoutes.confirmapplication);
           if (currentSelected === 'No') {
             const prevSelectedApprovers = [];
             actions.complete(applicationId, form.emailAddress, onProceed, prevSelectedApprovers);
           } else {
             actions.complete(applicationId, form.emailAddress, onProceed, this.state.selectedApprovers);
           }


        }
    };

    handleChange = (e) => {
        const {form} = this.state;
        const emailAddress = _.trimStart(e.target.value);
        this.setState({form: _.extend({}, form, {emailAddress})});
    };

    handleSelections = (e, identityNumber, approvalLevel) => {
      const { selectedApprovers, approversA, approversB, errormsg } = this.state;
      const authorizer = { identityNumber, approvalLevel};
      errormsg && this.setState({errormsg: false});


      if (selectedApprovers.some(a => a.identityNumber === identityNumber)) {
        this.setState(prevState => ({
          selectedApprovers: prevState.selectedApprovers.filter(a => a.identityNumber !== identityNumber),
          approversA: prevState.approversA.filter(a => a.identityNumber !== identityNumber),
          approversB: prevState.approversB.filter(a => a.identityNumber !== identityNumber),
        }));
      } else {
        this.setState(prevState => ({
          selectedApprovers: [...prevState.selectedApprovers, authorizer],
          approversA: approvalLevel === "A" ? [...prevState.approversA, authorizer] : prevState.approversA,
          approversB: approvalLevel === "B" ? [...prevState.approversB, authorizer] : prevState.approversB,
        }));
      }
    }

     renderList = (sc) => {
            if (!sc) return null;
            return (
                <div className="row">
                    <div className="col-md-12">
                        <label>{sc.approvalMessage}</label>
                    </div>

                    <hr/>
                </div>
            );
        };

    renderFilteredList(sc) {

        return (
            <div className="row">
                <div className="checkbox-inline column">
                    <FormField >
                        <div className ={sc.approvalMessage.includes('not')? " disable flexRow " : "flexRow"} style={{ alignItems: "flex-start", textAlign: "left" }}>
                            <div className="icon-container"  >
                                <i className={this.state.selectedApprovers.some(a => a.identityNumber === sc.identityNumber)  && !(sc.approvalMessage.includes('not'))? "fa fa-check-square-o" : "fa fa-square-o"}
                                    style={{ color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10 }}
                                     checked={this.state.selectedApprovers.some(a => a.identityNumber === sc.identityNumber) }
                                     disabled= {sc.approvalMessage.includes('not')}
                                     onClick={(event) => this.handleSelections(event, sc.identityNumber,sc.approvalLevel)} />
                            </div>
                            <p style={{ margin: 0 }}> {sc.approvalMessage.substring(0,sc.approvalMessage.indexOf("is"))} </p>
                        </div>
                    </FormField>
                </div>
            </div>
        );
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary btn-sm"
                    onClick={this.saveDetails}
                    type="button"
                >Complete
                </button>
            </div>
        )
    }

    render() {
        const {authorisers} = this.props;
        const {errors, form} = this.state;
        const result1 = authorisers.filter(apA => apA.approvalLevel === 'A' );
        const result2 = authorisers.filter(apB => apB.approvalLevel === 'B' );

        return (
                <div className="page-container">
                    <div className="page-main-section">
                        <div className="flexColumn" >
                            <div className="card-container-form">
                                <FormField>
                                  <SelectiveRulesPage
                                   selectedApprovers = {this.state.selectedApprovers}
                                   renderList = {this.renderList}
                                   renderFilteredList = {this.renderFilteredList.bind(this)}
                                   authorisers = {authorisers}
                                   result1 = {result1}
                                   result2 = {result2}
                                   goldTierId ={this.props.application.legalEntity.goldTierId}
                                   updateLevelDataList = {this.updateLevelDataList}
                                   updateSelection = {this.updateSelection}
                                   updateauthorisers = {this.updateauthorisers}
                                   errormsg = {this.state.errormsg}
                                   />
                                </FormField>


                                <FormField id="kycEmail" error={errors && errors.emailAddress}>
                                    <label>Please Enter Email Address Where The Confirmation Letter Will Be Sent</label>
                                    <input
                                        className="form-control"
                                        id="emailAddress"
                                        onChange={(event) => {
                                            errors && errors.emailAddress && this._resetError('emailAddress');
                                            this.handleChange(event)
                                        }}
                                        type="text"
                                        value={form && form.emailAddress ? form.emailAddress : ''}
                                    />
                                </FormField>
                            </div>
                        </div>
                    </div>
                    <div className="page-footer-section">
                        {this._renderFooter()}
                    </div>
                </div>
            );
    }
}

function mapStateToProps({application, authorisers, loading}) {
    return {
        applicationId: application && application.application ? application.application.id : '',
        application: application && application.application ? application.application : {},
        authorisers: authorisers && authorisers.authorisers ? authorisers.authorisers : [],

        loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, trackData}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmApplicationPage);