import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ListGroupItem from 'react-bootstrap/lib/ListGroupItem';
import Row from 'react-bootstrap/lib/Row';
import ListGroup from 'react-bootstrap/lib/ListGroup';
import Badge from 'react-bootstrap/lib/Badge';
import Col from 'react-bootstrap/lib/Col';
import ButtonsComponent from '../ButtonsComponent';
import Button from 'react-bootstrap/lib/Button';
// import AddNewSignatoryForm from '../maintenance/AddNewSignatoryForm';
import AddNewSignatoryForm from './NewSignatory';

import Popup from '../Popup';
import Modal from 'react-bootstrap/lib/Modal';
import {fetchAccounts, fetchIndiviualInfo, fetchSignatoriesForCif, uploadDoc} from '../../actions/kyc';
import {AddExistingSignatory} from './addExistingSignatory';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Alert from "react-bootstrap/lib/Alert";
import {saveSignatoryApplication, saveAndContinueSignatoryApplication} from "../../actions/signatoryActions";
import {signatoryRoots} from "../../routers/routes";


export const SignatoryReview = (props) => {
    const [addSign, setAddSign] = useState(false);
    const [copySign, setCopySign] = useState(false);
    const [addition, setAddition] = useState(null);
    const accounts = useSelector(state => state.copies.copies && state.copies.copies.accounts);
    const application = useSelector(state => state.signatoriesMaintenance.maintenanceRequest);
    const [instructions, setInstructions] = useState(application.instructions);
    const id = useSelector(state => state.signatoriesMaintenance.id);
    const dispatch = useDispatch();
    const [errors, setErrors] = useState(false);

    useEffect(() => {
        dispatch(fetchSignatoriesForCif(application.entityDTO.cif, null));
    }, []);

    const handleIndividualSearch = (idNumber, onComplete, onError) => {
        dispatch(fetchIndiviualInfo(idNumber, onComplete, 'signatoryCandidate', onError));
    };

    const handleUploadDocument = (base64, inProgress, onComplete, onSuccess = null) => {
        dispatch(uploadDoc(base64, onSuccess, null, inProgress, onComplete));
    };

    // Create an object with a dynamic unique ID
    function generateUniqueId() {
        const timestamp = Date.now().toString(36); // Convert current time to base 36
        const randomString = Math.random().toString(36).substr(2, 5); // Generate a random string
        return timestamp + randomString;
    }
      
    const saveSignatory = (signatory, accounts: Array) => {
        const tempInst = instructions ? [...instructions] : [];
        console.log(signatory);
        signatory.surname = signatory.lastName;
        signatory.signPlusAction = 'ADD';
        accounts.forEach(account => {
            const updatingSignatory = {...signatory, account};
            tempInst.push(updatingSignatory);
        });
        setInstructions(tempInst);
        setAddSign(true);
        dispatch(saveSignatoryApplication(id, tempInst));
    };

    const copySignatory = (signatories: Array, account: any) => {
        let tempInst = instructions ? [...instructions] : [];
        signatories.forEach(signatory => signatory.lastName = signatory.surname);
        signatories.forEach(signatory => signatory.account = account);
        signatories.forEach(signatory => signatory.signPlusAction = 'COPIED');
        signatories.forEach(signatory => signatory.id = generateUniqueId());
        tempInst = tempInst.concat(signatories);
        setInstructions([...tempInst]);
        setCopySign(false);
        dispatch(saveSignatoryApplication(id, tempInst));
    };


    // const deleteInstruction = (signer) => {
    //     const tempInst = [...instructions];
    //     // var index = tempInst.findIndex(x => x.account.accountNumber === signer.account.accountNumber);
    //     var records = tempInst.filter(record => record.id !== signer.id);
    //     // tempInst.splice(index, 1 );
    //     // const filteredArray = tempInst.filter((_, idx) => {
    //     //      return idx !== index;
    //     // });
    //     // console.log(filteredArray);
    //     // instructions.splice(0);
    //     setInstructions(records);
    //     onSave(records);
    // };

    const deleteInstruction = (signer) => {
        const tempInst = instructions.filter(instruction => 
            instruction.idOrPassportNumber !== signer.idOrPassportNumber || 
            instruction.account.accountNumber !== signer.account.accountNumber
        );
        
        setInstructions(tempInst);
        // onSave(tempInst);
    };
    

    const onSave = () => {
        const { history } = props;
        if (instructions && instructions.length > 0) {
            let nextPage = '/saveapplication';
            let saveAndContinue = true;
            const onProceed = () => history.push(nextPage);
            dispatch(saveAndContinueSignatoryApplication(id, instructions, saveAndContinue, onProceed));
        }
        else{
            setErrors(true);
        }
    };

    const onNext = () => {
        if (instructions && instructions.length > 0) {
            dispatch(saveSignatoryApplication(id, instructions));
            props.history.push(signatoryRoots.confirmAuthorizers);
        } else {
            setErrors(true);
        }
    };

    return (
        <div className={'container'} style={{paddingTop: '2em'}}>

        
            <div className={'row'} style={{margin:0}}>
                {/* <ButtonGroup style={{float:'left'}}>
                    <Button onClick={() => setAddSign(true)} style={{width:'auto'}}>
                        <i className={'fa fa-plus icon'}/> Add New Signatory
                    </Button>
                    <Button onClick={() => setCopySign(true)} style={{width:'auto', marginLeft:'1em'}}>
                        <i className={'fa fa-copy icon'}/> Copy Existing Signatory
                    </Button>
                </ButtonGroup> */}

                {/* New button */}
                <ButtonGroup style={{float:'left'}}>
                    <Button  onClick={(e) => {e.preventDefault(); setAddSign(!addSign); setCopySign(false); }} style={{width:'auto'}}>
                        <i className={'fa fa-plus icon'}/> Add New Signatory
                    </Button>
                    <Button  onClick={(e) => {e.preventDefault(); setCopySign(!copySign); setAddSign(false); }} style={{width:'auto', marginLeft:'1em'}}>
                        <i className={'fa fa-copy icon'}/> Copy Existing Signatory
                    </Button>
                </ButtonGroup>
            </div>

            {/* New signatory View */}
            { addSign && 
                <div className="row" style={{margin:0}} >
                    <AddNewSignatoryForm
                        saveSignatory={saveSignatory}
                        onIndividualSearch={handleIndividualSearch}
                        onUploadDocument={handleUploadDocument}
                        accounts={accounts}
                    />
                </div>
            }

            {/* Existing signatory View */}
            { copySign && 
                <div className="row" style={{margin:0}}>
                    <AddExistingSignatory
                        saveSignatory={copySignatory}
                        onIndividualSearch={handleIndividualSearch}
                        onUploadDocument={handleUploadDocument}
                    />
                </div>
            }

            <hr />
            <div>
                <div className="product-heading">
                     Signatories to be added:
                </div>
                <div className="title-gradient"/>
            </div>
            <br />
            <div className={'row'} style={{margin:0}}>
                <ListGroup style={{minHeight: '18em'}}>
                    {
                        instructions ?
                            instructions.map((addition, i) =>
                                <ListGroupItem type={'button'}
                                               key={i}>
                                    <Row>
                                        <Col md={10} lg={10} sm={12}>
                                            <Row style={{paddingLeft: '2em'}}>
                                                {/*<i className={choices.includes(i, 0) ? 'fa fa-check-square-o icon' : 'fa fa-square-o icon'}/>*/}
                                                <span style={{fontWeight: 'bold'}}>Signatory Name:</span>
                                                {addition.initialsOrFirstNames} {addition.surname}
                                            </Row>
                                            <Row style={{paddingLeft: '2.5em'}}>
                                                <span style={{fontWeight: 'bold'}}>Adding to Account:</span>
                                                {addition.account.name}&nbsp;&nbsp;
                                                <span className={'text-primary'} style={{fontWeight: 'bold'}}>
                                            {addition.account.accountNumber}
                                        </span>
                                            </Row>
                                            <Row style={{paddingLeft: '2.5em'}}>
                                                <span style={{fontWeight: 'bold'}}>Signing Arrangements :</span>
                                                {addition.instruction}
                                            </Row>
                                            <Row style={{paddingLeft: '2.5em'}}>
                                                <span style={{fontWeight: 'bold'}}>Signatory ID :</span>
                                                {addition.idOrPassportNumber}
                                            </Row>

                                        </Col>
                                        <Col md={2} style={{textAlign:'end'}}>
                                            <Button bsStyle={'danger'} bsSize={'sm'} type="button" onClick={() => deleteInstruction(addition)} style={{width:'auto'}}>
                                                <i className={'fa fa-undo'}/> Undo
                                            </Button>
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                            )
                            :
                            <div className="">
                                {/* <h3 className="login_title">You currently have no signatory instructions.</h3> */}
                                <p>Please either add a new signatory or copy an existing.</p>
                            </div>
                    }
                </ListGroup>
            </div>
            {
                errors &&
                <div className={'row'} style={{margin:0}}>
                    <Alert bsStyle={'danger'}>At least one instruction is required to continue.</Alert>
                </div>
            }

            <div className={'page-footer-section'} style={{width:'100%'}}>
                <ButtonsComponent
                    history={props.history}
                    onNext={onNext}
                    onSaveAndContinue={onSave}
                    hidePrevious={false}
                    prevPage={signatoryRoots.addStart}
                />
            </div>

            {/* <Modal show={addSign} onHide={() => setAddSign(false)} bsSize={'lg'}>
                <Modal.Body>
                    <AddNewSignatoryForm
                        saveSignatory={saveSignatory}
                        onIndividualSearch={handleIndividualSearch}
                        onUploadDocument={handleUploadDocument}
                        accounts={accounts}
                    />
                </Modal.Body>
            </Modal>
            <Modal show={copySign} onHide={() => setCopySign(false)} bsSize={'lg'}>
                <Modal.Header>
                    <Modal.Title>
                        Copy Signatory
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddExistingSignatory
                        saveSignatory={copySignatory}
                        onIndividualSearch={handleIndividualSearch}
                        onUploadDocument={handleUploadDocument}
                    />
                </Modal.Body>
            </Modal> */}
        </div>
    );
};
