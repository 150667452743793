import {API_ROOT} from '../api-config';
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from './appLoading';
import axios from 'axios';
import _ from 'lodash';
import * as types from './actionTypes';


const SIGNATORY_BASE_URL = `${API_ROOT}/api/maintanance/signatories`;

export function startSignatoryApplication(legalEntity, Application, type, impersonation, onCompleted = null) {
    const request = {maintenanceRequest: {impersonatedUser: impersonation, entityDTO: {...legalEntity}, action: type, product:{...Application.product} }};
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.post(`${SIGNATORY_BASE_URL}`, request).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.SM_START, application: responseData.data});
                onCompleted && onCompleted();
            } else {
                console.log('entity.fetchSelectedEntity.errorMessage ===> ', responseData.message);
                const hasNoApprovalRules = responseData && responseData.message && responseData.message.includes('no auth rules');
                const hasInsufficientApprovalRules = responseData && responseData.message && responseData.message.includes('Not enough');
                if(hasNoApprovalRules){
                    onCompleted && onCompleted(false, 'Authorisation Rules Need To Be Configured');
                }
                else if(hasInsufficientApprovalRules){
                    onCompleted && onCompleted(false, 'Not enough registered users as per Authorisation Rules settings. Please update the rules or ensure enough authorisers are registered');
                }
                else {
                    dispatch({
                        type: types.SHOW_RESPONSE_ERROR,
                        errorMessage: 'Couldn\'t retrieve the selected entity details.'
                    });
                }
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('signatory.start.error ===> ', JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function getSignatoryApplication(id, history, onCompleted = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(`${SIGNATORY_BASE_URL}/${id}`).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.SM_START, application: responseData.data});
                console.log(responseData.data);
                onCompleted && onCompleted();
                history && history.push(`/signatory-maintenance/${responseData.data.maintenanceRequest.action.toLowerCase()}/instructions`);
            } else {
                console.log('signatory.start.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t create an application.'});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('signatory.start.error ===> ', JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function getSignatoryApplicationsDashboard(onCompleted = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(`${SIGNATORY_BASE_URL}`).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.FETCH_SIGNATORY_APPLICATION_SUCCESS, signatories: responseData.data});
                onCompleted && onCompleted();
            } else {
                console.log('signatory.start.errorMessage ===> ', responseData.message);
                dispatch({type: types.FETCH_SIGNATORY_APPLICATION_FAILURE, errorMessage: 'Couldn\'t create an application.'});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('signatory.start.error ===> ', JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function getSignatoryApplications(onCompleted = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(`${SIGNATORY_BASE_URL}`).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.SM_APPS, copies: responseData.data});
                onCompleted && onCompleted();
            } else {
                console.log('signatory.start.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t create an application.'});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('signatory.start.error ===> ', JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}


export const saveAndContinueSignatoryApplication = (id, instructions, saveAndContinue , onComplete) => {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            
            let url = saveAndContinue ? SIGNATORY_BASE_URL + '/' + id + '/save?saveAndContinue=true' : SIGNATORY_BASE_URL + '/' + id + '/save';
            const responseData = await axios.put(url, instructions).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.SM_START, application: responseData.data});
                onComplete && onComplete(true);
            }else {
                console.log('signatory.start.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t create an application.'});
            }
 
        } catch (e) {
            console.log('signatory.start.error ===> ', JSON.stringify(e));
            authCheck(dispatch, e);
            const status = e.response && e.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export const saveSignatoryApplication = (id, instructions) => {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.put(`${SIGNATORY_BASE_URL}/${id}/save`, instructions).then(response => response.data);
 
            if (responseData.success) {
                dispatch({type: types.SM_START, application: responseData.data});
            } else {
                console.log('signatory.start.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t create an application.'});
            }
        } catch (e) {
            console.log('signatory.start.error ===> ', JSON.stringify(e));
            authCheck(dispatch, e);
            const status = e.response && e.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };

};

export const completeSignatoryApplication = (id, instructions, notificationEmailAddress, selectedApprovers, onComplete) => {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.put(
                 SIGNATORY_BASE_URL + '/'+id + '/complete' ,{'emailAddress': notificationEmailAddress,selectedApprovers}
                ).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.SM_START, application: responseData.data});
                onComplete && onComplete();
            } else {
                console.log('signatory.start.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t create an application.'});
            }
        } catch (e) {
            console.log('signatory.start.error ===> ', JSON.stringify(e));
            authCheck(dispatch, e);
            const status = e.response && e.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };

};
