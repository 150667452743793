import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function systemErrorReducer(state = initialState.systemError, action) {
    switch (action.type) {
        case types.SYSTEM_ERROR:
            return {...state, show: true, status: action.status};
        case types.CLEAR_SYSTEM_ERROR:
            return {...state, show: false, status: null};
        default:
            return state;
    }
}