import axios from 'axios';
import _ from 'lodash';
import * as types from './actionTypes';
import {API_ROOT} from "../api-config";
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from "./appLoading";
// import data from '../data/bol-profiles';

const BOL_PROFILES_URL = API_ROOT + '/api/bol-profiles?cif=';
const LEGAL_REMEDICATION = API_ROOT + '/api/legal-remediation';


export function onChangeValue(value) {
    return async dispatch => dispatch({type: 'LR_CHANGE', agreements: value});
}

export function onSelectEntity(value) {
    return async dispatch => dispatch({type: 'LR_SELECT_ENTITY', entity: value});
}

export function saveRemediation(id, agreements, onProceed = null) {
    return async dispatch => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const axiosResponse = await axios.put(LEGAL_REMEDICATION + '/' + id, agreements).then(response => response.data);
            if (axiosResponse.success) {
                dispatch({type: 'LR_FINISH'});
                onProceed && onProceed();
            }
        } catch (error) {
            console.log("lr.finishRem.error ===> ", JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            onProceed && onProceed(false, '');
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function startRemediation(idNumber, entity, onProceed = null) {
    idNumber = idNumber ? idNumber : '';
    return async dispatch => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const axiosResponse = await axios.post(LEGAL_REMEDICATION + "?idNumber=" + idNumber, {entity}).then(response => response.data);
            if (axiosResponse.success) {
                const data = axiosResponse.data;
                dispatch({
                    type: 'LR_START',
                    agreements: {id: data.id, status: data.status, agreements: data.ebtcRequest.agreements}
                });
                onProceed && onProceed();
            } else {
                const response = {status: axiosResponse.status};
                throw {response};
            }
        } catch (error) {
            console.log("lr.startRem.error ===> ", JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }

    }
}

export function fetchProfiles(cif) {
    const profiles = {};
    const accountsForProfile = {};
    return async dispatch => {
        loadingPageDataStart(dispatch);
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const axiosResponse = await axios.get(BOL_PROFILES_URL + cif).then(response => response.data);

            if (axiosResponse.success) {
                const {data} = axiosResponse;
                const entities = await data.map(entity => {
                    const {id, name, divisions} = entity;
                    divisions.forEach(division => {
                        const {code, name, users} = division;
                        const profile = profiles[id] ? profiles[id] : [];
                        profile.push({code, name, users});
                        profiles[id] = profile;

                        const {accounts} = division;
                        accounts.forEach(account => {
                            const {accountNumber} = account;
                            const profile = accountsForProfile[code] ? accountsForProfile[code] : [];
                            profile.push(accountNumber);
                            accountsForProfile[code] = profile;
                        })
                    });
                    return {id, name};
                });

                dispatch({type: 'LR_PROFILES', profiles: profiles});
                dispatch({type: 'LR_ENTITIES', entities: entities});
                dispatch({type: 'LR_ACCOUNTS', accounts: accountsForProfile});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("lr.fetchProfiles.error ===> ", JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}

export function fetchDocument(id) {
    return async dispatch => {
        loadingPageDataStart(dispatch);
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const axiosResponse = await axios.get(LEGAL_REMEDICATION + '/document/' + id).then(response => response.data);

            if (axiosResponse.success) {
                const {message} = axiosResponse;
                dispatch({type: 'LR_DOC', documentStr: message});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("lr.documents.error ===> ", JSON.stringify(error));
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    }
}