import axios from 'axios';
import * as types from './actionTypes';
import {API_ROOT} from '../api-config';
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from './appLoading';
import _ from "lodash";
import {electronicIndemnityRoutes} from '../routers/routes';

const ELECTRONIC_INDEMNITY_URL = API_ROOT + '/api/electronic-indemnity';
const ENTITIES_URL = API_ROOT + '/api/legal-entities';
const AUTHORISERS_URL = API_ROOT + '/api/approvals/';

const ELECTRONIC_INDEMNITY_PENDING_TASKS = {
    entityDetails: "UserTask_ConfirmEntity",
    clauses: 'UserTask_Clauses',
    authorisedIndividual: 'UserTask_ConfirmAPDetails',
    confirmAuthorisers: 'UserTask_ConfirmAuthorisers'
};

export function onChangeValue(objName, attribute, value) {
    return (dispatch) => {
        dispatch({type: types.ELECTRONICINDEMNITY_APPLICATION_SUCCESS, objName, attribute, value})
    }
}

export function startElectronicIndemnityApplication(selectedGoldTierId, application, impersonation, onProceed = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            const legalEntity = {
                'goldTierId': selectedGoldTierId
            };
            application.legalEntity = legalEntity;
            application.impersonatedIdNumber = impersonation.identityNumber;

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.post(ELECTRONIC_INDEMNITY_URL, application).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.FETCH_ELECTRONICINDEMNITY_DATA, data: responseData.data});
                onProceed && onProceed();
            } else {
                console.log('entity.fetchSelectedEntity.errorMessage ===> ', responseData.message);
                const hasNoApprovalRules = responseData && responseData.message && responseData.message.includes('no auth rules');
                const hasInsufficientApprovalRules = responseData && responseData.message && responseData.message.includes('Not enough');
                if (hasNoApprovalRules) {
                    onProceed && onProceed(false, 'Authorisation Rules Need To Be Configured');
                } else if (hasInsufficientApprovalRules) {
                    onProceed && onProceed(false, 'Not enough registered users as per Authorisation Rules settings. Please update the rules or ensure enough authorisers are registered');
                } else {
                    dispatch({
                        type: types.SHOW_RESPONSE_ERROR,
                        errorMessage: 'Couldn\'t retrieve the selected entity details.'
                    });
                }
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.error('entity.fetchSelectedEntity.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchSelectedElectronicIndemnityRequest(id, history, onFinish = null) {
    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(`${ELECTRONIC_INDEMNITY_URL}/${id}`).then(response => response.data);

            if (responseData.success) {

                const application = responseData.data;
                dispatch({type: types.FETCH_ELECTRONICINDEMNITY_DATA, data: application});

                const pendingTasks = application && application.applicationStatus && application.applicationStatus.pendingTasks;

                let path = "/"

                if (_.find(pendingTasks, task => (task.name === ELECTRONIC_INDEMNITY_PENDING_TASKS.clauses))) {
                    path = electronicIndemnityRoutes.electronicIndemnityClauses;
                } else if (_.find(pendingTasks, task => (task.name === ELECTRONIC_INDEMNITY_PENDING_TASKS.authorisedIndividual))) {
                    path = electronicIndemnityRoutes.authorisedIndividual;
                } else if (_.find(pendingTasks, task => (task.name === ELECTRONIC_INDEMNITY_PENDING_TASKS.confirmAuthorisers))) {
                    path = electronicIndemnityRoutes.confirmAuthorisers;
                } else {
                    path = electronicIndemnityRoutes.completeElectronicIndemnity;
                }

                history.push(path);
            } else {
                console.log("entity.fetchSelectedOnboardRequest.errorMessage ====>", responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: "Couldn't retrieve selected custody application."
                });
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function saveElectronicIndemnity(appId, electronicIndemnityRequest, task , onComplete, email = null) {
    return async dispatch => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            let responseData = null;
            if(email === null){
                responseData = await axios.put(ELECTRONIC_INDEMNITY_URL + '/' + appId + '/ElectronicIndemnityClauses', electronicIndemnityRequest).then(response => response.data);
            }else{
                responseData = await axios.put(ELECTRONIC_INDEMNITY_URL + '/complete-task/' + appId + '/' + task+'?email='+ email, electronicIndemnityRequest).then(response => response.data);
            }
            if (responseData.success) {
                onComplete && onComplete(true);
            }
        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
        }
    };
}

export function saveElectronicIndemnityUsers(appId, electronicIndemnityRequest, task, onComplete, email = null) {
    return async dispatch => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            let responseData = null;
            if(email === null){
                responseData = await axios.put(ELECTRONIC_INDEMNITY_URL + '/' + appId + '/confirmIndemnityUsers', electronicIndemnityRequest).then(response => response.data);
            }else{
                responseData = await axios.put(ELECTRONIC_INDEMNITY_URL + '/complete-task/' + appId + '/' + task+'?email='+ email, electronicIndemnityRequest).then(response => response.data);
            }
            if (responseData.success) {
                onComplete && onComplete(true);
            }
        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
        }   
    };
}

export function confirmElectronicIndemnityApplication(appId, electronicIndemnityRequest, task, onComplete, email = null) {
    return async dispatch => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            let responseData = null;
            if(email === null){
                responseData = await axios.put(ELECTRONIC_INDEMNITY_URL + '/' + appId + '/confirmAuthorisers', electronicIndemnityRequest).then(response => response.data);
            }else{
                responseData = await axios.put(ELECTRONIC_INDEMNITY_URL + '/complete-task/' + appId + '/' + task+'?email='+ email, electronicIndemnityRequest).then(response => response.data);
            }
            if (responseData.success) {
                onComplete && onComplete(true);
            }
        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
        }   
    };
}


export function saveAndContinueElectronicIndemnityUsers(appId, electronicIndemnityRequest, saveAndContinue , onComplete) {
    return async dispatch => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
          
            let url = saveAndContinue ? ELECTRONIC_INDEMNITY_URL + '/' + appId + '/confirmIndemnityUsers?saveAndContinue=true' : ELECTRONIC_INDEMNITY_URL + appId + '/confirmIndemnityUsers';
            const responseData = await axios.put(url, electronicIndemnityRequest).then(response => response.data);
            if (responseData.success) {
                onComplete && onComplete(true);
            }else {
                console.log('entity.updateEntity.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t confirm entity details.'});
            }
        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
        }
    };
}
export function saveAndContinueElectronicIndemnityClauses(appId, electronicIndemnityRequest, saveAndContinue , onComplete) {
    return async dispatch => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');

            let url = saveAndContinue ? ELECTRONIC_INDEMNITY_URL + '/' + appId + '/ElectronicIndemnityClauses?saveAndContinue=true' : ELECTRONIC_INDEMNITY_URL + appId + '/ElectronicIndemnityClauses';
            const responseData = await axios.put(url, electronicIndemnityRequest).then(response => response.data);

            if (responseData.success) {
                onComplete && onComplete(true);
            }else {
                console.log('entity.updateEntity.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t confirm entity details.'});
            }
        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
        }
    };
}

export function fetchHierachy(impersonation, onComplete) {

    let url = ENTITIES_URL;
    if (impersonation && impersonation.identityNumber) {
        url = url + '?id=' + impersonation.identityNumber;
    }
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(url).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.FECTH_HIERACHY_SUCCESS, entities: responseData.data});
            } else {
                console.log('entity.fetchHierachy.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t retrieve entities hierarchy.'});
            }

            if(onComplete) {
                onComplete('isFetchingEntities', dispatch);
            } else {
                loadingPageDataFinish(dispatch);
            }

        } catch (error) {
            console.log('entity.fetchHierachy.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            onComplete && onComplete('isFetchingEntities');
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchAuthorizers(appId, onProceed = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(AUTHORISERS_URL + appId).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.FETCH_AUTHORISERS, authorisers: responseData.data});
                onProceed && onProceed();
            } else {
                console.log('entity.fetchAuthorizers.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t confirm KYC.'});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('entity.fetchAuthorizers.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, false, status);
        }
    };
}

