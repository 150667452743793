import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function authorisersReducer(state = initialState.authorisers, action) {
    switch (action.type) {
        case types.FETCH_AUTHORISERS:
            return {...state, authorisers: action.authorisers};
        default:
            return state;
    }
}