import React from 'react';
import _ from "lodash";
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import validate from "validate.js";

import * as actions from "../../actions/bol";
import {fetchAccounts} from "../../actions/kyc";
import AccountDetailsForm from './AccountDetailsForm'
import FormField from "../formField/FormField";
import {DataList} from "../../../node_modules/primereact/components/datalist/DataList";
import Popup from "../Popup";

const TOGGLE = {
    accountDetailsForm: "accountDetailsForm",
};

const CHANNELS = {
    bol: {type: "businessOnline", label: "Business Online"},
    host: {type: "hostToHost", label: "Host To Host"}
};

class BankAccountsPage extends React.Component {
    state = {
        errors: {},
        toggle: {
            [TOGGLE.accountDetailsForm]: false,
        }
    };

    componentWillMount() {
        this._fetchAccounts();
    }

    _fetchAccounts() {
        const {actions, newBolProfile} = this.props;
        const customerDetails = newBolProfile && newBolProfile.customerDetails;
        if (customerDetails && customerDetails.cif) {
            actions.fetchAccounts(`cifNumber=${customerDetails.cif}`, 'selectedEntityAccounts', null, true);
        }
    };

    handleToggle = (attribute) => {
        this.setState(prevState => {
            const toggle = _.extend({}, prevState.toggle, {[attribute]: !(prevState.toggle && prevState.toggle[attribute])});
            return {toggle}
        });
    };

    handleSelectChange = (event, attribute, innerAttribute) => {
        const {actions, newBolProfile} = this.props;
        let value = event.value;
        if (innerAttribute) {
            value = _.extend({}, newBolProfile && newBolProfile[attribute], {[innerAttribute]: value})
        }
        actions.onChangeValue('newBolProfile', attribute, value);
    };

    handleOnChange = ({target}, attribute, innerAttribute) => {
        const {actions, newBolProfile} = this.props;
        let value = target.value;
        if (innerAttribute) {
            value = _.extend({}, newBolProfile && newBolProfile[attribute], {[innerAttribute]: value})
        }
        actions.onChangeValue('newBolProfile', attribute, value);
    };

    handleAddAccountToProfile = (account) => {
        const {actions, newBolProfile} = this.props;
        const bankAccountsDetails = newBolProfile && newBolProfile.bankAccountsDetails;

        const isFound = _.find(bankAccountsDetails && bankAccountsDetails.profileAccounts, acc => (acc.accountNumber === account.accountNumber));
        let profileAccounts;
        if (isFound) {
            profileAccounts = _.map(bankAccountsDetails && bankAccountsDetails.profileAccounts, acc => ((acc.accountNumber === account.accountNumber) ? account : acc));
        } else {
            profileAccounts = _.union(bankAccountsDetails && bankAccountsDetails.profileAccounts, [account]);
        }
        const updates = _.extend({}, bankAccountsDetails, {profileAccounts});

        actions.onChangeValue("newBolProfile", "bankAccountsDetails", updates);
        this.handleToggle(TOGGLE.accountDetailsForm)
    };

    handleRemoveAccountFromProfile = (account) => {
        const {actions, newBolProfile} = this.props;
        const bankAccountsDetails = newBolProfile && newBolProfile.bankAccountsDetails;
        const profileAccounts = _.filter(bankAccountsDetails && bankAccountsDetails.profileAccounts, acc => (acc.accountNumber !== account.accountNumber));
        const updates = _.extend({}, bankAccountsDetails, {profileAccounts});
        actions.onChangeValue("newBolProfile", "bankAccountsDetails", updates);
    };

    handleCloseAccountDetailsForm = () => {
        this.handleToggle(TOGGLE.accountDetailsForm)
    };

    determineNavigation = () => {
        const {newBolProfile} = this.props;
        const channelDetails = newBolProfile && newBolProfile.channelDetails;
        const productsAndServices = newBolProfile && newBolProfile.productsAndServices;
        const channelRequirement = channelDetails && channelDetails.channelRequirement;
        const hostToHostType = channelDetails && channelDetails.hostToHostType;

        const isBolType = channelRequirement === CHANNELS.bol.type;
        const hasProducts = _.size(productsAndServices) > 0;
        const path = (hasProducts && isBolType) ? "/bol/options" : "/bol/channels";
        return path
    };

    handlePrevious = () => {
        const {history} = this.props;
        const path = this.determineNavigation();
        history.push(path);
    };

    handleSaveAndContinue = () => {
        const {actions, newBolProfile, history} = this.props;
        const onProceed = () => {
            history.replace('/');
        };
        const updateDetails = _.extend({}, newBolProfile, {status: 'accounts'});
        actions.saveNewBolProfile(updateDetails, onProceed)
    };

    handleOnResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors});
    };

    _validateForm() {
        const {newBolProfile} = this.props;
        const bankAccountsDetails = newBolProfile && newBolProfile.bankAccountsDetails;
        const billingAccount = bankAccountsDetails && bankAccountsDetails.billingAccount;
        const profileAccounts = bankAccountsDetails && bankAccountsDetails.profileAccounts;
        const hasProfileAccounts = _.size(profileAccounts) > 0;

        const options = {fullMessages: false};
        const constraints = {
            billingAccount: {presence: true, length: {minimum: 1, message: "Required"}},
            profileAccounts: {presence: true},
        };

        const errors = validate({billingAccount, profileAccounts: !hasProfileAccounts && null}, constraints, options);
        this.setState({errors});
        return _.isEmpty(errors);
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (this._validateForm()) {
            const {actions, newBolProfile, history} = this.props;
            const onProceed = () => history.push("/bol/users");
            const updateDetails = _.extend({}, newBolProfile, {status: 'users'});
            actions.saveNewBolProfile(updateDetails, onProceed);
        }
    };

    _renderAccountTemplate = (account) => {
        if (!account) return null;
        return (
            <div>
                <div className="flexRow" style={{padding: 0}}>
                    <div className="col-md-11 col-sm-11 col-xs-11" style={{padding: 0}}>
                        <label>{account.accountNumber}</label>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1" style={{padding: 0}}>
                        <a
                            className="btn"
                            role="button"
                            id="remove-btn"
                            onClick={() => this.handleRemoveAccountFromProfile(account)}>
                            <i className="fa fa-times" aria-hidden="true"/>
                        </a>
                    </div>
                </div>
                <hr/>
            </div>
        );
    };

    _renderAccounts() {
        const {newBolProfile} = this.props;
        const bankAccountsDetails = newBolProfile && newBolProfile.bankAccountsDetails;
        const profileAccounts = bankAccountsDetails && bankAccountsDetails.profileAccounts;

        const hasProfileAccounts = _.size(profileAccounts) > 0;
        if (!hasProfileAccounts) return null;
        return (
            <div>
                <div className="row">
                    <div className="col-md-11 col-sm-11 col-xs-11">
                        <label style={{color: "black"}}><b>Account Number</b></label>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1"/>
                </div>
                <DataList
                    className="ui-datalist-nobullets"
                    itemTemplate={this._renderAccountTemplate}
                    paginator={true}
                    rows={3}
                    value={profileAccounts}
                />
            </div>
        )
    }

    _renderPopUps() {
        const {accounts} = this.props;
        const {toggle} = this.state;
        if (toggle && toggle[TOGGLE.accountDetailsForm]) {
            return (
                <Popup onClose={this.handleCloseAccountDetailsForm}>
                    <AccountDetailsForm
                        title={"Bank Account Details"}
                        accounts={accounts}
                        onSubmit={this.handleAddAccountToProfile}
                    />
                </Popup>
            )
        }
        return null;
    }

    _renderNominatedAccountSection() {
        const {accounts, newBolProfile} = this.props;
        const {errors} = this.state;
        const bankAccountsDetails = newBolProfile && newBolProfile.bankAccountsDetails;
        const hasAccounts = _.size(accounts) > 0;
        const accountOptions = _.map(accounts, (acc) => ({
            value: acc.accountNumber,
            label: `${acc.name} | ${acc.accountNumber}`
        }));

        return (
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        <span>Nominated Billing Account</span>
                    </div>

                    <FormField className="form-group" id="billAccount"
                               error={errors && errors.billingAccount ? (hasAccounts ? ["One Should Be Selected"] : errors.billingAccount) : ""}>
                        <label htmlFor="billingAccount">Standard Bank Charge/Billing Account</label>
                        <Select
                            name="billAccount"
                            onChange={(event) => {
                                errors && errors.billingAccount && this.handleOnResetError('billingAccount');
                                this.handleSelectChange(event, 'bankAccountsDetails', 'billingAccount');
                            }}
                            options={accountOptions}
                            value={bankAccountsDetails && bankAccountsDetails.billingAccount || ''}
                        />
                    </FormField>
                </div>
            </div>
        )
    }

    _renderBankingAccountsSection() {
        const {errors} = this.state;
        return (
            <div className="flexColumn">
                <div className="card-container-form">

                    <div className="section-title">
                        <span>Business Online Profile Accounts</span>
                    </div>

                    {this._renderAccounts()}
                    <FormField className="form-group" id="billAccount"
                               error={errors && errors.profileAccounts ? ["At Least One Profile Account Should Be Added"] : ""}>
                        <div>
                            <a
                                className="btn btn-secondary addButton"
                                onClick={() => {
                                    errors && errors.profileAccounts && this.handleOnResetError("profileAccounts");
                                    this.handleToggle(TOGGLE.accountDetailsForm)
                                }}
                            >Add Account</a>
                        </div>
                        {errors && errors.profileAccounts && <div><br/> <br/></div>}
                    </FormField>
                </div>
            </div>
        )
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <button
                    className="action-btn-primary"
                    onClick={this.handlePrevious}
                    type="button"
                >Previous
                </button>
                <button
                    className="action-btn-secondary"
                    onClick={this.handleSaveAndContinue}
                    type="button"
                > Save And Continue Later
                </button>
                <button
                    className="action-btn-primary"
                    type="submit"
                >Next
                </button>
            </div>
        )
    }

    render() {
        return (
            <div>
                <form className="page-container" onSubmit={this.handleSubmit}>
                    <div className="page-main-section">
                        {this._renderNominatedAccountSection()}
                        {this._renderBankingAccountsSection()}
                    </div>
                    <div className="page-footer-section">
                        {this._renderFooter()}
                    </div>
                </form>
                {this._renderPopUps()}
            </div>
        )
    }
}

function mapStateToProps({bol, accounts}) {
    return {
        newBolProfile: bol && bol.newBolProfile ? bol.newBolProfile : {},
        accounts: accounts && accounts.selectedEntityAccounts ? accounts.selectedEntityAccounts : []
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, fetchAccounts}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(BankAccountsPage);