import React from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import validate from 'validate.js'
import "../../styles/ConfirmApplication.css";
import "../../styles/Global.css";
import * as actions from '../../actions/custody';
import FormField from "../formField/FormField";
import {newAccountOpeningRoutes, newCustodyAccountRoutes} from "../../routers/routes";
import ButtonsComponent from "../ButtonsComponent";
import Select from "react-select";
import countries from "../../data/countries";

const labelClassList = "col-xs-12 col-sm-4 col-md-4";
const inputClassList = "col-xs-12 col-sm-8 col-md-8";

class AddressDetailsPage extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            errors: {}
        };


        this.handleOnBeneficialAddressChange = this._handleOnBeneficialAddressChange.bind(this);
        this.handleOnBNDAddressChange = this._handleOnBNDAddressChange.bind(this);
        this.handleBNDAddressSame =this._handleBNDAddressSame.bind(this);

    }

    componentDidMount(){
        const {newCustodyProfile} = this.props;
        this.props.action.onChangeValue("bndAddressOld", newCustodyProfile.bndAddress);
    }

    _handleOnBeneficialAddressChange({target}, attribute) {

        const {newCustodyProfile} = this.props;
        let value = _.trimStart(target.value);

        if(!newCustodyProfile.beneficialAddressOld)
            this.props.action.onChangeValue("beneficialAddressOld", newCustodyProfile.beneficialAddress);
        this.props.action.onChangeValue( 'beneficialAddress',attribute, value)
        if(newCustodyProfile && newCustodyProfile.isSameBNDAddress && newCustodyProfile.isSameBNDAddress.value){
            this.props.action.onChangeValue('bndAddress',attribute, value)
        }
    }
    _handleOnBNDAddressChange({target}, attribute) {

        let value = _.trimStart(target.value);
        this.props.action.onChangeValue('bndAddress',attribute, value)
        this.props.action.onChangeValue("bndAddressOld", attribute, value);
    }

    _handleBNDAddressSame(isSame = false){

        const {newCustodyProfile} = this.props;

        let bndAddress;
        if(isSame){
            if(!newCustodyProfile.bndAddressOld)
                this.props.action.onChangeValue("bndAddressOld", newCustodyProfile.bndAddress);
            bndAddress = newCustodyProfile.beneficialAddress;
        }

        else{
            bndAddress = newCustodyProfile.bndAddressOld;
        }

        this.props.action.onChangeValue("isSameBNDAddress", "value", isSame);
        this.props.action.onChangeValue("bndAddress", bndAddress);
    }

    handleCountryChange = (event, attribute, innerAttribute) => {
        let value = event.value;
        if (innerAttribute) {
            const {newCustodyProfile} = this.props;
            value = _.extend({}, newCustodyProfile && newCustodyProfile[attribute], innerAttribute ? {[innerAttribute]: value} : value)
        }
        this.props.action.onChangeValue('beneficialAddress', attribute, value);
        this.props.action.onChangeValue('bndAddress', attribute, value);
    };


    confirmDetails = (accountDetails, additionalProducts) => {
        const {actions, applicationId, history} = this.props;
        const onProceed = () => history.push(newAccountOpeningRoutes.kyc);
        actions.confirmAccount(accountDetails, additionalProducts, applicationId, onProceed);
    };


    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    validateForm = () => {
        const {form} = this.state;
        const options = {fullMessages: false};
        const constraints = {emailAddress: {presence: true, email: true, length: {minimum: 1, message: 'required'}}};
        const errors = validate(form, constraints, options);
        this.setState({errors});
        return _.isEmpty(errors);
    };

    saveDetails = (event) => {
        event.preventDefault();
        const {history} = this.props;
        const onSuccess = () => history.push(newCustodyAccountRoutes.contactdetails);
        this.props.action.saveCustody(this.props.appId, this.props.newCustodyProfile, 'UserTask_AddressDetails', onSuccess);
    };

    saveAndContinue = (event) => {
        event.preventDefault();
        const {history} = this.props;
        const onSuccess = () => history.push('/saveapplication');
        this.props.action.saveCustody(this.props.appId, this.props.newCustodyProfile, 'saveContinue', onSuccess);

    };

    handleChange = (e) => {
        const {form} = this.state;
        const emailAddress = _.trimStart(e.target.value);
        this.setState({form: _.extend({}, form, {emailAddress})});
    };

    _renderFooter() {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={this.saveDetails}
                    type="button"
                >Complete
                </button>
            </div>
        )
    }

    render() {
        const {newCustodyProfile} = this.props;
        const {errors, form} = this.state;

        return (
            <div className="page-container">
                <div className="page-main-section">

                    <div className="col-md-12">
                        <div className="card-container-form">
                            <div className="section-title">
                                <span>Address Details Beneficial Owner</span>
                            </div>
                            <div className="form-horizontal">
                                <div className="col-md-6 form-group" style={{marginBottom : "0px"}}>
                                    <FormField className="" id="streetName"
                                               error={errors && errors.physicalAddressLine1}>
                                            <label className={labelClassList} htmlFor="streetName">House Building Number And Street Name/ Number</label>
                                        <div className={inputClassList}>
                                            <input
                                                className="form-control"
                                                id="streetName"
                                                onChange={(event) => {
                                                    this.handleOnBeneficialAddressChange(event, 'streetName')
                                                }}
                                                type="text"
                                                value={newCustodyProfile.beneficialAddress.streetName ? newCustodyProfile.beneficialAddress.streetName : ""}
                                            />
                                        </div>
                                    </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField className="" id="houseName"
                                           error={errors && errors.physicalAddressLine2}>
                                    <label className={labelClassList} htmlFor="physicalAddressLine1">House/Building Name</label>
                                    <div className={inputClassList}>
                                        <input
                                            className="form-control"
                                            id="houseName"
                                            onChange={(event) => {
                                                this.handleOnBeneficialAddressChange(event, 'houseName')
                                            }}
                                            type="text"
                                            value={newCustodyProfile.beneficialAddress.houseName ? newCustodyProfile.beneficialAddress.houseName: ""}

                                        />
                                    </div>
                                </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField className="" id="room"
                                           error={errors && errors.physicalAddressLine2}>
                                    <label className={labelClassList} htmlFor="physicalAddressLine1">Room/Floor/Suite</label>
                                    <div className={inputClassList}>
                                    <input
                                        className="form-control"
                                        id="room"
                                        onChange={(event) => {
                                            this.handleOnBeneficialAddressChange(event, 'roomNumber')
                                        }}
                                        type="text"
                                        value={newCustodyProfile.beneficialAddress.roomNumber ? newCustodyProfile.beneficialAddress.roomNumber : ""}
                                    />
                                    </div>
                                </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField className="" id="suburb"
                                           error={errors && errors.physicalAddressLine2}>
                                    <label className={labelClassList} htmlFor="suburb">Suburb</label>
                                    <div className={inputClassList}>
                                    <input
                                        className="form-control"
                                        id="suburb"
                                        onChange={(event) => {
                                            this.handleOnBeneficialAddressChange(event, 'suburb')
                                        }}
                                        type="text"
                                        value={newCustodyProfile.beneficialAddress.suburb ? newCustodyProfile.beneficialAddress.suburb : ""}
                                    />
                                    </div>
                                </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField className="" id="postalCode" error={errors && errors.postalCode}>
                                    <label className={labelClassList} htmlFor="postalCode">Postal/ Zip Code</label>
                                    <div className={inputClassList}>
                                    <input
                                        className="form-control"
                                        id="postalCode"
                                        onChange={(event) => {
                                            this.handleOnBeneficialAddressChange(event, 'postalCode')
                                        }}
                                        type="number"
                                        maxLength="4"
                                        max="9999"
                                        value={newCustodyProfile.beneficialAddress.postalCode ? newCustodyProfile.beneficialAddress.postalCode : ""}
                                    />
                                    </div>
                                </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField className="" id="city" error={errors && errors.city}>
                                    <label className={labelClassList} htmlFor="city">City / Town</label>
                                    <div className={inputClassList}>
                                    <input
                                        className="form-control"
                                        id="city"
                                        onChange={(event) => {
                                            this.handleOnBeneficialAddressChange(event, 'city')
                                        }}
                                        type="text"
                                        value={newCustodyProfile.beneficialAddress.city ? newCustodyProfile.beneficialAddress.city : ""}
                                    />
                                    </div>
                                </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField className="" id="country" error={errors && errors.country}>
                                    <label className={labelClassList} htmlFor="country">Country</label>
                                    <div className={inputClassList}>
                                        <Select
                                            name="tittle"
                                            onChange={(event) => {
                                                this.handleCountryChange(event, 'country')
                                            }}
                                            options={countries}
                                            value={newCustodyProfile.beneficialAddress && newCustodyProfile.beneficialAddress.country}
                                        />
                                    </div>
                                </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField className="" id="province" error={errors && errors.province}>
                                    <label className={labelClassList} htmlFor="province">State/ Province</label>
                                    <div className={inputClassList}>
                                    <input
                                        className="form-control"
                                        id="province"
                                        onChange={(event) => {
                                            this.handleOnBeneficialAddressChange(event, 'state')
                                        }}
                                        type="text"
                                        value={newCustodyProfile.beneficialAddress.state ? newCustodyProfile.beneficialAddress.state : ""}
                                    />
                                    </div>
                                </FormField>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div
                            className="inline-container">
                            <div className="icon-container"
                                 onClick={(event) => {
                                     // errors && errors.fullLegalEntityName && this.resetError('fullLegalEntityName');
                                     this.handleBNDAddressSame(newCustodyProfile.isSameBNDAddress ? !newCustodyProfile.isSameBNDAddress.value : false)
                                 }}>
                                <i
                                    className={(
                                        (newCustodyProfile.isSameBNDAddress ? newCustodyProfile.isSameBNDAddress.value : false) ?
                                            "fa fa-check-square-o" : "fa fa-square-o") + " icon"}
                                    style={{fontSize: 20}}
                                    id="beneficiarySameAddress"

                                />
                            </div>
                            BND Address Same As Beneficial Owner Address
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="card-container-form">
                            <div className="section-title">
                                <span>BND Address Details </span>
                            </div>
                            <div className="form-horizontal">
                                <div className="col-md-6 form-group" style={{marginBottom: "0px"}}>
                                <FormField id="streetName"
                                           error={errors && errors.physicalAddressLine1}>
                                    <label className={labelClassList} htmlFor="physicalAddressLine1">House Building Number And Street Name/ Number</label>
                                    <div className={inputClassList}>
                                    <input
                                        className="form-control"
                                        id="streetName"
                                        onChange={(event) => {
                                            this.handleOnBNDAddressChange(event, 'streetName')
                                        }}
                                        type="text"
                                         disabled={(newCustodyProfile.isSameBNDAddress ? newCustodyProfile.isSameBNDAddress.value : false)}
                                        value={ newCustodyProfile.bndAddress.streetName ? newCustodyProfile.bndAddress.streetName :""}
                                    />
                                    </div>
                                </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField id="houseName"
                                           error={errors && errors.physicalAddressLine2}>
                                    <label className={labelClassList} htmlFor="physicalAddressLine1">House/Building Name</label>
                                    <div className={inputClassList}>
                                    <input
                                        className="form-control"
                                        id="houseName"
                                        onChange={(event) => {
                                            this.handleOnBNDAddressChange(event, 'houseName')
                                        }}
                                        type="text"
                                         disabled={(newCustodyProfile.isSameBNDAddress ? newCustodyProfile.isSameBNDAddress.value : false)}
                                        value={newCustodyProfile.bndAddress.houseName ? newCustodyProfile.bndAddress.houseName :""}
                                    />
                                    </div>
                                </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField id="room"
                                           error={errors && errors.physicalAddressLine2}>
                                    <label className={labelClassList} htmlFor="physicalAddressLine1">Room/Floor/Suite</label>
                                    <div className={inputClassList}>
                                    <input
                                        className="form-control"
                                        id="room"
                                        onChange={(event) => {
                                            this.handleOnBNDAddressChange(event, 'roomNumber')
                                        }}
                                        type="text"
                                         disabled={(newCustodyProfile.isSameBNDAddress ? newCustodyProfile.isSameBNDAddress.value : false)}
                                        value={newCustodyProfile.bndAddress.roomNumber ? newCustodyProfile.bndAddress.roomNumber :""}
                                    />
                                    </div>
                                </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField id="suburb"
                                           error={errors && errors.physicalAddressLine2}>
                                    <label className={labelClassList} htmlFor="physicalAddressLine1">Suburb</label>
                                    <div className={inputClassList}>
                                    <input
                                        className="form-control"
                                        id="suburb"
                                        onChange={(event) => {
                                            this.handleOnBNDAddressChange(event, 'suburb')
                                        }}
                                        type="text"
                                         disabled={(newCustodyProfile.isSameBNDAddress ? newCustodyProfile.isSameBNDAddress.value : false)}
                                        value={newCustodyProfile.bndAddress.suburb ? newCustodyProfile.bndAddress.suburb : ""}
                                    />
                                    </div>
                                </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField id="postalCode" error={errors && errors.postalCode}>
                                    <label className={labelClassList} htmlFor="postalCode">Postal/ Zip Code</label>
                                    <div className={inputClassList}>
                                    <input
                                        className="form-control"
                                        id="postalCode"
                                        onChange={(event) => {
                                            this.handleOnBNDAddressChange(event, 'postalCode')
                                        }}
                                        type="number"
                                        maxLength="4"
                                        max="9999"
                                         disabled={(newCustodyProfile.isSameBNDAddress ? newCustodyProfile.isSameBNDAddress.value : false)}
                                        value={newCustodyProfile.bndAddress.postalCode ? newCustodyProfile.bndAddress.postalCode : ""}
                                    />
                                    </div>
                                </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField id="city" error={errors && errors.city}>
                                    <label className={labelClassList} htmlFor="city">City / Town</label>
                                    <div className={inputClassList}>
                                    <input
                                        className="form-control"
                                        id="city"
                                        onChange={(event) => {
                                            this.handleOnBNDAddressChange(event, 'city')
                                        }}
                                        type="text"
                                         disabled={(newCustodyProfile.isSameBNDAddress ? newCustodyProfile.isSameBNDAddress.value : false)}
                                        value={newCustodyProfile.bndAddress.city ? newCustodyProfile.bndAddress.city : ""}
                                    />
                                    </div>
                                </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField id="country" error={errors && errors.country}>
                                    <label className={labelClassList} htmlFor="country">Country</label>
                                    <div className={inputClassList}>
                                        <Select
                                            name="tittle"
                                            onChange={(event) => {
                                                this.handleCountryChange(event, 'country')
                                            }}
                                            options={countries}
                                            disabled={(newCustodyProfile.isSameBNDAddress ? newCustodyProfile.isSameBNDAddress.value : false)}
                                            value={newCustodyProfile.bndAddress && newCustodyProfile.bndAddress.country}
                                        />
                                    </div>
                                </FormField>
                                </div>
                                <div className="col-md-6 form-group">
                                <FormField id="province" error={errors && errors.province}>
                                    <label className={labelClassList} htmlFor="province">State/ Province</label>
                                    <div className={inputClassList}>
                                    <input
                                        className="form-control"
                                        id="province"
                                        onChange={(event) => {
                                            this.handleOnBNDAddressChange(event, 'state')
                                        }}
                                        type="text"
                                         disabled={(newCustodyProfile.isSameBNDAddress ? newCustodyProfile.isSameBNDAddress.value : false)}
                                        value={newCustodyProfile.bndAddress.state ? newCustodyProfile.bndAddress.state  : ""}
                                    />
                                    </div>
                                </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-footer-section">
                    <ButtonsComponent
                        history={this.props.history}
                        onNext={this.saveDetails}
                        onSaveAndContinue={this.saveAndContinue}
                        prevPage={newCustodyAccountRoutes.beneficialowner}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps({custody}) {

    return {
        newCustodyProfile: custody.custodyRequest,
        appId: custody && custody.id
    }
}

function mapDispatchToProps(dispatch) {
    return {action: bindActionCreators(actions, dispatch)}
}


export default connect(mapStateToProps, mapDispatchToProps)(AddressDetailsPage);
