import React from 'react';
import _ from "lodash";
import DropDownIcon from "../DropDownIcon";

export default class ProfileProcessingOptions extends React.Component {
    state = {
        toggle: {},
    };

    componentDidMount() {
        this._setDefaults();
    }

    _setDefaults() {
        const {onUpdateProcessingOptions, processingOptions, processingOptionsSections} = this.props;
        let toggle = {};

        if (_.isEmpty(processingOptions)) {
            let defaultProcessingOptions = {};
            _.map(processingOptionsSections, mainSection => {
                const hasSections = _.size(mainSection.sections) > 0;
                let mainSectionDefaults = mainSection && (mainSection.selectable !== undefined) ? !(mainSection.selectable) : false;
                if (hasSections) {
                    _.map(mainSection.sections, section => {
                        const hasProcessingTypes = _.size(section.processingTypes) > 0;
                        const innerSectionToggleKey = this.getToggleKey(mainSection.sectionId, section.sectionId);

                        let sectionDefaults = section && (section.selectable !== undefined) ? !(section.selectable) : false;
                        if (hasProcessingTypes) {
                            _.map(section.processingTypes, ({processingId}) => {
                                sectionDefaults = _.extend({}, sectionDefaults, {[processingId]: false});
                            });
                        }

                        mainSectionDefaults = _.extend({}, mainSectionDefaults, {[section.sectionId]: sectionDefaults});
                        toggle = _.extend({}, toggle, {[innerSectionToggleKey]: false});
                    });
                }
                defaultProcessingOptions = _.extend({}, defaultProcessingOptions, {[mainSection.sectionId]: mainSectionDefaults});
                toggle = _.extend({}, toggle, {[mainSection.sectionId]: false});
            });
            _.defer(() => onUpdateProcessingOptions && onUpdateProcessingOptions(defaultProcessingOptions));
            this.setState({toggle});
        } else {
            this._toggleSections();
        }
    }

    _toggleSections() {
        const {processingOptions, processingOptionsSections} = this.props;
        let toggle = {};
        const toggleSection = (sectionId, innerId) => {
            let value;
            if (innerId) {
                value = processingOptions && processingOptions[sectionId] && processingOptions[sectionId][innerId];
            } else {
                value = processingOptions && processingOptions[sectionId];
            }

            const shouldBeToggled = (value === true) ? true : _.find(value, d => (d === true));
            return shouldBeToggled ? shouldBeToggled : false
        };

        _.map(processingOptionsSections, mainSection => {
            _.map(mainSection.sections, section => {
                const shouldBeToggled = toggleSection(mainSection.sectionId, section.sectionId);
                const innerSectionToggleKey = this.getToggleKey(mainSection.sectionId, section.sectionId);

                if (!(toggle && toggle[mainSection.sectionId])) {
                    toggle = _.extend({}, toggle, {[mainSection.sectionId]: shouldBeToggled});
                }

                if (!(toggle && toggle[innerSectionToggleKey])) {
                    toggle = _.extend({}, toggle, {[innerSectionToggleKey]: shouldBeToggled});
                }
            });
            if (!(toggle && toggle[mainSection.sectionId])) {
                toggle = _.extend({}, toggle, {[mainSection.sectionId]: toggleSection(mainSection.sectionId)});
            }
        });
        this.setState({toggle});
    }

    getToggleKey = (mainSectionId, sectionId = null) => {
        return (mainSectionId && sectionId) ? `${mainSectionId}/${sectionId}` : mainSectionId
    };

    handleToggle = (attribute, hardToggle = null) => {
        this.setState(prevState => {
            const value = (hardToggle !== null) ? hardToggle : !(prevState.toggle && prevState.toggle[attribute]);
            const toggle = _.extend({}, prevState.toggle, {[attribute]: value});
            return {toggle}
        });
    };

    handleOnChange = (isSelected, sectionId, attribute = null, innerAttribute = null) => {
        const {processingOptions, onUpdateProcessingOptions} = this.props;
        const section = processingOptions && processingOptions[sectionId];
        let value = isSelected;
        if (innerAttribute) {
            value = _.extend({}, section && section[attribute], {[innerAttribute]: value});
        }
        if (attribute) {
            value = _.extend({}, section, {[attribute]: value});
        }
        const updatedProcessingOptions = _.extend({}, processingOptions, {[sectionId]: value});

        onUpdateProcessingOptions && onUpdateProcessingOptions(updatedProcessingOptions);
    };

    _renderMainSection(mainSection, index) {
        const {productCategories} = this.props;
        const {sectionId, sectionTitle, sections, categoryID} = mainSection;

        if (productCategories && (productCategories[categoryID] !== true)) return null;

        return (
            <div
                key={`main-section/${sectionId}/${index}`}
                className="checkbox-inline " style={{marginLeft: 0, marginBottom: 5, paddingLeft: 0}}
            >
                <div
                    className="product-heading"
                    style={{width: "100%", paddingLeft: 0, marginLeft: 0, marginBottom: 5, fontSize: 16}}
                >{sectionTitle}
                </div>
                <div className="title-gradient"/>
                <br/>

                <div className="flexColumn" style={{alignItems: "flex-start"}}>
                    {
                        _.map(sections, (sec, i) => (this._renderInnerSections(sectionId, sec, i)))
                    }
                </div>
            </div>
        )
    }

    _renderInnerSections(mainSectionId, section, index) {
        const {processingOptions, productCategories} = this.props;
        const {sectionId, sectionTitle, processingTypes, categoryID} = section;

        if (productCategories && (productCategories[categoryID] !== true)) return null;

        const {toggle} = this.state;
        const isSelectable = section && (section.selectable !== undefined) ? section.selectable : true;
        const hasProcessingTypes = _.size(processingTypes) > 0;
        const isChecked = (processingOptions && processingOptions[mainSectionId] && processingOptions[mainSectionId][sectionId])
        const innerSectionToggleKey = this.getToggleKey(mainSectionId, sectionId);

        return (
            <div key={`section/${mainSectionId}/${sectionId}/${index}`} className="checkbox-inline"
                 style={{marginLeft: 0, marginBottom: 5, color: "black"}}
                 onClick={(e) => {
                     e.stopPropagation();
                     !hasProcessingTypes && this.handleOnChange(!(isChecked === true), mainSectionId, sectionId);
                     this.handleToggle(innerSectionToggleKey)
                 }}>
                <DropDownIcon
                    isToggled={(isChecked || toggle && toggle[innerSectionToggleKey])}
                    shouldDropDown={hasProcessingTypes}
                    isSelectable={isSelectable}
                />
                {sectionTitle}
            </div>
        )
    }

    render() {
        const {processingOptionsSections} = this.props;
        return (
            <div className="flexColumn" style={{alignItems: "flex-start"}}>
                {
                    _.map(processingOptionsSections, (mainSection, i) => (
                        this._renderMainSection(mainSection, i)
                    ))
                }
            </div>
        );
    }
}