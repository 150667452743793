import axios from "axios";
import { saveAs } from "file-saver";
import { API_ROOT } from "../api-config";
import * as types from "./actionTypes";
import { authCheck } from "./appLoading";
import { useDispatch } from "react-redux";

const DOWNLOAD_URL = `${API_ROOT}/api/reportdownload`;
const EMAIL_URL = `${API_ROOT}/api/reportemail`;

export function downloadReport(
    reportName,
    requestBody,
    onComplete = null,
    showError = false
) {
    return async (dispatch) => {
        try {
            // enableLoading &&
            axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("access_token");
            const url = `${DOWNLOAD_URL}?reportName=${reportName}`;

            const responseData = await axios
                .post(url, requestBody)
                .then((response) => response.data);

            if (responseData.success) {
                const { body, headers } = responseData.data;
                try {
                    const binary = atob(body.replace(/\s/g, ""));
                    const len = binary.length;
                    const buffer = new ArrayBuffer(len);
                    const view = new Uint8Array(buffer);

                    _.map(_.range(0, len), (i) => (view[i] = binary.charCodeAt(i)));

                    const blob = new Blob([view], { type: headers["Content-Type"][0] });
                    saveAs(blob, `${reportName}.pdf`);
                } catch (err) {
                    console.log("actions.reports.downloadReport.err ===>");
                }
                onComplete && onComplete(true);
            } else {
                console.log(
                    "actions.reports.downloadReport.errorMessage ===> ",
                    responseData.message
                );
                onComplete && onComplete();
                showError &&
                    dispatch({
                        type: types.SHOW_RESPONSE_ERROR,
                        errorMessage: "Couldn't download report.",
                    });
            }
        } catch (error) {
            onComplete && onComplete();
            console.log("actions.reports.downloadReport.error ===> ", error);
            authCheck(dispatch, error);
        }
    };
}

export function downloadConfirmationReport(reportName, selectedEntity, requestBody, onComplete = null, showError = false) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("access_token");

            const url = `${DOWNLOAD_URL}?reportName=${reportName}`;
            const response = await axios.post(url, requestBody, {
                responseType: "blob",
            });

            if (response.status === 200) {
                const blob = response.data;
                const contentType = response.headers["content-type"];
                let fileExtension = "zip"; // Default to zip
                
                if (contentType === "application/pdf") {
                    fileExtension = "pdf";
                } else if (contentType === "application/zip") {
                    fileExtension = "zip";
                }

                const downloadUrl = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = downloadUrl;
                a.download = `${selectedEntity.registeredName}.${fileExtension}`; 
                document.body.appendChild(a);
                a.click();
                a.remove();

                window.URL.revokeObjectURL(downloadUrl);

                if (onComplete) {
                    onComplete(true);
                }
            } else {
                console.log("Error: Could not download the report.");
                if (onComplete) {
                    onComplete(false);
                }

                if (showError) {
                    console.log("showError is true. Dispatching error action...");
                    dispatch({
                        type: types.SHOW_RESPONSE_ERROR,
                        errorMessage: "Couldn't download report.",
                    });
                }
            }
        } catch (error) {
            console.log("Download Report Error: ", error);
            if (onComplete) {
                onComplete(false);
            }
            authCheck(dispatch, error);
        }
    };
}

export function emailReport(reportName, requestBody, onComplete = null) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common["Authorization"] =
                "Bearer " + localStorage.getItem("access_token");
            const url = `${EMAIL_URL}?reportName=${reportName}`;
            const responseData = await axios
                .post(url, requestBody)
                .then((response) => response.data);
            if (responseData.success) {
                onComplete && onComplete(true);
            } else {
                onComplete && onComplete();
                console.log(
                    "actions.reports.emailReport.errorMessage ===> ",
                    responseData.message
                );
            }
        } catch (error) {
            onComplete && onComplete();
            console.log("actions.reports.emailReport.error ===> ", error);
            authCheck(dispatch, error);
        }
    };
}
