import axios from 'axios';
import * as types from './actionTypes';
import {API_ROOT} from '../api-config';
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from './appLoading';

const ENTITIES_URL = API_ROOT + '/api/legal-entities';
const APPLICATIONS_URL = API_ROOT + '/api/applications/';
const ONBOARD_ENTTY_URL = API_ROOT + '/api/onboard-entity';
const ENTITY_DETAILS_URL = API_ROOT + '/api/gt-corporates/';
const AUTHORISERS_URL = API_ROOT + '/api/approvals/';


export function fetchHierachy(impersonation, onComplete) {

    let url = ENTITIES_URL;
    if (impersonation && impersonation.identityNumber) {
        url = url + '?id=' + impersonation.identityNumber;
    }
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(url).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.FECTH_HIERACHY_SUCCESS, entities: responseData.data});
            } else {
                console.log('entity.fetchHierachy.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t retrieve entities hierarchy.'});
            }

            if(onComplete) {
                onComplete('isFetchingEntities', dispatch);
            } else {
                loadingPageDataFinish(dispatch);
            }

        } catch (error) {
            console.log('entity.fetchHierachy.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            onComplete && onComplete('isFetchingEntities');
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function startApplication({goldTierId}, application,impersonation, onProceed = null){
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            application.legalEntity = {goldTierId};
            application.impersonatedIdNumber= impersonation.identityNumber;

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.post(APPLICATIONS_URL, application).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.ENTITY_SELECTED, application: responseData.data});
                onProceed && onProceed();
            } else {
                console.log('entity.fetchSelectedEntity.errorMessage ===> ', responseData.message);
                const hasNoApprovalRules = responseData && responseData.message && responseData.message.includes('no auth rules');
                const hasInsufficientApprovalRules = responseData && responseData.message && responseData.message.includes('Not enough');
                if(hasNoApprovalRules){
                    onProceed && onProceed(false, 'Authorisation Rules Need To Be Configured');
                }
                else if(hasInsufficientApprovalRules){
                    onProceed && onProceed(false, 'Not enough registered users as per Authorisation Rules settings. Please update the rules or ensure enough authorisers are registered');
                }
                else {
                    dispatch({
                        type: types.SHOW_RESPONSE_ERROR,
                        errorMessage: 'Couldn\'t retrieve the selected entity details.'
                    });
                }
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.error('entity.fetchSelectedEntity.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function getEntityDetails(gtid){
    const url = ENTITY_DETAILS_URL + gtid;
    return async (dispatch) => {
        try{
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(url).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.FECTH_ENTITY_SUCCESS, entityData: responseData.data});
            }
            loadingPageDataFinish(dispatch);
        } catch (e) {
            console.log('entity.fetchDataByCif.error ===> ', e);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function addEntity(regNum, applicationId, history) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(APPLICATIONS_URL + applicationId + '/legalEntity/search?regNum=' + regNum).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.ENTITY_SELECTED, application: responseData.data, history});
            } else {
                console.log('entity.fetchSelectedEntity.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t add new entity'});
            }
        } catch (error) {
            console.log('entity.addEntity.error ===> ', error);
            authCheck(dispatch, error);
        }
    };
}


export function updateEntity(entity, applicationId, saveAndContinue, onProceed) {
    return async (dispatch) => {
        try {

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

            let url = saveAndContinue ? APPLICATIONS_URL + applicationId + '/legalEntity?saveAndContinue=true' : APPLICATIONS_URL + applicationId + '/legalEntity';
            const responseData = await axios.put(url, entity).then(response => response.data);


            if (responseData.success) {
                onProceed && onProceed();
                dispatch({type: types.ENTITY_UPDATE_SUCCESS, application: responseData.data});
            } else {
                console.log('entity.updateEntity.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t confirm entity details.'});
            }
        } catch (error) {
            console.log('entity.updateEntity.error ===> ', error);
            authCheck(dispatch, error);
        }
    };
}

export function fetchEntityInfo(regNum, finish, onSuccess = null, onError = null) {
    return async (dispatch) => {
        try {
            dispatch({type: types.REMOVE_SEARCH_RESULTS});

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(ENTITIES_URL + '/search?regNum=' + regNum).then(response => response.data);

            finish && finish(responseData.data);
            if (responseData.success) {
                dispatch({type: types.SEARCH_ENTITY_SUCCESS, entityInfo: responseData.data});
                onSuccess && onSuccess(responseData.data);
            } else {
                console.log('entity.fetchEntityInfo.errorMessage ===>', responseData.message);
                onError && onError();
            }
        } catch (error) {
            console.log('entity.fetchEntityInfo.error ===>', error);
            const status = error.response && error.response.status;
            if (status === 404) {
                console.log(status);
                onError && onError();
            }
            authCheck(dispatch, error);
        }
    };
}

export function removeSearched() {
    return (dispatch) => dispatch({type: types.REMOVE_SEARCH_RESULTS});
}

export function entityInfoLookup({registeredName, registeredNumber}, newEntityDetails, onProceed, onError) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const url = `${ENTITIES_URL}/search?regNum=${registeredNumber}&regName=${registeredName}`;
            const responseData = await axios.get(url).then(response => response.data);

            if (responseData.success) {
                const legalEntity = _.extend({}, newEntityDetails && newEntityDetails.legalEntity, responseData.data);
                dispatch({
                    type: types.NEW_ENTITY_SEARCH_SUCCESS, newEntityDetails: _.extend({}, newEntityDetails, {legalEntity})});
            } else {
                console.log('entity.entityInfoLookup.errorMessage ===>', responseData.message);
            }
            loadingPageDataFinish(dispatch);
            onProceed && onProceed();
        } catch (error) {
            console.log('entity.entityInfoLookup.error ===>', error);
            const status = error.response && error.response.status;
            if (status === 404) {
                console.log(status);
                onError && onError();
                loadingPageDataFinish(dispatch);
            } else {
                authCheck(dispatch, error);
                loadingPageDataFinish(dispatch, true, status);
            }
        }
    };
}

export function onChangeValue(objName, attribute, value) {
    return (dispatch) => {
        dispatch({type: types.ON_CHANGE_ENTITY_VALUE, objName, attribute, value});
    };
}

export function startNewEntityOnboard(newEntityDetails, onProceed, onComplete, canUpdateEntity = true, startProcess= false, showSpinner = false) {
    return async dispatch => {
        try {
            showSpinner && loadingPageDataStart(dispatch);

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

            const responseData = await axios.post(ONBOARD_ENTTY_URL + '/save', newEntityDetails).then(response => response.data);

            if (responseData.success) {
                onProceed && onProceed();
                const update = _.extend({}, newEntityDetails, {id: responseData.data && responseData.data.id});
                const action = _.extend({
                    type: types.SAVE_NEW_ENTITY_INFO_SUCCESS,
                    errorMessage: null
                }, canUpdateEntity && {newEntityDetails: update});
                dispatch(action);
                onComplete && onComplete(true);
            } else {
                console.log('entity.saveNewEntityInfo.errorMessage ====>', responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: 'Couldn\'t start onboarding new entity application.'
                });
            }
            showSpinner && loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('entity.saveNewEntityInfo.error ===>', error);
            authCheck(dispatch, error);
            onComplete && onComplete();
            const status = error.response && error.response.status;

            showSpinner && loadingPageDataFinish(dispatch, true, status);
        }
    };
}


export function saveNewEntityInfo(newEntityDetails, onProceed, onComplete, canUpdateEntity = true, startProcess= false, showSpinner = false) {
    return async dispatch => {
        try {
            showSpinner && loadingPageDataStart(dispatch);

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

            const responseData = await axios.put(ONBOARD_ENTTY_URL + '/save', newEntityDetails).then(response => response.data);

            if (responseData.success) {
                onProceed && onProceed();
                const update = _.extend({}, newEntityDetails, {id: responseData.data && responseData.data.id});
                const action = _.extend({
                    type: types.SAVE_NEW_ENTITY_INFO_SUCCESS,
                    errorMessage: null
                }, canUpdateEntity && {newEntityDetails: update});
                dispatch(action);
                onComplete && onComplete(true);
            } else {
                console.log('entity.updateeNewEntityInfo.errorMessage ====>', responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: 'Couldn\'t save onboarding new entity application.'
                });
            }
            showSpinner && loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('entity.saveNewEntityInfo.error ===>', error);
            authCheck(dispatch, error);
            onComplete && onComplete();
            const status = error.response && error.response.status;
            showSpinner && loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function onboardNewEntity(newEntityDetails, onSuccess) {
    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.put(ONBOARD_ENTTY_URL + '/complete', newEntityDetails).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.ONBOARD_NEW_ENTITY_SUCCESS, newEntityDetails});
                onSuccess && onSuccess();
            } else {
                console.log('entity.saveNewEntityInfo.errorMessage ====>', responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: 'Couldn\'t complete onboard new entity application.'
                });
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('entity.onboardNewEntity.error ===>', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchEntityOnboardingRequests(onComplete) {
    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(ONBOARD_ENTTY_URL).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.FETCH_ENTITIES_ONBOARD_REQUESTS_SUCCESS, newEntitiesRequests: responseData.data});
            } else {
                console.log('entity.fetchEntityOnboardingRequests.errorMessage ====>', responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: 'Couldn\'t retrieve list of new entities requests.'
                });
            }
            if (onComplete){
                onComplete('isFetchingRequest', dispatch);
            } else {
                loadingPageDataFinish(dispatch);
            }
        } catch (error) {
            console.log('entity.fetchEntityOnboardingRequests.error ===>', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchSelectedOnboardRequest(id, history, onFinish = null) {
    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(`${ONBOARD_ENTTY_URL}/${id}`).then(response => response.data);

            if (responseData.success) {
                const newEntityDetails = responseData.data;
                dispatch({type: types.FETCH_ENTITY_ONBOARD_REQUEST_SUCCESS, newEntityDetails});
                history.push(`/onboard/${newEntityDetails.status}?continue=true`);
            } else {
                console.log('entity.fetchSelectedOnboardRequest.errorMessage ====>', responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: 'Couldn\'t retrieve selected new entity application.'
                });
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('entity.fetchSelectedOnboardRequest.error ===>', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchAuthorizers(appId, onProceed = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(AUTHORISERS_URL + appId).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.FETCH_AUTHORISERS, authorisers: responseData.data});
                onProceed && onProceed();
            } else {
                console.log('entity.fetchAuthorizers.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t confirm KYC.'});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('entity.fetchAuthorizers.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, false, status);
        }
    };
}

export function clearEntityInfo() {
    return dispatch => dispatch({type: types.REMOVE_SEARCH_RESULTS});
}
