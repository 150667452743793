import axios from 'axios';
import * as types from './actionTypes';
import {API_ROOT, AUTH_URL} from "../api-config";
import {authCheck} from "./appLoading";
import _ from "lodash";
import {newAccountOpeningRoutes} from "../routers/routes";

const PROFILE_URL = AUTH_URL + '/api/me';
const DASHBOARD_URL = API_ROOT + '/api/dashboard';
const APPLICATIONS_URL = API_ROOT + '/api/applications';
const DELEGATION_URL = API_ROOT + '/api/impersonations/';

const CHEQUE_PENDING_TASKS = {
    confirmEnitityDetails: "UserTask_ConfirmEnitityDetails",
    confirmAccountDetails: "UserTask_ConfirmAccountDetails",
    allConfirmed: "UserTask_AllConfirmed"
};

export function loadProfile() {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');

            const response = await axios.get(PROFILE_URL).then(response => response.data);
            dispatch({type: types.LOAD_PROFILE_SUCCESS, user:  response.data});

            const impersonationRes = await axios.get(DELEGATION_URL + '?reverse=false').then(response => response.data);
            dispatch({type: types.FETCH_IMPERSONATIONS_SUCCESS, impersonationList: impersonationRes.data});
        } catch (error) {
            console.log("user.loadProfile.error ===>", error)
            authCheck(dispatch,error);
        }
    };
}

export function loadUserApplications(onComplete = null) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(DASHBOARD_URL).then(response => response.data);
            onComplete && onComplete();
            if (responseData.success) {
                const applications = _.reverse(_.sortBy(responseData.data, application => application.id));
                dispatch({type: types.LOAD_USER_APPLICATIONS_SUCCESS, applications, errorMessage: null});
            } else {
                console.log("user.loadUserApplications.errorMessage ===>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage:  "Couldn't retrieve your applications."});
            }
        } catch (error) {
            onComplete && onComplete();
            console.log("user.loadUserApplications.error ===>", error);
            authCheck(dispatch,error);
        }
    };
}

export function loadSelectedChequeApplication(applicationId, history, onFinish = null) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(APPLICATIONS_URL + '/' + applicationId).then(response => response.data);

            if(responseData.success){
                const application = responseData.data;
                dispatch({type: types.LOAD_SELECTED_APPLICATION_SUCCESS, application});

                const pendingTasks = application && application.applicationStatus && application.applicationStatus.pendingTasks;

                let path = "/"
                if (!(application && application.legalEntity)) {
                    path = newAccountOpeningRoutes.newAccount;
                } else if (_.find(pendingTasks, task => (task.name === CHEQUE_PENDING_TASKS.confirmEnitityDetails))) {
                    path = newAccountOpeningRoutes.confirmdetails;
                } else if (_.find(pendingTasks, task => (task.name === CHEQUE_PENDING_TASKS.confirmAccountDetails))) {
                    path = newAccountOpeningRoutes.confirmproduct;
                } else if (_.find(pendingTasks, task => (task.name === CHEQUE_PENDING_TASKS.allConfirmed))) {
                    path = newAccountOpeningRoutes.kyc;
                }
                history.push(path);

            } else {
                console.log("user.loadSelectedApplication.errorMessage ===> ", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage:  "Couldn't retrieve selected application."});
            }
            onFinish && onFinish()
        } catch (error) {
            console.log("user.loadSelectedApplication.error ===> ", error);
            authCheck(dispatch,error);
            onFinish && onFinish()
        }
    };
}
