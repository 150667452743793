import React from "react";
import _ from 'lodash';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

class ApplicationCompletePage extends React.Component {

    handleOnNavgiateToHome = (event) => {
        event.preventDefault();
        const {history} = this.props;
        history.replace("/");
    };

    _renderFooter() {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={this.handleOnNavgiateToHome}
                    type="button"
                >Home
                </button>
            </div>
        )
    }

    render() {
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="flexColumn">
                        <div className="card-container">
                            <div className="section-title">Application Submitted</div>
                            <br/>
                            <p>
                                It should take 24 hours for your application to be completed.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
            </div>
        )
    }
}

function mapStateToProps({loading}, ownProps) {
    return {loading};
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationCompletePage);