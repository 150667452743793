import React from 'react';
import _ from "lodash";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";

import * as actions from "../../actions/bol";
import {
    USER_PROFILE_PROCESSING_OPTIONS,USER_PROFILE_RELEASE_LEVELS_SECTIONS
} from "../../data/bolDometicTakeOn"
import ReleaseLevelsAndLimits from "./ReleaseLevelsAndLimits";
import ProfileProcessingOptions from './ProfileProcessingOptions'

class ProfileProcessingOptionsPage extends React.Component {
    state = {
        errors: {},
    };

    handlePrevious = () => {
        this.props.history.push("/bol/channels");
    };

    handleOnUpdateProcessingOptions = (processingOptions) => {
        const {actions, newBolProfile} = this.props;
        actions.onChangeValue('newBolProfile', 'processingOptions', processingOptions);
    };

    handleUpdateReleaseLevels = (releaseLevelsAndLimits) => {
        const {actions} = this.props;
        actions.onChangeValue('newBolProfile', "releaseLevelsAndLimits", releaseLevelsAndLimits);
    };

    handleSaveAndContinue = () => {
        const {actions, newBolProfile, history} = this.props;
        const onProceed = () => {
            history.replace('/');
            //clear newBolProfile data
        };
        const updateDetails = _.extend({}, newBolProfile, {status: 'processing'});
        actions.saveNewBolProfile(updateDetails, onProceed)
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const {actions, newBolProfile, history} = this.props;
        const onProceed = () => history.push("/bol/accounts");
        const updateDetails = _.extend({}, newBolProfile, {status: 'accounts'});
        actions.saveNewBolProfile(updateDetails, onProceed);
    }

    getProductCategories = () => {
        const {newBolProfile} = this.props;
        const productsAndServices = newBolProfile && newBolProfile.productsAndServices;
        let productCategories = {};
        _.map(productsAndServices, ({productId})=>(productCategories = _.extend({}, productCategories, {[productId]: true})));

        return productCategories
    };

    _renderProcessingOptions() {
        const {newBolProfile} = this.props;
        const processingOptions = newBolProfile && newBolProfile.processingOptions;

        const productCategories = this.getProductCategories()
        return (
            <div className="card-container-form">
                <div className="section-title">
                    <span>Processing Options</span>
                </div>
                <ProfileProcessingOptions
                    processingOptionsSections={USER_PROFILE_PROCESSING_OPTIONS}
                    processingOptions={processingOptions}
                    productCategories={productCategories}
                    onUpdateProcessingOptions={this.handleOnUpdateProcessingOptions}
                />
            </div>
        )
    }

    _renderReleaseLevels() {
        const {newBolProfile} = this.props;
        const releaseLevelsAndLimits = newBolProfile && newBolProfile.releaseLevelsAndLimits;
        const productCategories = this.getProductCategories()
        return (
            <div className="card-container-form">
                <div className="section-title">
                    <span>Release Levels and Limits</span>
                </div>
                <ReleaseLevelsAndLimits
                    levelsSections={USER_PROFILE_RELEASE_LEVELS_SECTIONS}
                    releaseLevels={releaseLevelsAndLimits}
                    productCategories={productCategories}
                    onUpdateLevels={this.handleUpdateReleaseLevels}
                />
                <br/>
            </div>
        )
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <button
                    className="action-btn-primary"
                    onClick={this.handlePrevious}
                    type="button"
                >Previous
                </button>
                <button
                    className="action-btn-secondary"
                    onClick={this.handleSaveAndContinue}
                    type="button"
                > Save And Continue Later
                </button>
                <button
                    className="action-btn-primary"
                    type="submit"
                >Next
                </button>
            </div>
        )
    }

    render() {
        return (
            <form className="page-container" onSubmit={this.handleSubmit}>
                <div className="page-main-section">
                    <div className="flexColumn">
                        {this._renderReleaseLevels()}
                    </div>
                    <div className="flexColumn">
                        {this._renderProcessingOptions()}
                    </div>
                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
            </form>
        )
    }
}

function mapStateToProps({bol}) {
    return {
        newBolProfile: bol && bol.newBolProfile ? bol.newBolProfile : {},
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileProcessingOptionsPage);