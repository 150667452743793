import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/omega/theme.css';
import {managedFundRoutes} from '../../../routers/routes';
import CashEntityDocsComponent from '../cashAccount/cashKycEntityDocs';
 import SignatoriesComponent from '../mFundSignatoriesComponenet';
import ButtonsComponent from '../../ButtonsComponent';
import {saveManagedFund} from "../../../actions/cashManagedFund";

export const kyc = (props) => {

    const applicationId = useSelector(state => state.cashManagedFund && state.cashManagedFund.id);
    const managedFundRequest = useSelector(state => state.cashManagedFund && state.cashManagedFund.cashManagedFundRequest);
    const [signatories, setSignatories] = useState(managedFundRequest.signatories);
    const [signatoryCopyAccount, setSignatoryCopyAccount] = useState(managedFundRequest.signatoryCopyAccount);
    const [entityDocs, setEntityDocs] = useState(managedFundRequest.documents);

    const systemError = useSelector(state => state.systemError);
    const tagged = useState(true);

    const entityDocsRef = React.useRef(null);
    const signatoriesRef = React.useRef(null);

    const dispatch = useDispatch();

    const saveAndContinue = (event) => {
        saveDetails(event, true);
    };

    const saveDetails = (event, saveAndContinue) => {
        
        const {history} = props;
        let cashManagedFundRequest = managedFundRequest;
        let nextPage = saveAndContinue ? '/saveapplication' :  managedFundRoutes.cashConfirmAuthorisers;
        let task = saveAndContinue ? 'saveContinue' : 'UserTask_ManagedFundKYC';
        const onProceed = () => history && history.push(nextPage);
        cashManagedFundRequest.signatories = signatories;
        cashManagedFundRequest.documents = entityDocs;
        managedFundRequest.signatoryCopyAccount = signatoryCopyAccount;
        const isDocsValid = entityDocsRef.current && entityDocsRef.current.validateDocs();
        const isSignatoriesValid = signatoriesRef.current && signatoriesRef.current.validateSignatories();
       
        if (isSignatoriesValid && isDocsValid) {
            dispatch(saveManagedFund(applicationId, cashManagedFundRequest, task, onProceed));
        }

    };

    const renderFooter = () => {
        const {history} = props;
        return (
            <ButtonsComponent
                history={history}
                onNext={saveDetails}
                prevPage={managedFundRoutes.cashConfirmProductdetails}
                onSaveAndContinue={saveAndContinue}
            />
        );
    };

    const handleDocUpdate= (documents) => {

       console.log('updating docs' + JSON.stringify(documents));
      setEntityDocs(documents);

    };

    const handleSignatoryUpdate = (signatories) => {
        setSignatories(signatories);
    };

    const handleSignatoryCopy = (signatoryCopyAccount) => {
        setSignatoryCopyAccount(signatoryCopyAccount);
    };


    if (systemError && systemError.show) return null;
    return (
        <div className="page-container">
            <div className="container" style={{minHeight: '50em'}}>
                <div className="col-md-8">
                    <CashEntityDocsComponent
                        ref={entityDocsRef}
                        applicationId={applicationId}
                        entityDocs={entityDocs}
                        handleDocUpdate={handleDocUpdate}
                    />

                </div>
                <div className="col-md-4">
                    <SignatoriesComponent
                        ref={signatoriesRef}
                        applicationId={applicationId}
                        signatories={signatories}
                        handleSignatoryUpdate={handleSignatoryUpdate}
                        handleSignatoryCopy={handleSignatoryCopy}
                        signatoryCopyAccount={signatoryCopyAccount}
                        entityDocs={entityDocs}
                        handleDocUpdate={handleDocUpdate}
                    />
                </div>

            </div>
            <div className="page-footer-section" style={{width: "80%"}}>
                {renderFooter()}
            </div>
        </div>
    );

};

export default kyc;
