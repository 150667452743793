import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function entityReducer(state = initialState.entities, action) {
    switch (action.type) {
        case types.FECTH_HIERACHY_SUCCESS:
            return {...state, entities: action.entities};

        case types.FECTH_ENTITY_SUCCESS:
            return {...state, selectedEntity: action.selectedEntity};

        case types.NEW_ENTITY_SEARCH_SUCCESS:
            return {...state, newEntityDetails: action.newEntityDetails};

        case types.NEW_ENTITY_DOC_UPDATE_SUCCESS: {
            const application = {...state};
            console.log('state', state);
            // return state;
            let index = -1;
            application.newEntityDetails.legalEntity.entityDocuments.some((docu, i) => {
                if (docu.documentType === action.doc.documentType) {
                    index = i;
                    return true;
                }
            });
            application.newEntityDetails.legalEntity.entityDocuments[index].description = action.doc.description;
            return {...state, ...application};
        }

        case types.ON_CHANGE_ENTITY_VALUE:
            return {
                ...state,
                [action.objName]: _.extend({}, state[action.objName], {[action.attribute]: action.value})
            };

        case types.SAVE_NEW_ENTITY_INFO_SUCCESS:
            const update = action.newEntityDetails ? {newEntityDetails: action.newEntityDetails} : {};
            return {...state, ...update, errorMessage: action.errorMessage};

        case types.ONBOARD_NEW_ENTITY_SUCCESS:
            return {...state, newEntityDetails: action.newEntityDetails, newEntitiesRequests: []};

        case types.FETCH_ENTITIES_ONBOARD_REQUESTS_SUCCESS:
            return {...state, newEntitiesRequests: action.newEntitiesRequests};

        case types.FETCH_ENTITY_ONBOARD_REQUEST_SUCCESS:
            return {...state, newEntityDetails: action.newEntityDetails};

        default:
            return state;
    }
}