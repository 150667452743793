import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import validate from 'validate.js';
import 'react-select/dist/react-select.css';
import branches from '../../../data/branches-std-only';
import {managedFundRoutes} from '../../../routers/routes';
import {clearBolProfileOperators, fetchBolOperators} from '../../../actions/bol';
import ButtonsComponent from '../../ButtonsComponent';
import FormField from '../../formField/FormField';
import {Glyphicon, OverlayTrigger, ToggleButtonGroup, Tooltip} from 'react-bootstrap';
import {BolSetup} from '../mFundBolSetup';
import {trackData} from '../../../actions/analytics';
import Modal from 'react-bootstrap/lib/Modal';
import Jumbotron from 'react-bootstrap/lib/Jumbotron';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import Row from 'react-bootstrap/lib/Row';
import {saveManagedFund} from "../../../actions/cashManagedFund";

const STATEMENTS_DELIVERY_METHODS = {
    email: 'E',
    post: 'M',
    suppressStatement: 'S',
    swift: 'SW'
};
const FREQUENCY = {
    monthly: 'Monthly',
    weekly: 'Weekly',
    daily: 'Daily'
};


export const ADDITIONAL_PRODUCTS = {
    bolId: 'Business Online',
    cashManId: 'Cash Management'
};

export const OTHER_PROFILE_ID = {value: 'otherId', label: 'Other'};

const CashmanDisclaimer = (props) => {
    return (
        <Modal show={props.show} bsSize={'lg'} keyboard={false} backdrop={'static'}>
            <Modal.Header><h3>Disclaimer</h3></Modal.Header>
            <Modal.Body>
                <p>
                    Please read the below disclaimer regarding Cash Management and
                    indicate whether you agree or do not agree.
                </p>
                <Jumbotron style={{overflowY: 'scroll', maxHeight: '40em'}}>
                    <p>
                        The Standard Bank of South Africa Limited (“<strong>the Bank</strong>”) has created a digital
                        platform for
                        Account Opening which allows our customers to open bank accounts on the digital channel.
                        The digital channel has the capability to link new bank accounts to existing Cash Management
                        (“<strong>Cash Man</strong>”) structures, if any. The customers must be aware that two scenarios
                        exist:
                    </p>
                    <h3>
                        <u>Scenario 1</u>
                    </h3>
                    <p>
                        In the event that the bank account that is to be loaded to the digital channel relates to a
                        legal entity that <u>has not</u> already been onboarded onto an existing Cash Man structure then
                        the
                        relevant internal due diligence procedures would need to be conducted and legal documents
                        relevant to the Cash Man product that is being offered to the customer would need to be signed
                        prior to the onboarding of the bank account to the digital channel and the Cash Man structure
                        being implemented.
                    </p>
                    <p>
                        The above processes take time to conduct and the Bank will conduct these internal process
                        requirements within a reasonable time, after receipt of such written request from the customer
                        that they are intending on opening a bank account on the digital channel and add such bank
                        account on the Cash Man structure.
                    </p>
                    <h3>
                        <u>Scenario 2</u>
                    </h3>
                    <p>
                        In the event that the bank account that is to be loaded to the digital channel relates to a
                        legal entity that has already been onboarded onto an existing Cash Man structure then the
                        relevant internal due diligence procedure would not be required as these would have already
                        have been conducted when the Cash Man product was onboarded and the process will be finalised
                        within a reasonable period of time.
                    </p>
                    <p>
                        <strong>
                            The Customer indemnifies the Bank against any loss, damage, claims, costs or any other
                            liability which may arise as a result of the Bank conducting the various internal process
                            requirements before the bank account and Cash Man structure are onboarded and implemented
                            respectively.
                        </strong>
                    </p>
                </Jumbotron>
            </Modal.Body>
            <Modal.Footer>
                <ButtonGroup>


                    <Button onClick={(event) => {
                        props.onCashManDicslaimer(event, 'disclaimerAccepted');
                    }} bsStyle={'primary'} value={true}>
                        Agree
                    </Button>
                    <Button onClick={(event) => {
                        props.onCashManDicslaimer(event, 'disclaimerAccepted');
                    }} bsStyle={'default'} value={false}>Do Not Agree</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    );
};

export const cashConfirmProduct = (props) => {

    const cashRequest = useSelector(state => state.cashManagedFund && state.cashManagedFund.cashManagedFundRequest);
    const applicationId = useSelector(state => state.cashManagedFund && state.cashManagedFund.id);
    const cashManProduct = useSelector(state => state.cashManagedFund && state.cashManagedFund.cashManagedFundRequest.cashManProduct);
    const accountDetails = useSelector(state => state.cashManagedFund && state.cashManagedFund.cashManagedFundRequest.cashAccountDetails);
    const bolProduct = useSelector(state => state.cashManagedFund && state.cashManagedFund.cashManagedFundRequest.bolProduct);
    const bolProfileOperators = useSelector(state => state.cashManagedFund && state.cashManagedFund.cashManagedFundRequest.bol && state.cashManagedFund.cashManagedFundRequest.bol.operators);
    const dispatch = useDispatch();
    const tagged = useState(true);

    // useEffect(() => {
    //         dispatch(fetchBolProfiles(appEntity.cif));
    //     }, []
    // );

    useEffect(() => {
        dispatch(trackData('pageName', 'New Account| Confirm Product| Step 3'));
    }, tagged);

    const confirmDetails = (accountDetails, bolProduct, cashManProduct, saveAndContinue = false) => {
        const {history} = props;
        let nextPage = saveAndContinue ? '/saveapplication' : managedFundRoutes.cashKyc;
        let task = saveAndContinue ? 'saveContinue' : 'UserTask_ManagedFundProduct';
        const onProceed = () => history.push(nextPage);
        let cashManagedFundRequest = cashRequest;
        cashManagedFundRequest.cashAccountDetails = accountDetails;
        cashManagedFundRequest.bolProduct = bolProduct;
        cashManagedFundRequest.cashManProduct = cashManProduct;
        dispatch(saveManagedFund(applicationId, cashManagedFundRequest, task, onProceed));
    };


    const onFetchOperators = (profileId) => {
        dispatch(clearBolProfileOperators());
        _.defer(() => profileId && dispatch(fetchBolOperators(profileId)));
    };

    return (
        <ProductDetailsCard
            accountDetails={accountDetails}
            cashManProduct={cashManProduct}
            bolProduct={bolProduct}
            bolProfileOperators={bolProfileOperators}
            confirmProductDetails={confirmDetails}
            onFetchOperators={onFetchOperators}
            history={props.history}
        />
    );

};

const ProductDetailsCard = (props) => {

    const {bolProfiles, bolProfileOperators, onFetchOperators} = props;
    const defaultBranch = _.find(branches, branch => branch.centreName == 'JOHANNESBURG');
    const filteredBranches = _.filter(branches, b => b.centreName !== defaultBranch.centreName);

    const [errors, setErrors] = useState({});
    const [bolProduct, setBolProduct] = useState(props.bolProduct);
    const [cashManProduct, setCashManProduct] = useState(props.cashManProduct);
    const [accountDetails, setAccountDetails] = useState(props.accountDetails);
    const [disclaimCash, setDisclaimCash] = useState(false);

    const statementTooltip = (
        <Tooltip id="tooltip">
            <strong>Suppress Statement: </strong>
            Select the following option
            if you receive statements
            from Business Online or via Host-to-Host

        </Tooltip>
    );

    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    };

    const validateForm = () => {
        const options = {fullMessages: false};
        const forbiddenDomains = ['gmail.com', 'yahoo.com', 'hotmail.com'];
        const constraints = _.extend(
            {
                accountName: {presence: true, length: {minimum: 1, message: 'required'}}
            },
             accountDetails && accountDetails.statementDeliveryMethod === STATEMENTS_DELIVERY_METHODS.email ? {
                deliveryDestination: {
                    presence: true, email: true, length: {minimum: 3, message: 'required'},
                    format: function (value, obj) {
                        const valueDomain = value && value.split('@').slice(1)[0];
                        const domain = valueDomain && valueDomain.toLowerCase();
                        return _.find(forbiddenDomains, f => f === domain);
                    }
                }
            } : null,
            accountDetails && accountDetails.statementDeliveryMethodSelect === STATEMENTS_DELIVERY_METHODS.swift ? {
                swiftAddress: {
                    presence: true, length: {minimum: 8, message: 'Minimum 8 character required'}
                }
            }: null,
        );

        let errors = validate(accountDetails , constraints, options);

        if (cashManProduct && cashManProduct.enabled) {

            const addCashmanConstraints = _.extend({
                    cashManGroupNumber: {
                        presence: true,
                        length: {minimum: 1, maximum: 10, message: 'Enter max 10 Alpha numeric value'}
                    }
                }
            );
            const err = validate(cashManProduct, addCashmanConstraints, options);
            errors = _.extend({}, errors, err)
        }

        if (bolProduct && bolProduct.enabled) {

            const bolConstraints = _.extend( {
                bolProfileId: {
                    presence: true, length: {minimum: 1, message: 'required'}
                },
                transactionalPayments: {
                    presence: true, numericality: {onlyInteger: true, greaterThan: 0}
                },
                // transactionalTransfers: {
                //     presence: true, 
                //     numericality: {minimum:1, onlyInteger: true, greaterThan: -1, message:"To enter nil value add '00'."}
                // },
                shortName :{
                    presence: true,length: {minimum: 1, message: 'required'}
                },
                operators: {presence: true}
            });


            const limits = bolProduct && bolProduct.limits;
            const operators = bolProduct && bolProduct.operators;
            const bolProfileId = bolProduct && (bolProduct.bolProfileId === OTHER_PROFILE_ID.value) ? null : bolProduct && bolProduct.bolProfileId;

            const bolErrors = validate({...bolProduct,...limits}, bolConstraints, options);
            errors = _.extend({}, errors, bolErrors);
        }

        setErrors(errors);
        return _.isEmpty(errors);
    };

    const saveAndContinue = (event) => {
        saveDetails(event, true);
    };

    const saveDetails = (event, saveAndContinue) => {
        event.preventDefault();
         if (validateForm()) {
            const {confirmProductDetails} = props;
            if (!accountDetails.centreNumber) {
                const defaultBranch = _.find(branches, b => b.centreName === 'JOHANNESBURG');
                accountDetails.centreNumber = defaultBranch.centreNumber;
                accountDetails.branchName = defaultBranch.centreName;
                accountDetails.branchCode = defaultBranch.centreIbt;
            }
        
            confirmProductDetails(accountDetails, bolProduct, cashManProduct, saveAndContinue);
        }
    };

    const handleDeliveryMethodChange = ({target}) => {
        const isPost = target.value === STATEMENTS_DELIVERY_METHODS.post;
        // const isSuppress = target.value === STATEMENTS_DELIVERY_METHODS.suppressStatement;


        if (isPost) {
            setAccountDetails(_.extend({}, accountDetails, {statementDeliveryMethod: target.value, deliveryDestination: null}));
        }
        // else if (isSuppress) {
        //     setAccountDetails(_.extend({}, accountDetails, {statementDeliveryMethod: target.value, deliveryDestination: null, statementDeliveryFrequency: null}));
        // }

        else {
            setAccountDetails(_.extend({}, accountDetails, {statementDeliveryMethod: target.value, deliveryDestination: null, statementDeliveryFrequency: FREQUENCY.daily}));
        }
    };


    const handleDeliveryMethodChangeselect = ({target}) => {
        // const isPost = target.value === STATEMENTS_DELIVERY_METHODS.post;
        const isSuppress = target.value === STATEMENTS_DELIVERY_METHODS.suppressStatement;


        // if (isPost) {
        //     setAccountDetails(_.extend({}, accountDetails, {statementDeliveryMethodSelect: target.value, deliveryDestination: null}));
        // } else
        if (isSuppress) {
            setAccountDetails(_.extend({}, accountDetails, {statementDeliveryMethodSelect: target.value, statementDeliveryFrequency: null}));
        } else {
            setAccountDetails(_.extend({}, accountDetails, {statementDeliveryMethodSelect: target.value, statementDeliveryFrequency: FREQUENCY.daily}));
        }
    };
    const handleBranchChange = ({target}) => {
        const selectedBranch = _.find(branches, b => b.centreName === target.value);
        const updatedAccount = {
            ...accountDetails,
            centreNumber: selectedBranch.centreNumber,
            branchName: selectedBranch.centreName,
            branchCode: selectedBranch.centreIbt
        };
        setAccountDetails(updatedAccount);
    };

    const handlePrefChange = ({target}, attribute) => {
        const {value} = target;
        let sanitizedValue = value;
        if (attribute === 'accountName') {
            // If it's the "accountName" attribute, sanitize the value to allow only alphanumeric characters and spaces
            sanitizedValue = value.replace(/[^a-zA-Z0-9 ]/g, '');
          }
        const updatedAccountDetails = _.extend({}, accountDetails, {[attribute]: _.trimStart(sanitizedValue)});
        setAccountDetails(updatedAccountDetails);
    };

    const onBusOnlineClick = (isSelected) => {
        console.log("bol clicked" + isSelected);
        setBolProduct({...bolProduct, enabled: isSelected});
    };

    const onCashManagementClick = (isSelected) => {
        setCashManProduct({...cashManProduct, enabled: isSelected});
        if (isSelected) {
            setDisclaimCash(true);
        } else {
            setCashManProduct(prevState => ({
                ...prevState,
                disclaimerAccepted: false,
                enabled: false,
                cashManGroupNumber: null 
            }))
        }
    };


    const onCashManChange = (e, attribute) => {
        setCashManProduct(_.extend({}, cashManProduct, {[attribute]: (e.target.value)}));
    };

    const allowAlphaNumeric = (event, attribute) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^a-zA-Z0-9\s]/g, '');
        setCashManProduct(_.extend({}, cashManProduct, {[attribute]: (value)}));
    }

    const onCashManDicslaimer = (e, attribute) => {
        const {value} = e.target;
        value == 'true' ?
        setCashManProduct(prevState => ({
            ...prevState,
            [attribute]: true,
            enabled: true 
        }))
         :  setCashManProduct(prevState => ({
            ...prevState,
            [attribute]: false,
            enabled: false,
            cashManGroupNumber: null  
        }))
        ;
        setDisclaimCash(false);
    };

    const onChangeBolProduct = (bolProduct) => {
        setBolProduct(bolProduct);
    };


    return (
        <div>
            <form className="page-container">
                <div className="container" style={{minHeight: '50em'}}>
                    <div className="col-xs-12 col-md-6">
                        <div className="card-container-form">
                            <div className="section-title">
                                <span>Product Details</span>
                            </div>

                            <FormField className="form-group" id="accName" error={errors && errors.accountName}>
                                <label htmlFor="accountName">Account Name <small className="text-danger">*</small>
                                </label>

                                <input
                                    className="form-control"
                                    id="accountName"
                                    onChange={(event) => {
                                        errors && errors.accountName && resetError('accountName');
                                        handlePrefChange(event, 'accountName');
                                    }}
                                    maxLength={30}
                                    type="text"
                                    value={accountDetails && accountDetails.accountName || ''}
                                />
                            </FormField>

                            <div className="form-group">
                                <label htmlFor="branch">Branch <small className="text-danger">*</small></label>
                                <select
                                    className="form-control"
                                    id="branch"
                                    onChange={(event) => handleBranchChange(event)}
                                    value={accountDetails && accountDetails.branchName || ''}
                                >
                                    <option key={'branchCode-default'}>{defaultBranch.centreName}</option>
                                    {
                                        _.map(filteredBranches, (b) => (
                                            <option key={`branchCode-${b.centreNumber}`}>{b.centreName}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="form-group" error={errors && errors.statementDeliveryMethod}>
                                <label htmlFor="statementDeliveryMethod">
                                    Primary Bank Statement Delivery Method <small className="text-danger">*</small>&nbsp;
                                    {/* <OverlayTrigger placement="right" overlay={statementTooltip}>
                                        <Glyphicon glyph="question-sign"/>
                                    </OverlayTrigger> */}
                                </label>
                                <select
                                    className="form-control"
                                    id="statementDeliveryMethod"
                                    required={true}
                                    onChange={(event) =>{ errors && errors.statementDeliveryMethod && resetError('statementDeliveryMethod'); handleDeliveryMethodChange(event)} }
                                    value={accountDetails && accountDetails.statementDeliveryMethod || ''}>
                                    <option value={STATEMENTS_DELIVERY_METHODS.suppressStatement}>Suppress Statement</option>
                                    <option value={STATEMENTS_DELIVERY_METHODS.email}>Email</option>
                                </select>
                            </div>

                            {
                                 accountDetails.statementDeliveryMethod === STATEMENTS_DELIVERY_METHODS.email &&
                                <FormField className="form-group" id="desti"
                                           error={errors && errors.deliveryDestination}>
                                    <label htmlFor="deliveryDestination">Bank Statement Email Address <small
                                        className="text-danger">*</small></label>
                                    <input
                                        id="deliveryDestination"
                                        className="form-control"
                                        onChange={(event) => {
                                            errors && errors.deliveryDestination && resetError('deliveryDestination');
                                            handlePrefChange(event, 'deliveryDestination');
                                        }}
                                        type="email"
                                        value={accountDetails && accountDetails.deliveryDestination || ''}
                                    />
                                </FormField>
                            }



                            <div className="form-group" error={errors && errors.statementDeliveryMethodSelect}>
                                <label htmlFor="statementDeliveryMethodSelect">
                                Secondary Bank Statement Delivery Method <small className="text-danger">*</small>&nbsp;
                                    <OverlayTrigger placement="right" overlay={statementTooltip}>
                                        <Glyphicon glyph="question-sign"/>
                                    </OverlayTrigger>
                                </label>
                                <select
                                    className="form-control"
                                    id="statementDeliveryMethodSelect"
                                    onChange={(event) => {errors && errors.statementDeliveryMethodSelect && resetError('statementDeliveryMethodSelect'); handleDeliveryMethodChangeselect(event)}}
                                    value={accountDetails && accountDetails.statementDeliveryMethodSelect || ''}>
                                    <option value={STATEMENTS_DELIVERY_METHODS.suppressStatement}>Suppress Statement</option>
                                    <option value={STATEMENTS_DELIVERY_METHODS.swift}>Swift Address</option>
                                </select>
                            </div>

                            {
                                accountDetails.statementDeliveryMethodSelect === STATEMENTS_DELIVERY_METHODS.swift &&
                                <FormField className="form-group" id="desti"
                                           error={errors && errors.swiftAddress}>
                                    <label htmlFor="swiftAddress">Bank Statement Swift Address <small
                                        className="text-danger">*</small></label>
                                    <input
                                        id="swiftAddress"
                                        className="form-control" minLength={8} maxLength={11}
                                        onChange={(event) => {
                                            errors && errors.deliveryDestination && resetError('swiftAddress');
                                            handlePrefChange(event, 'swiftAddress');
                                        }}
                                        type="swift"
                                        value={accountDetails && accountDetails.swiftAddress || ''}
                                    />
                                </FormField>
                            }

                            {
                                (accountDetails.statementDeliveryMethodSelect === STATEMENTS_DELIVERY_METHODS.swift || accountDetails.statementDeliveryMethod =="E") &&

                                <div className="form-group">
                                    <label htmlFor="bankStatementFrequency">Bank Statement Frequency</label>
                                    <select
                                        className="form-control"
                                        id="bankStatementFrequency"
                                        required={true}
                                        onChange={(event) => handlePrefChange(event, 'statementDeliveryFrequency')}
                                        value={accountDetails  && accountDetails.statementDeliveryFrequency || ''}
                                    >
                                        <option>{FREQUENCY.daily}</option>
                                        <option>{FREQUENCY.weekly}</option>
                                        <option>{FREQUENCY.monthly}</option>
                                    </select>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="card-container-form">
                            <div className="section-title">
                                <span>Add account to the following:</span>
                            </div>
                            <div className="input-group greyText fontSize">
                                <p>Select from the list below if your need you account to be added to them:</p>
                            </div>

                            <div>
                                <Row style={{margin: '0'}}>
                                    <span style={{paddingRight: '1em'}}>Business Online</span>
                                    <ToggleButtonGroup type="radio" name="bolOptions" id={'bolOptions'}
                                                       onChange={(event) => onBusOnlineClick(!bolProduct.enabled)}
                                                       value={bolProduct && bolProduct.enabled}>
                                        <ToggleButton id={'bolYes'} value={true}>Yes</ToggleButton>
                                        <ToggleButton id={'bolNo'} value={false}>No</ToggleButton>
                                    </ToggleButtonGroup>
                                </Row>
                            </div>

                            {
                                (bolProduct && bolProduct.enabled) &&

                                <BolSetup
                                    bolProduct={bolProduct}
                                    bolProfiles={bolProfiles}
                                    bolProfileOperators={bolProfileOperators}
                                    errors={errors}
                                    onFetchOperators={onFetchOperators}
                                    onChange={onChangeBolProduct}
                                    resetErrors={resetError}
                                />
                            }
                            <hr/>

                            <div className="form-group">
                                <div>
                                    <Row style={{margin: '0'}}>
                                        <span style={{paddingRight: '1em'}}>Cash Management</span>
                                        <ToggleButtonGroup type="radio" name="cashmanOptions" id="cashmanOptions"
                                                           onChange={(event) => onCashManagementClick(!cashManProduct.enabled)}
                                                           value={cashManProduct && cashManProduct.enabled}>
                                            <ToggleButton id={'cashmanYes'} value={true}>Yes</ToggleButton>
                                            <ToggleButton id={'cashmanNo'} value={false}>No</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Row>
                                </div>
                                {
                                    (cashManProduct && cashManProduct.enabled) &&
                                    <div>
                                        <br/>
                                        <FormField className="form-group" id="cashman" error={errors && errors.cashManGroupNumber}>
                                            <label>Cashman Group Number</label>
                                            <input
                                                className="form-control"
                                                id="cashManGroupNumber"
                                                maxLength={10}
                                                onChange={(event) => {
                                                    errors && errors.cashManGroupNumber && resetError('cashManGroupNumber');
                                                    onCashManChange(event, 'cashManGroupNumber');
                                                    allowAlphaNumeric(event, 'cashManGroupNumber');
                                                }}
                                                type="text"
                                                value={cashManProduct && cashManProduct.cashManGroupNumber || ''}
                                            />
                                        </FormField>
                                    </div>
                                }
                                <hr/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="page-footer-section" style={{width: "80%"}}>
                    <ButtonsComponent
                        history={props.history}
                        onNext={saveDetails}
                        onSaveAndContinue={saveAndContinue}
                        prevPage={managedFundRoutes.cashConfirmdetails}
                    />
                </div>
            </form>
            <CashmanDisclaimer
                show={disclaimCash}
                onCashManDicslaimer={onCashManDicslaimer}
            />
        </div>
    );
};

export default (cashConfirmProduct);
