import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {fetchHierachy} from '../../actions/entity';
import * as actions from '../../actions/bol';
import EntityList from "../legalentity/EntityList";
import ReactLoading from "react-loading";
import validate from "validate.js";
import FormField from "../formField/FormField";
import Alert from "react-bootstrap/lib/Alert";

class NewBolPage extends Component {
    constructor(props) {
        super(props);
        this.state = {errors: {}, selectedEntity: null, isLoading: false};
        this.handleSelect = this._handleSelect.bind(this);
        this.onSubmit = this._onSubmit.bind(this);
    }

    componentWillMount() {
        this._fetchHierachy();
    }

    _fetchHierachy() {
        const {actions, impersonation} = this.props;
        actions.fetchHierachy(impersonation);
    }

    _handleSelect(selectedEntity) {
        this.setState({selectedEntity});
    }

    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    validateSelected = () => {
        const {selectedEntity} = this.state;
        const options = {fullMessages: false};

        const constraints = {selectedEntity: {presence: true}};
        const errors = validate({selectedEntity}, constraints, options);

        this.setState({errors});
        return _.isEmpty(errors);
    };

    _onSubmit(e) {
        e.preventDefault();
        const {actions, history} = this.props;
        const {selectedEntity} = this.state;
        const customerDetails = _.pick(selectedEntity, ["cif", "registrationNumber", "registeredName"]);

        if (this.validateSelected()) {
            this.setState({isLoading: true});

            const onFinish = () => {
                this.setState({isLoading: false});
                history.push('/bol/details');
            };

            const onSuccess = (entityInfo) => {
                const pickedData = _.pick(entityInfo, ["cif", "registrationNumber", "registeredName","telephoneNumber", "addresses", "registeredAddress", "tradingNames"]);
                actions.onChangeValue('newBolProfile', 'customerDetails', _.extend({}, pickedData,customerDetails, {postalAddress: pickedData.registeredAddress}));
            };
            const onError = () => {
                this.setState({isLoading: false});
                this.setState({errors: {entitySearch: true}})
            }
            actions.saveNewBolProfile({customerDetails}, null, null, true, true);
            _.defer(()=>actions.fetchEntityInfo(selectedEntity && selectedEntity.goldTierId, onFinish, onSuccess, onError))
        }
    }

    _renderEntities() {
        const {entities} = this.props;
        const hasEntities = _.size(entities) > 0;

        if (!hasEntities) return null;
        const {errors,selectedEntity} = this.state;
        return (
            <FormField
                className="form-group"
                error={errors && errors.selectedEntity && ["One Should Be Selected"]}
            >
                <EntityList
                    entities={entities}
                    onSelect={this.handleSelect}
                    selectedEntity={selectedEntity}
                    style={{maxHeight: '65vh'}}
                />
                {
                    errors && errors.entitySearch &&
                    <Alert bsStyle={'danger'}>Error creating your application, failed to fetch your entity details.</Alert>
                }
            </FormField>
        );
    }

    _renderFooter() {
        return (
            <div className="flexRow" style={{justifyContent: "space-between"}}>
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={this.onSubmit}
                    type="button"
                >Next
                </button>
            </div>
        )
    }

    render() {
        const {entities, loading} = this.props;
        const {isLoading} = this.state;

        if (loading) return null;

        const hasEntities = _.size(entities) > 0;
        if (!hasEntities) {
            return (
                <div className="page-container">
                    <div className="page-main-section">
                        <div className="message-container">
                            <h3 className="login_title">You currently have no linked entities!</h3>
                            <p>Please ensure that you are listed as authorised person on your company resolution and
                                / or have been delegated the required access rights.</p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="page-container">
                    <div className="page-main-section">
                        <div className="column" style={{width: "60%", marginTop: "2em"}}>
                            {this._renderEntities()}
                        </div>
                    </div>
                    <div className="page-footer-section">
                        {this._renderFooter()}
                    </div>
                    {
                        isLoading &&
                        <div className="inner-spinner-container">
                            <ReactLoading type="spokes" color="#444"/>
                        </div>
                    }
                </div>
            )
        }
    }
}

function mapStateToProps({entity, impersonation, loading}) {
    return {
        entities: entity && entity.entities ? entity.entities : [],
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, fetchHierachy}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewBolPage);
