import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import "../../styles/ConfirmApplication.css";
import "../../styles/Global.css";
import * as actions from '../../actions/kyc';

class CompletionPage extends React.Component {

    goHome = (event) => {
        event.preventDefault();
        const {history} = this.props;
        history.replace("/");
    };


    _renderFooter() {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={this.goHome}
                    type="button"
                >Home
                </button>
            </div>
        )
    }

    render() {
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="flexColumn">
                        <div className="card-container-form">
                            <div className="section-title">Your application has been successfully completed</div>
                            <div className="input-group greyText fontSize">
                                <p>The custody account details will be emailed to you upon validation...</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
            </div>
        )
    }
}

function mapStateToProps({loading}, ownProps) {
    return {
        loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletionPage);
