import React from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import rsaBanks from '../../data/branches-rsa';
import usBanks from '../../data/branches-us';
import currencies from '../../data/currencies';

import {globalMarketAccountRoutes} from "../../routers/routes";
import * as actions from "../../actions/globalMarket";
import {previous} from '../../actions/navigation';

import "../../styles/ConfirmApplication.css";
import "../../styles/Global.css";
import "react-datepicker/dist/react-datepicker.css";
import { FormComponent } from "./settlements/FormComponent";
import { SettlementComponent } from "./settlements/SettlementComponent";


const INSTRUMENTS = [
    {instrument: 10, instrumentDescription: "FX INTERBANK", active: false},
    {instrument: 11, instrumentDescription: "FX DERIVATIVES", active: false},
    {instrument: 12, instrumentDescription: "INT RATE DERIVATIVES", active: false},
    {instrument: 14, instrumentDescription: "CREDIT DERIVATIVES", active: false},
    {instrument: 20, instrumentDescription: "DERIVATIVES", active: false},
    {instrument: 21, instrumentDescription: "COLLATERAL", active: false},
    {instrument: 30, instrumentDescription: "COMMERCIAL", active: false},
    {instrument: 41, instrumentDescription: "EQUITY DERIVATIVES", active: true},
    {instrument: 45, instrumentDescription: "SECURITIES SETTLEMENT", active: false},
    {instrument: 60, instrumentDescription: "BASE METALS", active: false},
    {instrument: 61, instrumentDescription: "PRECIOUS METALS", active: false},
    {instrument: 65, instrumentDescription: "AGRI", active: false},
    {instrument: 70, instrumentDescription: "MONEY MARKETS", active: true},
    {instrument: 71, instrumentDescription: "COMMODITIES", active: false}
];

const DEFAULT_CURRENCY = "ZAR";
const DEFAULT_BANK = "STANDARD BANK OF S.A LTD";
const DEFAULT_BRANCH = "JOHANNESBURG";

class SettlementInstructionsPage extends React.Component {
    state = {
        instructions: [],
        instruction: {
            instrument: 'MONEY MARKETS',
            ssiNumber: '',
            customerName: '',
            currency: DEFAULT_CURRENCY,
            isBankInstruction: true,

            intermediaryBank: DEFAULT_BANK,
            intermediaryAccountNo: '',
            ibanNumber: '',

            correspondentBIC: true,
            corrSwiftAddress: '',
            corrCurrency: DEFAULT_CURRENCY,
            corrBank: DEFAULT_BANK,
            corrBranch: DEFAULT_BRANCH,

            bankBeneficiaryBIC: true,
            beneficiaryCurrency: DEFAULT_CURRENCY,
            beneficiaryBank: DEFAULT_BANK,
            beneficiaryBranch: DEFAULT_BRANCH,

            customerBeneficiaryBIC: true,
            beneficiaryCurrency: DEFAULT_CURRENCY,
            beneficiaryBank: DEFAULT_BANK,
            beneficiaryBranch: DEFAULT_BRANCH,

            beneficiaryBranchIBT: '',
            beneficiaryAccountNo: '',
            intermediaries: [],

            primarySSI: false,
            editMode: false,
            editable: true
        },
        editMode: false,
        errors: {},

        currencies: [],
        branches: [],
        banks: []
    };

    componentWillMount() {
        this._loadCurrencies();
        this._loadBanks();
        this._loadInstructions();
    }

    componentDidMount() {
        this._init();
    }

    _loadCurrencies = () => {
        let anyCurrency = {
            "name": "ANY",
            "label": "Any Currency",
            "active": "true"
          };
        let filteredCurrencies = _.filter(currencies, c => c.active === 'true');
        filteredCurrencies = filteredCurrencies.concat(anyCurrency);
        this.setState(() => {
            return {currencies: filteredCurrencies}
        });
    }

    _loadBanks = () => {
        let loaded = false;
        if (rsaBanks.success === true) {
            rsaBanks.data.map((bank) => {
                return bank.currency = "ZAR";
            });

        }

        let banks = {...rsaBanks.data};
        this.setState(() => {
            return {banks: banks}
        });

    }

    _loadInstructions = () => {
        const {customer} = this.props;
        console.log ("customer", customer);
        const instructions = customer.settlementInstructions ? customer.settlementInstructions : [];
        console.log ("instructions", instructions);

        // set editable = false
        instructions.forEach(instruction => instruction.editable = false);

        this.setState(() => {
            return { instructions: instructions,
                     groupedInstructions: this._group(instructions)}
        });
    }

    _init = () => {
        let {instruction, currencies, banks} = this.state;
        const {customer} = this.props;

        const defaultBanks = _.reject(banks, (bank) => bank.currency !== DEFAULT_CURRENCY);
        const defaultBank = _.find(defaultBanks, {name: DEFAULT_BANK});
        let disableAddButton = this.disableAddSSIButton();
        instruction.customerName = customer.customerShortName;

        this.setState(() => {
            return {instruction: instruction,
                    currencies: currencies,
                    beneficiaryBanks: defaultBanks,
                    beneficiaryBranches: defaultBank.branches,
                    intermediaryBanks: defaultBanks,
                    disableAddButton: disableAddButton,

                    defaultInstruction: instruction,
                    defaultBeneficiaryBanks: defaultBanks,
                    defaultBeneficiaryBranches: defaultBank.branches,
                    defaultIntermediaryBanks: defaultBanks}

        });
    }

    _group = (instructions) => {
        let result = _(instructions).groupBy('currency').map((items, currency) => {
            return {
                currency: currency, instructions: [...items]
            }
        }).value();

        return (_.values(result));
    }

    _validateForm = () => {
        let {instructions, errors} = this.state;
        errors = {};
        if (!instructions || instructions.length < 1) {
            errors = errors.concat({message: "At least one account has to added!"});
        }

        this.setState({errors});
        console.log(errors)
        return _.isEmpty(errors);
    };

    handleOnNext = (e) => {
        e.preventDefault();
        const {instructions} = this.state;
        const {customer} = this.props;
        console.log("on submit")

        if (this._validateForm()) {

            const {actions, application, history, selectedEntity, impersonation} = this.props;

            const onProceed = () => history && history.push(globalMarketAccountRoutes.complete);

            application.customer.settlementInstructions = instructions;
            actions.processGMApplication(selectedEntity, application, impersonation, true, onProceed);
        }
    };

    saveAndContinue = () => {
        const {instructions} = this.state;
        const {actions, application, history, selectedEntity} = this.props;

        const onProceed = () => history && history.push("/");

        application.instructions = instructions;
        application.applicationStatus = {status: globalMarketAccountRoutes.instructions};
        actions.processGMApplication(selectedEntity, application, impersonation, false, onProceed);
    }

    handleOnPrevious = (e) => {
        e.preventDefault();

        const {actions, history, application} = this.props;
       // actions.previous(globalMarketAccountRoutes.accounts, history);

        const onProceed = () => history.push(globalMarketAccountRoutes.confirmentity);
        actions.update(application, onProceed);
    } 

    _renderFooter = () => {
        const {instructions} = this.state;
        return (
            <div className="action-button-container">
                <div/>
                <div className="page-footer-section" style={{minHeight: 30}}>
                    <div className="action-button-container">
                        <button
                            className="action-btn-primary"
                            onClick={this.handleOnPrevious}
                            type="button">
                            Previous
                        </button>
                        <button
                            className="action-btn-secondary"
                            onClick={this.saveAndContinue}
                            style={instructions.length > 0 ? {opacity: .95} : {opacity: .55}}
                            disabled={instructions.length > 0}
                            type="button">
                            Save And Continue Later
                        </button>
                        <button
                            className="action-btn-primary"
                            style={instructions.length > 0 ? {opacity: .95} : {opacity: .55}}
                            onClick={this.submit}
                            disabled={instructions.length <= 0}
                            type="button">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    disableAddSSIButton = (instruction) => {
        let disable = true;
        if (instruction) {
            disable = !(instruction.beneficiaryAccountNo && instruction.beneficiaryAccountNo.length > 3);
        }
        return disable;
    }

    handleBankNameChange = ({target}) => {
        const {instruction, beneficiaryBanks, agentBanks} = this.state;
        let selectedBank = null;
        let name = "";

        if (target.name === 'beneficiaryBank') {
            selectedBank = _.find(beneficiaryBanks, b => b.name === target.value);
            instruction[target.name] = selectedBank.name;
            if (selectedBank.name === DEFAULT_BANK) {
                instruction.beneficiaryBranch = DEFAULT_BRANCH;
            } else {
                instruction.beneficiaryBranch = selectedBank.branches && selectedBank.branches[0] && selectedBank.branches[0].name || "";
            }

            this.setState({
                beneficiaryBranches: selectedBank.branches,
                instruction: instruction
            });
        }

    }

    handleAddInstrument = (e) => {
        e.preventDefault();
        const {
            instructions,
            instruction,
            defaultInstruction,
            defaultBeneficiaryBanks,
            defaultBeneficiaryBranches,
            defaultIntermediaryBanks,
            banks
        } = this.state;


        instruction.indexKey = Math.round(new Date().getTime() + '' + (Math.random() * Math.pow(10, 6)));

        // set default primary account
        const currencyInstructions = _.reject(instructions, (acc) => acc.currency !== instruction.currency);
        if (currencyInstructions === undefined || currencyInstructions.length === 0) {
            instruction.primarySSI = true;
        } else {
            instruction.primarySSI = false;
        }

        // add new SSI to the list
        const newInstructions = instructions.concat(instruction);

        let disableAddButton = this.disableAddSSIButton();

        this.setState(() => {
            return {
                groupedInstructions: this._group(newInstructions),
                instructions: newInstructions,
                beneficiaryBanks: defaultBeneficiaryBanks,
                beneficiaryBranches: defaultBeneficiaryBranches,
                intermediaryBanks: defaultIntermediaryBanks,
                instruction: defaultInstruction,
                disableAddButton: disableAddButton
            }
        });
    }

    handleAddIntermediary = (e) => {
        e.preventDefault();
        const {intermediary, instruction} = this.state;

        const newInstruction = instruction.intermediaries.concat(intermediary);
        this.setState(() => { return {instruction: newInstruction} });
    }

    handleEditInstrument = (value, indexKey) => {
        const {instructions} = this.state;
        const instruction = _.find(instructions, (acc) => acc.indexKey === indexKey);
        this.setState({editMode: value, instruction: instruction});
    }

    handleEditInstrumentSubmit = (e) => {
        e.preventDefault();
        const {
            instructions,
            instruction,
            defaultInstruction,
            defaultBeneficiaryBanks,
            defaultBeneficiaryBranches,
            defaultIntermediaryBanks
        } = this.state;

        // find SSI tobe edited
        const editedSSI = _.find(instructions, (acc) => acc.indexKey === instruction.indexKey);

        // remove SSI from list, if removed SSI is primary - reassign primary
        const editInstructions = _.reject(instructiona, (acc) => acc.indexKey === editedSSI.indexKey);

        // add updated SSI to the list
        const newInstructions = editInstructions.concat(instruction);

        this.setState(() => {
            return {
                groupedInstructions: this._group(newInstructions),
                instructions: newInstructions,
                beneficiaryBanks: defaultBeneficiaryBanks,
                beneficiaryBranches: defaultBeneficiaryBranches,
                intermediaryBanks: defaultIntermediaryBanks,
                instruction: defaultInstruction,
                editMode: false
            }
        });
    }

    handleRemoveInstruction = (value, indexKey) => {
        const {instructions} = this.state;
        const removedSSI = _.find(instructions, (acc) => acc.indexKey === indexKey);
        this.setState({removeSSI: value, removedSSI: removedSSI});
    }

    handleRemoveInstructionSubmit = (e) => {
        e.preventDefault();
        const {instructions, removedSSI} = this.state;

        // remove SSI from list, if removed SSI is primary - reassign primary
        const removeInstructions = _.reject(instructions, (acc) => acc.indexKey === removedSSI.indexKey);
        if (removedSSI && removedSSI.primarySSI && removeInstructions && removeInstructions.length > 0) {
            removeInstructions[0].primarySSI = true;
        }
        let disableAddButton = this.disableAddSSIButton();

        this.setState(() => {
            return {
                groupedInstructions: this._group(removeInstructions),
                instructions: removeInstructions,
                disableAddButton: disableAddButton,
                removeSSI: false
            }
        });
    }

    handleCurrencyChange = ({target}) => {
        const {instruction, currencies, banks} = this.state;

        const selectedCurrency = _.find(currencies, c => c.name === target.value);
        if (selectedCurrency) {
            const defaultIntermediaryBanks = _.reject(banks, (bank) => bank.currency !== selectedCurrency.name);
            instruction.currency = selectedCurrency.name;

            this.setState({
                intermediaryBanks: defaultIntermediaryBanks,
                instruction: instruction
            });
        }
    }

    handleSSIPrimaryChange = (currency, indexKey) => {
        const {instructions} = this.state;

        instructions && instructions.map((a) => {
            if (a.currency === currency) {
                a.primarySSI = false;
                if (a.indexKey === indexKey) {
                    a.primarySSI = true;
                }
            }
        });
        this.setState({groupedInstructions: this._group(instructions), instructions: instructions});
    }
    
    handleOnCustomerChange = (e) => {
        e.preventDefault();
        const {instruction} = this.state;
        const {application} = this.props;

        let customer = _.find(application.customers, c => c.customerShortName === e.target.value); 
        let newinstruction = {...instruction, customer: customer, customerName: customer.customerShortName}
        this.setState(() => { return {  instruction: newinstruction, 
                                        instructions: customer.settlementInstructions, 
                                        groupedInstructions: this._group(customer.settlementInstructions) }});
    }

    handleOnChange = (e) => {
        const {instruction} = this.state;
        let newinstruction = {...instruction, [e.target.name]: e.target.value}

        let disableAddButton = this.disableAddSSIButton(newinstruction);
        this.setState({instruction: newinstruction, disableAddButton: disableAddButton});
    }

    handleOnToggle = (e, name) => {
        const {instruction} = this.state;
        const newinstruction = {...instruction, [name]: e};
        this.setState({instruction: newinstruction});
    }

    render() {
        const {
            errors,
            groupedInstructions,
            instruction,
            disableAddButton,
            currencies,
            intermediaryBanks,
            beneficiaryBanks,
            beneficiaryBranches
        } = this.state;

        const {
            application, 
            customer, 
            hasMoneyMarketSubscription
        } = this.props;
        return (
            <div className="page-container">
                <div style={{minHeight: 40}}>
                    <div className="col-md-2 card-container-form">
                        <div>
                            <div className="col-md-12">
                                <form>
                                    <FormComponent
                                        handleOnChange={this.handleOnChange}
                                        handleOnToggle={this.handleOnToggle}
                                        handleOnCustomerChange={this.handleOnCustomerChange}

                                        instruction={instruction}
                                        customer={customer}
                                        hasMoneyMarketSubscription={hasMoneyMarketSubscription}

                                        customers={application.customers}
                                        currencies={currencies}
                                        INSTRUMENTS={INSTRUMENTS}
                                    />

                                </form>

                                <hr style={{ margin: 0}}/>
                                <div className="col-md-12 " style={{padding: 10}}>

                                    <div style={{paddingLeft: 30, paddingRight: 30}}>
                                        <button
                                            className="action-btn-primary pull-right"
                                            onClick={this.addInstruction} style={{marginLeft: 50}}
                                            type="button">
                                            Add Instruction
                                        </button>
                                    </div>
                                </div>

                                <SettlementComponent
                                    handleAddInstruction={this.handleAddInstruction}
                                    handleAddIntermediary={this.handleAddIntermediary}
                                    handleEditInstruction={this.handleEditInstruction}
                                    handleEditInstructionSubmit={this.handleEditInstructionSubmit}
                                    handleRemoveInstruction={this.handleRemoveInstruction}
                                    handleRemoveInstructionSubmit={this.handleRemoveInstructionSubmit}
                                    handleCurrencyChange={this.handleCurrencyChange}
                                    handleSSIPrimaryChange={this.handleSSIPrimaryChange}
                                    handleOnChange={this.handleOnChange}
                                    handleOnToggle={this.handleOnToggle}
                                    handleBankNameChange={this.handleBankNameChange}
                                    handleBranchChange={this.handleBranchChange}

                                    application={application}
                                    instruction={instruction}
                                    disableAddButton={disableAddButton}

                                    groupedInstructions={groupedInstructions}
                                    intermediaryBanks={intermediaryBanks}
                                    beneficiaryBanks={beneficiaryBanks}
                                    beneficiaryBranches={beneficiaryBranches}

                                    errors={errors}
                                    currencies={currencies}
                                    INSTRUMENTS={INSTRUMENTS}
                                />

                                <hr style={{paddingBottom: 0, marginBottom: 0}}/>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
            </div>
        )
    }

}


function mapStateToProps({globalMarket, loading, impersonation}, ownProps) {

    const legalEntity = globalMarket.application && globalMarket.application.legalEntity;
    const application = globalMarket.application ? globalMarket.application : {};

    return {
        application: application,
        customer: application && application.customer,
        impersonation: impersonation && impersonation.impersonation,
        selectedEntity: legalEntity ? legalEntity : {},
        loading
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, previous}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(SettlementInstructionsPage);