import React from 'react';

const BusinessAccountInfo = () => (
    <div>
        <div>
            <div className="product-heading">
                Info: Business Current Account
            </div>
            <div className="title-gradient"/>
        </div>
        <div className="popup-content" >
            <div className="card-container-form" style={{maxWidth: "40em"}}>
                <p className="greyText">
                    Maximise transacting convenience while minimising time spend on administration.
                </p>
                <p>
                    A Business Current Account provides you with a secure means of paying
                    and collecting funds. It also acts as a gateway to tools and services
                    such as online banking services through our Business Online platform.
                    Depending on your credit rating, you'll have access to an overdraft
                    and a range of business lending solutions.
                </p>
                <div className="row">
                    <div className="" style={{padding: 10}}>
                        <p className="boldText">
                            A Business Current Account may suit your business if:
                        </p>
                        <ul className="greyText">
                            <li className="square">
                                You want access to a single point of contact through an account
                                executive.
                            </li>
                            <li className="square">
                                You want to build a banking relationship and credit risk profile
                                with Standard Bank.
                            </li>
                            <li className="square">
                                You need to make deposits, withdrawals and payments to third parties
                                using various channels; as a well as receive funds from your
                                customers.
                            </li>
                            <li className="square">
                                You need to keep accurate banking records.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
export default BusinessAccountInfo;