import React, {useEffect, useImperativeHandle, useState, useRef} from 'react';
import _ from 'lodash';
import {DataList} from 'primereact/components/datalist/DataList';
import {useDispatch, useSelector} from 'react-redux';
import ReactLoading from 'react-loading';
import {addSignatories, clearIndiviualInfo, fetchIndiviualInfo, removeSignatory} from '../../../actions/kyc';
import NewSignatory from './NewSignatory';
import CopySignatory from './CopySignatory';
import '../../../styles/kyc.css';
import '../../../styles/Global.css';
import NewSignatorySpecimenComponent from './NewSignatorySpecimenComponent';

import validate from 'validate.js';
import Popup from '../../Popup';
import FormField from '../../formField/FormField';
import {Glyphicon, HelpBlock, OverlayTrigger, Tooltip} from 'react-bootstrap';

const ACTIONS = {
    add: 'add',
    remove: 'remove',
    copy: 'copy',
    none: 'none',
    update: 'update'
};
const signatoryActionType = {
    ADD: 'ADD'
};

export const SignatoriesComponent = React.forwardRef((props, ref) => {

    const applicationId = useSelector(state => state.application && state.application.application.id);
    const individual = useSelector(state => state.individual && state.individual.individual);
    const appEntity = useSelector(state => state.application && state.application.application.legalEntity && state.application.application.legalEntity.typeOfEntity );
    const isEntityCorp = (appEntity === 'Corporate')
    const signatories = useSelector(state => 
     state.application.application.bankAccount.signatories ? state.application.application.bankAccount.signatories : []
    );
    
    const systemError = useSelector(state => state.systemError);

    const [action, setAction] = useState(ACTIONS.none);
    const [addNewRP, setAddNewRP] = useState(false);
    const [copy, setCopy] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [newRP, setNewRP] = useState({});
    const [toggleSpecimenDownloadUpload, setToggleSpecimenDownloadUpload] = useState(false);
    const dispatch = useDispatch();


    const [signatorySpecimens, setSignatorySpecimens] = useState([]);

    const newSignatorySpecimenRef = React.useRef(null);

      useImperativeHandle(ref, () => {
            return {
                validateSignatories: validateSignatories
            };
        });
        
        useEffect(() => {
                const signatoriesList = _.find(signatories, s => (s && s.signPlusAction === signatoryActionType.ADD));
                setToggleSpecimenDownloadUpload(!_.isEmpty(signatoriesList) && true);
            }, []
        );

        useEffect(() => {
                setNewRP(_.extend({}, individual, newRP));
            }, [individual]
        );

        useEffect(() => {
            const signatoriesList = _.filter(signatories, s => s.signPlusAction === signatoryActionType.ADD);
            setSignatorySpecimens(signatoriesList.map(signatory => signatory.idNumber));
            setToggleSpecimenDownloadUpload(!_.isEmpty(signatoriesList));
        }, [signatories]);
        



        const handleNewRPChange = ({target}, attribute, innerAttribute) => {
            let value = _.trimStart(target.value);
            if (target.type && target.type === 'number')
                value = parseInt(value, 10);
            if (innerAttribute) {
                value = _.extend({}, newRP[attribute], {[innerAttribute]: value});
            }
            setNewRP(_.extend({}, newRP, {[attribute]: value}));
        };

        const handleSelectChange = (event, attribute) => {
            setNewRP(_.extend({}, newRP, {[attribute]: event.value}));
        };

        const handleSelectAddress = (event, attribute) => {
            const updatedRP = _.extend({}, newRP,
            {address: _.extend({}, newRP.address , {street : event.value.street}, {suburb : event.value.suburb}, {province : event.value.province}, {postalCode : event.value.postalCode}, {city : event.value.city})});
            setNewRP(updatedRP);
            console.log(newRP);
        };

        const handleCopyClick = () => {
            setAddNewRP(false);
            setCopy(true);
            setAction(ACTIONS.add);
        };

        const handleAddNewRPClick = () => {
            setAddNewRP(true);
            setCopy(false);
            setAction(ACTIONS.add);
             setNewRP(_.extend({}, newRP, {signPlusAction: signatoryActionType.ADD}));

        };

        const removeSelectedSignatory = (selected) => {
            loadingStart();
            const onComplete = (success = false) => {
                loadingFinish();
                if (success) {
                    const filteredSignatories = _.filter(signatories, s => (s.idNumber !== selected.idNumber));
                    const isFound = _.find(filteredSignatories, signa => (signa && signa.signPlusAction === signatoryActionType.ADD));
                    setToggleSpecimenDownloadUpload(!!isFound);
                }
            };
            dispatch(removeSignatory(applicationId, selected, onComplete));
        };

        const resetError = (attribute) => {
            setErrors(_.extend({}, errors, {[attribute]: null}));
        };

        const validateSignatories = () => {

            const options = {fullMessages: false};
            const constraints = {signatories: {presence: true}};
            const errors = validate({signatories: _.size(signatories) > 0 ? signatories : null}, constraints, options);

            let isNewSignatorySpecimenValid = true;
            // if (toggleSpecimenDownloadUpload && !isEntityCorp) {
            if (toggleSpecimenDownloadUpload) {
                isNewSignatorySpecimenValid = newSignatorySpecimenRef.current && newSignatorySpecimenRef.current.validateDocuments();
            }

            setErrors(errors);
            return _.isEmpty(errors) && isNewSignatorySpecimenValid;
        };
        

        const validateForm = () => {
            const options = {fullMessages: false};
            const checkAddress = {...newRP};
            console.log(checkAddress.address);


            const constraints = {
                firstName: {presence: true, length: {minimum: 1, message: 'required'}},
                lastName: {presence: true, length: {minimum: 1, message: 'required'}},
                idNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                contactNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                emailAddress: {presence: true, email: true, length: {minimum: 1, message: 'required'}},
                instruction: {presence: true, length: {minimum: 1, message: 'required'}},
                signingLimits: { presence: true, numericality: { onlyInteger: true } } 
                // ...(isEntityCorp
                // ? { initial: { presence: true, length: { minimum: 1, message: 'required' } } }
                // : { signingLimits: { presence: true, numericality: { onlyInteger: true } } }
                // )

            };



      
            if (checkAddress.address && Object.keys(checkAddress.address).length === 0) {
                constraints.address = { presence: true, length: { minimum: 1, message: 'required' } };
            }

            let errors = validate(newRP, constraints, options);

            errors = _.extend({}, errors);

            setErrors(errors);
            return _.isEmpty(errors);
        };

        const onNewRPSubmit = () => {
            if (validateForm()) {
                loadingStart();
                const onComplete = () => {
                    loadingFinish();
                }
                dispatch(addSignatories(applicationId, [newRP], onComplete));
                dispatch(clearIndiviualInfo());


                // if (signatories.length > 0 && toggleSpecimenDownloadUpload) {
                //     newSignatorySpecimenRef.current && newSignatorySpecimenRef.current.resetFileUpload(); // Call resetFileUpload on child component
                // }
        

                _.defer(() => {
                    setAddNewRP(false);
                    setNewRP({});
                    setToggleSpecimenDownloadUpload(true);
                });
            }
        };


        const onIndividualSearch = (onComplete) => {
            loadingStart();
            const onFinish = () => {
                loadingFinish();
                onComplete && onComplete();
            };
            const onError = () => {
                loadingFinish();
                // this.setState({isLoading: false});
                setErrors({idNumber: ['Individual not found on search.']});
            };
            dispatch(fetchIndiviualInfo(newRP.idNumber, onFinish, null, onError));
        };

        const loadingStart = () => {
            setIsLoading(true);
        };

        const loadingFinish = () => {
            setIsLoading(false);
        };

        const onCopySubmit = () => {
            setCopy(false);
            setNewRP({});
        };

        const onHandleCancel = () => {
            dispatch(clearIndiviualInfo());
            _.defer(() => {
                setAddNewRP(false);
                setErrors({});
                setNewRP({});
            });
        };

        const onHandleCancelCopy = () => {
            setCopy(false);
        };

    const renderSignatory = (signatory) => {
        if (!signatory) return null;
        return (
            <div>
                <div className="flexRow" style={{padding: 0}}>
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12" style={{padding: 0}}>
                        <label>{`${signatory.firstName} ${signatory.lastName}`}</label>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12" style={{padding: 0}}>
                        <label>{signatory.instruction}</label>
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-12 col-xs-12" style={{padding: 0}}>
                        <a className="btn"
                            role="button"
                            id="remove-btn"
                            onClick={() => removeSelectedSignatory(signatory)}>
                            <i className="fa fa-times" aria-hidden="true"/>
                        </a>
                    </div>
                </div>
                <hr style={{margin:'5px 5px'}} />
            </div>);
    };

    const renderSignatories = () => {
        const hasSignatories = _.size(signatories) > 0;
        if (!hasSignatories) return null;
        return (
            <div>
                <div className="row" style={{margin:'0px 15px'}}>
                    <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12" style={{padding: 0}}>
                        <b>Name</b>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12" style={{padding: 0}}>
                        <b>Instruction</b>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" style={{padding: 0}}/>
                </div>
                <DataList
                    className="ui-datalist-nobullets"
                    itemTemplate={renderSignatory.bind(this)}
                    paginator={true}
                    rows={6}
                    value={signatories}
                />
            </div>
        );
    };

    const statementTooltip = (
        <Tooltip id="tooltip">
            Click here to copy signatory/ies from the other existing accounts.
        </Tooltip>
    );

    return (
            <div className="flexColumn">
                <div className="card-container-form" style={{minWidth: '22em'}}>
                    <div className="section-title">
                        Transactional Signatories
                        <br/>
                        <HelpBlock>
                            <small>
                                Signatories can sign checks and withdraw or deposit funds against the account.
                            </small>
                        </HelpBlock>
                    </div>

                    {renderSignatories()}

                    {
                        !addNewRP &&
                        <FormField
                            className="form-group"
                            error={errors && errors.signatories ? ['At least One Signatory Should Be Added'] : ''}>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                <a className="btn btn-secondary addButton"
                                   onClick={() => {
                                       errors && errors.signatories && resetError('signatories');
                                       handleAddNewRPClick();
                                   }}
                                >Add Signatory</a>
                                <a className="btn btn-secondary addButton"
                                   onClick={handleCopyClick}
                                >Copy signatory&nbsp;
                                    <OverlayTrigger placement="right" overlay={statementTooltip}>
                                        <Glyphicon glyph="question-sign"/>
                                    </OverlayTrigger>
                                </a>
                            </div>
                            {/* toggleSpecimenDownloadUpload && !isEntityCorp && */}
                            {
                                toggleSpecimenDownloadUpload && 
                                <NewSignatorySpecimenComponent
                                    signatories={signatories}
                                    ref={newSignatorySpecimenRef}
                                    selectedSignatoryId={newRP.idNumber}
                                />
                            }
                            {errors && errors.signatories && <br/>}
                        </FormField>
                    }
                {
                    addNewRP &&
                    <Popup onClose={onHandleCancel}>
                        <NewSignatory
                            cancel={onHandleCancel}
                            errors={errors}
                            handleNewRPChange={handleNewRPChange}
                            handleSelectChange={handleSelectChange}
                            handleSelectAddress={handleSelectAddress}
                            newRP={newRP}
                            onNewRPSubmit={onNewRPSubmit}
                            onIndividualSearch={onIndividualSearch}
                            onResetError={resetError}
                            appEntity = {appEntity}
                        />
                        {
                            isLoading &&
                            <div className="inner-spinner-container">
                                <ReactLoading type="spokes" color="#444"/>
                            </div>
                        }
                    </Popup>
                }

                {
                    copy &&
                    <Popup onClose={onHandleCancelCopy}>
                        <CopySignatory
                            onCopySubmit={onCopySubmit}
                            onLoadingFinish={loadingFinish}
                            onLoadingStart={loadingStart}
                        />
                    </Popup>

                }
                {
                    isLoading &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
            </div>
        </div>
    );
});


export default SignatoriesComponent;
