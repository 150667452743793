import React, {Component} from 'react';
import Tree from 'react-d3-tree';

export default class Organogram extends Component {

    clickNode = (e) => {
        const {clickEvent} = this.props;
        clickEvent && clickEvent(e);
    };

    render() {
        return (
            <Tree data={this.props.entity}
                  zoom={0.8}
                  translate={{x: 764, y: 120}}
                  scaleExtent={{min: 0.5, max: 2}}
                  separation={{siblings: 0.8, nonSiblings: 0.8}}
                  nodeSize={{x: 300, y: 140}}
                  pathFunc={"elbow"}
                  collapsible={false}
                  orientation={"vertical"}
                  onClick={this.clickNode}
                  textLayout={{textAnchor: 'middle', x: 0, y: 22}}
            />
        )
    }
}