import React from 'react';

const GlobalPaymentsTrackingInfo = () => (
    <div>
        <div>
            <div className="product-heading">
                Info: Global Payments Tracking
            </div>
            <div className="title-gradient"/>
        </div>
        <div className="popup-content">
            <div className="flexRow">
                <div className="card-container-form" style={{maxWidth: "40em"}}>
                    <p>
                        GPI is the new standard for cross-border payments which dramatically improves the customer
                        experience in cross-border payments by increasing their speed, transparency and end-to-end
                        tracking. By offering Payments via SWIFT GPI we see this as significantly increase customer
                        satisfaction by offering speed, certainty, transparency and a confirmation of credit for
                        cross-border payments.
                    </p>
                    <div className="row">
                        <div className="" style={{padding: 10}}>
                            <p className="boldText">
                                Assists our Clients with:
                            </p>
                            <ul className="greyText">
                                <li className="square">
                                    Better cash flow management.
                                </li>
                                <li className="square">
                                    Speed and visibility on critical payments.
                                </li>
                                <li className="square">
                                    Certainty for buyers and sellers.
                                </li>
                                <li className="square">
                                    Transparency on bank fees.
                                </li>
                                <li className="square">
                                    Reduced exception handling & investigations.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
export default GlobalPaymentsTrackingInfo;

