import React from 'react';
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";

import * as actions from "../actions/app";
import Popup from "./Popup";

class ErrorMessage extends React.Component {
    handleClose = () =>{
        const {actions} = this.props;
        actions.clearErrorMessage();
    };

    render() {
        const {errorMessage} = this.props;
        if(errorMessage){
            return (
                <Popup onClose={this.handleClose}>
                    <div>
                        <div>
                            <div className="product-heading">
                                Error
                            </div>
                            <div className="title-gradient"/>
                        </div>
                        <br/>
                        <div className="popup-content">
                            <div className="flexColumn">
                                <div>
                                    <p>
                                        {errorMessage}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>
            );
        }
        return null
    }
}

function mapStateToProps({error}) {
    return {errorMessage: error && error.errorMessage ?  error.errorMessage : null }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);