import React, {useState} from 'react';
import _ from 'lodash'
import {useDispatch} from 'react-redux';
import Select from 'react-select';
import FormField from '../formField/FormField';

const levelStyles = {
    any: 'any',
    combination: 'combination'
};
const levels = {
    levelA: 'A',
    levelB: 'B'
};

export const Rules = (props) => {

    const [selectedStyle, setSelectedStyle] = useState(levelStyles.any);
    const [blockA, setBlockA] = useState({count: '', level: 'A'});
    const [blockB, setBlockB] = useState({count: '', level: 'B',});

    const dispatch = useDispatch();

    const handleStyleSelect = ({value}) => {
        setSelectedStyle(value);
    }


    const handleAdd = (e) => {

        console.log(blockA)
        e.preventDefault();
        const isCombination = selectedStyle === levelStyles.combination;

        let levels = [];

        if (isCombination) {
            levels = [...[{
                level: blockA.level,
                count: blockA.count
            }], ...[{
                level: blockB.level,
                count: blockB.count
            }]];

        } else {
            levels = [...[{
                level: blockA.level,
                count: blockA.count
            }]];

        }
        setBlockA({count: '', level: 'A'});
        setBlockB({count: '', level: 'B'});
        dispatch(props.onAddRule({levels}));

    }

    const handleCountChange = ({target}, attribute) => {
        this.setState({[attribute]: Object.assign({}, this.state[attribute], {count: target.value})});
    }

    const renderRules = () => {
        const {onRemoveRule, rules} = props
        const describeRule = (rule, i) => {
            if (_.size(rule.levels) > 1) {
                return `${i}. A combination of: ${_.map(rule.levels, (l) => ` ${l.count} of ${l.level}`)}`
            }
            return `${i}. Any ${rule.levels[0] && rule.levels[0].count} of ${rule.levels[0] && rule.levels[0].level} authoriser(s)`
        };
        return (
            <div className="rules-container">
                {_.map(rules, (rule, i) => (
                    <div className="row rule-list-item" key={`rule-${i}`}>
                        <div className="col-md-11" id="item-content">
                            <p>{describeRule(rule, i + 1)}</p>
                        </div>
                        <div className="col-md-1" id="item-content">
                            <p><a className="btn " role="button" id="remove-btn"
                                  onClick={() => onRemoveRule(i)}> <i className="fa fa-times" aria-hidden="true"/></a>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    const {rules, error} = props
    const isCombination = selectedStyle === levelStyles.combination;
    const hasRules = _.size(rules) > 0;

    return (
        <FormField style={{width: '100%'}} error={error}>
            <div className="section-title">
                Authorisation Specification
            </div>
            <p>In order to activate an account the authorisation
                must be compliant in respect of the following:
            </p>
            <form onSubmit={handleAdd.bind(this)}>
                <div className="row">
                    <div className="col-md-3 col-sm-3 col-xs-3">
                        <Select
                            name="style"
                            value={selectedStyle}
                            options={[
                                {value: levelStyles.any, label: 'Any'},
                                {value: levelStyles.combination, label: 'Combination Of'},
                            ]}
                            onChange={(event) => handleStyleSelect(event)}
                        />
                    </div>
                    <div className="col-md-2 col-sm-2 col-xs-2">Number of:</div>
                    <div className="col-md-2 col-sm-2 col-xs-2">
                        <input className="form-control"
                               type="number"
                               value={blockA.count}
                               onChange={(e) => setBlockA(Object.assign({}, blockA, {count: e.target.value}))}
                               required={true}
                        />
                    </div>
                    <div className="col-md-2 col-sm-2 col-xs-2">Authoriser Level:</div>
                    <div className="col-md-2 col-sm-2 col-xs-2">
                        <Select
                            name="level"
                            value={blockA.level}
                            disabled={isCombination}
                            options={[
                                {value: levels.levelA, label: levels.levelA},
                                {value: levels.levelB, label: levels.levelB},
                            ]}
                            onChange={(e) => setBlockA(Object.assign({}, blockA, {level: e.value}))}
                            required={true}
                        />
                    </div>
                </div>
                {
                    isCombination &&
                    <div className="row">
                        <div className="col-md-3 col-sm-3 col-xs-3"/>
                        <div className="col-md-2 col-sm-2 col-xs-2">Number of:</div>
                        <div className="col-md-2 col-sm-2 col-xs-2">
                            <input className="form-control"
                                   type="number" min="1"
                                   value={blockB.count}
                                   onChange={(e) => setBlockB(Object.assign({}, blockB, {count: e.target.value}))}
                                   required={true}
                            />
                        </div>
                        <div className="col-md-2 col-sm-2 col-xs-2">Authoriser Level:</div>
                        <div className="col-md-2 col-sm-2 col-xs-2">
                            <Select
                                name="levelB"
                                value={blockB.level}
                                disabled
                                options={[
                                    {value: levels.levelB, label: levels.levelB},
                                ]}
                            />
                        </div>
                    </div>
                }
                <br/>
                <div className="flexRow" style={{justifyContent: 'space-between'}}>
                    <div/>
                    <button
                        className="action-btn-primary"
                        onClick={handleAdd.bind(this)}
                        type="button">Add
                    </button>
                </div>
            </form>

            <br/>
            <div className="section-title">
                Allowed sets of authorisation
            </div>
            {hasRules && renderRules()}
        </FormField>
    )
}

export default Rules;
