import React, {useEffect, useImperativeHandle, useState} from 'react';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import '../../styles/kyc.css';
import Popup from '../Popup';
import DocumentViewer from '../DocumentViewer';
import FormField from '../formField/FormField';
import validate from 'validate.js';
import {HelpBlock} from 'react-bootstrap';
import {updateDoc, uploadDoc, verifyDoc} from '../../actions/kyc';

const DOC_TYPES = {
    ownershipStructure: 'Ownership Structure',
    resolution: 'Resolution',
    cipcDocument: 'CIPC Document',
    financialStatements: 'Financial Statements'
};

export const EntityDocsComponent = React.forwardRef((props, ref) => {

    const [documentType, setDocumentType] = useState('');
    const entityDocs = props.entityDocs;
    const [showDocument, setShowDocument] = useState(false);
    const [errors, setErrors] = useState({});
    const [fileId, setFileId] = useState('');
    const [financials, setFinancials] = useState(true);
    const [accountStatements, setAccountStatements] = useState(false);
    const [cfoLetter, setCfoLetter] = useState(false);
    const [auditorLetter, setAuditorLetter] = useState(false);
    const dispatch = useDispatch();

    const findDoc = (entityDocs, documentType) => {
        const docFound = _.find(entityDocs, (doc) => (doc.documentType == documentType));
        return docFound ? docFound : {documentId: null};
    };

    const setDocDesc = () => {
        const finDoc = findDoc(entityDocs, DOC_TYPES.financialStatements);
        console.log(finDoc);
        switch (finDoc.description) {
            case 'cfoLetter':
                setFinancials(false);
                setCfoLetter(true);
                setAccountStatements(false);
                setAuditorLetter(false);
                break;
            case 'accountStatements':
                setFinancials(false);
                setAccountStatements(true);
                setCfoLetter(false);
                setAuditorLetter(false);
                break;
            case 'auditorLetter':
                setFinancials(false);
                setAuditorLetter(true);
                setCfoLetter(false);
                setAccountStatements(false);
                break;
            case 'financials':
            default:
                setFinancials(true);
                setAccountStatements(false);
                setCfoLetter(false);
                setAuditorLetter(false);
                break;
        }
    };

    useEffect(() => {
        // setEntityDocs(props.entityDocs);
        setDocDesc();
    }, [props]);

    useImperativeHandle(ref, () => {
        return {
            validateDocs: validateDocs
        };
    });



    const onHandleUpload = (base64, inProgress, onComplete, onSuccess) => {
        const handleOnSuccess = (documentId, data) => {
            onSuccess && onSuccess(documentId);
        };
        dispatch(uploadDoc(base64, handleOnSuccess, null, inProgress, onComplete));
    };

    const onHandleRemoveDocument = (documentType) => {
        const {onRemoveDocument} = props;
        onRemoveDocument && onRemoveDocument(documentType);
    };

    const handleResetError = (attribute) => {
        setErrors({errors: _.extend({}, errors, {[attribute]: null})});
    };

    const validateDocs = () => {

        const options = {fullMessages: false};

        const checkDocument = (documentType) => {
            const doc = findDoc(entityDocs, documentType);
            return doc && doc.verified ? doc : null;
        };

        const constraints = {
            [DOC_TYPES.resolution]: {presence: true},
            [DOC_TYPES.cipcDocument]: {presence: true},
            [DOC_TYPES.ownershipStructure]: {presence: true},
            [DOC_TYPES.financialStatements]: {presence: true},
        };
        const errors = validate({
            [DOC_TYPES.resolution]: checkDocument(DOC_TYPES.resolution),
            [DOC_TYPES.cipcDocument]: checkDocument(DOC_TYPES.cipcDocument),
            [DOC_TYPES.ownershipStructure]: checkDocument(DOC_TYPES.ownershipStructure),
            [DOC_TYPES.financialStatements]: checkDocument(DOC_TYPES.financialStatements),

        }, constraints, options);
        setErrors(errors);
        return _.isEmpty(errors);
    };

    const onHandleVerify = (documentId, documentType, onSuccess) => {
        const {onUploadComplete} = props;

        const onHandleSuccess = () => {

            onHandleCloseDocument();
            onSuccess && onSuccess();
            onUploadComplete && onUploadComplete(documentType, documentId);
        };
        if (props.applicationId) {
            dispatch(verifyDoc(entityDocs, documentType, props.applicationId, documentId, onHandleSuccess));
        } else {
            onHandleSuccess();
        }
    };

    const handleViewDocument = (event, fileId, documentType) => {
        setShowDocument(true);
        setDocumentType(documentType);
        setFileId(fileId);
    };

    const onHandleCloseDocument = () => {
        setShowDocument(false);
    };

    const renderDocument = () => {
        const {isUpload} = props;

        if (!showDocument) return null;
        return (
            <Popup onClose={onHandleCloseDocument}>
                <DocumentViewer
                    isUpload={isUpload}
                    fileId={fileId}
                    documentType={documentType}
                    onUpload={onHandleUpload}
                    onRemove={onHandleRemoveDocument}
                    onVerify={onHandleVerify}
                    onClose={onHandleCloseDocument}
                />
            </Popup>
        );
    };

    const shareholderDoc = findDoc(entityDocs, DOC_TYPES.ownershipStructure);
    const proofOfAuthDoc = findDoc(entityDocs, DOC_TYPES.resolution);
    const proofOfLegalDoc = findDoc(entityDocs, DOC_TYPES.cipcDocument);
    const financialDoc = findDoc(entityDocs, DOC_TYPES.financialStatements);

    return (
        <div>
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        Entity Documentation
                        <br/>
                        <HelpBlock>
                            <small>
                                Please review and verify that the documents are their latest copies.
                            </small>
                        </HelpBlock>
                    </div>
                    <hr style={{marginTop: 10, marginBottom: 10}}/>

                    <FormField
                        className="form-group"
                        error={errors && errors[DOC_TYPES.cipcDocument] ? ['Document Required'] : ''}
                    >
                        <div className="flexRow">
                            <div className="col-md-6 col-sm-4  col-xs-4" style={{padding: 0}}>
                                <label className=" blackText">Proof of Legal Existence </label>
                                <label
                                    className="form-group"
                                    id="proofOfLegalExistence"
                                />
                            </div>

                            <div className="col-md-3  col-sm-4  col-xs-4" style={{padding: 0}}>
                                <label
                                    className="form-group boldText"
                                    id="proofOfLegalExistenceStatus"
                                /> {proofOfLegalDoc.verified ? (props.isUpload ? 'Confirmed' : 'Verified') : (props.isUpload ? 'Unconfirmed' : 'Unverified')}
                            </div>

                            <div className="col-md-3  col-sm-4  col-xs-4" style={{padding: 0}}>
                                <div
                                    className="flat-button-primary"
                                    onClick={(event) => {
                                        errors && errors[DOC_TYPES.cipcDocument] && handleResetError(DOC_TYPES.cipcDocument);
                                        handleViewDocument(event, proofOfLegalDoc.documentId, DOC_TYPES.cipcDocument);
                                    }}
                                >{props.isUpload ? 'Upload' : 'Verify'} <i className="fa fa-angle-right fa-lg"/>
                                </div>
                            </div>
                        </div>
                    </FormField>
                    <hr style={{marginTop: 10, marginBottom: 10}}/>

                    <FormField
                        className="form-group"
                        error={errors && errors[DOC_TYPES.resolution] ? ['Document Required'] : ''}
                    >
                        <div className="flexRow">
                            <div className="col-md-6  col-sm-4 col-xs-4" style={{padding: 0}}>
                                <label className=" blackText">Proof of Authorised Individuals </label>
                                <label className="form-group" id="proofOfLegalExistence"/>
                            </div>

                            <div className="col-md-3  col-sm-4 col-xs-4" style={{padding: 0}}>
                                <label className="form-group" id="proofOfLegalExistenceStatus"
                                /> {proofOfAuthDoc.verified ? (props.isUpload ? 'Confirmed' : 'Verified') : (props.isUpload ? 'Unconfirmed' : 'Unverified')}
                            </div>

                            <div className="col-md-3 col-sm-4  col-xs-4" style={{padding: 0}}>
                                <div
                                    className="flat-button-primary"
                                    onClick={(event) => {
                                        errors && errors[DOC_TYPES.resolution] && handleResetError(DOC_TYPES.resolution);
                                        handleViewDocument(event, proofOfAuthDoc.documentId, DOC_TYPES.resolution);
                                    }}
                                >{props.isUpload ? 'Upload' : 'Verify'} <i className="fa fa-angle-right fa-lg"/>
                                </div>
                            </div>
                        </div>
                    </FormField>

                    <hr style={{marginTop: 10, marginBottom: 10}}/>
                    <FormField
                        className="form-group"
                        error={errors && errors[DOC_TYPES.ownershipStructure] ? ['Document Required'] : ''}
                    >
                        <div className="flexRow">
                            <div className="col-md-6 col-sm-4 col-xs-4" style={{padding: 0}}>
                                <label className=" blackText">Shareholder Structure</label>
                                <label
                                    className="form-group"
                                    id="proofOfLegalExistence"
                                />
                            </div>
                            <div className="col-md-3  col-sm-4 col-xs-4" style={{padding: 0}}>
                                <label
                                    className="form-group"
                                    id="proofOfLegalExistenceStatus"
                                /> {shareholderDoc.verified ? (props.isUpload ? 'Confirmed' : 'Verified') : (props.isUpload ? 'Unconfirmed' : 'Unverified')}
                            </div>
                            <div className="col-md-3 col-sm-4  col-xs-4 " style={{padding: 0}}>
                                <div
                                    className="flat-button-primary"
                                    onClick={(event) => {
                                        errors && errors[DOC_TYPES.ownershipStructure] && handleResetError(DOC_TYPES.ownershipStructure);
                                        handleViewDocument(event, shareholderDoc.documentId, DOC_TYPES.ownershipStructure);
                                    }}
                                >{props.isUpload ? 'Upload' : 'Verify'} <i className="fa fa-angle-right fa-lg"/>
                                </div>
                            </div>
                        </div>
                    </FormField>
                    <hr style={{marginTop: 10, marginBottom: 10}}/>
                    <FormField
                        className="form-group"
                        error={errors && errors[DOC_TYPES.financialStatements] ? ['Document Required'] : ''}
                    >
                        <div className="flexRow">
                            <div className="col-md-6 col-sm-4 col-xs-4" style={{padding: 0}}>
                                <label className=" blackText">Proof of Financial Status</label>
                                <label
                                    className="form-group"
                                    id="financialStatements"
                                />
                            </div>
                            <div className="col-md-3  col-sm-4 col-xs-4" style={{padding: 0}}>
                                <label
                                    className="form-group"
                                    id="financialStatementsStatus"
                                /> {financialDoc.verified ? (props.isUpload ? 'Confirmed' : 'Verified') : (props.isUpload ? 'Unconfirmed' : 'Unverified')}
                            </div>
                            <div className="col-md-3 col-sm-4  col-xs-4 " style={{padding: 0}}>
                                <div
                                    className="flat-button-primary"
                                    onClick={(event) => {
                                        errors && errors[DOC_TYPES.financialStatements] && handleResetError(DOC_TYPES.financialStatements);
                                        handleViewDocument(event, financialDoc.documentId, DOC_TYPES.financialStatements);
                                    }}
                                >{props.isUpload ? 'Upload' : 'Verify'} <i className="fa fa-angle-right fa-lg"/>
                                </div>
                            </div>
                        </div>
                        {/*<div>*/}
                        {/*    <i className={'fa fa-square-o'}/> &nbsp;*/}
                        {/*    I would prefer not to upload Financials here.*/}
                        {/*</div>*/}
                        {financialDoc.documentId != null &&
                        <div>
                            <h5>
                                Please indicate the type of document uploaded as Proof of Financial Status.
                            </h5>

                            <div
                                className="inline-container"
                                onClick={() => props.handleDocDesc(financialDoc, 'financials')}
                            >
                                <i className={financials === true ? 'fa fa-check-circle-o icon' : 'fa fa-circle-o icon'}/> &nbsp;
                                Financial Statements.
                            </div>
                            <div
                                className="inline-container"
                                onClick={() => props.handleDocDesc(financialDoc, 'auditorLetter')}
                            >
                                <i className={auditorLetter === true ? 'fa fa-check-circle-o icon' : 'fa fa-circle-o icon'}/> &nbsp;
                                Letter from the Auditor confirming the Net Asset value.
                            </div>
                            <div
                                className="inline-container"
                                onClick={() => props.handleDocDesc(financialDoc, 'accountStatements')}
                            >
                                <i className={accountStatements === true ? 'fa fa-check-circle-o icon' : 'fa fa-circle-o icon'}/> &nbsp;
                                Management Account Statements.
                            </div>
                            <div
                                className="inline-container"
                                onClick={() => props.handleDocDesc(financialDoc, 'cfoLetter')}
                            >
                                <i className={cfoLetter === true ? 'fa fa-check-circle-o icon' : 'fa fa-circle-o icon'}/> &nbsp;
                                A letter from the CFO confirming the value of Net Assets.
                            </div>
                        </div>
                        }
                    </FormField>
                </div>
            </div>
            {renderDocument()}
        </div>
    );
});
export default EntityDocsComponent;


