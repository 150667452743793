export const GENDER = [
    {value: 'MALE', label: "MALE"},
    {value: 'FEMALE', label: "FEMALE"}
]


export const MARKET_TYPE = [
    {value: 'individual', label: "Individual"},
    {value: 'company', label: "Private and Public Company"},
    {value: 'government', label: "Government Entity"},
    {value: 'fund', label: "Retirement Fund"},
    {value: 'trust', label: "Inter vivos Trusts"},
    {value: 'unIncorpTrust', label: "Un-Incorporated Body of Trust"}];

export const TITLE = [
    {value: 'ADMIN', label: "ADMIN"},
    {value: 'ADML', label: "ADML"},
    {value: 'ADV', label: "ADV"},
    {value: 'AJ', label: "AJ"},
    {value: 'BARON', label: "BARON"},
    {value: 'BARONESS', label: "BARONESS"},
    {value: 'BRIG', label: "BRIG"},
    {value: 'BWYLE', label: "BWYLE"},
    {value: 'CAPT', label: "CAPT"},
    {value: 'CHIEF', label: "CHIEF"},
    {value: 'COL', label: "COL"},
    {value: 'COMM', label: "COMM"},
    {value: 'COUNT', label: "COUNT"},
    {value: 'COUNTESS', label: "COUNTESS"},
    {value: 'DAME', label: "DAME"},
    {value: 'DR', label: "DR"},
    {value: 'DS', label: "DS"},
    {value: 'DUCHESS', label: "DUCHESS"},
    {value: 'DUKE', label: "DUKE"},
    {value: 'EKS', label: "EKS"},
    {value: 'EST LATE', label: "EST LATE"},
    {value: 'ESTATE OF', label: "ESTATE OF"},
    {value: 'EX', label: "EX"},
    {value: 'FATHER', label: "FATHER"},
    {value: 'GEN', label: "GEN"},
    {value: 'GENMAJ', label: "GENMAJ"},
    {value: 'HON', label: "HON"},
    {value: 'HRH', label: "HRH"},
    {value: 'INSP', label: "INSP"},
    {value: 'JUDGE', label: "JUDGE"},
    {value: 'JUSTICE', label: "JUSTICE"},
    {value: 'KAPT', label: "KAPT"},
    {value: 'KOL', label: "KOL"},
    {value: 'KMDT', label: "KMDT"},
    {value: 'KONST', label: "KONST"},
    {value: 'LADY', label: "LADY"},
    {value: 'LT', label: "LT"},
    {value: 'MADAM', label: "MADAM"},
    {value: 'MAJ', label: "MAJ"},
    {value: 'MASTER', label: "MASTER"},
    {value: 'ME', label: "ME"},
    {value: 'MEJ', label: "MEJ"},
    {value: 'MESSRS', label: "MESSRS"},
    {value: 'MEV', label: "MEV"},
    {value: 'MRS', label: "MRS"},
    {value: 'MISS', label: "MISS"},
    {value: 'MNR', label: "MNR"},
    {value: 'MR', label: "MR"},
    {value: 'MS', label: "MS"},
    {value: 'PASTOR', label: "PASTOR"},
    {value: 'PRINCE', label: "PRINCE"},
    {value: 'PRINCESS', label: "PRINCESS"},
    {value: 'PROF', label: "PROF"},
    {value: 'RABBI', label: "RABBI"},
    {value: 'REV', label: "REV"},
    {value: 'SIR', label: "SIR"},
    {value: 'SISTER', label: "SISTER"},
    {value: 'THE ESTATE', label: "THE ESTATE"}]


export const KYC_OPTIONS = [
    {name: "individual", label: "0001 - RSA Citizens", value: "27"},
    {name: "individual", label: "0002 - RSA Permanent Residents", value: "28"},
    {name: "individual", label: "0003 - Emigrants", value: "29"},
    {name: "individual", label: "0004 - Foreign Citizens", value: "30"},
    {name: "company", label: "0005 - RSA Close Corporations", value: "31"},
    {name: "company", label: "0006 - RSA Companies", value: "32"},
    {name: "company", label: "0007 - Foreign Companies", value: "33"},
    {name: "government", label: "0008 - Other Legal Persons", value: "34"},
    {name: "fund", label: "0008 - Other Legal Persons", value: "34"},
    {name: "trust", label: "0009 - Trusts", value: "35"},
    {name: "unIncorpTrust", label: "0008 - Other Legal Persons", value: "34"},
]

export const CENTRAL_BANK_OPTIONS = [

    {
        marketType: "individual",
        kycType: "27",
        label: "1300 - Individuals and Unincorporated Business Enterprises",
        value: "84"
    },
    {
        marketType: "individual",
        kycType: "28",
        label: "1300 - Individuals and Unincorporated Business Enterprises",
        value: "84"
    },
    {marketType: "individual", kycType: "29", label: "0100 - Non-residents and companies", value: "65"},
    {marketType: "individual", kycType: "30", label: "0100 - Non-residents and companies", value: "65"},

    {marketType: "company", kycType: "31", label: "0510 - Long Term Insurers", value: "69"},
    {
        marketType: "company",
        kycType: "31",
        label: "0520 - Short Term Insurers (including Medical Schemes)",
        value: "70"
    },
    {
        marketType: "company",
        kycType: "31",
        label: "0730 - Financial Public Enterprises and Financial Auxiliaries",
        value: "75"
    },
    {
        marketType: "company",
        kycType: "31",
        label: "0740 - Other Finance Companies and Sundry Financial Institutions",
        value: "76"
    },
    {
        marketType: "company",
        kycType: "31",
        label: "1120 - Non-financial Public Enterprises (Corporations)",
        value: "81"
    },
    {
        marketType: "company",
        kycType: "31",
        label: "1130 - Sundry Public Sector( Agricultural control boards, Univs)",
        value: "82"
    },

    {
        marketType: "company",
        kycType: "32",
        label: "0200 - S African Reserve Bank and Corporation for Public Deposits",
        value: "66"
    },
    {marketType: "company", kycType: "32", label: "0300 - Banks, Mutual Banks, Landbank and Postbank", value: "67"},
    {marketType: "company", kycType: "32", label: "0510 - Long Term Insurers", value: "69"},
    {
        marketType: "company",
        kycType: "32",
        label: "0520 - Short Term Insurers (including Medical Schemes)",
        value: "70"
    },
    {
        marketType: "company",
        kycType: "32",
        label: "0730 - Financial Public Enterprises and Financial Auxiliaries",
        value: "75"
    },
    {
        marketType: "company",
        kycType: "32",
        label: "0740 - Other Finance Companies and Sundry Financial Institutions",
        value: "76"
    },
    {
        marketType: "company",
        kycType: "32",
        label: "1120 - Non-financial Public Enterprises (Corporations)",
        value: "81"
    },
    {
        marketType: "company",
        kycType: "32",
        label: "1130 - Sundry Public Sector( Agricultural control boards, Univs)",
        value: "82"
    },
    {
        marketType: "company",
        kycType: "32",
        label: "1400 - Nominee Companies Rgstrd in Terms of Companies Act",
        value: "85"
    },

    {marketType: "company", kycType: "33", label: "0100 - Non-residents and companies", value: "65"},
    {marketType: "company", kycType: "33", label: "0300 - Banks, Mutual Banks, Landbank and Postbank", value: "67"},
    {marketType: "company", kycType: "33", label: "0510 - Long Term Insurers", value: "69"},
    {
        marketType: "company",
        kycType: "33",
        label: "0520 - Short Term Insurers (including Medical Schemes)",
        value: "70"
    },
    {
        marketType: "company",
        kycType: "33",
        label: "0730 - Financial Public Enterprises and Financial Auxiliaries",
        value: "75"
    },
    {
        marketType: "company",
        kycType: "33",
        label: "0740 - Other Finance Companies and Sundry Financial Institutions",
        value: "76"
    },
    {
        marketType: "company",
        kycType: "33",
        label: "1120 - Non-financial Public Enterprises (Corporations)",
        value: "81"
    },
    {
        marketType: "company",
        kycType: "33",
        label: "1130 - Sundry Public Sector( Agricultural control boards, Univs)",
        value: "82"
    },
    {
        marketType: "company",
        kycType: "33",
        label: "1400 - Nominee Companies Rgstrd in Terms of Companies Act",
        value: "85"
    },

    {marketType: "fund", kycType: "34", label: "0400 - Public Investment Corporation (PIC)", value: "68"},
    {
        marketType: "fund",
        kycType: "34",
        label: "0610 - Private Pension and Provident Funds (Self-administered)",
        value: "71"
    },
    {
        marketType: "fund",
        kycType: "34",
        label: "0620 - Official Pension and Provident Funds (Transnet, Telkom)",
        value: "72"
    },
    {
        marketType: "fund",
        kycType: "34",
        label: "0800 - Central Government including Social Security Funds",
        value: "77"
    },
    {marketType: "fund", kycType: "34", label: "1110 - Government Enterprises", value: "80"},


    {
        marketType: "government",
        kycType: "34",
        label: "0200 - S African Reserve Bank and Corporation for Public Deposits",
        value: "66"
    },
    {
        marketType: "government",
        kycType: "34",
        label: "0610 - Private Pension and Provident Funds (Self-administered)",
        value: "71"
    },
    {
        marketType: "government",
        kycType: "34",
        label: "0620 - Official Pension and Provident Funds (Transnet, Telkom)",
        value: "72"
    },
    {
        marketType: "government",
        kycType: "34",
        label: "0730 - Financial Public Enterprises and Financial Auxiliaries",
        value: "75"
    },
    {
        marketType: "government",
        kycType: "34",
        label: "0740 - Other Finance Companies and Sundry Financial Institutions",
        value: "76"
    },
    {marketType: "government", kycType: "34", label: "0900 - Provincial Governance", value: "78"},
    {marketType: "government", kycType: "34", label: "1000 - Local Authorities", value: "79"},
    {marketType: "government", kycType: "34", label: "1110 - Government Enterprises", value: "80"},
    {
        marketType: "government",
        kycType: "34",
        label: "1120 - Non-financial Public Enterprises (Corporations)",
        value: "82"
    },
    {
        marketType: "government",
        kycType: "34",
        label: "1200 - Non-profit Institutions, Co-operative Societies, Close Corps",
        value: "83"
    },

    {
        marketType: "unIncorpTrust",
        kycType: "34",
        label: "1200 - Non-profit Institutions, Co-operative Societies, Close Corps",
        value: "83"
    },
    {marketType: "unIncorpTrust", kycType: "34", label: "0710 - Unit Trusts", value: "73"},
    {
        marketType: "unIncorpTrust",
        kycType: "34",
        label: "0730 - Financial Public Enterprises and Financial Auxiliaries",
        value: "75"
    },
    {
        marketType: "unIncorpTrust",
        kycType: "34",
        label: "0740 - Other Finance Companies and Sundry Financial Institutions",
        value: "76"
    },
    {
        marketType: "unIncorpTrust",
        kycType: "34",
        label: "1120 - Non-financial Public Enterprises (Corporations)",
        value: "81"
    },
    {
        marketType: "unIncorpTrust",
        kycType: "34",
        label: "1130 - Sundry Public Sector( Agricultural control boards, Univs)",
        value: "82"
    },
    {
        marketType: "unIncorpTrust",
        kycType: "34",
        label: "1200 - Non-profit Institutions, Co-operative Societies, Close Corps",
        value: "83"
    },

    {
        marketType: "trust",
        kycType: "35",
        label: "0740 - Other Finance Companies and Sundry Financial Institutions",
        value: "76"
    },
    {
        marketType: "trust",
        kycType: "35",
        label: "1300 - Individuals and Unincorporated Business Enterprises",
        value: "84"
    },

]

export const INST_SECTOR__OPTIONS = [
    {marketType: "individual", label: "04-HOUSEHOLD", value: "194"},
    {marketType: "individual", label: "02-NON FINANCIAL CORPORATE", value: "192"},
    {marketType: "company", label: "01-FINANCIAL CORPORATE", value: "191"},
    {marketType: "government", label: "03-GENERAL GOVERNMENT", value: "193"},
    {marketType: "government", label: "02-NON FINANCIAL CORPORATE", value: "192"},
    {marketType: "fund", label: "03-GENERAL GOVERNMENT", value: "193"},
    {marketType: "fund", label: "02-NON FINANCIAL CORPORATE", value: "192"},
    {marketType: "trust", label: "01-FINANCIAL CORPORATE", value: "191"},
    {marketType: "trust", label: "04-HOUSEHOLD", value: "194"},
    {marketType: "unIncorpTrust", label: "01-FINANCIAL CORPORATE", value: "191"},
    {marketType: "unIncorpTrust", label: "02-NON FINANCIAL CORPORATE", value: "192"},
    {marketType: "unIncorpTrust", label: "03-GENERAL GOVERNMENT", value: "193"},
]


export const WEEK_DAYS = [
    {value: 'MONDAY', label: "MON"},
    {value: 'TUESDAY', label: "TUES"},
    {value: 'WEDNESDAY', label: "WED"},
    {value: 'THURSDAY', label: "THURS"},
    {value: 'FRIDAY', label: "FRIDAY"},
];

export const MONTH_DAYS = [
    {value: '1',label: '1'},
    {value: '2',label: '2'},
    {value: '3',label: '3'},
    {value: '4',label: '4'},
    {value: '5',label: '5'},
    {value: '6',label: '6'},
    {value: '7',label: '7'},
    {value: '8',label: '8'},
    {value: '9',label: '9'},
    {value: '10',label: '10'},
    {value: '11',label: '11'},
    {value: '12',label: '12'},
    {value: '13',label: '13'},
    {value: '14',label: '14'},
    {value: '15',label: '15'},
    {value: '16',label: '16'},
    {value: '17',label: '17'},
    {value: '18',label: '18'},
    {value: '19',label: '19'},
    {value: '20',label: '20'},
    {value: '21',label: '21'},
    {value: '22',label: '22'},
    {value: '23',label: '23'},
    {value: '24',label: '24'},
    {value: '25',label: '25'},
    {value: '26',label: '26'},
    {value: '27',label: '27'},
    {value: '28',label: '28'},
    {value: '29',label: '29'},
    {value: '30',label: '30'},
    {value: '31',label: '31'}

];





