import React from 'react';

const announcement = (props) => {
    const {selectedAnnouncement} = props;
    return(
            <div>
                <div className="row" style={{margin:'0 0 15px 0'}}>
                    <div className="product-heading" style={{fontSize:'18px'}}> <b>New Features :</b></div>
                    <div className="title-gradient"></div>
                </div>
                <div className="row" style={{margin:0, overflowY: 'scroll' , maxHeight: '70vh'}}>
                    { 
                        props.announcements.map((announcement) => (
                            <div key={announcement.id} className={`row ${ announcement.available === true ? 'announcement-enable' : 'announcement-disable'}`} style={{margin:'0'}} >
                                <dl>
                                    <dt style={{margin:'0 0 10px 0'}}> 
                                        <span className='' style={{fontSize:'20px', color:'#0033aa'}}> <span className="new-badge">New</span> </span> 
                                        <a onClick={()=>selectedAnnouncement(announcement.announcementRoute)} style={{fontSize:'15px', marginLeft:'5px', cursor:'pointer'}}>
                                            {announcement.name}
                                        </a>
                                        {/* {announcement.dateLaunched.split(' ')[0]} */}
                                        <span className="blink_me" style={{fontSize:'13px', color:'#000', fontWeight:'800', margin:'10px', fontWeight: '100', float:'right'}}>  
                                            { new Intl.DateTimeFormat('en-GB', { month: 'short', day: '2-digit', year: 'numeric'}).format(new Date(announcement.dateLaunched))}
                                        </span>
                                    </dt>
                                    {/* <dd>{announcement.description}</dd> */}
                                    <dd style={{fontSize:'13px'}} dangerouslySetInnerHTML={{ __html: announcement.description }}></dd>
                                </dl>
                                <hr />
                            </div>
                        ))
                    } 
                </div>
            </div>
        )
    };

export default announcement;

