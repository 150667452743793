import React from 'react';
import _ from "lodash";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";

import EntityList from '../EntityList'
import {loadingPageDataFinish} from "../../../actions/appLoading";
import * as actions from "../../../actions/entity";
import {ListSearchComponent} from "../../SearchComponent";
import NoLinkedEntitiesMessage from '../../legalentity/NoLinkedEntitiesMessage'
import FormField from "../../formField/FormField";
import validate from "validate.js";

const ONBOARDING_ERRORS = {
    startOnboarding: "startOnboarding",
    continueOnboarding: "continueOnboarding"
};

class OnBoardingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedEntity: null,
            selectedEntityRequest: null,
            isLoading: true,
            isFetchingEntities: true,
            isFetchingRequest: true,
            filteredRequests: null,
            errors: {}
        };
        this.onContinue = this._onContinue.bind(this);
        this.onSelectEntity = this._onSelectEntity.bind(this);
        this.onSelectEntityRequest = this._onSelectEntityRequest.bind(this);
        this.onStart = this._onStart.bind(this);
        this.onFilterRequests = this._onFilterRequests.bind(this);
    }

    componentWillMount() {
        this._fetchData();
    }

    _onFilterRequests(searchTerm) {
        const {newEntitiesRequests} = this.props;
        let filteredRequests = null;
        if (searchTerm) {
            filteredRequests = _.filter(newEntitiesRequests, req => (
                req && (req.description && req.description.toLowerCase().includes(searchTerm.toLowerCase()))
            ))
        }
        this.setState({filteredRequests, searchTerm})
    }

    _fetchData() {
        const {actions, impersonation} = this.props;

        const onComplete = (attr, dispatch) => {
            const cb = () => {
                const {isFetchingEntities, isFetchingRequest} = this.state;
                if (!isFetchingEntities && !isFetchingRequest) {
                    this.setState({isLoading: false});
                    loadingPageDataFinish(dispatch);
                }
            };
            this.setState({[attr]: false}, cb)
        };

        actions.fetchEntityOnboardingRequests(onComplete);
        actions.fetchHierachy(impersonation, onComplete);
    }

    _onSelectEntity(selectedEntity) {
        this.setState({selectedEntity});
    }

    _onSelectEntityRequest(selectedEntityRequest) {
        this.setState({selectedEntityRequest});
    }


    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    validateSelected = (attribute) => {
        const {selectedEntity, selectedEntityRequest} = this.state;
        const options = {fullMessages: false};

        let errors = {};
        if (attribute === ONBOARDING_ERRORS.continueOnboarding) {
            errors = _.extend({}, errors, validate({selectedEntityRequest}, {selectedEntityRequest: {presence: true}}, options))
        } else if (attribute === ONBOARDING_ERRORS.startOnboarding) {
            errors = _.extend({}, errors, validate({selectedEntity}, {selectedEntity: {presence: true}}, options))
        }

        this.setState({errors});
        return _.isEmpty(errors);
    };

    _onContinue() {
        if (this.validateSelected(ONBOARDING_ERRORS.continueOnboarding)) {
            const {actions, history} = this.props;
            const {selectedEntityRequest} = this.state;
            const onSuccess = (path) => history.push(`/onboard/${path ? path : 'reginfo'}`);
            actions.fetchSelectedOnboardRequest(selectedEntityRequest.id, onSuccess);
        }
    }

    _onStart() {
        if (this.validateSelected(ONBOARDING_ERRORS.startOnboarding)) {
            const {actions, history} = this.props;
            const {selectedEntity} = this.state;
            const legalEntity = {
                parentEntityName: (selectedEntity.tradingAs || selectedEntity.registeredName),
                parentEntityCif: selectedEntity.cif
            };
            actions.onChangeValue('newEntityDetails', 'legalEntity', legalEntity);
            history.push('/onboard/lookup')
        }
    }

    _renderEntitiesSection() {
        const {entities} = this.props;
        const {selectedEntity, errors} = this.state;

        return (
            <div style={{width: "100%"}}>
                <div>
                    <div className="product-heading">
                        Onboard New Entity
                    </div>
                    <div className="title-gradient"/>
                </div>

                <FormField
                    // className="card-container"
                    error={errors && errors.selectedEntity && ["One Should Be Selected"]}
                >
                    <br/>
                    <b>Select Parent Entity To Start</b>
                    <EntityList
                        entities={entities}
                        onSelect={(selectedEntity) => {
                            errors && errors.selectedEntity && this.handleResetError("selectedEntity")
                            this.onSelectEntity(selectedEntity)
                        }}
                        selectedEntity={selectedEntity}
                    />
                </FormField>
            </div>
        )
    }

    _renderNewEntitesRequestsSection() {
        const {newEntitiesRequests} = this.props;

        const hasNoRequests = _.size(newEntitiesRequests) === 0;
        const {selectedEntityRequest, filteredRequests, errors} = this.state;
        const items = filteredRequests || newEntitiesRequests;

        return (
            <div style={{width: "100%", marginLeft: 20}}>
                <div>
                    <div className="product-heading">
                        Continue Onboarding Entity
                    </div>
                    <div className="title-gradient"/>
                </div>
                <br/>
                <b>Select Application To Continue</b>

                <FormField
                    error={errors && errors.selectedEntityRequest && ["One Should Be Selected"]}
                >
                    {
                        hasNoRequests
                        ?
                        <div>
                            <br/>
                            <span>You Have No Applications!</span>
                        </div>
                        :
                        <div>
                            <div className="card-header">
                                <div/>
                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <ListSearchComponent
                                        onFilter={this.onFilterRequests}
                                        placeholder="Find entity..."
                                    />
                                    {false && <i className="fa fa-info-circle" style={{marginLeft: 10, fontSize: 16}}/>}
                                </div>
                            </div>
                            <fieldset className="selection-list" name="requests"
                                      style={{height: "100%", maxHeight: '70vh'}}>
                                {
                                    _.map(items, req => {
                                        const isSelected = (selectedEntityRequest && selectedEntityRequest.id) == req.id;
                                        return (
                                            <div
                                                className={isSelected ? "selection-list-item-selected" : "selection-list-item"}
                                                onClick={() => {
                                                    errors && errors.selectedEntityRequest && this.handleResetError("selectedEntityRequest");
                                                    this.onSelectEntityRequest(req)
                                                }}
                                                key={`request-${req.id}`}
                                            >
                                                {
                                                    isSelected ?
                                                        <i
                                                            className="fa fa-check-circle"
                                                            style={{
                                                                color: "rgb(0, 51, 170)",
                                                                fontSize: 20,
                                                                marginRight: 10
                                                            }}
                                                        />
                                                        :
                                                        <i className="fa fa-circle-o"
                                                           style={{
                                                               color: "rgb(0, 51, 170)",
                                                               fontSize: 20,
                                                               marginRight: 10
                                                           }}
                                                        />
                                                }
                                                <div>
                                                    <span className={"active-entity"}> {req.description}</span>
                                                    {req && req.createdAt &&
                                                    <span className={"greyText"}> | {req.createdAt} </span>}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </fieldset>
                        </div>
                    }
                </FormField>
            </div>
        )
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <div className="flexRow" style={{justifyContent: 'flex-end', flex: 1}}>
                    <button
                        className="action-btn-primary"
                        onClick={this.onStart}
                        type="button"
                    >Start
                    </button>
                </div>
                {/*<div className="flexRow" style={{justifyContent: "flex-end", flex: 1}}>*/}
                {/*    <button*/}
                {/*        className="action-btn-primary pull-right"*/}
                {/*        onClick={this.onContinue}*/}
                {/*        type="button"*/}
                {/*    >Continue*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
        )
    }


    render() {
        const {entities, loading, systemError} = this.props;
        const {isLoading} = this.state;
        if (isLoading || loading || systemError.show) return null;
        const hasEntities = _.size(entities) > 0;

        if (!hasEntities) return (
            <div className="page-container">
                <div className="page-main-section">
                    <NoLinkedEntitiesMessage/>
                </div>
            </div>
        );
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="flexRow" style={{width: "100%", alignItems: "flex-start"}}>
                        {this._renderEntitiesSection()}
                        {/*{this._renderNewEntitesRequestsSection()}*/}
                    </div>
                </div>
                <div className="page-footer-section" style={{width: "80%"}}>
                    {this._renderFooter()}
                </div>
            </div>
        )
    }
}

function mapStateToProps({entity, impersonation, loading, systemError}) {
    return {
        entities: entity.entities ? entity.entities : [],
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        newEntitiesRequests: entity.newEntitiesRequests ? entity.newEntitiesRequests : [],
        loading,
        systemError
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardingPage);
