import * as types from '../actions/actionTypes';
import initialState from './initialState';
import _ from 'lodash';

export default function kycReducer(state = initialState.kyc, action) {
    switch(action.type){
        case types.ENTITY_SELECTED:
            return {...state, application: action.application};
        case types.SEARCH_ENTITY_SUCCESS:
            return {...state, entityInfo: action.entityInfo};
        case types.FECTH_ENTITY_SUCCESS:
            return {...state, entityData: action.entityData};
        case types.FETCH_AUTH_RULES_SUCCESS:
            return {...state, authRules: action.authRules};
        case types.FETCH_AUTHORISERS:
            return {...state, authorisedPersons: action.authorisedPersons};
        case types.KY_START:
            return {...state, ...action.kycMaintenance, saved: false};
        case types.KY_CONTINUE:
            return {...state, ...action.kycMaintenance, saved: false};
        case types.KY_DIR_CHANGE: {
            // const update = _.extend(action.attribute && action.value);
            let {kycRequest} = state;
            kycRequest.directors[action.attribute] = action.value;
            return {...state, kycRequest};
        }
        case types.KY_ADDRESS_CHANGE: {
            const update = _.extend(action.attribute && action.value);
            const {kycRequest, kycRequest: {addresses}} = state;
            addresses.push(update);
            kycRequest.addresses = addresses;
            return {
                ...state,
                kycRequest
            };
        }
        case types.KY_ADD_OWNER: {
            const {update} = action;
            const {kycRequest} = state;
            kycRequest.children = update.children;
            return {
                ...state,
                kycRequest
            };
        }
        case types.KY_ENTITY:
            return {...state, legalEntity: action.length};
        case types.KY_CHANGE: {
            const {kycRequest} = state;
            kycRequest[action.attribute] = action.value;
            return {...state, kycRequest};
        }
        case types.KY_DOC: {
            let {kycRequest} = state;
            const {document} = action;
            kycRequest.documents.push(document);
            return {...state, kycRequest};
        }
        case types.KY_RELATED: {
            let {kycRequest} = state;
            const {relatedParty} = action;
            kycRequest.children.push(relatedParty);
            return {...state, kycRequest};
        }
        case types.KY_AUTHORISED_PERSON: {
            let {kycRequest} = state;
            const {authorisedPerson} = action;
            kycRequest.directors.push(authorisedPerson);
            return {...state, kycRequest};
        }
        case types.KY_RM_RELATED: {
            let {kycRequest} = state;
            const {relatedParty} = action;
            kycRequest.children.splice(relatedParty, 1);
            return {...state, kycRequest};
        }
        case types.KY_RM_AUTHORISED_PERS: {
            let {kycRequest} = state;
            const {authorisedPerson} = action;
            kycRequest.directors.splice(authorisedPerson, 1);
            return {...state, kycRequest};
        }
        case types.KY_PIP: {
            let {kycRequest} = state;
            kycRequest.pip = action.update;
            return {...state, kycRequest};
        }
        case types.KY_UPDATE_PIPS: {
            let {kycRequest} = state;
            kycRequest.pips = action.update;
            return {...state, kycRequest};
        }
        case types.KY_UPDATE_CONTROLLER: {
            let {kycRequest} = state;
            kycRequest.controllers = action.update;
            return {...state, kycRequest};
        }
        case types.KY_SAVED:
            return {saved: true};
        default:
            return state;
    }
}