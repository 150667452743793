import React from 'react';
import _ from 'lodash';

export class ListSearchComponent extends React.Component {
    state = {
        searchText: '',
        showSearchBtn: true,
    };

    onToggleIcon = () => {
        const searchInput = this.searchInput
        this.setState(prevState => ({showSearchBtn: !prevState.showSearchBtn}), () => {
            searchInput && searchInput.focus();
        })
    };

    onChange = ({target}, clearText = false) => {
        const {onFilter} = this.props;
        const searchText = clearText ? '' : target.value;
        this.setState({searchText});
        onFilter && onFilter(searchText);
    };

    onClearSearch = () => {
        const {onFilter} = this.props;
        const searchText = '';
        this.setState(prevState => ({searchText, showSearchBtn: !prevState.showSearchBtn}));
        onFilter && onFilter(searchText);
    };

    render() {
        const {className, placeholder} = this.props;
        const {searchText, showSearchBtn} = this.state;
        return (
            <div className={className ? className : ''}>
                {
                    showSearchBtn ?
                        <a
                            className="flat-button-primary"
                            onClick={this.onToggleIcon}
                            role="button"
                        >
                            <i className="fa fa-search"/>
                        </a>
                        :
                        <div className="search-container">
                            <input
                                ref={ref => this.searchInput = ref}
                                className="form-control"
                                onChange={this.onChange}
                                placeholder={placeholder ? placeholder : ''}
                                style={{width: 'auto'}}
                                type="text"
                                value={searchText}
                            />
                            <div className="input-group-append">
                                <span>
                                <a
                                    className="flat-button-primary"
                                    onClick={this.onClearSearch}
                                    role="button"
                                >
                                    <i className="fa fa-times"/>
                                </a>
                                </span>
                            </div>
                        </div>
                }
            </div>
        )
    }
}

const SearchComponent = (props) => {
    const {id, label, onChange, onSearch, searchValue} = props;
    return (
        <div className="flexColumn" style={{alignItems: "flex-start", width: "100%"}}>
            {label && <label>{label}</label>}
            <div className="input-group" style={{width: "100%"}}>
                <input
                    type="text"
                    className="form-control"
                    style={{width: "100%"}}
                    id={id}
                    maxLength={label === 'ID Number' ? '13' : undefined}
                    placeholder={"Search By " + label}
                    value={searchValue}
                    onChange={onChange}
                />
                <span className="input-group-btn">
                     <button
                         //disabled={_.size(searchValue) === 0}
                         className="btn btn-primary" style={{color: "white", fontWeight: "700"}}
                         type="button"
                         onClick={onSearch}
                     >Search</button>
                </span>
            </div>
        </div>
    )
};
export default SearchComponent;