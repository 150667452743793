import React, {useEffect, useState} from "react";
import 'react-select/dist/react-select.css';
import '../../../styles/bancs.css';
import {managedFundRoutes} from '../../../routers/routes';
import validate from 'validate.js';
import ButtonsComponent from "../../ButtonsComponent";
import {useDispatch, useSelector} from 'react-redux';
import FormField from "../../formField/FormField";
import Select from "react-select";
import _ from "lodash";
import {saveCustodyManagedFund} from "../../../actions/custodyManagedFund";


const CASH_ACC_TYPES = [
    {value: 'ALL', label: "Single Settlement Account Purpose (Securities Trading, Charges, Corporate Actions)"},
    {value: 'MULTI', label: "Multi Settlement Account Purpose (CA Sub purpose All)"}
]


let account = [];


const invoicePaymentMethodArray = [
    'Auto Debit',
    'Client Instruction',
    'Payment Term'
]


export const AccountCashPurpose = (props) => {

    const custodyRequest = useSelector(state => state.custodyManagedFund && state.custodyManagedFund.custodyManagedFundRequest);
    const appId = useSelector(state => state.custodyManagedFund && state.custodyManagedFund.id);
    const [accountType, setAccountType] = useState(custodyRequest.cashAccountPurpose.accountType);
    const [allAccountPurpose, setAllAccountPurpose] = useState(custodyRequest.cashAccountPurpose.allAccountPurpose);
    const [chargeAccPurpose, setChargeAccPurpose] = useState(custodyRequest.cashAccountPurpose.chargeAccPurpose);
    const [secTradingAccPurpose, setSecTradingAccPurpose] = useState(custodyRequest.cashAccountPurpose.secTradingAccPurpose);
    const [caAccountPurpose, setCaAccountPurpose] = useState(custodyRequest.cashAccountPurpose.caAccountPurpose);

    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();

    const handleToggle = (differentCif) => {
        this.setState({differentCif});
    };



    const resetError = (attribute) => {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    };

    const validateForm = () => {
        const options = {fullMessages: false};
        custodyRequest.cashAccountPurpose.accountType = accountType;
        console.log("validating");

        if (accountType === '' || accountType == null) {
            const accountTypeConstraints = {
                accountType: {presence: true, length: {message: 'required'}},
             };
             const accountTypeErrors = validate(accountType,accountTypeConstraints, options);
             const errors = _.extend({}, {accountTypeErrors});
             setErrors(errors);
             return _.isEmpty(accountTypeErrors);
        }


        if (accountType === 'ALL') {
            const allAccountPurposeconstraints = {
               accountNumber: {presence: true, length: {minimum: 1, message: 'required'}},
               accountName: {presence: true, length: {minimum: 1, message: 'required'}},
               branchCode: {presence: true, length: {minimum: 1, message: 'required'}},
            };
            const allAccountPurposeErrors = validate(allAccountPurpose,allAccountPurposeconstraints, options);
            const errors = _.extend({}, {allAccountPurposeErrors});
            setErrors(errors);
            return _.isEmpty(allAccountPurposeErrors);
        }
        if(accountType === "MULTI"){
            const chargeAccPurposeconstraints = {
                accountNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                accountName: {presence: true, length: {minimum: 1, message: 'required'}},
                branchCode: {presence: true, length: {minimum: 1, message: 'required'}},
            };

            const secTradingAccPurposeconstraints= {
                accountNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                accountName: {presence: true, length: {minimum: 1, message: 'required'}},
                branchCode: {presence: true, length: {minimum: 1, message: 'required'}},
            };

            const caAccountPurposeconstraints= {
                accountNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                accountName: {presence: true, length: {minimum: 1, message: 'required'}},
                branchCode: {presence: true, length: {minimum: 1, message: 'required'}},
            };

            const chargeAccPurposeErrors = validate(chargeAccPurpose,chargeAccPurposeconstraints, options);
            const secTradingAccPurposeErrors = validate(secTradingAccPurpose,secTradingAccPurposeconstraints, options);
            const caAccountPurposeErrors = validate(caAccountPurpose,caAccountPurposeconstraints, options);
            const errors = _.extend({}, {chargeAccPurposeErrors, secTradingAccPurposeErrors, caAccountPurposeErrors});
            setErrors(errors);
            return _.isEmpty(chargeAccPurposeErrors, secTradingAccPurposeErrors, caAccountPurposeErrors);
        }
    };

    const saveDetails = (event) => {
        event.preventDefault();

        if (validateForm()) {
            custodyRequest.cashAccountPurpose.accountType = accountType;

            if (accountType === 'ALL') {
                custodyRequest.cashAccountPurpose.allAccountPurpose = allAccountPurpose;
            } else {
                custodyRequest.cashAccountPurpose.caAccountPurpose = caAccountPurpose;
                custodyRequest.cashAccountPurpose.secTradingAccPurpose = secTradingAccPurpose;
                custodyRequest.cashAccountPurpose.chargeAccPurpose = chargeAccPurpose;
            }
            const onSuccess = () => props.history.push(managedFundRoutes.custodyInstructingParty);
            dispatch(saveCustodyManagedFund(appId, custodyRequest, 'UserTask_CashAccount', onSuccess));
        }
    };

    const saveAndContinue = (event) => {
        event.preventDefault();
        const {history} = props;
        
        custodyRequest.cashAccountPurpose.accountType = accountType;
        if (accountType === 'ALL') {
            custodyRequest.cashAccountPurpose.allAccountPurpose = allAccountPurpose;
        } else {
            custodyRequest.cashAccountPurpose.caAccountPurpose = caAccountPurpose;
            custodyRequest.cashAccountPurpose.secTradingAccPurpose = secTradingAccPurpose;
            custodyRequest.cashAccountPurpose.chargeAccPurpose = chargeAccPurpose;
        }
        const onSuccess = () => history.push('/saveapplication');
        dispatch(saveCustodyManagedFund(appId, custodyRequest, 'saveContinue', onSuccess));

    };

    const renderAccountSection = () => {
        console.log(accountType);
        if (accountType === "ALL") {
            return renderAllPurposeAccount();
        } else if ( accountType === "MULTI") {
            return renderMultiPurposeAccount();
        }
    };

    const renderAllPurposeAccount = () => {

        console.log(errors);

        return (
            <div className="row">
                <div className="col-md-4">
                    <FormField className="form-group" id="settlementPurpose">
                        <label htmlFor="clientType">Settlement Purpose</label>
                        <input
                            className="form-control"
                            id="currency"
                            type="text"
                            value="ALL" style={{fontWeight:"bolder"}}
                            disabled={true}
                        />
                    </FormField>
                    <FormField className="form-group" id="currency">
                        <label htmlFor="levelAt">Currency</label>
                        <input
                            className="form-control"
                            type="text" style={{fontWeight:"bolder"}}
                            value={allAccountPurpose.currency}
                            onChange={(event) => {
                                handleValueChange(event, 'allAccountPurpose', 'currency');
                            }}
                            disabled={true}
                        />
                    </FormField>
                    <FormField className="form-group" id="accountName" error={errors && errors.allAccountPurposeErrors && errors.allAccountPurposeErrors.accountName }>
                        <label htmlFor="accountName">Account Name</label>
                        <input
                            className="form-control"
                            id="accountName"
                             maxLength={35}
                            onChange={(event) => {
                                setAllAccountPurpose({
                                    ...allAccountPurpose,
                                    accountName: event.target.value
                                });

                            }}
                            type="text"
                            value={allAccountPurpose.accountName ? allAccountPurpose.accountName : ""}

                        />
                    </FormField>
                    <FormField className="form-group" id="accountNumber" error={errors && errors.allAccountPurposeErrors &&  errors.allAccountPurposeErrors.accountNumber}>
                        <label htmlFor="accountNumber">Account Number </label>
                        <input
                            className="form-control"
                            id="accountNumber"
                            onChange={(event) => {
                                setAllAccountPurpose({
                                    ...allAccountPurpose,
                                    accountNumber: event.target.value
                                });

                            }}
                            type="number"
                            value={allAccountPurpose.accountNumber ? allAccountPurpose.accountNumber : ""}

                        />
                    </FormField>
                    <FormField className="form-group" id="branchCode" error={errors && errors.allAccountPurposeErrors &&  errors.allAccountPurposeErrors.branchCode}>
                        <label htmlFor="branchCode">Branch Code </label>
                        <input
                            className="form-control"
                            id="branchCode"
                            maxLength={6}
                            onChange={(event) => {
                                setAllAccountPurpose({
                                    ...allAccountPurpose,
                                    branchCode: event.target.value
                                });

                            }}
                            type="text"
                            value={allAccountPurpose.branchCode ? allAccountPurpose.branchCode : ""}
                        />
                    </FormField>
                </div>
            </div>
        );

    };


    const renderMultiPurposeAccount = () => {
        return (
            <div className="row">

                <div className="col-md-4">
                    <FormField className="form-group" error={errors && errors.chargeAccPurposeErrors && errors.chargeAccPurposeErrors.settlementPurpose }>
                        <label htmlFor="clientType">Settlement Purpose</label>
                        <input
                            className="form-control"
                            type="text" style={{fontWeight:"bolder"}}
                            value={chargeAccPurpose.settlementPurpose}
                            disabled={true}
                        />
                    </FormField>
                    <FormField className="form-group" id="currency" error={errors && errors.chargeAccPurposeErrors && errors.chargeAccPurposeErrors.currency }>
                        <label htmlFor="levelAt">Currency</label>
                        <input
                            className="form-control"
                            id="currency"
                            type="text" style={{fontWeight:"bolder"}}
                            value={chargeAccPurpose.currency}
                            onChange={(event) => {
                                handleValueChange(event, 'chargeAccPurpose', 'currency')
                            }}
                            disabled={true}
                        />
                    </FormField>
                    <FormField className="form-group" id="accountName" error={errors && errors.chargeAccPurposeErrors && errors.chargeAccPurposeErrors.accountName }>
                        <label htmlFor="accountName">Account Name</label>
                        <input
                            className="form-control"
                            id="accountName"
                            maxLength={35}
                            onChange={(event) => {
                                setChargeAccPurpose({
                                    ...chargeAccPurpose,
                                    accountName: event.target.value
                                });

                            }}
                            type="text"
                            value={chargeAccPurpose && chargeAccPurpose.accountName}

                        />
                    </FormField>
                    <FormField className="form-group" id="accountNumber" error={errors && errors.chargeAccPurposeErrors && errors.chargeAccPurposeErrors.accountNumber }>
                        <label htmlFor="accountNumber">Account Number </label>
                        <input
                            className="form-control"
                            id="accountNumber"
                            onChange={(event) => {
                                setChargeAccPurpose({
                                    ...chargeAccPurpose,
                                    accountNumber: event.target.value
                                });

                            }}
                            type="number"
                            value={chargeAccPurpose && chargeAccPurpose.accountNumber}
                        />
                    </FormField>
                    <FormField className="form-group" id="branchCode" error={errors && errors.chargeAccPurposeErrors && errors.chargeAccPurposeErrors.branchCode }>
                        <label htmlFor="branchCode">Branch Code </label>
                        <input
                            className="form-control"
                            id="branchCode"
                            maxLength={6}
                            onChange={(event) => {
                                setChargeAccPurpose({
                                    ...chargeAccPurpose,
                                    branchCode: event.target.value
                                });

                            }}
                            type="text"
                            value={chargeAccPurpose && chargeAccPurpose.branchCode}
                        />
                    </FormField>
                </div>

                <div className="col-md-4">
                    <FormField className="form-group" error={errors && errors.secTradingAccPurposeErrors && errors.secTradingAccPurposeErrors.settlementPurpose }>
                        <label htmlFor="clientType">Settlement Purpose</label>
                        <input
                            className="form-control"
                            type="text" style={{fontWeight:"bolder"}}
                            value={secTradingAccPurpose.settlementPurpose}
                            disabled={true}
                        />
                    </FormField>
                    <FormField className="form-group" id="currency" error={errors && errors.secTradingAccPurposeErrors && errors.secTradingAccPurposeErrors.currency }>
                        <label htmlFor="levelAt">Currency</label>
                        <input
                            className="form-control"
                            id="currency"
                            type="text" style={{fontWeight:"bolder"}}
                            value={secTradingAccPurpose.currency}
                            disabled={true}
                            onChange={(event) => {
                                handleValueChange(event, 'secTradingAccPurpose', 'currency')
                            }}
                        />
                    </FormField>
                    <FormField className="form-group" id="accountName" error={errors && errors.secTradingAccPurposeErrors && errors.secTradingAccPurposeErrors.accountName }>
                        <label htmlFor="accountName">Account Name</label>
                        <input
                            className="form-control"
                            id="accountName"
                            maxLength={35}
                            onChange={(event) => {
                                setSecTradingAccPurpose({
                                    ...secTradingAccPurpose,
                                    accountName: event.target.value
                                });

                            }}
                            type="text"
                            value={secTradingAccPurpose && secTradingAccPurpose.accountName}
                        />
                    </FormField>
                    <FormField className="form-group" id="accountNumber" error={errors && errors.secTradingAccPurposeErrors && errors.secTradingAccPurposeErrors.accountNumber }>
                        <label htmlFor="accountNumber">Account Number </label>
                        <input
                            className="form-control"
                            id="accountNumber"
                            onChange={(event) => {
                                setSecTradingAccPurpose({
                                    ...secTradingAccPurpose,
                                    accountNumber: event.target.value
                                });

                            }}
                            type="number"
                            value={secTradingAccPurpose && secTradingAccPurpose.accountNumber}
                        />
                    </FormField>
                    <FormField className="form-group" id="branchCode" error={errors && errors.secTradingAccPurposeErrors && errors.secTradingAccPurposeErrors.branchCode }>
                        <label htmlFor="branchCode">Branch Code </label>
                        <input
                            className="form-control"
                            id="branchCode"
                            maxLength={6}
                            onChange={(event) => {
                                setSecTradingAccPurpose({
                                    ...secTradingAccPurpose,
                                    branchCode: event.target.value
                                });

                            }}
                            type="text"
                            value={secTradingAccPurpose && secTradingAccPurpose.branchCode}
                        />
                    </FormField>
                </div>

                <div className="col-md-4">
                    <FormField className="form-group" error={errors && errors.caAccountPurposeErrors && errors.caAccountPurposeErrors.settlementPurpose }>
                        <label htmlFor="clientType">Settlement Purpose</label>
                        <input
                            className="form-control"
                            type="text" style={{fontWeight:"bolder"}}
                            value={caAccountPurpose.settlementPurpose}
                            disabled={true}
                        />
                    </FormField>
                    <FormField className="form-group" id="currency" error={errors && errors.caAccountPurposeErrors && errors.caAccountPurposeErrors.currency }>
                        <label htmlFor="levelAt">Currency</label>
                        <input
                            className="form-control"
                            type="text" style={{fontWeight:"bolder"}}
                            value={caAccountPurpose.currency}
                            onChange={(event) => {
                                handleValueChange(event, 'caAccountPurpose', 'currency')
                            }}
                            disabled={true}
                        />
                    </FormField>
                    <FormField className="form-group" id="accountName" error={errors && errors.caAccountPurposeErrors && errors.caAccountPurposeErrors.accountName }>
                        <label htmlFor="accountName">Account Name</label>
                        <input
                            className="form-control"
                            id="accountName"
                            maxLength={35}
                            onChange={(event) => {
                                setCaAccountPurpose({
                                    ...caAccountPurpose,
                                    accountName: event.target.value
                                });

                            }}
                            type="text"
                            value={caAccountPurpose && caAccountPurpose.accountName}
                        />
                    </FormField>
                    <FormField className="form-group" id="accountNumber" error={errors && errors.caAccountPurposeErrors && errors.caAccountPurposeErrors.accountNumber }>
                        <label htmlFor="accountNumber" >Account Number </label>
                        <input
                            className="form-control"
                            id="accountNumber"
                            onChange={(event) => {
                                setCaAccountPurpose({
                                    ...caAccountPurpose,
                                    accountNumber: event.target.value
                                });

                            }}
                            type="number"
                            value={caAccountPurpose && caAccountPurpose.accountNumber}
                        />
                    </FormField>
                    <FormField className="form-group" id="branchCode" error={errors && errors.caAccountPurposeErrors && errors.caAccountPurposeErrors.branchCode }>
                        <label htmlFor="branchCode">Branch Code </label>
                        <input
                            className="form-control"
                            id="branchCode"
                             maxLength={6}
                            onChange={(event) => {
                                setCaAccountPurpose({
                                    ...caAccountPurpose,
                                    branchCode: event.target.value
                                });

                            }}
                            type="text"
                            value={caAccountPurpose && caAccountPurpose.branchCode}
                        />
                    </FormField>
                </div>
            </div>
        );

    }


    return (


        <div className="page-container">
            <div className="page-main-section">
                <div className="card-container-form">
                    <div className="section-title">
                        <span>Cash Account Purpose</span>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <FormField className="form-group" id="accountType" error={errors && errors.accountTypeErrors && errors.accountTypeErrors.accountType}>
                                <label htmlFor="accountType">Account Type</label>
                                <Select name="accountType"
                                    onChange={(event) => {
                                        setAccountType(event.value);
                                    }}
                                    options={CASH_ACC_TYPES}
                                    value={accountType}
                                />
                            </FormField>


                        </div>
                    </div>

                    {renderAccountSection()}


                </div>


            </div>
            <div className="page-footer-section" style={{width: '80%'}}>
                <ButtonsComponent
                    history={props.history}
                    onNext={saveDetails}
                    onSaveAndContinue={saveAndContinue}
                    prevPage={managedFundRoutes.custodyContactDetails}
                />
            </div>

        </div>
    );

};

export default (AccountCashPurpose);
