import React from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {DataList} from 'primereact/components/datalist/DataList';
import validate from 'validate.js'

import "../../styles/ConfirmApplication.css";
import "../../styles/Global.css";
import * as actions from '../../actions/custody';
import FormField from "./../formField/FormField";
import {newCustodyAccountRoutes} from "../../routers/routes";

class CustodyConfirmPage extends React.Component {
    state = {
        form: {emailAddress: ''}, errors: {}
    };

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    validateForm = () => {
        const {form} = this.state;
        const options = {fullMessages: false};
        const constraints = {emailAddress: {presence: true, email: true, length: {minimum: 1, message: 'required'}}};
        const errors = validate(form, constraints, options);
        this.setState({errors});
        return _.isEmpty(errors);
    };

    saveDetails = (event) => {
        event.preventDefault();
        const {form} = this.state;
        if (this.validateForm()) {
            const {history} = this.props;
            const onProceed = () => history && history.push(newCustodyAccountRoutes.complete);
            this.props.actions.saveCustody(this.props.appId, this.props.newCustodyProfile, 'UserTask_Notification', onProceed,form.emailAddress);
        }
    };

    handleChange = (e) => {
        const {form} = this.state;
        const emailAddress = _.trimStart(e.target.value);
        this.setState({form: _.extend({}, form, {emailAddress})});
    };

    renderList(sc) {
        if (!sc) return null;
        return (
            <div className="row">
                <div className="col-md-12">
                    <label>{sc.approvalMessage}</label>
                </div>
                <hr/>
            </div>
        );
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={this.saveDetails}
                    type="button"
                >Complete
                </button>
            </div>
        )
    }

    render() {
        const {authorisers} = this.props;
        const {errors, form} = this.state;
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="flexColumn">
                        <div className="card-container-form">
                            <div className="section-title">The following approval requests will be sent</div>
                            <DataList
                                itemTemplate={this.renderList.bind(this)}
                                value={authorisers}
                            />
                            <FormField id="kycEmail" error={errors && errors.emailAddress}>
                                <label>Please Enter Email Address where Confirmation Letter with be sent</label>
                                <input
                                    className="form-control"
                                    id="emailAddress"
                                    onChange={(event) => {
                                        errors && errors.emailAddress && this._resetError('emailAddress');
                                        this.handleChange(event)
                                    }}
                                    type="text"
                                    value={form && form.emailAddress ? form.emailAddress : ''}
                                />
                            </FormField>
                            <FormField id="comments" error={errors && errors.comments}>
                                <label>Comments</label>
                                <textarea className="form-control" rows={3} maxLength={200} id="comments"></textarea>
                            </FormField>
                        </div>
                    </div>
                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
            </div>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

function mapStateToProps({custody,authorisers}) {

    return {
        newCustodyProfile: custody.custodyRequest,
        appId: custody && custody.id,
        authorisers: authorisers && authorisers.authorisers ? authorisers.authorisers : []
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustodyConfirmPage);


