import axios from 'axios';
import * as types from './actionTypes';
import {API_ROOT} from "../api-config";
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from "./appLoading";

const PRODUCTS_URL = API_ROOT + '/api/products';
const APPLICATIONS_URL = API_ROOT + '/api/applications/';

export function loadProducts(cb) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(PRODUCTS_URL).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.LOAD_PRODUCTS_SUCCESS, products: responseData.data});
            } else {
                console.log("product.loadProducts.errorMessage ===>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Couldn't retrieve products."});
            }
            loadingPageDataFinish(dispatch);
            cb && cb()
        } catch (error) {
            console.log("product.loadProducts.error ===>", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function addProduct(selectedProduct, onProceed = null) {
    return (dispatch) => {
        dispatch({type: types.PRODUCT_SELECTED, application: {"product": {"id": selectedProduct}}, errorMessage: null});
        onProceed && onProceed();
    };
}

export function confirmAccount(account, additionalProducts, applicationId, saveAndContinue, onProceed) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');

            await axios.put(APPLICATIONS_URL + applicationId + "/additional", additionalProducts).then(response => response.data);

            let url = saveAndContinue ? APPLICATIONS_URL + applicationId + "/account?saveAndContinue=true" : APPLICATIONS_URL + applicationId + "/account";

            const responseData = await axios.put(url, account).then(response => response.data);

            loadingPageDataFinish(dispatch);
            if (responseData.success) {
                dispatch({type: types.ACCOUNT_CONFIRM_SUCCESS, application: responseData.data});
                onProceed && onProceed();
            } else {
                console.log("product.confirmAccount.errorMessage ==>", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: "Couldn't confirm product details."});
            }
        } catch (error) {
            console.log("product.confirmAccount.error ===>", error)
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, false, status);
        }
    };
}

export function viewMenuOption(history, path) {
    return (dispatch) => dispatch({type: types.VIEW_MENU_OPTION, history, path});
}
