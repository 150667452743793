import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function productReducer(state = initialState.products, action) {
    switch (action.type) {
        case types.LOAD_PRODUCTS_SUCCESS:
            return {
                ...state, products: action.products
            };
        case types.SELECTED_PRODUCT:
            action.history.push('/entityhierachy');
            return {
                ...state, selectedProduct: action.selectedProduct
            };
        default:
            return state;
    }
}