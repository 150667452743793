import React, {Component} from 'react';
import {
    Panel,
    Button,
    Popover,
    Badge,
    Table
} from "react-bootstrap";


class CustomerSubscriptionsComponent extends Component {

    render() {
        const {customer} = this.props;
        const wellStyles = {maxWidth: 400, margin: '0 auto 10px'};
        return (
            <div className="col-md-12" style={{marginTop: '3em'}}>
                <div className="col-md-12">
                    <div className="col-md-3">
                        <div className="col-md-12 form-group">
                            <div className="col-md-5">
                                <label htmlFor="osdId">Customer No</label>
                            </div>
                            <div className="col-md-7">
                                <div>
                                    <input className="form-control" id="osdId" type="text" name="osdId"
                                        defaultValue={customer && customer.id} readOnly/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 form-group">
                            <div className="col-md-5">
                                <label htmlFor="capturedBy">Captured By</label>
                            </div>
                            <div className="col-md-7">
                                <div>
                                    <input className="form-control" id="capturedBy" type="text" name="capturedBy"
                                        defaultValue={customer && customer.capturedBy} readOnly/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="col-md-12 form-group">
                            <div className="col-md-5">
                                <label htmlFor="name">Customer Name</label>
                            </div>
                            <div className="col-md-7">
                                <div>
                                    <input className="form-control" id="name" type="text" name="name"
                                        defaultValue={customer && customer.customerShortName} readOnly/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 form-group">
                            <div className="col-md-5">
                                <label htmlFor="capturedDate">Captured Date</label>
                            </div>
                            <div className="col-md-7">
                                <div>
                                    <input className="form-control" id="capturedDate" type="text" name="capturedDate"
                                        defaultValue={customer && customer.capturedDate} readOnly/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="col-md-12 form-group">
                            <div className="col-md-5">
                                <label htmlFor="segment">Segment</label>
                            </div>
                            <div className="col-md-7">
                                <div>
                                    <input className="form-control" id="segment" type="text" name="segment"
                                        defaultValue={customer && customer.segment} readOnly/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 form-group">
                            <div className="col-md-5">
                                <label htmlFor="authorisedBy">Authorised By</label>
                            </div>
                            <div className="col-md-7">
                                <div>
                                    <input className="form-control" id="authorisedBy" type="text" name="authorisedBy"
                                        defaultValue={customer && customer.authorisingUser} readOnly/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="col-md-12 form-group">
                            <div className="col-md-5">
                                <label htmlFor="status">Status</label>
                            </div>
                            <div className="col-md-7">
                                <div>
                                    <input className="form-control" id="status" type="text" name="status"
                                        defaultValue={customer && customer.status} readOnly/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 form-group">
                            <div className="col-md-5">
                                <label htmlFor="authorisedDate">Authorised Date</label>
                            </div>
                            <div className="col-md-7">
                                <div>
                                    <input className="form-control" id="authorisedDate" type="text" name="authorisedDate"
                                        defaultValue={customer && customer.authorisingDate} readOnly/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12" >
                    <hr/>
                    <table class="table table-striped">
                        <thead style={{backgroundColor: "#0033aa", color:"white", width: "100%", display:"relative"}}>
                            <tr >
                                <th>System No</th>
                                <th>System Code</th>
                                <th>Subscription Number</th>
                                <th>Status</th>
                                <th>Authorised By</th>
                                <th>Authorised Date</th>
                            </tr>
                        </thead>
                        <tbody style={{maxHeight: '40vh', overflowY: 'scroll', width: "100%"}}>
                        {
                            customer && customer.accounts &&
                            customer.accounts.map( ({id, systemCode, sourceSystemSubscriptionId, existsOnSourceSystem, authorisingUser, authorisingDate}, index) => {
                                return (
                                    <tr key={index} >
                                        <td>{id}</td>
                                        <td>{systemCode}</td>
                                        <td>{sourceSystemSubscriptionId}</td>
                                        <td>{existsOnSourceSystem ? "Active" : "Not Active"}</td>
                                        <td>{authorisingUser}</td>
                                        <td>{authorisingDate}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default (CustomerSubscriptionsComponent);