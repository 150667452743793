import React from "react";
import {bindActionCreators} from 'redux';
import 'react-select/dist/react-select.css';
import '../../styles/bancs.css';
import * as actions from '../../actions/custody';
import {newCustodyAccountRoutes} from '../../routers/routes'
import validate from 'validate.js'
import ButtonsComponent from "../ButtonsComponent";
import {connect} from 'react-redux';
import FormField from "../formField/FormField";
import {fetchAccounts} from "../../actions/kyc";
import Select from "react-select";
import _ from "lodash";
import ReactLoading from "react-loading";
import SearchComponent, {ListSearchComponent} from "../SearchComponent";
import Toggler from "../Toggler";


const CASH_ACC_TYPES = [
    {value: 'ALL', label: "Single Settlement Account Purpose (Securities Trading, Charges, Corporate Actions)"},
    {value: 'MULTI', label: "Multi Settlement Account Purpose (CA Sub purpose All)"}
]


let account = [];


const invoicePaymentMethodArray = [
    'Auto Debit',
    'Client Instruction',
    'Payment Term'
]

class AccountCashPurpose extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allAccountPurpose: {currency: 'ZAR', settlementPurpose: 'ALL', settlementType: 'BOTH', subPurpose: 'ALL'},
            chargeAccPurpose: {
                currency: 'ZAR',
                settlementPurpose: 'Charges',
                settlementType: 'PAYMENT',
                subPurpose: 'ALL'
            },
            secTradingAccPurpose: {
                currency: 'ZAR',
                settlementPurpose: 'Securities Trading', settlementType: 'BOTH', subPurpose: 'ALL'
            },
            caAccountPurpose: {
                currency: 'ZAR',
                settlementPurpose: 'Corporate Actions',
                settlementType: 'BOTH',
                subPurpose: 'ALL'
            },
            errors: {},
            filteredAccounts: null,
            cif: null,
            differentCif: false
        };
        this.handleOnChange = this.handleSelectChange.bind(this);
    }

    componentWillMount() {
        const {action, newCustodyProfile} = this.props;

        this.setState({isFetchingAccounts: true});

        const onComplete = () => {
            this.setState({isFetchingAccounts: false});
        };

        action.fetchAccounts(`cifNumber=${newCustodyProfile.custodyEntity.cif}`, 'selectedEntityAccounts', onComplete, false);
    }

    handleSelectPurporseType(event, attribute) {
        let value = event.value;
        this.props.action.onChangeValue('accountRelations', attribute, value);
    };


    handleValueChange = ({target}, attr, innerAttribute) => {
        let value = target.value;
        let val = this.state[attr];
        val[innerAttribute] = value;
        this.setState({[attr]: val})
    };


    handleSelectChange = (event, attr, innerAttribute) => {
        let value = event.target.value;
        let val = this.state[attr];
        val[innerAttribute] = value;
        console.log(value)
        this.setState({[attr]: val})

    };


    handleToggle = (differentCif) => {
        this.setState({differentCif});
    };

    handleCifChange =  ({target}) => {

        let value = _.trimStart(target.value);

        this.setState({cif: value});
    }


    onSearch = () =>{
        this.setState({isFetchingAccounts: true});

        const onComplete = () => {
            this.setState({isFetchingAccounts: false});
        };

        this.props.action.fetchAccounts(`cifNumber=${this.state.cif}`, 'selectedEntityAccounts', onComplete, false);
    }

    onHandleFilterAccounts = (searchTerm) => {
        const {accounts} = this.props;
        let filteredAccounts = null;
        if (searchTerm) {
            filteredAccounts = _.filter(accounts, ({name, accountNumber}) => (
                    (name && name.toString().includes(searchTerm)) ||
                    (accountNumber && accountNumber.includes(searchTerm))
                )
            )
        }
       this.setState({filteredAccounts, searchTerm});
    };

    _handleSelectAccount(e, attr) {

        let val = this.state[attr];
        account = this.props.accounts[e.target.value];
        val.accountNumber = account.accountNumber;
        val.accountBranch = account.branch;
        val.accountName = account.name;
        this.setState({[attr]: val})
    }

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    _validateForm() {
        const {newCustodyProfile} = this.props;
        const options = {fullMessages: false};
        console.log("validating");
        let constraints;
        if (newCustodyProfile.accountRelations && newCustodyProfile.accountRelations.cashAccountType === 'ALL') {
            constraints = {
                "allAccountPurpose.accountNumber": {presence: true, length: {minimum: 3, message: 'required'}},
            };
        } else {
            constraints = {
                "chargeAccPurpose.accountNumber": {presence: true, length: {minimum: 1, message: 'required'}},
                "secTradingAccPurpose.accountNumber": {presence: true, length: {minimum: 1, message: 'required'}},
                "caAccountPurpose.accountNumber": {presence: true, length: {minimum: 1, message: 'required'}},
            };
        }
        const errors = validate(this.state, constraints, options);

        this.setState({errors});
        return _.isEmpty(errors);
    }

    saveDetails = (event) => {
        event.preventDefault();

        if (this._validateForm()) {
            const {history, newCustodyProfile} = this.props;
            let cashAccounts = [];
            if (newCustodyProfile.accountRelations && newCustodyProfile.accountRelations.cashAccountType === 'ALL') {
                cashAccounts.push(this.state.allAccountPurpose);
            } else {
                cashAccounts.push(this.state.caAccountPurpose);
                cashAccounts.push(this.state.secTradingAccPurpose);
                cashAccounts.push(this.state.chargeAccPurpose);
            }
            newCustodyProfile.cashAccountDetails = cashAccounts;

            const onSuccess = () => history.push(newCustodyAccountRoutes.instructingparty);
            this.props.action.saveCustody(this.props.appId, this.props.newCustodyProfile, 'UserTask_CashAccount', onSuccess);
        }
    };

    saveAndContinue = (event) => {
        event.preventDefault();
        const {history,newCustodyProfile} = this.props;

        let cashAccounts = [];
        if (newCustodyProfile.accountRelations && newCustodyProfile.accountRelations.cashAccountType === 'ALL') {
            cashAccounts.push(this.state.allAccountPurpose);
        } else {
            cashAccounts.push(this.state.caAccountPurpose);
            cashAccounts.push(this.state.secTradingAccPurpose);
            cashAccounts.push(this.state.chargeAccPurpose);
        }
        newCustodyProfile.cashAccountDetails = cashAccounts;

        const onSuccess = () => history.push('/saveapplication');
        this.props.action.saveCustody(this.props.appId, newCustodyProfile, 'saveContinue', onSuccess);

    };

    _renderAccountSection() {
        const {newCustodyProfile} = this.props;

        if (newCustodyProfile.accountRelations.cashAccountType === "ALL") {
            return this._renderAllPurposeAccount();
        } else if (newCustodyProfile.accountRelations.cashAccountType === "MULTI") {
            return this._renderMultiPurposeAccount();
        }
    }

    _renderAllPurposeAccount() {
        const {accounts} = this.props;
        const {errors, filteredAccounts} = this.state;
        const accountList = filteredAccounts ? filteredAccounts : accounts;


        console.log(errors)

        return (
            <div className="row">

                <div className="col-md-4">

                    <FormField className="form-group" id="settlementPurpose">
                        <label htmlFor="clientType">Settlement Purpose</label>
                        <input
                            className="form-control"
                            id="currency"
                            type="text"
                            value="ALL"
                            disabled={true}
                        />
                    </FormField>

                    <FormField className="form-group" id="currency">
                        <label htmlFor="levelAt">Currency</label>
                        <input
                            className="form-control"
                            type="text"
                            value={this.state.allAccountPurpose.currency}
                            onChange={(event) => {
                                this.handleValueChange(event, 'allAccountPurpose', 'currency')
                            }}
                            disabled={true}
                        />
                    </FormField>

                    <FormField className="form-group" id="settlementAccount"
                               error={errors && errors['allAccountPurpose.accountNumber'] && ['Select Cash Account']}>
                        <label htmlFor="clientType">Settlement Cash Account</label>
                        <div className="card-header" style={{width: "100%"}}>
                            <div style={{flex: 1}}/>
                            <ListSearchComponent
                                onFilter={this.onHandleFilterAccounts}
                                placeholder="Search by Account name or number..."
                            />
                        </div>
                        <select className="form-control"
                                onChange={(event) => {
                                    this._handleSelectAccount(event, 'allAccountPurpose')
                                }}>
                            <option key={100} value=""> Please select</option>
                            {accountList.length > 0 && accountList.map((item, i) =>
                                <option key={i} value={i}>{item.accountNumber} | {item.name}</option>
                            )}
                            );
                        </select>
                    </FormField>


                    {/*<FormField className="form-group" id="invoicePaymentMethod">*/}
                    {/*    <label htmlFor="clientType">Invoice Payment Method </label>*/}
                    {/*    <select*/}
                    {/*        className="form-control"*/}
                    {/*        id="invoicePaymentMethod"*/}
                    {/*        onChange={(event) => {*/}
                    {/*            this.handleSelectChange(event, 'allAccountPurpose', 'invoicePaymentMethod')*/}
                    {/*        }}>*/}

                    {/*        {invoicePaymentMethodArray.length > 0 && invoicePaymentMethodArray.map((item, i) =>*/}
                    {/*            <option key={i}>{item}</option>*/}
                    {/*        )}*/}
                    {/*        );*/}
                    {/*    </select>*/}
                    {/*</FormField>*/}

                    {/*{*/}
                    {/*    this.state.allAccountPurpose.invoicePaymentMethod === 'Payment Term' ?*/}
                    {/*        <FormField className="form-group" id="bufferDays">*/}
                    {/*            <label htmlFor="levelAt">Buffer Days</label>*/}
                    {/*            <input*/}
                    {/*                className="form-control"*/}
                    {/*                id="bufferDays"*/}
                    {/*                type="text"*/}
                    {/*                value={this.state.allAccountPurpose.bufferDays}*/}
                    {/*                onChange={(event) => {*/}
                    {/*                    this.handleValueChange(event, 'allAccountPurpose', 'bufferDays')*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*        </FormField>*/}
                    {/*        : ''*/}
                    {/*}*/}
                </div>
            </div>
        );

    }

    _renderFooter() {
        return (
            <div className="flexRow" style={{justifyContent: "space-between", float: "right"}}>
                <button
                    className="action-btn-primary"
                    onClick={this.onSubmit}
                    type="button"
                >Next
                </button>
            </div>
        )
    }


    _renderMultiPurposeAccount() {
        const {accounts} = this.props;
        const {errors} = this.state;


        return (
            <div className="row">

                <div className="col-md-4">
                    <FormField className="form-group">
                        <label htmlFor="clientType">Settlement Purpose</label>
                        <input
                            className="form-control"
                            type="text"
                            value={this.state.chargeAccPurpose.settlementPurpose}
                            disabled={true}
                        />
                    </FormField>

                    <FormField className="form-group" id="currency">
                        <label htmlFor="levelAt">Currency</label>
                        <input
                            className="form-control"
                            id="currency"
                            type="text"
                            value={this.state.chargeAccPurpose.currency}
                            onChange={(event) => {
                                this.handleValueChange(event, 'chargeAccPurpose', 'currency')
                            }}
                            disabled={true}
                        />
                    </FormField>

                    <FormField className="form-group"
                               error={errors && errors['chargeAccPurpose.accountNumber'] && ['Select Cash Account']}>
                        <label htmlFor="clientType">Settlement Cash Account</label>
                        <select
                            className="form-control"
                            onChange={(event) => {
                                this._handleSelectAccount(event, 'chargeAccPurpose')
                            }}>
                            <option key={100} value=""> Please select</option>
                            {accounts.length > 0 && accounts.map((item, i) =>
                                <option key={i} value={i}>{item.accountNumber} | {item.name}</option>
                            )}
                            );
                        </select>
                    </FormField>

                </div>

                <div className="col-md-4">
                    <FormField className="form-group">
                        <label htmlFor="clientType">Settlement Purpose</label>
                        <input
                            className="form-control"
                            type="text"
                            value={this.state.secTradingAccPurpose.settlementPurpose}
                            disabled={true}
                        />
                    </FormField>

                    <FormField className="form-group" id="currency">
                        <label htmlFor="levelAt">Currency</label>
                        <input
                            className="form-control"
                            id="currency"
                            type="text"
                            value={this.state.secTradingAccPurpose.currency}
                            onChange={(event) => {
                                this.handleValueChange(event, 'secTradingAccPurpose', 'currency')
                            }}
                        />
                    </FormField>


                    <FormField className="form-group"
                               error={errors && errors['secTradingAccPurpose.accountNumber'] && ['Select Cash Account']}>
                        <label htmlFor="clientType">Settlement Cash Account</label>
                        <select
                            className="form-control"
                            id="accountNumber"
                            onChange={(event) => {
                                this._handleSelectAccount(event, 'secTradingAccPurpose')
                            }}>
                            <option key={100} value=""> Please select</option>
                            {accounts.length > 0 && accounts.map((item, i) =>
                                <option key={i} value={i}>{item.accountNumber} | {item.name}</option>
                            )}
                            );
                        </select>
                    </FormField>

                </div>

                <div className="col-md-4">
                    <FormField className="form-group">
                        <label htmlFor="clientType">Settlement Purpose</label>
                        <input
                            className="form-control"
                            type="text"
                            value={this.state.caAccountPurpose.settlementPurpose}
                            disabled={true}
                        />
                    </FormField>

                    <FormField className="form-group" id="currency">
                        <label htmlFor="levelAt">Currency</label>
                        <input
                            className="form-control"
                            type="text"
                            value={this.state.caAccountPurpose.currency}
                            onChange={(event) => {
                                this.handleValueChange(event, 'caAccountPurpose', 'currency')
                            }}
                            disabled={true}
                        />
                    </FormField>


                    <FormField className="form-group"
                               error={errors && errors['caAccountPurpose.accountNumber'] && ['Select Cash Account']}>
                        <label htmlFor="clientType">Settlement Cash Account</label>
                        <select
                            className="form-control"
                            onChange={(event) => {
                                this._handleSelectAccount(event, 'caAccountPurpose')
                            }}>
                            <option key={100} value=""> Please select</option>
                            {accounts.length > 0 && accounts.map((item, i) =>
                                <option key={i} value={i}>{item.accountNumber} | {item.name}</option>
                            )}
                            );
                        </select>
                    </FormField>
                </div>
            </div>
        );

    }


    render() {

        const {newCustodyProfile} = this.props;
        const {errors, isFetchingAccounts } = this.state;


        if (isFetchingAccounts) {
            return (
                <div className="rule-spinner">
                    <ReactLoading type="spokes" color="#444"/>
                </div>
            )
        }

        return (

            <div className="page-container">
                <div className="page-main-section">
                    <div className="card-container-form">
                        <div className="section-title">
                            <span>Cash Account Purpose</span>
                        </div>

                        <div className="row">
                            <div className="col-md-6">

                                <FormField className="form-group" id="cashAccountType">
                                    <label htmlFor="cashAccountType">Account Type</label>
                                    <Select
                                        name="cashAccountType"
                                        onChange={(event) => {
                                            this.handleSelectPurporseType(event, 'cashAccountType')
                                        }}
                                        options={CASH_ACC_TYPES}
                                        value={newCustodyProfile && newCustodyProfile.accountRelations && newCustodyProfile.accountRelations.cashAccountType}
                                    />
                                </FormField>

                                <FormField className="form-group" id="differentCif">
                                    <label htmlFor="differentCif">Search Cash Account from different Entity?</label>

                                    <Toggler
                                    label=""
                                    isOn={this.state.differentCif}
                                    onToggle={this.handleToggle}
                                />
                                </FormField>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                            <div>{this.state.differentCif &&
                            <FormField className="form-group" id="differentCif">
                                <SearchComponent
                                    label="CIF"
                                    onChange={(event) => {
                                        errors && errors.cif && onResetError && onResetError('cif');
                                        this.handleCifChange(event)
                                    }}
                                    onSearch={this.onSearch}
                                    searchValue={this.state.cif || ''}
                                />
                            </FormField>
                                }
                            </div>
                            </div>

                        </div>


                        {this._renderAccountSection()}


                    </div>


                    <div className="page-footer-section">
                        <ButtonsComponent
                            history={this.props.history}
                            onNext={this.saveDetails}
                            onSaveAndContinue={this.saveAndContinue}
                            prevPage={newCustodyAccountRoutes.contactdetails}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

function mapStateToProps({accounts, custody}) {
    return {
        newCustodyProfile: custody.custodyRequest,
        appId: custody && custody.id,
        accounts: accounts && accounts.selectedEntityAccounts ? accounts.selectedEntityAccounts : [],
    }
}

function mapDispatchToProps(dispatch) {
    return {action: bindActionCreators({...actions, fetchAccounts}, dispatch)}
}


export default connect(mapStateToProps, mapDispatchToProps)(AccountCashPurpose);
