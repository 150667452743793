import React, {Component} from 'react';
import _ from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import clientTypes from '../../../data/clientTypes';
import * as actions from '../../../actions/entity';
import countries from '../../../data/countries';
import validate from 'validate.js';
import FormField from '../../formField/FormField';

const FORM_KEYS = ['natureOfBusiness', 'clientType', 'businessClassification', 'countryOfRevenue', 'entityActive', 'parentEntityName', 'relationshipRole', 'countryOfOperation', 'kycLocation'];

class RegulatoryInfoPage extends Component {
    constructor(props) {
        super(props);
        const defaultCountry = _.find(countries, c => c.label == 'South Africa');
        this.state = {defaultCountry, searchText: '', errors: {}, formattedNetAssetValue: '', formattedExpectedIncomeValue: '', formattedManagementAssets: '', formattedValue: ''};
        this.handleNext = this._handleNext.bind(this);
        this.handleOnChange = this._handleOnChange.bind(this);
        this.handleOnChangeKycContact = this._handleOnChangeKycContact.bind(this);
        this.handlePrevious = this._handlePrevious.bind(this);
        this.handleSaveAndContinue = this._handleSaveAndContinue.bind(this);
    }

    componentDidMount() {
        this._setDefaultValues();
        const { legalEntity } = this.props.newEntityDetails;
        
        this.setState({
            formattedNetAssetValue: this.formatCurrency(legalEntity.netAssetValue),
            formattedExpectedIncomeValue: this.formatCurrency(legalEntity.annualTurnover),
            formattedManagementAssets: this.formatCurrency(legalEntity.managementAssets),
          });
    }

    _setDefaultValues() {
        const {actions, newEntityDetails} = this.props;
        const keys = _.union(_.keys(newEntityDetails), FORM_KEYS);
        let legalEntity = (newEntityDetails && newEntityDetails.legalEntity) ? newEntityDetails.legalEntity : {};

        _.map(keys, (key) => {
            const element = this.refs[key];

            if (!legalEntity[key] && element) {
                const value = element.value ? element.value : element.selected;
                legalEntity = _.extend({}, legalEntity, {[key]: value});
            }
        });
        const annualTurnover = legalEntity && legalEntity.annualTurnover && legalEntity.annualTurnover.toString();
        legalEntity = _.extend({}, legalEntity, {annualTurnover});
        actions.onChangeValue('newEntityDetails', 'legalEntity', legalEntity);
        const query = new URLSearchParams(this.props.location.search);
        const fromContinue = query.has('continue');
        actions.startNewEntityOnboard(_.extend({}, newEntityDetails, {
            legalEntity,
            status: 'reginfo'
        }), fromContinue, null);
    }

    _handleOnChange({target}, attribute, innerAttribute) {
        let value = _.trimStart(target.value);
        if (innerAttribute) {
            const {newEntityDetails} = this.props;
            value = _.extend({}, newEntityDetails && newEntityDetails[attribute], {[innerAttribute]: value});
            if (innerAttribute == 'clientType') {
                value = _.extend({}, value, {businessClassification: null});
            }
        }
        this.props.actions.onChangeValue('newEntityDetails', attribute, value);
    }

    _handleOnChangeKycContact({target}, attribute) {
        const {newEntityDetails} = this.props;
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        if (legalEntity) {
            const value = _.extend({}, legalEntity, {kycContact: _.extend({}, legalEntity && legalEntity.kycContact, {[attribute]: _.trimStart(target.value)})});
            this.props.actions.onChangeValue('newEntityDetails', 'legalEntity', value);
        }
    }

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})});
    }

    _validateForm() {
        const {newEntityDetails} = this.props;
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        const options = {fullMessages: false};
        const classifications = clientTypes[legalEntity.clientType] && clientTypes[legalEntity.clientType].classifications;

        const businessClassificationConstraint = _.size(classifications) > 0 && {
            businessClassification: {
                presence: true,
                length: {minimum: 1, message: 'required'}
            }
        };

        const constraints = _.extend({
            registrationNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            annualTurnover: {presence: true, numericality: true, length: {minimum: 1, message: 'required'}},
            netAssetValue: {presence: true, numericality: true, length: {minimum: 1, message: 'required'}},
            //managementAssets: {numericality: true, length: {minimum: 0}},
        }, businessClassificationConstraint);

        const kycContraints = {
            firstName: {presence: true, length: {minimum: 1, message: 'required'}},
            lastName: {presence: true, length: {minimum: 1, message: 'required'}},
            email: {presence: true, email: true, length: {minimum: 1, message: 'required'}},
            contactNumber: {presence: true, numericality: true, length: {minimum: 1, message: 'required'}},
        };

        const legalEntityErrors = validate(legalEntity, constraints, options);
        const kycErrors = validate(legalEntity && legalEntity.kycContact, kycContraints, options);

        const errors = _.extend({}, legalEntityErrors, kycErrors);

        this.setState({errors});
        return _.isEmpty(errors);
    }

    _handleNext(e) {
        e.preventDefault();

        if (this._validateForm()) {
            const {actions, newEntityDetails, history} = this.props;
            const onProceed = () => history.push('/onboard/details');
            const updateEntityDetails = _.extend({}, newEntityDetails, {status: 'details'});
            actions.saveNewEntityInfo(updateEntityDetails, onProceed, null, false, true);
        }
    }


    _handleSaveAndContinue() {
        const {actions, newEntityDetails, history} = this.props;
        const onProceed = () => history.replace('/');
        const updateEntityDetails = _.extend({}, newEntityDetails, {status: 'reginfo'});
        actions.saveNewEntityInfo(updateEntityDetails, onProceed);
    }

    _handlePrevious() {
        this.props.history.push('/onboard');
    }

    allowOnlyCharacters = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^A-Za-z\s]/g, '');
        event.target.value = value;
    }

    allowOnlyNumbers = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^0-9]/g, '');
        event.target.value = value;
    }

    allowOnlyAlphanumeric = (event) => {
        let { value } = event.target;
        // Replace characters that are not alphanumeric or spaces with an empty string
        value = value.replace(/[^A-Za-z0-9\s]/g, '');
        event.target.value = value;
    }

    handleEntityInfoChange = (event, key) => {
        const { value } = event.target;
        
        // Remove any commas from the value and check if it's a valid number
        const numericValue = value.replace(/,/g, '');
    
        if (/^\d{0,13}(\.\d{0,2})?$/.test(numericValue)) {
            this.setState({
                [key]: value,
            });
        }
    };
  
    formatCurrency = (value) => {
        const numericValue = parseFloat(value) || 0;
        return numericValue.toLocaleString('en-US', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };
    
    handleInputBlur = (key, propertyName) => {
        const legalEntity = this.props.newEntityDetails.legalEntity;
        let formattedValue = this.state[key];
    
        // Remove any commas and trailing ".00"
        const numericValue = formattedValue
            .replace(/,/g, '')  // Remove commas
            .replace(/\.00$/, '');  // Remove ".00"
    
        // Ensure the value is a valid number and greater than 0
        if (numericValue !== '' && !isNaN(numericValue) && parseFloat(numericValue) >= 0) {
            // Add ".00" if it's not already there
            const finalValue = numericValue.includes('.00')
                ? numericValue
                : numericValue + '.00';
    
            // Update the property in the kycRequest object without commas
            legalEntity[propertyName] = numericValue;
    
            // Format the value using the formatCurrency function
            formattedValue = this.formatCurrency(finalValue);
        } else {
             // Handle case when input is blank
             legalEntity[propertyName] = null; // or assign any default value you want
             formattedValue = ''; // or assign any default formatted value you want
        }
    
        // Set the formatted value with commas to the state for display
        this.setState({
            [key]: formattedValue,
        });
    };
    

    _renderFooter() {
        return (
            <div className="action-button-container">
                <button
                    className="action-btn-secondary"
                    onClick={this.handleSaveAndContinue}
                    type="button"
                > Save And Continue Later
                </button>
                <button
                    className="action-btn-primary"
                    type="submit"
                >Next
                </button>
            </div>
        );
    }

    render() {
        const {newEntityDetails, systemError} = this.props;
        if (systemError && systemError.show) return null;
        const {defaultCountry, errors, formattedNetAssetValue, formattedExpectedIncomeValue, formattedManagementAssets} = this.state;
        const filteredCountries = _.filter(countries, c => c.label !== defaultCountry.label);
        const legalEntity = newEntityDetails && newEntityDetails.legalEntity;
        const kycContact = legalEntity && legalEntity.kycContact;
        const clientType = legalEntity && legalEntity.clientType;
        const classification = clientTypes[clientType] && clientTypes[clientType].classifications;

        return (
            <form className="page-container" onSubmit={this.handleNext}>
                <div className="page-main-section">
                    <div className="flexColumn">
                        <div className="card-container-form">
                            <div className="section-title">
                                <span>Entity Details</span>
                            </div>

                            <FormField className="form-group" id="regNumber"
                                       error={errors && errors.registrationNumber}>
                                <label htmlFor="registeredName">Registration Number</label>
                                <input
                                    className="form-control"
                                    id="registrationNumber"
                                    onChange={(event) => {
                                        this.allowOnlyAlphanumeric(event);
                                        errors && errors.registrationNumber && this._resetError('registrationNumber');
                                        this.handleOnChange(event, 'legalEntity', 'registrationNumber');
                                    }}
                                    ref="registrationNumber"
                                    type="text"
                                    value={legalEntity && legalEntity.registrationNumber || ''}
                                />
                            </FormField>

                            <div className="form-group">
                                <label htmlFor="natureOfBusiness">Nature of Business</label>
                                <select
                                    className="form-control"
                                    id="natureOfBusiness"
                                    onChange={(event) => this.handleOnChange(event, 'legalEntity', 'natureOfBusiness')}
                                    ref="natureOfBusiness"
                                    defaultValue={legalEntity && legalEntity.natureOfBusiness || ''}
                                >
                                    <option>Other</option>
                                    <option>Antique Dealer</option>
                                    <option>Arms Manufacturer/Dealer/intermediary</option>
                                    <option>Art Dealer</option>
                                    <option>Auction House</option>
                                    <option>Bureau de Change</option>
                                    <option>Cash Intensive Business</option>
                                    <option>Casino</option>
                                    <option>Cheque Cashing/Payday Advance Services</option>
                                    <option>Exchange Traded Carbon Credit</option>
                                    <option>Gem/Jewel Dealer</option>
                                    <option>Money Service Businesses</option>
                                    <option>Online Gaming and Spread betting</option>
                                    <option>Other High Value / Precious Goods Dealer</option>
                                    <option>Pawnbroking</option>
                                    <option>Precious Metal Dealer</option>
                                    <option>Churches and other Religious Bodies</option>
                                    <option>Nuclear Industry</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="countryOfRevenue">Country of Revenue</label>
                                <select
                                    className="form-control"
                                    id="countryOfRevenue"
                                    onChange={(event) => this.handleOnChange(event, 'legalEntity', 'countryOfRevenue')}
                                    ref="countryOfRevenue"
                                    defaultValue={legalEntity && legalEntity.countryOfRevenue || ''}
                                >
                                    <option key={'countryOfRevenue-default'}>{defaultCountry.label}</option>
                                    {
                                        _.map(filteredCountries, (c, i) => (
                                            <option key={`countryOfRevenue-${i}`}>{c.label}</option>
                                        ))
                                    }
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="entityActive">Does Entity Actively Trade</label>
                                <select
                                    className="form-control"
                                    id="entityActive"
                                    onChange={(event) => this.handleOnChange(event, 'legalEntity', 'entityActive')}
                                    ref="entityActive"
                                    defaultValue={legalEntity && legalEntity.entityActive || ''}
                                >
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="relationshipRole">Relationship Role</label>
                                <select
                                    className="form-control"
                                    id="relationshipRole"
                                    onChange={(event) => this.handleOnChange(event, 'legalEntity', 'relationshipRole')}
                                    ref="relationshipRole"
                                    defaultValue={legalEntity && legalEntity.relationshipRole || ''}
                                >
                                    <option>Client</option>
                                    <option>Corresponding Bank</option>
                                    <option>Managed Fund</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="countryOfOperation">Country of Operation</label>
                                <select
                                    className="form-control"
                                    id="countryOfOperation"
                                    onChange={(event) => this.handleOnChange(event, 'legalEntity', 'countryOfOperation')}
                                    ref="countryOfOperation"
                                    defaultValue={legalEntity && legalEntity.countryOfOperation || ''}
                                >
                                    <option key={'countryOfOperation-default'}>{defaultCountry.label}</option>
                                    {
                                        _.map(filteredCountries, (c, i) => (
                                            <option key={`countryOfOperation-${i}`}>{c.label}</option>
                                        ))
                                    }
                                </select>
                            </div>


                            {/* <FormField className="form-group" id="turnover" error={errors && errors.annualTurnover}>
                                <label htmlFor="annualTurnover">Expected Income/Annual Turnover (in ZAR)</label>
                                <input
                                    className="form-control"
                                    id="annualTurnover"
                                    onChange={(event) => {
                                        errors && errors.annualTurnover && this._resetError('annualTurnover');
                                        this.handleOnChange(event, 'legalEntity', 'annualTurnover');
                                    }}
                                    type="text"
                                    ref="annualTurnover"
                                    value={legalEntity && legalEntity.annualTurnover || ''}
                                />
                            </FormField> */}

                            <FormField className="form-group" id="turnover" error={errors && errors.annualTurnover}>
                                <label htmlFor="annualTurnover">Expected Income/Annual Turnover (in ZAR)</label>
                                <input
                                    className="form-control"
                                    id="annualTurnover"
                                    onChange={(event) => {
                                        errors && errors.annualTurnover && this._resetError('annualTurnover');
                                        this.handleEntityInfoChange(event, 'formattedExpectedIncomeValue')
                                    }}
                                    type="text"
                                    ref="annualTurnover"
                                    onBlur={() => this.handleInputBlur('formattedExpectedIncomeValue', 'annualTurnover')}
                                    value={formattedExpectedIncomeValue}
                                />
                            </FormField>
                            {/* <FormField className="form-group" id="turnover" error={errors && errors.netAssetValue}>
                                <label htmlFor="netAsset">NET Asset Value (in Rand)</label>
                                <input
                                    className="form-control"
                                    id="netAsset"
                                    onChange={(event) => {
                                        errors && errors.netAssetValue && this._resetError('netAssetValue');
                                        this.handleOnChange(event, 'legalEntity', 'netAssetValue');
                                    }}
                                    type="text"
                                    ref="netAssetValue"
                                    value={legalEntity && legalEntity.netAssetValue || ''}
                                />
                            </FormField> */}
                            <FormField className="form-group" id="turnover" error={errors && errors.netAssetValue}>
                                <label htmlFor="netAsset">NET Asset Value (in ZAR)</label>
                                <input
                                    className="form-control"
                                    id="netAsset"
                                    onChange={(event) => {
                                        errors && errors.netAssetValue && this._resetError('netAssetValue');
                                        this.handleEntityInfoChange(event, 'formattedNetAssetValue');
                                    }}
                                    type="text"
                                    ref="netAssetValue"
                                    onBlur={() => this.handleInputBlur('formattedNetAssetValue', 'netAssetValue')}
                                    value={formattedNetAssetValue}
                                />
                            </FormField>
                            <FormField className="form-group" id="turnover" error={errors && errors.managementAssets}>
                                <label htmlFor="managementAssets">Assets Under Management (If Applicable)</label>
                                <input
                                    className="form-control"
                                    id="managementAssets"
                                    onChange={(event) => {
                                        errors && errors.managementAssets && this._resetError('managementAssets');
                                        this.handleEntityInfoChange(event, 'formattedManagementAssets');
                                    }}
                                    type="text"
                                    ref="managementAssets"
                                    onBlur={() => this.handleInputBlur('formattedManagementAssets', 'managementAssets')}
                                    value={formattedManagementAssets}
                                />
                            </FormField>

                            <div className="form-group">
                                <label htmlFor="clientType">Client Type</label>
                                <select
                                    className="form-control"
                                    id="clientType"
                                    onChange={(event) => this.handleOnChange(event, 'legalEntity', 'clientType')}
                                    ref="clientType"
                                    defaultValue={clientType || ''}
                                >
                                    {_.map(clientTypes, (t, i) => <option key={`client_type=${i}`}>{t.type}</option>)}
                                </select>
                            </div>

                            {
                                _.size(classification) > 0 &&
                                <FormField className="form-group" id="businessClass"
                                           error={errors && errors.businessClassification}>
                                    <label htmlFor="businessClassification">Business Classification</label>
                                    <select
                                        className="form-control"
                                        id="businessClassification"
                                        onChange={(event) => {
                                            errors && errors.businessClassification && this._resetError('businessClassification');
                                            this.handleOnChange(event, 'legalEntity', 'businessClassification');
                                        }}
                                        ref="businessClassification"
                                        defaultValue={legalEntity && legalEntity.businessClassification || ''}
                                    >
                                        <option key={'businessClassification=default'}/>
                                        {
                                            _.map(classification, (c, i) =>
                                                <option key={`businessClassification=${i}`}>{c}</option>)
                                        }
                                    </select>
                                </FormField>
                            }
                        </div>
                    </div>
                    <div className="flexColumn">
                        <div className="card-container-form">
                            <div className="section-title">
                                <span>KYC Contact</span>
                            </div>

                            <FormField className="form-group" id="kycFirstName" error={errors && errors.firstName}>
                                <label htmlFor="firstName">First Name</label>
                                <input
                                    className="form-control"
                                    id="firstName"
                                    onChange={(event) => {
                                        this.allowOnlyCharacters(event);
                                        errors && errors.firstName && this._resetError('firstName');
                                        this.handleOnChangeKycContact(event, 'firstName');
                                    }}
                                    ref="firstName"
                                    type="text"
                                    value={kycContact && kycContact.firstName || ''}
                                />
                            </FormField>

                            <FormField className="form-group" id="kycLastName" error={errors && errors.lastName}>
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    className="form-control"
                                    id="lastName"
                                    onChange={(event) => {
                                        this.allowOnlyCharacters(event);
                                        errors && errors.lastName && this._resetError('lastName');
                                        this.handleOnChangeKycContact(event, 'lastName');
                                    }}
                                    ref="lastName"

                                    type="text"
                                    value={kycContact && kycContact.lastName || ''}
                                />
                            </FormField>

                            <FormField className="form-group" id="kycEmail" error={errors && errors.email}>
                                <label htmlFor="email">Email</label>
                                <input
                                    className="form-control"
                                    id="email"
                                    onChange={(event) => {
                                        errors && errors.email && this._resetError('email');
                                        this.handleOnChangeKycContact(event, 'email');
                                    }}
                                    ref="email"

                                    type="email"
                                    value={kycContact && kycContact.email || ''}
                                />
                            </FormField>

                            <FormField className="form-group" id="kycContactNumber"
                                       error={errors && errors.contactNumber}>
                                <label htmlFor="contactNumber">Contact Number</label>
                                <input
                                    className="form-control"
                                    id="contactNumber"
                                    onChange={(event) => {
                                        this.allowOnlyNumbers(event);
                                        errors && errors.contactNumber && this._resetError('contactNumber');
                                        this.handleOnChangeKycContact(event, 'contactNumber');
                                    }}
                                    ref="contactNumber"

                                    type="text"
                                    value={kycContact && kycContact.contactNumber || ''}
                                />
                            </FormField>

                            <div className="form-group">
                                <label htmlFor="kycLocation">KYC Location</label>
                                <select
                                    className="form-control"
                                    id="kycLocation"
                                    onChange={(event) => this.handleOnChange(event, 'legalEntity', 'kycLocation')}
                                    ref="kycLocation"
                                    defaultValue={legalEntity && legalEntity.kycLocation || ''}
                                    data-search={true}
                                >
                                    <option>SBSA - Standard Bank of South Africa Ltd</option>
                                    <option>SBAO – Standard Bank de Angola SA</option>
                                    <option>SBBW – Stanbic Bank Botswana Ltd</option>
                                    <option>SBCD – Stanbic Bank Congo SARL</option>
                                    <option>SBCI – Standard Bank Cote D’Ivoire SA</option>
                                    <option>SBGH – Stanbic Ghana Ltd</option>
                                    <option>SBKE – CFC Stanbic Bank Kenya</option>
                                    <option>SBLS – Standard Bank Lesotho Bank Ltd</option>
                                    <option>SBMU – Standard Bank (Mauritius) Ltd</option>
                                    <option>SBMW – Standard Bank Ltd Malawi</option>
                                    <option>SBMZ – Standard Bank SARL Mozambique</option>
                                    <option>SBNA – Standard Bank Namibia Ltd</option>
                                    <option>SBN – Stanbic IBTC Bank Plc</option>
                                    <option>SBSZ – Standard Bank Swaziland Ltd</option>
                                    <option>SBT – Standard Bank South Africa ltd, Taipei Bra</option>
                                    <option>SBTZ – Stanbic Bank Tanzania Ltd</option>
                                    <option>SBUG – Stanbic Uganda</option>
                                    <option>SBZM – Stanbic Bank Zambia Ltd</option>
                                    <option>SBZW – Stanbic Bank Zimbabwe Ltd</option>
                                    <option>NONE – Asia</option>
                                </select>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
            </form>
        );
    }
}

function mapStateToProps({entity, systemError}) {
    return {
        newEntityDetails: entity && entity.newEntityDetails ? entity.newEntityDetails : {},
        systemError
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)};
}

export default connect(mapStateToProps, mapDispatchToProps)(RegulatoryInfoPage);
