import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function announcementReducer(state = initialState.announcements, action) {
    switch (action.type) {
        case types.LOAD_ANNOUNCEMENT_SUCCESS:
            return {
                ...state, announcements: action.announcements
            };
        default:
            return state;
    }
}