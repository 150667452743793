import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {fetchHierachy} from '../../actions/entity';
import {startBolOnboard} from '../../actions/businessOnline';
import EntityList from '../legalentity/EntityList';
import NoLinkedEntitiesMessage from '../legalentity/NoLinkedEntitiesMessage';
import FormField from '../formField/FormField';
import {businessOnlineRoutes} from '../../routers/routes';


export const BusinessOnlinePage = (props) => {

    const impersonation = useSelector(state => state.impersonation && state.impersonation.impersonation);
    const entities = useSelector(state => state.entity && state.entity.entities);
    const loading = useSelector(state => state.loading);

    const [errors, setErrors] = useState({});
    const [selectedEntity, setSelectedEntity] = useState(null);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const dispatch = useDispatch();


    useEffect(() => {
            dispatch(fetchHierachy(impersonation));
        }, []
    );


    const handleEntitySelect = (selectedEntity) => {
        setSelectedEntity(selectedEntity)
    }

    const handleResetError = () => {
        setErrors({})
    }
    const handleAcceptTsAndCs = () => {
        errors && errors.termsAccepted && handleResetError();
        setTermsAccepted(!termsAccepted)
    };


    const onSubmit = (e) => {

        e.preventDefault();
        const cif = selectedEntity && selectedEntity.cif;

        const onProceed = (success = true) => {
            if (success) {
                props.history.push(businessOnlineRoutes.transaction);
            }
        };

        if (cif) {
            dispatch(startBolOnboard(selectedEntity, impersonation && impersonation.identityNumber, onProceed));
        }
    }

    const renderEntities = () => {
        return (
            <div style={{width: '100%'}}>
                <div>
                    <div className="product-heading">
                        Legal Entities
                    </div>
                    <div className="title-gradient"/>
                </div>
                <EntityList
                    entities={entities}
                    onSelect={handleEntitySelect}
                    selectedEntity={selectedEntity}
                    style={{minHeight: '50vh'}}
                />
            </div>
        )
    }

    const renderTerms = () => {
        return (
            <FormField
                className="form-group"
                error={errors && errors.termsAccepted && ['Terms And Conditions Should Be Accepted']}
            >
                <div className="flexRow" style={{alignItems: 'flex-start', textAlign: 'left'}}>
                    <div className="icon-container" onClick={handleAcceptTsAndCs}>
                        <i
                            className={termsAccepted ? 'fa fa-check-square-o' : 'fa fa-square-o'}
                            style={{color: 'rgb(0, 51, 170)', fontSize: 20, marginRight: 10}}
                        />
                    </div>
                    <p style={{margin: 0}}>
                        I agree to the
                        <a
                            target="_blank"
                            href="https://www.businessonline.standardbank.co.za/docs/Standard%20Bank%20Business%20Online%20Principal%20Electronic%20Banking%20Terms%20and%20Conditions.pdf">
                            Terms and Conditions
                        </a>
                        , including sourcing of entity details from public domain.
                    </p>
                </div>
            </FormField>
        )
    }

    const renderFooter = () => {
        return (
            <div className="flexRow" style={{justifyContent: 'space-between'}}>
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={onSubmit}
                    type="button"
                    disabled={termsAccepted ? !termsAccepted : true}
                >Next
                </button>
            </div>
        )
    }

    if (loading) return null;

    const hasEntities = _.size(entities) > 0;
    if (!hasEntities)

        return (
            <div className="page-container">
                <div className="page-main-section">
                    <NoLinkedEntitiesMessage/>
                </div>
            </div>
        );
    return (
        <div className="page-container">
            <div className="col-md-5">
                <div className="center form-group">
                    {renderEntities()}
                </div>
                <div className="column">
                    {renderTerms()}
                </div>
                <div className="page-footer-section">
                    {renderFooter()}
                </div>
            </div>
        </div>
    )
}

export default BusinessOnlinePage;
