import React from 'react';
import _ from "lodash";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";

import * as actions from "../../actions/bol";
import {BOL_PRODUCTS_SERVICES_CATEGORIES} from '../../data/bolDometicTakeOn'
import validate from "validate.js";
import FormField from "../formField/FormField";
import ProductsAndServices from './ProductsAndServices'

const CHANNELS = {
    bol: {type: "businessOnline", label: "Business Online"},
    host: {type: "hostToHost", label: "Host To Host"}
};

const BOL_TYPES = {domestic: "domestic", international: "international"};
const HOST_TO_HOST_TYPE = {partialAutomated: "partialAutomated", fullyAutomated: "fullyAutomated"};

class ChannelPage extends React.Component {
    state = {errors: {}, toggle: {}};

    handleChannelSelect = (channelRequirement) => {
        const {actions, newBolProfile} = this.props;
        const {errors} = this.state;
        const channelDetails = newBolProfile && newBolProfile.channelDetails;

        errors && errors.channelRequirement && this.handleResetError("channelRequirement");
        const isBolChannel = (channelRequirement === (CHANNELS.bol && CHANNELS.bol.type));
        !isBolChannel && this.productsAndServicesRef && this.productsAndServicesRef.setDefaults && this.productsAndServicesRef.setDefaults(true);
        const updatedChannelDetails = _.extend({}, channelDetails, {
            channelRequirement,
            hostToHostType: null,
            bolType: null
        });

        actions.onChangeValue('newBolProfile', "channelDetails", updatedChannelDetails);
    };

    handleHostTypeSelect = (hostToHostType) => {
        const {actions, newBolProfile} = this.props;
        const {errors} = this.state;
        const channelDetails = newBolProfile && newBolProfile.channelDetails;

        errors && errors.hostToHostType && this.handleResetError("hostToHostType");
        const updatedChannelDetails = _.extend({}, channelDetails, {hostToHostType});

        actions.onChangeValue('newBolProfile', "channelDetails", updatedChannelDetails);
    };
    handleBolTypeSelect = (bolType) => {
        const {actions, newBolProfile} = this.props;
        const {errors} = this.state;
        const channelDetails = newBolProfile && newBolProfile.channelDetails;

        errors && errors.bolType && this.handleResetError("bolType");
        const updatedChannelDetails = _.extend({}, channelDetails, {bolType});

        actions.onChangeValue('newBolProfile', "channelDetails", updatedChannelDetails);
    };

    handleOnUpdateProductsAndServices = (productsAndServices) => {
        const {actions} = this.props;
        actions.onChangeValue('newBolProfile', 'productsAndServices', productsAndServices);
    };

    handlePrevious = () => {
        this.props.history.push("/bol/details");
    };

    handleSaveAndContinue = () => {
        const {actions, newBolProfile, history} = this.props;
        const onProceed = () => history.replace('/');
        const updateDetails = _.extend({}, newBolProfile, {status: 'channels'});
        actions.saveNewBolProfile(updateDetails, onProceed)
    };

    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    handleValidate = () => {
        const {newBolProfile} = this.props;
        const channelDetails = newBolProfile && newBolProfile.channelDetails;
        const options = {fullMessages: false};

        const isHostToHost = (channelDetails && channelDetails.channelRequirement) === CHANNELS.host.type;
        const isBolType = (channelDetails && channelDetails.channelRequirement) === CHANNELS.bol.type;

        const constraints = _.extend({channelRequirement: {presence: true}}, isHostToHost && {hostToHostType: {presence: true}}, isBolType && {bolType: {presence: true}});

        const errors = validate(channelDetails, constraints, options);

        this.setState({errors});
        return _.isEmpty(errors);
    };

    determineNavigation = () => {
        const {newBolProfile} = this.props;
        const channelDetails = newBolProfile && newBolProfile.channelDetails;
        const productsAndServices = newBolProfile && newBolProfile.productsAndServices;
        const channelRequirement = channelDetails && channelDetails.channelRequirement;
        const hostToHostType = channelDetails && channelDetails.hostToHostType;

        let path = '';
        if (channelRequirement === CHANNELS.bol.type) {
            const hasProducts = _.size(productsAndServices) > 0;
            path = hasProducts ? "/bol/options" : "/bol/accounts";
        } else if (channelRequirement === CHANNELS.host.type && (hostToHostType === HOST_TO_HOST_TYPE.fullyAutomated)) {
            path = "/bol/accounts";
        } else if (channelRequirement === CHANNELS.host.type && (hostToHostType === HOST_TO_HOST_TYPE.partialAutomated)) {
            path = "/bol/users";
        }
        return path
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const isProductValid = this.productsAndServicesRef && this.productsAndServicesRef.validateProductsAndServices && this.productsAndServicesRef.validateProductsAndServices();
        if (this.handleValidate() && isProductValid) {
            const {actions, newBolProfile, history} = this.props;

            const onProceed = () => {
                const path = this.determineNavigation();
                history.push(path);
            };
            const updateDetails = _.extend({}, newBolProfile, {status: 'channels'});
            actions.saveNewBolProfile(updateDetails, onProceed);
        }
    };

    _renderChannelRequirements() {
        const {newBolProfile} = this.props;
        const {errors} = this.state;
        const channelDetails = newBolProfile && newBolProfile.channelDetails;
        const channelRequirement = channelDetails && channelDetails.channelRequirement;
        const hostToHostType = channelDetails && channelDetails.hostToHostType;
        const bolType = channelDetails && channelDetails.bolType;
        const showHostTypes = (channelRequirement === CHANNELS.host.type);
        const showBolType = (channelRequirement === CHANNELS.bol.type);

        return (
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        <span>Channel Requirements</span>
                    </div>
                    <FormField error={errors && errors.channelRequirement && ["Please Select Channel"]}>

                        <div>
                            <div className="selection-list-item"
                                 onClick={(e) => this.handleChannelSelect(CHANNELS.bol.type)}>
                                <i
                                    className={showBolType ? "fa fa-check-circle" : "fa fa-circle-o"}
                                    style={{color: "rgb(0, 51, 170)", fontSize: 20}}
                                />
                                <span style={{marginLeft: 10}}>{CHANNELS.bol.label}</span>
                            </div>
                            <FormField
                                style={{marginLeft: 15}}
                                error={errors && errors.bolType && ["Please Select Business Online Type"]}
                            >
                                <div style={{flexDirection: "row", display: "flex"}}>
                                    <div className="selection-list-item"
                                         onClick={(e) => showBolType && this.handleBolTypeSelect(BOL_TYPES.domestic)}>
                                        <i
                                            className={(bolType === BOL_TYPES.domestic) ? "fa fa-check-circle" : "fa fa-circle-o"}
                                            style={{color: showBolType ? "rgb(0, 51, 170)" : "grey", fontSize: 20}}
                                        />
                                        <span style={{marginLeft: 10}}>Domestic</span>
                                    </div>
                                    <div className="selection-list-item"
                                         onClick={(e) => showBolType && this.handleBolTypeSelect(BOL_TYPES.international)}>
                                        <i
                                            className={(bolType === BOL_TYPES.international) ? "fa fa-check-circle" : "fa fa-circle-o"}
                                            style={{color: showBolType ? "rgb(0, 51, 170)" : "grey", fontSize: 20}}
                                        />
                                        <span style={{marginLeft: 10}}> International</span>
                                    </div>
                                </div>
                            </FormField>
                        </div>

                        <div>
                            <div className="selection-list-item"
                                 onClick={(e) => this.handleChannelSelect(CHANNELS.host.type)}>
                                <i
                                    className={showHostTypes ? "fa fa-check-circle" : "fa fa-circle-o"}
                                    style={{color: "rgb(0, 51, 170)", fontSize: 20}}
                                />
                                <span style={{marginLeft: 10}}>{CHANNELS.host.label}</span>
                            </div>
                            <FormField
                                style={{marginLeft: 15}}
                                error={errors && errors.hostToHostType && ["Please Select Host To Host Type"]}
                            >
                                <div style={{flexDirection: "row", display: "flex"}}>
                                    <div className="selection-list-item"
                                         onClick={(e) => showHostTypes && this.handleHostTypeSelect(HOST_TO_HOST_TYPE.fullyAutomated)}>
                                        <i
                                            className={(hostToHostType === HOST_TO_HOST_TYPE.fullyAutomated) ? "fa fa-check-circle" : "fa fa-circle-o"}
                                            style={{color: showHostTypes ? "rgb(0, 51, 170)" : "grey", fontSize: 20}}
                                        />
                                        <span style={{marginLeft: 10}}>Fully Automated</span>
                                    </div>
                                    <div className="selection-list-item"
                                         onClick={(e) => showHostTypes && this.handleHostTypeSelect(HOST_TO_HOST_TYPE.partialAutomated)}>
                                        <i
                                            className={(hostToHostType === HOST_TO_HOST_TYPE.partialAutomated) ? "fa fa-check-circle" : "fa fa-circle-o"}
                                            style={{color: showHostTypes ? "rgb(0, 51, 170)" : "grey", fontSize: 20}}
                                        />
                                        <span style={{marginLeft: 10}}> Partially Automated</span>
                                    </div>
                                </div>
                            </FormField>
                        </div>
                    </FormField>
                </div>
            </div>
        )
    }

    _renderProductsAndServices() {
        const {newBolProfile} = this.props;
        const productsAndServices = newBolProfile && newBolProfile.productsAndServices;
        const channelDetails = newBolProfile && newBolProfile.channelDetails;
        const isBusinessOnline = channelDetails && channelDetails.channelRequirement === CHANNELS.bol.type;
        return (
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        <span>Products And Services</span>
                    </div>
                    <ProductsAndServices
                        ref={(ref) => {
                            if (ref) this.productsAndServicesRef = ref
                        }}
                        productsServicesSections={BOL_PRODUCTS_SERVICES_CATEGORIES}
                        productsAndServices={productsAndServices}
                        onUpdateProductsAndServices={this.handleOnUpdateProductsAndServices}
                        shouldDisable={!isBusinessOnline}
                    />
                </div>
            </div>
        )
    }

    _renderFooter() {
        return (
            <div className="action-button-container">
                <button
                    className="action-btn-primary"
                    onClick={this.handlePrevious}
                    type="button"
                >Previous
                </button>
                <button
                    className="action-btn-secondary"
                    onClick={this.handleSaveAndContinue}
                    type="button"
                > Save And Continue Later
                </button>
                <button
                    className="action-btn-primary"
                    type="submit"
                >Next
                </button>
            </div>
        )
    }

    render() {
        return (
            <div>
                <form className="page-container" onSubmit={this.handleSubmit}>
                    <div className="page-main-section">
                        {this._renderChannelRequirements()}
                        {this._renderProductsAndServices()}
                    </div>
                    <div className="page-footer-section">
                        {this._renderFooter()}
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps({bol}) {
    return {newBolProfile: bol && bol.newBolProfile ? bol.newBolProfile : {},}
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(ChannelPage);