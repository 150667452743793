import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ReactLoading from "react-loading";
import {trackData, completeApplicationTracking} from "../../../actions/analytics";

import * as actions from '../../../actions/product';
import {downloadReport} from '../../../actions/reports'

export const REPORT_TYPES = {
    APPLICATION_STATUS: 'APPLICATION_STATUS',
    BOL_PROFILE_ONBOARD: 'BOL_PROFILE_ONBOARD'
};

class ConfirmApplicationPage extends React.Component {
    state = {
        isLoading: false
    };

    _loadingStart() {
        this.setState({isLoading: true})
    };

    componentDidMount() {
        this.props.actions.trackData('pageName', 'New Account| Complete Application| Step 6');
        this.props.actions.completeApplicationTracking("Business Cheque Account");

    }

    _loadingFinish() {
        this.setState({isLoading: false})
    };

    handleOnDownloadApplication = (event) => {
        event.preventDefault();
        const {actions, applicationId} = this.props;
        this._loadingStart();

        const onComplete = () => {
            this._loadingFinish()
        };
        actions.downloadReport(REPORT_TYPES.APPLICATION_STATUS, {applicationId}, onComplete, true);
    };

    handleOnNavgiateToHome = (event) => {
        event.preventDefault();
        const {history} = this.props;
        history.replace("/");
    };

    _renderFooter(){
        return(
            <div className="action-button-container">
                {/* <button
                    className="action-btn-primary btn-sm" disabled
                    onClick={this.handleOnDownloadApplication}
                    type="submit"
                >Download Application
                </button> */}
                <button
                    className="action-btn-primary btn-sm"
                    onClick={this.handleOnNavgiateToHome}
                    type="button"
                >Home
                </button>
            </div>
        )
    }

    render() {
        const {accountDetails} = this.props;
        const {isLoading} = this.state;

        if (!accountDetails) return null;
        return (
            <div className="page-container">
                <div className="page-main-section">
                    <div className="flexColumn">
                        <div className="card-container">
                            <div className="section-title">Application Submitted</div>
                            <div>
                                <div className="product-heading">
                                    {accountDetails && accountDetails.accountType || "Managed Fund Custody Account"}
                                </div>
                                <div className="title-gradient"/>
                            </div>

                            <br/>
                            <p>
                                A letter of confirmation will be emailed to you as soon as your account is ready to
                                transact on.
                            </p>
                            <p>
                                It should take 24 hours for your application to be completed once the approval is
                                received.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="page-footer-section">
                    {this._renderFooter()}
                </div>
                {
                    isLoading &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
            </div>
        )
    }
}

function mapStateToProps({application, loading}) {
    const hasApplication = application && application.application;
    return {
        accountDetails: hasApplication && application.application.bankAccount ? application.application.bankAccount : {accountNumber: ''},
        applicationId: hasApplication ? application.application.id : '',
        loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, downloadReport, trackData, completeApplicationTracking}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmApplicationPage);
