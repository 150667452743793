import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import validate from 'validate.js';
import {Col} from 'react-bootstrap';
// import {ADDITIONAL_PRODUCTS} from '../ConfirmProductPage';
// import {ADDITIONAL_PRODUCTS} from '../cashAccount/cashConfirmProduct';
import FormField from '../../formField/FormField';
import Select from 'react-select';
import Popup from '../../Popup';

const OTHER_PROFILE_ID = {value: 'otherId', label: 'Other'};


export const ISonlineSetup = (props) => {

    const [isAddToggled, setIsAddToggled] = useState(false);
    const [onlineProduct, setonlineProduct] = useState(props.onlineProduct);
    const [operator, setOperator] = useState({
        accountAccess: {
            balanceStatements: false,
            Payments: false,
            ownTransfers: false,
            notifications: false
        }
    });
    const [selectedUser, setSelectedUser] = useState(null);
    const [operatorErrors, setOperatorErrors] = useState({});
    const [isOther, setIsOther] = useState(false);


    const handleOnChange = ({target}, attribute, innerAttribute) => {
        const {onChange} = props;
        let value = _.trimStart(target.value);
        if (innerAttribute) {
            value = _.extend({}, onlineProduct[attribute], {[innerAttribute]: value});
        }
        setonlineProduct(_.extend({}, onlineProduct, {[attribute]: value}));
        onChange(_.extend({}, onlineProduct, {[attribute]: value}));
    };

    const handleSelectChangeOperator = (event, attribute) => {
        let value = event.value;
        setOperator(_.extend({}, operator, {[attribute]: value}));
    };

    const handleOnChangeLimits = ({target}, attribute) => {
        const {onChange} = props;
        let value = _.trimStart(target.value);
        if (target.type === 'number')
            value = parseInt(value, 10);
        const limits = _.extend({}, onlineProduct && onlineProduct.limits, {[attribute]: value});

        setonlineProduct(_.extend({}, onlineProduct, {limits}));
        onChange(_.extend({}, onlineProduct, {limits}));
    };

    const handleOptions = (event, attribute) => {

        const {onChange} = props;
        let value = event.value;
        const limits = _.extend({}, onlineProduct && onlineProduct.limits, {[attribute]: onlineProduct && onlineProduct.limits ? !onlineProduct.limits[attribute] : true});
        setonlineProduct(_.extend({}, onlineProduct, {limits}));
        onChange(onlineProduct);
    };

    const handleOnChangeOperator = ({target}, attribute, innerAttribute) => {
        let value = _.trimStart(target.value);
        if (innerAttribute) {
            value = _.extend({}, operator && operator[attribute], {[innerAttribute]: value});
        }
        setOperator(_.extend({}, operator, {[attribute]: value}));
    };

    const handleCheckSelect = (isSelected, attribute, innerAttribute) => {
        let value = isSelected;
        if (innerAttribute) {
            value = _.extend({}, operator && operator[attribute], {[innerAttribute]: value});
        }

        if(innerAttribute=='all'){
            operator.operatorId = 'ALL';
        }
        if(!isSelected){
            operator.operatorId = '';
        }
        setOperator(_.extend({}, operator, {[attribute]: value}));
    };


    const resetError = (attribute) => {
        setOperator(_.extend({}, errors, {[attribute]: null}));
    };

    const validateForm = () => {
        const options = {fullMessages: false};
        const constraints = {operatorId: {presence: true, length: {minimum: 1, message: 'required'}}};
        const operatorErrors = validate(operator, constraints, options);
        setOperatorErrors(operatorErrors);
        return _.isEmpty(operatorErrors);
    };

    const handleAddOperator = () => {
        if (validateForm()) {
            const {onChange} = props;
            const isFound = _.find(onlineProduct && onlineProduct.isOperators, o => ((o.operatorId && o.operatorId.toLocaleLowerCase()) === (operator.operatorId && operator.operatorId.toLocaleLowerCase())));

            let isOperators;
            if (!isFound) {
                isOperators = _.union(onlineProduct && onlineProduct.isOperators, [operator]);
            } else {
                isOperators = _.map(onlineProduct && onlineProduct.isOperators, o => {
                    const isMatch = ((o.operatorId && o.operatorId.toLocaleLowerCase()) === (operator.operatorId && operator.operatorId.toLocaleLowerCase()));
                    return isMatch ? operator : o;
                });
            }
            const updatedProduct = _.extend({}, onlineProduct,  {isOperators});
            setonlineProduct(updatedProduct);
            setIsAddToggled(false);
            setOperator({
                accountAccess: {
                    balanceStatements: false,
                    Payments: false,
                    ownTransfers: false,
                    notifications: false
                }
            });
            onChange(updatedProduct);
        }
    };

    const handleRemoveOperator = (operatorId) => {
        const {onChange} = props;
        const isOperators = _.filter(onlineProduct && onlineProduct.isOperators, o => (o.operatorId !== operatorId));
        setonlineProduct(_.extend({}, onlineProduct, {isOperators}));
        onChange(onlineProduct);
    };

    const showAddOperator = () => {
        setIsAddToggled(!isAddToggled);
    };

    const renderOperatorForm = () => {
        const {bolProfiles} = props;
        const accountAccess = operator && operator.accountAccess;
        let isOperatorsOptions = [];

        _.each(bolProfiles, (entity) => {
            _.each(entity.divisions, (div) => {

                if (div.code === selectedUser) {
                    _.each(div.users, (f) => {
                        isOperatorsOptions.push({'value': f.id, 'label': `${f.id}: ${f.name}`});
                    });
                }
            });
        });

        const hasProfileisOperators = _.size(isOperatorsOptions) > 0;

        return (
            <div>
                <div className="product-heading">
                    Operator Details
                </div>
                <div className="title-gradient"/>
                <div style={{paddingTop: '5px'}}>
                    Add User(s) you want to allow access to this account.
                </div>
                <div className="card-container-form">

                    <FormField className="form-group">
                        <div className="inline-container" style={{marginLeft: 0}}
                            onClick={() => handleCheckSelect(!(accountAccess && accountAccess.all), 'accountAccess', 'all')}>
                            <div className="icon-container">
                                <i className={((accountAccess && accountAccess.all) ? 'fa fa-check-square-o' : 'fa fa-square-o') + ' icon'}
                                style={{fontSize: 20}}/>
                            </div>
                            Add all Users
                        </div>
                    </FormField>
                    <FormField className="form-group" id="bol" error={operatorErrors && operatorErrors.operatorId}>
                        <label>User ID</label>
                        {
                            hasProfileisOperators ?

                                <Select
                                    name="operatorId"
                                    onChange={(event) => {
                                        operatorErrors && operatorErrors.operatorId && setOperatorErrors({});
                                        handleSelectChangeOperator(event, 'operatorId');
                                    }}
                                    options={isOperatorsOptions}
                                    value={operator && operator.operatorId ? operator.operatorId : ''}
                                />
                                :
                                <input
                                    className="form-control"
                                    id="operatorId"
                                    onChange={(event) => {
                                        operatorErrors && operatorErrors.operatorId && setOperatorErrors({});
                                        handleOnChangeOperator(event, 'operatorId');
                                    }}
                                    type="text"
                                    value={operator && operator.operatorId || ''}
                                />
                        }
                    </FormField>

                </div>
                <div className="action-button-container">
                    <div/>
                    <button
                        className="action-btn-secondary"
                        onClick={handleAddOperator}
                        type="button">
                        Add
                    </button>
                </div>
            </div>
        );
    };

    const renderisOperators = () => {
        const {isOnlineisOperators} = props;
        const propErrors = props.errors;
        const hasisOperators = _.size(onlineProduct && onlineProduct.isOperators) > 0;

        return (
            <div>
                <FormField error={propErrors && propErrors.isOperators ? ['At Least One Operator Should Be Added'] : ''}>
                    {
                        hasisOperators &&
                        <div>
                            <div className="section-title" style={{marginBottom: 0}}>
                                <span>Operators</span>
                            </div>
                            {
                                _.map(onlineProduct && onlineProduct.isOperators, (o, i) => {
                                    const accountAccess = o && o.accountAccess;
                                    const op = _.find(isOnlineisOperators, op => (op.operatorId === o.operatorId));
                                    return (
                                        <div key={`operator/${i}`}>
                                            <div className="flexRow" style={{alignItems: 'center',}}>
                                                <div className="col-md-11">
                                                    <span
                                                        className={'active-entity'}> {`${i + 1}. ${o.operatorId}: ${op && op.operatorName ? op.operatorName : ''}`}</span>
                                                    {accountAccess.balanceStatements &&
                                                    <span className="greyText"> | Balance & Statements</span>}
                                                    {accountAccess.payments &&
                                                    <span className="greyText"> | Payments</span>}
                                                    {accountAccess.ownTransfers &&
                                                    <span className="greyText"> | Own Transfers</span>}
                                                    {accountAccess.notifications &&
                                                    <span className="greyText"> | Notifications</span>}
                                                </div>
                                                <div className="col-md-1" style={{padding: 0}} id="item-content">
                                                    <p><a className="btn"
                                                          role="button"
                                                          id="remove-btn"
                                                          onClick={() => handleRemoveOperator(o.operatorId)}> <i
                                                        className="fa fa-times" aria-hidden="true"/></a>
                                                    </p>
                                                </div>
                                            </div>
                                            <hr style={{padding: 1, margin: 0}}/>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                    <a
                        className="btn btn-secondary addButton"
                        onClick={() => {
                            propErrors && propErrors.isOperators && props.resetErrors('isOperators');
                            showAddOperator();
                        }}>Add All Users / Add Specific User</a>
                    {propErrors && propErrors.isOperators && (<div><br/><br/></div>)}
                </FormField>

                {isAddToggled &&
                <div style={{zIndex: -1}}>
                    <Popup onClose={showAddOperator.bind(this)}>
                        {renderOperatorForm()}
                    </Popup>
                </div>
                }
            </div>
        );
    };

    const {resetErrors, bolProfiles, onFetchisOperators, errors} = props;
    const hasProfiles = _.size(bolProfiles) > 0;

    let profilesOptions = [];
    _.each(bolProfiles, (entity) => {
        _.each(entity.divisions, (div) => {
            profilesOptions.push({'value': div.code, 'label': `${div.code}: ${div.name}`});
        });
    });

    return (
        <div style={{marginTop:'10px'}}>
            <FormField className="form-group" id="cashman" error={errors && errors.profileId}>
                <label>Investor Services Online Profile ID</label>
                <input className="form-control" id="profileId" onChange={(event) => {
                    errors && errors.profileId && resetError('profileId');
                    handleOnChange(event, 'profileId');
                }}
                       value={onlineProduct && onlineProduct.profileId || ''}
                />
            </FormField>

            {renderisOperators()}
        </div>
    );
};
