import React, {Component} from 'react';
import _ from 'lodash';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import '../../styles/App.scss';
import '../../styles/EntityDetails.css';
import '../../styles/NewEntityDetails.css';
import '../../styles/Global.css';

// import * as actions from '../../actions/entity';

import * as actions from '../../actions/electronicIndemnity';
import {processGMApplication} from '../../actions/globalMarket';
import {fetchAccounts} from "../../actions/kyc";
import {electronicIndemnityRoutes, globalMarketAccountRoutes, signatoryRoots, kycRoutes} from "../../routers/routes";
import {startKycMaintenance} from "../../actions/kycMaintenance";
import EntityList from "../legalentity/EntityList";
import validate from "validate.js";
import FormField from "../formField/FormField";
import NoLinkedEntitiesMessage from '../legalentity/NoLinkedEntitiesMessage'
import Popup from "../Popup";
import {trackData, startApplicationTracking} from "../../actions/analytics";
import {startSignatoryApplication} from "../../actions/signatoryActions";

class ElectronicIndemnity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            selectedEntity: null,
            searchTerm: '',
            termsAccepted: false,
            showHasNoApprovalMessage: false,
            message: ''
        };
        this.handleSelect = this._handleSelect.bind(this);
        this.onSubmit = this._onSubmit.bind(this);
    }

    componentWillMount() {
        this._fetchHierachy();
    }

    componentDidMount() {
        this.props.actions.startApplicationTracking("Electronic Indemnity");
        this.props.actions.trackData("Electronic Indemnity", "New Account| Entity| Step 1");
    }

    handleAcceptTsAndCs = () => {
        const {errors} = this.state;
        errors && errors.termsAccepted && this.handleResetError("termsAccepted");
        this.setState(prevState => ({termsAccepted: !prevState.termsAccepted}))
    };

    _fetchHierachy() {
        const {actions, impersonation} = this.props;
        actions.fetchHierachy(impersonation);
    }


    _handleSelect(selectedEntity) {
        const {errors} = this.state;
        errors && errors.selectedEntity && this.handleResetError("selectedEntity");
        this.setState({selectedEntity});
    }

    handleResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    validateForm = () => {
        const {selectedEntity, termsAccepted} = this.state;
        const options = {fullMessages: false};
        const constraints = {selectedEntity: {presence: true}};

        let errors = validate({selectedEntity, termsAccepted}, constraints, options);
        if (!termsAccepted) {
            errors = _.extend({}, errors, validate({termsAccepted: null}, {termsAccepted: {presence: true}}, options))
        }
        this.setState({errors});
        return _.isEmpty(errors);
    };

    _onSubmit(e) {
        e.preventDefault();
        const {actions, application, user, impersonation, history, match} = this.props;
        const {selectedEntity} = this.state;
        const isKycApp = (match.path === kycRoutes.root);
        const isGMAccount = (match.path === globalMarketAccountRoutes.entity);
        const isSignatoryAdd = (match.path === signatoryRoots.addStart);
        const isSignatoryDelete = (match.path === signatoryRoots.deleteStart);
        const identityNumber = impersonation ? impersonation.identityNumber : '';
        
        const selectedGoldTierId = selectedEntity && selectedEntity.goldTierId;
        const goldTierId = selectedEntity && selectedEntity.goldTierId;
    
        if (this.validateForm() && goldTierId) {
            const onProceed = (success = true, message = '') => {
                if (success) {
                    if (isGMAccount) {
                        history && history.push(globalMarketAccountRoutes.confirmentity);
                    } else if (isSignatoryAdd) {
                        history && history.push(signatoryRoots.addInstructions)
                    } else if (isSignatoryDelete) {
                        history && history.push(signatoryRoots.deleteInstructions)
                    } else {
                        history.push(electronicIndemnityRoutes.electronicIndemnityClauses);
                    }
                } else {
                    this.setState({showHasNoApprovalMessage: true, message })
                }
            };

            /** start application */

            if (isGMAccount) {
                actions.processGMApplication(selectedEntity, null, impersonation, false, onProceed);
            } else if (isSignatoryAdd) {
                actions.startSignatoryApplication(selectedEntity, 'ADD', impersonation, onProceed);
            } else if (isSignatoryDelete) {
                actions.startSignatoryApplication(selectedEntity, 'DELETE', impersonation, onProceed);
            } else if (isKycApp) {
                actions.startKycMaintenance(identityNumber, selectedEntity, history);
            } else {
                actions.startElectronicIndemnityApplication(selectedGoldTierId, application, impersonation, onProceed);
            }

        }

    }

    _renderTerms() {
        const {termsAccepted, errors} = this.state;
        return (
            <FormField
                className="form-group"
                error={errors && errors.termsAccepted && ["Terms And Conditions Should Be Accepted"]}
            >
                <div className="flexRow" style={{alignItems: "flex-start", textAlign: "left"}}>
                    <div className="icon-container" onClick={this.handleAcceptTsAndCs}>
                        <i
                            className={termsAccepted ? "fa fa-check-square-o" : "fa fa-square-o"}
                            style={{color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10}}
                        />
                    </div>
                    <p style={{margin: 0}}>
                        I agree to the <a target="_blank"
                                          href="https://www.standardbank.co.za/static_file/South%20Africa/PDF/Business%20Ts%20and%20Cs/Business%20General%20Terms%20and%20Conditions.pdf">Terms
                        and Conditions</a>
                         , including sourcing of entity details from public domain.
                    </p>
                </div>
            </FormField>
        )
    }

    _renderEntities() {
        const {entities} = this.props;
        const hasEntities = _.size(entities) > 0;

        if (!hasEntities) return null;
        const {errors, selectedEntity} = this.state;

        return (
            <EntityList
                entities={entities}
                onSelect={this.handleSelect}
                error={errors && errors.selectedEntity}
                selectedEntity={selectedEntity}
                style={{maxHeight: '65vh'}}
            />
        );
    }

    handlePrevious = () => {
        const {history} = this.props;
        history && history.push('/');
    };

    handleCloseApprovalRulesErrorMessage = () => {
        this.setState({showHasNoApprovalMessage: false})
    };

    handleNavigateToAuthRules = ()=> {
        const {history} = this.props;
        history && history.replace('/rules');
    };

    _renderPopUps() {
        const {showHasNoApprovalMessage, message} = this.state;
        if (showHasNoApprovalMessage) {
            return (
                <Popup onClose={this.handleCloseApprovalRulesErrorMessage}>
                    <div>
                        <div>
                            <div className="product-heading">
                                Error
                            </div>
                            <div className="title-gradient"/>
                        </div>
                        <br/>
                        <p>{message}</p>

                        <div className="action-button-container" style={{width: "100%"}}>
                            <div/>
                            <button
                                className="action-btn-secondary"
                                onClick={this.handleNavigateToAuthRules}
                                type="submit"
                            >Configure
                            </button>
                        </div>

                    </div>
                </Popup>
            )
        }
        return null;
    }

    _renderFooter() {
        return (
            <div className="flexRow" style={{justifyContent: "space-between"}}>
                <button
                    className="action-btn-primary btn-sm"
                    onClick={this.handlePrevious}
                    type="button"
                >Previous
                </button>
                <button
                    className="action-btn-primary btn-sm"
                    onClick={this.onSubmit}
                    type="button"
                >Next
                </button>
            </div>
        )
    }

    render() {
        const {entities, loading} = this.props;
        if (loading) return null;

        const hasEntities = _.size(entities) > 0;
        if (!hasEntities) {
            return (
                <div className="page-container">
                    <div className="page-main-section">
                        <NoLinkedEntitiesMessage/>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="page-container">
                    <div className="page-main-section">
                        <div className="column" style={{width: "60%"}}>
                            {this._renderEntities()}
                        </div>
                    </div>
                    {this._renderTerms()}
                    <div className="page-footer-section" style={{width: "80%"}}>
                        {this._renderFooter()}
                    </div>
                    {this._renderPopUps()}
                </div>
            );
        }
    }
}

function mapStateToProps({application, entity, impersonation, user, loading}) {
    const hasEntities = entity && entity.entities;
    return {
        application: application.application,
        user: user && user.user,
        entities: hasEntities ? entity.entities : [],
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...actions,
            processGMApplication, fetchAccounts, trackData, startApplicationTracking, startSignatoryApplication, startKycMaintenance
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ElectronicIndemnity);
