import React, {useImperativeHandle, useState} from 'react';
import _ from 'lodash';
import validate from 'validate.js';
import {useDispatch, useSelector} from 'react-redux';
import "../../../styles/Global.css";
import '../../../styles/kyc.css';
import {managedFundRoutes} from '../../../routers/routes'
import {BolSetup} from './custodyBolSetup';
import {ToggleButtonGroup} from 'react-bootstrap';
import Row from 'react-bootstrap/lib/Row';
import ButtonsComponent from "../../ButtonsComponent";
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import CashEntityDocsComponent from "../custodyAccount/custodyKycEntityDocs";
import {saveCustodyManagedFund} from "../../../actions/custodyManagedFund";

const DOC_TYPES = {
    powerofAttorney: 'Power of Attorney',
    ownershipStructure: 'Ownership Structure',
    resolution: 'Resolution',
    cipcDocument: 'CIPC Document',
    financialStatements: 'Financial Statements'
};

export const custodyIsOnlineAndDocUpload = React.forwardRef((props, ref) => {

    const custodyRequest = useSelector(state => state.custodyManagedFund && state.custodyManagedFund.custodyManagedFundRequest);
    const appId = useSelector(state => state.custodyManagedFund && state.custodyManagedFund.id);
    const [entityDocs, setEntityDocs] = useState(custodyRequest.documents);
    const [bolProduct, setBolProduct] = useState(custodyRequest.bolProduct);
    const [cashManProduct, setCashManProduct] = useState(custodyRequest.cashManProduct);
    const [disclaimCash, setDisclaimCash] = useState(false);

    const [errors, setErrors] = useState({});
    const [fileId, setFileId] = useState('');


    const entityDocsRef = React.useRef(null);

    const dispatch = useDispatch();

    const {bolProfiles, bolProfileOperators, onFetchOperators} = props;


    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    };


    useImperativeHandle(ref, () => {
        return {
            validateDocs: validateDocs
        };
    });

    const onBusOnlineClick = (isSelected) => {
        console.log("bol clicked" + isSelected);
        setBolProduct({...bolProduct, enabled: isSelected});
    };

    const onCashManagementClick = (isSelected) => {
        setCashManProduct({...cashManProduct, enabled: isSelected});
        if (isSelected) {
            setDisclaimCash(true);
        }
    };


    const onCashManChange = (e, attribute) => {
        setCashManProduct(_.extend({}, cashManProduct, {[attribute]: (e.target.value)}));
    };

    const onCashManDicslaimer = (e, attribute) => {
        setCashManProduct(_.extend({}, cashManProduct, {[attribute]: (e.target.value)}));
        setDisclaimCash(false);
    };

    const onChangeBolProduct = (bolProduct) => {
        setBolProduct(bolProduct);
    };

    const saveAndContinue = (event) => {
        // saveDetails(event, true);
        const {history} = props;
        const onSuccess = () => history.push('/saveapplication');
        dispatch(saveCustodyManagedFund(appId, custodyRequest, 'saveContinue', onSuccess));
    };


    const validateForm = () => {
        const options = {fullMessages: false};
        const bolConstraints = _.extend({
            bolProfileId: {
                presence: true, length: {minimum: 1, message: 'required'}
            }               
        });
        const bolErrors = validate(bolProduct, bolConstraints, options);
        setErrors(bolErrors);
        return _.isEmpty(bolErrors);
    };

    const saveDetails = (event, saveAndContinue) => {

        custodyRequest.documents = entityDocs;
        custodyRequest.bolProduct = bolProduct;

        const isDocsValid = entityDocsRef.current && entityDocsRef.current.validateDocs();
        const {history} = props;
        if (bolProduct && bolProduct.enabled) {
            if (validateForm()){
                const onProceed = () => history && history.push(managedFundRoutes.custodyConfirmAuthorizers);
                dispatch(saveCustodyManagedFund(appId, custodyRequest, 'UserTask_DocumentUpload', onProceed));
            }
        }
        if(bolProduct && bolProduct.enabled == false && isDocsValid) {
            console.log(JSON.stringify(custodyRequest));
            const onProceed = () => history && history.push(managedFundRoutes.custodyConfirmAuthorizers);
            dispatch(saveCustodyManagedFund(appId, custodyRequest, 'UserTask_DocumentUpload', onProceed));
        }
    };


    const handleDocUpdate = (documents) => {
        setEntityDocs(documents);
    };


    return (
        <div>
            <form className="page-container">
                <div className="page-main-section">
                    <div className="container" style={{padding: 0}}>
                        <div className='row' style={{margin:'0px'}}>
                            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <CashEntityDocsComponent
                                    ref={entityDocsRef}
                                    applicationId={appId}
                                    entityDocs={entityDocs}
                                    handleDocUpdate={handleDocUpdate}
                                />
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">
                                <div className="card-container-form">
                                    <div className="section-title">
                                        <span>Add account to the following:</span>
                                    </div>
                                    <div className="input-group greyText fontSize">
                                        <p>Select from the list below if your need you account to be added to them:</p>
                                    </div>

                                    <div>
                                        <Row style={{margin: '0'}}>
                                            <span style={{paddingRight: '1em'}}>Investor Services Online</span>
                                            <ToggleButtonGroup type="radio" name="bolOptions" id={'bolOptions'}
                                                               onChange={onBusOnlineClick}
                                                               value={bolProduct && bolProduct.enabled}>
                                                <ToggleButton id={'bolYes'} value={true}>Yes</ToggleButton>
                                                <ToggleButton id={'bolNo'} value={false}>No</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Row>
                                    </div>
                                    {
                                        (bolProduct && bolProduct.enabled) &&

                                        <BolSetup
                                            bolProduct={bolProduct}
                                            bolProfiles={bolProfiles}
                                            bolProfileOperators={bolProfileOperators}
                                            errors={errors}
                                            onFetchOperators={onFetchOperators}
                                            onChange={onChangeBolProduct}
                                            resetErrors={resetError}
                                        />
                                    }
                                    <hr/>

                                    {/* <div className="form-group">
                                    <div>
                                        <Row style={{margin:'0'}}>
                                            <span style={{paddingRight:'1em'}}>Cash Management</span>
                                            <ToggleButtonGroup type="radio" name="cashmanOptions" id="cashmanOptions" onChange={onCashManagementClick}
                                                value={additionalProducts && additionalProducts.cashManagement}>
                                                <ToggleButton id={'cashmanYes'} value={'YES'}>Yes</ToggleButton>
                                                <ToggleButton id={'cashmanNo'} value={'NO'}>No</ToggleButton>
                                            </ToggleButtonGroup>
                                        </Row>
                                    </div>
                                    {
                                        (additionalProducts && additionalProducts.cashManagement ==='YES') &&
                                        <div>
                                            <br/>
                                            <FormField className="form-group" id="cashman"
                                                        error={errors && errors.cashManGroupNumber}>
                                                <label>Cashman Group Number</label>

                                                <input
                                                    className="form-control"
                                                    id="cashManGroupNumber"
                                                    maxLength={5}
                                                    onChange={(event) => {
                                                        errors && errors.cashManGroupNumber && resetError('cashManGroupNumber');
                                                        onAdditionalChange(event, 'cashManGroupNumber');
                                                    }}
                                                    type="text"
                                                    value={additionalProducts && additionalProducts.cashManGroupNumber || ''}
                                                />
                                            </FormField>
                                        </div>
                                    }

                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-footer-section" style={{width: '80%'}}>
                    <ButtonsComponent
                        history={props.history}
                        onNext={saveDetails}
                        onSaveAndContinue={saveAndContinue}
                        prevPage={managedFundRoutes.custodyInstructingParty}
                    />
                </div>
            </form>
        </div>

    );
});
export default custodyIsOnlineAndDocUpload;


