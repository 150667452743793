export default {
    products: {},
    announcements:{},
    importantNotices:{},
    entities: {},
    newEntity: {},
    user: {},
    application: {application: {legalEntity: {}}},
    applications: {},
    pandingApplications: {},
    accounts: {},
    copies: {copies: {}},
    authorisers: {},
    authRules: {},
    delegatedList: {},
    individual: {},
    entityInfo: {},
    impersonation: {},
    impersonationList: [],
    accountsEmpty: false,
    error: null,
    systemError: {},
    kyc: {saved: false},
    support: {},
    remediation: {},
    signatories: {},
    businessOnline: {bolRequest: {transactionDetails: {}, authorisation: {}}},
    tpfa: {application: {additionalProducts: {businessOnline: '', cashManagement: ''}}},
    signatoryMaintenance: {maintenanceRequest: {entityDTO: {goldTierId: null}}},
    tracking: {
        deviceType: dtm_deviceCheck,
        loginStatus: 'guest',
        numSearchResults: '',
        pageCategory: '',
        pageName: '',
        websiteNameCode: 'AO',
        businessUnit: 'CIB',
        websiteName: 'Account Opening',
        pageSubSection1: '',
        pageSubSection2: '',
        pageSubSection3: '',
        searchTerm: '',
        siteCountry: 'South Africa',
        siteLanguage: 'English',
        userRegistrationSuccess: false,
        applicationName: '',
        applicationStart: false,
        applicationComplete: false
    }
};
