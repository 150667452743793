import axios from 'axios';
import _ from 'lodash';
import * as types from './actionTypes';
import {API_ROOT} from '../api-config';
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from './appLoading';

const NEW_BOL_PROFILE_URL = API_ROOT + '/api/bol';
const BOL_PROFILES_URL = API_ROOT + '/api/bol-profiles';
const ENTITIES_URL = API_ROOT + '/api/legal-entities';

const NEW_PROFILE_KEYS_MAIN =  [
    'customerDetails', 'profileDetails', '', 'tokenDetails', 'bankAccountsDetails', 'usersDetails', 'channelDetails', 'productsAndServices'
];

const NEW_PROFILE_KEYS_INFO =  ['id', 'username', 'status', 'description', 'createdAt', 'modifiedAt'];

export function onboardNewBolProfile(newBolProfile, onSuccess) {
    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const newProfile = _.pick(newBolProfile, NEW_PROFILE_KEYS_MAIN);
            const applicationInfo = _.pick(newBolProfile, NEW_PROFILE_KEYS_INFO);
            const responseData = await axios.put(NEW_BOL_PROFILE_URL + '/complete', {newProfile, ...applicationInfo}).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.ONBOARD_NEW_ENTITY_SUCCESS, newBolProfile});
                onSuccess && onSuccess();
            } else {
                console.log('entity.saveNewEntityInfo.errorMessage ====>', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Could\'t onboard new entity.'});
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log('entity.onboardNewEntity.error ===>', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}


export function saveNewBolProfile(newBolProfile, onProceed, onComplete, canUpdateBolProfile = true, isFirstSave = false) {
    return async dispatch => {
        try {
            onProceed && onProceed();
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const newProfile = _.pick(newBolProfile,NEW_PROFILE_KEYS_MAIN);

            let responseData;
            if (isFirstSave) {
                responseData = await axios.post(NEW_BOL_PROFILE_URL, {newProfile}).then(response => response.data);
            } else {
                const applicationInfo = _.pick(newBolProfile, NEW_PROFILE_KEYS_INFO);
                responseData = await axios.put(NEW_BOL_PROFILE_URL, {newProfile, ...applicationInfo}).then(response => response.data);
            }

            if (responseData && responseData.success) {
                const applicationInfo = _.pick(responseData && responseData.data, NEW_PROFILE_KEYS_INFO);
                const update = _.extend({}, applicationInfo, newProfile);
                const action = _.extend({
                    type: types.SAVE_NEW_BOL_PROFILE_SUCCESS,
                }, canUpdateBolProfile && {newBolProfile: update});
                dispatch(action);
                onComplete && onComplete(true);
            } else {
                console.log('bol.saveNewBolProfile.errorMessage ====>', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t save new bol profile details.'});
                onComplete && onComplete();
            }
        } catch (error) {
            console.log('bol.saveNewBolProfile.error ===>', error);
            authCheck(dispatch, error);
            onComplete && onComplete();
        }
    };
}

export function fetchEntityInfo(goldTierId, finish,onSuccess = null, onError = null) {
    return async (dispatch) => {
        try {
            dispatch({type: types.REMOVE_SEARCH_RESULTS});

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(ENTITIES_URL + '/search?goldtierId=' + goldTierId).then(response => response.data);

            finish && finish();
            if (responseData.success) {
                dispatch({type: types.SEARCH_ENTITY_SUCCESS, entityInfo: responseData.data});
                onSuccess && onSuccess(responseData.data);
            } else {
                console.log('entity.fetchEntityInfo.errorMessage ===>', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t retrieve entity information.'});
            }
        } catch (error) {
            console.log('entity.fetchEntityInfo.error ===>', error);
            const status = error.response && error.response.status;
            if (status === 404) {
                console.log(status);
                onError && onError();
            }
            authCheck(dispatch, error);
        }
    };
}

export function fetchBolProfiles(cif) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(`${BOL_PROFILES_URL}?cif=${cif}`).then(response => response.data);

            if (responseData.success) {
                const hasProfiles = _.size(responseData.data) > 0;
                const profiles = hasProfiles ? responseData.data : [];
                dispatch({type: types.FETCH_BOL_PROFILES_SUCCESS, profiles});
            } else {
                console.log('fetchBolProfiles.errorMessage ===> ', responseData.message);
            }
        } catch (error) {
            console.log('fetchBolProfiles.errorMessage ===> ', JSON.stringify(error));
            authCheck(dispatch, error);
        }
    };
}

export function fetchBolOperators(profileId) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(`${BOL_PROFILES_URL}/${profileId}`).then(response => response.data);

            if (responseData.success) {
                const hasOperators = _.size(responseData.data) > 0;
                const operators = hasOperators ? responseData.data : [];
                dispatch({type: types.FETCH_BOL_OPERATORS_SUCCESS, operators});
            } else {
                console.log('fetchBolProfiles.errorMessage ===> ', responseData.message);
            }
        } catch (error) {
            console.log('fetchBolProfiles.errorMessage ===> ', JSON.stringify(error));
            authCheck(dispatch, error);
        }
    };
}

export function clearBolProfileOperators() {
    return (dispatch) => {
        dispatch({type: types.CLEAR_BOL_PROFILE_OPERATORS});
    };
}

export function onChangeValue(objName, attribute, value) {
    return (dispatch) => {
        dispatch({type: types.ON_CHANGE_BOL_VALUE, objName, attribute, value});
    };
}
