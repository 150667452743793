import React from 'react';
import _ from "lodash";
import {Document} from "react-pdf/build/entry.webpack";
import ReactLoading from "react-loading";
import {Page} from "react-pdf";
import validate from "validate.js";

import FormField from "./formField/FormField";
import {API_ROOT} from "../api-config";
import FileUpload from "./FileUpload";


export default class DocumentViewer extends React.Component {
    state = {
        errors: {},
        fileUrl: null,
        showFooterUpload: true,
        uploading: false,
        loading: true,
        fileNumPages: 0,
    };

    componentWillMount() {
        const {fileId} = this.props;
        this.generateFileUrl(fileId);
    }

    generateFileUrl = (documentId) => {
        if (documentId && documentId.indexOf('{') > -1) {
            documentId = documentId.match(/{.+?}/g).map(function (x) {
                return x.slice(1, -1)
            });
        }
        let fileUrl = null;
        if (documentId) {
            const httpHeaders = {'Authorization': `Bearer ` + localStorage.getItem('access_token')};
            const url = `${API_ROOT}/api/documents/${documentId}`;
            fileUrl = {url, httpHeaders}
        }
        this.setState({fileUrl, showFooterUpload: true, documentId})
    };

    onHandleDocumentLoadSuccess = (docload) => {
        const fileNumPages = docload && docload.numPages;
        this.setState({fileNumPages, loading: false});
    };

    onHandleUpload = (base64, inProgress, onComplete) => {
        const {onUpload} = this.props;
        const {errors} = this.state;
        errors && errors.fileUrl && this.handleResetErrors('fileUrl');
        this.setState({uploading: true})
        const onSuccess = documentId => {
            this.setState({uploading: false});
            this.generateFileUrl(documentId)
        };
        onUpload && onUpload(base64, inProgress, onComplete, onSuccess);
    };


    onHandleRemoveDocument = () => {
        const {errors} = this.state;
        errors && errors.fileUrl && this.handleResetErrors('fileUrl');
    };

    handleResetErrors = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors});
    };

    validateForm = () => {
        const {fileUrl} = this.state;
        const options = {fullMessages: false};

        const constraints = {
            fileUrl: {presence: true},
        };
        const errors = validate({fileUrl}, constraints, options);
        this.setState({errors});
        return _.isEmpty(errors);
    };

    onHandleSubmit = () => {
        if (this.validateForm()) {
            const {documentId} = this.state;
            const {documentType, onClose, onVerify} = this.props;
            const onSuccess = () => onClose && onClose();
            onVerify && onVerify(documentId, documentType, onSuccess);
        }
    };

    _renderHeader = () => {
        const {documentType} = this.props;
        return (
            <div>
                <div className="product-heading">
                    {documentType}
                </div>
                <div className="title-gradient"/>
                <div>Please upload a new document or scroll down to the bottom of the document to verify the document.</div>
            </div>
        )
    };

    _renderWhileLoading = () => (
        <div className="flexColumn">
            <ReactLoading type="spokes" color="#444"/>
            <span>Loading Document</span>
        </div>
    );

    _renderFileUpload = (message) => {
        const {documentType} = this.props;
        const {errors} = this.state;

        return (
            <div className="flexColumn" style={{alignItems: "flex-start", width: "100%"}}>
                {message && <span style={{marginTop: "1em"}}>{message}</span>}
                <FormField
                    className="form-group"
                    style={{display: "flex", flexDirection: "column", width: "100%", marginTop: "1em"}}
                    error={errors && errors.fileUrl ? ["Document required"] : null}>
                    <label>{`Upload Valid ${documentType}`}</label>
                    <FileUpload
                        documentType={documentType}
                        onUpload={this.onHandleUpload}
                        onRemoveDocument={this.onHandleRemoveDocument}
                    />
                </FormField>
            </div>
        )
    };

    _renderFooter() {
        const {isUpload} = this.props;
        return (
            <div className="action-button-container">
                {this._renderFileUpload()}
                <div style={{margin: 10}}/>
                <button
                    className="action-btn-secondary"
                    onClick={this.onHandleSubmit}
                    disabled={this.state.uploading || this.state.loading}
                >{isUpload ? "Confirm" : "Verify"}
                </button>
            </div>
        )
    }

    handleError = () => {
        const {fileId, documentType} = this.props;

        return fileId ? fileId.split(".").pop() === 'pdf' ? <span>No {documentType} found for this entity.</span>
            : <span>{documentType} is not a PDF and cannot be viewed here.</span> :
            <span>No {documentType} found for this entity.</span>;
    }

    render() {
        const {documentType} = this.props;
        const {fileNumPages, fileUrl} = this.state;
        return (
            <div>
                {this._renderHeader()}
                <div className={"document-card"}>
                    <Document
                        className='floatCenter list-scroller'
                        file={fileUrl}
                        loading={(this._renderWhileLoading)()}
                        error={(this.handleError)()}
                        noData={""}
                        onLoadSuccess={this.onHandleDocumentLoadSuccess}
                        style={{alignContent: 'center'}}
                    >
                        {
                            Array.from(
                                new Array(fileNumPages),
                                (el, index) => (
                                    <Page size="A4"
                                          key={`page_${index + 1}`}
                                          pageNumber={index + 1}
                                    />
                                ),
                            )
                        }
                    </Document>
                </div>
                {this._renderFooter()}
            </div>
        )
    }
}
