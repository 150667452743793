import React from "react";
import _ from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ReactLoading from 'react-loading';
import validate from 'validate.js'

import * as actions from '../../actions/globalMarket';
import {getEntityDetails} from '../../actions/entity';
import {globalMarketAccountRoutes} from '../../routers/routes'
import ButtonsComponent from "../ButtonsComponent";
import FormField from '../formField/FormField'


const labelClassList = "col-xs-12 col-sm-4 col-md-4";
const inputClassList = "col-xs-12 col-sm-8 col-md-8";

class ConfirmEntityPage extends React.Component {

    constructor(props) {
        super(props);
        const typeOfEntity = props.selectedEntity && props.selectedEntity.typeOfEntity ? props.selectedEntity.typeOfEntity : 'Private Company (Pty LTD)';
        const selectedEntity = _.extend({}, props.selectedEntity, {typeOfEntity})
        this.state = {selectedEntity, detailsUpdated: false, addressUpdated: false, errors: {}};
        this.handleChange = this.handleChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.saveDetails = this.saveDetails.bind(this);
        this.resetError = this._resetError.bind(this);
    }

    componentWillMount() {
        this._init();
    }

    componentWillReceiveProps(nextProps, prevProps) {
        this.setState({selectedEntity: nextProps.selectedEntity});
    }

    _init() {
        const {actions, selectedEntity} = this.props;
        actions.getEntityDetails(selectedEntity.goldTierId);
    }

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    _validateForm() {
        const {selectedEntity} = this.state;
        const options = {fullMessages: false};

        const constraints = {
            registeredName: {presence: true, length: {minimum: 1, message: 'required'}},
        };


        const selectedEntityErrors = validate(selectedEntity, constraints, options);
        const errors = _.extend({}, selectedEntityErrors);

        this.setState({errors});
        return _.isEmpty(errors);
    }

    saveDetails(event) {
        event.preventDefault();
        const {selectedEntity} = this.state;

        if (this._validateForm()) {
            const {actions, application, impersonation, history, user} = this.props;
            const onProceed = () => {
                console.log ("running onProceed");
                const onComplete = () => {
                    console.log ("running onComplete");
                    application.fetchingCustomers = false;
                    const onDone = () => {
                        console.log ("running onDone");
                        history.push(globalMarketAccountRoutes.instructions);
                        };
                    actions.update (application, onDone);
                }
                actions.fetchCustomers(selectedEntity.cif, application, onComplete);
            };

            if (application) {
                application.requestedBy = user.username;
                application.notificationEmailAddress = user.email;
                application.fullName = user.displayName;
                application.applicationStatus = {status: globalMarketAccountRoutes.instructions};
            }

            console.log ("application", application);
            console.log ("selectedEntity", selectedEntity);
            console.log ("impersonation", impersonation);
            application.fetchingCustomers = true;
            actions.processGMApplication(selectedEntity, application, impersonation, false, onProceed);
        }
    }

    handleChange({target}, attribute) {
        const selectedEntity = _.extend({}, this.state.selectedEntity, {
                [attribute]: _.trimStart(target.value),
                detailsUpdated: true
            }
        );
        this.setState({selectedEntity});
    }

    handleAddressChange({target}, attribute) {
        const {selectedEntity} = this.state;
        const address = _.extend({}, selectedEntity.address, {[attribute]: _.trimStart(target.value)});
        const selectedEntityChanges = _.extend({}, selectedEntity, {address, addressUpdated: true});
        this.setState({selectedEntity: selectedEntityChanges});
    }

    render() {
        const {loading, history, systemError, entityData, address} = this.props;
        const {errors, selectedEntity} = this.state;
        if (systemError && systemError.show) return null;
        return (
            <div className="page-container">
                <EntityDetailsCard
                    errors={errors}
                    handleAddressChange={this.handleAddressChange}
                    loading={loading}
                    onChange={this.handleChange}
                    onResetError={this.resetError}
                    selectedEntity={selectedEntity}
                    entityData={entityData}
                    address={address}
                />
                <div className="page-footer-section">
                    <ButtonsComponent
                        history={history}
                        onNext={this.saveDetails}
                        hidePrevious={false}
                        prevPage={globalMarketAccountRoutes.entity}
                    />
                </div>
            </div>
        );
    }
}

const EntityDetailsCard = (props) => {
    const {errors, handleAddressChange, loading, onChange, onResetError, entityData, address} = props;
    if (loading) return <ReactLoading type="spokes" color="#444"/>

    return (
        <div className="page-main-section">
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        <span>Entity Details</span>
                    </div>

                    <FormField className="form-group" id="name" error={errors && errors.name}>
                        <label htmlFor="name">Registered Name</label>
                        <input
                            type="text" readOnly={true}
                            className="form-control"
                            id="name"
                            value={(entityData && entityData.name) ? entityData.name : ""}
                            onChange={(event) => {
                                errors && errors.name && onResetError && onResetError('name');
                                onChange(event, 'name')
                            }}
                        />
                    </FormField>

                    <div className="form-group">
                        <label htmlFor="registrationNumber">Registration Number</label>
                        <input
                            type="text" readOnly={true}
                            className="form-control"
                            id="registrationNumber"
                            value={(entityData && entityData.registrationNumber) ? entityData.registrationNumber : ""}
                            onChange={(event) => onChange(event, 'registrationNumber')}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="businessType">Business Type</label>
                        <input
                            type="text" readOnly={true}
                            className="form-control"
                            id="businessType"
                            value={(entityData && entityData.businessType) ? entityData.businessType : ""}
                            onChange={(event) => onChange(event, 'businessType')}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="natureOfBusiness">Nature Of Business</label>
                        <input
                            type="text" readOnly={true}
                            className="form-control"
                            id="natureOfBusiness"
                            value={(entityData && entityData.natureOfBusiness) ? entityData.natureOfBusiness : ""}
                            onChange={(event) => onChange(event, 'natureOfBusiness')}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                            type="text" readOnly={true}
                            className="form-control"
                            id="phoneNumber"
                            value={(entityData && entityData.phoneNumber) ? entityData.phoneNumber : ""}
                            onChange={(event) => onChange(event, 'phoneNumber')}
                        />
                    </div>
                </div>
            </div>

            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        <span>Registered Address</span>
                    </div>

                    <FormField className="form-group" id="streetAddressLine1F"
                               error={errors && errors.streetAddressLine1}>
                        <label htmlFor="streetAddressLine1">Address Line 1</label>
                        <input readOnly={true}
                               className="form-control"
                               id="streetAddressLine1"
                               onChange={(event) => {
                                   errors && errors.streetAddressLine1 && onResetError && onResetError('streetAddressLine1');
                                   handleAddressChange(event, 'streetAddressLine1')
                               }}
                               type="text"
                               value={(address && address.streetAddressLine1) ? address.streetAddressLine1 : ""}

                        />
                    </FormField>

                    <FormField className="form-group" id="streetAddressLine2F"
                               error={errors && errors.streetAddressLine2}>
                        <label htmlFor="streetAddressLine2">Address Line 2</label>
                        <input readOnly={true}
                               className="form-control"
                               id="streetAddressLine2"
                               onChange={(event) => {
                                   errors && errors.streetAddressLine2 && onResetError && onResetError('streetAddressLine2');
                                   handleAddressChange(event, 'streetAddressLine2')
                               }}
                               type="text"
                               value={(address && address.streetAddressLine2) ? address.streetAddressLine2 : ""}

                        />
                    </FormField>

                    <FormField className="form-group" id="line2" error={errors && errors.suburb}>
                        <label htmlFor="registeredAddressLine2">Suburb</label>
                        <input readOnly={true}
                               className="form-control"
                               id="registeredAddressLine2"
                               onChange={(event) => {
                                   errors && errors.suburb && onResetError && onResetError('suburb');
                                   handleAddressChange(event, 'suburb')
                               }}
                               type="text"
                               value={(address && address.suburb) ? address.suburb : ""}
                        />
                    </FormField>

                    <FormField className="form-group" id="regCity" error={errors && errors.crcCityId}>
                        <label htmlFor="crcCityId">City</label>
                        <input readOnly={true}
                               type="text"
                               className="form-control"
                               id="crcCityId"
                               value={(address && address.crcCityId) ? address.crcCityId : ""}
                               onChange={(event) => {
                                   errors && errors.crcCityId && onResetError && onResetError('crcCityId');
                                   handleAddressChange(event, 'crcCityId')
                               }}
                        />
                    </FormField>

                    <FormField className="form-group" id="crcRegionIdF" error={errors && errors.crcRegionId}>
                        <label htmlFor="stateOrProvince">State/Province</label>
                        <input readOnly={true}
                               type="text"
                               className="form-control"
                               id="crcRegionId"
                               value={(address && address.crcRegionId) ? address.crcRegionId : ""}
                               onChange={(event) => {
                                   errors && errors.crcRegionId && onResetError && onResetError('crcRegionId');
                                   handleAddressChange(event, 'crcRegionId');
                               }}
                        />
                    </FormField>

                    <FormField className="form-group" id="zipCodeF" error={errors && errors.zipCode}>
                        <label htmlFor="zipCode">Zip/Postal Code</label>
                        <input readOnly={true}
                               type="text"
                               className="form-control"
                               id="zipCode"
                               value={(address && address.zipCode) ? address.zipCode : ""}
                               onChange={(event) => {
                                   errors && errors.zipCode && onResetError && onResetError('zipCode');
                                   handleAddressChange(event, 'zipCode')
                               }}
                        />
                    </FormField>

                    <FormField className="form-group" id="crcCountryIdF" error={errors && errors.country}>
                        <label htmlFor="crcCountryId">Country</label>
                        <input readOnly={true}
                               type="text"
                               className="form-control"
                               id="crcCountryId"
                               value={(address && address.crcCountryId) ? address.crcCountryId : ""}
                               onChange={(event) => {
                                   errors && errors.country && onResetError && onResetError('crcCountryId');
                                   handleAddressChange(event, 'crcCountryId')
                               }}
                        />
                    </FormField>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps({globalMarket, kyc, loading, systemError, impersonation, user}, ownProps) {

    const address = kyc.entityData && kyc.entityData.addresses && kyc.entityData.addresses[0];
    const application = globalMarket.application ? globalMarket.application : {};
    
    return {
        application: application,
        impersonation: impersonation ? impersonation : {},
        selectedEntity: application.legalEntity,
        entityData: kyc.entityData,
        address: address,
        user: user && user.user,
        loading/*,
        systemError*/
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, getEntityDetails}, dispatch)}
}


export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEntityPage);