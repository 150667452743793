import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function userApplicationsReducer(state = initialState.applications, action) {
    switch(action.type) {
        case types.LOAD_USER_APPLICATIONS_SUCCESS:
            return {...state, applications: action.applications};

        default:
            return state;
    }
}