import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function userReducer(state = initialState.user, action) {
    switch(action.type) {
        case types.LOAD_PROFILE_SUCCESS:
            return {...state, user: action.user};

        case types.SAVE_USER_APPLICATIONS_SUCCESS: {
            return state;
        }
        case types.LOGOUT_SUCCESS:
            return  {...state, user: action.user};

        default:
            return state;
    }
}