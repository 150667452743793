import React from "react";
import _ from "lodash";
import {withRouter} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";

import {
    ACCOUNT_OPENING_STEPS,
    DIGITAL_CREDIT_STEPS,
    NEW_BOL_DOMESTIC_PRFOILE_ON_TAKE,
    ONBOARDING_NEW_ENTITY_STEPS,
    CUSTODY_ACCOUNT_STEPS,
    MANAGED_FUND_CASH_ACCOUNT_STEPS,
    MANAGED_FUND_CUSTODY_ACCOUNT_STEPS,
    MANAGED_FUND_CASH_AND_CUSTODY_ACCOUNT_STEPS,
    ELECTRONIC_INDEMNITY_STEPS,
    GLOBAL_MARKET_STEPS,
    LEGAL_REMEDIATION,
    SHARIAH_BCA_STEPS,
    SAVINGS_ACCOUNT_STEPS,
    BUSINESS_ONLINE_PROFILE, TPFA, KYC, SIGNATORY,
    

} from '../data/workFlowSteps'
import * as actions from "../actions/delegation";

const MilestoneShape = ({isCurrentPath, isCompleted, onSelect, path, position,}) => {
    const backgroundColor = isCompleted ? '#0033AA' : "#ededed";
    const color = isCompleted ? 'white' : "black";
    const style = _.extend({backgroundColor}, !isCurrentPath && {color});
    return (
        <div
            className={isCurrentPath ? "step-shape-circle-highlighted" : "step-shape-circle"}
            style={style}
            onClick={(e) => onSelect(e, path)}
            role="button"
        >{position}</div>
    )
};

class StepIndicatorNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {progressIndex: 1, steps: [], stepPath: null, lastHighestProgressIndex: -1, breadCrumb: ""};
        this.pathChangeListener = null;
    }

    componentWillMount() {
        this.pathChangeListener = this.onPathChangeListener();
    }

    componentDidMount() {
        this.setDefaults();
    }

    componentWillUnmount() {
        if (this.pathChangeListener) {
            this.pathChangeListener();
        }
    }

    setDefaults = () => {
        const currentPath = window.location.pathname;
        const stepPath = `/${currentPath.split("/")[1]}`;

        this.setState({currentPath, stepPath});
        this.determineSteps(currentPath);
        _.defer(() => this._determineProgress())

    };

    onPathChangeListener = () => {
        const {history} = this.props;

        history.listen(({pathname}, action) => {

            this.determineSteps(pathname);
            _.defer(() => this._determineProgress())
        });
    };


    determineSteps = (currentPath) => {


        if (currentPath) {
            const stepPath = `/${currentPath.split("/")[1]}`;
            if (stepPath !== this.state.stepPath) {
                const steps = this.getSteps(stepPath);
                this.setState({steps, stepPath})

            }

        }
    };

    setBreadCrumb = (breadCrumb) => {

        this.setState({breadCrumb});
    };

    getSteps = (stepPath) => {
        switch (stepPath) {
            case "/bol":
                this.setBreadCrumb("New Business Online Profile");
                return NEW_BOL_DOMESTIC_PRFOILE_ON_TAKE;
            case "/onboard":
                this.setBreadCrumb("New Subsidiary / Entity");
                return ONBOARDING_NEW_ENTITY_STEPS;
            case "/new-account":
                this.setBreadCrumb("Account Opening");
                return ACCOUNT_OPENING_STEPS;
            case "/custodyaccount":
                this.setBreadCrumb("Custody Account");
                return CUSTODY_ACCOUNT_STEPS;

            case "/managedfund":
                this.setBreadCrumb("Managed Fund");
                return [];

            case "/managedFund":
                this.setBreadCrumb("Cash Account");
                return MANAGED_FUND_CASH_ACCOUNT_STEPS;

            case "/cashAccount":
                this.setBreadCrumb("Cash Account");
                return MANAGED_FUND_CASH_ACCOUNT_STEPS;

            case "/managed-Fund":
                this.setBreadCrumb("Custody Account");
                return MANAGED_FUND_CUSTODY_ACCOUNT_STEPS;

            case "/custody-Account":
                this.setBreadCrumb("Custody Account");
                return MANAGED_FUND_CUSTODY_ACCOUNT_STEPS;

            case "/managed-fund-cash-and-custody":
                this.setBreadCrumb("Cash And Custody Account");
                return MANAGED_FUND_CASH_AND_CUSTODY_ACCOUNT_STEPS;
            
            case '/electronicIndemnity':
                this.setBreadCrumb('Electronic Indemnity');
                return ELECTRONIC_INDEMNITY_STEPS;

            case '/shariah-bca':
                this.setBreadCrumb('Shari’ah Business Current Account');
                return SHARIAH_BCA_STEPS;

            case '/savings-account':
                this.setBreadCrumb('Savings Account');
                return SAVINGS_ACCOUNT_STEPS;
                
            case "/credit":
                this.setBreadCrumb("Digital Credit");
                return DIGITAL_CREDIT_STEPS;
                
            case "/reports":
                this.setBreadCrumb("Reports");
                return [];
                
            case "/confirm-and-signatories-reports":
                this.setBreadCrumb("Confirmation and Signatories Letter");
                return [];

            case "/download-statement":
                this.setBreadCrumb("Statements");
                return [];

            case "/track":
                this.setBreadCrumb("Global Payments Tracking");
                return [];

            case "/globalmarket":
                this.setBreadCrumb("Global Markets");
                return GLOBAL_MARKET_STEPS;

            case "/rules":
                this.setBreadCrumb('Authorisation Rules');
                return [];

            case '/delegate':
                this.setBreadCrumb('Delegate Authority');
                return [];

            case '/maintenance':
                this.setBreadCrumb('Maintenance');
                return [];
            case '/kyc':
                this.setBreadCrumb('KYC Review');
                return KYC;
            case '/business-online':
                this.setBreadCrumb('Business Online Profile');
                return BUSINESS_ONLINE_PROFILE;
            case '/EBTC':
                this.setBreadCrumb('Legal Terms and Conditions Approval/Update');
                return LEGAL_REMEDIATION;
            case '/EBTC/agreements':
                this.setBreadCrumb('EBTC Update');
                return LEGAL_REMEDIATION;
            case "/EBTC/complete":
                this.setBreadCrumb('EBTC Update');
                return LEGAL_REMEDIATION;
            case "/TPFA":
                this.setBreadCrumb('TPFA Accounts Application');
                return TPFA;
            case '/signatory-maintenance':
                this.setBreadCrumb('Signatory Maintenance')
                return [];
           
            default:
                this.setBreadCrumb("");
                return [];
        }
    };

    onSelect = (path) => {
        const {history} = this.props;
        const {lastHighestProgressIndex, progressIndex, steps} = this.state;
        const onComplete = () => {
            const chunckedSteps = _.chunk(steps, lastHighestProgressIndex)[0];
            const isFound = _.find(chunckedSteps, step => (step.path === path));
            if (isFound) {
                history && history.push(path);
            }
        };
        this._determineProgress(onComplete);
    };

    _determineProgress(onComplete = null) {
        const {steps} = this.state;
        const hasSteps = _.size(steps) > 0;
        if (hasSteps) {
            const currentPath = window.location.pathname;
            const progressIndex = _.findIndex(steps, step => currentPath === step.path);
            const lastHighestProgressIndex = Math.max(progressIndex, this.state.lastHighestProgressIndex);
            this.setState({progressIndex: (progressIndex - 1), currentPath, lastHighestProgressIndex});
            onComplete && onComplete();
        }
    }

    // _renderBreadCrumb() {
    //     const { history } = this.props;
    //     const { breadCrumb, currentPath } = this.state;
    //     const isHomePath = (currentPath === '/');
    //     if (isHomePath) return null;
    //     // let subBreadCrumb = ['Confirmation and Signatories Letter','Statements'];
    //     return (
    //         <div className="section-heading" style={{ color: "#0033AA", fontSize: 14, padding: 0, margin: 0 }}>
    //             <span>
    //                 <span className="flat-button-primary" style={{ padding: 0, margin: 0 }}
    //                     onClick={() => history && history.push("/")}>Home </span>
    //                 {
    //                     ( breadCrumb === 'Cash Account' || breadCrumb === 'Custody Account' || breadCrumb === 'Cash And Custody Account') &&
    //                     <span className="flat-button-primary" onClick={() => history && history.push("/managedfund")}><i className="fa fa-angle-right" style={{ fontWeight: "bold", marginRight:"5px" }} /> Manage Fund </span>
    //                 }
    //                 {
    //                     ( breadCrumb === 'Confirmation and Signatories Letter' || breadCrumb === 'Statements') &&
    //                     <span className="flat-button-primary" onClick={() => history && history.push("/reports")}><i className="fa fa-angle-right" style={{ fontWeight: "bold", marginRight:"5px" }} /> Reports </span>
    //                 }
    //                 { breadCrumb && <i className="fa fa-angle-right" style={{ fontWeight: "bold" }} /> }
    //                 <span className="flat-button-primary" style={{ padding: 0, margin: 0, cursor:'default' }}> {breadCrumb} </span>
    //             </span>
    //         </div>
    //     )
    // }


    _renderBreadCrumb () {
        const { history } = this.props;
        const { breadCrumb, currentPath } = this.state;
        const isHomePath = (currentPath === '/');
        if (isHomePath) return null;
        const breadcrumbsMap = {
            //Manage fund 
            'Cash Account': { label: 'Manage Fund', path: '/managedfund' },
            'Custody Account': { label: 'Manage Fund', path: '/managedfund' },
            'Cash And Custody Account': { label: 'Manage Fund', path: '/managedfund' },

            //Reports
            'Confirmation and Signatories Letter': { label: 'Reports', path: '/reports' },
            'Statements': { label: 'Reports', path: '/reports' },
        };
        const renderBreadcrumbLink = (label, path) => (
            <span className="flat-button-primary" onClick={() => history.push(path)}>
                <i className="fa fa-angle-right" style={{ fontWeight: 'bold' }} /> {label}
            </span>
        );
        return (
            <div className="section-heading" style={{ color: "#0833AA", fontSize: 14, padding: 0, margin: 0 }}>
                <span style={{ padding: 0, margin: '5px', cursor: 'pointer' }} onClick={() => history.push('/')}>Home</span>

                {breadcrumbsMap[breadCrumb] && renderBreadcrumbLink(breadcrumbsMap[breadCrumb].label, breadcrumbsMap[breadCrumb].path)}
                
                {breadCrumb && (
                    <span>
                        <i className="fa fa-angle-right" style={{ fontWeight: 'bold' }} />
                        <span className="flat-button-primary" style={{ padding: 0, margin: '5px', cursor: 'default' }}>
                            {breadCrumb}
                        </span>
                    </span>
                )}
            </div>
        );
    }
       
    render() {
        const {currentPath, progressIndex, steps} = this.state;

        const isHomePath = (currentPath === '/');
        if (isHomePath) return null;
        const numberOfSteps = _.size(steps);
        const hasSteps = numberOfSteps > 1;
        const isAccountOpening = currentPath && currentPath.startsWith("/new-account");
        const isCustodyAccountOpening = currentPath && currentPath.startsWith("/custodyaccount");
        const shouldBeShown = _.find(steps, step => (step.path === currentPath)) || isAccountOpening || isCustodyAccountOpening;

        return (
            <div className="flexColumn card-container" style={
                {
                    padding: 0,
                    paddingLeft: 10,
                    paddingRight: 10,
                    margin: 0,
                }
            }>
                {<div className="flexRow" style={{
                    padding: 10,
                    margin: 0,
                    alignSelf: "flex-start"
                }}>
                    {this._renderBreadCrumb()}
                </div>}
                <div className="flexRow step-container" style={{margin: 0, padding: 0, height:'auto'}}>
                        {
                            hasSteps && shouldBeShown &&
                            _.map(steps, (step, index) => {
                                const isFirstStep = index === 0;
                                const isLastStep = index === (numberOfSteps - 1);
                                const isCompleted = index <= progressIndex;
                                const isPreviousComplete = (index - 1) <= progressIndex;
                                const path = step && step.path;
                                const isSelectable = step && step.isSelectable;
                                return (
                                    <div className="step-bar-container" key={`step-${index}`} style={{margin: 0, marginBottom: 15}}>
                                        <div className="step-bar"
                                            style={{backgroundColor: isFirstStep ? "transparent" : (isPreviousComplete ? '#0033AA' : "#DCDCDC")}}
                                        />
                                        <div className="step-bar"
                                            style={{backgroundColor: isLastStep ? "transparent" : (isCompleted ? '#0033AA' : "#DCDCDC")}}
                                        />
                                        <MilestoneShape
                                            isCompleted={isCompleted}
                                            isCurrentPath={currentPath === path}
                                            position={index + 1}
                                            onSelect={() => (isSelectable === undefined ? true : isSelectable) && this.onSelect(path)}
                                        />
                                        <div className="step-name">{step && step.name}</div>
                                    </div>
                                )
                            })
                        }
                </div>
            </div>
        )
    }
};

function mapStateToProps(state, ownProps) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators(actions, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StepIndicatorNav));
