import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector } from 'react-redux';
import validate from 'validate.js';
import 'react-select/dist/react-select.css';
import { electronicIndemnityRoutes } from '../../routers/routes';
import ButtonsComponent from '../ButtonsComponent';
import FormField from '../formField/FormField';
import { trackData } from '../../actions/analytics';
import { saveElectronicIndemnity , saveAndContinueElectronicIndemnityClauses } from "../../actions/electronicIndemnity";

export const electronicIndemnityClauses = (props) => {
    const indemnityRequest = useSelector(state => state.electronicIndemnity);
    const applicationId = useSelector(state => state.electronicIndemnity && state.electronicIndemnity.id);
    const [errors, setErrors] = useState({});
    const goldTierId = useSelector(state => state.electronicIndemnity && state.electronicIndemnity.legalEntity.goldTierId);
    const [postalAddress, setPostalAddress] = useState(indemnityRequest.postalAddress || '');
    const [emailAddress, setEmailAddress] = useState(indemnityRequest.emailAddress || '');
    const dispatch = useDispatch();
    const tagged = useState(true);
   
    const [agreeTerm, setAgreeterm] = useState({
        agree6: false
    });

    const handleAgreechecks = (attribute) => {
        setAgreeterm({
            ...agreeTerm,
            [attribute]: !agreeTerm[attribute]
        })
    }

    useEffect(() => {
        dispatch(trackData('Electronic Indemnity', 'New Account| Clauses| Step 2'));
    }, tagged);

    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, { [attribute]: null }));
    };

    const validateForm = () => {
        const options = {fullMessages: false};
        const constraints = {
            postalAddress: {presence: true, length: {minimum: 1, message: 'required'}},
            emailAddress: {presence: true,  email: true, length: {minimum: 1, message: 'required'}},
        };

        const clausesErrors = validate({postalAddress, emailAddress},constraints, options);
        let errors = _.extend({}, {clausesErrors});

        if (!agreeTerm.agree6) {
            errors = _.extend({}, errors, validate({agree6: null}, {agree6: {presence: true}}, options));
        }
        setErrors(errors);
        return _.isEmpty(clausesErrors);
    };

    const saveAndContinue = (event) => {
        // saveDetails(event, true);
        const { history } = props;

        indemnityRequest.postalAddress = postalAddress;
        indemnityRequest.emailAddress = emailAddress;
        let electronicIndemnityRequest = {postalAddress , emailAddress, goldTierId};
                   
        let nextPage = '/saveapplication';
        let saveAndContinue = true;
        const onProceed = () => history.push(nextPage);
        dispatch(saveAndContinueElectronicIndemnityClauses(applicationId, electronicIndemnityRequest, saveAndContinue, onProceed));
    };

    const saveDetails = (event) => { 
        event.preventDefault();
         if (validateForm() && agreeTerm.agree6) {
            const { history } = props;

            indemnityRequest.postalAddress = postalAddress;
            indemnityRequest.emailAddress = emailAddress;
            let electronicIndemnityRequest = {postalAddress , emailAddress, goldTierId};
                       
            let nextPage = electronicIndemnityRoutes.authorisedIndividual;
            const onProceed = () => history.push(nextPage);
            dispatch(saveElectronicIndemnity(applicationId, electronicIndemnityRequest, 'UserTask_Clauses', onProceed));
        }
        
    };

    return (
        <div>
            <form className="page-container">
                <div className="page-main-section">
                    <div className="container" style={{ minHeight: '50em' }}>
                        <div className="row">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <br />
                                <p className="text-color-blue" style={{textAlign:'justify'}}>Please consider the clauses below carefully as they limit the Bank’s liability and constitute an assumption of risk by you</p><br />
                                <p className="text-color-blue" style={{textAlign:'justify'}}>The selected entity has requested The Standard Bank of South Africa Limited (Registration Number 1962/000738/06)  (Bank)  to  act  in  accordance  with  any  instructions,  information,  or  other  communications  furnished  to  the  Bank,  or  which  reasonably  appear  to  have  been  furnished  to  the  Bank,  transmitted  via  electronic  means,  including  but  not  limited  to  emails  and  instructions  processed on any digital media platform, by you or by any purportedly duly authorised third party on your behalf, to the Bank (Instructions).</p>
                                <p className="text-color-blue" style={{textAlign:'justify'}}>You hereby authorise the Bank to accept and act on any Instruction/s which the  Bank  would  ordinarily  accept  if  the  Instructions  were  presented  in  an  original written format.</p>
                                <p className="text-color-blue" style={{textAlign:'justify'}}>The  Bank  will  not  accept  responsibility  for  any  loss  (consequential  or  otherwise)  incurred  by  you  as  a  result  of  the  Bank  acting  or  declining  to  act  (wholly  or  in  part)  on  Instructions  which  the  Bank  believes  to  have  emanated  from  you,  and  you  hereby  waive  any  and  all  claims  you  may  have now or in future against the Bank arising directly or indirectly from any losses or damages which you may suffer as a result of the Bank acting or declining to act (wholly or in part) on any Instruction.</p>
                                <p className="text-color-blue" style={{textAlign:'justify'}}>You acknowledge that electronic media such as emails and digital platforms are not secure or error-free channels of communication and acknowledge that it is not practical for the Bank to establish or verify the authenticity of all  Instructions.  The  fact  that  any  Instruction  (including  email  and  digital  Instructions)  may  later  be  shown  to  be  in  any  way  false,  inaccurate,  unauthorised or otherwise not authentic, will not be an impediment to the Bank’s rights in terms of this Electronic Instruction Indemnity.</p>
                                <p className="text-color-blue" style={{textAlign:'justify'}}>You  acknowledge  that  the  Bank  is  prepared  to  act  on  Instructions  which  purport to emanate (come from) you, or from any purportedly duly authorised third party on your behalf, provided that it receives this Electronic Instruction Indemnity accepted and signed by you.</p>
                            </div>
                            <br />
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <span className="text-color-blue"><b>You :</b> </span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">

                                <div className="row" style={{margin:0}}>
                                    <p className="text-color-blue" style={{textAlign:'justify'}}>
                                        1.  Agree that Instructions transmitted to the Bank may, as a result of the
                                        malfunction of equipment, the distortion of communication links and
                                        the like or for any other reason, be different to that intended or sent
                                        and you shall be bound thereby
                                    </p>
                                </div>

                                <div className="row" style={{margin:0}}>
                                    <p className="text-color-blue" style={{textAlign:'justify'}}>
                                        2.  Agree that the Bank is not to be held liable for errors or delays in
                                        transmissions, or the misinterpretation on receipt of an Instruction,
                                        save for where the Bank has acted with gross negligence or willful
                                        misconduct or fraudulently through itself and/or its employees
                                    </p>
                                </div>

                                <div className="row" style={{margin:0}}>
                                    <p className="text-color-blue" style={{textAlign:'justify'}}>
                                        3.  Waive any rights you may have or obtain now or in future against the
                                        Bank arising directly or indirectly from any losses or damages which
                                        you may suffer as a result of the Bank acting or declining to act
                                        (wholly or in part) on any Instruction, and you agree to indemnify
                                        the Bank in respect of any claims, demands or actions made against
                                        the Bank or losses or damages suffered by the Bank because it so
                                        acted or declined to act, excluding losses arising solely and
                                        exclusively from the gross negligence or fraudulent acts of the Bank and/or its employees;
                                    </p>
                                </div>

                                <div className="row" style={{margin:0}}>
                                    <p className="text-color-blue" style={{textAlign:'justify'}}>
                                        4.  Agree that in respect of Instructions regarding the transfer of money,
                                        whether the transfer is from any account in your name to any other
                                        account in your name or to any account in the name of any third party
                                        at any branch of the Bank or at any branch of any other bank, same
                                        day value may only be given if the Instruction is received by the Bank
                                        before the relevant cut-off time;
                                    </p>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div className="row" style={{margin:0}}>
                                    <p className="text-color-blue" style={{textAlign:'justify'}}>
                                        5.  Agree to implement and adhere to any procedures and/or restrictions
                                        imposed on you by the Bank from time to time regarding the sending
                                        of Instructions to the Bank;
                                    </p>
                                </div>

                                <div className="row" style={{margin:0}}>
                                    <p className="text-color-blue" style={{textAlign:'justify'}}>
                                        6.  Agree that the Bank will not be obliged to act on any Instruction and
                                        that it may at any time on written notice sent to you at:<br />
                                    </p>
                                </div>

                                <div className="row" style={{margin:0}}>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: 0 }}>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{ padding: 0}}>
                                            <p className="text-color-blue">Postal Address : </p>
                                        </div>
                                        <FormField className="col-lg-8 col-md-8 col-sm-12 col-xs-12" error={errors && errors.clausesErrors && errors.clausesErrors.postalAddress } >
                                            <textarea className="form-control" id="address" rows="5" cols="22"
                                                onChange={(event) => {
                                                    errors && errors.clausesErrors && errors.clausesErrors.postalAddress && resetError('postalAddress');
                                                    setPostalAddress(event.target.value);
                                                }}
                                                value={postalAddress}
                                                maxLength="250"
                                            />
                                        </FormField>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '10px 0px' }}>
                                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12" style={{ padding: 0}}>
                                            <p className="text-color-blue">Email Address : </p>
                                        </div>
                                        <FormField className="col-lg-8 col-md-8 col-sm-12 col-xs-12" error={errors && errors.clausesErrors && errors.clausesErrors.emailAddress}>
                                            <input type="text" id="emailAddress" className="form-control" 
                                                onChange={(event) => {
                                                    errors && errors.clausesErrors && errors.clausesErrors.postalAddress && resetError('emailAddress');
                                                    setEmailAddress(event.target.value);
                                                }}
                                                value={emailAddress}
                                            />
                                        </FormField>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '10px 0px' }}>
                                        <p className="text-color-blue">withdraw from the arrangements envisaged in this Electronic
                                            Instruction Indemnity.</p>
                                    </div>
                                </div>
                            </div>
                        </div>                  

                        {/* Updated code */}
                            <div style={{margin:'1% 22%'}}>
                                <FormField className="form-group"  error={errors && errors.agree6 && ["Clauses Should Be Accepted"]} >
                                    <div className="flexRow" style={{alignItems: "flex-start", textAlign: "left"}}>
                                        <div className="icon-container"  onClick={() => {
                                                errors && errors.agree6 && resetError('agree6');
                                                handleAgreechecks('agree6')
                                            }}>
                                            <i className={agreeTerm.agree6 ? "fa fa-check-square-o" : "fa fa-square-o"}
                                                style={{color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10}}
                                            />
                                        </div>
                                        <p style={{margin: 0}} className="text-color-blue"> I confirm that I have read and understood the clauses stated above and agree to comply. </p>
                                    </div>
                                </FormField>
                            </div>
                        {/* Updated code */}
                    </div>
                </div>
                <div className="page-footer-section" style={{ width: "80%" }}>
                    <ButtonsComponent
                        history={props.history}
                        onNext={saveDetails}
                        onSaveAndContinue={saveAndContinue}
                        prevPage={electronicIndemnityRoutes.electronicIndemnity}
                    />
                </div>
            </form>
        </div>
    );

};

// function mapStateToProps({application, entity, impersonation, user, electronicIndemnity}) {
//     const hasEntities = entity && entity.entities;
//     return {
//         electronicIndemnityRequest: electronicIndemnity && electronicIndemnity.electronicIndemnityRequest,
//         application: application.application,
//         entities: hasEntities ? entity.entities : [],
//         impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
//         user: user && user.user
//     }
// }

// function mapDispatchToProps(dispatch) {
//     return {
//         actions: bindActionCreators({...actions, saveElectronicIndemnity}, dispatch)
//     }
// }
// connect(mapStateToProps, mapDispatchToProps;
export default (electronicIndemnityClauses);

