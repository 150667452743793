import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';

const ConfirmDelete = (props) => {
    const {type, confirmAction, cancelAction, detail} = props;
    return (
        <Modal show={true} onHide={cancelAction}>
            <Modal.Header closeButton style={{padding:'0 10px'}}><h4>Confirm Delete</h4></Modal.Header>
            <Modal.Body>
                <h5>Are you sure you want to delete the following {type}?</h5>
                <h4>{detail}</h4>
            </Modal.Body>
            <Modal.Footer style={{padding:'0 10px'}}>
                <ButtonGroup style={{float:'right'}}>
                    <Button style={{width:'auto', margin:'5px', padding:'5px 10px'}} onClick={cancelAction}>Cancel</Button>
                    <Button style={{width:'auto', margin:'5px', padding:'5px 10px'}} bsStyle={'danger'}  onClick={confirmAction}>Confirm</Button>
                </ButtonGroup>
            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmDelete;