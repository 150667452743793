import React from "react";
import _ from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ReactLoading from 'react-loading';
import validate from 'validate.js'

import * as actions from '../../../actions/entity';

import {managedFundRoutes} from '../../../routers/routes'
import ButtonsComponent from "../../ButtonsComponent";
import FormField from '../../formField/FormField'
import SearchComponent from "../../SearchComponent";
import {saveManagedFund} from "../../../actions/cashManagedFund";
import DatePicker from "react-datepicker";
import moment from "moment";

class cashConfirmdetails extends React.Component {

    constructor(props) {
        super(props);
        const typeOfEntity = props.selectedEntity && props.selectedEntity.typeOfEntity ? props.selectedEntity.typeOfEntity : 'Private Company (Pty LTD)';
        const selectedEntity = _.extend({}, props.selectedEntity, {typeOfEntity})
        this.state = {selectedEntity, detailsUpdated: false, addressUpdated: false, shouldDisableInput: true, addressDisable :false, haveVatNumber:false, entityNotFoundErr : '', errors: {}};
        this.handleChange = this.handleChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.saveDetails = this.saveDetails.bind(this);
        this.resetError = this._resetError.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        
    }

    componentDidMount() {
        if(this.addressDisable = true && this.state.selectedEntity.registrationNumber){
            this.setState({addressDisable:true});
        }
    }

    componentWillReceiveProps(nextProps, prevProps) {
        this.setState({selectedEntity: nextProps.selectedEntity, entityNotFoundErr :[] });
    }

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    _validateForm() {
        const {selectedEntity, haveVatNumber} = this.state;
        const options = {fullMessages: false};

        const constraints = {
            registrationName: {presence: true, length: {minimum: 1, message: 'required'}},
            // registrationNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            registrationDate: {presence: true},
            ...(haveVatNumber && {vatNumber: {presence: true, length: {minimum: 10, message: 'VAT Number should be 10 digits.'}}}),
            // faisRequired: {presence: {message: 'required'}},
        };

        const addressConstraints = {
            street: {presence: true, length: {minimum: 1, message: 'required'},},
            city: {presence: true, length: {minimum: 1, message: 'required'}},
            province: {presence: true, length: {minimum: 1, message: 'required'}},
            postalCode: {presence: true, length: {minimum: 1, message: 'required'}},
            country: {presence: true, length: {minimum: 1, message: 'required'}},
            suburb: {presence: true, length: {minimum: 1, message: 'required'}}
        };

        const selectedEntityErrors = validate(selectedEntity, constraints, options);
        const addressErrors = validate(selectedEntity && selectedEntity.address, addressConstraints, options);
        const errors = _.extend({}, selectedEntityErrors, addressErrors);

        this.setState({errors});
        return _.isEmpty(errors);
    }

    saveAndContinue = (event) => {
        this.saveDetails(event, true);
    }

    saveDetails(event, saveAndContinue) {
        event.preventDefault();
        if (this._validateForm()) {
            const { history} = this.props;
            let nextPage = saveAndContinue ? '/saveapplication' :  managedFundRoutes.cashConfirmProductdetails;
            let task = saveAndContinue ? 'saveContinue' : 'UserTask_ManagedFundDetails';
            const onProceed = () => history.push(nextPage);
            let cashManagedFundRequest = this.props.cashManagedFundRequest;
            let vatNumber = this.state.selectedEntity.vatNumber;
            if (vatNumber || vatNumber === '') {
                vatNumber = vatNumber.trim() === '' ? null : vatNumber;
            }

            cashManagedFundRequest.managedFundEntity = {...this.state.selectedEntity, vatNumber: vatNumber};
            this.props.actions.saveManagedFund(this.props.appId, cashManagedFundRequest, task, onProceed);
        }
    }

    handleEntitySearch = (event) => {
        event.preventDefault();


        const onFinish = (data) => {

            if (!data){
                this.setState({selectedEntity:{}, entityNotFoundErr: 'Not found entity details', addressDisable:false });
                return;
            }
            const selectedEntity = _.extend({}, this.state.selectedEntity, {
                registrationName: data.registeredName,
                registrationNumber: data.registrationNumber,
                registrationDate: moment(data.registrationDate).format('YYYY-MM-DD'),
                cif: data.cif,
                goldtierId: data.goldtierId,
                vatNumber: data.vatNumber,
                address: {street : data.registeredAddress && data.registeredAddress.street,
                suburb:data.registeredAddress.suburb, city: data.registeredAddress.city,
                province: data.registeredAddress.province, postalCode: data.registeredAddress.postalCode,
                country: data.registeredAddress.country},
                detailsUpdated: true
                }
            );

            this.setState({selectedEntity, isSearchComplete: true, addressDisable:true,  entityNotFoundErr: '' });

        };
        const onError = (data) => {
            console.log(data ,'ERROR');
        };
        this.props.actions.fetchEntityInfo(this.state.selectedEntity.registrationNumber, onFinish, onFinish, onError);
    };


    handleChange({target}, attribute) {
        const selectedEntity = _.extend({}, this.state.selectedEntity, {
                [attribute]: _.trimStart(target.value),
                detailsUpdated: true
            }
        );

        this.setState({selectedEntity});
    }

    handleDateChange(event, attribute) {
        const selectedEntity = _.extend({}, this.state.selectedEntity, {
                [attribute]: moment(event).format('YYYY-MM-DD'),
                detailsUpdated: true
            }
        );

        this.setState({selectedEntity});
    }

    handleAssetValueChange = (value, attribute) => {
        console.log(value);
        const selectedEntity = _.extend({}, this.state.selectedEntity, {
                [attribute]: value,
                detailsUpdated: true
            }
        );

        this.setState({selectedEntity});
    };

    handleAddressChange({target}, attribute) {
        const {selectedEntity} = this.state;
        const address = _.extend({}, selectedEntity.address, {[attribute]: _.trimStart(target.value)});
        const selectedEntityChanges = _.extend({}, selectedEntity, {address, addressUpdated: true});
        this.setState({selectedEntity: selectedEntityChanges});
    }

    allowVatNumber = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^0-9]/g, '');
       // Limit the input to a maximum of 10 numbers
      value = value.slice(0, 10);
    
    this.setState({haveVatNumber : value.length >= 1 && value.length < 10});
    event.target.value = value;    
    }

    allowOnlyCharacters = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^A-Za-z\s]/g, '');
        event.target.value = value;
    }

    allowOnlyNumbers = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^0-9]/g, '');
        event.target.value = value;
    }

    render() {
        const {loading, history, systemError} = this.props;
        const {errors, selectedEntity, shouldDisableInput, addressDisable, entityNotFoundErr} = this.state;
        if (systemError && systemError.show) return null;
        return (
            <div className="page-container">
                <EntityDetailsCard
                    errors={errors}
                    handleAddressChange={this.handleAddressChange}
                    loading={loading}
                    onChange={this.handleChange}
                    onChangeAssetValue={this.handleAssetValueChange}
                    onResetError={this.resetError}
                    selectedEntity={selectedEntity}
                    shouldDisableInput={shouldDisableInput}
                    addressDisable = {addressDisable}
                    onSearch={this.handleEntitySearch}
                    handleDateChange={this.handleDateChange}
                    allowVatNumber={this.allowVatNumber}
                    allowOnlyCharacters = {this.allowOnlyCharacters}
                    allowOnlyNumbers = {this.allowOnlyNumbers}
                    entityNotFoundErr = {entityNotFoundErr}
                />
                <div className="page-footer-section" style={{width: "80%"}}>
                    <ButtonsComponent
                        history={history}
                        onNext={this.saveDetails}
                        hidePrevious={false}
                        prevPage={managedFundRoutes.cashAccount}
                        onSaveAndContinue={this.saveAndContinue}
                    />
                </div>
            </div>
        );
    }
}

const EntityDetailsCard = (props) => {
    const {errors, handleAddressChange, loading, onChange, onChangeAssetValue, onResetError, entityNotFoundErr,
        selectedEntity, shouldDisableInput, addressDisable, onSearch, handleDateChange, allowVatNumber, allowOnlyCharacters, allowOnlyNumbers} = props;
    if (!selectedEntity) return null;
    if (loading) return <ReactLoading type="spokes" color="#444"/>

    return (
        <div className="page-main-section">
            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        <span>Managed Fund Entity Details</span>
                    </div>

                    <FormField className="form-group" id="registrationNum" error={errors && errors.registrationNumber}>
                        {
                            !shouldDisableInput ?
                                <div>
                                    <label>Registration Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="registrationNumber"
                                        value={selectedEntity.registrationNumber || ''}
                                        onChange={(event) => {
                                            errors && errors.registrationNumber && onResetError && onResetError('registrationNumber');
                                            onChange(event, 'registrationNumber')
                                        }}
                                    />
                                </div> :
                                <span>
                                    <SearchComponent
                                        label={"Registration Number"}
                                        onChange={(event) => {
                                            errors && errors.registrationNumber && onResetError && onResetError('registrationNumber');
                                            onChange(event, 'registrationNumber')
                                        }}
                                        onSearch={onSearch}
                                        searchValue={selectedEntity.registrationNumber || ''}
                                    />
                                    {
                                        entityNotFoundErr ?
                                        <span className="form-error" style={{ position: 'absolute', paddingLeft: '15px'}}>{ entityNotFoundErr }</span> : ''
                                    }
                                    
                                </span>
                        }
                    </FormField>
                    
                    <FormField className="form-group col">
                        <label htmlFor="tradingAs">Trading As <small className="text-danger">Optional</small></label>
                        <input
                            type="text"
                            className="form-control"
                            id="tradingAs"
                            value={selectedEntity.tradingAs ? selectedEntity.tradingAs : ""}
                            onChange={(event) => onChange(event, 'tradingAs')}
                        />
                    </FormField>

                    <FormField className="form-group col" id="regName" error={errors && errors.registrationName}>
                        <label htmlFor="registerdName">Registered Name</label>
                        <input
                            type="text"
                            className="form-control"
                            id="registrationName"
                            required={true}
                            value={selectedEntity.registrationName ? selectedEntity.registrationName :""}
                            onChange={(event) => {
                                errors && errors.registrationName && onResetError && onResetError('registrationName');
                                onChange(event, 'registrationName')
                            }}
                        />
                    </FormField>

                    <FormField className="form-group col" error={errors && errors.registrationDate }>
                        <label htmlFor="registrationDate">Entity Registration Date</label>

                        <DatePicker
                            className="form-control"
                            selected={selectedEntity.registrationDate && new Date(selectedEntity.registrationDate)}
                            onChange={(event) => {
                                errors && errors.registrationDate && onResetError && onResetError('registrationDate');
                                handleDateChange(event, 'registrationDate')
                            }}
                            showYearDropdown
                            showMonthDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={30}
                            maxDate={new Date()}
                            dateFormat="yyyy-MM-dd"

                        />

                    </FormField>

                    <FormField className="form-group col" error={errors && errors.vatNumber }>
                        <label htmlFor="vatNumber">VAT Number <small className="text-danger">Optional</small></label>
                        <input
                            type="text"
                            className="form-control"
                            id="vatNumber"
                            value={selectedEntity.vatNumber ? selectedEntity.vatNumber : ""}
                            onChange={(event) => {
                                allowVatNumber(event);
                                onChange(event, 'vatNumber')}} />
                    </FormField>

                    {/* <FormField className="form-group col">
                        <label htmlFor="telephoneNumber">Telephone Number <small
                            className="text-danger">Optional</small></label>
                        <input
                            type="text"
                            className="form-control"
                            id="telephoneNumber"
                            value={selectedEntity.telephoneNumber ? selectedEntity.telephoneNumber : ""}
                            onChange={(event) => onChange(event, 'telephoneNumber')}
                        />
                    </FormField> */}

                    {/* <FormField className="form-group col" error={errors && errors.faisRequired}>
                        <label>
                            What is your total Net Asset Value?
                       
                        </label>
                        <hr/>
                        <div className="inline-container"
                            onClick={() => onChangeAssetValue(true, 'faisRequired')} >
                            <div className="icon-container">
                                <i className={(selectedEntity.faisRequired ? 'fa fa-check-circle-o' : 'fa fa-circle-o') + ' icon'}
                                   style={{fontSize: 20}}/>
                            </div>
                            Over 20 Million Rands
                        </div>
                        <div
                            className="inline-container"
                            onClick={() => onChangeAssetValue(false, 'faisRequired')}>
                            <div className="icon-container">
                                <i className={(selectedEntity.faisRequired != null && !selectedEntity.faisRequired ? 'fa fa-check-circle-o' : 'fa fa-circle-o') + ' icon'}
                                   style={{fontSize: 20}}/>
                            </div>
                            Under 20 Million Rands
                        </div>
                    </FormField> */}

                </div>
            </div>

            <div className="flexColumn">
                <div className="card-container-form">
                    <div className="section-title">
                        <span>Registered Address {/* <small className="text-danger">Required</small>*/}</span>
                    </div>

                    <FormField className="form-group col" id="regAddress" error={errors && errors.street}>
                        <label htmlFor="registeredAddressLin1">Address</label>
                        <input
                            className="form-control"
                            id="registeredAddressLine1"
                            onChange={(event) => { 
                                errors && errors.street && onResetError && onResetError('street');
                                handleAddressChange(event, 'street')
                            }}
                            type="text"
                            value={(selectedEntity.address && selectedEntity.address.street) ? selectedEntity.address.street : ""}
                            disabled={ addressDisable }
                        />
                    </FormField>


                    <FormField className="form-group col" id="line2" error={errors && errors.suburb}>
                        <label htmlFor="registeredAddressLine2">Suburb</label>
                        <input
                            className="form-control"
                            id="registeredAddressLine2"
                            onChange={(event) => {
                                errors && errors.suburb && onResetError && onResetError('suburb');
                                handleAddressChange(event, 'suburb')
                            }}
                            type="text"
                            value={(selectedEntity.address && selectedEntity.address.suburb) ? selectedEntity.address.suburb : ""}
                            disabled={ addressDisable}
                        />
                    </FormField>

                    <FormField className="form-group col" id="regCity" error={errors && errors.city}>
                        <label htmlFor="registeredCity">City</label>
                        <input
                            type="text"
                            className="form-control"
                            id="registeredCity"
                            value={(selectedEntity.address && selectedEntity.address.city) ? selectedEntity.address.city : ""}
                            onChange={(event) => {
                                
                                errors && errors.city && onResetError && onResetError('city');
                                allowOnlyCharacters(event);
                                handleAddressChange(event, 'city')
                            }}
                            disabled={ addressDisable}
                        />
                    </FormField>

                    <FormField className="form-group col" id="regProvince" error={errors && errors.province}>
                        <label htmlFor="stateOrProvince">State/Province</label>
                        <input
                            type="text"
                            className="form-control"
                            id="stateOrProvince"
                            value={(selectedEntity.address && selectedEntity.address.province) ? selectedEntity.address.province : ""}
                            onChange={(event) => {
                                errors && errors.province && onResetError && onResetError('province');
                                allowOnlyCharacters(event);
                                handleAddressChange(event, 'province');
                            }}
                            disabled={ addressDisable}
                        />
                    </FormField>

                    <FormField className="form-group col" id="regPostalCode" error={errors && errors.postalCode}>
                        <label htmlFor="stateOrProvince">Zip/Postal Code</label>
                        <input
                            type="text"
                            className="form-control"
                            id="registoredZipOrPostalCode"
                            value={(selectedEntity.address && selectedEntity.address.postalCode) ? selectedEntity.address.postalCode : ""}
                            onChange={(event) => {
                                errors && errors.postalCode && onResetError && onResetError('postalCode');
                                allowOnlyNumbers(event);
                                handleAddressChange(event, 'postalCode')
                            }}
                            disabled={ addressDisable}
                        />
                    </FormField>

                    <FormField className="form-group col" id="regCountry" error={errors && errors.country}>
                        <label htmlFor="stateOrProvince">Country</label>
                        <input
                            type="text"
                            className="form-control"
                            id="country"
                            value={(selectedEntity.address && selectedEntity.address.country ) ? selectedEntity.address.country : ""}
                            onChange={(event) => {
                                errors && errors.country && onResetError && onResetError('country');
                                allowOnlyCharacters(event);
                                handleAddressChange(event, 'country');
                            }}
                            disabled={ addressDisable}
                        />
                    </FormField>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps({cashManagedFund}) {
    return {
        cashManagedFundRequest: cashManagedFund && cashManagedFund.cashManagedFundRequest ,
        selectedEntity: cashManagedFund && cashManagedFund.cashManagedFundRequest && cashManagedFund.cashManagedFundRequest.managedFundEntity,
        appId: cashManagedFund && cashManagedFund.id
    }

}
function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions,saveManagedFund}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps)(cashConfirmdetails);
