import React from "react";
import _ from "lodash";
import {ButtonToolbar, ToggleButtonGroup, ToggleButton} from 'react-bootstrap';

import "../../../styles/ConfirmApplication.css";
import "../../../styles/Global.css";
import "react-datepicker/dist/react-datepicker.css";

export const BasicInfoComponent = (props) => {
    return (
        <div className="col-md-12" style={{minHeight: 200}}>
            <div className="form-horizontal col-md-12" style={{padding: "0 10"}}>
                <div className="col-md-12">

                    <div className="section-title col-md-12" style={{paddingBottom: 0, marginBottom: "0px"}}>
                        <span>SSI Information</span>
                        <hr style={{padding: 0}}/>
                    </div>

                    <div className="col-md-12 form-group">
                        <div className="col-md-4">
                            <label htmlFor="isBankInstruction">Load SSI For</label>
                        </div>
                        <div className="col-md-8">
                            <div>
                                <ButtonToolbar>
                                    <ToggleButtonGroup
                                        type="radio"
                                        name="isBankInstruction"
                                        value={props.instruction && props.instruction.isBankInstruction}
                                        onChange={(event) => props.handleOnToggle(event, "isBankInstruction")}>
                                        <ToggleButton value={true}>Bank</ToggleButton>
                                        <ToggleButton value={false}>Customer</ToggleButton>
                                    </ToggleButtonGroup>
                                </ButtonToolbar>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 form-group">
                        <div className="col-md-4">
                            <label htmlFor="instrument">Instrument<span
                                className="redText">&nbsp;*</span></label>
                        </div>
                        <div className="col-md-8">
                            <select className="form-control col-md-10" id="instrument" type="text" name="instrument"
                                    onChange={(event) => props.handleOnChange(event)}
                                    value={props.instruction && props.instruction.instrument || ''}>
                                {
                                    _.map(props.INSTRUMENTS, (c) => (
                                        <option key={`currency-${c.instrument}`} value={c.instrumentDescription}
                                                disabled={!c.active}>{c.instrumentDescription}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>


                    {
                        props.instruction.ssiNumber &&
                        (<div className="col-md-12 form-group">
                            <div className="col-md-4">
                                <label htmlFor="ssiNumber">SSI Number</label>
                            </div>
                            <div className="col-md-8">
                                <div>
                                    <input className="form-control" id="ssiNumber" type="text" name="ssiNumber"
                                            value={props.instruction && props.instruction.ssiNumber}
                                            onChange={(event) => props.handleOnChange(event)}
                                            readOnly/>
                                </div>
                            </div>
                        </div>)
                    }

                    <div className="col-md-12 form-group">
                        <div className="col-md-4">
                            <label htmlFor="currency">Currency<span className="redText">&nbsp;*</span></label>
                        </div>
                        <div className="col-md-8">
                            <select className="form-control col-md-10" id="currency" type="text" name="currency"
                                    onChange={(event) => props.handleCurrencyChange(event)}
                                    value={props.instruction && props.instruction.currency || ''}>
                                {
                                    _.map(props.currencies, (c) => (
                                        <option key={`main-currency-${c.name}`} value={c.name}>{c.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <div className="col-md-12 form-group">
                        <div>
                            <button
                                className="action-btn-secondary btn-block pull-right"
                                onClick={props.addIntermediary} 
                                type="button">
                                Add Intermediary
                            </button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )

}