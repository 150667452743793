import axios from 'axios';
import * as types from './actionTypes';
import {API_ROOT} from '../api-config';
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from './appLoading';
import _ from "lodash";
import {managedFundRoutes} from "../routers/routes";


const CASH_MANAGED_FUND_URL = API_ROOT + '/api/cash-managed-fund';

const CASH_MANAGED_FUND_PENDING_TASKS = {
    confirmDetails: "UserTask_ManagedFundDetails",
    productDetails: "UserTask_ManagedFundProduct",
    kycDetails: "UserTask_ManagedFundKYC",
    notification: "UserTask_ManagedFundNotification"
};

export function startCashManagedFundApplication(advisorGoldtierId, application,impersonation, onProceed = null) {
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            application.advisorGoldtierId = advisorGoldtierId;
            application.impersonatedIdNumber = impersonation.identityNumber;

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.post(CASH_MANAGED_FUND_URL, application).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.FETCH_CASH_MANAGED_FUND_DATA, data: responseData.data});
                onProceed && onProceed();
            } else {
                console.log('entity.fetchSelectedEntity.errorMessage ===> ', responseData.message);
                const hasNoApprovalRules = responseData && responseData.message && responseData.message.includes('no auth rules');
                const hasInsufficientApprovalRules = responseData && responseData.message && responseData.message.includes('Not enough');
                if (hasNoApprovalRules) {
                    onProceed && onProceed(false, 'Authorisation Rules Need To Be Configured');
                } else if (hasInsufficientApprovalRules) {
                    onProceed && onProceed(false, 'Not enough registered users as per Authorisation Rules settings. Please update the rules or ensure enough authorisers are registered');
                } else {
                    dispatch({
                        type: types.SHOW_RESPONSE_ERROR,
                        errorMessage: 'Couldn\'t retrieve the selected entity details.'
                    });
                }
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.error('entity.fetchSelectedEntity.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchSelectedCashManagedFundRequest(id, history, onFinish = null) {
    return async dispatch => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(`${CASH_MANAGED_FUND_URL}/${id}`).then(response => response.data);

            if (responseData.success) {

                const application = responseData.data;
                dispatch({type: types.FETCH_CASH_MANAGED_FUND_DATA, data: application});

                const pendingTasks = application && application.applicationStatus && application.applicationStatus.pendingTasks;

                let path = "/"

                if (_.find(pendingTasks, task => (task.name === CASH_MANAGED_FUND_PENDING_TASKS.confirmDetails))) {
                    path = managedFundRoutes.cashConfirmdetails;
                } else if (_.find(pendingTasks, task => (task.name === CASH_MANAGED_FUND_PENDING_TASKS.productDetails))) {
                    path = managedFundRoutes.cashConfirmProductdetails;
                } else if (_.find(pendingTasks, task => (task.name === CASH_MANAGED_FUND_PENDING_TASKS.kycDetails))) {
                    path = managedFundRoutes.cashKyc;
                } else if (_.find(pendingTasks, task => (task.name === CASH_MANAGED_FUND_PENDING_TASKS.notification))) {
                    path = managedFundRoutes.cashConfirmAuthorisers;
                } else {
                    path = managedFundRoutes.cashConfirmApplication;
                }

                history.push(path);
            } else {
                console.log("entity.fetchSelectedOnboardRequest.errorMessage ====>", responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: "Couldn't retrieve selected custody application."
                });
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function saveManagedFund(id, managedFundRequest, task, onComplete, email = null ) {
        return async dispatch => {
            try {

                axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
                let responseData = null;
                if(email === null ){
                    responseData = await axios.put(CASH_MANAGED_FUND_URL + '/complete-task/' + id + '/' + task,  managedFundRequest).then(response => response.data);
                }
                else{
                    responseData = await axios.put(CASH_MANAGED_FUND_URL + '/complete-task/' + id + '/' + task + '?email='+ email,  managedFundRequest).then(response => response.data);
                 }
                if (responseData.success) {
                    onComplete && onComplete(true);
                }
            } catch (error) {
                console.log("entity.fetchCustody.error ===>", error);
            }
        };
}

export function saveAndContinueManagedFund(id, managedFundRequest, saveAndContinue, onComplete, email = null ) {
    return async dispatch => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            let responseData = null;
            if(email === null ){
                responseData = await axios.put(CASH_MANAGED_FUND_URL + '/complete-task/' + id + '/' + task,  managedFundRequest).then(response => response.data);
            }
            else{
                responseData = await axios.put(CASH_MANAGED_FUND_URL + '/complete-task/' + id + '/' + task+'?email='+ email,  managedFundRequest).then(response => response.data);
             }
            if (responseData.success) {
                onComplete && onComplete(true);
            }

        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
        }
    };
}


