import React from "react";
import _ from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Col} from 'react-bootstrap';

import * as actions from '../../actions/globalMarket';
import {getEntityDetails} from '../../actions/entity';
import {fetchAccounts} from "../../actions/kyc";
import {globalMarketAccountRoutes} from '../../routers/routes'
import CustomerComponent from './customers/CustomerComponent';
import CustomerSubscriptionsComponent from './customers/CustomerSubscriptionsComponent';
import { isSameDay } from "date-fns";


class CustomersPage extends React.Component {

    state = {
        selectedCustomer: {},
        fetchingCustomers: false,
        errors: {}
    };

    _resetError(attribute) {
        const {errors} = this.state;
        this.setState({errors: _.extend({}, errors, {[attribute]: null})})
    }

    onSelectCustomer = (customer) => {
        const {selectedCustomer} = this.state;
        console.log("selected customer", selectedCustomer);
        this.setState({
            selectedCustomer: customer
        });
    }

    handleOnPrevious = (e) => {
        e.preventDefault();
        const {history, actions, application} = this.props;
        application.selectedCustomer = null;
        application.customers = [];

        const onProceed = () => history.push(globalMarketAccountRoutes.confirmentity);
        actions.update(application, onProceed);
    }

    onSaveAndContinueLater = (event) => {
        event.preventDefault();

        const {actions, application, impersonation, history, selectedEntity} = this.props;
        const {selectedCustomer} = this.state;

        const onProceed = () => history.push("/");
        application.selectedCustomer = selectedCustomer;
        application.applicationStatus = {status: globalMarketAccountRoutes.customers};
        actions.processGMApplication (selectedEntity, application, impersonation, false, onProceed);
    }

    onNext = (event) => {
        event.preventDefault();

        const {actions, application, impersonation, history, selectedEntity} = this.props;
        const {selectedCustomer} = this.state;

        const onProceed = () => history.push(globalMarketAccountRoutes.instructions);
        application.selectedCustomer = selectedCustomer;
        application.applicationStatus = {status: globalMarketAccountRoutes.instructions};
        actions.processGMApplication (selectedEntity, application, impersonation, false, onProceed);
    }

    _renderFooter = () => {
        const {application} = this.props;
        return (
            <div className="col-md-12">
                <div className="action-button-container col-md-12">
                    <button
                            className="action-btn-primary pull-left"
                            onClick={this.handleOnPrevious}
                            type="button">
                            Previous
                    </button>
                    <button
                            className="action-btn-primary"
                            onClick={this.onSaveAndContinueLater}
                            type="button">
                            Save And Continue later
                    </button>
                    <button
                        className="action-btn-primary"
                        onClick={this.onNext}
                        disabled={false}
                        type="button">
                        Next
                    </button>
                </div>
            </div>
        )
    }


    _renderCustomers = () => {
        const {application} = this.props;
        const {errors, selectedCustomer} = this.state;
        if (application.fetchingCustomers) return ( <div></div> );
        return (
            <div style={{marginTop: '3em', maxHeight: '50vh', overflowY: 'scroll'}}>
                <CustomerComponent customers={application.customers} selected={selectedCustomer} onSelectCustomer={this.onSelectCustomer} />
            </div>
        )
    }

    _renderCustomerSubscriptions = () => {
        const {application} = this.props;
        const {selectedCustomer} = this.state;
        if (application.fetchingCustomers) return ( <div></div> );
        return (
            <div >
                <CustomerSubscriptionsComponent customer={selectedCustomer} />
            </div>
        )
    }

    render() {
        const {application} = this.props;
        
        const hasProfiles = _.size(application.customers) > 0;
        return (
             <div className="container-fluid">
                 <br/><hr/><br/>
                <div className="col-md-12">
                    <div className="col-md-3">
                        <div className="product-heading">
                            Customer Profiles
                        </div>
                        <div className="title-gradient"/>
                        {this._renderCustomers()}
                    </div>
                    {
                        hasProfiles &&
                            (<div className="col-md-9">
                                <div className="product-heading">
                                    Profile Subscriptions
                                </div>
                                <div className="title-gradient"/>
                                {this._renderCustomerSubscriptions()}
                            </div>)
                    }
                </div>
                
                <br/><hr/><br/>
                <div className="col-md-12">
                    <div className="page-footer-section" >
                        {this._renderFooter()}
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps({globalMarket, accounts, loading, systemError, impersonation, user}, ownProps) {

    const legalEntity = globalMarket.application && globalMarket.application.legalEntity;
    const application = globalMarket.application ? globalMarket.application : {};

    /** FOR TEST PURPOSE */
    application.customers = 
    [
        {
          "id": 67831,
          "customerShortName": "ABSA - Pension Fund S21",
          "createDate": "2007-09-20 00:00:00",
          "effectiveDate": "2007-09-20 00:00:00",
          "closingDate": null,
          "closingReasonCode": null,
          "cif": 100060019,
          "accounts": [
            {
              "id": 4412025,
              "customerNumber": 67831,
              "systemCode": "CAM",
              "sourceSystemSubscriptionId": "67831",
              "existsOnSourceSystem": true,
              "authorisingUser": "KGANYAGOM",
              "authorisingDate": "2013-05-07 10:14:08"
            },
            {
              "id": 6477869,
              "customerNumber": 67831,
              "systemCode": "CDPL",
              "sourceSystemSubscriptionId": "67831",
              "existsOnSourceSystem": false,
              "authorisingUser": "CDPL_TAKEON",
              "authorisingDate": "2018-08-14 17:03:17"
            },
            {
              "id": 3553129,
              "customerNumber": 67831,
              "systemCode": "CIF",
              "sourceSystemSubscriptionId": "100060019",
              "existsOnSourceSystem": true,
              "authorisingUser": "REPREZASA",
              "authorisingDate": "2007-09-20 16:34:02"
            },
            {
              "id": 3649035,
              "customerNumber": 67831,
              "systemCode": "CPSA",
              "sourceSystemSubscriptionId": "514422",
              "existsOnSourceSystem": true,
              "authorisingUser": "MOOSAA",
              "authorisingDate": "2009-09-26 15:40:22"
            },
            {
              "id": 4724153,
              "customerNumber": 67831,
              "systemCode": "CQM",
              "sourceSystemSubscriptionId": "67831",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-11-12 00:00:00"
            },
            {
              "id": 3646543,
              "customerNumber": 67831,
              "systemCode": "DTRS",
              "sourceSystemSubscriptionId": "57864",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2009-09-03 00:00:00"
            },
            {
              "id": 5544675,
              "customerNumber": 67831,
              "systemCode": "ESB",
              "sourceSystemSubscriptionId": "67831",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2016-06-10 00:00:00"
            },
            {
              "id": 5375909,
              "customerNumber": 67831,
              "systemCode": "EXMB",
              "sourceSystemSubscriptionId": "ZA67831",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2015-08-21 00:00:00"
            },
            {
              "id": 3553134,
              "customerNumber": 67831,
              "systemCode": "FEDS",
              "sourceSystemSubscriptionId": "67831",
              "existsOnSourceSystem": false,
              "authorisingUser": "MACANDAF",
              "authorisingDate": "2018-03-26 17:55:32"
            },
            {
              "id": 5554474,
              "customerNumber": 67831,
              "systemCode": "FXAM",
              "sourceSystemSubscriptionId": "67831",
              "existsOnSourceSystem": true,
              "authorisingUser": "MACANDAF",
              "authorisingDate": "2018-03-26 17:55:38"
            },
            {
              "id": 4582022,
              "customerNumber": 67831,
              "systemCode": "ISW",
              "sourceSystemSubscriptionId": "67831",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-09-19 00:00:00"
            },
            {
              "id": 4412024,
              "customerNumber": 67831,
              "systemCode": "MQSA",
              "sourceSystemSubscriptionId": "573",
              "existsOnSourceSystem": true,
              "authorisingUser": "KGANYAGOM",
              "authorisingDate": "2013-05-07 10:14:06"
            },
            {
              "id": 6255060,
              "customerNumber": 67831,
              "systemCode": "MURX",
              "sourceSystemSubscriptionId": "26972",
              "existsOnSourceSystem": false,
              "authorisingUser": "MACANDAF",
              "authorisingDate": "2018-03-26 17:57:27"
            },
            {
              "id": 6107211,
              "customerNumber": 67831,
              "systemCode": "MXCO",
              "sourceSystemSubscriptionId": "22344",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2017-10-27 00:00:00"
            },
            {
              "id": 4613675,
              "customerNumber": 67831,
              "systemCode": "MXLN",
              "sourceSystemSubscriptionId": "22344",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-11-01 00:00:00"
            },
            {
              "id": 3619881,
              "customerNumber": 67831,
              "systemCode": "MXMR",
              "sourceSystemSubscriptionId": "14582",
              "existsOnSourceSystem": true,
              "authorisingUser": "MOOSAA",
              "authorisingDate": "2010-03-12 13:15:22"
            },
            {
              "id": 3567733,
              "customerNumber": 67831,
              "systemCode": "RFRM",
              "sourceSystemSubscriptionId": "25",
              "existsOnSourceSystem": true,
              "authorisingUser": "SWANEPOELL",
              "authorisingDate": "2007-09-26 14:58:35"
            },
            {
              "id": 4427293,
              "customerNumber": 67831,
              "systemCode": "XCEP",
              "sourceSystemSubscriptionId": "67831",
              "existsOnSourceSystem": false,
              "authorisingUser": "POONENS",
              "authorisingDate": "2013-06-11 13:19:38"
            }
          ],
          "settlementInstructions": [
            {
              "id": 57213,
              "intermediaries": [],
              "capturingUser": "KINGS",
              "createdDate": "2014-03-19 18:31:24",
              "effectiveDate": "1998-01-05 00:00:00",
              "authorizingUser": "POONENS",
              "authorizingDate": "2014-03-19 18:55:14",
              "udpatedDate": "2014-03-19 00:00:00"
            },
            {
              "id": 57214,
              "intermediaries": [],
              "capturingUser": "CI",
              "createdDate": "2009-09-03 00:00:00",
              "effectiveDate": "1998-01-05 00:00:00",
              "authorizingUser": "CI",
              "authorizingDate": "2009-09-03 00:00:00",
              "udpatedDate": null
            },
            {
              "id": 61821,
              "intermediaries": [],
              "capturingUser": "PILLAYN",
              "createdDate": "2018-12-11 10:29:20",
              "effectiveDate": "1998-01-05 00:00:00",
              "authorizingUser": "NKABINDEV",
              "authorizingDate": "2018-12-11 10:44:02",
              "udpatedDate": "2018-12-11 00:00:00"
            },
            {
              "id": 183900,
              "intermediaries": [],
              "capturingUser": "PILLAYN",
              "createdDate": "2015-07-30 15:38:57",
              "effectiveDate": "2015-07-30 00:00:00",
              "authorizingUser": "LAZARUSL",
              "authorizingDate": "2015-07-30 15:47:45",
              "udpatedDate": "2015-07-30 00:00:00"
            },
            {
              "id": 155384,
              "intermediaries": [],
              "capturingUser": "CI",
              "createdDate": "2014-11-07 16:56:54",
              "effectiveDate": "2013-07-16 00:00:00",
              "authorizingUser": "CI",
              "authorizingDate": "2014-11-07 16:56:54",
              "udpatedDate": "2014-03-19 00:00:00"
            },
            {
              "id": 155385,
              "intermediaries": [],
              "capturingUser": "CI",
              "createdDate": "2014-11-07 16:56:54",
              "effectiveDate": "2013-07-16 00:00:00",
              "authorizingUser": "CI",
              "authorizingDate": "2014-11-07 16:56:54",
              "udpatedDate": "2013-07-16 00:00:00"
            },
            {
              "id": 155383,
              "intermediaries": [],
              "capturingUser": "CI",
              "createdDate": "2014-11-07 16:56:54",
              "effectiveDate": "2013-07-16 00:00:00",
              "authorizingUser": "CI",
              "authorizingDate": "2014-11-07 16:56:54",
              "udpatedDate": "2013-07-16 00:00:00"
            },
            {
              "id": 155386,
              "intermediaries": [],
              "capturingUser": "CI",
              "createdDate": "2014-11-07 16:56:54",
              "effectiveDate": "2013-07-16 00:00:00",
              "authorizingUser": "CI",
              "authorizingDate": "2014-11-07 16:56:54",
              "udpatedDate": "2013-07-16 00:00:00"
            },
            {
              "id": 155381,
              "intermediaries": [],
              "capturingUser": "CI",
              "createdDate": "2014-11-07 16:56:53",
              "effectiveDate": "2013-07-16 00:00:00",
              "authorizingUser": "CI",
              "authorizingDate": "2014-11-07 16:56:53",
              "udpatedDate": "2013-07-16 00:00:00"
            },
            {
              "id": 155382,
              "intermediaries": [],
              "capturingUser": "CI",
              "createdDate": "2014-11-07 16:56:53",
              "effectiveDate": "2013-07-16 00:00:00",
              "authorizingUser": "CI",
              "authorizingDate": "2014-11-07 16:56:53",
              "udpatedDate": "2013-07-16 00:00:00"
            },
            {
              "id": 57215,
              "intermediaries": [],
              "capturingUser": "KINGS",
              "createdDate": "2014-03-19 18:31:24",
              "effectiveDate": "1998-01-05 00:00:00",
              "authorizingUser": "POONENS",
              "authorizingDate": "2014-03-19 18:55:39",
              "udpatedDate": "2014-03-19 00:00:00"
            },
            {
              "id": 57216,
              "intermediaries": [],
              "capturingUser": "PILLAYN",
              "createdDate": "2018-12-10 17:10:38",
              "effectiveDate": "1998-01-05 00:00:00",
              "authorizingUser": "NKABINDEV",
              "authorizingDate": "2018-12-11 10:43:20",
              "udpatedDate": "2018-12-10 00:00:00"
            }
          ]
        },
        {
          "id": 94167,
          "customerShortName": "ALLAN GRAY ABGPE",
          "createDate": "2009-07-03 00:00:00",
          "effectiveDate": "2009-07-03 00:00:00",
          "closingDate": null,
          "closingReasonCode": null,
          "cif": 100060019,
          "accounts": [
            {
              "id": 6459481,
              "customerNumber": 94167,
              "systemCode": "CDPL",
              "sourceSystemSubscriptionId": "94167",
              "existsOnSourceSystem": false,
              "authorisingUser": "CDPL_TAKEON",
              "authorisingDate": "2018-08-14 16:48:23"
            },
            {
              "id": 3792390,
              "customerNumber": 94167,
              "systemCode": "CIF",
              "sourceSystemSubscriptionId": "100060019",
              "existsOnSourceSystem": true,
              "authorisingUser": "FOWLERR",
              "authorisingDate": "2010-09-29 14:04:30"
            },
            {
              "id": 4709720,
              "customerNumber": 94167,
              "systemCode": "CQM",
              "sourceSystemSubscriptionId": "94167",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-11-12 00:00:00"
            },
            {
              "id": 5652801,
              "customerNumber": 94167,
              "systemCode": "ESB",
              "sourceSystemSubscriptionId": "94167",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2016-06-18 00:00:00"
            },
            {
              "id": 5370000,
              "customerNumber": 94167,
              "systemCode": "EXMB",
              "sourceSystemSubscriptionId": "ZA94167",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2015-08-21 00:00:00"
            },
            {
              "id": 3726957,
              "customerNumber": 94167,
              "systemCode": "FEDS",
              "sourceSystemSubscriptionId": "94167",
              "existsOnSourceSystem": false,
              "authorisingUser": "FOWLERR",
              "authorisingDate": "2009-07-03 14:53:01"
            },
            {
              "id": 4247491,
              "customerNumber": 94167,
              "systemCode": "FRE",
              "sourceSystemSubscriptionId": "94167",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-02-07 00:00:00"
            },
            {
              "id": 5616110,
              "customerNumber": 94167,
              "systemCode": "FXAM",
              "sourceSystemSubscriptionId": "94167",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2016-06-18 00:00:00"
            },
            {
              "id": 4576002,
              "customerNumber": 94167,
              "systemCode": "ISW",
              "sourceSystemSubscriptionId": "94167",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-09-19 00:00:00"
            },
            {
              "id": 6105225,
              "customerNumber": 94167,
              "systemCode": "MXCO",
              "sourceSystemSubscriptionId": "22611",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2017-10-27 00:00:00"
            },
            {
              "id": 4164157,
              "customerNumber": 94167,
              "systemCode": "MXFX",
              "sourceSystemSubscriptionId": "15842",
              "existsOnSourceSystem": false,
              "authorisingUser": "KGANYAGOM",
              "authorisingDate": "2012-11-09 12:58:10"
            },
            {
              "id": 4613332,
              "customerNumber": 94167,
              "systemCode": "MXLN",
              "sourceSystemSubscriptionId": "22611",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-11-01 00:00:00"
            },
            {
              "id": 6152972,
              "customerNumber": 94167,
              "systemCode": "TMSA",
              "sourceSystemSubscriptionId": "94167",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2017-12-01 00:00:00"
            },
            {
              "id": 4620133,
              "customerNumber": 94167,
              "systemCode": "TOMS",
              "sourceSystemSubscriptionId": "94167",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-11-18 00:00:00"
            },
            {
              "id": 4809803,
              "customerNumber": 94167,
              "systemCode": "XCEP",
              "sourceSystemSubscriptionId": "94167",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2014-01-24 00:00:00"
            }
          ],
          "settlementInstructions": []
        },
        {
          "id": 94212,
          "customerShortName": "CORO AM ABSBAL",
          "createDate": "2009-07-06 00:00:00",
          "effectiveDate": "2009-07-06 00:00:00",
          "closingDate": null,
          "closingReasonCode": null,
          "cif": 100060019,
          "accounts": [
            {
              "id": 4902962,
              "customerNumber": 94212,
              "systemCode": "CAM",
              "sourceSystemSubscriptionId": "94212",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2014-11-07 00:00:00"
            },
            {
              "id": 6459525,
              "customerNumber": 94212,
              "systemCode": "CDPL",
              "sourceSystemSubscriptionId": "94212",
              "existsOnSourceSystem": false,
              "authorisingUser": "CDPL_TAKEON",
              "authorisingDate": "2018-08-14 16:48:25"
            },
            {
              "id": 3792400,
              "customerNumber": 94212,
              "systemCode": "CIF",
              "sourceSystemSubscriptionId": "100060019",
              "existsOnSourceSystem": true,
              "authorisingUser": "FOWLERR",
              "authorisingDate": "2010-09-29 14:05:02"
            },
            {
              "id": 4709739,
              "customerNumber": 94212,
              "systemCode": "CQM",
              "sourceSystemSubscriptionId": "94212",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-11-12 00:00:00"
            },
            {
              "id": 5652820,
              "customerNumber": 94212,
              "systemCode": "ESB",
              "sourceSystemSubscriptionId": "94212",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2016-06-18 00:00:00"
            },
            {
              "id": 5370021,
              "customerNumber": 94212,
              "systemCode": "EXMB",
              "sourceSystemSubscriptionId": "ZA94212",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2015-08-21 00:00:00"
            },
            {
              "id": 3726932,
              "customerNumber": 94212,
              "systemCode": "FEDS",
              "sourceSystemSubscriptionId": "94212",
              "existsOnSourceSystem": false,
              "authorisingUser": "FOWLERR",
              "authorisingDate": "2010-12-10 09:49:36"
            },
            {
              "id": 4247509,
              "customerNumber": 94212,
              "systemCode": "FRE",
              "sourceSystemSubscriptionId": "94212",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-02-07 00:00:00"
            },
            {
              "id": 5616131,
              "customerNumber": 94212,
              "systemCode": "FXAM",
              "sourceSystemSubscriptionId": "94212",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2016-06-18 00:00:00"
            },
            {
              "id": 4576023,
              "customerNumber": 94212,
              "systemCode": "ISW",
              "sourceSystemSubscriptionId": "94212",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-09-19 00:00:00"
            },
            {
              "id": 5900857,
              "customerNumber": 94212,
              "systemCode": "MQSA",
              "sourceSystemSubscriptionId": "11521",
              "existsOnSourceSystem": false,
              "authorisingUser": "CI",
              "authorisingDate": "2017-04-10 00:00:00"
            },
            {
              "id": 6105244,
              "customerNumber": 94212,
              "systemCode": "MXCO",
              "sourceSystemSubscriptionId": "22614",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2017-10-27 00:00:00"
            },
            {
              "id": 3726933,
              "customerNumber": 94212,
              "systemCode": "MXFX",
              "sourceSystemSubscriptionId": "15859",
              "existsOnSourceSystem": true,
              "authorisingUser": "FOWLERR",
              "authorisingDate": "2009-07-06 11:39:35"
            },
            {
              "id": 4613350,
              "customerNumber": 94212,
              "systemCode": "MXLN",
              "sourceSystemSubscriptionId": "22614",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-11-01 00:00:00"
            },
            {
              "id": 4809812,
              "customerNumber": 94212,
              "systemCode": "XCEP",
              "sourceSystemSubscriptionId": "94212",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2014-01-24 00:00:00"
            }
          ],
          "settlementInstructions": [
            {
              "id": 161586,
              "intermediaries": [],
              "capturingUser": "CI",
              "createdDate": "2014-11-07 16:55:52",
              "effectiveDate": "2013-11-25 00:00:00",
              "authorizingUser": "CI",
              "authorizingDate": "2014-11-07 16:55:52",
              "udpatedDate": null
            },
            {
              "id": 166688,
              "intermediaries": [],
              "capturingUser": "CI",
              "createdDate": "2014-11-07 16:55:52",
              "effectiveDate": "2014-03-14 00:00:00",
              "authorizingUser": "CI",
              "authorizingDate": "2014-11-07 16:55:52",
              "udpatedDate": "2014-03-14 00:00:00"
            }
          ]
        },
        {
          "id": 111903,
          "customerShortName": "ABSA Pension Fd",
          "createDate": "2010-09-27 00:00:00",
          "effectiveDate": "2010-09-27 00:00:00",
          "closingDate": null,
          "closingReasonCode": null,
          "cif": 100060019,
          "accounts": [
            {
              "id": 4619308,
              "customerNumber": 111903,
              "systemCode": "CAM",
              "sourceSystemSubscriptionId": "111903",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-11-15 00:00:00"
            },
            {
              "id": 6570512,
              "customerNumber": 111903,
              "systemCode": "CDPL",
              "sourceSystemSubscriptionId": "111903",
              "existsOnSourceSystem": false,
              "authorisingUser": "CDPL_TAKEON",
              "authorisingDate": "2018-08-14 18:25:45"
            },
            {
              "id": 3800590,
              "customerNumber": 111903,
              "systemCode": "CIF",
              "sourceSystemSubscriptionId": "100060019",
              "existsOnSourceSystem": true,
              "authorisingUser": "ESSAF",
              "authorisingDate": "2010-09-27 12:23:24"
            },
            {
              "id": 4703698,
              "customerNumber": 111903,
              "systemCode": "CQM",
              "sourceSystemSubscriptionId": "111903",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-11-12 00:00:00"
            },
            {
              "id": 5650371,
              "customerNumber": 111903,
              "systemCode": "ESB",
              "sourceSystemSubscriptionId": "111903",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2016-06-18 00:00:00"
            },
            {
              "id": 5608842,
              "customerNumber": 111903,
              "systemCode": "FXAM",
              "sourceSystemSubscriptionId": "111903",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2016-06-18 00:00:00"
            },
            {
              "id": 5900644,
              "customerNumber": 111903,
              "systemCode": "MQSA",
              "sourceSystemSubscriptionId": "11785",
              "existsOnSourceSystem": false,
              "authorisingUser": "CI",
              "authorisingDate": "2017-04-10 00:00:00"
            },
            {
              "id": 6103451,
              "customerNumber": 111903,
              "systemCode": "MXCO",
              "sourceSystemSubscriptionId": "22844",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2017-10-27 00:00:00"
            },
            {
              "id": 4612887,
              "customerNumber": 111903,
              "systemCode": "MXLN",
              "sourceSystemSubscriptionId": "22844",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2013-11-01 00:00:00"
            },
            {
              "id": 4834116,
              "customerNumber": 111903,
              "systemCode": "XCEP",
              "sourceSystemSubscriptionId": "111903",
              "existsOnSourceSystem": true,
              "authorisingUser": "CI",
              "authorisingDate": "2014-03-31 00:00:00"
            }
          ],
          "settlementInstructions": [
            {
              "id": 89295,
              "intermediaries": [],
              "capturingUser": "CI",
              "createdDate": "2014-11-07 16:54:17",
              "effectiveDate": "2010-09-28 00:00:00",
              "authorizingUser": "CI",
              "authorizingDate": "2014-11-07 16:54:17",
              "udpatedDate": "2014-02-10 00:00:00"
            },
            {
              "id": 161700,
              "intermediaries": [],
              "capturingUser": "CI",
              "createdDate": "2014-11-07 16:54:17",
              "effectiveDate": "2013-11-25 00:00:00",
              "authorizingUser": "CI",
              "authorizingDate": "2014-11-07 16:54:17",
              "udpatedDate": null
            }
          ]
        }
      ];
    return {
        application: application,
        impersonation: impersonation ? impersonation : {},
        selectedEntity: legalEntity ? legalEntity : {},
        loading,
        systemError
    };
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, getEntityDetails, fetchAccounts}, dispatch)}
}


export default connect(mapStateToProps, mapDispatchToProps)(CustomersPage);