import React, {Component} from 'react';
import _ from 'lodash';
import {browserHistory} from 'react-router';
import howToGuild from '../../resources/Legal Remediation Digital How to Guide.pdf';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '../../actions/entity';
import {onSelectEntity, startRemediation} from '../../actions/legal-remidiation'
import EntityList from "../legalentity/EntityList";
import NoLinkedEntitiesMessage from "../legalentity/NoLinkedEntitiesMessage";
import {fetchAccounts} from "../../actions/kyc";
import FormField from "../formField/FormField";
import {remediationRoutes} from "../../routers/routes";
import {Button, ListGroup, ListGroupItem, Modal, Panel, PanelGroup} from "react-bootstrap";


class LegalRemediation extends Component {
    constructor(props) {
        super(props);

        this.handleSelect = this._handleSelect.bind(this);
        this._renderFooter = this._renderFooter.bind(this);
        this.onSubmit = this._onSubmit.bind(this);
        this.state = {
            errors: null,
            show: true
        };
    }

    componentWillMount() {
        this._fetchHierarchy();
    }

    _fetchHierarchy() {
        const {actions, impersonation} = this.props;
        actions.fetchHierachy(impersonation);
    }

    handleAcceptTsAndCs = () => {
        const {errors} = this.state;
        errors && errors.termsAccepted && this.handleResetError("termsAccepted");
        this.setState(prevState => ({termsAccepted: !prevState.termsAccepted}))
    };

    _handleSelect(selectedEntity) {
        const {actions} = this.props;
        this.setState({selectedEntity});
        actions.onSelectEntity(selectedEntity);
    }

    _onSubmit(e) {

        e.preventDefault();
        const {actions, impersonation: {identityNumber}, history} = this.props;
        const {selectedEntity} = this.state;


        const cif = selectedEntity && selectedEntity.cif;

        const onProceed = (success = true, message = '') => {
            if (success) {
                history.push(remediationRoutes.legals);
            } else {
                this.setState({showHasNoApprovalMessage: true, message})
            }
        };

        if (/*this.validateForm()*/ true && cif) {
            actions.startRemediation(identityNumber, selectedEntity);
            actions.fetchAccounts(`cifNumber=${cif}`, 'selectedEntityAccounts', onProceed, true);
        }
    }

    _renderEntities() {
        const {entities} = this.props;
        const hasEntities = _.size(entities) > 0;

        if (!hasEntities) return null;
        const {errors, selectedEntity} = this.state;

        return (
            <EntityList
                entities={entities}
                onSelect={this.handleSelect}
                error={errors && errors.selectedEntity}
                selectedEntity={selectedEntity}
                style={{minHeight: '65vh'}}
            />
        );
    }

    _renderTerms() {
        const {termsAccepted, errors} = this.state;
        return (
            <FormField
                className="form-group"
                error={errors && errors.termsAccepted && ["Terms And Conditions Should Be Accepted"]}
            >
                <div className="flexRow" style={{alignItems: "flex-start", textAlign: "left"}}>
                    <div className="icon-container" onClick={this.handleAcceptTsAndCs}>
                        <i
                            className={termsAccepted ? "fa fa-check-square-o" : "fa fa-square-o"}
                            style={{color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10}}
                        />
                    </div>
                    <p style={{margin: 0}}>
                        I agree to the <a target="_blank"
                                          href="https://www.businessonline.standardbank.co.za/docs/Electronic%20banking%20terms%20and%20conditions%202020.pdf">Terms
                        and Conditions</a>, including sourcing of entity details from public domain.
                    </p>
                </div>
            </FormField>
        )
    }

    _renderFooter() {
        const {termsAccepted} = this.state;
        return (
            <div className="flexRow" style={{justifyContent: "space-between"}}>
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={this.onSubmit}
                    type="button"
                    disabled={termsAccepted ? !termsAccepted : true}
                >Next
                </button>
            </div>
        )
    }

    render() {
        const {entities, loading} = this.props;
        if (loading) return null;

        const hasEntities = _.size(entities) > 0;
        if (!hasEntities) {
            return (
                <div className="page-container">
                    <div className="page-main-section">
                        <NoLinkedEntitiesMessage/>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="page-container">
                    <Modal show={this.state.show} keyboard={true} onHide={() => this.setState({show: false})}
                           bsSize="large">
                        <Modal.Header closeButton>
                            <Modal.Title>Context</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <PanelGroup accordion id="accordion-uncontrolled-example" defaultActiveKey="">
                                <Panel id="collapsible-panel-1" bsStyle="primary" eventKey='1'>
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            1. What is it?
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Collapse>
                                        <Panel.Body>
                                            The Principal and Participant Electronic Banking Terms and Conditions (EB
                                            Terms
                                            and Conditions) are the terms and condition that govern the Client’s use of
                                            the
                                            Electronic Banking System. The EB Terms and Conditions have been amended
                                            from an
                                            agreement to terms and conditions to alleviate the need to re-sign
                                            documentation
                                            every time there is an amendment thereto. This allows for updates without
                                            the
                                            need to check resolutions and formally amend through an addendum.
                                        </Panel.Body>
                                    </Panel.Collapse>
                                </Panel>
                                <Panel id="collapsible-panel-2" bsStyle="primary" eventKey='2'>
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            2. Why must I sign it (albeit electronically)?
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Collapse>
                                        <Panel.Body>
                                            <p>
                                                Clients are being migrated to a single sign on electronic banking
                                                platform.
                                                Signing the EB Terms and Conditions will allow for future updates
                                                without
                                                the need to check resolutions and formally amend through an addendum.
                                            </p>
                                            <p>
                                                The Access Codes clauses have been enhanced to ensure clients are aware
                                                of
                                                their obligations in this regard, particularly where clients were
                                                sharing
                                                User IDs, passwords, and tokens.
                                            </p>
                                            <p>
                                                Clients will be notified of any amendment to the EB Terms and
                                                Conditions,
                                                and where a client is not comfortable, client can engage with Standard
                                                Bank.
                                            </p>
                                            <p>
                                                The Sanctions and POPI clauses have been updated to ensure alignment
                                                with
                                                Group Sanctions Desk directives.
                                            </p>
                                            <p>
                                                The EB Terms and Conditions are drafted in plain and understandable
                                                language, are not unfairly or excessively one-sided and highlights the
                                                risks
                                                inherent in the platform
                                            </p>
                                            <p>
                                                The EB Terms and Conditions, elaborates on indemnities to ensure the
                                                client
                                                is aware of the risks inherent in the operation of the platform, and can
                                                put
                                                the necessary measures in place within their organisation, to guard
                                                against
                                                fraud.
                                            </p>
                                            <p>
                                                Standard Bank has committed to covering all direct damages or losses
                                                where
                                                Standard Bank has acted with negligence or wilful misconduct in its
                                                proportionate share.
                                            </p>
                                            <p>
                                                Standard Bank has lessened the liability threshold of gross negligence
                                                to
                                                negligence which means instead of Standard Bank being liable for a
                                                conscious
                                                and voluntary disregard of the need to use reasonable care, Standard
                                                Bank
                                                will be responsible for mere failure to use reasonable care.
                                            </p>
                                            <p>
                                                Dispute Resolution has been amended from Court to Arbitration
                                                proceedings to
                                                ensure that were issues arise, these are effectively dealt by experts in
                                                as
                                                short a time as possible
                                            </p>
                                        </Panel.Body>
                                    </Panel.Collapse>
                                </Panel>
                                <Panel id="collapsible-panel-3" bsStyle="primary" eventKey='3'>
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            3. Is my electronic acceptance sufficient and legally binding?
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Collapse>
                                        <Panel.Body>
                                            The acceptance of the EB Terms and Conditions by an authorised signatory,
                                            supported by the Principal and Participant’s Resolutions, respectively will
                                            be
                                            legally binding.
                                        </Panel.Body>
                                    </Panel.Collapse>
                                </Panel>
                                <Panel id="collapsible-panel-4" bsStyle="primary" eventKey='4'>
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            4. How do I use this?
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Collapse>
                                        <Panel.Body>
                                            <a className='btn btn-default' target='_blank' href={howToGuild}>How to
                                                Guild</a>
                                        </Panel.Body>
                                    </Panel.Collapse>
                                </Panel>
                            </PanelGroup>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.setState({show: false})}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="col-md-5">
                        <div className="center form-group">
                            {this._renderEntities()}
                        </div>
                        <div className="column">
                            {this._renderTerms()}
                        </div>
                        <div className="page-footer-section">
                            {this._renderFooter()}
                        </div>
                    </div>
                </div>
            )
        }
    }
}


function mapStateToProps({entity, remediation, impersonation, loading}) {
    const hasEntities = entity && entity.entities;
    return {
        remediation: remediation,
        entities: hasEntities ? entity.entities : [],
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, fetchAccounts, onSelectEntity, startRemediation}, dispatch)}
}


export default connect(mapStateToProps, mapDispatchToProps)(LegalRemediation);