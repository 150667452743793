import React from 'react';
import _ from "lodash";
import {File, FilePond, registerPlugin} from "react-filepond";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond/dist/filepond.min.css';
import Alert from "react-bootstrap/lib/Alert";

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

export default class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {files: []};
        this.server = {
            process: this.handleUpload,
            revert: this.handleRemove
        }
    }

    handleOnUpdateFiles = (fileItems) => {
        const files = _.map(fileItems, fileItem => fileItem.file);
        this.setState({files});
    };

    getBase64 = (file) => (
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        })
    );

    handleUpload = (fieldName, file, metadata, load, error, progress, abort) => {
        const {onUpload} = this.props;
        console.log(file.size);

        const inProgress = ({lengthComputable, loaded, total}) => {
            progress(lengthComputable, loaded, total)
        };

        const onComplete = (response) => {
            const msg = response && response.message;
            (response && response.success) ? load(msg) : error(msg)
        };

        this.getBase64(file).then(base64 => {
            onUpload && onUpload(base64, inProgress, onComplete);
        }).catch(error => {
            console.log("FileUpload.handleUpload.error ===>", error)
        });
    };

    handleRemove = () => {
        const {onRemoveDocument, documentType} = this.props;
        onRemoveDocument && onRemoveDocument(documentType);
    };

    render() {
        const {disabled} = this.props;
        return (
            <FilePond
                allowMultiple={false}
                allowBrowse={!disabled}
                maxFiles={1}
                acceptedFileTypes={['application/pdf']}
                server={this.server}
                onupdatefiles={this.handleOnUpdateFiles}
                allowFileSizeValidation={true}
                maxFileSize={'20MB'}
            >
                {
                    _.map(this.state.files, file => (
                        <File key={file} src={file} origin="local"/>
                    ))
                }
            </FilePond>
        )
    }
}
