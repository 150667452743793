import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {businessOnlineRoutes} from "../../routers/routes";
import {Table} from "react-bootstrap";
import FormField from "../formField/FormField";
import ButtonsComponent from "../ButtonsComponent";
import Col from "react-bootstrap/es/Col";
import Popup from "../Popup";
import {saveBolOnboard} from "../../actions/businessOnline";

const ErrorLabel = ({error}) => (
    <div>
        <span className="form-error">{error}</span>
    </div>
);


export const AccountSetupPage = (props) => {

    const businessOnlineApplication = useSelector(state => state.businessOnline && state.businessOnline);
    const businessOnline = useSelector(state => state.businessOnline && state.businessOnline.bolRequest);
    const loading = useSelector(state => state.loading);

    const [errors, setErrors] = useState({});
    const [showPopUp, setShowPopUp] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState({});
    const [selectedAccounts, setSelectedAccounts] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
            if (businessOnline.transactionDetails) {
                setSelectedAccounts(businessOnline.transactionDetails.transactionalAccounts);
            }
        }, [businessOnline]
    );


    const handleChange = ({target}, attribute) => {
        let {value, type} = target;
        if (type === "number") {
            value = parseFloat(value);
        }
        setSelectedAccount(_.extend({}, selectedAccount, {[attribute]: value}));
    };


    const handleOnSelectAccount = (account) => {
        setSelectedAccount(account);
        setShowPopUp(true);
    }

    const handleOnAddDetails = () => {
        // const isSelected = !selectedAccount.isSelected;
        console.log(selectedAccount);

        let valid = validateFields(['shortName', 'batchLimit', 'transactionalLimit', 'allowableTransactions']);

        if (valid) {
            setShowPopUp(false);
            const updatedAccounts = selectedAccounts.map(a => {
                if (a.accountNumber === selectedAccount.accountNumber) {
                    selectedAccount.updated = true;
                    return selectedAccount;
                }
                return a;
            })
            setSelectedAccounts(updatedAccounts);
        }
    }


    const onNext = (e) => {
        e.preventDefault();
        const {history} = props;
        const onProceed = (success = true) => {
            if (success) {
                history.push(businessOnlineRoutes.authorization);
            }
        };

        let valid = true;
        _.each(selectedAccounts, (acc, i) => {
                if (!acc.updated) {
                    valid = false;
                }
            }
        )

        if (valid) {

            const updatedTransactionDetails = _.extend({}, businessOnline.transactionDetails, {['transactionalAccounts']: selectedAccounts})
            dispatch(saveBolOnboard(businessOnlineApplication.id, _.extend({}, businessOnline, {['transactionDetails']: updatedTransactionDetails}), 'UserTask_AccountsSetup', onProceed));
        } else {
            setErrors({'transactionalAccounts': "Please Update accounts settings"});

        }

    };

    const validateFields = (fields) => {
        const errors = {};
        const vv = fields.map((field) => {
            if (selectedAccount[field]) {
                return true;
            } else {
                errors[field] = "Required";
                return false;
            }
        });
        setErrors(errors);
        return !vv.includes(false, 0);
    };

    const renderPopUp = () => {
        if (showPopUp) {
            return (
                <Popup onClose={() => setShowPopUp(false)}>
                    <div className="form-group row">
                        <label
                            className="col-md-4 control-label">Short Name</label>
                        <FormField className="col-md-4">
                            <input className="form-control" type="text" onChange={(event) => {
                                handleChange(event, 'shortName')
                            }} value={selectedAccount.shortName}
                            />
                            {errors.shortName && <ErrorLabel error={errors.shortName}/>}

                        </FormField>
                    </div>
                    <div className="form-group row">
                        <label
                            className="col-md-4 control-label">Batch Limit</label>
                        <FormField className="col-md-4">

                            <input className="form-control" type="number" onChange={(event) => {
                                handleChange(event, 'batchLimit')
                            }} min={0} value={selectedAccount.batchLimit}/>
                            {errors.batchLimit && <ErrorLabel error={errors.batchLimit}/>}
                        </FormField>
                    </div>
                    <div className="form-group row">
                        <label
                            className="col-md-4 control-label">Transactional Limit</label>
                        <FormField className="col-md-4">
                            <input className="form-control" type="number" onChange={(event) => {
                                handleChange(event, 'transactionalLimit')
                            }} min={0} value={selectedAccount.transactionalLimit}/>
                            {errors.transactionalLimit && <ErrorLabel error={errors.transactionalLimit}/>}
                        </FormField>
                    </div>


                    <div className="form-group row">
                        <label
                            className="col-md-4 control-label">Allowable Transactions</label>
                        <FormField className="col-md-4">
                            <select
                                type="text"
                                className="form-control"
                                id="allowableTransactions"
                                value={selectedAccount.allowableTransactions || ''}
                                onChange={(event) => {
                                    errors && errors.idType && onResetError && onResetError('allowableTransactions');
                                    handleChange(event, 'allowableTransactions')
                                }}>
                                <option value="">--SELECT--</option>
                                <option>Debits</option>
                                <option>Credits</option>
                                <option>Both</option>
                            </select>
                            {errors.allowableTransactions && <ErrorLabel error={errors.allowableTransactions}/>}
                        </FormField>
                        <button className="action-btn-primary" onClick={handleOnAddDetails}>Save</button>
                    </div>

                </Popup>
            )
        }
        return null
    }

    const renderAccountSummary = () => {
        return (
            <div id="userSummary">
                <Table striped>
                    <thead>
                    <tr>
                        <th>Account</th>
                        <th>Account Name</th>
                        <th>Short Name</th>
                        <th>Batch Limit</th>
                        <th>Transactional Limit</th>
                        <th>Allowable Transactions</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {selectedAccounts && selectedAccounts.map((a, i) => {
                        return (
                            <tr key={i}>
                                <td>{a.accountNumber}</td>
                                <td>{a.accountName}</td>
                                <td>{a.shortName}</td>
                                <td>{a.batchLimit}</td>
                                <td>{a.transactionalLimit}</td>
                                <td>{a.allowableTransactions}</td>
                                <td>
                                    <button onClick={() => handleOnSelectAccount(a)}
                                            className={`btn ${a.updated ? "btn-success" : "btn-warning"}`}>
                                        Update
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                {errors.transactionalAccounts && <ErrorLabel error={errors.transactionalAccounts}/>}
            </div>
        )
    }


    return (
        <div className="container-fluid">
            <Col sm={12}>
                <div className="col-md-6 col-md-offset-2">
                    <h3>Transactional Account Details</h3>

                </div>
            </Col>
            <hr/>
            <Col sm={12}>
                <div className="col-md-6 col-md-offset-2">
                    {renderAccountSummary()}
                    {renderPopUp()}
                </div>

            </Col>

            <div className="col-md-12">
                <ButtonsComponent
                    history={props.history}
                    prevPage={businessOnlineRoutes.transaction}
                    onNext={onNext}/>
            </div>

        </div>
    );

}

export default AccountSetupPage;
