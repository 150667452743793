import React from 'react'
import {connect} from "react-redux";
import ReactLoading from 'react-loading';

const PageLoading = ({systemError, loading}) => {
    if (loading) {
        return (
            <div className="spinner-container">
                <ReactLoading type="spokes" color="#444"/>
            </div>
        )
    } else if (systemError && systemError.show) {
        return (
            <div id="system-error-container">
                <h1>Oops, something went wrong!</h1>
                {systemError && systemError.status && <p>{systemError.status}</p>}
                <a className="flat-button-primary"
                   onClick={() => window.location.reload()}
                   role="button"
                > Reload</a>
            </div>
        )
    }
    return null
};

function mapStateToProps({systemError, loading}, ownProps) {
    return {systemError, loading};
}

export default connect(mapStateToProps)(PageLoading);