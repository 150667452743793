import _ from 'lodash';
import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function globalMarketReducer(state = initialState.application, action) {

    switch (action.type) {
        case types.GM_START_APPLICATION:
            return {...state, application: action.application};
        case types.GM_UPDATE_APPLICATION:
            return {...state, application: action.application};
        case types.GM_COMPLETE_APPLICATION:
            return {...state, application: action.application};        
        case types.GM_FETCH_CUSTOMERS:
            return {...state, application: action.application};        
        case types.GM_FETCH_INSTRUCTIONS:
            return {...state, application: action.application};        
        case types.GM_UPDATE:
            return {...state, application: action.application};        
        case types.FETCH_BRANCHES:
            return {...state, application: action.application}; 
        default:
            return state;
    }

}