import React from "react";

const MilestoneShape = ({isIntermediary, isDone}) => {
    const backgroundColor = isDone ? 'green' : "#ededed";
    const className = isIntermediary ? "shape-circle" : "shape-square";
    return (
        <div
            className={className}
            style={{backgroundColor}}
        />
    )
};

export const Tracking = (props) => {
    const {isStartPoint, isEndpoint, predecessorCompleted, isCompleted} = props;
    return (
        <div className="track-bar-container">
            <div className="track-bar"
                 style={{backgroundColor: isStartPoint ? 'white' : (predecessorCompleted ? 'green' : '#ededed')}}/>
            <div className="track-bar"
                 style={{backgroundColor: isEndpoint ? 'white' : (isCompleted ? 'green' : '#ededed')}}/>
            <MilestoneShape
                isIntermediary={!(isStartPoint || isEndpoint)}
                isPending={true}
                isDone={isStartPoint || isCompleted}
                isRejected={false}
            />
        </div>
    )
};