import axios from 'axios';
import * as types from './actionTypes';
import {API_ROOT} from "../api-config";
import {authCheck, loadingPageDataFinish, loadingPageDataStart} from "./appLoading";
import {shariahBcaRoutes} from "../routers/routes";
import _ from "lodash";

const ENTITIES_URL = API_ROOT + '/api/legal-entities';
const SHARIAH_BCA_APPLICATION_URL = API_ROOT + '/api/applications';
const ONBOARD_ENTTY_URL = API_ROOT + '/api/onboard-entity';
const ENTITY_DETAILS_URL = API_ROOT + '/api/gt-corporates/';
const AUTHORISERS_URL = API_ROOT + '/api/approvals/';

const SHARIAH_BCA_PENDING_TASKS = {
    confirmDetails: "UserTask_ConfirmEnitityDetails",
    confirmProduct: "UserTask_ConfirmAccountDetails",
    kyc: "UserTask_AllConfirmed"
};


export function fetchHierachy(impersonation, onComplete) {

    let url = ENTITIES_URL;
    if (impersonation && impersonation.identityNumber) {
        url = url + '?id=' + impersonation.identityNumber;
    }
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.get(url).then(response => response.data);
            if (responseData.success) {
                dispatch({type: types.FECTH_HIERACHY_SUCCESS, entities: responseData.data});
            } else {
                console.log('entity.fetchHierachy.errorMessage ===> ', responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage: 'Couldn\'t retrieve entities hierarchy.'});
            }

            if(onComplete) {
                onComplete('isFetchingEntities', dispatch);
            } else {
                loadingPageDataFinish(dispatch);
            }

        } catch (error) {
            console.log('entity.fetchHierachy.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            onComplete && onComplete('isFetchingEntities');
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function startShariahApplication({goldTierId}, application,impersonation, onProceed = null){
    return async (dispatch) => {
        try {
            loadingPageDataStart(dispatch);
            application.legalEntity = {goldTierId};
            application.impersonatedIdNumber= impersonation.identityNumber;
            application.subType= 'SHARIAH';

            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');
            const responseData = await axios.post(SHARIAH_BCA_APPLICATION_URL, application).then(response => response.data);

            if (responseData.success) {
                dispatch({type: types.ENTITY_SELECTED, application: responseData.data});
                onProceed && onProceed();
            } else {
                console.log('entity.fetchSelectedEntity.errorMessage ===> ', responseData.message);
                const hasNoApprovalRules = responseData && responseData.message && responseData.message.includes('no auth rules');
                const hasInsufficientApprovalRules = responseData && responseData.message && responseData.message.includes('Not enough');
                if(hasNoApprovalRules){
                    onProceed && onProceed(false, 'Authorisation Rules Need To Be Configured');
                }
                else if(hasInsufficientApprovalRules){
                    onProceed && onProceed(false, 'Not enough registered users as per Authorisation Rules settings. Please update the rules or ensure enough authorisers are registered');
                }
                else {
                    dispatch({
                        type: types.SHOW_RESPONSE_ERROR,
                        errorMessage: 'Couldn\'t retrieve the selected entity details.'
                    });
                }
            }
            loadingPageDataFinish(dispatch);
        } catch (error) {
            console.error('entity.fetchSelectedEntity.error ===> ', error);
            authCheck(dispatch, error);
            const status = error.response && error.response.status;
            loadingPageDataFinish(dispatch, true, status);
        }
    };
}

export function fetchShariahBusinessCurrentAccountRequest(applicationId, history, onFinish = null) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const responseData = await axios.get(SHARIAH_BCA_APPLICATION_URL + '/' + applicationId).then(response => response.data);

            if(responseData.success){
                const application = responseData.data;
                dispatch({type: types.LOAD_SELECTED_APPLICATION_SUCCESS, application});

                const pendingTasks = application && application.applicationStatus && application.applicationStatus.pendingTasks;

                let path = "/"
                if (!(application && application.legalEntity)) {
                    path = newAccountOpeningRoutes.newAccount;
                } else if (_.find(pendingTasks, task => (task.name === SHARIAH_BCA_PENDING_TASKS.confirmDetails))) {
                    path = shariahBcaRoutes.shariahBcaConfirmDetails;
                } else if (_.find(pendingTasks, task => (task.name === SHARIAH_BCA_PENDING_TASKS.confirmProduct))) {
                    path = shariahBcaRoutes.shariahBcaConfirmProduct;
                } else if (_.find(pendingTasks, task => (task.name === SHARIAH_BCA_PENDING_TASKS.kyc))) {
                    path = shariahBcaRoutes.shariahBcaKYC;
                }
                history.push(path);

            } else {
                console.log("user.loadSelectedApplication.errorMessage ===> ", responseData.message);
                dispatch({type: types.SHOW_RESPONSE_ERROR, errorMessage:  "Couldn't retrieve selected application."});
            }
            onFinish && onFinish()
        } catch (error) {
            console.log("user.loadSelectedApplication.error ===> ", error);
            authCheck(dispatch,error);
            onFinish && onFinish()
        }
    };
}

export function saveShariahBusinessCurrentAccount(id, custodyRequest, task, onComplete, email = null) {
    return async dispatch => {
        try {

            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            let responseData = null;
            if(email ===null){
                responseData = await axios.put(SHARIAH_BCA_APPLICATION_URL + '/complete-task/' + id + '/' + task, custodyRequest).then(response => response.data);
            }else{
                responseData = await axios.put(SHARIAH_BCA_APPLICATION_URL + '/complete-task/' + id + '/' + task+'?email='+ email, custodyRequest).then(response => response.data);
            }
            if (responseData.success) {
                onComplete && onComplete(true);
            }
        } catch (error) {
            console.log("entity.fetchCustody.error ===>", error);
        }
    }
}

