import React, {useState} from 'react';
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {businessOnlineRoutes} from "../../routers/routes";
import FormField from "../formField/FormField";
import ButtonsComponent from "../ButtonsComponent";
import Col from "react-bootstrap/es/Col";
import {saveBolOnboard} from "../../actions/businessOnline";

const ErrorLabel = ({error}) => (
    <div>
        <span className="form-error">{error}</span>
    </div>
);


export const BolContactInfo = (props) => {

    const businessOnlineApplication = useSelector(state => state.businessOnline && state.businessOnline);
    const businessOnline = useSelector(state => state.businessOnline && state.businessOnline.bolRequest);
    const loading = useSelector(state => state.loading);

    const [errors, setErrors] = useState({});
    const [contactTelephone, setContactTelephone] = useState('');
    const [contactEmailAddress, setContactEmailAddress] = useState('');

    const dispatch = useDispatch();


    const onNext = (e) => {
        e.preventDefault();
        const {history} = props;
        const onProceed = (success = true) => {
            if (success) {
                history.push(businessOnlineRoutes.complete);
            }
        };
        if (validateFields()) {
            dispatch(saveBolOnboard(businessOnlineApplication.id, _.extend({},
                businessOnline, {
                    'contactEmailAddress': contactEmailAddress,
                    'contactTelephone': contactTelephone
                }), 'UserTask_ContactInfo', onProceed));
        }
    };

    const validateFields = () => {
        const errors = {};
        if (contactEmailAddress && contactTelephone) {
            return true;
        } else {
            errors['contactEmailAddress'] = "Required";
            return false;
        }
        setErrors(errors);
        return _.isEmpty(errors)
    };

    const renderContact = () => {
        return (

                    <div className="flexColumn">
                        <div className="card-container-form">
                            <div className="section-title">Please enter your contact information for further
                                communication
                            </div>

                            <FormField id="email" error={errors && errors.email}>
                                <label>Email Address</label>
                                <input
                                    className="form-control"
                                    id="emailAddress"
                                    onChange={(event) => {
                                        setContactEmailAddress(event.target.value)
                                    }}
                                    type="text"
                                    value={contactEmailAddress}
                                />
                            </FormField>

                            <FormField id="telephone" error={errors && errors.telephone}>
                                <label>Contact Number</label>
                                <input
                                    className="form-control"
                                    id="telephone"
                                    onChange={(event) => {
                                        setContactTelephone(event.target.value)
                                    }}
                                    type="text"
                                    value={contactTelephone}
                                />
                            </FormField>

                        </div>
                    </div>

        )
    }


    return (
        <div className="container-fluid">
            <Col sm={12}>
                <div className="col-md-4 col-md-offset-3">
                    <h3>Contact Details</h3>

                </div>
            </Col>
            <hr/>
            <Col sm={12}>
                <div className="col-md-4 col-md-offset-3">
                    {renderContact()}
                </div>

            </Col>
            <hr/>

            <div className="row" style={{justifyContent: 'space-between'}}>
                <div className="col-md-6 col-md-offset-3">
                    <ButtonsComponent
                        history={props.history}
                        prevPage={businessOnlineRoutes.authorization}
                        onNext={onNext}/>
                </div>
            </div>

        </div>
    );

}

export default BolContactInfo;
