import React from 'react';
import FormField from "../formField/FormField";
import _ from "lodash";
import validate from "validate.js";

export default class ImpersonationCandidate extends React.Component {
    state = {errors: {}, form: {}, isSearchComplete: false};

    handleOnChange = ({target}, attribute, isCheckbox = false) => {
        const {form} = this.state;
        const value = isCheckbox ? !(form && form[attribute]) : _.trimStart(target.value);
        const updatedForm = _.extend({}, form, {[attribute]: value});
        this.setState({form: updatedForm})
    };

    onResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    validateForm = () => {
        const {form} = this.state;
        const options = {fullMessages: false};
        const constraints = {idNumber: {presence: true, length: {minimum: 1, message: 'required'}}};

        let errors = validate(form, constraints, options);
        if (!(form && form.termsAccepted)) {
            errors = _.extend({}, errors, validate({termsAccepted: null}, {termsAccepted: {presence: true}}, options))
        }
        this.setState({errors});
        return _.isEmpty(errors);
    };

    handleOnSubmit = () => {
        if (this.validateForm()) {
            const {onImpersonate} = this.props;
            const {form} = this.state;
            this.setState({isSearchComplete: false});
            const onComplete = () => this.setState({isSearchComplete: true});
            onImpersonate && onImpersonate(form, onComplete)
        }
    };

    _renderHeader() {
        return (
            <div>
                <div className="product-heading">
                    Act On Behalf Of Client
                </div>
                <div className="title-gradient"/>
            </div>
        )
    };

    _renderTerms() {
        const {form, errors} = this.state;
        return (
            <FormField
                className="form-group"
                error={errors && errors.termsAccepted && ["Terms And Conditions Should Be Accepted"]}
            >
                <div className="flexRow" style={{alignItems: "flex-start", textAlign: "left", cursor: "pointer"}}
                     onClick={(e) => {
                         errors && errors.termsAccepted && this.onResetError('termsAccepted');
                         this.handleOnChange(e, "termsAccepted", true)
                     }}
                >
                    <div className="icon-container">
                        <i
                            className={form && form.termsAccepted ? "fa fa-check-square-o" : "fa fa-square-o"}
                            style={{color: "rgb(0, 51, 170)", fontSize: 20, marginRight: 10}}
                        />
                    </div>
                    <p style={{margin: 0, color: "grey"}}>
                        You confirm that you have a valid record of the customer’s written / verbal instruction to open
                        the bank account on their behalf and accept you will be held responsible for any errors or
                        omissions.
                    </p>
                </div>
            </FormField>
        )
    }

    _renderFooter() {
        const {isSearchComplete, impersonation} = this.state;
        return (
            <div className="action-button-container" style={{width: "100%"}}>
                <div style={{flex: 1}}>
                    {
                        isSearchComplete && !impersonation &&
                        <span style={{color: "rgb(178,34,34)"}}>
                         The entered ID number is not linked to any entities.
                   </span>
                    }
                </div>
                <button
                    className="action-btn-secondary"
                    onClick={this.handleOnSubmit}
                    type="button"
                > Start Acting
                </button>
            </div>
        )
    }

    render() {
        const {errors, form} = this.state;
        return (
            <div>
                {this._renderHeader()}
                <div className="flexColumn">
                    <div className="popup-content">
                        <div className="card-container-form">
                            <FormField className="form-group" id="id" error={errors && errors.idNumber}>
                                <label htmlFor="idNumber">ID Number / Passport</label>
                                <input
                                    className="form-control"
                                    id="idNumber"
                                    onChange={(event) => {
                                        errors && errors.idNumber && this.onResetError('idNumber');
                                        this.handleOnChange(event, 'idNumber');
                                    }}
                                    type="text"
                                    value={form && form.idNumber || ''}
                                />
                            </FormField>
                            {this._renderTerms()}
                        </div>
                    </div>
                </div>

                {this._renderFooter()}
            </div>
        )
    }
}