import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import _ from 'lodash';
import {
    PanelGroup,
    Panel,
    Col,
    Badge,
    FormGroup,
    FormControl,
    Button,
    Modal,
    InputGroup
} from 'react-bootstrap';
import FormField from '../formField/FormField';
import Organogram from './organogram';
import ModalTitle from 'react-bootstrap/es/ModalTitle';
import ButtonGroup from 'react-bootstrap/es/ButtonGroup';
import HelpBlock from 'react-bootstrap/es/HelpBlock';
import NewRelatedParty from './NewRelatedParty';
import ReactLoading from 'react-loading';
import Popup from '../Popup';
import validate from 'validate.js';
import Row from 'react-bootstrap/es/Row';
import ConfirmDelete from './ConfirmDelete';
import * as entityActions from '../../actions/entity';
import { uploadDoc, verifyDoc, clearIndiviualInfo, fetchIndiviualInfo } from '../../actions/kyc';
import { bindActionCreators } from "redux";
import PanelFooter from 'react-bootstrap/lib/PanelFooter';

const BUSINESS_TYPE = {
    naturalPerson: 'Natural Person',
    individual: 'Individual',
    juristicEntity: 'Juristic Entity',
    nonFinancialBusiness:"Non-Financial Business"
};

class RelatedPartiesNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: null,
            newParty: false,
            errors: {},
            newRelatedPartyErrors: {},
            ogranShow: false,
            child: null,
            showOptions: false,
            newRP: {
                businessType: BUSINESS_TYPE.naturalPerson,
                identityDocument: {},
                proofOfAddressDocument: {},
                new: true
            },
            loading: false,
            removing: false,
            removingRP: null,
        };
    }

    handleSelect = (activeKey) => {
        this.setState({ activeKey });
    };

    handleCloseParty = () => {
        this.setState({ newParty: false })
    };

    handleNewRelatedParty = (form) => {
        form.preventDefault();
        const { handleAddRelatedParty, handleAddOwner } = this.props;
        const newRelatedPartyErrors = {};
        const { target: { elements } } = form;
        const name = elements[0].value;
        const registrationNumber = elements[1].value;
        const phoneNumber = elements[2].value;
        const shareHoldingPercentage = elements[3].value;
        if (!name)
            newRelatedPartyErrors.name = 'error';
        if (!registrationNumber)
            newRelatedPartyErrors.registrationNumber = 'error';
        if (!phoneNumber)
            newRelatedPartyErrors.phoneNumber = 'error';
        if (!shareHoldingPercentage)
            newRelatedPartyErrors.shareHoldingPercentage = 'error';
        if (_.isEmpty(newRelatedPartyErrors)) {
            if (!this.state.child)
                handleAddRelatedParty({ registrationNumber, phoneNumber, shareHoldingPercentage, name, new: true });
            else {
                const { child } = this.state;
                child.children ? child.children.push({
                    registrationNumber,
                    phoneNumber,
                    shareHoldingPercentage,
                    name,
                    new: true
                }) : child.children = [{ registrationNumber, phoneNumber, shareHoldingPercentage, name, new: true }];
                let parent;
                do {
                    parent = this.buildMainUpdate(child);
                } while (parent.parent);
                handleAddOwner(parent);
            }
            this.setState({ newRelatedPartyErrors, newParty: false, child: null })
        } else {
            this.setState({ newRelatedPartyErrors })
        }
    };

    handleSelectChange = (event, attribute) => {

        this.setState({ newRP: _.extend({}, this.state.newRP, { [attribute]: event.value }) });

    };

    addDoc = (fileType, file) => {
        const { newRP } = this.state;
        const strippedBase64 = file.base64.split(',')[1];
        const doc = {
            documentId: strippedBase64,
            documentType: fileType,
            name: file.name,
            verified: true,
        };

        this.setState({
            newRP: _.extend({}, newRP, {
                supportingDocuments: _.union(newRP.supportingDocuments, [doc])
            })
        });
    };

    onHandleUploadIdentityDocument = (base64, inProgress, onComplete, onSuccess) => {
        const { actions } = this.props;
        const { newRP } = this.state; 
        if(!newRP.identityDocument) {
            newRP.identityDocument = {};
        }

        const handleOnSuccess = (documentId) => {
            newRP.identityDocument.isUpload = true;
            newRP.identityDocument.updated = true;
            newRP.identityDocument.externalId = documentId;
            this.setState({ newRP });
            onSuccess && onSuccess(documentId);
        };
        actions.uploadDoc(base64, handleOnSuccess, null, inProgress, onComplete)
    };

    onHandleUploadProofOfAddress = (base64, inProgress, onComplete, onSuccess) => {
        const { actions } = this.props;
        const { newRP } = this.state;

        if (!newRP.proofOfAddressDocument) {
            newRP.proofOfAddressDocument = {};
        }

        const handleOnSuccess = (documentId) => {
            newRP.proofOfAddressDocument.isUpload = true;
            newRP.proofOfAddressDocument.updated = true;
            newRP.proofOfAddressDocument.externalId = documentId;
            this.setState({ newRP });
            onSuccess && onSuccess(documentId);
        };
        actions.uploadDoc(base64, handleOnSuccess, null, inProgress, onComplete)
    };

    onHandleUploadCompanyRegistrationDocument = (base64, inProgress, onComplete, onSuccess) => {
        const { actions } = this.props;
        const { newRP } = this.state;

        if (!newRP.CompanyRegistrationDocument) {
            newRP.CompanyRegistrationDocument = {};
        }

        const handleOnSuccess = (documentId) => {
            newRP.CompanyRegistrationDocument.isUpload = true;
            newRP.CompanyRegistrationDocument.updated = true;
            newRP.CompanyRegistrationDocument.externalId = documentId;
            this.setState({ newRP });
            onSuccess && onSuccess(documentId);
        };
        actions.uploadDoc(base64, handleOnSuccess, null, inProgress, onComplete)
    };

    loadingStart = () => {
        this.setState({ loading: true })
    };

    loadingFinish = () => {
        this.setState({ loading: false })
    };

    onCancel = () => {
        const { actions } = this.props;
        actions.removeSearched();
        actions.clearIndiviualInfo();
        actions.clearEntityInfo();
        _.defer(() => this.setState({
            newParty: false,
            newRP: { businessType: BUSINESS_TYPE.naturalPerson, supportingDocuments: [] },
            errors: {}
        }))
    };

    onChange = (event, attribute) => {
        let { target, target: { value } } = event;
        value = _.trimStart(value);
        if (target.type === 'number')
            value = parseInt(value, 10);
        const newRP = _.extend({}, this.state.newRP, { [attribute]: value });
        this.setState({ newRP });
    };

    onIndividualSearch = (onComplete) => {
        this.setState({ errors: {} });
        this.loadingStart();

        const onFinish = (finish, individual) => {
            let { newRP } = this.state;
            this.loadingFinish();
            newRP = { ...newRP, ...individual };
            this.setState({ newRP });
            onComplete && onComplete();
        };
        const onError = () => {
            this.loadingFinish();
            this.setState({ errors: { registrationNumber: ['Could not find Person from search, check Identity number.'] } })
        }
        this.props.actions.fetchIndiviualInfo(this.state.newRP.registrationNumber, onFinish, "RP", onError);
    };

    onEntitySearch = (onComplete) => {

        this.setState({ errors: {} });
        this.loadingStart();
        const onFinish = (entity) => {
            this.loadingFinish();
            let { newRP } = this.state;
            this.loadingFinish();
            newRP = { ...newRP, ...entity };
            this.setState({ newRP });
            onComplete && onComplete();
            onComplete && onComplete();
        };
        const onError = () => {
            this.loadingFinish();
            this.setState({ errors: { registrationNumber: ['Could not find entity from search, check registration number.'] } })
        }
        this.props.actions.fetchEntityInfo(this.state.newRP.registrationNumber, onFinish, null, onError);
    };

    resetError = (attribute) => {
        const { errors } = this.state;
        this.setState({ errors: _.extend({}, errors, { [attribute]: null }) })
    };

    onNewRPSubmit = () => {

        const { handleAddRelatedParty, handleAddOwner, address } = this.props;
        const { newRP } = this.state;
        const isNaturalPerson = newRP.businessType === BUSINESS_TYPE.naturalPerson || newRP.businessType === BUSINESS_TYPE.individual;
        const { actions } = this.props;
        const isFormValid = this.validateForm();

        if (isFormValid) {
            if (isNaturalPerson) {
                newRP.registeredName = `${newRP.firstName} ${newRP.lastName}`;
                newRP.firstName = newRP.firstName;
                newRP.name = newRP.registeredName;
                newRP.idNumber = newRP.registrationNumber;
                newRP.phoneNumber = newRP.contactNumber;
                newRP.proofOfAddressDocument = newRP.proofOfAddressDocument;
                newRP.identityDocument = newRP.identityDocument;
                if (!newRP.new) {
                    newRP.new = true;
                } 
                newRP.address = {
                    streetAddressLine1: newRP.streetAddress,
                    suburb: newRP.suburb,
                    crcCityId: newRP.city,
                    zipCode: newRP.postalCode,
                    crcCountryId: newRP.country,
                    crcRegionId: newRP.province
                };
            }
            newRP.shareHoldingPercentage = newRP.sharePercentage;
            actions.clearIndiviualInfo();
            actions.clearEntityInfo();
            if (!this.state.child)
                handleAddRelatedParty(newRP);
            else {
                const { child } = this.state;
                child.children ? child.children.push({ ...newRP }) : child.children = [{ ...newRP }];
                let parent;
                do {
                    parent = this.buildMainUpdate(child);
                } while (parent.parent);
                handleAddOwner(parent);
            }
            _.defer(() => this.setState({
                addNewRP: false,
                newParty: false,
                newRP: { businessType: BUSINESS_TYPE.naturalPerson, supportingDocuments: [] }
            }))
        }
    };

    validateForm = () => {
        const { newRP } = this.state;
        const options = { fullMessages: false };
        if(!newRP.proofOfAddressDocument && !newRP.identityDocument){
            newRP.proofOfAddressDocument = {};
            newRP.identityDocument = {};
        }
        const isNaturalPerson = newRP.businessType === BUSINESS_TYPE.naturalPerson || newRP.businessType === BUSINESS_TYPE.individual;
        let constraints;
        let fileUploadErrors = {};
        if (!isNaturalPerson) {
            constraints = {
                registeredName: { presence: true, length: { minimum: 1, message: 'required' } },
                registrationNumber: { presence: true, length: { minimum: 1, message: 'required' } },
                telephoneNumber: { presence: true, numericality: true, length: { minimum: 1, message: 'required' } },
                sharePercentage: {
                    presence: true,
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        lessThanOrEqualTo: 100,
                        message: 'should be between 0 and 100',
                        allowFloat: true,
                    },
                },
                physicalAddress: { presence: true, length: { minimum: 1, message: 'required' } },
                headOfficeAddress: { presence: true, length: { minimum: 1, message: 'required' } },
                street: { presence: true, length: { minimum: 1, message: 'required' } }
            };

        } else {
            constraints = {
                firstName: { presence: true, length: { minimum: 1, message: 'required' } },
                registrationNumber: { presence: true, length: { minimum: 1, message: 'required' } },
                contactNumber: { presence: true, numericality: true, length: { minimum: 1, message: 'required' } },
                sharePercentage: {
                    presence: true,
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        lessThanOrEqualTo: 100,
                        message: 'should be between 0 and 100',
                        allowFloat: true,
                    },
                },
                
                lastName: { presence: true, length: { minimum: 1, message: 'required' } },
                emailAddress: { presence: true, email: true, length: { minimum: 1, message: 'required' } },
                capacity: { presence: true, length: { minimum: 1, message: 'required' } },
                streetAddress: { presence: true, length: { minimum: 1, message: 'required' } },
                suburb: { presence: true, length: { minimum: 1, message: 'required' } },
                city: { presence: true, length: { minimum: 1, message: 'required' } },
                province: { presence: true, length: { minimum: 1, message: 'required' } },
                postalCode: { presence: true, length: { minimum: 1, message: 'required' } },
                country: { presence: true, length: { minimum: 1, message: 'required' } },
            };

            //Additional check for "Proof of Address" file upload
            if (newRP && newRP.proofOfAddressDocument && !newRP.proofOfAddressDocument.externalId) {
                fileUploadErrors.proofOfAddress = ['Document required'];
            }

            // Additional check for "Proof of ID" file upload
            if (newRP && newRP.identityDocument && !newRP.identityDocument.externalId) {
                fileUploadErrors.proofOfId = ['Document required'];
            }

        }

        const errors = validate(newRP, constraints, options);

        //Combine text input errors and file upload errors into a single object
        const combinedErrors = {
            ...errors,
            ...fileUploadErrors,
        };

        // this.setState({ errors: errors });
        // return _.isEmpty(errors);
        this.setState({ errors: combinedErrors });
        return _.isEmpty(combinedErrors);
    };

    selectRPType = (event, selected) => {
        event.preventDefault();
        this.setState({ newRP: { businessType: selected, supportingDocuments: [] }, errors: {} });
    };

    buildMainUpdate(child) {
        const { parent } = child;
        const childId = _.findIndex(parent.children, (item) => item.goldTierId === child.goldTierId);
        parent.children[childId] = child;
        return parent;
    }

    onRelatedPartyAddressUpdate = ({ target }, number) => {
        const { kycStructure, handleRelatedPartyAddressChange } = this.props;
        const relatedParty = kycStructure.children[number];
        const address = relatedParty.address ? relatedParty.address : {};
        const { name, value } = target;
        address[name] = value;
        if(name == 'zipCode') {
            address.crcCountryId = 'SOUTH AFRICA';
        }
        handleRelatedPartyAddressChange(number, address);
    };

    handelOptions = (item) => {
        const { depth } = item;
        if (depth === 0) {
            this.setState({ newParty: true, ogranShow: false, child: null });
        }
        if (depth >= 1) {
            this.setState({ showOptions: true, ogranShow: false, child: item });
        }
    };

    onRemoveRP = (value) => {
        this.setState({ removing: true, removingRP: value })
    }

    confirmRemove = () => {
        const { handleRemoveRelatedParty } = this.props;
        handleRemoveRelatedParty(this.state.removingRP);
        this.cancelRemove();
    }
    cancelRemove = () => {
        this.setState({ removing: false, removingRP: null });
    }


    removeConfirm = () => {
        const { kycStructure: { children } } = this.props;
        const party = children[this.state.removingRP];
        const detail = `${party.name}`;

        return (
            <ConfirmDelete
                type={'Authorised Person'}
                confirmAction={this.confirmRemove}
                cancelAction={this.cancelRemove}
                detail={detail}
            />
        )
    }

    allowOnlyCharacters = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^A-Za-z\s]/g, '');
        event.target.value = value;
    }

    allowOnlyNumbers = (event) => {
        let { value } = event.target;
        // Replace characters that are not letters or spaces with an empty string
        value = value.replace(/[^0-9]/g, '');
        event.target.value = value;
    }

    
    
    validateIndividual = (party, partyIndex) => {
        const { errors } = this.state;
        const options = { fullMessages: false };

        if(party.businessType === BUSINESS_TYPE.juristicEntity || party.businessType === BUSINESS_TYPE.nonFinancialBusiness ){
            const constraintsInd = {
                registrationNumber: { presence: true, length: { minimum: 1, message: 'required' } },
                contactNumber: { presence: true, length: { minimum: 1, message: 'required' } },
                shareHoldingPercentage: {
                    presence: true,
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        lessThanOrEqualTo: 100,
                        message: 'should be between 0 and 100',
                        allowFloat: true,
                    },
                },
            };

            const constraints = {
                streetAddressLine1: { presence: true, length: { minimum: 1, message: 'required' } },
                suburb: { presence: true, length: { minimum: 1, message: 'required' } },
                crcCityId: { presence: true, length: { minimum: 1, message: 'required' } },
                crcRegionId: { presence: true, length: { minimum: 1, message: 'required' } },
                zipCode: { presence: true, length: { minimum: 1, message: 'required' } },
                crcCountryId: { presence: true, length: { minimum: 1, message: 'required' } },
            };
            
            const activeParty = { ...party };
            const partyIndErrors = validate(activeParty, constraintsInd, options);
            const activePartyAddress = { ...party.address };
            const partyAddressErrors = validate(activePartyAddress, constraints, options);
        
            const updatedErrors = { ...errors };
            updatedErrors[partyIndex] = { ...partyIndErrors, ...partyAddressErrors };

            // If there are no errors in the party, also clear the party-level errors
            if (_.isEmpty(partyIndErrors) && _.isEmpty(partyAddressErrors)) {
                updatedErrors[partyIndex] = null;
            }


            this.setState((prevState) => {
                // Update state based on the validation result
                return {
                    errors: { ...prevState.errors, ...updatedErrors },
                };
            });
        
            return _.isEmpty(partyIndErrors) && _.isEmpty(partyAddressErrors);

        }
        
        if(party.businessType === BUSINESS_TYPE.naturalPerson || party.businessType === BUSINESS_TYPE.individual){
            const constraintsInd = {
                idNumber: { presence: true, length: { minimum: 1, message: 'required' } },
                phoneNumber: { presence: true, length: { minimum: 1, message: 'required' } },
                emailAddress: {
                    presence: true,
                    email: { message: 'should be a valid email address' },
                },
                capacity: { presence: true, length: { minimum: 1, message: 'required' } },
                shareHoldingPercentage: {
                    presence: true,
                    numericality: {
                        greaterThanOrEqualTo: 0,
                        lessThanOrEqualTo: 100,
                        message: 'should be between 0 and 100',
                    },
                },
            };
        
            const constraints = {
                streetAddressLine1: { presence: true, length: { minimum: 1, message: 'required' } },
                suburb: { presence: true, length: { minimum: 1, message: 'required' } },
                crcCityId: { presence: true, length: { minimum: 1, message: 'required' } },
                crcRegionId: { presence: true, length: { minimum: 1, message: 'required' } },
                zipCode: { presence: true, length: { minimum: 1, message: 'required' } },
                crcCountryId: { presence: true, length: { minimum: 1, message: 'required' } },
            };
        
            const activeParty = { ...party };
            const partyIndErrors = validate(activeParty, constraintsInd, options);
            const activePartyAddress = { ...party.address };
            const partyAddressErrors = validate(activePartyAddress, constraints, options);
        
            const updatedErrors = { ...errors };
            updatedErrors[partyIndex] = { ...partyIndErrors, ...partyAddressErrors };

            // If there are no errors in the party, also clear the party-level errors
            if (_.isEmpty(partyIndErrors) && _.isEmpty(partyAddressErrors)) {
                updatedErrors[partyIndex] = null;
            }


            this.setState((prevState) => {
                // Update state based on the validation result
                return {
                    errors: { ...prevState.errors, ...updatedErrors },
                };
            });
        
            return _.isEmpty(partyIndErrors) && _.isEmpty(partyAddressErrors);
        }
       
    };
    
    
    renderIndividual = (party, i) => {
        const { address } = party;
        const { errors } = this.state;
        let partyErrors;
        if (errors) {
            // Get errors for the current party if available, otherwise, use an empty object
            partyErrors = errors[i] || {};
        }


        return (
            <div>
                <Col md={6}>
                    <div className="section-title">
                        <span>Details</span>
                    </div>
                    <FormField className="form-group" error={partyErrors && partyErrors.idNumber}>
                        <label>Identity Number: <span className='text-danger'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            value={party.idNumber}
                            disabled={true}
                            name='idNumber'
                            placeholder="Enter Identity Number"
                            onChange={(e) => {
                                this.props.allowOnlyNumbers(e);
                                this.props.handleRelatedPartyChange(e, i);
                            }}
                        />
                        
                    </FormField>
                    <FormField className="form-group" error={partyErrors && partyErrors.phoneNumber}>
                        <label>Telephone Number: <span
                            className='text-danger'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            value={party.phoneNumber}
                            name='phoneNumber'
                            placeholder="Enter Number"
                            onChange={(e) => {
                                this.props.allowOnlyNumbers(e);
                                this.props.handleRelatedPartyChange(e, i);
                            }}
                        />
                        
                    </FormField>
                    <FormField className="form-group" error={partyErrors && partyErrors.emailAddress}>
                        <label>Email Address: <span
                            className='text-danger'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            value={party.emailAddress}
                            name='emailAddress'
                            placeholder="Enter text"
                            onChange={(e) => this.props.handleRelatedPartyChange(e, i)}
                        />
                        
                    </FormField>
                    <FormField className="form-group" error={partyErrors && partyErrors.capacity}>
                        <label>Capacity: <span className='text-danger'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            value={party.capacity}
                            name='capacity'
                            placeholder="Enter text"
                            onChange={(e) => {
                                this.props.allowOnlyCharacters(e);
                                this.props.handleRelatedPartyChange(e, i);
                            }}
                        />
                        
                    </FormField>
                    <FormField className="form-group" error={partyErrors && partyErrors.shareHoldingPercentage}>
                        <label>ShareHolding Percentage: <span className='text-danger'>*</span></label>
                        <InputGroup>
                            <input
                                type="number"
                                className="form-control"
                                value={party.shareHoldingPercentage}
                                name='shareHoldingPercentage'
                                placeholder="Enter Percentage"
                                onChange={(e) => {
                                this.props.allowOnlyNumbers(e);
                                this.props.handleRelatedPartyChange(e, i);
                            }}
                                min='0'
                                max='100'
                            />
                            
                            <InputGroup.Addon>%</InputGroup.Addon>
                        </InputGroup>
                    </FormField>
                </Col>
                <Col md={6}>
                    <div className="section-title">
                        <span>Primary Address <span className='text-danger'>*</span></span>
                    </div>

                    <FormField className="form-group" id="regAddress" error={partyErrors && partyErrors.streetAddressLine1} >
                        <label htmlFor="registeredAddressLin1">Address <span class="text-danger">*</span></label>
                        <input
                            className="form-control"
                            id="registeredAddressLine1"
                            name='streetAddressLine1'
                            onChange={(event) => {
                                this.onRelatedPartyAddressUpdate(event, i);
                            }}
                            type="text"
                            value={(address && address.streetAddressLine1) ? address.streetAddressLine1 : ""}

                        />
                    </FormField>

                    <FormField className="form-group" id="line2" error={partyErrors && partyErrors.suburb}>
                        <label htmlFor="registeredAddressLine2">Suburb <span class="text-danger">*</span></label>
                        <input
                            className="form-control"
                            id="registeredAddressLine2"
                            name='suburb'
                            onChange={(event) => {
                                this.props.allowOnlyCharacters(event);
                                this.onRelatedPartyAddressUpdate(event, i);
                            }}
                            type="text"
                            value={(address && address.suburb) ? address.suburb : ""}
                        />
                    </FormField>

                    <FormField className="form-group" id="regCity" error={partyErrors && partyErrors.crcCityId}>
                        <label htmlFor="registeredCity">City <span class="text-danger">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="registeredCity"
                            name='crcCityId'
                            value={(address && address.crcCityId) ? address.crcCityId : ""}
                            onChange={(event) => {
                                this.props.allowOnlyCharacters(event);
                                this.onRelatedPartyAddressUpdate(event, i);
                            }}
                        />
                    </FormField>

                    <FormField className="form-group" id="regProvince" error={partyErrors && partyErrors.crcRegionId}>
                        <label htmlFor="stateOrProvince">State/Province <span class="text-danger">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="stateOrProvince"
                            name='crcRegionId'
                            value={(address && address.crcRegionId) ? address.crcRegionId : ""}
                            onChange={(event) => {
                                this.props.allowOnlyCharacters(event);
                                this.onRelatedPartyAddressUpdate(event, i);
                            }}
                        />
                    </FormField>

                    <FormField className="form-group" id="regPostalCode" error={partyErrors && partyErrors.zipCode}>
                        <label htmlFor="stateOrProvince">Zip/Postal Code <span class="text-danger">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="registoredZipOrPostalCode"
                            name='zipCode'
                            value={(address && address.zipCode) ? address.zipCode : ""}
                            onChange={(event) => {
                                this.props.allowOnlyNumbers(event);
                                this.onRelatedPartyAddressUpdate(event, i);
                            }}

                        />
                    </FormField>

                    <FormField className="form-group" id="regCountry" error={partyErrors && partyErrors.crcCountryId}>
                        <label htmlFor="stateOrProvince">Country <span class="text-danger">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="country"
                            disabled="disabled"
                            name='crcCountryId'
                            // value={(address && address.crcCountryId) ? address.crcCountryId : ""}
                            value={(address && address.crcCountryId) ? address.crcCountryId : "SOUTH AFRICA"}
                            onChange={(event) => {
                                this.props.allowOnlyCharacters(event);
                                this.onRelatedPartyAddressUpdate(event, i);
                            }}
                        />
                    </FormField>
                </Col>
            </div>
        );
    };

    renderJuristic = (party, i) => {
        const { address } = party;
        const { errors } = this.state;
        let partyErrors;
        if (errors) {
            // Get errors for the current party if available, otherwise, use an empty object
            partyErrors = errors[i] || {};
          }


        return (
            <div>
                <div className="card-container-form" >
                    <div className="section-title">
                        <span>Details</span>
                    </div>
                    <FormField className="form-group" error={partyErrors && partyErrors.registrationNumber}>
                        <label>Registration Number: <span className='text-danger'>*</span></label>
                        <input
                            type="text"
                            value={party.registrationNumber}
                            className="form-control"
                            name='registrationNumber'
                            placeholder="Enter text"
                            // onChange={(e) => this.props.handleRelatedPartyChange(e, i)}
                            onChange={(event) => {
                                this.props.allowOnlyNumbers(event);
                                this.props.handleRelatedPartyChange(event, i);
                            }}
                        />
                    </FormField>
                    <FormField className="form-group" error={partyErrors && partyErrors.contactNumber}>
                        <label>Telephone Number: <span className='text-danger'>*</span></label>
                        <input
                            type="text"
                            value={party.contactNumber}
                            name='contactNumber'
                            className="form-control"
                            placeholder="Enter Number"
                            // onChange={(e) => this.props.handleRelatedPartyChange(e, i)}
                            onChange={(event) => {
                                this.props.allowOnlyNumbers(event);
                                this.props.handleRelatedPartyChange(event, i);
                            }}
                        />
                    </FormField>
                    <FormField className="form-group" error={partyErrors && partyErrors.shareHoldingPercentage}>
                        <Row style={{ marginLeft: 0, marginRight: 0 }}>
                            <label>ShareHolding Percentage: <span
                                className='text-danger'>*</span></label>
                        </Row>
                        <InputGroup>
                            <input
                                type="number"
                                className="form-control"
                                value={party.shareHoldingPercentage}
                                name='shareHoldingPercentage'
                                placeholder="Enter Percentage"
                                // onChange={(e) => this.props.handleRelatedPartyChange(e, i)}
                                onChange={(event) => {
                                    this.props.allowOnlyDecimal(event);
                                    this.props.handleRelatedPartyChange(event, i);
                                }}
                                min='0'
                                max='100'
                            />
                            <InputGroup.Addon>%</InputGroup.Addon>
                        </InputGroup>
                    </FormField>

                    <div className="section-title">
                        <span>Primary Address <span className='text-danger'>*</span></span>
                    </div>

                    <FormField className="form-group" id="regAddress" error={partyErrors && partyErrors.streetAddressLine1}>
                        <label htmlFor="registeredAddressLin1">Address <span class="text-danger">*</span></label>
                        <input
                            className="form-control"
                            id="registeredAddressLine1"
                            name='streetAddressLine1'
                            onChange={(event) => {
                                this.onRelatedPartyAddressUpdate(event, i);
                            }}
                            type="text"
                            value={(address && address.streetAddressLine1) ? address.streetAddressLine1 : ""}
                        />
                    </FormField>

                    <FormField className="form-group" id="line2"  error={partyErrors && partyErrors.suburb}>
                        <label htmlFor="registeredAddressLine2">Suburb <span class="text-danger">*</span></label>
                        <input
                            className="form-control"
                            id="registeredAddressLine2"
                            name='suburb'
                            // onChange={(event) => {
                            //     this.onRelatedPartyAddressUpdate(event, i);
                            // }}
                            onChange={(event) => {
                                this.props.allowOnlyCharacters(event);
                                this.onRelatedPartyAddressUpdate(event, i);
                            }}
                            type="text"
                            value={(address && address.suburb) ? address.suburb : ""}
                        />
                    </FormField>

                    <FormField className="form-group" id="regCity" error={partyErrors && partyErrors.crcCityId}>
                        <label htmlFor="registeredCity">City <span class="text-danger">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="registeredCity"
                            name='crcCityId'
                            value={(address && address.crcCityId) ? address.crcCityId : ""}
                            // onChange={(event) => {
                            //     this.onRelatedPartyAddressUpdate(event, i);
                            // }}
                            onChange={(event) => {
                                this.props.allowOnlyCharacters(event);
                                this.onRelatedPartyAddressUpdate(event, i);
                            }}
                        />
                    </FormField>

                    <FormField className="form-group" id="regProvince" error={partyErrors && partyErrors.crcRegionId}>
                        <label htmlFor="stateOrProvince">State/Province <span class="text-danger">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="stateOrProvince"
                            name='crcRegionId'
                            value={(address && address.crcRegionId) ? address.crcRegionId : ""}
                            // onChange={(event) => {
                            //     this.onRelatedPartyAddressUpdate(event, i);
                            // }}
                            onChange={(event) => {
                                this.props.allowOnlyCharacters(event);
                                this.onRelatedPartyAddressUpdate(event, i);
                            }}
                        />
                    </FormField>

                    <FormField className="form-group" id="regPostalCode" error={partyErrors && partyErrors.zipCode}>
                        <label htmlFor="stateOrProvince">Zip/Postal Code <span class="text-danger">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="registoredZipOrPostalCode"
                            name='zipCode'
                            value={(address && address.zipCode) ? address.zipCode : ""}
                            onChange={(event) => {
                                this.props.allowOnlyNumbers(event);
                                this.onRelatedPartyAddressUpdate(event, i);
                            }}
                        />
                    </FormField>

                    <FormField className="form-group" id="regCountry" error={partyErrors && partyErrors.crcCountryId}>
                        <label htmlFor="stateOrProvince">Country <span class="text-danger">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            id="country"
                            disabled="disabled"
                            name='crcCountryId'
                            value={(address && address.crcCountryId) ? address.crcCountryId : "SOUTH AFRICA"}
                            onChange={(event) => {
                                this.props.allowOnlyCharacters(event);
                                this.onRelatedPartyAddressUpdate(event, i);
                            }}
                        />
                    </FormField>
                </div>
            </div>
        );
    };

    renderRelatedParty = (party, i) => {
        const wellStyles = { maxWidth: 400, margin: '0 auto 10px', float: 'right' };
        return (
            <Panel eventKey={i} key={i} bsStyle="primary">
                <Panel.Heading>
                    <Panel.Title toggle>
                        <span className="caret" /><Badge>OPEN</Badge>&nbsp;
                        {  
                            party.businessType === 'Juristic Entity' ? party.registeredName : party.name
                        }
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>

                    {
                        party.businessType === BUSINESS_TYPE.naturalPerson ||
                            party.businessType === BUSINESS_TYPE.individual
                            ? this.renderIndividual(party, i) : this.renderJuristic(party, i)
                    }
                    <div className="row" style={{ margin: '10px 0' }} >
                        <Col md={12} style={{ padding: '10px 0' }} >
                            <div className="btn-block" style={wellStyles}>
                                <Button bsStyle="primary" bsSize="small" style={{ margin: '10px 10px', float: 'right' }} 
                                    // onClick={() => { this.validateIndividual(party, i); this.props.handleSave(); this.setState({ activeKey: null }); }}
                                    onClick={() => {
                                        if (this.validateIndividual(party, i)) {
                                            this.props.handleSave();
                                            this.setState({ activeKey: null });
                                        } else {
                                            // Optionally, you can handle the case when there are validation errors
                                            console.log("Validation failed. Please check the form for errors.");
                                        }
                                    }}>
                                    Save
                                </Button>
                                <Button bsStyle="danger" bsSize="small" style={{ margin: '10px 10px', float: 'right' }} onClick={() => this.onRemoveRP(i)}>
                                    Remove
                                </Button>
                            </div>
                        </Col>
                    </div>
                </Panel.Body>

            </Panel>
        )
    };


    renderOrgan = () => {
        return (
            <Modal show={this.state.ogranShow} bsSize="large" dialogClassName={'organModal'}
                onHide={() => this.setState({ ogranShow: false })}>
                <Modal.Body style={{ height: '85vh' }}>
                    <Organogram entity={this.props.kycStructure} clickEvent={this.handelOptions} />
                </Modal.Body>
                <Modal.Footer>
                    <HelpBlock>Click and drag with the mouse to move around.</HelpBlock>
                    <HelpBlock>Use the scroll wheel to zoom out.</HelpBlock>
                </Modal.Footer>
            </Modal>
        )
    };

    renderOptions = () => {
        const { child } = this.state;
        const back = () => {
            this.setState({ showOptions: false, ogranShow: true })
        };
        if (child) {
            return (
                <Modal show={this.state.showOptions} onHide={() => this.setState({ showOptions: false })}>
                    <Modal.Header>
                        <button type="button" className="close" onClick={back}><span aria-hidden="true">&lt; Back</span>
                        </button>
                        <ModalTitle>Options</ModalTitle>
                    </Modal.Header>
                    <Modal.Body>
                        <h3>{child.name}</h3>
                        <p>{`Registration Number: ${child.registrationNumber}`}</p>
                        <p>{`Phone Number: ${child.phoneNumber}`}</p>
                        <p>{`Share Holding Percentage: ${child.shareHoldingPercentage}`}</p>
                    </Modal.Body>
                    {
                        child.businessType !== "Individual" &&
                        <Modal.Footer>
                            <ButtonGroup>
                                <Button onClick={() => this.setState({ newParty: true, showOptions: false })}>Add
                                    Owner</Button>
                            </ButtonGroup>
                        </Modal.Footer>
                    }
                </Modal>
            )
        } else {
            return false;
        }
    };

    render() {
        const { kycStructure } = this.props;
        const { errors, newParty } = this.state;
        return (
            <div>
                <PanelGroup
                    accordion
                    id="accordion-related-parties"
                    activeKey={this.state.activeKey}
                    onSelect={this.handleSelect}
                >
                    {
                        _.isEmpty(kycStructure.children) ?
                            <span>No Related parties for this entity.</span> :
                            kycStructure.children.map((party, i) => this.renderRelatedParty(party, i))
                    }
                   
                </PanelGroup>

                {
                    newParty &&
                    <Popup onClose={() => this.onCancel()}>
                        <NewRelatedParty
                            errors={errors}
                            onCancel={this.onCancel}
                            onChange={this.onChange}
                            onHandleUploadIdentityDocument={this.onHandleUploadIdentityDocument}
                            onHandleUploadProofOfAddress = {this.onHandleUploadProofOfAddress}
                            onHandleUploadCompanyRegistrationDocument = {this.onHandleUploadCompanyRegistrationDocument}
                            handleSelectChange={this.handleSelectChange}
                            onIndividualSearch={this.onIndividualSearch}
                            onEntitySearch={this.onEntitySearch}
                            onResetError={this.resetError}
                            onSubmit={this.onNewRPSubmit}
                            newRP={this.state.newRP}
                            selectRPType={this.selectRPType}
                            idDoc={true}
                        />
                        {
                            this.state.loading &&
                            <div className="inner-spinner-container">
                                <ReactLoading type="spokes" color="#444" />
                            </div>
                        }
                    </Popup>
                }

                {
                    this.renderOrgan()
                }
                {
                    this.renderOptions()
                }
                {
                    this.state.removing && this.removeConfirm()
                }
                {/* <hr /> */}
                <div className="row" style={{margin:0}}>
                    <ButtonGroup style={{float:'left'}}>
                        <Button style={{width:'auto', margin:'5px'}} bsStyle={'primary'}  onClick={() => this.setState({ newParty: true })} >Add Related Party</Button>
                        <Button style={{width:'auto', margin:'5px'}} bsStyle={'primary'}  onClick={() => this.setState({ ogranShow: true })} >Show Organogram</Button>
                    </ButtonGroup>
                </div>
            </div>
        )
    }

}

function mapStateToProps({ kyc, impersonation, loading, individual }) {
    return {
        kyc,
        individual,
        impersonation: impersonation && impersonation.impersonation ? impersonation.impersonation : {},
        loading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...entityActions,
            uploadDoc, verifyDoc, clearIndiviualInfo, fetchIndiviualInfo
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RelatedPartiesNew);
