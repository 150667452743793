export const BOL_PRODUCT_CATEGORY_IDS = {
    collections: "collections",
    payments: "payments",
    interAccountTransfers: "interAccountTransfers",
    other: "other",
};

export const USER_PROFILE_RELEASE_LEVELS_SECTIONS = [
    {
        sectionTitle: "Payments",
        sectionId: "payments",
        categoryID: BOL_PRODUCT_CATEGORY_IDS.payments,
        alwaysShow: true,
        sections: [
            {
                sectionTitle: "Beneficiary Details",
                sectionId: "beneficiaryDetails",
                attribute: "paymentsBeneficiaryForm",
            },
            {
                sectionTitle: "Payments",
                sectionId: "PaymentsDetails",
                attribute: "paymentsPaymentsForm",
            }
        ]
    },
    {
        sectionTitle: "Collections",
        sectionId: "collections",
        categoryID: BOL_PRODUCT_CATEGORY_IDS.collections,
        alwaysShow: true,
        sections: [
            {
                sectionTitle: "Debtors Details",
                sectionId: "debtorsDetails",
                attribute: "collectionsDebtorsForm",
            },
            {
                sectionTitle: "Collections",
                sectionId: "collections",
                attribute: "collectionsForm",
            }
        ]
    },
    {
        sectionTitle: "Inter-account Transfers",
        sectionId: "interAccountTransfers",
        attribute: "ownTransfersForm",
        alwaysShow: true,
        categoryID: BOL_PRODUCT_CATEGORY_IDS.interAccountTransfers
    }
];

export const USER_PROFILE_PROCESSING_SECTIONS = {
    payments: "payments",
    collections: "collections",
    interAccountTransfers: "interAccountTransfers",
    administration: "administration",
    beneficiaryDetails: "beneficiaryDetails",
    debtorsDetails: "debtorsDetails"
};

export const USER_PROFILE_PROCESSING_OPTIONS = {

    [USER_PROFILE_PROCESSING_SECTIONS.payments]: {
        sectionTitle: "Payments",
        sectionId: USER_PROFILE_PROCESSING_SECTIONS.payments,
        categoryID: BOL_PRODUCT_CATEGORY_IDS.payments,
        isMultiSelect: false,
        sections: [
            {
                sectionTitle: "Itemized Transaction on Statements",
                sectionId: "itemizedTransactionStatements",
                categoryID: BOL_PRODUCT_CATEGORY_IDS.payments,
                processingTypes: []
            },
            {
                sectionTitle: "Consolidated Transactions on Statement",
                sectionId: "consolidatedTransactionStatements",
                categoryID: BOL_PRODUCT_CATEGORY_IDS.payments,
                processingTypes: []
            },

            {
                sectionTitle: "Do you want to load your Beneficiaries to Business Online?",
                sectionId: "loadBeneficiariesToBol",
                categoryID: BOL_PRODUCT_CATEGORY_IDS.payments,
                processingTypes: []
            }

        ]
    },

    [USER_PROFILE_PROCESSING_SECTIONS.collections]: {
        sectionTitle: "Collections",
        sectionId: USER_PROFILE_PROCESSING_SECTIONS.collections,
        categoryID: BOL_PRODUCT_CATEGORY_IDS.collections,
        isMultiSelect: false,
        sections: [
            {
                sectionTitle: "Itemized Transaction on Statements",
                sectionId: "itemizedTransactionStatements",
                categoryID: BOL_PRODUCT_CATEGORY_IDS.collections,
                processingTypes: []
            },
            {
                sectionTitle: "Consolidated Transactions on Statement",
                sectionId: "consolidatedTransactionStatements",
                categoryID: BOL_PRODUCT_CATEGORY_IDS.collections,
                processingTypes: []
            },

            {
                sectionTitle: "Do you want to load your Debtors to Business Online?",
                sectionId: "loadDebtorsToBol",
                categoryID: BOL_PRODUCT_CATEGORY_IDS.collections,
                processingTypes: []
            }
        ]
    }
};

export const BOL_PRODUCTS_SERVICES_CATEGORIES = [
    {
        sectionTitle: "Payments",
        sectionId: BOL_PRODUCT_CATEGORY_IDS.payments,
        optionRequired: true,
        options: [
            {
                description: "Same Day Value",
                id: "sameDayValue",
            },
            {
                description: "Same Day Value - Urgent",
                id: "sameDayValueUrgent",
            },
            {
                description: "One Day/Two Day Serive",
                id: "oneTwoDayService",
            }
        ]
    },
    {
        sectionTitle: "Collections",
        sectionId: BOL_PRODUCT_CATEGORY_IDS.collections,
        optionRequired: true,
        options: [
            {
                description: "Same Day Value",
                id: "sameDayValue",
            },
            {
                description: "One Day/Two Day Serive",
                id: "oneTwoDayService",
            }
        ]
    },
    {
        sectionTitle: "Inter-account Transfers",
        sectionId: BOL_PRODUCT_CATEGORY_IDS.interAccountTransfers,
        optionRequired: false,
        options: [
            {
                description: "Same Day Value",
                id: "sameDayValue",
            }
        ]
    }
];