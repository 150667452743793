import React from "react";
import _ from "lodash";
import {Table} from 'react-bootstrap';

import "../../../styles/ConfirmApplication.css";
import "../../../styles/Global.css";
import "react-datepicker/dist/react-datepicker.css";
import Popup from "../../Popup";
import {FormComponent} from "./FormComponent";

export const SettlementComponent = (props) => {

    if (props.groupedInstructions) {
        return (
            <div className="col-md-12">

                <div className="greyText fontSize">
                    <div style={{minHeight: 10}}>
                        {/* striped bordered condensed hover responsive */}
                        <Table striped responsive>
                            <thead style={{backgroundColor: "#0033aa", color: "white"}}>
                                <tr>
                                    <th>Currency</th>
                                    <th>Customer</th>
                                    <th>Instrument</th>
                                    <th>Load For</th>
                                    <th>Correspondent</th>
                                    <th>Correspondent Account No</th>
                                    <th>Beneficiary Type</th>
                                    <th>Beneficiary Bank</th>
                                    <th>Beneficiary Account No</th>
                                    <th>Primary</th>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>

                            <InstructionData
                                groupedInstructions={props.groupedInstructions}
                                currencies={props.currencies}
                                handleSSIPrimaryChange={props.handleSSIPrimaryChange}
                                handleEditInstruction={props.handleEditInstruction}
                                handleRemoveInstruction={props.handleRemoveInstruction}
                            />

                        </Table>
                    </div>
                </div>

                {
                    props.removeSSI && (
                        <div className="page-container">
                            <Popup onClose={() => props.handleRemoveSSI(false)}>
                                <div className="col-md-12">
                                    <div>
                                        <p>Remove SSI</p>
                                    </div>
                                    <hr/>
                                    <div>
                                        <p>Are you sure you want to remove this SSI?</p>
                                    </div>
                                    <hr/>
                                    <div className="pull-right">
                                        <button type="button" className="btn btn-secondary"
                                                onClick={(e) => props.handleRemoveSSIToggle(false, 0)}>Cancel
                                        </button>
                                        &nbsp;
                                        <button type="button" className="btn btn-danger"
                                                onClick={(e) => props.handleRemoveSSI(e)}>Remove
                                        </button>

                                    </div>
                                </div>
                            </Popup>
                        </div>
                    )
                }
            </div>
        )
    } else {
        return (
            <div>
                <p>Customer has no instructions</p>
            </div>
        )
    }

}

const InstructionData = (props) => {
    console.log("groupedInstructions", props.groupedInstructions);
    console.log("currencies", props.currencies);

    if (props.groupedInstructions && props.groupedInstructions.length > 0) {

        return props.currencies.map(({name}, index) => {
            const groupedObject = _.find(props.groupedInstructions, {currency: name});
            //console.log("currency objects", groupedObject);
            const currencyInstructions = groupedObject && groupedObject.instructions;
            //console.log("currencyInstructions", currencyInstructions);

            if (currencyInstructions && currencyInstructions.length > 0) {
                return (
                    <tbody key={index}>
                    {
                        currencyInstructions.length > 0 &&
                        currencyInstructions.map(
                            ({
                                 currency,
                                 instrument,
                                 id,
                                 customerName,
                                 isFinancial,
                                 intermediaryBank,
                                 intermediaryAccountNo,
                                 ibanNumber,
                                 beneficiaryBank,
                                 beneficiaryBranch,
                                 beneficiaryBranchIBT,
                                 beneficiaryAccountNo,
                                 primarySSI,
                                 editable,
                                 indexKey
                             }, index) =>

                                <tr key={id}>
                                    {index === 0 && (<td rowSpan={currencyInstructions.length}>{currency}</td>)}
                                    <td>{instrument}</td>
                                    <td>{customerName}</td>
                                    <td>{intermediaryBank}</td>
                                    <td>{intermediaryAccountNo}</td>
                                    <td>{ibanNumber}</td>
                                    <td>{beneficiaryBank}</td>
                                    <td>{beneficiaryBranch}</td>
                                    <td>{beneficiaryBranchIBT}</td>
                                    <td>{beneficiaryAccountNo}</td>
                                    <td style={{textAlign: "center"}}>
                                        <div onClick={() => props.handleSSIPrimaryChange(currency, indexKey)}
                                             value={primarySSI}>
                                            <i
                                                className={primarySSI ? "fa fa-check-circle" : "fa fa-circle-o"}
                                                style={{
                                                    color: "rgb(0, 51, 170)",
                                                    fontSize: 20,
                                                    marginRight: 10,
                                                    cursor: "pointer"
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td style={{textAlign: "center"}}>
                                        <div onClick={(e) => props.handleRemoveInstruction(true, indexKey)}>
                                            <i style={{
                                                color: "rgb(0, 51, 170)", fontSize: 20,
                                                marginRight: 10, color: "#0033aa",
                                                cursor: "pointer"
                                            }} className={"fa fa-trash"}/>
                                        </div>
                                    </td>
                                    <td style={{textAlign: "center"}}>
                                        <div onClick={(e) => props.handleEditInstruction(true, indexKey)}>
                                            <i style={{
                                                color: "rgb(0, 51, 170)", fontSize: 20,
                                                marginRight: 10, color: "#0033aa",
                                                cursor: "pointer"
                                            }} className={"fa fa-edit"}/>
                                        </div>
                                    </td>
                                </tr>
                        )
                    }
                    </tbody>
                )
            }
        })
    } else {
        return (
            <tbody>
            <tr>
                <td colSpan="9">Customer has no instructions</td>
            </tr>
            </tbody>
        )
    }
}
