import React, {useEffect, useImperativeHandle, useState} from 'react';
import _ from 'lodash';
import {DataList} from 'primereact/components/datalist/DataList';
import {useDispatch, useSelector} from 'react-redux';
import ReactLoading from 'react-loading';
import NewRelatedParty from './NewRelatedParty';
import {
    addRelatedParty,
    clearIndiviualInfo,
    fetchIndiviualInfo,
    removeRelatedParty,
    updateRelatedParty
} from '../../../actions/kyc';
import {clearEntityInfo, fetchEntityInfo, removeSearched} from '../../../actions/entity';
import '../../../styles/kyc.css';
import '../../../styles/App.scss';
import validate from 'validate.js';
import Popup from '../../Popup';
import FormField from '../../formField/FormField';
import {HelpBlock} from 'react-bootstrap';

const ACTIONS = {
    add: 'add',
    update: 'update',
    none: 'none'
};

const BUSINESS_TYPE = {
    naturalPerson: 'Natural Person',
    juristicEntity: 'Juristic Entity'
};

export const RelatedPartiesComponent = React.forwardRef((props, ref) => {

    const applicationId = useSelector(state => state.application && state.application.application.id);
    const individual = useSelector(state => state.individual && state.individual.individual);
    const entityInfo = useSelector(state => state.entityInfo && state.entityInfo.entityInfo);
    const relatedParties = useSelector(state => state.application.application.legalEntity.relatedParties ? state.application.application.legalEntity.relatedParties : []);

    const [action, setAction] = useState(ACTIONS.none);
    const [addNewRP, setAddNewRP] = useState(false);
    const [errors, setErrors] = useState({searchEntity: false, searchInd: false});
    const [isLoading, setIsLoading] = useState(false);
    const [newRP, setNewRP] = useState({businessType: BUSINESS_TYPE.naturalPerson, supportingDocuments: []});


    useImperativeHandle(ref, () => {
        return {
            validateRelatedParties: validateRelatedParties
        };
    });

    const dispatch = useDispatch();

    useEffect(() => {
            if (individual) {
                let person = {};
                person.registrationNumber = individual.idNumber;
                person.registeredName = individual.firstName;
                person.lastName = individual.lastName;
                person.businessType = BUSINESS_TYPE.naturalPerson;
                person.telephones = individual.telephones;
                person.supportingDocuments = [];
                setNewRP(person);

            }
        }, [individual]
    );

    useEffect(() => {
            if (entityInfo) {
                let entity = {};

                entity.businessType = BUSINESS_TYPE.juristicEntity;
                entity.contactNumber = entityInfo.telephoneNumber;

                entity.street = entityInfo.address && entityInfo.address.street ? entityInfo.address.street : '';
                entity.registeredName = entityInfo.tradingNames;
                entity.registrationNumber = entityInfo.registrationNumber;
                entity.supportingDocuments = [];
                setNewRP(entity);
            }
        }, [entityInfo]
    );

    const onChange = (event, attribute) => {
        let {target, target: {value}} = event;
        value = _.trimStart(value);
        if (target.type === 'number')
            value = parseInt(value, 10);
        setNewRP(_.extend({}, newRP, {[attribute]: value}));
    };

    const handleAddNewRPClick = () => {
        setAction(ACTIONS.add);
        setAddNewRP(true);
        setNewRP(_.extend({}, newRP, {supportingDocuments: []}));
    };

    const handleSelectChange = (event, attribute) => {
        setNewRP(_.extend({}, newRP, {[attribute]: event.value}));
    };

    const addDoc = (fileType, file) => {
        const strippedBase64 = file.base64.split(',')[1];
        const doc = {
            documentId: strippedBase64,
            documentType: fileType,
            name: file.name,
            verified: true,
        };

        setNewRP(_.extend({}, newRP, {
            supportingDocuments: _.union(newRP.supportingDocuments, [doc])
        }));
    };

    const selectRPType = (event, selected) => {
        event.preventDefault();
        setNewRP({businessType: selected, supportingDocuments: []});
        setErrors({});
    };

    const removeRP = (selected) => {
        dispatch(removeRelatedParty(applicationId, selected));
    };

    const resetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    };

    const validateRelatedParties = () => {
        const options = {fullMessages: false};
        const constraints = {relatedParties: {presence: true}};

        const errors = validate({relatedParties: _.size(relatedParties) > 0 ? relatedParties : null}, constraints, options);
        setErrors(errors);
        return _.isEmpty(errors);
    };

    const validateForm = () => {
        const options = {fullMessages: false};
        const isNaturalPerson = newRP.businessType === BUSINESS_TYPE.naturalPerson || newRP.businessType === BUSINESS_TYPE.individual;
        let constraints;
        if (!isNaturalPerson) {
            constraints = {
                registeredName: {presence: true, length: {minimum: 1, message: 'required'}},
                registrationNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                contactNumber: {presence: true, numericality: true, length: {minimum: 1, message: 'required'}},
                sharePercentage: {
                    presence: true,
                    numericality: {greaterThan: -1 , lessThanOrEqualTo: 100}
                },
                physicalAddress: {presence: true, length: {minimum: 1, message: 'required'}},
                headOfficeAddress: {presence: true, length: {minimum: 1, message: 'required'}},
                street: {presence: true, length: {minimum: 1, message: 'required'}}
            };
        } else {
            constraints = {
                registeredName: {presence: true, length: {minimum: 1, message: 'required'}},
                registrationNumber: {presence: true, length: {minimum: 1, message: 'required'}},
                contactNumber: {presence: true, numericality: true, length: {minimum: 1, message: 'required'}},
                sharePercentage: {
                    presence: true,
                    numericality: {greaterThan: -1 , lessThanOrEqualTo: 100}
                },
                lastName: {presence: true, length: {minimum: 1, message: 'required'}},
                emailAddress: {presence: true, email: true, length: {minimum: 1, message: 'required'}},
                capacity: {presence: true, length: {minimum: 1, message: 'required'}}
            };
        }
        const errors = validate(newRP, constraints, options);

        setErrors(errors);
        return _.isEmpty(errors);
    };

    const onNewRPSubmit = () => {
        if (validateForm()) {
            if (action === ACTIONS.update) {
                dispatch(updateRelatedParty(applicationId, newRP));
                dispatch(clearIndiviualInfo());
                dispatch(clearEntityInfo());
            } else {
                dispatch(addRelatedParty(applicationId, newRP));
                dispatch(clearIndiviualInfo());
                dispatch(clearEntityInfo());
            }
            _.defer(() => {
                setAddNewRP(false);
                setNewRP({businessType: BUSINESS_TYPE.naturalPerson, supportingDocuments: []});
            });
        }
    };

    const onIndividualSearch = (onComplete) => {
        setErrors({});
        loadingStart();

        const onFinish = (done, data) => {
            loadingFinish();
            setNewRP({
                registrationNumber: data.idNumber,
                registeredName: `${data.firstName}`,
                lastName : `${data.lastName}`,
                businessType: 'Natural Person'
            });
            onComplete && onComplete();
        };
        const onError = () => {
            loadingFinish();
            setErrors({registrationNumber: ['Individual not found on search.']});
        };
        dispatch(fetchIndiviualInfo(newRP.registrationNumber, onFinish, null, onError));
    };

    const onEntitySearch = (onComplete) => {

        setErrors({});
        loadingStart();
        const onFinish = (data) => {
            loadingFinish();
            const rpData = {
                registrationNumber: data.registrationNumber,
                registeredName: data.registeredName,
                businessType: 'Juristic Entity'
            };
            if (data.primaryDataSource === 'PUBLIC'){
                rpData.street= `${data.registeredAddress.street} ${data.registeredAddress.suburb} ${data.registeredAddress.city} ${data.registeredAddress.province}`;
            } else {
                rpData.street = _.isEmpty(data.addresses) ? '' :
                `${data.addresses[0].street} ${data.addresses[0].suburb} ${data.addresses[0].city} ${data.addresses[0].province}`;
            }
            setNewRP(rpData);
            onComplete && onComplete();
        };
        const onError = () => {
            setErrors({registrationNumber: ['Entity not found on Search.']});
            loadingFinish();
        };
        dispatch(fetchEntityInfo(newRP.registrationNumber, null, onFinish, onError));
    };

    const loadingStart = () => {
        setIsLoading(true);
    };

    const loadingFinish = () => {
        setIsLoading(false);
    };

    const onCancel = () => {
        dispatch(removeSearched());
        dispatch(clearIndiviualInfo());
        dispatch(clearEntityInfo());
        _.defer(() => {
            setAddNewRP(false);
            setNewRP({businessType: BUSINESS_TYPE.naturalPerson, supportingDocuments: []});
            setErrors({});
        });
    };

    const renderRelatedParty = (relatedParty) => {
        if (!relatedParty) return null;

        return (
            relatedParty.actionType !== 'REMOVED' &&
            <div>
                <div className="flexRow" style={{justifyContent: 'space-between'}}>
                    <div>
                        <label>{relatedParty.registeredName}</label>
                    </div>
                    <div>
                        <div style={{padding: 0}}>
                            <a
                                className="btn"
                                role="button"
                                id="remove-btn"
                                onClick={() => removeRP(relatedParty)}>
                                <i className="fa fa-times" aria-hidden="true"/>
                            </a>
                        </div>
                    </div>

                </div>
                <hr style={{marginTop: 10, marginBottom: 10}}/>
            </div>);
    };

    const renderRelatedParties = () => {
        const hasRelatedParties = _.size(relatedParties) > 0;
        if (!hasRelatedParties) return null;
        return (
            <div>
                <DataList
                    className="ui-datalist-nobullets"
                    itemTemplate={renderRelatedParty.bind(this)}
                    paginator={true}
                    rows={3}
                    value={relatedParties}
                />
                <br/>
            </div>
        );
    };

    return (
        <div className="flexColumn">
            <div className="card-container-form">
                <div className="section-title">
                    <span>Related Parties</span>
                    <HelpBlock>
                        <small>Beneficial Owners, Controllers or Senior Management</small>
                    </HelpBlock>
                </div>
                {renderRelatedParties()}
                {
                    !(addNewRP) &&

                    <FormField
                        className="form-group"
                        error={errors && errors.relatedParties ? ['At least One Related Party Should Be Added'] : ''}>
                        <a className="btn btn-secondary addButton"
                           onClick={() => {
                               errors && errors.relatedParties && resetError('relatedParties');
                               handleAddNewRPClick();
                           }}
                        >Add a related party</a>
                        {errors && errors.relatedParties && <div><br/><br/></div>}
                    </FormField>
                }

                {
                    addNewRP &&
                    <Popup onClose={() => onCancel()}>
                        <NewRelatedParty
                            addDoc={addDoc}
                            errors={errors}
                            onCancel={onCancel}
                            onChange={onChange}
                            handleSelectChange={handleSelectChange}
                            onIndividualSearch={onIndividualSearch}
                            onEntitySearch={onEntitySearch}
                            onResetError={resetError}
                            onSubmit={onNewRPSubmit}
                            newRP={newRP}
                            selectRPType={selectRPType}
                        />
                        {
                            isLoading &&
                            <div className="inner-spinner-container">
                                <ReactLoading type="spokes" color="#444"/>
                            </div>
                        }
                    </Popup>
                }
                {
                    isLoading &&
                    <div className="inner-spinner-container">
                        <ReactLoading type="spokes" color="#444"/>
                    </div>
                }
            </div>
        </div>
    );

});

export default RelatedPartiesComponent;
