import React from "react";
import _ from "lodash";
import validate from "validate.js";
import {DataList} from "../../../node_modules/primereact/components/datalist/DataList";
import Toggler from "../Toggler";
import FormField from "../formField/FormField";
import {bindActionCreators} from "redux";
import * as actions from "../../actions/bol";
import {clearIndiviualInfo, fetchIndiviualInfo} from "../../actions/kyc";
import connect from "react-redux/es/connect/connect";
import Popup from "../Popup";
import NaturalPersonForm from "./NaturalPersonForm";
import ReactLoading from "react-loading";

const TOGGLE = {
    tokenForm: "tokenForm",
    recipientForm: "recipientForm",
};

const TOKEN_RECIPIENT_CANDIDATE = "tokenRecipientCandidate"

class TokenDeliveryDetails extends React.Component{
    state = {
        errors: {},
        toggle: {
            [TOGGLE.recipientForm]: false,
            [TOGGLE.tokenForm]: false,
        }
    };
    componentDidMount() {
        this._setDefaults();
    }

    _setDefaults() {
        const {actions, newBolProfile} = this.props;
        const tokenDetails = newBolProfile && newBolProfile.tokenDetails;
        if (_.isEmpty(tokenDetails)) {
            const updatedTokensDetails = _.extend({}, tokenDetails, {bulkDelivery: false});
            actions.onChangeValue("newBolProfile", "tokenDetails", updatedTokensDetails);
        }
    }

    handleToggle = (attribute, hardToggle = null) => {
        const updates = (attribute === TOGGLE.tokenForm) ? {form: null} : {};
        this.setState(prevState => {
            const value = (hardToggle !== null) ? hardToggle : !(prevState.toggle && prevState.toggle[attribute]);
            const toggle = _.extend({}, prevState.toggle, {[attribute]: value});
            return {toggle, ...updates}
        });
    };

    handleOnResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    handleOnChange = ({target}, attribute, innerAttribute) => {
        const {actions, newBolProfile} = this.props;
        const tokenDetails = newBolProfile && newBolProfile.tokenDetails;

        let value = target.value;
        if (innerAttribute) {
            value = _.extend({}, tokenDetails && tokenDetails[attribute], {[innerAttribute]: value})
        }

        const updatedTokensDetails = _.extend({}, tokenDetails, {[attribute]: value});
        actions.onChangeValue("newBolProfile", "tokenDetails", updatedTokensDetails);
    };

    handleAddRecipient = (recipient) => {
        const {actions, newBolProfile} = this.props;
        const {errors} = this.state;
        const tokenDetails = newBolProfile && newBolProfile.tokenDetails;

        const selectedRecipientDetails = _.pick(recipient, ["address", "contactNumber", "emailAddress", "firstName", "idNumber", "lastName"]);

        const isFound = _.find(tokenDetails && tokenDetails.tokenRecipients, r => (r.idNumber === selectedRecipientDetails.idNumber));

        let tokenRecipients;

        if (isFound) {
            tokenRecipients = _.map(tokenDetails && tokenDetails.tokenRecipients, r => (
                (r.idNumber === recipient.idNumber) ? selectedRecipientDetails : r
            ));
        } else {
            tokenRecipients = _.union(tokenDetails && tokenDetails.tokenRecipients, [selectedRecipientDetails]);
        }

        const updatedTokenDetails = _.extend({}, tokenDetails, {tokenRecipients});

        errors && errors.tokenRecipients && this.handleOnResetError("tokenRecipients");
        actions.onChangeValue("newBolProfile", "tokenDetails", updatedTokenDetails);
        actions.clearIndiviualInfo(TOKEN_RECIPIENT_CANDIDATE);
        this.handleToggle(TOGGLE.recipientForm);
    };

    handleBulkTokenDelivery = (bulkDelivery) => {
        const {actions, newBolProfile} = this.props;
        const tokenDetails = newBolProfile && newBolProfile.tokenDetails;

        let updatedTokenDetails;
        if (bulkDelivery) {
            updatedTokenDetails = _.extend({}, tokenDetails, {bulkDelivery})
        } else {
            this.setState({errors:{}});
            updatedTokenDetails = {bulkDelivery};
        }
        actions.onChangeValue("newBolProfile", "tokenDetails", updatedTokenDetails);
    };

    handleRemoveRecipient = (recipient) => {
        const {actions, newBolProfile} = this.props;
        const tokenDetails = newBolProfile && newBolProfile.tokenDetails;
        const filteredTokenRecipients = _.filter(tokenDetails && tokenDetails.tokenRecipients, r => (r.idNumber !== recipient.idNumber));
        const tokenRecipients = _.size(filteredTokenRecipients) > 0 ? filteredTokenRecipients : null
        const updatedTokenDetails = _.extend({}, tokenDetails, {tokenRecipients})
        actions.onChangeValue("newBolProfile", "tokenDetails", updatedTokenDetails);
    };

    handleOnSearch = (formType, idNumber, onComplete = null) => {
        const {actions} = this.props;

        this.setState({isLoading: true});
        const onFinish = () => {
            this.setState({isLoading: false});
            onComplete && onComplete();
        };
        const onError = () => {
            this.setState({isLoading: false,
                errors: {idNumber: ['Individual not found on search.']}
            });
            // setErrors({});
        };
        actions.fetchIndiviualInfo(idNumber, onFinish, TOKEN_RECIPIENT_CANDIDATE, onError);
    };

    validateTokenDetails = () =>{
        const {newBolProfile} = this.props;
        const tokenDetails = newBolProfile && newBolProfile.tokenDetails;

        const options = {fullMessages: false};
        const constraints = {users: {presence: true}};

        let errors = validate(tokenDetails, constraints, options);

        if (tokenDetails && tokenDetails.bulkDelivery) {
            const tokenDetailsConstraints = {
                numberOfTokensIssued: {presence: true, numericality: true, length: {minimum: 1, message: "Required"}},
                numberOfTokensReassigned: {
                    presence: true,
                    numericality: true,
                    length: {minimum: 1, message: "Required"}
                },
                tokenRecipients: {presence: true},
            };
            errors = _.extend({}, errors, validate(tokenDetails, tokenDetailsConstraints, options))
        }

        this.setState({errors});
        return _.isEmpty(errors);
    };

    _renderTokenRecipientsTemplate = (person) => {
        if (!person) return null;
        return (
            <div>
                <div className="flexRow" style={{padding: 0}}>
                    <div className="col-md-3 col-sm-3 col-xs-3" style={{padding: 0}}>
                        <label>{person.firstName + ' ' + person.lastName}</label>
                    </div>
                    <div className="col-md-8 col-sm-8 col-xs-8" style={{padding: 0}}>
                        <label>{person.idNumber}</label>
                    </div>

                    <div className="col-md-1 col-sm-1 col-xs-1" style={{padding: 0}}>
                        <a
                            className="btn"
                            role="button"
                            id="remove-btn"
                            onClick={() => this.handleRemoveRecipient(person)}>
                            <i className="fa fa-times" aria-hidden="true"/>
                        </a>
                    </div>
                </div>
                <hr/>
            </div>
        );
    };

    _renderTokenRecipient() {
        const {newBolProfile} = this.props;
        const tokenDetails = newBolProfile && newBolProfile.tokenDetails;
        const tokenRecipients = tokenDetails && tokenDetails.tokenRecipients;
        const hasTokenRecipients = _.size(tokenRecipients) > 0;
        if (!hasTokenRecipients) return null;
        return (
            <div>
                <div className="row">
                    <div className="col-md-3 col-sm-3 col-xs-3">
                        <label style={{color: "black"}}><b>Names</b></label>
                    </div>
                    <div className="col-md-8 col-sm-8 col-xs-8" style={{paddingLeft: 8}}>
                        <label style={{color: "black"}}><b>Identity Number</b></label>
                    </div>
                    <div className="col-md-1 col-sm-1 col-xs-1"/>
                </div>
                <DataList
                    className="ui-datalist-nobullets"
                    itemTemplate={this._renderTokenRecipientsTemplate}
                    paginator={true}
                    rows={3}
                    value={tokenRecipients}
                />
            </div>
        )
    }

    _renderPopUps(){
        const {actions} = this.props;
        const {isLoading, toggle, errors} = this.state;
        if (toggle && toggle[TOGGLE.recipientForm]) {
            return (
                <Popup onClose={() => {
                    actions && actions.clearIndiviualInfo(TOKEN_RECIPIENT_CANDIDATE);
                    this.handleToggle(TOGGLE.recipientForm)
                }}>
                    <NaturalPersonForm
                        title={"Token Recipient Details"}
                        onSubmit={this.handleAddRecipient}
                        onSearch={this.handleOnSearch}
                        person={this.props.candidate}
                        formType={TOGGLE.recipientForm}
                        errorList={errors}
                    />
                    {
                        isLoading &&
                        <div className="inner-spinner-container">
                            <ReactLoading type="spokes" color="#444"/>
                        </div>
                    }
                </Popup>
            )
        }
        return null
    }

    render() {
        const {newBolProfile} = this.props;
        const {errors} = this.state;
        const tokenDetails = newBolProfile && newBolProfile.tokenDetails;
        const bulkDelivery = tokenDetails && tokenDetails.bulkDelivery;
        return (
            <div className="card-container-form">
                <div className="section-title">
                    <span>Bulk Delivery Recipient/Instruction</span>
                </div>

                <Toggler
                    label="Do you wish to have bulk delivery of tokens?"
                    isOn={bulkDelivery}
                    onToggle={this.handleBulkTokenDelivery}
                />
                <br/>

                {
                    bulkDelivery &&
                    <div>

                        <FormField className="form-group"
                                   error={errors && errors.numberOfTokensIssued}>
                            <label htmlFor="ownerId">Number of new tokens to be issued</label>
                            <input
                                className="form-control"
                                onChange={(event) => {
                                    errors && errors.numberOfTokensIssued && this.handleOnResetError('numberOfTokensIssued');
                                    this.handleOnChange(event, 'numberOfTokensIssued');
                                }}
                                type="text"
                                value={tokenDetails && tokenDetails.numberOfTokensIssued ? tokenDetails.numberOfTokensIssued : ''}
                            />
                        </FormField>
                        <FormField className="form-group"
                                   error={errors && errors.numberOfTokensReassigned}>
                            <label htmlFor="ownerId">Number of new tokens to be reassigned</label>
                            <input
                                className="form-control"
                                onChange={(event) => {
                                    errors && errors.numberOfTokensReassigned && this.handleOnResetError('numberOfTokensReassigned');
                                    this.handleOnChange(event, 'numberOfTokensReassigned');
                                }}
                                type="text"
                                value={tokenDetails && tokenDetails.numberOfTokensReassigned ? tokenDetails.numberOfTokensReassigned : ''}
                            />
                        </FormField>

                        <div className="section-title">
                            <span>Token Recipients</span>
                        </div>

                        {this._renderTokenRecipient()}

                        <FormField
                            className="form-group"
                            error={errors && errors.tokenRecipients ? ["At Least On Token Recipient Should be Added"] : ""}
                        >
                            <div>
                                <a
                                    className="btn btn-secondary addButton"
                                    onClick={() => {
                                        errors && errors.tokenRecipients && this.handleOnResetError('tokenRecipients');
                                        this.handleToggle(TOGGLE.recipientForm)
                                    }}
                                >Add Token Recipient</a>
                            </div>
                            {errors && errors.tokenRecipients && <div><br/><br/></div>}
                        </FormField>
                    </div>
                }
                {this._renderPopUps()}
            </div>
        )
    }
}

function mapStateToProps({bol, individual}) {
    return {
        newBolProfile: bol && bol.newBolProfile ? bol.newBolProfile : {},
        candidate: individual && individual[TOKEN_RECIPIENT_CANDIDATE] ? individual[TOKEN_RECIPIENT_CANDIDATE] : {},
    }
}

function mapDispatchToProps(dispatch) {
    return {actions: bindActionCreators({...actions, clearIndiviualInfo, fetchIndiviualInfo}, dispatch)}
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(TokenDeliveryDetails);
