import React from "react";
import _ from "lodash";
import validate from "validate.js";
import FormField from "../formField/FormField";
import Select from 'react-select';
import 'react-select/dist/react-select.css';

const ACCOUNT_DETAILS_FORM_DEFAULTS = {
    balanceStatements: false,
    allowableTransactions: {
        pay: false,
        collect: false,
        both: false,
    }
};

export default class AccountDetailsForm extends React.Component {
    state = {
        accounts: this.props.accounts,
        form: ACCOUNT_DETAILS_FORM_DEFAULTS,
        errors: {}
    };

    handleOnChange = ({target}, attribute, innerAttribute) => {
        const {form} = this.state;
        let value = target.value;
        if (innerAttribute) {
            value = _.extend({}, form && form[attribute], {[innerAttribute]: value});
        }
        const updateForm = _.extend({}, form, {[attribute]: value});
        this.setState({form: updateForm});
    };

    handleSelectChange = (event, attribute, innerAttribute, isCheckbox = true) => {
        const {form} = this.state;
        let value = isCheckbox ? event.target.checked : event.value;
        if (innerAttribute) {
            value = _.extend({}, form && form[attribute], {[innerAttribute]: value});
        }
        const updateForm = _.extend({}, form, {[attribute]: value});
        this.setState({form: updateForm});
    };
    handleCheckBoxSelect = (isChecked, attribute, innerAttribute) => {
        const {form} = this.state;
        let value = isChecked;
        if (innerAttribute) {
            value = _.extend({}, form && form[attribute], {[innerAttribute]: value});
        }
        const updateForm = _.extend({}, form, {[attribute]: value});
        this.setState({form: updateForm});
    };

    onResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    validateForm = () => {
        const {form} = this.state;
        const options = {fullMessages: false};

        const constraints = {
            accountNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            paymentLimit: {presence: true, length: {minimum: 1, message: 'required'}},
            transactionLimit: {presence: true, numericality: true, length: {minimum: 1, message: 'required'}},
        };

        const errors = validate(form, constraints, options);

        this.setState({errors});
        return _.isEmpty(errors);
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validateForm()) {
            const {onSubmit} = this.props;
            onSubmit && onSubmit(this.state.form);
        }
    };

    _renderFooter() {
        return (
            <div className="action-button-container" style={{width: "100%"}}>
                <div/>
                <button
                    className="action-btn-secondary"
                    onClick={this.handleSubmit}
                    type="submit"
                >Add
                </button>
            </div>
        )
    }

    _renderHeader() {
        const {title} = this.props;
        if (!title) return null;
        return (
            <div>
                <div className="product-heading">
                    {title}
                </div>
                <div className="title-gradient"/>
            </div>
        )
    }

    render() {
        const {form, errors} = this.state;
        const {accounts} = this.props;
        const hasAccounts = _.size(accounts) > 0;
        const accountOptions = _.map(accounts, (acc) => ({
            value: acc.accountNumber,
            label: `${acc.name} | ${acc.accountNumber}`
        }));
        const allowableTransactions = form && form.allowableTransactions;
        return (
            <div>
                {this._renderHeader()}

                <div className="popup-content">
                    <div className="flexColumn">
                        <div className="card-container-form">
                            <FormField className="form-group"
                                       error={errors && errors.accountNumber ? (hasAccounts ? ["One Should Be Selected"] : errors.accountNumber) : ""}>
                                <label htmlFor="accountNumber">Account Number</label>
                                <Select
                                    name="accountNumber"
                                    onChange={(event) => {
                                        errors && errors.accountNumber && this.onResetError('accountNumber');
                                        this.handleSelectChange(event, 'accountNumber', null, false);
                                    }}
                                    options={accountOptions}
                                    value={form && form.accountNumber ? form.accountNumber : ''}
                                />
                            </FormField>

                            <FormField className="form-group" error={errors && errors.paymentLimit}>
                                <label htmlFor="paymentLimit">Payment Limit</label>
                                <input
                                    className="form-control"
                                    onChange={(event) => {
                                        errors && errors.paymentLimit && this.onResetError('paymentLimit');
                                        this.handleOnChange(event, 'paymentLimit');
                                    }}
                                    id="paymentLimit"
                                    type="text"
                                    value={form && form.paymentLimit || ''}
                                />
                            </FormField>

                            <FormField className="form-group" error={errors && errors.transactionLimit}>
                                <label htmlFor="transactionLimit">Limit Per Transaciton</label>
                                <input
                                    className="form-control"
                                    onChange={(event) => {
                                        errors && errors.transactionLimit && this.onResetError('transactionLimit');
                                        this.handleOnChange(event, 'transactionLimit');
                                    }}
                                    type="text"
                                    value={form && form.transactionLimit || ''}
                                />
                            </FormField>

                            <div className="flexColumn" style={{alignItems: "flex-start", padding: 0, margin: 0}}>
                                <div
                                    className="selection-list-item" style={{padding: 1}}
                                    onClick={() => {
                                        errors && errors.allowableTransactions && this.handleOnResetError('allowableTransactions');
                                        this.handleCheckBoxSelect(!(form && form.balanceStatements), 'balanceStatements');
                                    }}>
                                    <div className="icon-container">
                                        <i
                                            className={form && form.balanceStatements ? "fa fa-check-square-o" : "fa fa-square-o"}
                                            style={{color: "rgb(0, 51, 170)", fontSize: 19, marginRight: 10}}
                                        />
                                    </div>
                                    Viewing Of Balance And Statements
                                </div>

                                <br/>
                                <div className="title">
                                    <span>Do you want to:</span>
                                </div>

                                <div
                                    className="selection-list-item" style={{padding: 1}}
                                    onClick={() => {
                                        errors && errors.allowableTransactions && this.handleOnResetError('allowableTransactions');
                                        this.handleCheckBoxSelect(!(allowableTransactions && allowableTransactions.pay), 'allowableTransactions', 'pay');
                                    }}>
                                    <div className="icon-container">
                                        <i
                                            className={allowableTransactions && allowableTransactions.pay ? "fa fa-check-square-o" : "fa fa-square-o"}
                                            style={{color: "rgb(0, 51, 170)", fontSize: 19, marginRight: 10}}
                                        />
                                    </div>
                                    Pay out of this account?
                                </div>

                                <div
                                    className="selection-list-item" style={{padding: 1}}
                                    onClick={() => {
                                        errors && errors.allowableTransactions && this.handleOnResetError('allowableTransactions');
                                        this.handleCheckBoxSelect(!(allowableTransactions && allowableTransactions.collect), 'allowableTransactions', 'collect');
                                    }}>
                                    <div className="icon-container">
                                        <i
                                            className={allowableTransactions && allowableTransactions.collect ? "fa fa-check-square-o" : "fa fa-square-o"}
                                            style={{color: "rgb(0, 51, 170)", fontSize: 19, marginRight: 10}}
                                        />
                                    </div>
                                    Collect in to this account?
                                </div>


                                <div
                                    className="selection-list-item" style={{padding: 1}}
                                    onClick={() => {
                                        errors && errors.allowableTransactions && this.handleOnResetError('allowableTransactions');
                                        this.handleCheckBoxSelect(!(allowableTransactions && allowableTransactions.both), 'allowableTransactions', 'both');
                                    }}>
                                    <div className="icon-container">
                                        <i
                                            className={allowableTransactions && allowableTransactions.both ? "fa fa-check-square-o" : "fa fa-square-o"}
                                            style={{color: "rgb(0, 51, 170)", fontSize: 19, marginRight: 10}}
                                        />
                                    </div>
                                    Both
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                {this._renderFooter()}
            </div>
        )
    }
}