import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function signatoriesMaintenanceReducer(state = initialState.signatoryMaintenance, action) {
    switch (action.type) {
        case types.SM_START:
            return {...state, ...action.application};
        case types.SM_APPS:
            return {...state, applications: [...action.copies]};
        case types.UPDATE_COPIES:
            return {...state, copies: {...action.copies}};
        case types.CLEAR_COPIES:
            return {...state, copies: null};
        default:
            return state;
    }
}
