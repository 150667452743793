import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/omega/theme.css';
import {managedFundRoutes} from '../../../routers/routes';
import CashEntityDocsComponent from './cashAndCustodyKycEntityDocs';
 import SignatoriesComponent from '../mFundSignatoriesComponenet';
import ButtonsComponent from '../../ButtonsComponent';
import {saveCashCustodyManagedFund} from "../../../actions/cashAndCustodyManagedFund";

export const kyc = (props) => {

    const managedFundRequest = useSelector(state => state.cashCustodyManagedFund && state.cashCustodyManagedFund.cashCustodyManagedFundRequest);
    const applicationId = useSelector(state => state.cashCustodyManagedFund && state.cashCustodyManagedFund.id);
    const [signatories, setSignatories] = useState(managedFundRequest.signatories);
    const [signatoryCopyAccount, setSignatoryCopyAccount] = useState(managedFundRequest.signatoryCopyAccount);
    const [entityDocs, setEntityDocs] = useState(managedFundRequest.documents);


    const systemError = useSelector(state => state.systemError);
    const tagged = useState(true);

    const [errors, setErrors] = useState({});


    const entityDocsRef = React.useRef(null);
    const signatoriesRef = React.useRef(null);

    const dispatch = useDispatch();

    const saveAndContinue = (event) => {
        // saveDetails();
        let cashManagedFundRequest = managedFundRequest;
        cashManagedFundRequest.signatories = signatories;
        cashManagedFundRequest.documents = entityDocs;
        managedFundRequest.signatoryCopyAccount = signatoryCopyAccount;
        const {history} = props;
        const onProceed = () => history && history.push('/saveapplication');
        dispatch(saveCashCustodyManagedFund(applicationId, cashManagedFundRequest, 'saveContinue', onProceed));
    };

    const saveDetails = (event) => {
        let cashManagedFundRequest = managedFundRequest;
        cashManagedFundRequest.signatories = signatories;
        cashManagedFundRequest.documents = entityDocs;
        managedFundRequest.signatoryCopyAccount = signatoryCopyAccount;
        const isDocsValid = entityDocsRef.current && entityDocsRef.current.validateDocs();
        const isSignatoriesValid = signatoriesRef.current && signatoriesRef.current.validateSignatories();

        const {history} = props;
        if (isSignatoriesValid && isDocsValid) {
            const onProceed = () => history && history.push(managedFundRoutes.cashAndCustodyCapacityType);
            dispatch(saveCashCustodyManagedFund(applicationId, cashManagedFundRequest, 'UserTask_KYC', onProceed));
        }

    };

    const renderFooter = () => {
        const {history} = props;
        return (
            <ButtonsComponent
                history={history}
                onNext={saveDetails}
                prevPage={managedFundRoutes.cashAndCustodyConfirmProduct}
                onSaveAndContinue={saveAndContinue}
            />
        );
    };

    const handleDocUpdate= (documents) => {
      setEntityDocs(documents);

    };

    const handleSignatoryUpdate = (signatories) => {
        setSignatories(signatories);
    };

    const handleSignatoryCopy = (signatoryCopyAccount) => {
        setSignatoryCopyAccount(signatoryCopyAccount);
    };

    if (systemError && systemError.show) return null;
    return (
        <div className="page-container">
            <div className="page-main-section">
                <div className="container" style={{minHeight: '50em'}}>
                    <div className="col-md-8">
                        <CashEntityDocsComponent
                            ref={entityDocsRef}
                            applicationId={applicationId}
                            entityDocs={entityDocs}
                            handleDocUpdate={handleDocUpdate}
                        />

                    </div>
                    <div className="col-md-4">
                        <SignatoriesComponent
                            ref={signatoriesRef}
                            applicationId={applicationId}
                            signatories={signatories}
                            handleSignatoryUpdate={handleSignatoryUpdate}
                            handleSignatoryCopy={handleSignatoryCopy}
                            signatoryCopyAccount={signatoryCopyAccount}
                            entityDocs={entityDocs}
                            handleDocUpdate={handleDocUpdate}
                        />
                    </div>

                </div>
            </div>
            <div className="page-footer-section" style={{width: "80%"}}>
                {renderFooter()}
            </div>
        </div>
    );

};

export default kyc;
