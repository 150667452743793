import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import validate from 'validate.js';
import {Panel, PanelGroup, Table} from 'react-bootstrap';
import FormField from '../formField/FormField';
import {
    clearForm,
    searchApplicationByAppID,
    searchEntityByCIF,
    searchEntityByGoldTierID,
    searchEntityByRegNum, searchEntityBySalesforceID
} from '../../actions/support';


export const ClientSupportPage = (props) => {
    const [errors, setErrors] = useState(false);
    const [form, setForm] = useState({
        applicationId: '',
        cif: '',
        goldTierId: '',
        registrationNumber: '',
        salesforceId: ''
    });
    const [isSearchComplete, setIsSearchComplete] = useState(false);
    const [disableSearchButton, setDisableSearchButton] = useState(true);

    const support = useSelector(state => state.support && state.support.support);
    const authorisationRules = useSelector(state => state.support && state.support.authorisationRules ? state.support.authorisationRules : []);
    const authorisedPersons = useSelector(state => state.support && state.support.authorisedPersons ? state.support.authorisedPersons : []);

    const dispatch = useDispatch();


    const handleOnChange = ({target}, attribute, isCheckbox = false) => {

        let updatedForm = _.extend({}, form, {
            cif: '',
            goldTierId: '',
            applicationId: '',
            registrationNumber: '',
            salesforceId: ''
        });
        updatedForm = _.extend({}, updatedForm, {[attribute]: _.trimStart(target.value)});
        const disable = handleDisableSearchButton(updatedForm);
        setForm(updatedForm);
        setDisableSearchButton(disable);
    };

    const onResetError = (attribute) => {
        setErrors(_.extend({}, errors, {[attribute]: null}));
    };

    const validateForm = () => {
        const options = {fullMessages: false};
        const constraintsAppId = {applicationId: {presence: true, length: {minimum: 1, message: 'required'}}};
        const constraintsCIF = {cif: {presence: true, length: {minimum: 1, message: 'required'}}};
        const constraintsGoldTierId = {goldTierId: {presence: true, length: {minimum: 1, message: 'required'}}};
        const constraintsRegNum = {registrationNumber: {presence: true, length: {minimum: 1, message: 'required'}}};
        const constraintsSalesforceId = {salesforceId: {presence: true, length: {minimum: 1, message: 'required'}}};

        let errors = {};
        if (form.applicationId.length > 0) {
            errors = validate(form, constraintsAppId, options);

        } else if (form.cif.length > 0) {
            errors = validate(form, constraintsCIF, options);

        } else if (form.goldTierId.length > 0) {
            errors = validate(form, constraintsGoldTierId, options);

        } else if (form.registrationNumber.length > 0) {
            errors = validate(form, constraintsRegNum, options);

        } else if (form.salesforceId.length > 0) {
            errors = validate(form, constraintsSalesforceId, options);

        } else {
            errors = {errors: 'No search value'};

        }
        setErrors(errors);
        return _.isEmpty(errors);
    };

    const handleOnSearch = () => {

        if (validateForm()) {

            if (form.applicationId.length > 0) {
                console.log('handle search application by id: ' + form.applicationId);
                dispatch(searchApplicationByAppID(form.applicationId, null));

            } else if (form.cif.length > 0) {
                console.log('handle search by CIF: ' + form.cif);
                dispatch(searchEntityByCIF(form.cif, null));

            } else if (form.goldTierId.length > 0) {
                console.log('handle search by GoldTier ID: ' + form.goldTierId);
                dispatch(searchEntityByGoldTierID(form.goldTierId, null));

            } else if (form.registrationNumber.length > 0) {
                console.log('handle search by Reg Num: ' + form.registrationNumber);
                dispatch(searchEntityByRegNum(form.registrationNumber, null));

            } else if (form.salesforceId.length > 0) {
                console.log('handle search by Salesforce ID: ' + form.salesforceId);
                dispatch(searchEntityBySalesforceID(form.salesforceId, null));
            }
        }
        setIsSearchComplete(true);
    };

    const handleDisableSearchButton = (form) => {

        let disable = disableSearchButton;
        if (form) {
            disable = (
                (form.applicationId.length === 0) &&
                (form.cif.length === 0) &&
                (form.goldTierId.length === 0) &&
                (form.salesforceId.length === 0) &&
                (form.registrationNumber.length === 0)
            );
        }
        return disable;
    };

    const handleOnFinish = () => {
        const {close} = props;
        dispatch(clearForm());
        close && close();
    };

    const renderHeader = () => {
        return (
            <div>
                <div className="product-heading">
                    Client Support
                </div>
                <div className="title-gradient"/>
            </div>
        );
    };

    const renderEntityResultsBar = () => {


        const describeRule = (rule, i) => {
            if (_.size(rule.levels) > 1) {
                return `${i}. A combination of: ${_.map(rule.levels, (l) => ` ${l.count} of ${l.level}`)}`;
            }
            return `${i}. Any ${rule.levels[0] && rule.levels[0].count} of ${rule.levels[0] && rule.levels[0].level} authoriser(s)`;
        };


        return (
            <PanelGroup accordion id="search-results">
                <Panel eventKey="1">
                    <Panel.Heading>
                        <Panel.Title toggle>KYC Status</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="col-md-12">
                            {
                                support && support.length > 0 ?
                                    (<Table striped>
                                        <thead style={{backgroundColor: 'lightgrey', color: 'white'}}>
                                        <tr>
                                            <th>Registration Number</th>
                                            <th>GoldTier ID</th>
                                            <th>CIF Number</th>
                                            <th>Name</th>
                                            <th>Business Type</th>
                                            <th>KYC Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            support.map(
                                                ({
                                                     registrationNumber, goldTierId, cifNumber, name, kycStatus, businessType
                                                 }, index) =>
                                                    (
                                                        <tr key={index}>
                                                            <td>{registrationNumber}</td>
                                                            <td>{goldTierId}</td>
                                                            <td>{cifNumber}</td>
                                                            <td>{name}</td>
                                                            <td>{businessType}</td>
                                                            <td>{kycStatus}</td>
                                                        </tr>
                                                    ))
                                        }
                                        </tbody>
                                    </  Table>) : (<p>No entity found</p>)
                            }
                        </div>
                    </Panel.Body>
                </Panel>
                <Panel eventKey="2">
                    <Panel.Heading>
                        <Panel.Title toggle>Authorised Persons</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="col-md-12">
                            {
                                authorisedPersons && authorisedPersons.length > 0 ?
                                    (<Table striped>
                                        <thead style={{backgroundColor: 'lightgrey', color: 'white'}}>
                                        <tr>
                                            <th>GoldTier ID</th>
                                            <th>First Name</th>
                                            <th>Middle Name</th>
                                            <th>Last Name</th>
                                            <th>ID Number</th>
                                            <th>Email</th>
                                            <th>Phone Number</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            authorisedPersons.map(
                                                ({
                                                     goldTierId, firstName, middleName, lastName, identityNumber, email, phoneNumber
                                                 }, index) =>
                                                    (
                                                        <tr key={index}>
                                                            <td>{goldTierId}</td>
                                                            <td>{firstName}</td>
                                                            <td>{middleName}</td>
                                                            <td>{lastName}</td>
                                                            <td>{identityNumber}</td>
                                                            <td>{email}</td>
                                                            <td>{phoneNumber}</td>
                                                        </tr>
                                                    ))
                                        }
                                        </tbody>
                                    </Table>) : (<p>No authorised persons found</p>)
                            }
                        </div>
                    </Panel.Body>
                </Panel>
                <Panel eventKey="3">
                    <Panel.Heading>
                        <Panel.Title toggle>Authorisation Rules</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="col-md-12">
                                {
                                    authorisationRules && authorisationRules.authorisers && authorisationRules.authorisers.length > 0 ?
                                        (<Table striped>
                                            <thead style={{backgroundColor: 'lightgrey', color: 'white'}}>
                                            <tr>
                                                <th>Name</th>
                                                <th>Surname</th>
                                                <th>ID Number</th>
                                                <th>Auth Level</th>
                                                <th>Registered</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                authorisationRules.authorisers.map(
                                                    ({
                                                         firstName, surname, idNumber, authLevel, registered
                                                     }, index) =>
                                                        <tr key={index}>
                                                            <td>{firstName}</td>
                                                            <td>{surname}</td>
                                                            <td>{idNumber}</td>
                                                            <td>{authLevel}</td>
                                                            <td>{registered ? 'Yes' : 'No'}</td>
                                                        </tr>
                                                )
                                            }
                                            </tbody>
                                        </Table>) : (<p>No authorisation authorises found</p>)
                                }
                        </div>
                        <div className="col-md-12">
                            {
                                authorisationRules && authorisationRules.rules && authorisationRules.rules.length > 0 &&
                                (<div className="rules-container">
                                    {_.map(authorisationRules.rules, (rule, i) => (
                                        <div className="row rule-list-item" key={`rule-${i}`}>
                                            <div className="col-md-11" id="item-content">
                                                <p>{describeRule(rule, i + 1)}</p>
                                            </div>
                                            <div className="col-md-1" id="item-content">
                                                <p><a className="btn " role="button" id="remove-btn"
                                                      onClick={() => onRemoveRule(i)}> <i className="fa fa-times"
                                                                                          aria-hidden="true"/></a>
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>)
                            }
                        </div>

                    </Panel.Body>
                </Panel>
            </PanelGroup>
        );
    };

    const renderApplicationResultsBar = () => {
        return (
            <PanelGroup accordion id="search-results">
                <Panel eventKey="1">
                    <Panel.Heading>
                        <Panel.Title toggle>Application Details</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="col-md-12">
                            <div className="col-md-6">
                                <div>
                                    <FormField className="form-group">
                                        <label htmlFor="applicationId">Application ID</label>
                                        <input className="form-control" id="applicationId" defaultValue={support.id}
                                               readOnly={true}/>
                                    </FormField>
                                </div>
                                <div>
                                    <FormField className="form-group">
                                        <label htmlFor="createdAt">Application Creation Date</label>
                                        <input className="form-control" id="createdAt"
                                               defaultValue={support.createdAt}
                                               readOnly={true}/>
                                    </FormField>
                                </div>
                                <div>
                                    <FormField className="form-group">
                                        <label htmlFor="modifiedAt">Application Modified Date</label>
                                        <input className="form-control" id="modifiedAt"
                                               defaultValue={support.modifiedAt} readOnly={true}/>
                                    </FormField>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div>
                                    <FormField className="form-group">
                                        <label htmlFor="impersonatedIdNumber">Impersonated ID Number</label>
                                        <input className="form-control" id="impersonatedIdNumber"
                                               defaultValue={support.impersonatedIdNumber} readOnly={true}/>
                                    </FormField>
                                </div>
                                <div>
                                    <FormField className="form-group">
                                        <label htmlFor="notificationEmailAddress">Notification Email Address</label>
                                        <input className="form-control" id="notificationEmailAddress"
                                               defaultValue={support.notificationEmailAddress} readOnly={true}/>
                                    </FormField>
                                </div>
                                <div>
                                    <FormField className="form-group">
                                        <label htmlFor="applicationStatus">Application Status</label>
                                        <input className="form-control" id="applicationStatus"
                                               defaultValue={support.applicationStatus && support.applicationStatus.applicationOutcome}
                                               readOnly={true}/>
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </Panel.Body>
                </Panel>
                <Panel eventKey="2">
                    <Panel.Heading>
                        <Panel.Title toggle>Entity Details</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="col-md-12">
                            <div className="col-md-12">
                                <div className="col-md-6">
                                    <div>
                                        <div className="product-heading">
                                            Entity Details
                                        </div>
                                        <div className="title-gradient"/>
                                        <hr/>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="goldTierId">GoldTier ID</label>
                                            <input className="form-control" id="goldTierId"
                                                   defaultValue={support.legalEntity ? support.legalEntity.goldTierId : ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="cif">CIF Number</label>
                                            <input className="form-control" id="cif"
                                                   defaultValue={support.legalEntity ? support.legalEntity.cif : ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="salesforceId">Salesforce ID</label>
                                            <input className="form-control" id="salesforceId"
                                                   defaultValue={support.legalEntity ? support.legalEntity.salesforceId : ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="registeredName">Registered Name</label>
                                            <input className="form-control" id="registeredName"
                                                   defaultValue={support.legalEntity ? support.legalEntity.registeredName : ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="registrationNumber">Registration Number</label>
                                            <input className="form-control" id="registrationNumber"
                                                   defaultValue={support.legalEntity ? support.legalEntity.registrationNumber : ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="tradingAs">Trading As</label>
                                            <input className="form-control" id="tradingAs"
                                                   defaultValue={support.legalEntity ? support.legalEntity.tradingAs : ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="typeOfEntity">Entity Type</label>
                                            <input className="form-control" id="typeOfEntity"
                                                   defaultValue={support.legalEntity ? support.legalEntity.typeOfEntity : ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="telephoneNumber">Telephone Number</label>
                                            <input className="form-control" id="telephoneNumber"
                                                   defaultValue={support.legalEntity ? support.legalEntity.telephoneNumber : ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="vatNumber">VAT Number</label>
                                            <input className="form-control" id="vatNumber"
                                                   defaultValue={support.legalEntity ? support.legalEntity.vatNumber : ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div>
                                        <div className="product-heading">
                                            Entity Address
                                        </div>
                                        <div className="title-gradient"/>
                                        <hr/>
                                    </div>

                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="street">Street</label>
                                            <input className="form-control" id="street"
                                                   defaultValue={support.legalEntity && support.legalEntity.address && support.legalEntity.address.street || ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="suburb">Suburb</label>
                                            <input className="form-control" id="suburb"
                                                   defaultValue={support.legalEntity && support.legalEntity.address && support.legalEntity.address.suburb || ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="city">City</label>
                                            <input className="form-control" id="city"
                                                   defaultValue={support.legalEntity && support.legalEntity.address && support.legalEntity.address.city || ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="province">Province</label>
                                            <input className="form-control" id="province"
                                                   defaultValue={support.legalEntity && support.legalEntity.address && support.legalEntity.address.province || ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="country">Country</label>
                                            <input className="form-control" id="country"
                                                   defaultValue={support.legalEntity && support.legalEntity.address && support.legalEntity.address.country || ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="postalCode">Postal Code</label>
                                            <input className="form-control" id="postalCode"
                                                   defaultValue={support.legalEntity && support.legalEntity.address && support.legalEntity.address.postalCode || ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>

                                </div>
                            </div>
                            <hr/>
                            <PanelGroup accordion id="entity-details-data">
                                <Panel eventKey="1">
                                    <Panel.Heading>
                                        <Panel.Title toggle>Related Parties</Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        <div className="col-md-12">
                                            {
                                                support.legalEntity && support.legalEntity.relatedParties && support.legalEntity.relatedParties.length > 0 ?
                                                    (<Table striped>
                                                        <thead
                                                            style={{backgroundColor: 'lightgrey', color: 'white'}}>
                                                        <tr>
                                                            <th>Type</th>
                                                            <th>Name</th>
                                                            <th>Identification Number</th>
                                                            <th>Contact Number</th>
                                                            <th>Email</th>
                                                            <th>Capacity</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            support.legalEntity.relatedParties.map(
                                                                ({
                                                                     businessType, registeredName, lastName, registrationNumber, contactNumber, emailAddress, capacity
                                                                 }, index) =>
                                                                    (
                                                                        <tr key={index}>
                                                                            <td>{businessType}</td>
                                                                            <td>{registeredName}</td>
                                                                            <td>{registrationNumber}</td>
                                                                            <td>{contactNumber}</td>
                                                                            <td>{emailAddress}</td>
                                                                            <td>{capacity}</td>
                                                                        </tr>
                                                                    ))
                                                        }
                                                        </tbody>
                                                    </Table>) : (<p>No related parties</p>)
                                            }
                                        </div>
                                    </Panel.Body>
                                </Panel>
                                <Panel eventKey="2">
                                    <Panel.Heading>
                                        <Panel.Title toggle>Supporting Documents</Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        <div className="col-md-12">
                                            {
                                                support.legalEntity && support.legalEntity.supportingDocuments && support.legalEntity.supportingDocuments.length > 0 ?
                                                    (<Table striped>
                                                        <thead
                                                            style={{backgroundColor: 'lightgrey', color: 'white'}}>
                                                        <tr>
                                                            <th>Type</th>
                                                            <th>Name</th>
                                                            <th>Doc ID</th>
                                                            <th>Verified</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            support.legalEntity.supportingDocuments.map(
                                                                ({
                                                                     documentType, name, documentId, verified
                                                                 }, index) =>
                                                                    (
                                                                        <tr key={index}>
                                                                            <td>{documentType}</td>
                                                                            <td>{name}</td>
                                                                            <td>{documentId}</td>
                                                                            <td>{verified}</td>
                                                                        </tr>
                                                                    ))
                                                        }
                                                        </tbody>
                                                    </Table>) : (<p>No supporting documents</p>)
                                            }
                                        </div>
                                    </Panel.Body>
                                </Panel>
                            </PanelGroup>
                        </div>
                    </Panel.Body>
                </Panel>
                <Panel eventKey="3">
                    <Panel.Heading>
                        <Panel.Title toggle>Bank Account Details</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="col-md-12">
                            <div className="col-md-12">
                                <div className="col-md-6">
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="accountType">Account Type</label>
                                            <input className="form-control" id="accountType"
                                                   defaultValue={support.bankAccount ? support.bankAccount.accountType : ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="accountNumber">Account Number</label>
                                            <input className="form-control" id="accountNumber"
                                                   defaultValue={support.bankAccount ? support.bankAccount.accountNumber : ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="branchCode">Branch Code</label>
                                            <input className="form-control" id="branchCode"
                                                   defaultValue={support.bankAccount ? support.bankAccount.branchCode : ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="active">Active</label>
                                            <input className="form-control" id="active"
                                                   defaultValue={support.bankAccount ? support.bankAccount.active : ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="accountName">Account Name</label>
                                            <input className="form-control" id="accountName"
                                                   defaultValue={support.bankAccount && support.bankAccount.preferences && support.bankAccount.preferences.accountName || ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="statementDeliveryFrequency">Statement Delivery
                                                Frequency</label>
                                            <input className="form-control" id="statementDeliveryFrequency"
                                                   defaultValue={support.bankAccount && support.bankAccount.preferences && support.bankAccount.preferences.statementDeliveryFrequency || ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="statementDeliveryMethod">Statement Delivery
                                                Method</label>
                                            <input className="form-control" id="statementDeliveryMethod"
                                                   defaultValue={support.bankAccount && support.bankAccount.preferences && support.bankAccount.preferences.statementDeliveryMethod || ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>
                                    <div>
                                        <FormField className="form-group">
                                            <label htmlFor="deliveryDestination">Delivery Destination</label>
                                            <input className="form-control" id="deliveryDestination"
                                                   defaultValue={support.bankAccount && support.bankAccount.preferences && support.bankAccount.preferences.deliveryDestination || ''}
                                                   readOnly={true}/>
                                        </FormField>
                                    </div>

                                </div>
                            </div>
                            <hr/>
                            <PanelGroup accordion id="signatories-data">
                                <Panel eventKey="1">
                                    <Panel.Heading>
                                        <Panel.Title toggle>Signatories</Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        <div className="col-md-12">
                                            {
                                                support && support.bankAccount && support.bankAccount.signatories && support.bankAccount.signatories.length > 0 ?
                                                    (<Table striped>
                                                        <thead
                                                            style={{backgroundColor: 'lightgrey', color: 'white'}}>
                                                        <tr>
                                                            <th>First Name</th>
                                                            <th>Last Name</th>
                                                            <th>ID Number</th>
                                                            <th>Email</th>
                                                            <th>Contact Number</th>
                                                            <th>Signing Limits</th>
                                                            <th>Signing Arrangements</th>
                                                            <th>Action Type</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            support.bankAccount.signatories.map(
                                                                ({
                                                                     idNumber, firstName, lastName, signingLimits, contactNumber, emailAddress, signingArrangements, actionType
                                                                 }, index) =>
                                                                    (
                                                                        <tr key={index}>
                                                                            <td>{firstName}</td>
                                                                            <td>{lastName}</td>
                                                                            <td>{idNumber}</td>
                                                                            <td>{emailAddress}</td>
                                                                            <td>{contactNumber}</td>
                                                                            <td>{signingLimits}</td>
                                                                            <td>{signingArrangements}</td>
                                                                            <td>{actionType}</td>
                                                                        </tr>
                                                                    ))
                                                        }
                                                        </tbody>
                                                    </Table>) : (<p>No signatories</p>)
                                            }
                                        </div>
                                    </Panel.Body>
                                </Panel>
                            </PanelGroup>
                        </div>
                    </Panel.Body>
                </Panel>
                <Panel eventKey="4">
                    <Panel.Heading>
                        <Panel.Title toggle>Additional Products</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="col-md-12">
                            {
                                support && support.additionalProducts && support.additionalProducts.length > 0 ?
                                    (<Table striped>
                                        <thead style={{backgroundColor: 'lightgrey', color: 'white'}}>
                                        <tr>
                                            <th>Name</th>
                                            <th>BOL Profile ID</th>
                                            <th>Group Number</th>
                                            <th>Transactional Payments</th>
                                            <th>Transactional Transfers</th>
                                            <th>Operators</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {

                                            support.additionalProducts.map(
                                                ({
                                                     id, bolProfileId, cashManGroupNumber, config
                                                 }, index) =>
                                                    (
                                                        <tr key={index}>
                                                            <td>{id}</td>
                                                            <td>{bolProfileId}</td>
                                                            <td>{cashManGroupNumber}</td>
                                                            <td>{config && config.limits && config.limits.transactionalPayments}</td>
                                                            <td>{config && config.limits && config.limits.transactionalTransfers}</td>
                                                            <td>
                                                                <PanelGroup accordion id="operators">
                                                                    {
                                                                        config && config.operators && config.operators.map(({operatorId, accountAccess}, index) =>
                                                                            (<Panel eventKey={index} key={index}>
                                                                                <Panel.Heading>
                                                                                    <Panel.Title toggle>Operator
                                                                                        Name: {operatorId}</Panel.Title>
                                                                                </Panel.Heading>
                                                                                <Panel.Body collapsible>
                                                                                    <div>
                                                                                        <p>Payments: {accountAccess && accountAccess.Payments ? 'Yes' : 'No'}</p>
                                                                                        <p>Balance
                                                                                            Statements: {accountAccess && accountAccess.balanceStatements ? 'Yes' : 'No'}</p>
                                                                                        <p>Notifications: {accountAccess && accountAccess.notifications ? 'Yes' : 'No'}</p>
                                                                                        <p>Own
                                                                                            Transfers: {accountAccess && accountAccess.ownTransfers ? 'Yes' : 'No'}</p>
                                                                                    </div>
                                                                                </Panel.Body>
                                                                            </Panel>)
                                                                        )
                                                                    }
                                                                </PanelGroup>
                                                            </td>
                                                        </tr>
                                                    ))
                                        }
                                        </tbody>
                                    </Table>) : (<p>No additional products</p>)
                            }
                        </div>
                    </Panel.Body>
                </Panel>
                <Panel eventKey="5">
                    <Panel.Heading>
                        <Panel.Title toggle>Approval Messages</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="col-md-12">
                            {
                                support && support.approvalMessages && suopport.approvalMessages.length > 0 ?
                                    (<Table striped>
                                        <thead style={{backgroundColor: 'lightgrey', color: 'white'}}>
                                        <tr>
                                            <th>Approver Msisdn Number</th>
                                            <th>Message</th>
                                            <th>Response</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            support.approvalMessages.map(
                                                ({
                                                     approverMsisdn, message, response
                                                 }, index) =>
                                                    (
                                                        <tr key={index}>
                                                            <td>{approverMsisdn}</td>
                                                            <td>{message}</td>
                                                            <td>{response}</td>
                                                        </tr>
                                                    ))
                                        }
                                        </tbody>
                                    </Table>) : (<p>No approval messages</p>)
                            }
                        </div>
                    </Panel.Body>
                </Panel>
            </PanelGroup>
        );
    };

    const renderSearchBar = () => {
        return (
            <div className="col-md-12 row" style={{marginBottom: '10px'}}>
                <div>
                    <div className="row">
                        <div className="col-md-6">
                            <FormField className="form-group">
                                <label htmlFor="applicationId">Application ID</label>
                                <input className="form-control" id="applicationId"
                                       onChange={(event) => {
                                           errors && errors.applicationId && onResetError('applicationId');
                                           handleOnChange(event, 'applicationId');
                                       }}
                                       type="number" value={form && form.applicationId || ''}
                                />
                            </FormField>
                        </div>
                        <div className="col-md-6">
                            <FormField className="form-group">
                                <label htmlFor="cif">CIF</label>
                                <input className="form-control" id="cif"
                                       onChange={(event) => {
                                           errors && errors.cif && onResetError('cif');
                                           handleOnChange(event, 'cif');
                                       }}
                                       type="text" value={form && form.cif || ''}
                                />
                            </FormField>
                        </div>
                        <div className="col-md-6">
                            <FormField className="form-group">
                                <label htmlFor="goldTierId">GoldTier ID</label>
                                <input className="form-control" id="goldTierId"
                                       onChange={(event) => {
                                           errors && errors.goldTierId && onResetError('goldTierId');
                                           handleOnChange(event, 'goldTierId');
                                       }}
                                       type="number" value={form && form.goldTierId || ''}
                                />
                            </FormField>
                        </div>
                        <div className="col-md-6">
                            <FormField className="form-group">
                                <label htmlFor="registrationNumber">Registration Number</label>
                                <input className="form-control" id="registrationNumber"
                                       onChange={(event) => {
                                           errors && errors.registrationNumber && onResetError('registrationNumber');
                                           handleOnChange(event, 'registrationNumber');
                                       }}
                                       type="text" value={form && form.registrationNumber || ''}
                                />
                            </FormField>
                        </div>
                        <div className="col-md-6">
                            <FormField className="form-group">
                                <label htmlFor="salesforceId">Salesforce ID</label>
                                <input className="form-control" id="salesforceId"
                                       onChange={(event) => {
                                           errors && errors.salesforceId && onResetError('salesforceId');
                                           handleOnChange(event, 'salesforceId');
                                       }}
                                       type="text" value={form && form.salesforceId || ''}
                                />
                            </FormField>
                        </div>
                        <hr/>
                        <div>
                            <button disabled={disableSearchButton}
                                    className={!disableSearchButton ? 'action-btn-primary pull-right' : 'action-btn-secondary pull-right'}
                                    onClick={handleOnSearch}
                                    type="button">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    {
                        support && support.errorMessage && (
                            <div>
                                <hr/>
                                <p className="pull-right" style={{color: 'red'}}><i>{support.errorMessage}</i></p>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    };

    const renderFooter = () => {
        return (
            <div className="col-md-12">
                <button className="action-btn-danger pull-right" onClick={handleOnFinish} type="button">
                    Close
                </button>
            </div>
        );
    };

    return (
        <div className="page-container">
            <div className="col-md-12" style={{width: '100%', color: 'black'}}>
                {renderHeader()}
                <hr/>
                {renderSearchBar()}
                <hr/>
                {
                    form && form.applicationId && form.applicationId.length > 0 ?
                        (support && support.id && renderApplicationResultsBar()) :
                        (support && support.length > 0 && renderEntityResultsBar())
                }
                {
                    support && support.id && (<hr/>)
                }

                {renderFooter()}
            </div>
        </div>
    );

};

export default ClientSupportPage;
