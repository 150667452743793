import React from "react";
import _ from "lodash";
import validate from "validate.js";
import FormField from "../formField/FormField";
import SearchComponent from "../SearchComponent";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import Toggler from "../Toggler";

export default class NaturalPersonForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: props.person,
            isRSACitizen: true,
            isSearchComplete: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.person) {
            const form = _.extend({}, nextProps.person, this.state.form);
            this.setState({form})
        }
    }

    handleOnResetError = (attribute) => {
        const errors = _.extend({}, this.state.errors, {[attribute]: null});
        this.setState({errors})
    };

    validateForm = () => {
        const {showWorkInfo} = this.props;
        const {form} = this.state;
        const options = {fullMessages: false};

        const constraints = _.extend({
            firstName: {presence: true, length: {minimum: 1, message: 'required'}},
            lastName: {presence: true, length: {minimum: 1, message: 'required'}},
            idNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            contactNumber: {presence: true, length: {minimum: 1, message: 'required'}},
            emailAddress: {presence: true, email: true, length: {minimum: 1, message: 'required'}},
        }, showWorkInfo &&{landLine: {presence: true, length: {minimum: 1, message: 'required'}}});

        const addressConstraints = _.extend({
            registeredAddress: {presence: true, length: {minimum: 1, message: 'required'}}
        }, showWorkInfo && {workAddress: {presence: true, length: {minimum: 1, message: 'required'}}} );

        const addressErrors = validate(form && form.address, addressConstraints, options);
        let errors = validate(form, constraints, options);
        errors = _.extend({}, errors, addressErrors);
        this.setState({errors});

        return _.isEmpty(errors);
    };

    handleOnSubmit = () => {
        const {onSubmit, validateForm} = this.props;
        const isValid = validateForm ? validateForm() : true;
        if (this.validateForm() && isValid) {
            onSubmit(this.state.form);
        }
    };

    handleOnCancel = () => {
        const {onCancel, formType} = this.props;
        onCancel && onCancel(formType);
    };

    handleOnChange = ({target}, attribute, innerAttribute) => {
        const {form} = this.state;
        let value = target.value;
        if (innerAttribute) {
            value = _.extend({}, form && form[attribute], {[innerAttribute]: value});
        }
        const updateForm = _.extend({}, form, {[attribute]: value});
        this.setState({form: updateForm})
    };
    handleSelectChange = (event, attribute, isAddress = false) => {
        let form = _.extend({}, this.state.form);
        if (isAddress) {
            const address = _.extend({}, form && form.address, {registeredAddress: event.value});
            form = _.extend({}, form, {address})
        } else {
            form = _.extend({}, form, {[attribute]: event.value})
        }
        this.setState({form});
    };


    handleOnSearch = () => {
        const {onSearch, formType} = this.props;
        const {form} = this.state;
        this.setState({errors: {}});
        const onComplete = () => this.setState({isSearchComplete: true});
        onSearch && onSearch(formType, form && form.idNumber, onComplete);
    };

    handleChangeSACitizen = (isRSACitizen) => {
        this.setState({isRSACitizen})
    };

    _formatAddress = (address) => {
        return address && `${address.line1 ? (address.line1 + ', ') : ''}${address.line2 ? (address.line2 + ', ') : ''}${address.line3 ? (address.line3 + ', ') : ''}${address.line4 ? (address.line4 + ', ') : ''}${address.postalCode ? (address.postalCode) : ''}`
    };

    renderDetailsForm() {
        const {errorList, showWorkInfo} = this.props;
        const {errors, form, isRSACitizen, isSearchComplete} = this.state;
        const phoneOptions = _.map(form && form.telephones, (t) => ({value: t.msisdn, label: t.msisdn}));
        const addressOptions = _.map(form && form.addresses, (t) => ({value: this._formatAddress(t), label: t.line1}));

        const hasPhoneOptions = _.size(phoneOptions) > 0;
        const hasAddressOptions = _.size(addressOptions) > 0;
        const shouldDisableInput = !(!isRSACitizen || isSearchComplete);

        return (
            <div className="flexColumn">
                <div className="popup-content">
                    <div className="flexColumn">
                        <div className="card-container-form">
                            <FormField className="form-group" error={errors && errors.idNumber}>
                                {
                                    !shouldDisableInput ?

                                        <div>
                                            <label>ID/ Passport Number</label>
                                            <input
                                                className="form-control"
                                                id="idNumber"
                                                onChange={(event) => {
                                                    errors && errors.idNumber && this.handleOnResetError('idNumber');
                                                    this.handleOnChange(event, 'idNumber')
                                                }}
                                                type="text"
                                                value={form && form.idNumber ? form.idNumber : ''}
                                            />
                                        </div>

                                        :
                                        <SearchComponent
                                            label="ID Number"
                                            onCancel={this.handleOnCancel}
                                            onChange={(event) => {
                                                errorList && errorList.idNumber && this.handleOnResetError('idNumber');
                                                this.handleOnChange(event, 'idNumber')
                                            }}
                                            onSearch={this.handleOnSearch}
                                            searchValue={form && form.idNumber ? form.idNumber : ''}
                                        />
                                }
                            </FormField>

                            <FormField className={"form-group"} id="name" error={errors && errors.firstName}>
                                <label>First Name</label>
                                <input
                                    className="form-control"
                                    disabled={shouldDisableInput}
                                    id="firstName"
                                    value={form && form.firstName ? form.firstName : ''}
                                    onChange={(event) => {
                                        errors && errors.firstName && this.handleOnResetError('firstName');
                                        this.handleOnChange(event, 'firstName')
                                    }}
                                    type="text"
                                />
                            </FormField>

                            <FormField className={"form-group"} id="lastName" error={errors && errors.lastName}>
                                <label>Surname</label>
                                <input
                                    className="form-control"
                                    disabled={shouldDisableInput}
                                    id="surname"
                                    onChange={(event) => {
                                        errors && errors.lastName && this.handleOnResetError('lastName');
                                        this.handleOnChange(event, 'lastName')
                                    }}
                                    type="text"
                                    value={form && form.lastName ? form.lastName : ''}
                                />
                            </FormField>
                            {
                                showWorkInfo &&
                                <div className="form-group">
                                    <label>Work Address</label>
                                    {
                                        hasAddressOptions ?
                                            <Select
                                                name="address"
                                                disabled={shouldDisableInput}
                                                onChange={(event) => this.handleSelectChange(event, 'address', true)}
                                                options={addressOptions}
                                                value={form && form.address && form.address.workAddress}
                                            />
                                            :
                                            <FormField id="number" error={errors && errors.workAddress}>
                                                <input
                                                    className="form-control"
                                                    disabled={shouldDisableInput}
                                                    id="address"
                                                    onChange={(event) => {
                                                        errors && errors.workAddress && this.handleOnResetError('workAddress');
                                                        this.handleOnChange(event, 'address', 'workAddress')
                                                    }}
                                                    type="text"
                                                    value={form && form.address && form.address.workAddress ? form.address.workAddress : ''}
                                                />
                                            </FormField>
                                    }
                                </div>
                            }
                            {this.props.children}
                        </div>
                    </div>
                    <div className="flexColumn">
                        <div className="card-container-form">
                            <div className="form-group">
                                <label>Address</label>
                                {
                                    hasAddressOptions ?
                                        <Select
                                            name="address"
                                            disabled={shouldDisableInput}
                                            onChange={(event) => this.handleSelectChange(event, 'address', true)}
                                            options={addressOptions}
                                            value={form && form.address && form.address.registeredAddress}
                                        />
                                        :
                                        <FormField id="number" error={errors && errors.registeredAddress}>
                                            <input
                                                className="form-control"
                                                disabled={shouldDisableInput}
                                                id="address"
                                                onChange={(event) => {
                                                    errors && errors.registeredAddress && this.handleOnResetError('registeredAddress');
                                                    this.handleOnChange(event, 'address', 'registeredAddress')
                                                }}
                                                type="text"
                                                value={form && form.address && form.address.registeredAddress ? form.address.registeredAddress : ''}
                                            />
                                        </FormField>
                                }
                            </div>

                            <FormField className={"form-group"} id="emailAddress" error={errors && errors.emailAddress}>
                                <label>Email Address</label>
                                <input
                                    className="form-control"
                                    disabled={shouldDisableInput}
                                    id="email"
                                    onChange={(event) => {
                                        errors && errors.emailAddress && this.handleOnResetError('emailAddress');
                                        this.handleOnChange(event, 'emailAddress')
                                    }}
                                    type="text"
                                    value={form && form.emailAddress ? form.emailAddress : ''}
                                />
                            </FormField>
                            <div className="form-group">
                                <label>Contact Number</label>
                                {
                                    hasPhoneOptions ?
                                        <Select
                                            name="contactNumber"
                                            disabled={shouldDisableInput}
                                            onChange={(event) => this.handleSelectChange(event, 'contactNumber')}
                                            options={phoneOptions}
                                            value={form.contactNumber}
                                        />
                                        :
                                        <FormField id="number" error={errors && errors.contactNumber}>
                                            <input
                                                className="form-control"
                                                disabled={shouldDisableInput}
                                                id="contactNumber"
                                                onChange={(event) => {
                                                    errors && errors.contactNumber && this.handleOnResetError('contactNumber');
                                                    this.handleOnChange(event, 'contactNumber')
                                                }}
                                                type="text"
                                                value={form && form.contactNumber ? form.contactNumber : ''}
                                            />
                                        </FormField>
                                }
                            </div>
                            {
                                showWorkInfo &&
                                <div className="form-group">
                                    <label>Landline Number</label>
                                    {
                                        hasPhoneOptions ?
                                            <Select
                                                name="contactNumber"
                                                disabled={shouldDisableInput}
                                                onChange={(event) => this.handleSelectChange(event, 'landLine')}
                                                options={phoneOptions}
                                                value={form.landLine}
                                            />
                                            :
                                            <FormField id="number" error={errors && errors.landLine}>
                                                <input
                                                    className="form-control"
                                                    disabled={shouldDisableInput}
                                                    id="contactNumber"
                                                    onChange={(event) => {
                                                        errors && errors.landLine && this.handleOnResetError('landLine');
                                                        this.handleOnChange(event, 'landLine')
                                                    }}
                                                    type="text"
                                                    value={form && form.landLine ? form.landLine : ''}
                                                />
                                            </FormField>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="action-button-container" style={{width: "100%"}}>
                    <div/>
                    <button
                        className="action-btn-secondary"
                        onClick={this.handleOnSubmit}
                        type="button"
                    >Add
                    </button>
                </div>
            </div>
        )
    }

    _renderHeader() {
        const {title} = this.props;
        return (
            <div>
                <div className="product-heading">
                    {title}
                </div>
                <div className="title-gradient"/>
            </div>
        )
    }

    render() {
        const {isRSACitizen} = this.state;
        return (
            <div>
                {this._renderHeader()}
                <br/>
                <Toggler
                    label="South African Citizen / Resident?"
                    isOn={isRSACitizen}
                    onToggle={this.handleChangeSACitizen}
                />
                {this.renderDetailsForm()}
            </div>
        )
    }
}
