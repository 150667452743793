import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import EntityList from '../legalentity/EntityList';
import {useDispatch, useSelector} from 'react-redux';
import {setEntity, startKycMaintenance} from '../../actions/kycMaintenance';
import {fetchHierachy} from '../../actions/entity';
import FormField from '../formField/FormField';
import NoLinkedEntitiesMessage from '../legalentity/NoLinkedEntitiesMessage';

const KycEntity = (props) => {
    const [selectedEntity, setSelectedEntity] = useState(false);
    const [errors, setErrors] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const impersonation = useSelector(state => state.impersonation && state.impersonation.impersonation);
    const entities = useSelector(state => state.entity && state.entity.entities);
    const dispatch = useDispatch();

    useEffect(() => {
            dispatch(fetchHierachy(impersonation));
        }, []
    );

    const _handleSelect = (entity) => {
        errors && errors.selectedEntity && handleResetError('selectedEntity');
        setSelectedEntity(entity);
    };

    const handleAcceptTsAndCs = () => {
        errors && errors.termsAccepted && handleResetError('termsAccepted');
        setTermsAccepted(!termsAccepted);
    };

    const _handleStart = () => {
        const identityNumber = impersonation ? impersonation.identityNumber : '';
        const {history} = props;
        dispatch(setEntity(selectedEntity));
        dispatch(startKycMaintenance(identityNumber, selectedEntity, history));

    };

    const _renderTerms = () => {
        return (
            <FormField
                className="form-group"
                error={errors && errors.termsAccepted && ['Terms And Conditions Should Be Accepted']}
            >
                <div className="flexRow" style={{alignItems: 'flex-start', textAlign: 'left'}}>
                    <div className="icon-container" onClick={handleAcceptTsAndCs}>
                        <i
                            className={termsAccepted ? 'fa fa-check-square-o' : 'fa fa-square-o'}
                            style={{color: 'rgb(0, 51, 170)', fontSize: 20, marginRight: 10}}
                        />
                    </div>
                    <p style={{margin: 0}}>
                        I agree to the <a target="_blank"
                                          href="https://www.businessonline.standardbank.co.za/docs/Terms%20and%20conditions%20for%20business%20transactional%20accounts.pdf">Terms
                        and Conditions</a>, including sourcing of entity details from public domain.
                    </p>
                </div>
            </FormField>
        );
    };

    const _renderEntities = () => {
        const hasEntities = _.size(entities) > 0;

        if (!hasEntities)
            return (
                <div className="page-container">
                    <div className="page-main-section">
                        <NoLinkedEntitiesMessage/>
                    </div>
                </div>
            );

        return (
            <EntityList
                entities={entities}
                onSelect={_handleSelect}
                onSelectRadio={_handleSelect}
                error={errors && errors.selectedEntity}
                selectedEntity={selectedEntity}
                style={{maxHeight: '100vh'}}
            />
        );
    };
    const _renderFooter = () => {
        return (
            <div className="flexRow" style={{justifyContent: 'space-between'}}>
                <div/>
                <button
                    className="action-btn-primary"
                    onClick={_handleStart}
                    type="button"
                    disabled={selectedEntity && termsAccepted ? !termsAccepted : true}
                >Next
                </button>
            </div>
        );
    };

    return (
        <div className="page-container">
            <div className="container">
                <div className="column">
                    <_renderEntities/>
                    <_renderTerms/>
                    <_renderFooter/>
                </div>
            </div>
        </div>
    );
};

export default KycEntity;