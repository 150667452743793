import React from "react";
import _ from "lodash";
import {Table} from 'react-bootstrap';

import "../../../styles/ConfirmApplication.css";
import "../../../styles/Global.css";
import "react-datepicker/dist/react-datepicker.css";
import Popup from "../../Popup";
import {AddSettlementInstructionForm} from "./BasicInfoComponent";

export const IntermediaryComponent = (props) => {

    if (props.instruction.intermediaries && props.instruction.intermediaries.length > 0) {
        return (
            <div className="col-md-12">
                <hr style={{padding: 0}}/>
                <div style={{paddingLeft: 30, paddingRight: 30}}>
                    <button
                        className="action-btn-primary pull-right"
                        onClick={props.addIntermediary} style={{marginLeft: 50}}
                        type="button">
                        Add Intermediary
                    </button>
                </div>

                <div className="greyText fontSize">
                    <div style={{minHeight: 10}}>
                        <Table striped responsive>
                            <thead style={{backgroundColor: "#0033aa", color: "white"}}>
                            <tr>
                                <th>SSI Number</th>
                                <th>Sequence</th>
                                <th>Option</th>
                                <th>Swift Code</th>
                                <th>Bank Name</th>
                                <th>Bank Account Number</th>
                                <th>Account Number</th>
                                <th>Clearing Code</th>
                                <th>Message</th>
                                <th>Address</th>
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                props.instruction.intermediaries.map(
                                    ({
                                        ssiNumber,
                                        ssiSequence,
                                        swiftCode,
                                        option,
                                        bankName,
                                        addressLine1,
                                        addressLine2,
                                        addressLine3,
                                        message,
                                        subAccountNo,
                                        clearingCode,
                                        accountNumber
                                    }, index) =>
                                        <tr key={index}>
                                            <td>{ssiNumber}</td>
                                            <td>{ssiSequence}</td>
                                            <td>{option}</td>
                                            <td>{swiftCode}</td>
                                            <td>{bankName}</td>
                                            <td>{swiftCode? subAccountNo : accountNumber}</td>
                                            <td>{addressLine1 +" "+addressLine2 +" "+addressLine3}</td>
                                            <td>{message}</td>
                                            <td>{clearingCode}</td>
                                            <td>{beneficiaryAccountNo}</td>

                                            <td style={{textAlign: "center"}}>
                                                <div onClick={(e) => props.handleRemoveIntermediary(true, index)}>
                                                    <i style={{
                                                        color: "rgb(0, 51, 170)", fontSize: 20,
                                                        marginRight: 10, color: "#0033aa",
                                                        cursor: "pointer"
                                                    }} className={"fa fa-trash"}/>
                                                </div>
                                            </td>
                                        </tr>
                                )
                            }
                            </tbody>
                        </Table>
                    </div>
                </div>

            </div>
        )
     } else {
         return (
             <div></div>
         )
     }
    //else {
    //     return (
    //         <div className="col-md-12" style={{paddingBottom: 5, marginBottom: 5}}>
    //             <div className="col-md-6">
    //                 <p>Instruction has no intermediaries.</p>
    //             </div>
    //             <div className="col-md-6" >
    //                 <button
    //                     className="action-btn-secondary pull-right"
    //                     onClick={props.addIntermediary} style={{marginLeft: 50}}
    //                     type="button">
    //                     Add Intermediary
    //                 </button>
    //             </div>
    //         </div>
    //     )
    // }

}
