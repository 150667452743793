import * as types from './actionTypes';
import axios from 'axios';
import {API_ROOT} from "../api-config";
import {authCheck} from "./appLoading";

const AUTH_RULES_CONFIG_URL = API_ROOT + '/api/authorisation/';

export function getAuthRules(goldTierId, impersonation, onComplete = null) {
    return async (dispatch) => {
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            let authUrl = AUTH_RULES_CONFIG_URL + goldTierId;
            if (impersonation && impersonation.identityNumber) {
                authUrl = `${authUrl}?impersonationId=${impersonation.identityNumber}`;
            }
            const responseData = await axios.get(authUrl).then(response => response.data);

            onComplete && onComplete();
            if (responseData.success) {
                dispatch({type: types.FETCH_AUTH_RULES_SUCCESS, authRules: responseData.data});
            } else {
                console.log("rules.getAuthRules.errorMessage ===>", responseData.message);
                dispatch({
                    type: types.SHOW_RESPONSE_ERROR,
                    errorMessage: responseData.message && responseData.message.includes('not authorised') ? 'You are not authorised to configure the entity rules' : "Couldn't retrieve authorisation rules config."
                });
            }
        } catch (error) {
            console.log("rules.getAuthRules.error ===>", error);
            authCheck(dispatch,error);
        }
    }
}

export function selectDirectorAuthLevel(idNumber, authLevel) {
    return (dispatch) => dispatch({type: types.SELECT_DIRECTOR_AUTH_LEVEL, idNumber, authLevel});
}

export function addRule(rule) {
    return (dispatch) => dispatch({type: types.ADD_AUTH_RULE, rule});
}

export function saveAuthRulesConfig(authRules, onComplete = null) {
    return async (dispatch) => {
        try {

            axios.defaults.headers.common['Authorization'] = `Bearer ` + localStorage.getItem('access_token');
            const url = `${AUTH_RULES_CONFIG_URL}${authRules.legalEntityId}`;
            const responseData = await axios.put(url, authRules).then(response => response.data);

            onComplete && onComplete();
            if (responseData.success) {
                dispatch({type: types.SAVE_AUTH_RULES_SUCCESS, authRules: responseData.data});
            } else {
                console.log("rules.saveAuthRulesConfig.errorMessage ===>", responseData.message);
                dispatch({
                    type: types.SAVE_AUTH_RULES_ERROR,
                    errorMessage: responseData.message.includes('not authorised') ? 'You are not authorised to configure the entity rules' : responseData.message
                });
            }
        } catch (error) {
            console.log("rules.saveAuthRulesConfig.error ===>", error);
            authCheck(dispatch,error);
        }
    }
}

export function removeRule(index) {
    return (dispatch) => dispatch({type: types.REMOVE_AUTH_RULE, index})
}

export function onCloseModal() {
    return (dispatch) => dispatch({type: types.CLOSE_MESSAGE_MODAL})
}